import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, LinkButton, Text, Tooltip } from 'new-ui';

import { useServices } from '../../../../bi/context/services';

import { LABELS } from '../../consts';
import TEXTS from '../../../../bi/constants/texts';
import ROUTES from '../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './styles.module.css';

export const BottomPanel = () => {
  const {
    workspaceService: { isDemo },
    departmentsService: {
      updateForm,
      saveForm,
      store: {
        isValid,
        preparedToSaveModel,
        form: {
          value: { CompanyId, Name },
        },
      },
    },
  } = useServices(['Workspace', 'Departments', 'Notification']);

  const history = useHistory();

  const [showTooltip, setShowTooltip] = useState(false);

  const positionTooltip = showTooltip ? 'right' : 'top';

  const handleResize = (e: WindowEventMap['resize']) => {
    if ((e?.target as Window)?.innerWidth < 1400) {
      return setShowTooltip(true);
    }

    return setShowTooltip(false);
  };

  const handleGoBack = () => history.push(ROUTES.SETTINGS.DEPARTMENTS);

  const handleCancellation = async () => {
    updateForm({ forcedPrompt: true });
    handleGoBack();
  };

  const handleSubmitForm = () => {
    updateForm({ forcedPrompt: true });

    return saveForm(preparedToSaveModel);
  };

  useEffect(() => {
    if (window.innerWidth < 1400) {
      setShowTooltip(true);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  const RenderDemoTooltipContent = () => (
    <Text
      className={ styles.tooltip }
      color='white'
      type='NORMAL_14_130'
    >
      { TEXTS.NOT_FOR_DEMO }
    </Text>
  );

  const RenderNotValidaTooltipContent = useCallback(() => {
    const nonFilledFields = [];
    const tooltipStyle = showTooltip ? styles.errorTooltip : styles.nonFilled;

    if (!CompanyId) {
      nonFilledFields.push(LABELS.ORGANIZATION);
    }

    if (!Name) {
      nonFilledFields.push(LABELS.DEPARTMENT_NAME);
    }

    return (
      <div className={ tooltipStyle }>
        <Text
          color='white'
          type='bold_16'
        >
          { LABELS.REQUIRED_FIELDS }
        </Text>
        { !!nonFilledFields.length && (
          <Text
            color='white'
            type='NORMAL_14_130'
          >
            { nonFilledFields.join(', ') }
          </Text>
        ) }
      </div>
    );
  }, [CompanyId, Name]);

  const renderTooltipText = (
    isDemo ? RenderDemoTooltipContent : RenderNotValidaTooltipContent
  );

  return (
    <div className={ styles.bottom }>
      <div className={ styles.container }>
        <Tooltip
          position={ positionTooltip }
          className={ styles.submit }
          show={ !isValid || isDemo }
          renderContent={ renderTooltipText }
        >
          <Button
            type='secondary'
            onClick={ handleSubmitForm }
            disabled={ !isValid || isDemo }
            qaAttr={ QA_ATTRIBUTES.settings.departments.create.saveDepartmentButton }
          >
            { LABELS.SUBMIT_FORM }
          </Button>
        </Tooltip>
        <LinkButton
          className={ styles.cancel }
          theme='blue-without-border'
          onClick={ handleCancellation }
        >
          <Text
            type='NORMAL_14'
            color='accent'
          >
            { LABELS.CANCEL }
          </Text>
        </LinkButton>
      </div>
    </div>
  );
};
