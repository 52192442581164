import React from 'react';

import { Text } from 'new-ui';

import styles from './index.module.css';

interface IItemCss {
  width: number;
  height: number;
  top: number;
  left: number;
}

interface ItemProps {
  css: IItemCss;
  type: string;
  toolTip: string;
  text: string;
}

interface IInfoItem {
  item: ItemProps;
}

const getStyleForTooltip = ({ type, css: { height } }: ItemProps) => {
  const initial = 'initial';

  const top = type === 'top' ? initial : height;
  const bottom = type === 'bottom' ? initial : height;

  return {
    top,
    bottom,
  };
};

const InfoItem: React.FC<IInfoItem> = ({ item, item: { css, type, toolTip, text } }) => {
  const tooltipStyle = getStyleForTooltip(item);

  return (
    <div
      className={ styles.item }
      style={ { ...css } }
    >
      { text }
      <div
        className={ `${styles.tooltip} ${type === 'top' ? `${styles.top}` : `${styles.bottom}`}` }
        style={ tooltipStyle }
      >
        <Text type='NORMAL_14'>{ toolTip }</Text>
      </div>
    </div>
  );
};

export { InfoItem };
