import React, { ReactNode, useState } from 'react';
import { Button, Dialog, Select, Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import styles from './styles.module.css';

interface AddInTripDialogProps {
  show: boolean,
  items: { label: string, value: number | string }[],
  loading: boolean
  onClose(value:boolean): void,
  onSave(value:string | number): void,
}

const LABELS = {
  ADD: getText('cart:addingTripDialog.add'),
  CANCEL: getText('cart:addingTripDialog.cancel'),
  TITLE: getText('cart:addingTripDialog.title'),
  DISCRTIPTION: getText('cart:addingTripDialog.discription'),
  SELECT_TRIP: getText('cart:addingTripDialog.selectTrip'),
  SEARCH_SELECT_TRIP: getText('cart:addingTripDialog.searchSelectTrip'),
};

type ItemType = {
  label: string,
  value: string | number
};

const AddInTripDialog = ({ show, items, loading, onClose, onSave }: AddInTripDialogProps) => {
  const [selectedValue, setSelectedValue] = useState<string | number>('');

  const buttonsHtml = (
    <div className={ styles.buttons }>
      <Button onClick={ () => onClose(false) } type='textual'> {LABELS.CANCEL} </Button>
      <Button
        onClick={ () => onSave(selectedValue) }
        disabled={ selectedValue === '' }
        loading={ loading }
        type='secondary'
      >
        {LABELS.ADD}
      </Button>
    </div>
  );

  const renderItems = (item: ItemType): ReactNode => (
    <div className={ styles.item }>
      {item.label}
    </div>
  );

  return (
    <Dialog
      show={ show }
      showClosing
      className={ styles.dialog }
      onChange={ () => onClose(false) }
    >
      <div className={ styles.wrapper }>
        <Text type='bold_20'>
          {LABELS.TITLE}
        </Text>
        <Text type='NORMAL_16_140' className={ styles.description }>
          {LABELS.DISCRTIPTION}
        </Text>
        <div className={ styles.select }>
          <Select
            theme='border'
            search
            placeholder={ LABELS.SELECT_TRIP }
            value={ selectedValue }
            items={ items }
            searchPlaceholder={ LABELS.SEARCH_SELECT_TRIP }
            renderItem={ renderItems }
            onChange={ setSelectedValue }
          />
        </div>
        {buttonsHtml}
      </div>
    </Dialog>
  );
};

export { AddInTripDialog };
