import Api from '../../api';

import { MainAnalytic } from '../../utils/analytics';

import { aeroexpressStore, IAeroexpressStore } from './store';

import { AEROEXPRESS_CLASSES } from '../../../page/Aeroexpress/components/SearchMenu';
import {
  AEROEXPRESS_TARIFF_DESCRIPTIONS,
  AEROEXPRESS_TARIFF_TYPES, IAeroexpressPricingResponse, IAeroexpressPricingResponseMapped,
  IAeroexpressRoutesResponse, IAeroexpressToCartRequest,
} from './const';

const RESPONSE_TYPES = {
  SUCCESS: 'success',
};

const FIELDS_TO_BE_IGNORED = ['schedules', 'count'];

class AeroexpressService {
  api: Api['aeroexpress'];
  store = aeroexpressStore;

  constructor(api: Api) {
    this.api = api.aeroexpress;
  }

  getRoutes() {
    this.api.getRoutes()
      .then((r: IAeroexpressRoutesResponse[]) => this.store.setRawRoutes(r));
  }

  setSearchFromApproveRequest = (travelApproval: any) => this.store.setRawRoutesFromApproval(travelApproval);

  searchTwoWayStandard = async () => {
    const { tariff_type: tt, specific, count: c } = this.store.preparedSearchModel;

    const searchPromises = [
      this.api.search({ tariff_type: tt, count: c, departure_date: specific[0].departure_date }),
      this.api.search({ tariff_type: tt, count: c, departure_date: specific[0].backward_date }),
    ];

    return Promise.all(searchPromises).then((...rest: IAeroexpressPricingResponse[][]) => {
      const payload = [];

      for (let i = 0; i < rest[0].length; i++) {
        const { result, status, error_message: errorMessage } = rest[0][i];

        if (status !== RESPONSE_TYPES.SUCCESS) {
          return this.store.updateSearchResult({ loading: false, error: errorMessage });
        }

        const { id, variants, count } = result;
        const parsed = variants
          .reduce<IAeroexpressPricingResponseMapped[]>((
          r, { tariffs: [{ price: { total }, type }], departure_date },
        ) => ([
          ...r,
          ({
            amount: total,
            type,
            description: AEROEXPRESS_TARIFF_DESCRIPTIONS[AEROEXPRESS_TARIFF_TYPES.TWOWAY](departure_date),
            count,
            id,
            departure_date,
          }),
        ]), []);

        payload.push(...parsed);
      }

      return this.store.updateSearchResult({ loading: false, items: payload });
    });
  };

  search() {
    this.store.updateSearchResult({ loading: true });
    const { tariff_type: tt, specific, count: c } = this.store.preparedSearchModel;

    if (specific[0].backward_date) {
      return this.searchTwoWayStandard();
    }

    return this.api.search({ tariff_type: tt, count: c, ...specific[0] }).then(
      ({
        result,
        status,
        error_message,
      }: IAeroexpressPricingResponse,
      ) => {
        if (status === RESPONSE_TYPES.SUCCESS && result) {
          const { id, count, variants } = result;

          const get_date_back = () => variants[1] && variants[1].departure_date;

          const payload = variants.reduce<IAeroexpressPricingResponseMapped[]>((
            r,
            { tariffs: [{ price: { total }, type }], departure_date },
          ) => [
            ...r, ({
              amount: total,
              type,
              description: AEROEXPRESS_TARIFF_DESCRIPTIONS[type](departure_date, get_date_back()),
              count,
              id,
              departure_date,
            }),
          ], []);

          this.store.updateSearchResult({ loading: false, items: payload, error: null });

          return;
        }

        this.store.updateSearchResult({ loading: false, error: error_message, items: [] });
      });
  }

  addToCart(payload: IAeroexpressToCartRequest) {
    return this.api.addToCart(payload);
  }

  updateSearchSettings = (payload: any) => {
    if (Object.keys(payload).every((k) => {
      switch (k) {
        case 'dateDeparture':
        case 'dateBack': {
          return payload[k].valueOf() === this.store.searchSettings[k].valueOf();
        }
        case 'route': {
          return !!payload[k].label && payload[k].label === this.store.searchSettings[k].label;
        }
        case 'type': {
          const value = payload[k];

          MainAnalytic.sendAmplitudeArrayArgs(
            MainAnalytic.ACTIONS.SEARCH.SEARCH_AERO_EXPRESS_CLASS_CHOSEN(
              AEROEXPRESS_CLASSES.find(i => i.value === value)?.analyticValue,
            ),
          );

          return value === this.store.searchSettings[k];
        }
        default: {
          return payload[k] === this.store.searchSettings[k as keyof IAeroexpressStore['searchSettings']];
        }
      }
    })) {
      return;
    }

    this.store.updateSearchSettings(payload);

    if (Object.keys(payload).some(k => FIELDS_TO_BE_IGNORED.includes(k))) {
      return;
    }

    this.store.updateSearchSettings({ schedules: { dateDeparture: null, dateBack: null } });
  };

  disableSearchButton = (isDateSelected: boolean) => {
    this.store.disableSearchButton(isDateSelected);
  };

  setNewSearch = () => {
    this.store.clearSearchSettings();
  };
}

export default AeroexpressService;
