import React, { FC } from 'react';
import { Dropdown, Button, Text, Icon, Collapse } from 'new-ui';
import { getText } from '../../../../../i18n';

import { formatDate } from '../../../../bi/utils/formatDate';
import { invoiceSmartAgent } from '../../../../bi/utils/documents';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { PATTERN } from '../../../../bi/constants/dateFormats';

import { DocumentsList, PackagesItems } from '../../../../bi/types/report';

import styles from '../../styles/row.module.css';

type DocumentRowProps = {
  item: PackagesItems,
  onDownloadDocument: (Id: number, format: string, documentId: number) => void,
  onDownloadAllInvoiceByData: (Id: number, documentId: number,) => void,
};

const { DAY_OF_MONTH_LONG, YEARMONTHDAY } = PATTERN;

const LABELS = {
  DOCUMENT_IN_FORMAT: (name: string, type: string) => getText('reports:documents.row.documentInFormat', { name, type }),
  DOCUMENT_NAME: (number: string, createdDate: string) => getText('reports:documents.row.documentName', { number, createdDate }),
  DOWNLOAD_ALL_INVOICE_BY_DATE: getText('reports:documents.row.downloadAllInvoiceByDate'),
};

const renderDetailButtons = (
  { FileType, Id, DocName }:{ DocName: string, FileType: string[], Id: number },
  handleDownloadDocument: (documentId: number, format: string) => void) => {
  const [mainDocument, ...otherDocuments] = FileType;

  if (!otherDocuments.length || invoiceSmartAgent(DocName)) {
    return (
      <div className={ styles.document }>
        <Button
          onClick={ () => handleDownloadDocument(Id, mainDocument) }
          type='textual'
        >
          { DocName }
        </Button>
      </div>
    );
  }

  const linksButtonHtml = otherDocuments.map(type => (
    <Button
      key={ `${Id}__${type}` }
      onClick={ () => handleDownloadDocument(Id, type) }
      type='textual'
      className={ styles.btn }
    >
      { LABELS.DOCUMENT_IN_FORMAT(DocName, type)}
    </Button>),
  );

  return (
    <div className={ styles.document }>
      <Dropdown
        title={ DocName }
        onClick={ () => handleDownloadDocument(Id, mainDocument) }
        smooth
      >
        { linksButtonHtml }
      </Dropdown>
    </div>
  );
};

const renderDetail = (item: DocumentsList, onDownloadDocument: (Id: number, format: string, documentId?: number) => void) => {
  const downloadDocumentHtml = renderDetailButtons(item, onDownloadDocument);

  return (
    <div key={ `document__${item.Id}` } className={ styles['detail-part'] }>
      <div className={ styles.date } />
      <div className={ styles.number } />
      <div className={ styles.period } />
      { downloadDocumentHtml }
    </div>
  );
};

const renderDetails = (details: DocumentsList[], onDownloadDocument: (Id: number, format: string, documentId?: number) => void) => {
  if (!details.length) {
    return null;
  }

  return details.map(item => renderDetail(item, onDownloadDocument));
};

const DocumentRow: FC<DocumentRowProps> = ({ item, onDownloadDocument, onDownloadAllInvoiceByData }) => {
  const { Id, CreatedDate, PeriodStart, PeriodEnd, Number, Documents } = item;

  const handleDownloadDocument = (documentId: number, format: string): void => onDownloadDocument(Id, format, documentId);

  const handleDownloadArchive = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, documentId: number) => {
    e.preventDefault();
    e.stopPropagation();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.DOC_ARCHIVE);
    onDownloadAllInvoiceByData(Id, documentId);
  };

  const createdDate = formatDate(CreatedDate, DAY_OF_MONTH_LONG);
  const createdDateYear = formatDate(CreatedDate, YEARMONTHDAY);
  const period = `${formatDate(PeriodStart, DAY_OF_MONTH_LONG)} - ${formatDate(PeriodEnd, DAY_OF_MONTH_LONG)}`;

  const documentHtml = renderDetails(Documents, handleDownloadDocument);

  const renderContent = () => (
    <div className={ styles['main-part'] }>
      <Text className={ styles.date }>{ createdDate }</Text>
      <Text className={ styles.number }>{ Number }</Text>
      <Text className={ styles.period }>{ period }</Text>
      <Text className={ styles.document }>{ LABELS.DOCUMENT_NAME(Number, createdDateYear)}</Text>
      <Button
        key={ `${Id}__${Id}` }
        type='textual'
        onClick={ (e) => handleDownloadArchive(e, Id) }
      >
        {LABELS.DOWNLOAD_ALL_INVOICE_BY_DATE}
      </Button>
      <div className={ styles.arrow }>
        <Icon type='arrowsDownGray'/>
      </div>
    </div>
  );

  return (
    <Collapse
      className={ styles.wrap }
      openedClassName={ styles.open }
      opened={ false }
      renderContent={ renderContent }
    >
      <div className={ styles.details }>
        { documentHtml }
      </div>
    </Collapse>
  );
};

export { DocumentRow };
