import React from 'react';
import { Button, Text, Tooltip } from 'new-ui';

import { useServices } from '../../../../../bi/context/services';

import { getText } from '../../../../../../i18n';

import { AirSegmentWithRouteId } from '../../../../../bi/types/airline';
import { ISourcesItem } from '../../../../../bi/services/checkout/types';

import styles from './styles.module.css';

interface AirlineAdditionalServicesRowProps {
  segment: AirSegmentWithRouteId;
  number: number;
  everyEmployeeChoosen: boolean;
  handleChooseSeat({ segmentId, number, RouteId }: { segmentId: number, number: number, RouteId: string | number }): void;
  currentItem: ISourcesItem;
}

interface IEmployeeCartItemsInfo {
  companyIds: number[];
  employeeIds: number[];
}

const LABELS = {
  PLACES: getText('cart:airlineAdditionalService.seats.places'),
  NOT_SELECTED: getText('cart:airlineAdditionalService.seats.notSelected'),
  FLIGHT: getText('cart:airlineAdditionalService.seats.flight'),
  SELECT_PASSENGERS: getText('cart:airlineAdditionalService.seats.selectPassengers'),
  CHOOSE_SEATS: getText('cart:airlineAdditionalService.seats.chooseSeats'),
};

const AirlineAdditionalServicesRow = ({
  segment: {
    DepartureCity,
    ArrivalCity,
    ID,
    AdditionalServices,
    RouteId,
    MarketingAirline,
  },
  everyEmployeeChoosen,
  number,
  handleChooseSeat,
  currentItem,
}: AirlineAdditionalServicesRowProps) => {
  const { airlineSeatsService } = useServices(['AirlineSeats']);

  const seats = AdditionalServices.map(({ Service: { Number } }) => Number);

  const { Metadata: { FareId } } = JSON.parse(currentItem.Data);

  const handleChooseSeatButton = () => {
    const airlineCode = MarketingAirline?.ID;
    const fareName = FareId.split(' ').join('');

    const { companyIds, employeeIds } = currentItem.EmployeeCartItems?.reduce((acc, { CompanyId, EmployeeId }) => {
      acc.companyIds.push(CompanyId);
      acc.employeeIds.push(EmployeeId);

      return acc;
    }, {
      companyIds: [],
      employeeIds: [],
    } as IEmployeeCartItemsInfo);

    handleChooseSeat({ segmentId: ID, number, RouteId });
    airlineSeatsService.getAirlineSeatsBonusWarningS7(fareName, companyIds, employeeIds, airlineCode);
  };

  const renderSeats = () => {
    const places = seats.length ? seats.join(' ') : LABELS.NOT_SELECTED;

    return (
      <Text className={ styles.seats }>
        <span className={ styles.places }>{ LABELS.PLACES }:</span>
        &nbsp;
        { places }
      </Text>
    );
  };

  return (
    <div className={ styles['wrapper-flight'] }>
      <Text>
        { LABELS.FLIGHT } {number}.
        &nbsp;
        <span className={ styles.cities }>{DepartureCity} — {ArrivalCity}</span>
      </Text>
      <div className={ styles['wrapper-place'] }>
        { renderSeats() }
        <Tooltip
          position='left'
          show={ !everyEmployeeChoosen }
          renderContent={ () => (
            <Text
              className={ styles.tooltip }
              type='NORMAL_14_130'
              color='white'
            >
              { LABELS.SELECT_PASSENGERS }
            </Text>
          ) }
        >
          <Button
            className={ styles['button-choose-seat'] }
            type='primary'
            disabled={ !everyEmployeeChoosen }
            onClick={ handleChooseSeatButton }
          >
            { LABELS.CHOOSE_SEATS }
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export { AirlineAdditionalServicesRow };
