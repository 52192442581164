import React from 'react';

import { Dialog, Button, Text } from 'new-ui';
import { getText, getTextArray } from '../../../../../i18n';

import toDecline from '../../../../bi/utils/toDecline';

import { IParentCar } from '../../../../bi/services/trains/store/types';
import { ISelectedPlace } from '../../../../bi/types/train';

import styles from './index.module.css';

interface IAddToCartDialog {
  showAddToCartDialog: boolean;
  selectedPlace: ISelectedPlace[],
  car: IParentCar;
  range: {
    from: string,
    to: string,
  };
  onClose: () => void;
  onAddToCart: () => void;
}

const LABELS = {
  TICKETSPLACE: getTextArray('trains:addToCartDialog.ticketPlace'),
  NONREFUNDABLE: getTextArray('trains:addToCartDialog.nonRefundable'),
  ANOTHERPLACE: getTextArray('trains:addToCartDialog.anotherPlace'),
  NONREFUNDABLETICKETS: getTextArray('trains:addToCartDialog.nonRefundableTickets'),
  TICKET: getTextArray('trains:addToCartDialog.ticket'),
  PLACE: getTextArray('trains:addToCartDialog.place'),
  NEED: getTextArray('trains:addToCartDialog.need'),
  CONTINUE: getText('common:continue'),
  CANCEL: getText('common:cancel'),
  CONTINUE_WITHOUT_PLACES: getText('trains:addToCartDialog.continueWithoutPlaces'),
  NO_PLACE_SELECTED: getText('trains:addToCartDialog.noPlaceSelected'),
  NON_REFUNDABLE_TICKETS: getText('trains:addToCartDialog.nonRefundableTicketsWarning'),
  ADD_TO_CART: getText('trains:addToCartDialog.addToCart'),
  ATTENTION: getText('trains:addToCartDialog.attention'),
  WITHOUT_VARIANTS: (
    needText: string,
    ticketText: string,
    placeText: string,
  ) => getText('trains:addToCartDialog.ticketWithRefund.withoutVariants', { needText, ticketText, placeText }),
  WITH_VARIANTS: (
    needText: string,
    ticketText: string,
    anotherPlaceText: string,
    countRefundablePlaces: number,
  ) => getText('trains:addToCartDialog.ticketWithRefund.withVariants', { needText, ticketText, anotherPlaceText, countRefundablePlaces }),
};

const renderHaveSelectedPlaceText = (
  nonRefundableSelectedPlaces: number[],
  isAllPlacesNonRefundable: boolean,
  countRefundablePlaces: number,
) => {
  const countNonRefundableSelectedPlaces = nonRefundableSelectedPlaces.length;

  const ticketsPlaceText = toDecline(countNonRefundableSelectedPlaces, LABELS.TICKETSPLACE);
  const nonRefundableText = toDecline(countNonRefundableSelectedPlaces, LABELS.NONREFUNDABLE);
  const nonRefundableTicketsText = toDecline(countNonRefundableSelectedPlaces, LABELS.NONREFUNDABLETICKETS);
  const anotherPlaceText = toDecline(countNonRefundableSelectedPlaces, LABELS.ANOTHERPLACE);
  const ticketText = toDecline(countNonRefundableSelectedPlaces, LABELS.TICKET);
  const placeText = toDecline(countNonRefundableSelectedPlaces, LABELS.PLACE);
  const needText = toDecline(countNonRefundableSelectedPlaces, LABELS.NEED);

  const placesNumbers = nonRefundableSelectedPlaces.join(', ');

  const middleText = isAllPlacesNonRefundable
    ? LABELS.WITHOUT_VARIANTS(needText, ticketText, placeText)
    : LABELS.WITH_VARIANTS(needText, ticketText, anotherPlaceText, countRefundablePlaces);

  const middleTextHtml = <Text type='NORMAL_14'>{ middleText }</Text>;

  return (
    <>
      <Text type='NORMAL_14'>{ LABELS.ATTENTION } { ticketsPlaceText } { placesNumbers } - { nonRefundableText }.</Text>
      { middleTextHtml }
      <br />
      <Text type='NORMAL_14'>{ LABELS.ADD_TO_CART } { nonRefundableTicketsText }?</Text>
    </>
  );
};

const renderWithoutSelectedPlacesText = (haveNonRefundablePlaceInCar: boolean) => {
  const textHaveNonRefundablePlaceInCar = haveNonRefundablePlaceInCar && (
    <Text type='NORMAL_14'>
      { LABELS.NON_REFUNDABLE_TICKETS }
    </Text>
  );

  return (
    <>
      <Text type='NORMAL_14'>
        { LABELS.NO_PLACE_SELECTED }
      </Text>
      { textHaveNonRefundablePlaceInCar }
      <br />
      <Text type='NORMAL_14'>
        { LABELS.CONTINUE_WITHOUT_PLACES }
      </Text>
    </>
  );
};

const renderTextAddToCartDialog = (
  haveSelectedPlace: boolean,
  haveNonRefundablePlaceInCar: boolean,
  nonRefundableSelectedPlaces: number[],
  isAllPlacesNonRefundable: boolean,
  countRefundablePlaces: number,
) => (haveSelectedPlace
  ? renderHaveSelectedPlaceText(nonRefundableSelectedPlaces, isAllPlacesNonRefundable, countRefundablePlaces)
  : renderWithoutSelectedPlacesText(haveNonRefundablePlaceInCar));

const AddToCartDialog: React.FC<IAddToCartDialog> = ({
  showAddToCartDialog,
  selectedPlace,
  car: { PlacesPrices },
  range: { from },
  onClose,
  onAddToCart,
}) => {
  const haveSelectedPlace = !!from || !!selectedPlace.length;
  const haveNonRefundablePlaceInCar = PlacesPrices.some(({ NonRefundable }) => NonRefundable);
  const isAllPlacesNonRefundable = PlacesPrices.every(({ NonRefundable }) => NonRefundable);

  const countRefundablePlaces = PlacesPrices.reduce((acc, { NonRefundable, Places }) => (
    NonRefundable ? acc : acc + Places.length), 0,
  );

  const nonRefundableSelectedPlaces = selectedPlace.reduce<number[]>((acc, { number, hasAlternative }) => {
    const isNonRefundable = hasAlternative || PlacesPrices.some(({ NonRefundable, Places }) =>
      NonRefundable && Places.some(place => parseInt(place, 10) === number),
    );

    if (isNonRefundable) acc.push(number);

    return acc;
  }, []);

  return (
    <Dialog
      show={ showAddToCartDialog }
      onChange={ onClose }
    >
      <div className={ styles.wrapper }>
        {
          renderTextAddToCartDialog(
            haveSelectedPlace,
            haveNonRefundablePlaceInCar,
            nonRefundableSelectedPlaces,
            isAllPlacesNonRefundable,
            countRefundablePlaces,
          )
        }
        <div className={ styles.buttons }>
          <Button
            type='primary'
            onClick={ onAddToCart }
          >
            { LABELS.CONTINUE }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual-medium'
            onClick={ onClose }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { AddToCartDialog };
