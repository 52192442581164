import React from 'react';
import { Button, Dropdown, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { languages } from '../../../../../i18n/locales/consts';

import styles from './styles/index.module.css';

const LABELS = {
  DOWNLOAD: getText('trip:documents.download'),
  FORMAT_PDF_RU: getText('trip:documents.formatPdfRu'),
  FORMAT_PDF_EN: getText('trip:documents.formatPdfEn'),
};

interface IBookingForm {
  id: number,
  onDownloadButton(value: number): void,
  onDownloadVoucherLocaleButton(id: number, note: string): void,
}

const BookingForm = ({
  id,
  onDownloadButton,
  onDownloadVoucherLocaleButton,
}: IBookingForm) => {
  const renderButtonDownload = (ids: number) => (
    <Button
      type='textual'
      className={ styles.btn }
      onClick={ () => onDownloadButton(ids) }
    >
      { LABELS.DOWNLOAD }
    </Button>
  );

  return (
    <div className={ styles.wrapper }>
      <Dropdown
        isOpenMenu
        hoverOpenMenu
        renderLabel={ () => renderButtonDownload(id) }
      >
        <div className={ styles.content }>
          <Button
            type='textual'
            onClick={ () => onDownloadVoucherLocaleButton(id, languages.ru) }
          >
            <Text color='default' type='NORMAL_16'>{ LABELS.FORMAT_PDF_RU }</Text>
          </Button>
        </div>
        <div className={ styles.content }>
          <Button
            type='textual'
            onClick={ () => onDownloadVoucherLocaleButton(id, languages.en) }
          >
            <Text color='default' type='NORMAL_16'>{ LABELS.FORMAT_PDF_EN }</Text>
          </Button>
        </div>
      </Dropdown>
    </div>
  );
};

export { BookingForm };
