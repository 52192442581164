import ACTION from './action';
import Store from '../../store';

import { getMoment, startOfMonth, subPeriod } from '../../utils/formatDate';
import { createItems } from '../../utils/report';
import { IOperationStore } from '../../types/report';

const createNewState = (): IOperationStore => ({
  loading: false,
  more: false,
  total: null,
  bankDetails: '',
  items: [],
  startDate: startOfMonth(),
  endDate: getMoment(),
  maxDate: getMoment(),
  invoice: {
    startDate: subPeriod(7, 'days'),
    endDate: getMoment(),
  },
  allInvoicesInfo: {
    loadingAllInvoices: false,
    allInvoices: [],
  },
});

const reducer = (action: { [key: string]: any, type: string }, state: IOperationStore) => {
  switch (action.type) {
    case ACTION.STARTLOADOPERATION: {
      return {
        ...state,
        loading: true,
        items: [],
      };
    }

    case ACTION.LOADCOMPLETE: {
      return {
        ...state,
        loading: false,
        items: createItems(action.payload).slice(0, 5),
        more: action.payload.length > 5,
      };
    }

    case ACTION.LOADRANGEOPERATIONSCOMPLETE: {
      const items = createItems(action.payload);

      return {
        ...state,
        loading: false,
        items,
      };
    }

    case ACTION.GET_ALL_INVOICE: {
      return {
        ...state,
        allInvoicesInfo: {
          loadingAllInvoices: action.payload.loadingAllInvoices,
          allInvoices: action.payload.allInvoices,
        },
      };
    }

    case ACTION.LOADBALANCE: {
      const { Credit, Debit } = action.payload;
      const credit = Math.abs(Credit);
      const overpayment = credit - Debit;

      const total = {
        Credit: credit,
        IsOverpayment: overpayment > 0,
        IsDebts: overpayment < 0,
        NoDebts: overpayment === 0,
        Overpayment: Math.round(overpayment * 100) / 100,
        Debt: 0,
        Debit: Math.abs(Debit),
      };

      if (total.IsDebts) {
        total.Debt = Math.abs(Math.round((Debit - credit) * 100) / 100);
      }

      return {
        ...state,
        total,
      };
    }
    case ACTION.INVOICE_REQUISITES: {
      const { bankDetails } = action.payload;

      return {
        ...state,
        bankDetails,
      };
    }
  }

  return state;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
