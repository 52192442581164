// @ts-nocheck
import React from 'react';

import { Text, Tooltip } from 'new-ui';

import {
  formatDate,
  formatDateWithMinutes,
  textualMonthAndYearWithHoursAndMinutesPattern,
} from '../../../../bi/utils/formatDate';

import { getText } from '../../../../../i18n';

import { FIELD_APPROVAL_REQUEST, ExpenseStatus, CHANGE_FIELD } from '../../../../bi/constants/approve';

import styles from './index.module.css';

const LABELS = {
  APPLICATION_FROM: getText('approvalScheme:approvalTooltip.applicationFrom'),
  SENT_FROM: getText('approvalScheme:approvalTooltip.sent'),
  COMMENTS: getText('approvalScheme:approvalTooltip.comments'),
  CHANGES: getText('approvalScheme:approvalTooltip.changes'),
  EDIT: (oldValue: string, newValue: string) => getText('approvalScheme:approvalTooltip.edit', { oldValue, newValue }),
  DELETE: (value: string) => getText('approvalScheme:approvalTooltip.delete', { value }),
};

const LABEL_KEYS = {
  Budget: getText('approvalScheme:approvalTooltip.budget'),
  StartDate: getText('approvalScheme:approvalTooltip.startDate'),
  EndDate: getText('approvalScheme:approvalTooltip.endDate'),
  Purpose: getText('approvalScheme:approvalTooltip.purpose'),
  Destinations: getText('approvalScheme:approvalTooltip.destination'),
  Accepted: getText('applicationScheme:applicationStatusCard:approved'),
  Awaiting: getText('applicationScheme:applicationStatusCard:awaiting'),
  Unnecessary: getText('applicationScheme:applicationStatusCard:unnecessary'),
  Declined: getText('applicationScheme:applicationStatusCard:declined'),
  Archived: getText('applicationScheme:applicationStatusCard:archived'),
  Analytics: getText('approvalScheme:approvalTooltip.analytics'),
  Tags: getText('approvalScheme:approvalTooltip.tags'),
  Comment: getText('approvalScheme:approvalTooltip.comment'),
  Employees: getText('approvalScheme:approvalTooltip.employees'),
};

const ApprovalHistoryPopUp = ({ travelApproval, approvalPopUp }) => {
  if (!travelApproval || !travelApproval.length || !approvalPopUp) return null;

  const { Approves } = approvalPopUp;
  const [travelApprovalData] = travelApproval;

  const renderSenderAndDate = () => {
    const { Messages, CreatedDate } = travelApprovalData;

    const [messageInfo] = Messages;
    const { Name } = messageInfo;
    const formattedDate = formatDate(CreatedDate, textualMonthAndYearWithHoursAndMinutesPattern);

    return (
      <div className={ styles.sender_info }>
        <Text color='white' type='NORMAL_14'>{LABELS.APPLICATION_FROM} <b>{ Name }</b></Text>
        <Text color='white' type='NORMAL_14'>{LABELS.SENT_FROM} <b>{ formattedDate }</b></Text>
      </div>
    );
  };

  const renderComments = () => {
    const allMessages = Approves.flatMap(approve => approve.Messages);

    const uniqueMessages = allMessages.filter((message, index, self) => {
      const hasValidComment = message.Comment && message.Comment.length > 0;
      const isUniqueComment = self.findIndex(m => m.Comment === message.Comment) === index;

      return hasValidComment && isUniqueComment;
    });

    if (!uniqueMessages.length) return null;

    const commentsHtml = uniqueMessages.map(({ Date, Name, Comment }, i) => {
      const formattedDate = formatDate(Date, textualMonthAndYearWithHoursAndMinutesPattern);

      return (
        <div key={ i } className={ styles.general_information }>
          <div className={ styles.info }>
            <Text
              color='white'
              type='NORMAL_14'
              className={ styles.border_bottom_white }
            >
              {formattedDate} {` ${Name}: `}
            </Text>
            <Text color='white' type='bold_14'>{Comment}</Text>
          </div>
        </div>
      );
    });

    return (
      <div className={ styles.comments }>
        <Text type='bold_14' color='white'>{LABELS.COMMENTS}</Text>
        {commentsHtml}
      </div>
    );
  };

  const renderAnalyticHistoryChange = (changedFields) => {
    const changedAnalytics = changedFields.map(({ Name, NewValue, OldValue }, i) => {
      if (Name !== CHANGE_FIELD.ANALYTICS) return null;

      const oldValue = JSON.parse(OldValue);
      const newValue = JSON.parse(NewValue);

      return Object.entries(oldValue).map(([analyticName, analyticValue]) => (
        <>
          <Text color='white' type='NORMAL_14' key={ i }>
            {`${analyticName}: `}
          </Text>
          <Text color='white' type='bold_14' key={ i }>
            {`${analyticValue} — ${newValue[analyticName]}`}
          </Text>
        </>
      ));
    });

    return (
      <div className={ styles.info }>
        <Text color='white' type='bold_14'>{LABEL_KEYS.Analytics}</Text>
        <div>{changedAnalytics}</div>
      </div>
    );
  };

  const historyChanges = () => {
    const { Changes } = travelApprovalData;

    if (!Changes?.length) return null;

    const changesHtml = Changes.map(({ EventDate, Fields: fil, Id }) => {
      const renderFields = fil.length && fil.map(({ Name, NewValue, OldValue }, i) => {
        if (Name === CHANGE_FIELD.ANALYTICS) return null;

        const renderValue = (oldValues: string, newValues: string) => {
          if (!oldValues && !!newValues) {
            return (
              <Text color='white' type='bold_14'>
                { newValues }
              </Text>
            );
          }

          if ((!!oldValues && !newValues) || (Name === CHANGE_FIELD.BUDGET && !Number(newValues))) {
            return (
              <Text color='white' type='bold_14'>
                { LABELS.DELETE(oldValues) }
              </Text>
            );
          }

          return (
            <Text color='white' type='bold_14'>
              { LABELS.EDIT(oldValues, newValues) }
            </Text>
          );
        };

        return (
          <div className={ styles.info } key={ i }>
            <Text color='white' type='NORMAL_14'>{ LABEL_KEYS[Name] }</Text>
            <Text color='white' type='bold_14'> { renderValue(OldValue, NewValue) } </Text>
          </div>
        );
      });

      return (
        <div key={ Id }>
          <Text color='white' type='bold_14'>
            { LABELS.CHANGES } { formatDateWithMinutes(EventDate) }
          </Text>
          { renderFields }
        </div>
      );
    });

    const [approveChanges] = Changes;
    const { Fields } = approveChanges;

    return (
      <div className={ styles.history }>
        <div className={ styles.general_information }>
          {changesHtml}
          {renderAnalyticHistoryChange(Fields)}
        </div>
      </div>
    );
  };

  const getResolvedValue = (Resolution) => {
    if (Resolution === ExpenseStatus.AutoAccepted || Resolution === ExpenseStatus.AutoDeclined) {
      return LABEL_KEYS.Unnecessary;
    }

    if (Resolution === ExpenseStatus.Accepted || Resolution === ExpenseStatus.Declined) {
      return LABEL_KEYS[Resolution];
    }

    return LABEL_KEYS.Awaiting;
  };

  const renderApprovalEntity = (entity, index, senderAndDateContent) => {
    const { Resolution, ApprovalDate, ApproverName } = entity;
    const formattedDate = formatDate(ApprovalDate, textualMonthAndYearWithHoursAndMinutesPattern);

    const shouldRenderDetails = !senderAndDateContent && index === 0;
    const renderSenderContent = shouldRenderDetails ? renderSenderAndDate(entity) : null;
    const renderCommentsContent = shouldRenderDetails ? renderComments() : null;
    const renderHistoryChanges = shouldRenderDetails ? historyChanges() : null;

    return (
      <div key={ entity.id } className={ styles.tooltip }>
        {renderSenderContent}
        {renderCommentsContent}
        {renderHistoryChanges}
        <div className={ styles.resolution }>
          <Text type='NORMAL_14' color='white'>
            <span className={ styles.border_bottom_white }>{`${formattedDate} ${ApproverName}: `}</span>
          </Text>
          <Text type='bold_14' color='white'>{getResolvedValue(Resolution)}</Text>
        </div>
      </div>
    );
  };

  const renderTooltipContent = () => Approves.map((entity, index) => renderApprovalEntity(entity, index));

  return (
    <Tooltip renderContent={ renderTooltipContent } position='right'>
      <Text type='NORMAL_14' className={ styles.border_bottom }>
        {FIELD_APPROVAL_REQUEST.APPROVAL_HISTORY}
      </Text>
    </Tooltip>
  );
};

export { ApprovalHistoryPopUp };
