import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  Button,
  Checkbox,
  Dialog,
  DotLoading,
  IconButton,
  Input,
  LinkButton,
  Text,
  Tooltip,
  BackLink,
  Select,
  Icon,
  NoResults,
} from 'new-ui';

import { MainAnalytic } from '../../bi/utils/analytics';
import { getEmployeeFullName } from '../../bi/utils/employees';

import { getText, getTextArray } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import ApprovalSchemesService from '../../bi/services/approvalSchemes';

import { POPUP_GROUP_TYPES, POPUP_POSITIONS } from '../../bi/constants/popups';

import { useSchemes } from '../../bi/services/applicationScheme/hooks';

import {
  APPROVAL_SCHEME_STEP_FIELDS,
  APPROVAL_SCHEME_TYPES,
  APPROVAL_STEP_DRAGGABLE_ITEM_TYPES,
  APPROVAL_TYPES,
  DEFAULT_APPROVAL_SCHEME_FORM_PRE_STEP,
  DEFAULT_APPROVAL_SCHEME_FORM_STEP,
  StatusScheme,
} from '../../bi/constants/approvalSchemes';
import TEXTS from '../../bi/constants/texts';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import WorkspaceSettings from '../../bi/services/workspace';
import SidePanelService from '../../bi/services/sidePanel';
import DepartmentsService from '../../bi/services/departments';

import toDecline from '../../bi/utils/toDecline';

import { DraggableItem } from '../../components/DraggableItem';
import { EmployeeCloneItem } from '../../components/EmployeeCloneItem';
import { AddButton } from '../../components/AddButton';
import { ApprovalSchemeStep } from '../../components/ApproveSchemeCommon/approvalSchemeStep';

import { ApprovalPurchaseStep } from './components/ApprovalSchemeStep';
import { ApprovalSchemeFAQ } from './components/FAQ';
import { Questionnaire } from './components/Questionnaire';

import styles from './styles/index.module.css';

const LABELS = {
  APPLICATION_SCHEME_SELECT_LABEL: getText('applicationScheme:approvalSchemePageHeader'),
  APPLICATION_SCHEME_SELECT_TOOLTIP: getText('applicationScheme:selectApplicationSchemeTooltip'),
  APPLICATION_SCHEME_SELECT_NOT_SELECTED: getText('applicationScheme:select.labelNotAllowed'),
  HOW_IT_WORKS: getText('approvalScheme:howItWorks'),
  SCHEME_CREATION: getText('approvalScheme:schemeCreation'),
  EMPLOYEE_SEARCH: getText('approvalScheme:employeeSearch'),
  ADD_STEP: getText('approvalScheme:addStep'),
  APPLY_FOR_ALL: getText('approvalScheme:applyForAll'),
  SUBMIT: getText('common:save'),
  APPLY_SCHEME: getText('approvalScheme:applyScheme'),
  CANCEL: getText('common:undo'),
  SCHEME_NAME: getText('approvalScheme:schemeName'),
  DROP_CART: getText('approvalScheme:dropCart'),
  FORM_NOT_VALID: getText('approvalScheme:formNotValid'),
  EMPLOYEE_DECLINES: getTextArray('approvalScheme:employeeDeclines'),
  ALL_EMPLOYEES: getTextArray('approvalScheme:allEmployees'),
  ADMIN_DECLINES: getTextArray('approvalScheme:adminDeclines'),
  ERROR_DIALOG: {
    EDIT: {
      TITLE: getText('approvalScheme:errorDialog.edit.title'),
      TEXT: getText('approvalScheme:errorDialog.edit.text'),
    },
    SAVE: {
      TITLE: getText('approvalScheme:errorDialog.save.title'),
      TEXT: getText('approvalScheme:errorDialog.save.text'),
    },
  },
  POPUP: {
    TITLE: getText('approvalScheme:popup.title'),
    DESCRIPTION: getText('approvalScheme:popup.description'),
  },
  TRAVEL_APPROVALS: {
    TITLE: getText('approvalScheme:travelApprovals.title'),
    SUBTITLE: getText('approvalScheme:travelApprovals.subTitle'),
  },
  APPROVALS: {
    TITLE: getText('approvalScheme:approvals.title'),
    SUBTITLE: getText('approvalScheme:approvals.subTitle'),
  },
  SUBMIT_DIALOG: {
    ONE_TEXT: {
      ONE: getText('approvalScheme:submitDialog.oneText.one'),
      TWO: getText('approvalScheme:submitDialog.oneText.two'),
      THREE: getText('approvalScheme:submitDialog.oneText.three'),
    },
    TWO_TEXT: {
      ONE: getText('approvalScheme:submitDialog.twoText.one'),
      TWO: getText('approvalScheme:submitDialog.twoText.two'),
    },
    THREE: getText('approvalScheme:submitDialog.threeText'),
  },
  HINT: {
    SHOW: getText('approvalScheme:hint.show'),
    NOT_SHOW: getText('approvalScheme:hint.notShow'),
  },
  EMPLOYEE_LIST: {
    TITLE: getText('approvalScheme:employeeList.title'),
    BUTTON: getText('approvalScheme:employeeList.button'),
  },
  EMPTY_DEPARTMENTS_NOTIFICATION: {
    TEXT: getText('approvalScheme:emptyDepartmentNotification.text'),
    LINK: getText('approvalScheme:emptyDepartmentNotification.link'),
  },
};

const ROLES_TAB = '1.2';
const ANIMATION_TIMEOUT = 1200;

interface IApprovalSchemePage {
  approvalSchemesService: ApprovalSchemesService,
  workspaceService: WorkspaceSettings,
  sidePanelService: SidePanelService,
  popupsService: any,
  departmentsService: DepartmentsService,
}

const ApprovalSchemePage = observer(({
  approvalSchemesService,
  workspaceService,
  sidePanelService,
  popupsService,
  departmentsService,
}: IApprovalSchemePage) => {
  const {
    approvalSchemesStore,
    settingSchemeStore,
  } = useStores([MOBX_STORES.APPROVAL_SCHEMES, MOBX_STORES.SETTING_SCHEME]);
  const inputRef = useRef(null);

  const [searchValue, setSearchValue] = useState('');
  const [draggableItem, setDraggableItem] = useState({
    id: null,
    type: '',
  });
  const [hasEmptyDepartments, setEmptyDepartments] = useState(false);
  const [notifyUser, setNotifyUser] = useState(true);
  const [removableItem, setRemovableItem] = useState({
    cb: () => {},
    show: false,
    showPanel: false,
  });
  const [showSubmitDialog, setSHowSubmitDialog] = useState(false);
  const [animateHint, setAnimateHint] = useState(false);
  const [errorDialog, setErrorDialog] = useState({
    show: false,
    code: null,
  });
  const [applicationSchemeChanged, setApplicationSchemeChanged] = useState(false);

  const { isLoading: schemeIsLoading, data: applicationSchemes } = useSchemes();

  const history = useHistory();

  const { isDemo } = workspaceService;

  const {
    form,
    isFormValid,
    form: {
      Id: isEdit,
      Name,
      ApplyForAll,
      Steps,
      PreSteps,
      Employees,
      Roles,
      TemplateRequestId,
      Utils: {
        admins: {
          value: admins,
          loading: aLoading,
        },
        submitLoading,
      },
      Roles: {
        loading: rLoading,
        value: rValue,
      },
      Employees: {
        loading: eLoading,
        value: eValue,
      },
    },
    status: settingStatus,
    employeesCount,
  } = approvalSchemesStore;

  const {
    isApprovalUnavailable,
    isTravelApprovalUnavailable,
    loadings,
  } = settingSchemeStore;

  const {
    updateForm,
    submitForm,
    updateFaq,
    updateWhoBuys,
    updateTravelApproval,
    updateApproval,
    updateStatusFromCreate,
    updateStatusToCreate,
    loadEmployeesCount,
  } = approvalSchemesService;

  const { loadApprovers, loadScheme, loadRoles } = loadings;

  useEffect(() => {
    approvalSchemesService.loadRoles();
    approvalSchemesService.loadNewApprovers();
    departmentsService.justLoadList()
      .then((organizations: any) => {
        organizations.forEach(({ Departments }: { Departments: any }) => {
          const departmentsWithoutHeads = Departments.some(({ HeadOfDepartmentId }: { HeadOfDepartmentId: any }) =>
            !HeadOfDepartmentId);

          if (departmentsWithoutHeads) setEmptyDepartments(true);
        });
      });

    return approvalSchemesService.clearStoresExceptList;
  }, [approvalSchemesService, popupsService, workspaceService]);

  const errorDialogContent = (header: string, text: string, qaAttrErr: string) => (
    <div className={ styles.error }>
      <Text
        className={ styles.header }
        type='bold_20'
      >
        { header }
      </Text>
      <Text qaAttr={ qaAttrErr }>
        { text }
      </Text>
    </div>
  );

  const prepareErrorDialogContent = (code: any) => {
    const { ERROR_DIALOG: { SAVE, EDIT } } = LABELS;
    const qaAttrErrSave = QA_ATTRIBUTES.approvalSchemes.trips.error.save;
    const qaAttrErrEdit = QA_ATTRIBUTES.approvalSchemes.trips.error.edit;

    switch (code) {
      case 409:
        return errorDialogContent(SAVE.TITLE, SAVE.TEXT, qaAttrErrSave);
      case 424:
        return errorDialogContent(EDIT.TITLE, EDIT.TEXT, qaAttrErrEdit);
      default:
        return <NoResults label={ TEXTS.SOMETHING_WENT_WRONG } />;
    }
  };

  const handleSetSubmitDialog = (value: boolean) => setSHowSubmitDialog(value);

  const handleClickAddApproverPanel = () => {
    if (!animateHint) {
      setAnimateHint(true);
      setTimeout(() => setAnimateHint(false), ANIMATION_TIMEOUT);
    }
  };

  const handleGoBack = () => history.push(ROUTES.SETTINGS.APPROVAL_SCHEMES);

  const handleGoEmployees = () => history.push(ROUTES.SETTINGS.EMPLOYEES);

  const handleChangeForm = (payload: any) => updateForm({ ...form, ...payload });

  const handleSelectAllEmployees = (value: boolean) => {
    handleChangeForm({ [APPROVAL_SCHEME_STEP_FIELDS.APPLY_FOR_ALL]: value });
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.COST_SCHEME_CHECKBOX);
  };

  const userNotification = (payload: any) => {
    if (!payload) {
      const stepsIncludesHeadDepartments = Steps.some(({ Roles: roles }) => !roles.includes(1));
      const preStepsIncludesHeadDepartments = PreSteps.some(({ Roles: roles }) => !roles.includes(1));

      if (hasEmptyDepartments && (stepsIncludesHeadDepartments || preStepsIncludesHeadDepartments)) return null;
    }

    const roles = payload?.Roles;

    if (hasEmptyDepartments && (!payload || (roles.length && roles.includes(1) && notifyUser))) {
      const description =
        <div className={ styles.link }>
          {`${LABELS.EMPTY_DEPARTMENTS_NOTIFICATION.TEXT} \t`}
          <Link to={ '/settings/departments' } target='_blank'>
            {LABELS.EMPTY_DEPARTMENTS_NOTIFICATION.LINK}
          </Link>
        </div>;
      popupsService.addPopup({
        description,
        cancel: { action: () => {}, label: '' },
        submit: {},
        position: POPUP_POSITIONS.TOP_RIGHT,
        group: POPUP_GROUP_TYPES.DEFAULT,
        show: true,
      });
      setNotifyUser(false);
    }

    return null;
  };

  const handleStepChange = (payload: any, index: number) => {
    userNotification(payload);
    const newSteps = [...Steps];
    newSteps[index] = { ...payload };

    handleChangeForm({ Steps: newSteps });
  };

  const handlePreStepChange = (payload: any, index: number) => {
    userNotification(payload);
    const newPreSteps = [...PreSteps];
    newPreSteps[index] = { ...payload };

    handleChangeForm({ PreSteps: newPreSteps });
  };

  const handleSetRemovableApprover = (value: any) => setRemovableItem({ ...removableItem, ...value });

  const handleAddStep = () => {
    handleChangeForm({
      Steps: [...Steps, { ...DEFAULT_APPROVAL_SCHEME_FORM_STEP, Type: APPROVAL_SCHEME_TYPES.EVERY.value }],
    });
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.COST_SCHEME_ADD_STEP);
  };

  const handleAddPreStep = () =>
    handleChangeForm({ PreSteps: [...PreSteps, { ...DEFAULT_APPROVAL_SCHEME_FORM_PRE_STEP }] });

  const handleRemoveStep = (i: number) => {
    const newSteps = [...Steps.slice(0, i), ...Steps.slice(i + 1)];

    handleChangeForm({ Steps: newSteps });
  };

  const handleRemovePreStep = (item: any) => {
    const newPreSteps = [...PreSteps.slice(0, item), ...PreSteps.slice(item + 1)];

    handleChangeForm({ PreSteps: newPreSteps });
  };

  const handleDragStart = (id: any, type: string) => setDraggableItem({ id, type });

  const handleDragEnd = () => setDraggableItem({ id: null, type: '' });

  const handleSetErrorDialog = (value: any) => setErrorDialog({ ...errorDialog, ...value });

  const handleSubmitForm = (needCheck = true) => {
    if (applicationSchemeChanged) {
      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_CHANGED_IN_APPROVAL_SCHEME_SAVE_BUTTON);
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_CHANGED_IN_APPROVAL_SCHEME_SAVE_BUTTON, {
        schemeSource: isEdit ? 'edit' : 'new',
      });
    } else {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.COST_SCHEME_SAVE_BUTTON);
    }

    if (ApplyForAll && needCheck) {
      loadEmployeesCount();
      handleSetSubmitDialog(true);

      return;
    }

    userNotification(null);

    submitForm()
      .then(
        handleGoBack,
        ({ status }: { status: number }) => handleSetErrorDialog({ code: status, show: true }),
      );
  };

  const renderFaq = () => {
    const { faq: { id: faqId } } = approvalSchemesStore;

    return (
      <ApprovalSchemeFAQ
        openedTab={ faqId as string }
      />
    );
  };

  const handleSetFaqDialog = () => {
    updateFaq({ id: null });
    sidePanelService.setShow(true);
    sidePanelService.setRenderFn(renderFaq);
  };

  const handleChangeFaqTab = (id: number | string) => {
    updateFaq({ id });
    sidePanelService.setShow(true);
    sidePanelService.setRenderFn(renderFaq);
  };

  const renderItemRole = ({ Id, Name: rName }: any) => {
    const qaAttrRoleId = `${QA_ATTRIBUTES.approvalSchemes.trips.role}-${Id}`;

    return (
      <div key={ Id } className={ styles.item }>
        <DraggableItem
          isRed
          text={ rName }
          onDragStart={ () => handleDragStart(Id, APPROVAL_STEP_DRAGGABLE_ITEM_TYPES.ROLE) }
          onDragEnd={ handleDragEnd }
          qaAttr={ qaAttrRoleId }
        />
        <IconButton
          className={ styles.icon }
          onClick={ () => handleChangeFaqTab(ROLES_TAB) }
          iconType='question'
        />
      </div>
    );
  };

  const renderRoles = () => {
    if (rLoading && !eLoading) {
      return (
        <div className={ styles.loading }>
          <DotLoading />
        </div>
      );
    }

    if (!rValue) {
      return null;
    }

    return (
      <div className={ styles.roles }>
        { rValue.map(renderItemRole) }
      </div>
    );
  };

  const renderEmployeesList = () => {
    if (eLoading) {
      return (
        <div className={ styles.loading }>
          <DotLoading />
        </div>
      );
    }

    if (!eValue.length) {
      return (
        <div className={ styles['empty-employees'] }>
          <Text>
            { LABELS.EMPLOYEE_LIST.TITLE }
          </Text>
          &nbsp;
          <LinkButton
            theme='blue-without-border'
            onClick={ handleGoEmployees }
          >
            { LABELS.EMPLOYEE_LIST.BUTTON }
          </LinkButton>
        </div>
      );
    }

    const listContent = eValue.reduce((r, e) => {
      const { UserId } = e;
      const trimmedInput = searchValue.trim().toLowerCase();

      if (!trimmedInput ||
        (trimmedInput && getEmployeeFullName(e).toLowerCase().includes(trimmedInput))) {
        return [...r, (
          <DraggableItem
            key={ UserId }
            onDragStart={ () => handleDragStart(UserId, APPROVAL_STEP_DRAGGABLE_ITEM_TYPES.EMPLOYEE) }
            onDragEnd={ handleDragEnd }
            renderContent={ () => <EmployeeCloneItem value={ e } /> }
          />
        )];
      }

      return r;
    }, []);

    return (
      <div className={ styles.employees }>
        <div className={ styles.search }>
          <Input
            isCleansing
            theme='light-shadow'
            className={ styles.input }
            value={ searchValue }
            onChange={ setSearchValue }
            placeholder={ LABELS.EMPLOYEE_SEARCH }
          />
        </div>
        { listContent }
      </div>
    );
  };

  const renderApprovalSteps = () => {
    if (isApprovalUnavailable) return null;

    const listContent = Steps.map((s, i) => (
      <ApprovalPurchaseStep
        key={ `approval_${i}` }
        ind={ i + 1 }
        value={ s }
        employees={ Employees }
        roles={ Roles }
        draggableItem={ draggableItem }
        renderEmployee={ (e: any) => <EmployeeCloneItem value={ e } /> }
        onChange={ (value: any) => handleStepChange(value, i) }
        onRemove={ () => handleRemoveStep(i) }
        onClickAddApprover={ handleClickAddApproverPanel }
        onDragRemovableApprover={ (cb: any) => handleSetRemovableApprover({ cb, show: true }) }
        onDragEndRemovableApprover={ () => handleSetRemovableApprover({ show: false, showPanel: false }) }
      />
    ));

    return (
      <div className={ styles.steps }>
        <Text type='bold_18'>
          { LABELS.APPROVALS.TITLE }
        </Text>
        <Text type='NORMAL_14_130' color='gray' className={ styles.subtitle }>
          { LABELS.APPROVALS.SUBTITLE }
        </Text>
        { listContent }
        <AddButton
          className={ styles.add }
          text={ LABELS.ADD_STEP }
          onClick={ handleAddStep }
          qaAttr={ QA_ATTRIBUTES.approvalSchemes.trips.addStep }
        />
      </div>
    );
  };

  const renderTravelApprovalSteps = () => {
    if (isTravelApprovalUnavailable) return null;

    const listContent = PreSteps.map((step, i) => (
      <ApprovalSchemeStep
        field={ APPROVAL_TYPES.TRIP }
        key={ `travel_approval_${i}` }
        ind={ i + 1 }
        value={ step }
        employees={ Employees }
        roles={ Roles }
        draggableItem={ draggableItem }
        renderEmployee={ (e: any) => <EmployeeCloneItem value={ e } /> }
        onChange={ (value: any) => handlePreStepChange(value, i) }
        onRemove={ () => handleRemovePreStep(i) }
        onClickAddApprover={ handleClickAddApproverPanel }
        onDragRemovableApprover={ (cb: any) => handleSetRemovableApprover({ cb, show: true }) }
        onDragEndRemovableApprover={ () => handleSetRemovableApprover({ show: false, showPanel: false }) }
      />
    ));

    const borderHtml = !isApprovalUnavailable && <div className={ styles.border } />;

    return (
      <div className={ styles.travel_approval_steps }>
        <Text type='bold_18'>
          { LABELS.TRAVEL_APPROVALS.TITLE }
        </Text>
        <Text type='NORMAL_14_130' color='gray' className={ styles.subtitle }>
          { LABELS.TRAVEL_APPROVALS.SUBTITLE }
        </Text>
        { listContent }
        <AddButton
          className={ styles.add }
          text={ LABELS.ADD_STEP }
          onClick={ handleAddPreStep }
        />
        { borderHtml }
      </div>
    );
  };

  const isEnabledButton = isFormValid ? QA_ATTRIBUTES.approvalSchemes.trips.buttonSave : '';

  const renderBottomPanel = () => {
    const submitContent = (
      <Tooltip
        show={ isDemo || !isFormValid }
        renderContent={ () => (
          <Text
            className={ styles.tooltip }
            type='NORMAL_14_130'
            color='white'
          >
            {
              !isFormValid
                ? LABELS.FORM_NOT_VALID
                : TEXTS.NOT_FOR_DEMO
            }
          </Text>
        ) }
      >
        <Button
          type='secondary'
          loading={ submitLoading }
          disabled={ !isFormValid || isDemo }
          onClick={ () => handleSubmitForm(true) }
          qaAttr={ isEnabledButton }
        >
          { LABELS.SUBMIT }
        </Button>
      </Tooltip>
    );

    const checkboxContent = (
      <Checkbox
        className={ styles.checkbox }
        onChange={ value => handleSelectAllEmployees(value) }
        value={ ApplyForAll }
      >
        { LABELS.APPLY_FOR_ALL }
      </Checkbox>
    );

    return (
      <div className={ styles.bottom }>
        <div className={ styles.actions }>
          { submitContent }
          { checkboxContent }
        </div>
      </div>
    );
  };

  const renderSubmitDialog = () => {
    const employeesText = employeesCount &&
      `${employeesCount} ${toDecline(employeesCount, LABELS.EMPLOYEE_DECLINES)}`;
    const adminsText = admins && `${toDecline(admins.length, LABELS.ADMIN_DECLINES)} (${admins.map(({ Email }) => Email).join(', ')})`;

    return (
      <Dialog
        show={ showSubmitDialog }
        onChange={ () => handleSetSubmitDialog(false) }
      >
        <div className={ styles.dialog }>
          <div className={ styles.texts }>
            <Text type='NORMAL_16_130'>
              {LABELS.SUBMIT_DIALOG.ONE_TEXT.ONE}
              <b>{ Name }</b>
              {LABELS.SUBMIT_DIALOG.ONE_TEXT.TWO}
              <b>{ employeesText }</b>
              {LABELS.SUBMIT_DIALOG.ONE_TEXT.THREE}
            </Text>
            <Text type='NORMAL_16_130'>
              {LABELS.SUBMIT_DIALOG.TWO_TEXT.ONE}
              <b>{ adminsText }</b>
              {LABELS.SUBMIT_DIALOG.TWO_TEXT.TWO}
            </Text>
            <br/>
            <Text type='NORMAL_16_130'>
              {LABELS.SUBMIT_DIALOG.THREE}
            </Text>
          </div>
          <div className={ styles.actions }>
            <Button
              loading={ aLoading }
              type='secondary'
              onClick={ () => {
                handleSubmitForm(false);
                handleSetSubmitDialog(false);
              } }
            >
              { LABELS.APPLY_SCHEME }
            </Button>
            <LinkButton
              theme='blue-without-border'
              className={ styles.cancel }
              onClick={ () => handleSetSubmitDialog(false) }
            >
              <Text
                type='NORMAL_14'
                color='accent'
              >
                { LABELS.CANCEL }
              </Text>
            </LinkButton>
          </div>
        </div>
      </Dialog>
    );
  };

  const renderErrorDialog = () => {
    const { show, code } = errorDialog;

    return (
      <Dialog
        show={ show }
        showClosing
        onChange={ () => handleSetErrorDialog({ show: false, code: null }) }
      >
        <div className={ styles.dialog }>
          { prepareErrorDialogContent(code) }
        </div>
      </Dialog>
    );
  };

  const renderSidePanel = () => {
    const { show, cb, showPanel } = removableItem;

    const hintClassNames = [styles.info];

    const sideClassNames = [styles.side];

    if (animateHint) {
      hintClassNames.push(styles.animated);
    }

    if (show) {
      sideClassNames.push(styles.deletable);
    }

    const containerDraggableProps = show && !showPanel ? {
      onDragEnter: () => handleSetRemovableApprover({ showPanel: true }),
      onDragOver: (e: any) => e.preventDefault(),
    } : {};

    const droppablePanelDraggableProps = {
      onDragLeave: () => handleSetRemovableApprover({ showPanel: false }),
      onDragOver: (e: any) => e.preventDefault(),
      onDrop: () => {
        cb();
        handleSetRemovableApprover({ show: false, showPanel: false });
      },
    };

    const droppablePanel = showPanel && (
      <div
        className={ styles.droppable }
        { ...droppablePanelDraggableProps }
      >
        <Text type='normal_20'>
          { LABELS.DROP_CART }
        </Text>
      </div>
    );

    const hintContent = show ? LABELS.HINT.SHOW : LABELS.HINT.NOT_SHOW;

    return (
      <div className={ styles['side-wrapper'] }>
        <div
          className={ sideClassNames.join(' ') }
          { ...(() => (showPanel ? {} : containerDraggableProps))() }
        >
          <Text
            type='NORMAL_14_130'
            color='gray'
            className={ hintClassNames.join(' ') }
          >
            { hintContent }
          </Text>
          { renderRoles() }
          { renderEmployeesList() }
          { droppablePanel }
        </div>
      </div>
    );
  };

  const header = Name || LABELS.SCHEME_CREATION;

  const applicationSchemeSelect = () => {
    const mappedSchemes = schemeIsLoading ? [] : applicationSchemes.map(({ Name: label, Id: value }: { Name: string, Id: number }) => ({ label, value }));
    mappedSchemes.push({ label: LABELS.APPLICATION_SCHEME_SELECT_NOT_SELECTED, value: null });

    const handleChangeSelect = (Id: string | number) => {
      setApplicationSchemeChanged(true);

      handleChangeForm({ TemplateRequestId: Id });
    };

    return PreSteps.length ? (
      <div className={ styles['application-scheme'] }>
        <div className={ styles['application-scheme-label'] }>
          <Text
            type='NORMAL_14_130'
          >
            { LABELS.APPLICATION_SCHEME_SELECT_LABEL }
          </Text>
          <Tooltip
            className={ styles.info }
            renderContent={ () => (
              <Text type='NORMAL_14_130' color='white' className={ styles['tooltip-wrapper'] }>
                { LABELS.APPLICATION_SCHEME_SELECT_TOOLTIP }
              </Text>
            ) }
          >
            <Icon type='question' />
          </Tooltip>
        </div>
        <Select
          value={ TemplateRequestId }
          loading={ schemeIsLoading }
          className={ styles.select }
          items={ mappedSchemes }
          theme='default-large'
          onChange={ handleChangeSelect }
          placeholder={ LABELS.APPLICATION_SCHEME_SELECT_NOT_SELECTED }
        />
      </div>
    ) : null;
  };

  const renderContent = () => (settingStatus === StatusScheme.EDIT ? (
    <>
      { applicationSchemeSelect() }
      <Input
        ref={ inputRef }
        className={ styles.name }
        value={ Name }
        placeholder={ LABELS.SCHEME_NAME }
        onChange={ value => handleChangeForm({ Name: value }) }
        qaAttr={ QA_ATTRIBUTES.approvalSchemes.trips.inputSchemeName }
      />
      <div className={ styles.form }>
        <div>
          { renderTravelApprovalSteps() }
          { renderApprovalSteps() }
        </div>
        { renderSidePanel() }
        { renderBottomPanel() }
      </div>
    </>
  ) : (
    <Questionnaire
      status={ settingStatus }
      onChangeWhoBuys={ updateWhoBuys }
      onChangeTravelApproval={ updateTravelApproval }
      onChangeApproval={ updateApproval }
      onContinue={ updateStatusFromCreate }
      onSettingEmployees={ handleGoEmployees }
      onBack={ updateStatusToCreate }
    />
  ));

  if (loadApprovers || loadScheme || loadRoles) {
    return <div className={ styles.loading_page }>
      <DotLoading />
    </div>;
  }

  return (
    <div className={ styles.wrapper }>
      <BackLink link={ ROUTES.SETTINGS.APPROVAL_SCHEMES } />
      <div className={ styles.content }>
        <Text type='bold_32' >
          { header }
        </Text>
        <LinkButton
          theme='blue-without-border'
          className={ styles['how-to'] }
          onClick={ handleSetFaqDialog }
        >
          { LABELS.HOW_IT_WORKS }
        </LinkButton>
        { renderContent() }
      </div>
      { renderSubmitDialog() }
      { renderErrorDialog() }
    </div>
  );
});

export { ApprovalSchemePage };
