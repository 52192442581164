import React, { useEffect, useState, useCallback } from 'react';
import { Dialog, Text, Input, Button, Tooltip, Icon, Checkbox } from 'new-ui';
import { getText } from '../../../../../i18n';

import { EmployeeSuggest } from '../../../../components/EmployeeSuggest';
import { ClearButton } from '../../../../components/ClearButton';

import { ONLYANYHARS } from '../../../../bi/constants/regExp';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { IProjects } from '../../../../bi/types/employees';
import { EmployeeType } from '../../../../bi/services/employee/consts';

import styles from './styles.module.css';

const LABELS = {
  CANCEL: getText('common:undo'),
  SAVE: getText('common:save'),
  SELECT_MANAGER: getText('analytics:projectDialog.selectManager'),
  ENTER_THE_TITLE: getText('analytics:projectDialog.enterTheTitle'),
  APPROVE_TOOLTOP: getText('analytics:projectDialog.approveTooltip'),
  ARCHIEVE_TOOLTOP: getText('analytics:projectDialog.archiveTooltip'),
  ARCHIEVE: getText('analytics:projectDialog.archive'),
  SET_NAME: getText('analytics:projectDialog.enterTheTitle'),
};

const FIELDS = {
  IS_ARCHIVAL: 'IsArchival',
  NAME: 'Name',
};

type TValue = string | boolean | null;

interface IProjectDialogProps {
  onChangeField(field: string, value: TValue): void;
  onChange(value: boolean): void;
  onFetch: (value: string) => Promise<EmployeeType[]>;
  onSelect: (employee: Partial<EmployeeType> | null) => void;
  onSave(): void;
  project: IProjects | null;
  employee: EmployeeType | null;
  initialValue: string;
}

const ProjectDialog = ({
  onChangeField,
  onChange,
  onFetch,
  onSelect,
  onSave,
  project = null,
  employee = null,
  initialValue = '',
}: IProjectDialogProps) => {
  const name: string = (project?.Name ?? initialValue) || '';
  const isArchival: boolean = project?.IsArchival || false;
  const isDisabledForSave: boolean = !ONLYANYHARS.test(name);
  const [validationMessageName, setValidationMessageName] = useState<string>('');
  const [isEditName, setIsEditName] = useState<boolean>(false);

  useEffect(() => {
    if (!project?.Name) {
      onChangeField(FIELDS.NAME, initialValue);
    }
  }, [initialValue]);

  const getValidateMessageName =
    useCallback(
      () => ((isDisabledForSave && isEditName) ? LABELS.SET_NAME : ''),
      [isDisabledForSave, isEditName],
    );

  useEffect(() => {
    setIsEditName(true);
    setValidationMessageName(getValidateMessageName());
  }, [getValidateMessageName, name]);

  const iconWithTip = (label: string) => (
    <Tooltip
      className={ styles.tip }
      renderContent={ () => (
        <div className={ styles.tip_content }>
          <Text
            color='white'
            type='NORMAL_14_130'
          >
            { label }
          </Text>
        </div>
      ) }
    >
      <Icon type='question'/>
    </Tooltip>
  );

  const archivalPlace = project?.Id && (
    <div className={ styles.project_dialog_manager }>
      <div className={ styles.project_dialog_manager_label }>
        <Checkbox
          value={ isArchival }
          onChange={ value => onChangeField(FIELDS.IS_ARCHIVAL, value) }
        />
        <div className={ styles.archive_text }>
          <Text type='NORMAL_16'>{ LABELS.ARCHIEVE }</Text>
          {iconWithTip(LABELS.ARCHIEVE_TOOLTOP)}
        </div>
      </div>
    </div>
  );

  return (
    <Dialog
      show
      showClosing
      onChange={ onChange }
    >
      <div className={ styles.project_dialog }>
        <div>
          <Text type='NORMAL_16' qaAttr={ QA_ATTRIBUTES.settings.analytics.centerCreate.addWindow }>
            {LABELS.ENTER_THE_TITLE}
          </Text>
          <Input
            className={ styles.project_dialog_input }
            value={ name }
            onChange={ value => onChangeField(FIELDS.NAME, value) }
            error={ validationMessageName }
            qaAttr={ QA_ATTRIBUTES.settings.analytics.centerCreate.inputName }
          />
        </div>
        <div className={ styles.project_dialog_manager }>
          <div className={ styles.project_dialog_manager_label }>
            <Text type='NORMAL_16'>{LABELS.SELECT_MANAGER}</Text>
            {iconWithTip(LABELS.APPROVE_TOOLTOP)}
          </div>
          <div className={ styles.project_dialog_manager_form }>
            <EmployeeSuggest
              canAddEmployee={ false }
              onFetch={ onFetch }
              onSelect={ onSelect }
              value={ employee }
              qaAttrInputEmployee={ QA_ATTRIBUTES.settings.analytics.centerCreate.inputLeader }
              qaAttrFirstEl={ QA_ATTRIBUTES.settings.analytics.centerCreate.firstLeader }
            />
            <ClearButton onClick={ () => { onSelect(null); } } />
          </div>
          { archivalPlace }
        </div>
        <div className={ styles.project_dialog_actions }>
          <Button
            disabled={ isDisabledForSave }
            onClick={ () => { onSave(); } }
            qaAttr={ QA_ATTRIBUTES.settings.analytics.centerCreate.saveButton }
          >
            { LABELS.SAVE }
          </Button>
          <Button
            className={ styles.cancel_button }
            type='textual-medium'
            onClick={ () => onChange(false) }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ProjectDialog;
