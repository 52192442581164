import { getText, getTextArray } from '../../../../i18n';

import toDecline from '../../utils/toDecline';
import MoneyFormat from '../../utils/money';

import { SERVICETYPE } from '../../constants/serviceType';
import {
  TRAVEL_POLICY_CURRENCIES,
  TRAVEL_POLICY_CURRENCIES_LABELS,
  TRAVEL_POLICY_DEFAULT_ROUTE,
  TRAVEL_POLICY_FIELDS_DICTIONARIES,
  TRAVEL_POLICY_GENERAL_FIELDS_TO_BE_RENDERED,
  TRAVEL_POLICY_STORE_FIELDS,
} from './consts';

import { TravelPolicyClass, TravelPolicyRoute } from './types';
import { AirException } from './serviceTypeStores/air/types';

const getTravelPolicyRouteFullName = ({ From, To }: TravelPolicyRoute, serviceType: string) => {
  switch (serviceType) {
    case SERVICETYPE.HOTEL: {
      return To.Name;
    }
    default:
      return `${From.Name} \u21C4 ${To.Name}`;
  }
};

const LABELS = {
  RUB: getText('services:travelPolicy.utils.rub'),
  EXCEPTION_TYPES_HOURS: (hours: number) => (
    `${getText('services:travelPolicy.utils.exceptionTypesHours', { hours })}
     ${toDecline(hours, getTextArray('settings:travelPolicy.rules.exceptions.hoursDeclines'))}`),
  DAY_OFF: {
    AIR: getText('services:travelPolicy.utils.text.dayOff.air'),
    TRAIN: getText('services:travelPolicy.utils.text.dayOff.train'),
    TRANSFER: getText('services:travelPolicy.utils.text.dayOff.transfer'),
    TAXI: getText('services:travelPolicy.utils.text.dayOff.taxi'),
    DEFAULT: getText('services:travelPolicy.utils.text.dayOff.default'),
  },
  ONE_WAY: {
    HOTEL: (value: any, currency: any) => getText('services:travelPolicy.utils.text.oneWay.hotel', { value, currency }),
    TRAIN: (value: any, currency: any) => getText('services:travelPolicy.utils.text.oneWay.train', { value, currency }),
    DEFAULT: (value: any, currency: any, valueBack: any) => getText('services:travelPolicy.utils.text.oneWay.default', { value, currency, valueBack }),
  },
  NOT_GREATER_PRICE: {
    HOTEL: (value: any, type: string) => getText('services:travelPolicy.utils.text.notGreaterPrice.hotel', { value, type }),
    DEFAULT: (value: any, type: string) => getText('services:travelPolicy.utils.text.notGreaterPrice.default', { value, type }),
  },
  DAYS_COUNT: (days: number, decline: string) => getText('services:travelPolicy.utils.text.daysCount', { days, decline }),
  DAYS_DECLINE: getTextArray('services:travelPolicy.utils.daysDeclines'),
  VAT: getText('services:travelPolicy.utils.text.vat'),
  REFUNDABLE: getText('services:travelPolicy.utils.text.refundable'),
  CLASS: {
    AIR: {
      ECONOM: getText('services:travelPolicy.utils.text.class.air.econom'),
      DEFAULT: getText('services:travelPolicy.utils.text.class.air.default'),
    },
    TRAIN: {
      RESERVED_SEATS: getText('services:travelPolicy.utils.text.class.train.reservedSeats'),
      ECONOM: getText('services:travelPolicy.utils.text.class.train.econom'),
      DEFAULT: getText('services:travelPolicy.utils.text.class.train.default'),
    },
    SAPSAN: {
      ECONOM: getText('services:travelPolicy.utils.text.sapsanClass.econom'),
      DEFAULT: getText('services:travelPolicy.utils.text.sapsanClass.default'),
    },
  },
  CLASSES: {
    ECONOMY: getText('services:travelPolicy.utils.text.class.taxi.economy'),
    COMFORT: getText('services:travelPolicy.utils.text.class.taxi.comfort'),
    COMFORT_PLUS: getText('services:travelPolicy.utils.text.class.taxi.comfortPlus'),
    BUSINESS: getText('services:travelPolicy.utils.text.class.taxi.business'),
    MINIVAN: getText('services:travelPolicy.utils.text.class.taxi.minivan'),
    DEFAULT: '',
  },
  INCLUDED_BAGGAGE: getText('services:travelPolicy.utils.text.includedBaggage'),
  PROHIBIT_BOOK: getText('services:travelPolicy.utils.text.prohibitBook'),
  ALLOW_BOOK: getText('services:travelPolicy.utils.text.allowBook'),
};

const NOT_GREATER_PRICE_TYPE_TEXT = {
  [TRAVEL_POLICY_FIELDS_DICTIONARIES.NOT_GREATER_PRICE_TYPE.RUBLES]: LABELS.RUB,
  [TRAVEL_POLICY_FIELDS_DICTIONARIES.NOT_GREATER_PRICE_TYPE.PERCENTS]: '%',
};

const formatMoney = (value: string | number) => MoneyFormat.money(value);

const getTextForServiceType = (name: string, value: any, serviceType: string) => ({
  [`${TRAVEL_POLICY_STORE_FIELDS.DAY_OFF}`]: () => {
    if (serviceType === SERVICETYPE.AIR) {
      return LABELS.DAY_OFF.AIR;
    }

    if (serviceType === SERVICETYPE.TRAIN) {
      return LABELS.DAY_OFF.TRAIN;
    }

    if (serviceType === SERVICETYPE.TAXI) {
      return LABELS.DAY_OFF.TAXI;
    }

    if (serviceType === SERVICETYPE.TRANSFER) {
      return LABELS.DAY_OFF.TRANSFER;
    }

    return LABELS.DAY_OFF.DEFAULT;
  },
  [TRAVEL_POLICY_STORE_FIELDS.ONE_WAY]: () => {
    const { Value, Currency } = value;
    const currency = TRAVEL_POLICY_CURRENCIES_LABELS[Currency || TRAVEL_POLICY_CURRENCIES.RUB];

    if (serviceType === SERVICETYPE.HOTEL) {
      return LABELS.ONE_WAY.HOTEL(formatMoney(Value), currency);
    }

    if (serviceType === SERVICETYPE.TRAIN) {
      return LABELS.ONE_WAY.TRAIN(formatMoney(Value), currency);
    }

    return LABELS.ONE_WAY.DEFAULT(formatMoney(Value), currency, formatMoney(Value * 2));
  },
  [TRAVEL_POLICY_STORE_FIELDS.NOT_GREATER_PRICE]: () => {
    const { Value, ValueType } = value;

    if (serviceType === SERVICETYPE.HOTEL) {
      return LABELS.NOT_GREATER_PRICE.HOTEL(formatMoney(Value), NOT_GREATER_PRICE_TYPE_TEXT[ValueType]);
    }

    return LABELS.NOT_GREATER_PRICE.DEFAULT(formatMoney(Value), NOT_GREATER_PRICE_TYPE_TEXT[ValueType]);
  },
  [TRAVEL_POLICY_STORE_FIELDS.DAYS_COUNT]: () => {
    const { Days } = value;

    return LABELS.DAYS_COUNT(Days, toDecline(Days, LABELS.DAYS_DECLINE));
  },
  [TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE]: () => LABELS.REFUNDABLE,
  [TRAVEL_POLICY_STORE_FIELDS.VAT]: () => LABELS.VAT,
  [TRAVEL_POLICY_STORE_FIELDS.CLASS]: () => {
    const { Value } = value;

    switch (serviceType) {
      case SERVICETYPE.AIR:
        if (Value === TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.ECONOM) {
          return LABELS.CLASS.AIR.ECONOM;
        }

        return LABELS.CLASS.AIR.DEFAULT;

      case SERVICETYPE.TRAIN:
        if (Value === TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.ECONOM) {
          return LABELS.CLASS.TRAIN.ECONOM;
        }

        if (Value === TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.RESERVED_SEATS) {
          return LABELS.CLASS.TRAIN.RESERVED_SEATS;
        }

        return LABELS.CLASS.TRAIN.DEFAULT;

      default:
        return '';
    }
  },
  [TRAVEL_POLICY_STORE_FIELDS.SAPSAN_CLASS]: () => {
    const { Value } = value;

    if (Value === TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.ECONOM) {
      return LABELS.CLASS.SAPSAN.ECONOM;
    }

    return LABELS.CLASS.SAPSAN.DEFAULT;
  },
  [TRAVEL_POLICY_STORE_FIELDS.CLASSES as unknown as string]: () => {
    const { Value } = value;

    if (Value === TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.ECONOMY) {
      return LABELS.CLASSES.ECONOMY;
    }

    if (Value === TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.COMFORT) {
      return LABELS.CLASSES.COMFORT;
    }

    if (Value === TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.COMFORT_PLUS) {
      return LABELS.CLASSES.COMFORT_PLUS;
    }

    if (Value === TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.BUSINESS) {
      return LABELS.CLASSES.BUSINESS;
    }

    if (Value === TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.MINIVAN) {
      return LABELS.CLASSES.MINIVAN;
    }

    return LABELS.CLASSES.DEFAULT;
  },
  [TRAVEL_POLICY_STORE_FIELDS.INCLUDED_BAGGAGE]: () => LABELS.INCLUDED_BAGGAGE,
  [TRAVEL_POLICY_STORE_FIELDS.PROHIBIT_BOOK]: () => LABELS.PROHIBIT_BOOK,
  [TRAVEL_POLICY_STORE_FIELDS.ALLOW_BOOK]: () => LABELS.ALLOW_BOOK,
})[name]();

const getRulesTexts = <Rules extends object>(rules: Rules, type: string) => {
  const texts: string[] = [];

  TRAVEL_POLICY_GENERAL_FIELDS_TO_BE_RENDERED[type].forEach((f) => {
    const value = rules[f as keyof Rules] as TravelPolicyClass;

    if (value.IsEnabled) {
      texts.push(getTextForServiceType(f, value, type));
    }
  });

  return texts;
};

type GetTypesTextsArg = Pick<AirException, 'Type' | 'Routes' | 'Hours'>;

const getTypesTexts = ({ Type, Routes, Hours }: GetTypesTextsArg) => {
  if (Type === TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.HOURS) {
    return [LABELS.EXCEPTION_TYPES_HOURS(Hours)];
  }

  return Routes.reduce<string[]>((result, { fullName, isValid, isEmpty }) => {
    const newResult = [...result];

    if (isValid && !isEmpty) {
      newResult.push(fullName);
    }

    return newResult;
  }, []);
};

const getTravelPolicyExceptionFullName = (value: any, serviceType: string) => {
  const { Routes, Type, Hours, ...rest } = value;

  const typeText = getTypesTexts({ Routes, Type, Hours });
  const rulesTexts = getRulesTexts(rest, serviceType);

  return { typeText: typeText.join(', '), restrictions: rulesTexts.join('; ') };
};

const isRouteValid = ({ From, To }: TravelPolicyRoute, serviceType: string, length: number) => {
  switch (serviceType) {
    case SERVICETYPE.AIR:
    case SERVICETYPE.TRAIN:
      return (To.Name || From.Name) || length === 1
        ? !!To.Name && !!To.Code && To.isSelected && !!From.Name && !!From.Code && From.isSelected
        : true;
    default:
      return To.Name || length === 1
        ? !!To.Name && !!To.Code && To.isSelected
        : true;
  }
};

const isRouteEmpty = ({ From, To }: TravelPolicyRoute, serviceType: string) => {
  switch (serviceType) {
    case SERVICETYPE.AIR:
    case SERVICETYPE.TRAIN:
      return !From.Name.length && !To.Name.length;
    default:
      return !To.Name.length;
  }
};

const filterNewAndRemovedExceptions = (list: any[]): any[] => list.filter(({ isNew, isRemoved }) => !isNew && !isRemoved);

const mapTravelPolicyRoutes = (list: any[]): any[] => (
  list?.length ?
    list.map(({ To, From }) =>
      ({ To: { ...TRAVEL_POLICY_DEFAULT_ROUTE.To, ...To, isSelected: true },
        From: { ...TRAVEL_POLICY_DEFAULT_ROUTE.From, ...From, isSelected: true },
      }))
    : [{ ...TRAVEL_POLICY_DEFAULT_ROUTE }]
);

const mapTravelPolicyExceptions = (list: any[], serviceType: string): any[] =>
  list.map((li) => {
    const Routes = li.Routes.map((ri: any) =>
      ({
        ...ri,
        isValid: isRouteValid(ri, serviceType, li.Routes.length),
        isEmpty: isRouteEmpty(ri, serviceType),
        fullName: getTravelPolicyRouteFullName(ri, serviceType),
      }),
    );

    return {
      ...li,
      Routes,
      fullNameModel: getTravelPolicyExceptionFullName({ ...li, Routes }, serviceType),
    };
  });

const mapTravelPolicyDirections = (list: any[], serviceType: string): any[] =>
  list.map(i =>
    ({
      ...i,
      isValid: isRouteValid(i, serviceType, list.length),
      isEmpty: isRouteEmpty(i, serviceType),
      fullName: getTravelPolicyRouteFullName(i, serviceType),
    }),
  );

export {
  getTravelPolicyRouteFullName,
  getTravelPolicyExceptionFullName,
  isRouteValid,
  isRouteEmpty,
  filterNewAndRemovedExceptions,
  mapTravelPolicyRoutes,
  mapTravelPolicyExceptions,
  mapTravelPolicyDirections,
};
