import React from 'react';
import ReactDOM from 'react-dom/client';

import AppComponent from './App';

import { initSentry } from './utils/sentry';

import './i18n';
import './utils/firebase';

import CONFIG from './config';

initSentry();

import(`./App/styles/themes/${CONFIG.THEME}_theme.css`);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AppComponent/>,
);
