import React from 'react';
import { Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import { timeLabelWithColon, timeLabel } from '../../../../bi/utils/timeLabel';
import { getTrainNameByNumber } from '../../../../bi/utils/train';

import { IFavorite, IQaAttrsFavoritesItem, ITrainItem } from '../../../../bi/types/favorites';

import styles from './style.module.css';

const LABELS = {
  IN_TRAVEL: getText('favorites:tripInProgress'),
};

interface ITrainItemProps {
  favorite: IFavorite;
  qaAttrs: IQaAttrsFavoritesItem;
}

const TrainItem = ({
  favorite: {
    Item,
  },
  qaAttrs: {
    from = '',
    to = '',
    name = '',
  },
}: ITrainItemProps) => {
  const {
    TrainName,
    TrainNumber,
    TrainNumberLocal,
    StationFrom,
    DepartureDate,
    DepartureDateLocal,
    TravelTime,
    StationTo,
    ArrivalDate,
    ArrivalDateLocal,
  } = Item as ITrainItem;

  const trainName = TrainName || getTrainNameByNumber(null, TrainNumber) || '';
  const departureDate = DepartureDateLocal || DepartureDate;
  const arrivalDate = ArrivalDateLocal || ArrivalDate;
  const trainNumber = TrainNumberLocal || TrainNumber;

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.place }>
        <Text type='SEMIBOLD_16' qaAttr={ from }>
          { StationFrom }
        </Text>
        <Text type='NORMAL_14' color='gray' className={ styles.time }>
          { timeLabelWithColon(departureDate.Time) }
        </Text>
      </div>
      <div className={ styles.train }>
        <Text type='NORMAL_14' color='gray' qaAttr={ name }>
          { `${trainNumber} ${trainName ? `(${trainName})` : ''}` }
        </Text>
        <Text type='SEMIBOLD_14' className={ styles.time }>
          { timeLabel(TravelTime) }
        </Text>
        <Text type='NORMAL_14' color='gray'>
          { LABELS.IN_TRAVEL }
        </Text>
      </div>
      <div className={ styles.place }>
        <Text type='SEMIBOLD_16' qaAttr={ to }>
          { StationTo }
        </Text>
        <Text type='NORMAL_14' color='gray' className={ styles.time }>
          { timeLabelWithColon(arrivalDate?.Time) }
        </Text>
      </div>
    </div>
  );
};

export { TrainItem };
