import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Text } from 'new-ui';

import { useServices } from '../../../../../../bi/context/services';

import { EmployeeSuggest } from '../../../../../../components/EmployeeSuggest';

import { getEmployeeFullName } from '../../../../../../bi/utils/employees';

export const EmployeeSelect = observer(() => {
  const {
    workspaceService: { rights },
    signatureService: {
      employeeAutocomplete,
      updateFilter,
      enhancedSignatureStore: { selectedEmployee },
    },
  } = useServices(['Workspace', 'Signature']);

  const [predefinedUser, setPredefinedUser] = useState<number>();

  useEffect(() => {
    if (rights.EmployeeId) {
      setPredefinedUser(rights.EmployeeId);
      updateFilter('employeeId', rights.EmployeeId);
      employeeAutocomplete(String(rights.EmployeeId));
    }
  }, [rights]);

  if (predefinedUser) {
    if (selectedEmployee) {
      return (
        <Text type='NORMAL_18'>
          { getEmployeeFullName(selectedEmployee) }
        </Text>
      );
    }

    return <div />;
  }

  return (
    <div>
      <EmployeeSuggest
        value={ selectedEmployee }
        onSelect={ ({ Id }) => {
          if (Id) updateFilter('employeeId', Id);
        } }
        onFetch={ employeeAutocomplete }
        withFetching
        debounceMs={ 400 }
      />
    </div>
  );
});
