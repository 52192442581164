import React, { useEffect, useRef } from 'react';
import { Text } from 'new-ui';

import { TABS_FAQ, TABS_TEXT_FAQ, TABS_FAQ_FIELD } from '../../../../bi/constants/approvalSchemes';

import styles from './styles/index.module.css';

import OneOneScheme from './styles/images/1.1/scheme.webp';
import OneOneScreen from './styles/images/1.1/screen.png';
import OneTwoScheme from './styles/images/1.2/scheme.webp';
import OneTwoScreen from './styles/images/1.2/screen.png';
import OneThreeScheme from './styles/images/1.3/scheme.webp';
import OneThreeScreen from './styles/images/1.3/screen.png';
import OneFourScheme from './styles/images/1.4/scheme.webp';
import OneFourScreen from './styles/images/1.4/screen.png';
import OneFiveScheme from './styles/images/1.5/scheme.webp';
import OneFiveScreen from './styles/images/1.5/screen.png';
import TwoOne from './styles/images/2/1.png';
import TwoTwo from './styles/images/2/2.png';
import TwoThree from './styles/images/2/3.png';
import ThreeTwo from './styles/images/3/2.png';
import ThreeThree from './styles/images/3/3.png';

type TTabsRefsItem = { [key: string]: HTMLDivElement | null };

const ApprovalSchemeFAQ = ({ openedTab = '' }: { openedTab: string }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const tabsRefs = useRef<TTabsRefsItem>({});

  const onScrollToTab = (id: string) => {
    setTimeout(() => {
      const ref = tabsRefs.current[id];

      if (ref && wrapperRef?.current) {
        wrapperRef.current.scroll({ top: ref.offsetTop - 50, behavior: 'smooth' });
      }
    }, 100);
  };

  useEffect(() => {
    onScrollToTab(openedTab);
  }, [openedTab]);

  const setRef = (id: string, ref: HTMLDivElement | null) => {
    tabsRefs.current[id] = ref;
  };

  const renderTabContent = (tab: string) => {
    switch (tab) {
      case TABS_FAQ_FIELD.ONE:
        return (
          <div className={ styles.tab } ref={ ref => setRef(tab, ref) }>
            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ[TABS_FAQ_FIELD.ONE].ONE}
            </Text>
            <div className={ styles.images }>
              <img src={ OneOneScheme } alt='' />
              <img src={ OneOneScreen } alt='' />
            </div>
          </div>
        );
      case TABS_FAQ_FIELD.TWO:
        return (
          <div className={ styles.tab } ref={ ref => setRef(tab, ref) }>
            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ[TABS_FAQ_FIELD.TWO].ONE}
            </Text>
            <div className={ styles.images }>
              <img src={ OneTwoScheme } alt='' />
              <img src={ OneTwoScreen } alt='' />
            </div>
          </div>
        );
      case TABS_FAQ_FIELD.THREE:
        return (
          <div className={ styles.tab } ref={ ref => setRef(tab, ref) }>
            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ[TABS_FAQ_FIELD.THREE].ONE}
            </Text>
            <div className={ styles.images }>
              <img src={ OneThreeScheme } alt='' />
              <img src={ OneThreeScreen } alt='' />
            </div>
          </div>
        );
      case TABS_FAQ_FIELD.FOUR:
        return (
          <div className={ styles.tab } ref={ ref => setRef(tab, ref) }>
            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ[TABS_FAQ_FIELD.FOUR].ONE}
              <br /> <br />
              {TABS_TEXT_FAQ[TABS_FAQ_FIELD.FOUR].TWO}
            </Text>
            <div className={ styles.images }>
              <img src={ OneFourScheme } alt='' />
              <img src={ OneFourScreen } alt='' />
            </div>
          </div>
        );
      case TABS_FAQ_FIELD.FIVE:
        return (
          <div className={ styles.tab } ref={ ref => setRef(tab, ref) }>
            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ[TABS_FAQ_FIELD.FIVE].ONE}
            </Text>
            <div className={ styles.images }>
              <img src={ OneFiveScheme } alt='' />
              <img src={ OneFiveScreen } alt='' />
            </div>
          </div>
        );
      case '2':
        return (
          <div className={ styles.tab } ref={ ref => setRef(tab, ref) }>
            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ['2'].ONE}
            </Text>
            <div className={ styles['big-img'] } >
              <img src={ TwoOne } alt='' />
            </div>

            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ['2'].TWO}
            </Text>
            <div className={ styles['big-img'] } >
              <img src={ TwoTwo } alt='' />
            </div>
            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ['2'].THREE}
            </Text>

            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ['2'].FOUR}
            </Text>
            <div className={ styles['big-img'] } >
              <img src={ TwoThree } alt='' />
            </div>

            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ['2'].FIVE}
            </Text>
          </div>
        );
      case '3':
        return (
          <div className={ styles.tab } ref={ ref => setRef(tab, ref) }>
            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ['3'].ONE}
            </Text>
            <div className={ styles['big-img'] } >
              <img src={ ThreeTwo } alt='' />
            </div>

            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ['3'].TWO}
            </Text>
            <div className={ styles['big-img'] } >
              <img src={ ThreeThree } alt='' />
            </div>

            <Text type='NORMAL_16_140' className={ styles.text }>
              {TABS_TEXT_FAQ['3'].THREE}
            </Text>
          </div>
        );
    }

    return null;
  };

  const renderTab = (id: string, label?: string) => (
    <div key={ id } className={ styles.content }>
      { label && (
        <Text type='bold_18' className={ styles.header }>{ label }</Text>
      ) }
      { renderTabContent(id) }
    </div>
  );

  return (
    <div className={ styles.wrapper } ref={ wrapperRef }>
      { TABS_FAQ.map(({ id, label, children }) => {
        const textClassNames = [styles.header];

        if (children) {
          textClassNames.push(styles.children);
        }

        return (
          <div key={ id }>
            <Text type={ children ? 'bold_32' : 'bold_18' } className={ textClassNames.join(' ') }>{label}</Text>
            {
              children
                ? children.map(({ id: cId, label: cLabel }) => renderTab(cId, cLabel))
                : renderTab(id)
            }
          </div>
        );
      })}
    </div>
  );
};

export { ApprovalSchemeFAQ };
