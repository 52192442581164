import React from 'react';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import Footer from '../../../../components/Footer';

import ROUTES from '../../../../bi/constants/routes';

import { IFooterSmartagent } from '../../types';

import styles from './styles/index.module.css';

const FooterSmartagent = ({
  chatService,
  productAnalyticsProtocol,
  history,
  getShowElementForSmartagent,
}: IFooterSmartagent) => {
  const {
    uiSettingsStore: {
      settings: {
        copyrightName,
        phone,
        email: companyEmail,
        companyName: mainCompanyName,
      },
    },
  } = useStores([MOBX_STORES.UI_SETTINGS]);

  const handleOpenQuality = () => history.push({
    pathname: ROUTES.QUALITY.GENERAL,
  });

  const handleOpenTravelChat = () => {
    chatService.show();
    productAnalyticsProtocol.clickIntercom();
  };

  return (
    <div className={ styles.wrapper }>
      <Footer
        copyrightName={ copyrightName }
        email={ companyEmail }
        phone={ phone }
        wiki={ ROUTES.SMARTWAY_WIKI }
        companyName={ mainCompanyName }
        handleOpenTravelChat={ handleOpenTravelChat }
        handleOpenQuality={ handleOpenQuality }
        getShowElementForSmartagent={ getShowElementForSmartagent }
      />
    </div>
  );
};

export default FooterSmartagent;
