import Api from '../../api';

import { approvalERSchemesStore } from './store';

import { mapEmployeesListWithNamesakes } from '../../utils/employees';

import EMPLOYEE_STATUSES from '../../constants/employee';
import { APPROVE_EXPENSE_REPORTS } from '../../constants/rights';
import {
  DEFAULT_APPROVAL_SCHEME,
  DEFAULT_APPROVAL_SCHEME_FORM_EXPENSE_REPORTS, StatusScheme,
} from '../../constants/approvalSchemes';

import type { ApprovalERSchemeFAQStore, ApprovalERSchemeFormStore, ApprovalERSchemeStep } from './types';

class ApprovalERSchemesService {
  api: Api;
  store = approvalERSchemesStore;

  constructor(api: Api) {
    this.api = api;
  }

  loadExpenseReportsSchemeList = async () => {
    this.store.updateList({ loading: true, error: false });

    try {
      const res = await this.api.approvalExpenseReports.getERSchemes();

      this.store.updateList({ value: res });
    } catch {
      this.store.updateList({ error: true });
    } finally {
      this.store.updateList({ loading: false });
    }
  };

  updateStatus = (value: StatusScheme) => this.store.setStatus(value);

  setLoading = (value: boolean) => this.store.setLoading(value);

  initForm = (data = {}) => this.store.updateForm({ ...DEFAULT_APPROVAL_SCHEME, ...data } as any);

  removeScheme = (id: number | string) => this.api.approvalExpenseReports.removeScheme(id);

  submitForm = () => {
    const { form: { Employees, Roles, Utils, ...rest } } = this.store;

    this.store.updateForm({ Utils: { ...Utils, submitLoading: true } });

    if (rest.Id) {
      return this.api.approvalExpenseReports.saveSchemePut(rest)
        .finally((scheme: Partial<ApprovalERSchemeFormStore>) => {
          this.store.updateForm({ Utils: { ...Utils, submitLoading: false } });

          return scheme;
        });
    }

    return this.api.approvalExpenseReports.saveSchemeSend(rest)
      .finally((scheme: Partial<ApprovalERSchemeFormStore>) => {
        this.store.updateForm({ Utils: { ...Utils, submitLoading: false } });

        return scheme;
      });
  };

  updateForm = (data: Partial<ApprovalERSchemeFormStore>) => this.store.updateForm(data);

  updateFaq = (data: ApprovalERSchemeFAQStore) => this.store.updateFaq(data);

  loadEmployeesWithRights = () => this.api.employee.getWithRights();

  loadingRoles = async () => {
    this.store.updateForm({ Roles: { loading: true } });

    try {
      const list = await this.api.userSession.getRolesForExpenseReportSchemes();

      this.store.updateForm({
        Roles: {
          ...this.store.form.Roles,
          value: list,
          hash: (list as any[]).reduce<Record<string, any>>((r, ri) => ({ ...r, [ri.Id]: ri }), {}),
        },
      });
    } catch (e: any) {
      this.store.updateForm({ Roles: { ...this.store.form.Roles, error: e } });
    } finally {
      this.store.updateForm({ Roles: { ...this.store.form.Roles, loading: false } });
    }
  };

  createScheme = () => this.store.updateForm({ Steps: [{ ...DEFAULT_APPROVAL_SCHEME_FORM_EXPENSE_REPORTS } as any] });

  loadingApprovers = async (popupCb: (...args: any[]) => void) => {
    const {
      form: {
        Employees,
        Steps,
      },
      updateForm,
    } = this.store;

    updateForm({ Employees: { ...Employees, loading: true } });

    try {
      const list = await this.loadEmployeesWithRights();
      const onlyApprovers = list.filter(({ Rights, Status }: any) =>
        Rights && Rights.ApproveExpenseReports === APPROVE_EXPENSE_REPORTS.Available && Status === EMPLOYEE_STATUSES.ACCESS.USER);
      const approversIdList = (steps: ApprovalERSchemeStep[]) => steps.reduce<string[]>((r, { Approvers }) => [...r, ...Approvers], []);
      const notFoundApprovers = (steps: string[]) => steps.filter(a => !onlyApprovers.find(({ Rights: { UserId } }: any) => String(a) === String(UserId)));

      const approversInSteps = approversIdList(Steps);

      const notFoundApproversFromSteps = notFoundApprovers(approversInSteps);

      if (approversInSteps.length && notFoundApproversFromSteps.length) {
        popupCb(notFoundApproversFromSteps);

        return updateForm({ Employees: { ...Employees, loading: true, error: true } });
      }

      const preparedList = mapEmployeesListWithNamesakes(onlyApprovers);

      const users = (list as any[]).filter(({ Status }) => Status === EMPLOYEE_STATUSES.ACCESS.USER);

      return this.store.updateForm({
        Employees: {
          ...Employees,
          loading: false,
          value: preparedList,
          hash: preparedList.reduce((r, e) => ({ ...r, [e.Rights.UserId]: e }), {}),
          users,
        },
      });
    } catch {
      this.store.updateForm({ Employees: { ...this.store.form.Employees, loading: false, error: true } });

      return await Promise.reject();
    } finally {
      this.setLoading(false);
    }
  };

  loadingAdmins = async () => {
    this.store.updateForm({ Utils: { ...this.store.form.Utils, admins: { ...this.store.form.Utils.admins, loading: true } } });

    try {
      const value = await this.api.userSession.getAccountAdmins();

      this.updateForm({ Utils: { ...this.store.form.Utils, admins: { ...this.store.form.Utils.admins, value } } });
    } catch (e) {
      this.store.updateForm({ Utils: { ...this.store.form.Utils, admins: { ...this.store.form.Utils.admins, error: true } } });
    } finally {
      this.store.updateForm({ Utils: { ...this.store.form.Utils, admins: { ...this.store.form.Utils.admins, loading: false } } });
    }
  };

  clearStoresList = () => this.store.clearExceptList();
}

export default ApprovalERSchemesService;
