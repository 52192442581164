import { TRAVELPOLICYFILTER } from '../constants/travelPolicy';
import { SERVICETYPE } from '../constants/serviceType';

import { TRAVEL_POLICY_STORE_FIELDS } from '../services/travelPolicy/consts';

import { PreparedVariant } from '../services/hotels/types';
import { ITravelPolicy } from '../services/transfer/types';

import { TravelPolicyRules } from '../types/travelPolicy';
import { ITrain } from '../types/train';

const applyTrainTP = (travelPolicy: ITravelPolicy, selectedTravelPolicy: string) => {
  const hasTP = travelPolicy && travelPolicy.Apply;

  if (hasTP && selectedTravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED) {
    return !!travelPolicy.Errors[selectedTravelPolicy];
  }

  return false;
};

const applyTrainTPTrainWithTransfer = (trains: ITrain[], selectedTravelPolicy: string) =>
  trains.some(({ TravelPolicy }: { TravelPolicy: ITravelPolicy }) => applyTrainTP(TravelPolicy, selectedTravelPolicy));

const commonTPTrainWithTransfer = (trains: ITrain[]) => {
  const errors = trains.reduce((acc, train) => {
    const { TravelPolicy, TravelPolicy: { Apply } } = train;

    if (TravelPolicy && Apply) {
      const result = {
        ...acc,
      };

      Object.keys(TravelPolicy.Errors).forEach((item) => {
        // @ts-ignore
        result[item] = TravelPolicy.Errors[item];
      });

      return result;
    }

    return acc;
  }, {});

  return {
    TravelPolicy: {
      Errors: errors,
    },
  };
};

const applyHotelTravelPolicy = (ticket: PreparedVariant, selectedTravelPolicy: string) => {
  const hasTP = ticket.rate.TravelPolicy && ticket.rate.TravelPolicy.Apply;

  return hasTP && (hasTP && selectedTravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED
    ? !!ticket.rate.TravelPolicy.Errors[selectedTravelPolicy]
    : false);
};

const travelPolicyRules = (data: TravelPolicyRules) => {
  const {
    airTravelPolicyStore: { store: airStore, mappedDirections: airDirections, mappedExceptions: airExceptions },
    trainTravelPolicyStore: { store: trainStore, mappedDirections: trainDirections, mappedExceptions: trainExceptions },
    hotelTravelPolicyStore: { store: hotelStore, mappedDirections: hotelDirections, mappedExceptions: hotelExceptions },
    taxiTravelPolicyStore: { store: taxiStore },
    transferTravelPolicyStore: { store: transferStore },
    vipHallTravelPolicyStore: { store: vipHallStore },
  } = data;

  return [
    { store: { ...airStore, [TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS]: airDirections, [TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS]: airExceptions }, type: SERVICETYPE.AIR },
    { store: { ...trainStore, [TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS]: trainDirections, [TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS]: trainExceptions }, type: SERVICETYPE.TRAIN },
    { store: { ...hotelStore, [TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS]: hotelDirections, [TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS]: hotelExceptions }, type: SERVICETYPE.HOTEL },
    { store: { ...taxiStore }, type: SERVICETYPE.TAXI },
    { store: { ...transferStore }, type: SERVICETYPE.TRANSFER },
    { store: { ...vipHallStore }, type: SERVICETYPE.VIP_HALL },
  ];
};

export {
  applyTrainTP,
  applyTrainTPTrainWithTransfer,
  commonTPTrainWithTransfer,
  applyHotelTravelPolicy,
  travelPolicyRules,
};
