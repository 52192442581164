import ACTION from '../action';
import Store from '../../../store/index';

import { getDistanceToCenter } from '../../../utils/gis';
import { getCategoryMeal } from '../../../utils/hotel';

import { LANGUAGES } from '../../../constants/employee';

import {
  HotelFilters,
  HotelsRecommended,
  PrepareRate,
  PrepareRoomGroup,
  RoomGroup,
  Variant,
  IHotelStore,
  IHotel,
} from '../types';

const createNewState = (): IHotelStore => ({
  hotel: null,
  cacheRoomGroups: [],
  staticRoomGroups: null,
  loadingRate: false,
  loadingHotel: false,
  filterVat: {
    check: false,
    hasVat: false,
  },
  filters: {
    hasCancellation: false,
    online: true,
    meal: [],
  },
  showFormHotelSearchDialog: false,
  isMessageSend: false,
  loadingRequest: false,
  radiusSearchHotelResult: false, // чтобы signaler попадал в нужную функцию
  radiusSearchParams: {},
  hotelsRecommended: [],
  selectOfflineRoom: null,
  showIsPriceGuaranteeChange: false,
  errorHotelResearch: false,
  countReserved: 0,
});

const createSelectData = (rate: PrepareRate, roomCount: number): PrepareRate => {
  const { ReservedCount, FreeRooms, Offline } = rate;

  const free = FreeRooms || 1;
  const freeRooms = free - ReservedCount;

  const items = [];

  for (let i = 1; i <= freeRooms; i++) {
    items.push({ label: i, value: i });
  }

  const count = items.length ? items[0].value : null;
  const countValue = roomCount > 1 && items.length >= roomCount ? Number(roomCount) : count;

  return {
    ...rate,
    Select: {
      items,
      count: Offline && FreeRooms === 0 ? roomCount : countValue,
    },
  };
};

const sortRoomGroupsByFilters = (
  roomGroups: PrepareRoomGroup[],
  filters: HotelFilters,
) => roomGroups.reduce<PrepareRoomGroup[]>((acc, roomGroup): PrepareRoomGroup[] => {
  const updatedRates = roomGroup.Rates.filter(rate =>
    (!filters.meal || (filters.meal && getCategoryMeal(filters.meal, rate.Meal.Category))) &&
    (!filters.hasCancellation || (filters.hasCancellation && rate.CancellationPolicy.Refundable)) &&
    (!filters.online || (filters.online && !rate.Offline)));

  if (updatedRates.length) {
    return [...acc, {
      ...roomGroup,
      Rates: updatedRates,
    }];
  }

  return acc;
}, []);

const updateRoomGroups = (roomGroups: RoomGroup[], roomCount: number): PrepareRoomGroup[] => roomGroups.map(roomGroup => {
  const updatedRates: PrepareRate[] = roomGroup.Rates.map(rate => createSelectData({
    ...rate,
    ReservedCount: 0,
  }, roomCount));

  return {
    ...roomGroup,
    Rates: updatedRates,
  };
});

const updateRoomFreeCount = (hotel: IHotel, { RateId, count }: { RateId: string, count: number }) => {
  const updatedRoomGroups = hotel.RoomGroups.map((roomGroup) => {
    const updatedRates = roomGroup.Rates.map((rate) => {
      if (rate.RateId !== RateId) return rate;

      const updatedReservedCount = rate.ReservedCount ? rate.ReservedCount + count : count;
      const clonedRate = {
        ...rate,
        ReservedCount: updatedReservedCount,
      };

      return createSelectData(clonedRate, count);
    });

    return {
      ...roomGroup,
      Rates: updatedRates,
    };
  });

  return { ...hotel, RoomGroups: updatedRoomGroups };
};

const updateSelectedRate = (hotel: IHotel, { bookId, value }: { bookId: string, value:string }) => {
  const updatedRoomGroups = hotel.RoomGroups.map((roomGroup) => {
    const updatedRates = roomGroup.Rates.map((rate) => {
      if (rate.BookId !== bookId) return rate;

      return {
        ...rate,
        Select: {
          ...rate.Select,
          count: value,
        },
      };
    });

    return {
      ...roomGroup,
      Rates: updatedRates,
    };
  });

  return { ...hotel, RoomGroups: updatedRoomGroups };
};

const reducer = (action: { [key: string]: any, type: string }, state: IHotelStore) => {
  switch (action.type) {
    case ACTION.LOADHOTEL: {
      return {
        ...state,
        hotel: {
          ...state.hotel,
          ...action.payload,
        },
        cacheRoomGroups: state.cacheRoomGroups || [],
        loadingHotel: false,
      };
    }

    case ACTION.UPDATE_HOTELS_RESULT_PAGE: {
      // @ts-ignore
      const { Latitude, Longitude } = state.hotel;
      const { body, isFiltersHotelsInMicroservice } = action.payload;
      const { Variants } = body;

      const currentHotelLocation: number[] = [Latitude, Longitude];

      const newHotelsRecommended: HotelsRecommended[] = Variants.map((variant: Variant) => {
        const {
          Static,
          Rates,
          Rate,
        } = variant;

        const variantHotelLocation: number[] = [Static.Latitude, Static.Longitude];

        const distanceFromCenter = Math.round(getDistanceToCenter(currentHotelLocation, variantHotelLocation) * 100) / 100;

        if (isFiltersHotelsInMicroservice) {
          return {
            ...Static,
            total: Rate?.Total,
            distance: distanceFromCenter,
          };
        }

        let minTotal = Rates[0].Total;

        Rates.forEach(({ Total }) => {
          if (Total < minTotal) {
            minTotal = Total;
          }
        });

        return {
          ...Static,
          total: minTotal,
          distance: distanceFromCenter,
        };
      });

      const oldHotelsRecommended = state.hotelsRecommended.filter(({ HotelId }) => !newHotelsRecommended.some(({ HotelId: newHotelId }) => HotelId === newHotelId));

      return {
        ...state,
        hotelsRecommended: [
          ...newHotelsRecommended,
          ...oldHotelsRecommended,
        ],
      };
    }

    case ACTION.LOAD_COMPLETE_RESULT_PAGE: {
      // @ts-ignores
      const { hotel: { Id }, hotelsRecommended } = state;
      const hotelsRecommendedSorted = hotelsRecommended
        .sort((a, b) => a.distance - b.distance);

      const hotelsRecommendedFiltered = hotelsRecommendedSorted.filter(({ HotelId }) => HotelId !== Id);

      return {
        ...state,
        radiusSearchHotelResult: false,
        loadingRate: false,
        hotelsRecommended: hotelsRecommendedFiltered,
      };
    }

    case ACTION.UPDATEHOTELROOMGROUPS: {
      const { hotel } = state;

      if (!action.payload) {
        return {
          ...state,
          hotel: {
            ...hotel,
            RoomGroups: [],
          },
          cacheRoomGroups: [],
          loadingRate: false,
        };
      }

      action.payload.startMetrics();

      const { res: { RoomGroups, SearchId, TaxInfo, IsAvailableContract, ImportantInfo, IsPriceGuaranteed }, roomCount } = action.payload;

      const filters = state.filters;

      const isContract = RoomGroups.some((item: RoomGroup) => item.IsContract);

      if (filters.online && isContract) {
        filters.online = false;
      }

      const updateFilters = filters.online && isContract ? {
        filters: {
          ...state.filters,
          online: false,
        },
      } : {};

      const updatedRoomGroups: PrepareRoomGroup[] = updateRoomGroups(RoomGroups, roomCount);

      const roomGroupsHasVat = updatedRoomGroups.filter(({ Rates }) =>
        Rates && Rates.find(({ Price }) => Price.HasVAT)).length > 0;
      // @ts-ignore
      const hasVat = roomGroupsHasVat && hotel.CountryCode === LANGUAGES.RU;

      const showIsPriceGuaranteeChange = hotel && hotel.IsPriceGuaranteed && !IsPriceGuaranteed;

      const loadingRate = !RoomGroups.length;

      action.payload.stopMetrics();

      return {
        ...state,
        hotel: {
          ...hotel,
          RoomGroups: sortRoomGroupsByFilters(updatedRoomGroups, state.filters),
          TaxInfo,
          SearchId,
          IsAvailableContract,
          ImportantInfo,
          IsPriceGuaranteed,
        },
        cacheRoomGroups: updatedRoomGroups,
        staticRoomGroups: RoomGroups,
        filterVat: {
          ...state.filterVat,
          hasVat,
        },
        loadingRate,
        showIsPriceGuaranteeChange,
        ...updateFilters,
      };
    }

    case ACTION.UPDATE_HOTEL_IMPORTANT_INFO: {
      return {
        ...state,
        hotel: {
          ...state.hotel,
          ImportantInfo: action.payload,
        },
      };
    }

    case ACTION.HOTELNOTFOUND: {
      return {
        ...state,
        hotel: null,
        loadingRate: false,
        loadingHotel: false,
      };
    }

    case ACTION.RATESNOTFOUND: {
      return {
        ...state,
        hotel: {
          ...state.hotel,
          RoomGroups: [],
        },
        cacheRoomGroups: [],
        loadingRate: false,
      };
    }

    case ACTION.STARTLOADHOTEL: {
      return {
        ...state,
        loadingHotel: true,
      };
    }

    case ACTION.STARTLOADHOTELRATE: {
      return {
        ...state,
        hotel: {
          ...state.hotel,
          RoomGroups: [],
        },
        cacheRoomGroups: [],
        loadingRate: true,
      };
    }

    case ACTION.UPDATERATECOUNTSELECT: {
      return {
        ...state,
        // @ts-ignore
        hotel: updateSelectedRate(state.hotel, action.payload),
      };
    }

    case ACTION.UPDATEROOMCOUNT: {
      return {
        ...state,
        // @ts-ignore
        hotel: updateRoomFreeCount(state.hotel, action.payload),
      };
    }

    case ACTION.CLEARHOTEL: {
      return {
        ...createNewState(),
      };
    }

    case ACTION.UPDATEHOTELFAVORITE: {
      return {
        ...state,
        hotel: {
          ...state.hotel,
          FavoriteId: action.payload,
        },
      };
    }

    case ACTION.UPDATEHASVATFILTER: {
      const checked = action.payload;
      let newRoomGroups = state.cacheRoomGroups;

      if (checked) {
        newRoomGroups = [];
        // @ts-ignore
        for (let i = 0, item = state.hotel.RoomGroups[0]; i < state.hotel.RoomGroups.length; i++, item = state.hotel.RoomGroups[i]) {
          const vatRooms = item.Rates.filter(room => room.Price.HasVAT);

          if (vatRooms.length > 0) {
            const newGroup = {
              ...item,
              Rates: vatRooms,
            };

            newRoomGroups.push(newGroup);
          }
        }
      }

      return {
        ...state,
        filterVat: {
          ...state.filterVat,
          check: checked,
        },
        hotel: {
          ...state.hotel,
          RoomGroups: newRoomGroups,
        },
      };
    }

    case ACTION.SHOW_FORM_HOTEL_SEARCH_DIALOG: {
      const { show, room } = action.payload;

      return {
        ...state,
        showFormHotelSearchDialog: show,
        selectOfflineRoom: room,
      };
    }

    case ACTION.CHANGE_RESERVED_COUNT: {
      return {
        ...state,
        countReserved: action.payload,
      };
    }

    case ACTION.MESSAGE_SEND: {
      return {
        ...state,
        isMessageSend: !state.isMessageSend,
      };
    }

    case ACTION.LOADING_REQUEST: {
      return {
        ...state,
        loadingRequest: !state.loadingRequest,
      };
    }

    case ACTION.UPDATEHOTELFILTER: {
      const newFilter = {
        ...state.filters,
        [action.payload.filterName]: action.payload.value,
      };

      return {
        ...state,
        hotel: {
          ...state.hotel,
          RoomGroups: sortRoomGroupsByFilters(state.cacheRoomGroups, newFilter),
        },
        filters: newFilter,
      };
    }

    case ACTION.UPDATEHOTELFILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }

    case ACTION.SET_ERROR_HOTEL_RESEARCH: {
      return {
        ...state,
        errorHotelResearch: action.payload,
      };
    }

    case ACTION.START_RADIUS_SEARCH_HOTEL: {
      return {
        ...state,
        radiusSearchHotelResult: true,
        hotelsRecommended: [],
      };
    }

    case ACTION.UPDATE_RADIUS_SEARCH_PARAMS: {
      return {
        ...state,
        radiusSearchParams: {
          ...state.radiusSearchParams,
          ...action.payload,
        },
      };
    }
  }

  return state;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
