import React, { useState } from 'react';
import { MultiSelect, Input, Button, LinkButton, Text } from 'new-ui';
import { getText } from '../../../../../../../i18n';

import { ISwitchIntData, IToggleData, ICompanyList } from '../../../../../../bi/types/integration';

import { MainAnalytic } from '../../../../../../bi/utils/analytics';

import styles from './index.module.css';

const LABELS = {
  SAVE: getText('common:save'),
  CANCEL: getText('common:undo'),
  ENTER_ID: getText('settings:integration.identifier.enterId'),
  CHANGE: getText('settings:integration.identifier.change'),
  CHOOSE_ORGANIZATION: getText('settings:integration.identifier.chooseOrganization'),
};

const isValid = (v: { guidErr: string, companiesErr: string }) => !Object.values(v).some(r => !!r);

const DEFAULT_VALIDATION = {
  guidErr: '',
  companiesErr: '',
};

interface IIdentifier {
  companyList: ICompanyList[],
  data: {
    guid: string,
    companies: number[],
  },
  validateFn(currentGuid: string, guid: string, companiesToSwitch: number[]): {
    guidErr: string;
    companiesErr: string
  },
  toggleEditMode(data: IToggleData): void,
  switchIntegration(data: ISwitchIntData): void,
  single: boolean,
  loading: boolean,
  editable: boolean,
}

const Identifier = ({
  companyList,
  data: {
    guid,
    companies,
  },
  validateFn,
  toggleEditMode,
  switchIntegration,
  single,
  loading,
  editable,
}: IIdentifier) => {
  const [currentGuid, setCurrentGuid] = useState(guid);
  const [currentCompanies, setCurrentCompanies] = useState(companies);
  const [validation, setValidation] = useState(DEFAULT_VALIDATION);

  const handleToggleEdit = () => {
    if (guid) {
      setCurrentGuid(guid);
      setCurrentCompanies(companies);
      setValidation(DEFAULT_VALIDATION);
    }

    toggleEditMode({ currentGuid: guid, previousGuid: '' });
  };

  const handleValidateAndSend = () => {
    const companiesToSwitch = single ? [companyList[0].value] : currentCompanies;

    const validationRes: {
      guidErr: string;
      companiesErr: string
    } = validateFn(currentGuid, guid, companiesToSwitch);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.INTEGRATION_SAVE_BUTTON);

    if (!isValid(validationRes)) {
      setValidation(validationRes);

      return;
    }

    const dataIsEqual = currentGuid === guid && currentCompanies[0] === companies[0];

    if (single && dataIsEqual) {
      handleToggleEdit();

      return;
    }

    switchIntegration({
      guid: currentGuid,
      companies: companiesToSwitch,
      previousGuid: guid,
      previousCompanies: companies,
    });
  };

  const handleInputChange = (value: string) => {
    setValidation({
      ...validation,
      guidErr: '',
    });

    setCurrentGuid(value);
  };

  const handleCompaniesChange = (values: number[]) => {
    setValidation({
      ...validation,
      companiesErr: '',
    });

    setCurrentCompanies(values);
  };

  const renderSelectableContent = () => {
    const { guidErr, companiesErr } = validation;

    return (
      <>
        { !single && (
          <MultiSelect
            className={ styles.selectable }
            placeholder={ LABELS.CHOOSE_ORGANIZATION }
            list={ companyList }
            values={ currentCompanies }
            error={ companiesErr }
            onChange={ handleCompaniesChange }
            disabled={ !editable }
            theme='border'
          />
        ) }
        <Input
          className={ styles.selectable }
          value={ currentGuid }
          error={ guidErr }
          onChange={ handleInputChange }
          placeholder={ LABELS.ENTER_ID }
          disabled={ !editable }
        />
      </>
    );
  };

  const actionButtons = (
    <div className={ styles.buttons }>
      { editable && (
        <Button
          className={ styles.save }
          type='secondary'
          onClick={ handleValidateAndSend }
          disabled={ !isValid(validation) }
          loading={ loading }
        >
          { LABELS.SAVE }
        </Button>
      ) }
      <LinkButton
        className={ styles.secondary }
        theme='blue-without-border'
        onClick={ handleToggleEdit }
      >
        <Text
          type='NORMAL_14'
          color='accent'
        >
          { editable ? LABELS.CANCEL : LABELS.CHANGE }
        </Text>
      </LinkButton>
    </div>
  );

  return (
    <div className={ styles.identifier }>
      { renderSelectableContent() }
      { actionButtons }
    </div>
  );
};

export { Identifier };
