import React, { forwardRef, ForwardedRef } from 'react';
import { Moment } from 'moment';
import {
  Button,
  Checkbox,
  Select,
  Text,
  Icon,
  Collapse,
} from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { TripEventData, ITripPlanItem } from '../../../../../../bi/types/order';

import TripPlanEvent from '../TripPlanEvent';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('trip:tripPlan.tripPlan'),
  DOWNLOAD: getText('trip:tripPlan.downloadTripPlan'),
  SELECT_PLACEHOLDER: getText('trip:tripPlan.selectEmployee'),
  SEND_TO_EMAIL: getText('trip:tripPlan.sendToEmail'),
  ADD_EVENT: getText('trip:tripPlan.addEvent'),
  ADD_VOUCHER: getText('trip:tripPlan.addVoucher'),
};

interface TripPlanHeaderProps {
  loading: boolean,
  voucher: boolean,
  employees:{ value:number, label:string }[],
  selectEmployee:number | null,
  onSelectEmployee(value:number): void,
  onSetVoucher(value:boolean): void,
  onCreateEvent(value: { Text: string, Name:string, EventDate: Moment, EventTime: string }): void,
  onDownloadTripPlanEvent():void,
  onOpenMailDialog():void,
  tripPlanEvent: ITripPlanItem,
  onSetCollapseOpen(): void,
  onResetTripPlanEvent(): void,
  onEditEvent(event: TripEventData): void,
  onChangePlanEventData(type: string, value: string): void,
}

const TripPlanHeader = forwardRef(({
  loading,
  employees,
  selectEmployee,
  voucher,
  onCreateEvent,
  onSetVoucher,
  onSelectEmployee,
  onDownloadTripPlanEvent,
  onOpenMailDialog,
  tripPlanEvent,
  onSetCollapseOpen,
  onChangePlanEventData,
  onResetTripPlanEvent,
  onEditEvent,
}: TripPlanHeaderProps, ref: ForwardedRef<unknown>) => {
  const handleSelectEmployee = (value: number) => onSelectEmployee(value);

  const handleEditEvent = (data: TripEventData) => {
    onEditEvent(data);
    onSetCollapseOpen();
  };

  const valueEmployee = selectEmployee || '';

  const allButtonsDisabled = !selectEmployee || loading;
  const disabled = !selectEmployee;
  const buttonStyles = disabled ? styles.suggest_trip_wrapper_disabled : styles.suggest_trip_wrapper;
  const textColor = disabled ? 'gray' : 'accent';

  const renderEvent = () => (!tripPlanEvent.isEdit ? (
    <TripPlanEvent
      onCreateEvent={ onCreateEvent }
      onEditEvent={ handleEditEvent }
      onSetCollapseOpen={ onSetCollapseOpen }
      tripPlanEvent={ tripPlanEvent }
      onChangePlanEventData={ onChangePlanEventData }
      isDisabled={ allButtonsDisabled }
    />
  ) : null);

  const renderEmployee = () => (employees.length > 1 ? (
    <Select
      value={ valueEmployee }
      items={ employees }
      onChange={ handleSelectEmployee }
      theme='border'
      disabled={ loading }
      className={ styles.select }
      placeholder={ LABELS.SELECT_PLACEHOLDER }
    />
  ) : <Text className={ styles.name } type='NORMAL_18'> { employees[0]?.label } </Text>);

  return (
    <div className={ styles.header }>
      <div className={ styles.header_row }>
        <Text type='bold_32'> { LABELS.TITLE } </Text>
      </div>
      <div className={ styles.header_row }>
        { renderEmployee() }
        <Button
          className={ styles.button }
          disabled={ allButtonsDisabled }
          onClick={ onDownloadTripPlanEvent }
        >
          { LABELS.DOWNLOAD }
        </Button>
      </div>
      <div className={ styles.header_row }>
        <Checkbox
          value={ voucher }
          disabled={ allButtonsDisabled }
          onChange={ onSetVoucher }
        >
          <Text type='NORMAL_16'>{ LABELS.ADD_VOUCHER }</Text>
        </Checkbox>
        <Button
          type='textual'
          disabled={ allButtonsDisabled }
          onClick={ onOpenMailDialog }
        >
          { LABELS.SEND_TO_EMAIL }
        </Button>
      </div>
      <Collapse
        opened={ false }
        // @ts-ignore
        ref={ ref }
        renderContent={ () => (
          <div className={ buttonStyles } onClick={ onResetTripPlanEvent }>
            <Icon type='plusRound' />
            <Text color={ textColor } type='NORMAL_14'>
              { LABELS.ADD_EVENT }
            </Text>
          </div>
        ) }
        disabled={ disabled }
      >
        { renderEvent() }
      </Collapse>
    </div>
  );
});

export { TripPlanHeader };
