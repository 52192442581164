import ACTION from './action';
import Store from '../../store';

import { IUserSessionStore } from '../../types/userSession';

const createNewState = (): IUserSessionStore => ({
  enums: {
    documents: {},
    bonus: {
      Air: [],
      Train: [],
    },
    citizenship: [],
  },
  projects: [],
  travelPolicies: [],
  departmentHead: {
    requestSuccess: true,
    approve: true,
  },
  projectHead: {
    requestSuccess: true,
    approve: true,
  },
});

const reducer = (action: { [key: string]: any, type: string }, state: IUserSessionStore) => {
  const newState = { ...state };

  switch (action.type) {
    case ACTION.GETENUMS: {
      newState.enums = action.payload;

      break;
    }

    case ACTION.GETPROJECTS: {
      newState.projects = action.payload;

      break;
    }

    case ACTION.GETTRAVELPOLICIES: {
      newState.travelPolicies = action.payload;

      break;
    }

    case ACTION.DEPARTMENTS_SUCCESS: {
      newState.departmentHead.requestSuccess = action.payload;

      break;
    }

    case ACTION.DEPARTMENTS_APPROVE: {
      newState.departmentHead.approve = action.payload;

      break;
    }

    case ACTION.PROJECT_HEAD_SUCCESS: {
      newState.projectHead.requestSuccess = action.payload;

      break;
    }

    case ACTION.PROJECT_HEAD_APPROVE: {
      newState.projectHead.approve = action.payload;

      break;
    }
  }

  return newState;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
