// @ts-nocheck
import { FILTER_KEYS } from '../../constants/employee';
import { IRequestBody } from '../../types/filters';

import { isSmartAgent } from '../../utils/env';

const STEP = 50;

class SettingsProtocol {
  constructor(services) {
    Object.keys(services)
      .forEach((s) => {
        this[s] = services[s];
      });
  }

  loadSettingsEmployeesPage = (field) => {
    const data = this.Settings.getDataSearchEmployeesAndChangePagination(field);

    Promise.all([
      this.getEmployeesCount(field),
      this.Settings.employeesSearchDebounce({ ...data, isSmartAgent }),
      this.Settings.getSettingsVoucher(),
    ])
      .then((list) => {
        this.Settings.setEmployeesCount(list[0]);
        this.Settings.setEmployees(list[1]);
      })
      .finally(() => this.Settings.setEmployeesWaitingResponse(false));
  };

  getFilterEmployees = async (field, value) => {
    await this.Settings.setEmployeesWaitingResponse(true);

    if (field) {
      this.Settings.changeFilters(field, value);
    }

    return this.loadSettingsEmployeesPage(field);
  };

  getFilterEmployeesProgressive = async (data) => {
    await this.Settings.setEmployeesWaitingResponse(true);

    return this.Settings.loadSettingsEmployeesPage(data);
  };

  getEmployeesCount = (field) => {
    const data = this.Settings.getDataSearchEmployeesAndChangePagination(field);

    return this.Settings.getEmployeesCount({ ...data, isSmartAgent });
  };

  getEmployeesPaginationListNew = (flag: boolean, data: IRequestBody) => {
    this.Settings.setEmployeesWaitingResponse(true);

    if (flag) {
      this.getFilterEmployeesProgressive(data);

      return;
    }

    this.getFilterEmployees(FILTER_KEYS.COMPANY_ID, 0);
  };

  changePageEmployees = (page) => {
    this.Settings.changePageEmployeesOnly(page);
    this.getFilterEmployees();
  };

  resetFilters = (field, value, step = STEP) => {
    this.Settings.setResetFilers(field, value, step);
    this.getFilterEmployees();
  };
}

export { SettingsProtocol };
