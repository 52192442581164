import React from 'react';
import { observer } from 'mobx-react';

import ChartsAnalytics from '../../../../../bi/services/chartsAnalytics';

import ROUTES from '../../../../../bi/constants/routes';

import { AirPageSubSections } from '../../common/smartIndex/components/airPageSubSections';

const SmartAirline = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => (
  <AirPageSubSections
    chartsAnalyticsService={ chartsAnalyticsService }
    path={ ROUTES.CHARTS_ANALYTICS.SMART.AIRLINE.SECOND }
  />
));

export { SmartAirline };
