import React, { FC } from 'react';
import { Tooltip, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import styles from './index.module.css';

const LABELS = {
  TRIP_DIRECTION: getText('trains:car.tripDirection'),
};

interface Props {
  direction: 'right' | 'left' | null;
}

const TrainCarDirection: FC<Props> = ({ direction }) => {
  const arrowSide = direction === 'left' ? styles.left : styles.right;

  return (
    <Tooltip
      renderContent={ () => (
        <Text
          type='NORMAL_14_130'
          color='white'
          className={ styles.tooltip }
        >
          { LABELS.TRIP_DIRECTION }
        </Text>
      ) }
    >
      <div className={ styles.wrapper }>
        <div className={ `${styles.img} ${arrowSide}` } />
      </div>
    </Tooltip>
  );
};

export { TrainCarDirection };
