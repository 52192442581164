import { BonusProgramStore, BonusProgramStoreType } from './store';
import {
  BonusProgramAddingStore,
  BonusProgramEmailStore,
  BonusProgramRegistrationStore,
  BonusProgramValueStore,
  IBonusData,
} from './types';

class BonusProgramService {
  api: any;
  store: BonusProgramStoreType;
  constructor(api: any) {
    this.api = api;
    this.store = BonusProgramStore;
  }

  loadBonuses = () => {
    this.store.updateBonuses({ error: false, loading: true });
    this.api.bonusProgram.getBonuses()
      .then((value: BonusProgramValueStore) => this.store.updateBonuses(value))
      .catch(() => this.store.updateBonuses({ error: true }))
      .finally(() => {
        this.store.updateBonuses({ loading: false });
      });
  };

  setBonusCode = async (code: string) => {
    this.store.updateAddingCode({ error: false, loading: true });
    await this.api.bonusProgram.setCode(code)
      .then((value: BonusProgramAddingStore) => this.store.updateAddingCode(value))
      .catch(() => this.store.updateAddingCode({ error: true }))
      .finally(() => this.store.updateAddingCode({ loading: false }));
  };

  checkEmail = (data: { Email: string }) => {
    this.api.bonusProgram.checkEmail(data)
      .then((value: BonusProgramEmailStore) => this.store.updateBonusEmail(value))
      .catch(() => this.store.updateBonusEmail({ error: true }));
  };

  bonusRegistration = async (data: IBonusData) => {
    this.store.updateBonusRegistration({ error: false, loading: true });
    await this.api.bonusProgram.registration(data)
      .then((value: BonusProgramRegistrationStore) => this.store.updateBonusRegistration(value))
      .catch(() => this.store.updateBonusRegistration({ error: true }))
      .finally(() => this.store.updateBonusRegistration({ loading: false }));
  };
}

export default BonusProgramService;
