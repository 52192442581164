import { IUnderageEmployeesItem } from '../../../utils/employees';

/** Функция для создания списка полных ФИО выбранных сотрудников */
export const createEmployeeDictionaryFullName = (
  employees: IUnderageEmployeesItem[],
): Record<number, string> => employees?.reduce((acc, employee) => {
  const { Id, Surname, Name, Patronymic } = employee.Employee;

  const fullName = `${Surname} ${Name} ${Patronymic}`.trim();

  acc[Id] = fullName;

  return acc;
}, {} as Record<number, string>);
