import React, { useMemo, useCallback, useEffect, useRef, useState, RefObject, ForwardedRef } from 'react';
import { IconButton, Card, Text, Button } from 'new-ui';
import { Link } from 'react-router-dom';

import { getText, getTextArray } from '../../../../../../../i18n';

import toDecline from '../../../../../../bi/utils/toDecline';
import { throttle } from '../../../../../../bi/utils/throttle';
import { MainAnalytic } from '../../../../../../bi/utils/analytics';

import { HotelsRecommended } from '../../../../../../bi/services/hotels/types';

import styles from './styles.module.css';

const LABELS = {
  CURRENCY: getText('common:currency'),
  NIGHT_DECLINES: getTextArray('utils:hotel.nightDeclines'),
  FROM_HOTEL: (distance: string, currentHotelName: string) => getText('hotels:hotelResult.item.roomGroup.recommendedHotels.fromHotel', { distance, currentHotelName }),
  CHOOSE_ROOM: getText('hotels:hotelResult.item.roomGroup.recommendedHotels.chooseRoom'),
  FROM: getText('hotels:hotelResult.item.roomGroup.recommendedHotels.from'),
  FOR: getText('hotels:hotelResult.item.roomGroup.recommendedHotels.for'),
};

const CAROUSEL_DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right',
};

const THROTTLE_TIMEOUT = 300;

interface HotelCarouselProps {
  items: HotelsRecommended[],
  currentHotelName: string,
  nightsCount: number
  searchParams?: string,
  cardMarginRight?: number,
  cardsRow?: number,
}

const HotelCarousel = ({
  items,
  currentHotelName,
  nightsCount,
  searchParams = '',
  cardMarginRight = 0,
  cardsRow = 0,
}: HotelCarouselProps) => {
  const [cardTranslateX, SetCardTranslateX] = useState(0);
  const cardRefs: RefObject<HTMLDivElement>[] = items.map(() => React.createRef());
  const sliderIndex = useRef(0);
  const cardWidth = useRef(0);

  useEffect(() => {
    cardRefs.forEach(ref => {
      const newRef = { ...ref };

      // @ts-ignore
      newRef.current.style.marginRight = `${cardMarginRight}px`;
    });
    // @ts-ignore
    cardWidth.current = cardRefs[0].current.offsetWidth;
  }, [cardRefs, cardMarginRight]);

  const slide = useCallback((direction: string) => {
    const canSlideLeft = direction === CAROUSEL_DIRECTIONS.LEFT && sliderIndex.current !== 0;
    const canSlideRight = direction === CAROUSEL_DIRECTIONS.RIGHT && sliderIndex.current !== items.length - cardsRow;
    const isLastItem = sliderIndex.current === items.length - cardsRow;

    if (canSlideLeft) {
      const newSliderIndex = { ...sliderIndex };
      sliderIndex.current = newSliderIndex.current - 1;
      SetCardTranslateX(prevTranslate => prevTranslate + cardMarginRight + cardWidth.current);
    }

    if (canSlideRight) {
      const newSliderIndex = { ...sliderIndex };
      sliderIndex.current = newSliderIndex.current + 1;
      SetCardTranslateX(prevTranslate => prevTranslate - cardMarginRight - cardWidth.current);
    }

    if (isLastItem) {
      sliderIndex.current = 0;
      SetCardTranslateX(0);
    }

    if (canSlideLeft || canSlideRight) {
      MainAnalytic.send(
        MainAnalytic.CATEGORY.HOTELS,
        MainAnalytic.ACTIONS.HOTELS.ALTERNATIVE_MORE_CARDS,
        {
          label: currentHotelName,
        },
      );
    }
  }, [cardMarginRight, cardsRow, currentHotelName, items.length]);

  const slideThrottled = useMemo(
    () => throttle<string[], void>(slide, THROTTLE_TIMEOUT),
    [slide],
  );

  const getTranslateStyle = () => {
    const translateX = `translateX(${cardTranslateX}px)`;

    return {
      WebkitTransform: translateX,
      MozTransform: translateX,
      msTransform: translateX,
      OTransform: translateX,
      transform: translateX,
    };
  };

  const handleChooseRoom = () => {
    MainAnalytic.send(
      MainAnalytic.CATEGORY.HOTELS,
      MainAnalytic.ACTIONS.HOTELS.ALTERNATIVE_HOTEL,
      {
        label: currentHotelName,
      },
    );
  };

  const goToHotel = (id: number) => window.open(`/search/hotel/${id}?${searchParams}`, '_blank');

  const renderHotelCard = (item: HotelsRecommended, ref: ForwardedRef<HTMLDivElement>) => {
    const { HotelName, Address, total, distance, HotelId } = item;

    return (
      <Card
        item={ item }
        ref={ ref }
        wrapClassname={ styles.card }
        contentClassname={ styles.content }
        ratingClassname={ styles.rating }
        imageClassname={ styles['image-container'] }
        key={ item.HotelId }
        handleImage={ () => goToHotel(item.HotelId) }
        handleRating={ () => goToHotel(item.HotelId) }
      >
        <Text
          type='SEMIBOLD_16'
          color='gray'
        >
          { HotelName }
        </Text>
        <Text
          type='NORMAL_14'
          color='gray'
          className={ styles.adress }
        >
          { Address }
        </Text>
        <Text
          type='SEMIBOLD_14'
          color='gray'
        >
          { LABELS.FROM_HOTEL(distance.toString(), currentHotelName) }
        </Text>
        <div className={ styles['price-wrapper'] }>
          <Text
            type='NORMAL_14'
            color='gray'
          >
            { LABELS.FROM } <span className={ styles.price }>{ total } { LABELS.CURRENCY }</span>
          </Text>
          <Text
            type='NORMAL_14'
            color='gray'
          >
            { LABELS.FOR } { nightsCount } { toDecline(nightsCount, LABELS.NIGHT_DECLINES) }
          </Text>
        </div>
        <Link
          to={ `/search/hotel/${HotelId}?${searchParams}` }
          target='_blank'
        >
          <Button
            type='secondary'
            className={ styles['choose-room'] }
            onClick={ handleChooseRoom }
          >
            { LABELS.CHOOSE_ROOM }
          </Button>
        </Link>
      </Card>
    );
  };

  const renderHotelCards = () => {
    const translateXStyle = getTranslateStyle();

    return (
      <div className={ styles['item-container'] }>
        <div className={ styles['card-wrapper'] } style={ translateXStyle }>
          { items.map((item, index) => renderHotelCard(item, cardRefs[index])) }
        </div>
      </div>
    );
  };

  const renderIcons = () => {
    const showIcons = items.length > cardsRow;

    const arrowLeft = (
      <IconButton
        iconType='arrowsLeftRoundBig'
        onClick={ () => slideThrottled(CAROUSEL_DIRECTIONS.LEFT) }
        className={ styles['arrow-left'] }
      />
    );

    const arrowRight = (
      <IconButton
        iconType='arrowsRightRoundBig'
        onClick={ () => slideThrottled(CAROUSEL_DIRECTIONS.RIGHT) }
        className={ styles['arrow-right'] }
      />
    );

    return showIcons && (
      <>
        { sliderIndex.current !== 0 && arrowLeft }
        { arrowRight }
      </>
    );
  };

  return (
    <div className={ styles['carousel-content'] }>
      { renderHotelCards() }
      { renderIcons() }
    </div>
  );
};

export { HotelCarousel };
