import React from 'react';
import { Icon, IconButton, Text, StyledWrapper } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { UploadLogoVoucherProps } from '../../../../../../bi/types/voucherSettings';

import { MainAnalytic } from '../../../../../../bi/utils/analytics';

import styles from '../../styles/index.module.css';

const LABELS = {
  LOGO_SIZE: (size: number) => getText('settings:vouchers:logo:size', { size }),
  LOGO_LOAD: getText('settings:vouchers:logo:load'),
  LOGO_TOOLTIP: getText('settings:vouchers:logo:formatTooltip'),
};

const UploadLogoVoucher = ({
  isLogo,
  IsErrorLogo,
  loadProgress,
  fileName,
  fileSize,
  handleReset,
  setShowUpload,
  isSmartAgent,
  renderErrorLogo,
  renderTooltip,
}: UploadLogoVoucherProps) => {
  const handleShowUpload = () => {
    setShowUpload(true);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.SETTINGS_FORM_LOGO);
  };

  if (isLogo && !IsErrorLogo) {
    const labelSize = loadProgress + LABELS.LOGO_SIZE(Number(fileSize[0]) + 1);

    return (
      <div
        className={ styles.wrapper_log }
      >
        <Icon
          className={ styles.icon_indent }
          type='menuReportsBiggest'
        />
        <div>
          <Text
            className={ styles.name_files }
            type='bold_16'
          >
            { fileName }
          </Text>
          <Text
            className={ styles.text_size }
            type='NORMAL_14_130'
          >
            { labelSize }
          </Text>
        </div>
        <IconButton
          className={ styles.close_button }
          onClick={ () => handleReset() }
          iconType='closeButton'
        />
      </div>
    );
  }

  return (
    <>
      <div
        onClick={ handleShowUpload }
      >
        <StyledWrapper
          className={ styles.wrapper_button }
        >
          <IconButton
            iconType='plusRound'
            alternativeDesign={ isSmartAgent }
          >
            { LABELS.LOGO_LOAD }
          </IconButton>
        </StyledWrapper>
        { renderErrorLogo() }
      </div>
      { renderTooltip(LABELS.LOGO_TOOLTIP, styles.tooltip_logo) }
    </>
  );
};

export default UploadLogoVoucher;
