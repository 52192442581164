import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Dialog, DotLoading, Input, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useServices } from '../../../../bi/context/services';

import { useStatusCondition } from '../../../../bi/hooks/useStatusCondition';

import { LoadingStatus } from '../../../../bi/services/utils/network/types';

import styles from './styles.module.css';

const LABELS = {
  PASTE: getText('settings:employees.employee.telegram.pasteMail'),
  SUBMIT: getText('settings:employees.employee.telegram.submitMail'),
  CANCEL: getText('settings:employees.employee.telegram.cancel'),
  FORMAT_ERROR: getText('settings:employees.employee.telegram.formatError'),
  SUCCESS_TITLE: getText('settings:employees.employee.telegram.successTitle'),
  SUCCESS_DESCRIPTION: getText('settings:employees.employee.telegram.successDescription'),
  OK: getText('common:ok'),
};

interface TgMailDialogProps {
  id: number
  show: boolean
  setShow: (value: boolean) => void
  initialMail?: string
}

export const TgMailDialog = observer(({
  id,
  show,
  setShow,
  initialMail = '',
}: TgMailDialogProps) => {
  const { employeeService } = useServices(['Employee']);

  const [mail, setMail] = useState(initialMail);

  const handleSetShow = (value: boolean) => {
    setShow(value);

    if (!value) {
      setMail(initialMail);
      employeeService.networkStore.clearStatus('sendTgLink');
    }
  };

  const handleSendTgToMail = () => {
    employeeService.sendMailWithTgLink(id, mail);
  };

  const renderPasteMailToTg = () => (
    <>
      <Text type='bold_20'>
        { LABELS.PASTE }
      </Text>
      <Input
        value={ mail }
        onChange={ setMail }
      />
      <div className={ styles.bottomBlock }>
        <Button type='secondary' onClick={ handleSendTgToMail }>
          { LABELS.SUBMIT }
        </Button>
        <Button type='textual' onClick={ () => handleSetShow(false) }>
          { LABELS.CANCEL }
        </Button>
      </div>
    </>
  );

  const renderSuccess = () => (
    <>
      <Text type='bold_20'>
        { LABELS.SUCCESS_TITLE }
      </Text>
      <Text type='NORMAL_14_130'>
        { LABELS.SUCCESS_DESCRIPTION }
      </Text>
      <div className={ styles.bottomBlock }>
        <Button
          onClick={ () => handleSetShow(false) }
          type='secondary'
          className={ styles.bottomButton }
        >
          <Text type='NORMAL_14' color='white'>
            { LABELS.OK }
          </Text>
        </Button>
      </div>
    </>
  );

  const status = employeeService.networkStore.getStatus('sendTgLink');

  const [renderForm, className] = useStatusCondition(status, {
    onLoading: [() => <DotLoading />, ''],
    onLoaded: [renderSuccess, styles.dialogSuccess],
    fallback: [renderPasteMailToTg, styles.dialog],
  });

  const showClosing = status !== LoadingStatus.LOADING;

  return (
    <Dialog
      showClosing={ showClosing }
      show={ show }
      onChange={ handleSetShow }
      className={ `${styles.base} ${className}` }
    >
      { renderForm() }
    </Dialog>
  );
});
