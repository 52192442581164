import React from 'react';
import { observer } from 'mobx-react';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import { ChartAnalyticsNoResult } from '../../../noResult';

import { SmartIndexChart } from '../../../smart/components/SmartIndexChart';
import { SmartIndexTable } from '../../../smart/components/SmartIndexTable';

const SubSectionsOfTable = observer(({
  store,
  onOpen,
  chartsAnalyticsService,
  serviceType,
  tooltip,
  type,
}: {
  store: any,
  onOpen(subPageInfo: any, servType: string): void,
  chartsAnalyticsService: ChartsAnalytics,
  serviceType: string,
  tooltip: string,
  type: string,
}) => {
  const {
    loading,
    label,
    chart: {
      values,
      labels,
    },
    summaryIndex,
    tableInfo,
  } = store;

  if (!values.length) {
    return <ChartAnalyticsNoResult />;
  }

  return (
    <>
      <SmartIndexChart
        title={ label }
        values={ values }
        labels={ labels }
        summaryIndex={ summaryIndex }
        tooltip={ tooltip }
      />
      <SmartIndexTable
        loading={ loading }
        title={ label }
        tableInfo={ tableInfo }
        onOpen={ onOpen }
        serviceType={ serviceType }
        type={ type }
        chartsAnalyticsService={ chartsAnalyticsService }
      />
    </>
  );
});

export { SubSectionsOfTable };
