import React from 'react';

import FlightDetails from './FlightDetails';

import { Fare, FlightRouteProps } from './types';

const FlightRouteComponent = ({
  lastFare,
  routeFares,
  totalDuration,
}: FlightRouteProps) => {
  const renderFlightDetails = (
    fares: Fare[],
    duration: string,
    last: Fare,
  ) => fares.map((fare, index) => (
    <FlightDetails
      routeFares={ fares }
      index={ index }
      key={ fare.Id }
      fare={ fare }
      totalDuration={ duration }
      isFirstSegment={ index === 0 }
      lastFare={ last }
    />
  ));

  return (
    <div>
      {renderFlightDetails(routeFares, totalDuration, lastFare)}
    </div>
  );
};

export default FlightRouteComponent;
