import React, { FC, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Text, RadioButton, Button } from 'new-ui';

import { getText } from '../../../../../i18n';

import { isSmartAgent } from '../../../../bi/utils/env';

import { VALUES_CHECKBOX } from '../../../../bi/constants/changeAirTrip';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import ROUTES from '../../../../bi/constants/routes';

import { TicketDataInterface } from '../../../../components/ChangeAirTripHeader/types';

import styles from './styles/index.module.css';

interface ChangeTypeSelectionProps {
  history: RouteComponentProps['history'];
  match: RouteComponentProps['match'];
  ticketData: TicketDataInterface;
  setChangeTypeCrumbs(path: string): void;
  canEditEmployee: boolean;
  canEditSelf: boolean;
  employeeId: number | string;
  tripId: string;
}

const LABELS = {
  TITLE: getText('components:changeAirTrip.changeType.title'),
  DATE_TIME: getText('components:changeAirTrip.changeType.dateTime'),
  FLIGHT_DIRECTION: getText('components:changeAirTrip.changeType.flightDirection'),
  PASSPORT: getText('components:changeAirTrip.changeType.passport'),
  OTHER: getText('components:changeAirTrip.changeType.other'),
  NEXT: getText('components:changeAirTrip.next'),
  BACK: getText('components:changeAirTrip.back'),
};

const {
  changeTrip: {
    air: {
      select: {
        title,
        choiceType,
        nextStage,
        backButton: backButtonTest,
      },
    },
  },
} = QA_ATTRIBUTES;

const ChangeTypeSelection: FC<ChangeTypeSelectionProps> = ({
  history,
  match,
  ticketData,
  canEditEmployee,
  canEditSelf,
  employeeId,
  tripId,
  setChangeTypeCrumbs,
}) => {
  const [changeType, setChangeType] = useState('');

  const disabled = !changeType;
  const personId = ticketData.Travellers.ADT[0].ID;

  const canEditPassport = useMemo(() => canEditEmployee || (canEditSelf && personId === employeeId), []);

  const handleGoPageChange = () => {
    if (changeType === VALUES_CHECKBOX.PASSPORT) {
      setChangeTypeCrumbs(`${match.url}/${changeType}/${personId}`);

      return history.push(`${match.url}/${changeType}/${personId}`);
    }

    setChangeTypeCrumbs(`${match.url}/${changeType}`);

    return history.push(`${match.url}/${changeType}`);
  };

  const handleBack = () => history.push(ROUTES.CHANGE_AIR_TRIP.TRIP(tripId));

  const renderPassport = () => (canEditPassport ? (
    <RadioButton
      qaAttr={ choiceType }
      className={ styles.item }
      value={ VALUES_CHECKBOX.PASSPORT }
      alternativeDesign={ isSmartAgent }
      checked={ changeType === VALUES_CHECKBOX.PASSPORT }
      onChange={ setChangeType }
    >
      { LABELS.PASSPORT }
    </RadioButton>
  ) : null);

  return (
    <div className={ styles.wrapper }>
      <Text qaAttr={ title } type='SEMIBOLD_16' color='blue'>
        { LABELS.TITLE }
      </Text>
      <RadioButton
        qaAttr={ choiceType }
        className={ styles.item }
        value={ VALUES_CHECKBOX.DATE_TIME }
        alternativeDesign={ isSmartAgent }
        checked={ changeType === VALUES_CHECKBOX.DATE_TIME }
        onChange={ setChangeType }
      >
        { LABELS.DATE_TIME }
      </RadioButton>
      <RadioButton
        qaAttr={ choiceType }
        className={ styles.item }
        value={ VALUES_CHECKBOX.FLIGHT_DIRECTION }
        alternativeDesign={ isSmartAgent }
        checked={ changeType === VALUES_CHECKBOX.FLIGHT_DIRECTION }
        onChange={ setChangeType }
      >
        { LABELS.FLIGHT_DIRECTION }
      </RadioButton>
      { renderPassport() }
      <RadioButton
        qaAttr={ choiceType }
        className={ styles.item }
        value={ VALUES_CHECKBOX.OTHER }
        alternativeDesign={ isSmartAgent }
        checked={ changeType === VALUES_CHECKBOX.OTHER }
        onChange={ setChangeType }
      >
        { LABELS.OTHER }
      </RadioButton>
      <div className={ styles.action }>
        <Button
          qaAttr={ nextStage }
          className={ styles.button }
          type='secondary'
          disabled={ disabled }
          onClick={ handleGoPageChange }
        >
          { LABELS.NEXT }
        </Button>
        <Button
          qaAttr={ backButtonTest }
          type='textual'
          onClick={ handleBack }
        >
          { LABELS.BACK }
        </Button>
      </div>
    </div>
  );
};

export default ChangeTypeSelection;
