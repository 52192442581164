import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Text, DotLoading, Button } from 'new-ui';
import { observer } from 'mobx-react';

import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import Report from '../../bi/services/report';

import ROUTES from '../../bi/constants/routes';

import { IMatch } from '../../bi/types/shared';

import logo from '../../../svg/logos/smartway.svg';
import styles from '../QuickApprove/styles/index.module.css';

const LABELS = {
  TO_WORKPLACE: getText('reports:reportAct.toWorkplace'),
};

interface IReportActProps {
  reportService: Report,
  match: IMatch,
  history: RouteComponentProps['history'],
}

const ReportAct = observer(({ reportService, match, history }: IReportActProps) => {
  const {
    requestDialogStore: {
      loadingReportAct,
      reportAct: { Message },
    },
  } = useStores([MOBX_STORES.REQUEST_DIALOG_STORE]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!match.params.guid) {
      return history.push({ pathname: ROUTES.SMARTDESK });
    }

    reportService.downloadReportAct(match.params.guid);
  }, []);

  const goToSmartdesk = () => {
    history.push({ pathname: ROUTES.SMARTDESK });
  };

  const renderPage = () => {
    const additionalButton = (
      <div className={ styles.button_wrapper }>
        <Button
          onClick={ goToSmartdesk }
          className={ styles.button }
          type='secondary'
        >
          { LABELS.TO_WORKPLACE }
        </Button>
      </div>
    );

    return (
      <div className={ styles.wrap }>
        <div className={ styles.content }>
          <div className={ styles.logo } onClick={ goToSmartdesk }>
            <img
              src={ logo }
              alt='Smartway'
              title='Smartway'
            />
          </div>
          <div className={ styles.message }>
            <Text type='NORMAL_16_140'>
              {' '}
              { Message }{' '}
            </Text>
          </div>
          { additionalButton }
        </div>
      </div>
    );
  };

  const renderLoading = () => (
    <div className={ styles.loading }>
      <DotLoading />
    </div>
  );

  return loadingReportAct ? renderLoading() : renderPage();
});

export { ReportAct };
