import React from 'react';
import { Text } from 'new-ui';

import { getAirlineType } from '../../../../bi/utils/airline';
import { dateWithoutMoment } from '../../../../bi/utils/formatDate';
import toDecline from '../../../../bi/utils/toDecline';

import { DATEFORMATS } from '../../../../bi/constants/dateFormats';

import { TRANSFER, WITHOUT_TRANSFER } from '../../../../bi/constants/airline';

import {
  IAirItem,
  IAirRoute,
  IFavorite,
  IQaAttrsFavoritesItem,
} from '../../../../bi/types/favorites';

import styles from './style.module.css';

interface IAirlineItemProps {
  favorite: IFavorite;
  qaAttrs: IQaAttrsFavoritesItem;
}

const AirlineItem = ({
  favorite: { Item },
  qaAttrs: {
    from = '',
    to = '',
    name = '',
  },
}: IAirlineItemProps) => {
  const {
    Routes,
  } = Item as IAirItem;

  const itemHtml = ({ Segments, Date }: IAirRoute, index: number) => {
    const { DepartureTime, DepartureCity, DepartureAirport: { ID } } = Segments[0];
    const countOfLastSegment = Segments.length - 1;
    const lastSegment = Segments[countOfLastSegment];
    const {
      ArrivalTime,
      FlightNumber,
      ArrivalCity,
      ArrivalAirport: { ID: arrivalAirportId },
    } = lastSegment;

    const changeCount = Segments.length > 1
      ? `${(countOfLastSegment)} ${toDecline((countOfLastSegment), TRANSFER)}`
      : WITHOUT_TRANSFER;
    const departureTime = dateWithoutMoment(DepartureTime, DATEFORMATS.TIME);
    const arrivalTime = dateWithoutMoment(ArrivalTime, DATEFORMATS.TIME);
    const airlineType = getAirlineType(lastSegment);

    return (
      <div className={ styles.item } key={ `airline_route_${Date}_${index}` }>
        <div className={ styles.place }>
          <Text type='SEMIBOLD_16' qaAttr={ from }>
            { `${DepartureCity}, ${ID}` }
          </Text>
          <Text type='NORMAL_14' color='gray' className={ styles.time }>
            { departureTime }
          </Text>
        </div>
        <div className={ styles.airline }>
          <Text type='NORMAL_14' color='gray' qaAttr={ name }>
            { lastSegment[airlineType].Name } ({`${lastSegment[airlineType].ID} ${FlightNumber}`})
          </Text>
          <Text type='NORMAL_14' color='gray' className={ styles.change }>
            { changeCount }
          </Text>
        </div>
        <div className={ styles.place }>
          <Text type='SEMIBOLD_16' qaAttr={ to }>
            { `${ArrivalCity}, ${arrivalAirportId}` }
          </Text>
          <Text type='NORMAL_14' color='gray' className={ styles.time }>
            { arrivalTime }
          </Text>
        </div>
      </div>
    );
  };

  return (
    <div className={ styles.wrapper }>
      { Routes.map(itemHtml) }
    </div>
  );
};

export { AirlineItem };
