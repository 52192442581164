import axios from 'axios';

import CONFIG from '../../../config';
import ROUTES from '../api/routes';

const getFile = (guid: string) => {
  const downloadDiv = document.getElementById('downloadSector');
  const srcUrl = `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}/${guid}`;
  const iframe = document.createElement('iframe');
  iframe.src = srcUrl;

  downloadDiv!.innerHTML = '';
  downloadDiv!.appendChild(iframe);
};

const downloadFileWithGuid = async (url: string, data: any) => {
  try {
    const guid: string = (await axios.post(url, data)).data;

    return getFile(guid.replace(/"/g, ''));
  } catch (err) {
    return Promise.reject(err);
  }
};

export { downloadFileWithGuid, getFile };
