import React from 'react';
import { Moment } from 'moment';
import { Button, Datepicker, Text, Textarea } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { DATA_KEY } from '../../../../../../bi/constants/trips';

import { currentDate, isMoment, momentObject } from '../../../../../../bi/utils/formatDate';
import { MainAnalytic } from '../../../../../../bi/utils/analytics';

import { ITripPlanItem, TripEventData } from '../../../../../../bi/types/order';

import { TimeInputWithoutValue } from '../../../../../../components/TimeInputWithoutValue';

import styles from './index.module.css';

interface TripPlanEventProps {
  onCreateEvent(eventData: TripEventData): void;
  onEditEvent(eventData: any, value: boolean): void;
  onSetCollapseOpen():void;
  tripPlanEvent: ITripPlanItem,
  onChangePlanEventData(dataKey: string, value: string | number | null | Moment): void,
  isDisabled: boolean,
}

const TIME_LABEL = '--:--';

const LABELS = {
  ADD_EVENT: getText('trip:tripPlan.addedEvent'),
  DATA: getText('trip:tripPlan.date'),
  TIME: getText('trip:tripPlan.time'),
  ADD_NAME_EVENT: getText('trip:tripPlan.enterEventName'),
  ADD_TEXT: getText('trip:tripPlan.enterText'),
  ADD: getText('trip:tripPlan.add'),
  EDIT: getText('trip:tripPlan.edit'),
  CANCEL: getText('trip:tripPlan.cancel'),
};

const TripPlanEvent = ({
  onCreateEvent,
  onSetCollapseOpen,
  tripPlanEvent,
  onChangePlanEventData,
  onEditEvent,
  isDisabled,
}: TripPlanEventProps) => {
  const { Name, AdditionalInfo, EventDate, EventTime, isEdit, Id } = tripPlanEvent;
  const handleSetEventName = (value: string) => onChangePlanEventData(DATA_KEY.NAME, value);
  const handleSetEventText = (value: string) => onChangePlanEventData(DATA_KEY.ADDITIONAL_INFO, value);
  const handleSetEventDate = (value: Moment) => onChangePlanEventData(DATA_KEY.EVENT_DATE, value);
  const handleSetEventTime = (hours = '', minutes = '') => onChangePlanEventData(DATA_KEY.EVENT_TIME, !hours && !minutes ? '' : `${hours}:${minutes}`);

  const handleSetCollapseOpen = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.TRIP_PLAN_CANCEL_EVENT_BUTTON_PRESSED);
    onSetCollapseOpen();
  };

  const handleCreateEvent = () => {
    onCreateEvent({
      Name,
      Text: AdditionalInfo,
      EventDate,
      EventTime,
    });
  };
  const handleEditEvent = () => {
    onEditEvent({
      Name,
      Text: AdditionalInfo,
      EventDate,
      EventTime,
      Id,
    }, false);
  };

  const handleClickAddButton = isEdit ? handleEditEvent : handleCreateEvent;

  const dateMoment = isMoment(EventDate) ? EventDate : momentObject(EventDate);

  const buttonName = isEdit ? LABELS.EDIT : LABELS.ADD;

  const headerName = isEdit ? '' : <Text type='bold_18'>{ LABELS.ADD_EVENT }</Text>;

  const preparedAdditionalInfo = Array.isArray(AdditionalInfo) ? AdditionalInfo.join() : AdditionalInfo;

  return (
    <div className={ styles.collapse_wrapper }>
      { headerName }
      <div className={ styles.row }>
        <div className={ styles.row_item }>
          <Text type='NORMAL_16' color='gray'>{ LABELS.DATA }</Text>
          <Datepicker
            inputClassName={ styles.input }
            value={ dateMoment }
            min={ currentDate() }
            onChange={ handleSetEventDate }
          />
        </div>
        <div className={ styles.row_item }>
          <Text type='NORMAL_16' color='gray'>{ LABELS.TIME }</Text>
          <TimeInputWithoutValue
            label={ TIME_LABEL }
            className={ styles.input }
            border
            value={ EventTime }
            onChange={ handleSetEventTime }
            onBlur={ handleSetEventTime }
            max={ 5 }
          />
        </div>
      </div>
      <Textarea
        value={ Name }
        placeholder={ LABELS.ADD_NAME_EVENT }
        noneResize
        className={ `${styles.row} ${styles.text_type_one}` }
        onChange={ handleSetEventName }
      />
      <Textarea
        value={ preparedAdditionalInfo }
        placeholder={ LABELS.ADD_TEXT }
        noneResize
        className={ `${styles.row} ${styles.text_type_two}` }
        onChange={ handleSetEventText }
      />
      <div className={ styles.row }>
        <Button
          className={ styles.row_item }
          onClick={ handleClickAddButton }
          disabled={ isDisabled }
        >
          { buttonName }
        </Button>
        <Button
          className={ styles.row_item }
          type='textual'
          onClick={ handleSetCollapseOpen }
          disabled={ isDisabled }
        >
          { LABELS.CANCEL }
        </Button>
      </div>
    </div>
  );
};

export default TripPlanEvent;
