// @ts-nocheck
import { TITLES, CHARTS_ANALYTICS } from '../../constants/chartsAnalytics';

class ChartsAnalytics {
  constructor(services) {
    Object.keys(services).forEach((s) => {
      this[s] = services[s];
    });
  }

  preparedCompanies = () => {
    const companies = this.Workspace.get().Companies;

    this.ChartsAnalytics.preparedCompanies(companies);
  };

  preparedData = async () => {
    await this.getCustomAnalytics();
    await this.getDepartments();
    this.preparedMenu(); // здесь из фф приходит список меню, которое можно показать, как откроется this.getCustomAnalytics();, перетащить логику туда
    this.preparedCompanies();
    this.ChartsAnalytics.preparedDates(null);
  };

  preparedMenu = () => {
    // const showDepartmentsMenu = this.ChartsAnalytics.getShowDepartmentsMenu();
    // const departmentsInMenu = showDepartmentsMenu ? MENU_KEYS.STRUCTURE.DEPARTMENTS : null;
    // const departmentsInMenu = false;
    const openMenu = this.FeatureFlags.getOpenMenuChartsAnalytics();

    this.ChartsAnalytics.setMenu(openMenu);
  };

  getDepartments = async () => {
    try {
      const departments = await this.Departments.justLoadList();

      this.ChartsAnalytics.setShowDepartments(departments.some(i => i.Departments.length));
    } catch {
      this.ChartsAnalytics.setShowDepartments(false);
    }
  };

  getCustomAnalytics = async () => {
    try {
      const customAnalytics = await this.CustomAnalytics.getCustomAnalytics();

      if (customAnalytics.length) {
        const mappedCustomAnalytics = customAnalytics.map(({ Name, Id }) => ({
          id: CHARTS_ANALYTICS(Id),
          title: TITLES.STRUCTURE.CUSTOM_ANALYTICS(Name),
        }));

        this.ChartsAnalytics.setCustomAnalyticsToMenu(mappedCustomAnalytics);
        this.ChartsAnalytics.setCustomAnalytics(customAnalytics);
      }
    } catch {
      this.ChartsAnalytics.setCustomAnalyticsToMenu([]);
    }
  };
}

export { ChartsAnalytics };
