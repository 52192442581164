import React, { FC } from 'react';

import { Text } from 'new-ui';

import { SearchHistory } from '../../../../components/SearchHistory';

import { TransferHistoryListProps, TransferHistoryItemProps, IHistoryItem } from '../../../../bi/types/transfer';

import styles from './index.module.css';

const TransferHistoryItem: FC<TransferHistoryItemProps> = ({
  item: {
    EndPlace: { Address: AddressEnd },
    StartPlace: { Address: AddressStart },
  },
}) => {
  const startAddress = `${AddressStart ? `${AddressStart}` : ''}`;
  const endAddress = `${AddressEnd ? `${AddressEnd}` : ''}`;

  return (
    <div className={ styles.item }>
      <div className={ styles.title }>
        <Text type='NORMAL_14' className={ styles.departure }>
          { startAddress } – { endAddress }
        </Text>
      </div>
    </div>
  );
};

const TransferHistoryList: FC<TransferHistoryListProps> = ({ list, onSearch }) => {
  if (!list.length) return null;

  return (
    <SearchHistory
      items={ list }
      onClick={ onSearch }
      renderItem={ (item: IHistoryItem) => <TransferHistoryItem item={ item } /> }
    />
  );
};

export { TransferHistoryList };
