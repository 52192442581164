import { action, observable } from 'mobx';

class AccountStore {
  @observable userId: string | null = null;

  @action.bound
  setUserId = (id: string) => {
    this.userId = id;
  };

  isNowUser = (id: string | null) => this.userId === id;
}

export const accountStore = new AccountStore();
