import React, { useEffect, useState, FC } from 'react';
import { observer } from 'mobx-react';
import { Button, Dialog, Text, Select, Datepicker, MultiSelect, Checkbox, Tooltip, Input } from 'new-ui';

import { Moment } from 'moment';
import CONFIG from '../../../../../../config';

import { getText } from '../../../../../../i18n';

import {
  getStartMonth,
  getStartOfDay,
  getEndOfMonth,
  isSameDate,
  startOfMonth,
  subPeriod,
} from '../../../../../bi/utils/formatDate';
import { isEmail } from '../../../../../bi/utils/validation';
import { MainAnalytic } from '../../../../../bi/utils/analytics';

import { DATEFORMATS } from '../../../../../bi/constants/dateFormats';
import { TYPES_ACT, FORMATS_ACT } from '../../../../../bi/constants/report';
import FORMATS from '../../../../../bi/constants/formats';

import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import styles from './styles.module.css';

interface DialogRequestActProps {
  accountId: number,
  companiesItems: { label: string, value: number }[],
  currentCompany: number,
  onRequest: () => void,
  onClose: () => void,
}

const LABELS = {
  TITLE: getText('reports:operations.dialogRequestAct.title'),
  SELECT_PERIOD: getText('reports:operations.dialogRequestAct.selectPeriod'),
  SELECT_FORMAT: getText('reports:operations.dialogRequestAct.selectFormat'),
  SELECT_TYPE: getText('reports:operations.dialogRequestAct.selectType'),
  SELECT_COMPANY: getText('reports:operations.dialogRequestAct.selectCompany'),
  SELECT_ALL: getText('reports:operations.dialogRequestAct.selectAll'),
  REQUEST: getText('reports:operations.dialogRequestAct.request'),
  REQUEST_COMMON_ACT: getText('reports:operations.dialogRequestAct.requestCommonAct'),
  GENERAL_REPORT: getText('reports:operations.dialogRequestAct.generalReport'),
  SEND_REPORT_EMAIL: getText('reports:operations.dialogRequestAct.sendReportEmail'),
  ENTER_EMAIL: getText('reports:operations.dialogRequestAct.enterEmail'),
  UNDO: getText('common:undo'),
  PERIOD_FROM: getText('reports:operations.dialogRequestAct.from'),
  PERIOD_TO: getText('reports:operations.dialogRequestAct.to'),
  TEXT_WARNING: getText('reports:operations.dialogRequestAct.textWarning'),
};

const ID_APPROVAL_EMAIL = 'approvalEmail';

const ERRORLABELS = {
  EMAIL: getText('services:employee.store.employee.emailFormat'),
};

const DialogRequestAct: FC<DialogRequestActProps> = observer(({
  onRequest,
  onClose,
  accountId,
  companiesItems,
  currentCompany,
}) => {
  const {
    requestDialogStore: {
      loadingRequestDialog,
      date,
      dateTo,
      detailed,
      format,
      setDate,
      setDateTo,
      setDetailed,
      setFormat,
      setShowSelection,
      setSelectedCompanies,
      selectedCompanies,
      requestIsValid,
      showSelection,
      reportEmail,
      setReportEmail,
    },
  } = useStores([MOBX_STORES.REQUEST_DIALOG_STORE]);

  useEffect(() => {
    setSelectedCompanies([currentCompany]);
  }, [currentCompany]);

  const maxDateTo = subPeriod(1, 'd');

  const [dateOpen, setDateOpen] = useState<boolean>(false);
  const [dateToOpen, setDateToOpen] = useState<boolean>(false);
  const [showImputEmail, setShowImputEmail] = useState<boolean>(false);
  const [emailIsValid, setEmailIsValid] = useState<boolean>(true);

  const handleChangeDate = (value: Moment | null) => {
    if (!value) {
      setDate(null);

      return;
    }

    const newValue = getStartOfDay(value);
    const firstValue = getStartMonth(value);
    const newDate = isSameDate(newValue, firstValue) ? newValue : firstValue;
    setDate(newDate);

    if (dateTo && newDate > dateTo) {
      setDateTo(isSameDate(newDate, startOfMonth())
        ? maxDateTo
        : getEndOfMonth(newDate),
      );
    }
  };

  const handleChangeDateTo = (value: Moment | null) => {
    if (!value) {
      setDateTo(null);

      return;
    }

    const newDate = getStartOfDay(value);

    setDateTo(newDate);

    if (date && newDate < date) {
      setDate(getStartMonth(newDate));
    }
  };

  const handleChangeFormat = (value: string) => {
    setFormat(value);
  };

  const handleChangeType = (value: number) => {
    setDetailed(value);
  };

  const handleShowSelection = (value: boolean) => {
    if (value) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.TOTAL_ACT_CHOSEN);
    }

    setShowSelection(value);
    setSelectedCompanies([companiesItems[0].value]);
  };

  const handleShowImputEmail = (value: boolean) => {
    setShowImputEmail(value);
    setReportEmail('');
  };

  const handleRequest = () => {
    if (loadingRequestDialog) {
      return;
    }

    onRequest();
  };

  const handleChangeSelectedCompanies = (values: number[]) => {
    setSelectedCompanies(values);
  };

  const handleChangeBlur = () => setDateToOpen(true);

  const handleCheckEmail = (valueReportEmail: string) => {
    setReportEmail(valueReportEmail);
    const isValid = isEmail(valueReportEmail);

    setEmailIsValid(isValid);
  };

  const renderCompaniesList = () => showSelection && (
    <div className={ styles.select }>
      <MultiSelect
        theme='border'
        selectAll
        selectAllLabel={ LABELS.SELECT_ALL }
        list={ companiesItems }
        values={ selectedCompanies }
        onChange={ handleChangeSelectedCompanies }
        overflow
      />
    </div>
  );

  const renderTextWarning = () => (loadingRequestDialog ? (
    <div className={ styles.text_warning }>
      <Text type='NORMAL_12' color='red'>{ LABELS.TEXT_WARNING }</Text>
    </div>
  ) : null);

  const renderCheckBox = () => {
    const checkId = CONFIG.CHECK_GENERAL_REPORT.includes(accountId);
    const showCheckBoxGeneralReport = companiesItems.length > 1 && !checkId;

    return showCheckBoxGeneralReport && (
      <div className={ styles.item }>
        <Text className={ styles.label }>{LABELS.GENERAL_REPORT}</Text>
        <Checkbox
          onChange={ handleShowSelection }
          value={ showSelection }
          className={ styles.checkbox }
        />
        { renderCompaniesList() }
      </div>
    );
  };

  const renderImputEmail = () => {
    const errorEmail = emailIsValid ? '' : ERRORLABELS.EMAIL;

    if (showImputEmail) {
      return (
        <div className={ styles.imputEmail }>
          <Input
            type={ FORMATS.EMAIL }
            placeholder={ LABELS.ENTER_EMAIL }
            id={ ID_APPROVAL_EMAIL }
            value={ reportEmail }
            onChange={ handleCheckEmail }
            error={ errorEmail }
          />
        </div>
      );
    }

    return null;
  };

  const renderCheckBoxEmail = (
    <div className={ styles.itemEmail }>
      <Text className={ styles.labelReportEmail }>{LABELS.SEND_REPORT_EMAIL}</Text>
      <Checkbox
        onChange={ handleShowImputEmail }
        value={ showImputEmail }
        className={ styles.checkbox }
      />
      { renderImputEmail() }
    </div>
  );

  const canDownload = (showSelection && selectedCompanies?.length < 1) || requestIsValid;

  const validEmailBtn = showImputEmail && (!emailIsValid || reportEmail === '');

  const disabledBtn = requestIsValid || canDownload || validEmailBtn;

  const showTooltip = requestIsValid || canDownload;

  return (
    <Dialog show onChange={ onClose }>
      <div className={ styles.wrapper }>
        <Text type='bold_22' className={ styles.title } >{LABELS.TITLE}</Text>
        <div className={ styles.item }>
          <Text type='NORMAL_16' color='default'>
            {LABELS.SELECT_PERIOD}
          </Text>
          <div className={ styles.content }>
            <Text>{LABELS.PERIOD_FROM}</Text>
            <Datepicker
              closeOnTabOut
              isCleansing
              open={ dateOpen }
              type='date'
              wrapperClassName={ styles.date }
              inputClassName={ styles.datepicker }
              value={ date }
              format={ DATEFORMATS.DATE }
              max={ maxDateTo }
              onBlur={ handleChangeBlur }
              onChange={ handleChangeDate }
              getCurrentOpen={ setDateOpen }
              isDuration
              durationDates={ [date, dateTo] }
            />
            <Text>{LABELS.PERIOD_TO}</Text>
            <Datepicker
              closeOnTabOut
              isCleansing
              open={ dateToOpen }
              type='date'
              wrapperClassName={ styles.date }
              inputClassName={ styles.datepicker }
              value={ dateTo }
              max={ maxDateTo }
              format={ DATEFORMATS.DATE }
              onChange={ handleChangeDateTo }
              getCurrentOpen={ setDateToOpen }
              isDuration
              durationDates={ [date, dateTo] }
            />
          </div>
        </div>
        <div className={ styles.item }>
          <Text className={ styles.label }>{LABELS.SELECT_TYPE}</Text>
          <div className={ styles.select }>
            <Select
              theme='default-border'
              items={ TYPES_ACT }
              value={ detailed }
              onChange={ handleChangeType }
              excludeValue
            />
          </div>
        </div>
        <div className={ styles.item }>
          <Text className={ styles.label }>{LABELS.SELECT_FORMAT}</Text>
          <div className={ styles.select }>
            <Select
              theme='default-border'
              items={ FORMATS_ACT }
              value={ format }
              onChange={ handleChangeFormat }
              excludeValue
            />
          </div>
        </div>
        { renderCheckBox() }
        { renderCheckBoxEmail }
        <div className={ styles.action }>
          <Tooltip
            position='right'
            show={ !!showTooltip }
            renderContent={ () => (
              <div>
                <Text type='NORMAL_16' color='white' className={ styles.tooltip_content }>
                  { LABELS.SELECT_COMPANY }
                </Text>
              </div>
            ) }
          >
            <Button
              loading={ loadingRequestDialog }
              className={ styles.button }
              onClick={ () => handleRequest() }
              disabled={ !!disabledBtn }
            >
              { LABELS.REQUEST }
            </Button>
          </Tooltip>
          <Button
            type='textual'
            className={ styles.button }
            onClick={ onClose }
          >
            { LABELS.UNDO }
          </Button>
        </div>
        { renderTextWarning() }
      </div>
    </Dialog>
  );
});

export { DialogRequestAct };
