import React, { useRef } from 'react';
import { Select, Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import { NumberInput } from '../../../../components/NumberInput';
import HelpIcon from '../../../../components/HelpIcon';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { TRAVEL_POLICY_FIELDS_DICTIONARIES } from '../../../../bi/services/travelPolicy/consts';

import { NotGreaterPriceRuleProps } from './types';

import styles from './index.module.css';

const SELECT_ITEMS = [
  { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.NOT_GREATER_PRICE_TYPE.RUBLES,
    label: getText('settings:travelPolicy.rules.notGreaterPrice.rubles'),
  },
  { value: TRAVEL_POLICY_FIELDS_DICTIONARIES.NOT_GREATER_PRICE_TYPE.PERCENTS,
    label: getText('settings:travelPolicy.rules.notGreaterPrice.percents'),
  },
];

const LABELS = {
  HOTELS_INFO: getText('settings:travelPolicy.rules.notGreaterPrice.info'),
  NOT_MORE_THAN: getText('settings:travelPolicy.rules.notGreaterPrice.noMoreThan'),
  THAN: getText('settings:travelPolicy.rules.notGreaterPrice.labels.than'),
};

const getLabel = (type: string) => {
  switch (type) {
    case SERVICETYPE.AIR:
    case SERVICETYPE.TRAIN:
      return getText('settings:travelPolicy.rules.notGreaterPrice.labels.tickets');
    case SERVICETYPE.HOTEL:
      return getText('settings:travelPolicy.rules.notGreaterPrice.labels.hotels');
    default:
      return '';
  }
};

const NotGreaterPriceRule = ({
  isUnique,
  type,
  value,
  onChange,
  onRender,
}: NotGreaterPriceRuleProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { Value: priceValue, ValueType: priceType } = value;

  const infoContent = type === SERVICETYPE.HOTEL && <HelpIcon text={ LABELS.HOTELS_INFO } />;
  const text = getLabel(type);

  return (
    <div className={ styles.wrap } data-qa={ QA_ATTRIBUTES.settings.travelPolicy.addRules.rules.notGreaterPriceRule }>
      { text }
      <Text type='SEMIBOLD_16'>
        &nbsp;{ LABELS.NOT_MORE_THAN }&nbsp;
      </Text>
      { LABELS.THAN }
      <NumberInput
        disabled={ !!isUnique }
        value={ priceValue }
        onChange={ (val: number) => onChange({ ...value, Value: val }) }
        className={ styles.input }
        onRender={ (ref) => {
          onRender(ref);
          inputRef.current = ref;
        } }
      />
      <Select
        disabled={ !!isUnique }
        theme='open-blue'
        items={ SELECT_ITEMS }
        onChange={ (t: string) => {
          onChange({ ...value, ValueType: t });

          if (inputRef.current) {
            inputRef.current.focus();
          }
        } }
        value={ priceType }
      />
      { infoContent }
    </div>
  );
};

export { NotGreaterPriceRule };
