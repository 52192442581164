import React from 'react';
import { StyledWrapper, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import styles from '../../index.module.css';

const LABELS = {
  WARNING_RESERVED: getText('note:warningReserved'),
};

interface IWaitingReserved {
  countReserved: number,
}

const MAX_COUNT = 20;

const WaitingReserved = ({
  countReserved,
}: IWaitingReserved) => {
  if (countReserved !== MAX_COUNT) {
    return null;
  }

  return (
    <StyledWrapper className={ styles.wrapper_warning }>
      <Text type='NORMAL_16'>
        { LABELS.WARNING_RESERVED }
      </Text>
    </StyledWrapper>
  );
};

export { WaitingReserved };
