const EMPLOYEEACTION = {
  EMPLOYEEUPDATE: 'employee.update',
  EMPLOYEECLEAR: 'employee.clear',
  EMPLOYEESET: 'employee.set',
  EMPLOYEELOAD: 'employee.load',
  EMPLOYEESTART: 'employee.start',
  EMPLOYEECITIZENSHIP: 'employee.citizenship',
  EMPLOYEECITIZENSHIPSUGGEST: 'employee.citizenship.suggest',
  EMPLOYEEAIRLINE: 'employee.airline',
  EMPLOYEEAIRLINESUGGEST: 'employee.airline.suggest',
  CHANGE_NO_PATRONYMIC_VALUE: 'change.no.patronymic.value',
  NOT_VALIDATION_PATRONYMIC: 'not.validation.patronymic',
  RESET_NOT_VALIDATION_PATRONYMIC: 'reset.not.validation.patronymic',
  EMPLOYEEUPDATEINPUT: 'employee.update.input',
  EMPLOYEEUPDATECOMPANIES: 'employee.update.companies',
  EMPLOYEEUPDATERUPASSPORT: 'employee.update.ru.passport',
  EMPLOYEEUPDATECERTIFICATENUMBER: 'employee.update.birth.certificate.number',
  EMPLOYEEUPDATEINTPASSPORTNUMBER: 'employee.update.int.passport.number',
  EMPLOYEEUPDATEDOMESTICPASSPORT: 'employee.update.domestic.number',
  EMPLOYEEUPDATETRIPTAGS: 'employee.update.trip.tags',
  EMPLOYEEUPDATEBONUS: 'employee.update.bonus',
  EMPLOYEEVALIDATIONINPUT: 'employee.validation.input',
  EMPLOYEERESETERRORS: 'employee.reset.errors',
  EMPLOYEESETERRORS: 'employee.set.errors',
  EMPLOYEEADDBONUS: 'employee.add.bonus',
  EMPLOYEEFORCEUPDATEBONUS: 'employee.update.force.bonus',
  EMPLOYEEDELETEBONUS: 'employee.delete.bonus',
  EMPLOYEEVALIDATIONSUCCESS: 'employee.validation.success',
  SETTPRIGHTS: 'employee.tp.rights.set',
  UPDATE_CHOOSEN_TP: 'employee.choosen.tp',
  SETIDENTITY: 'employee.useridentity.set',
  EMPLOYEEUPDATECHOSENTP: 'employee.update.chosen.tp',
  EMPLOYEEADDDOCUMENT: 'employee.add.document',
  EMPLOYEEDELETEDOCUMENT: 'employee.delete.document',
  EMPLOYEEDOCUMENTSUPDATEINPUT: 'employee.documents.update.input',
  EMPLOYEERESETERRORSDOCUMENT: 'employee.reset.errors.document',
  EMPLOYEEVALIDATIONDOCUMENTINPUT: 'employee.validation.document.input',
  EMPLOYEESELECTDOCUMENT: 'employee.select.document',
  EMPLOYEEARCHIVED: 'employee.archived',
  EMPLOYEEUNARCHIVED: 'employee.unarchived',
  EMPLOYEEVALIDATEREQUIREDFIELDS: 'employee.validation.form',
  EMPLOYEETRANSLITERATEPATRONYMIC: 'employee.update.patronymic.transliterate',
  EMPLOYEEVALIDATERUSSIANPASSPORTREQUIREDFIELDS: 'employee.validation.passport.russian',
  EMPLOYEEVALIDATECOMMONINFOREQUIREDFIELDS: 'employee.validation.common',
  EMPLOYEESTOPLOAD: 'employee.stop.load',
  SET_PROJECT_VALUE: 'employee.set.project',
  SET_EMPLOYEES_CURRENT_DOCUMENT: 'employee.set.currentDocument',
  SET_EMPLOYEES_PREVIOUS_DOCUMENT: 'employee.set.previousDocument',
  SET_IS_TG_LINKED_ACCOUNT: 'employee.set.isTgLinkedAccount',
};

export default EMPLOYEEACTION;
