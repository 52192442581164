import React, { useState } from 'react';
import { Text, Input, Button, BackLink, Tooltip, Dialog, NoResults } from 'new-ui';

import { getText } from '../../../../i18n';

import { DropZone } from './components/DropZone';
import { DropList } from './components/DropList';
import { DraggableItem } from '../../../components/DraggableItem';

import { MainAnalytic } from '../../../bi/utils/analytics';

import TEXTS from '../../../bi/constants/texts';

import { RequestInterface, RequestInterfaceRecord, RequestInterfaceKeys } from '../../../bi/types/applicationScheme';
import { ErrorState } from '../types';

import styles from './index.module.css';

const LABELS = {
  SCHEME_CREATION: getText('approvalScheme:schemeCreation'),
  CANCEL: getText('common:undo'),
  SCHEME_NAME: getText('approvalScheme:schemeName'),
  DROP_CART: getText('approvalScheme:dropCart'),
  SUBMIT: getText('common:save'),
  IS_APPLIED_TOOLTIP: getText('applicationScheme:tooltip.isApplied'),
  TITLE_NOT_EMPTY: getText('applicationScheme:tooltip.titleAndRequiredFieldMayNotEmpty'),
  ERROR_DIALOG: {
    HEADER: getText('applicationScheme:errorDialog.header'),
    MAY_NOT_TITLE_DUPLICATE: getText('applicationScheme:errorDialog.titleDuplicate'),
  },
};

const KEYS: Record<RequestInterfaceKeys, string> = {
  IsRequiredDestinationCity: 'Город назначения',
  IsRequiredDates: 'Даты',
  IsRequiredBudget: 'Общий бюджет',
  IsRequiredPurpose: 'Цель командировки',
  IsRequiredComment: 'Комментарий',
};

const DEFAULT_APPLICATION_SCHEME_TEMPLATE: RequestInterface = {
  Name: '',
  IsRequiredDestinationCity: false,
  IsRequiredDates: false,
  IsRequiredBudget: false,
  IsRequiredPurpose: false,
  IsRequiredComment: false,
  Id: null,
  AccountId: null,
};

interface ApplicationSchemePageProps {
  onSubmit: any;
  scheme?: RequestInterface;
  header: string,
  goBack: () => void;
  isApplied?: string,
  error: ErrorState,
  handleChangeError: (newError: ErrorState) => void,
  isNew: boolean
}

const ApplicationSchemePage = ({
  onSubmit,
  scheme = DEFAULT_APPLICATION_SCHEME_TEMPLATE,
  header,
  goBack,
  isApplied = '',
  handleChangeError,
  error,
  isNew,
}: ApplicationSchemePageProps) => {
  const [isDragged, setIsDragged] = useState<boolean>(false);
  const [draggableItem, setDraggableItem] = useState<RequestInterfaceKeys | null>(null);
  const [fields, setFields] = useState<RequestInterface>(scheme);

  const checkIsRequiredFields = (request: RequestInterfaceRecord): boolean => Object
    .keys(request)
    .some(key => key.startsWith('IsRequired') && request[key as RequestInterfaceKeys]);

  const handleDragStart = (key: RequestInterfaceKeys) => {
    setIsDragged(true);
    setDraggableItem(key);
  };

  const handleDragEnd = () => {
    setIsDragged(false);
    setDraggableItem(null);
  };

  const renderFields = (getSelectedFields: boolean) => {
    const filteredFields = Object
      .entries(KEYS)
      .filter((entries) => fields[entries[0] as RequestInterfaceKeys] === getSelectedFields);

    return filteredFields.map(([key, text]) =>
      <DraggableItem
        key={ key }
        onDragStart={ () => handleDragStart(key as RequestInterfaceKeys) }
        onDragEnd={ handleDragEnd }
        text={ text }
      />,
    );
  };

  const handleDrop = (isSelected: boolean) => {
    setIsDragged(false);
    setFields({ ...fields, [draggableItem as RequestInterfaceKeys]: isSelected });
    setDraggableItem(null);
  };

  const handleGoBack = () => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_CANCELED_CREATION_NEW_SCHEME);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_CANCELED_CREATION_NEW_SCHEME);

    goBack();
  };

  const handleSubmit = () => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_SAVED);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_SAVED, {
      scheme: isNew ? 'new' : 'edit',
    });

    onSubmit.mutate(fields);
  };

  const handleChangeSchemeName = (value: string) => setFields({ ...fields, Name: value });

  const renderContent = () => (
    <>
      <Input
        className={ styles.name }
        value={ fields.Name }
        placeholder={ LABELS.SCHEME_NAME }
        onChange={ handleChangeSchemeName }
      />

      <div className={ styles.dropContent }>
        <DropZone
          onDrop={ () => handleDrop(true) }
          renderFields={ renderFields(true) }
          isDragged={ isDragged }
          draggableItem={ draggableItem }
        />

        <DropList
          onDrop={ () => handleDrop(false) }
          renderFields={ renderFields(false) }
          isDragged={ isDragged }
          draggableItem={ draggableItem }
        />
      </div>
    </>
  );

  const renderBottomPanel = () => {
    const buttonIsDisabled = !fields.Name || isApplied === 'true' || !checkIsRequiredFields(fields);
    const tooltip: string[] = [];

    if (!fields.Name || !checkIsRequiredFields(fields)) {
      tooltip.push(LABELS.TITLE_NOT_EMPTY);
    }

    if (isApplied === 'true') {
      tooltip.push(LABELS.IS_APPLIED_TOOLTIP);
    }

    return (
      <div className={ styles.bottom }>
        <div className={ styles.actions }>
          <Tooltip
            show={ buttonIsDisabled }
            renderContent={ () => (
              <Text
                className={ styles.tooltip }
                type='NORMAL_14'
                color='white'
              >
                { tooltip.join('.') }
              </Text>
            ) }
          >
            <Button
              type='secondary'
              loading={ onSubmit.isLoading }
              disabled={ buttonIsDisabled }
              onClick={ handleSubmit }
            >
              { LABELS.SUBMIT }
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderErrorContent = (errorTitle: string, text: string) => (
    <div className={ styles.error }>
      <Text
        className={ styles.header }
        type='bold_20'
      >
        { errorTitle }
      </Text>
      <Text>
        { text }
      </Text>
    </div>
  );

  const renderErrorDialogContent = (code: number | null) => {
    const { ERROR_DIALOG: { HEADER, MAY_NOT_TITLE_DUPLICATE } } = LABELS;

    switch (code) {
      case 500:
        return renderErrorContent(HEADER, MAY_NOT_TITLE_DUPLICATE);
      default:
        return <NoResults label={ TEXTS.SOMETHING_WENT_WRONG } />;
    }
  };

  const renderErrorDialog = () => {
    const { show, code } = error;

    return (
      <Dialog
        show={ show }
        showClosing
        onChange={ () => handleChangeError({ show: false, code: null }) }
      >
        <div className={ styles.dialog }>
          { renderErrorDialogContent(code) }
        </div>
      </Dialog>
    );
  };

  return (
    <div className={ styles.wrapper }>
      <BackLink onClick={ handleGoBack }/>
      <Text type='bold_32' >
        { header }
      </Text>
      { renderContent() }
      { renderBottomPanel() }
      { renderErrorDialog() }
    </div>
  );
};

export { ApplicationSchemePage };
