import React from 'react';
import {
  Text,
  StyledWrapper,
  Table,
} from 'new-ui';
import { getText } from '../../../i18n';

import styles from './styles/index.module.css';

const LABELS = {
  title: getText('offer:title'),
  sections: {
    first: {
      title: getText('offer:sections.first.title'),
      paragraphs: [
        getText('offer:sections.first.paragraphs.first'),
        getText('offer:sections.first.paragraphs.second'),
      ],
    },
    second: {
      title: getText('offer:sections.second.title'),
      paragraphs: [
        {
          subtitle: getText('offer:sections.second.paragraphs.first.subtitle'),
          content: getText('offer:sections.second.paragraphs.first.content'),
        },
        {
          subtitle: getText('offer:sections.second.paragraphs.second.subtitle'),
          content: getText('offer:sections.second.paragraphs.second.content'),
        },
        {
          subtitle: getText('offer:sections.second.paragraphs.third.subtitle'),
          content: getText('offer:sections.second.paragraphs.third.content'),
        },
        {
          subtitle: getText('offer:sections.second.paragraphs.fourth.subtitle'),
          content: getText('offer:sections.second.paragraphs.fourth.content'),
        },
        {
          subtitle: getText('offer:sections.second.paragraphs.fifth.subtitle'),
          content: getText('offer:sections.second.paragraphs.fifth.content'),
        },
        {
          subtitle: getText('offer:sections.second.paragraphs.sixth.subtitle'),
          content: getText('offer:sections.second.paragraphs.sixth.content'),
        },
        {
          subtitle: getText('offer:sections.second.paragraphs.seventh.subtitle'),
          content: getText('offer:sections.second.paragraphs.seventh.content'),
        },
        {
          subtitle: getText('offer:sections.second.paragraphs.eighth.subtitle'),
          content: getText('offer:sections.second.paragraphs.eighth.content'),
        },
      ],
    },
    third: {
      title: getText('offer:sections.third.title'),
      paragraphs: [
        getText('offer:sections.third.paragraphs.first'),
        getText('offer:sections.third.paragraphs.second'),
        getText('offer:sections.third.paragraphs.third'),
        getText('offer:sections.third.paragraphs.fourth'),
        getText('offer:sections.third.paragraphs.fifth'),
      ],
    },
    fourth: {
      title: getText('offer:sections.fourth.title'),
      paragraphs: [
        getText('offer:sections.fourth.paragraphs.first'),
        getText('offer:sections.fourth.paragraphs.second'),
        getText('offer:sections.fourth.paragraphs.third'),
        getText('offer:sections.fourth.paragraphs.fourth'),
        getText('offer:sections.fourth.paragraphs.fifth'),
        getText('offer:sections.fourth.paragraphs.sixth'),
        getText('offer:sections.fourth.paragraphs.seventh'),
        getText('offer:sections.fourth.paragraphs.eighth'),
        getText('offer:sections.fourth.paragraphs.ninth'),
      ],
    },
    fifth: {
      title: getText('offer:sections.fifth.title'),
      table: {
        headRow: [
          getText('offer:sections.fifth.table.headRow.first'),
          getText('offer:sections.fifth.table.headRow.second'),
          getText('offer:sections.fifth.table.headRow.third'),
        ],
        firstRow: [
          getText('offer:sections.fifth.table.firstRow.first'),
          getText('offer:sections.fifth.table.firstRow.second'),
          getText('offer:sections.fifth.table.firstRow.third'),
        ],
      },
    },
    sixth: {
      title: getText('offer:sections.sixth.title'),
      paragraphs: [
        getText('offer:sections.sixth.paragraphs.first'),
        getText('offer:sections.sixth.paragraphs.second'),
        getText('offer:sections.sixth.paragraphs.third'),
        getText('offer:sections.sixth.paragraphs.fourth'),
        getText('offer:sections.sixth.paragraphs.fifth'),
      ],
    },
  },
};

const Offer = () => {
  const firstParagraphs = LABELS.sections.first.paragraphs.map((p, index) => (
    <li key={ index } className={ styles.paragraph }>
      <Text type='NORMAL_18'>
        { p }
      </Text>
    </li>));

  const secondParagraphs = LABELS.sections.second.paragraphs.map((p, index) => (
    <li key={ index } className={ styles.paragraph }>
      <Text className={ styles.inline } type='bold_18'>
        { p.subtitle }
      </Text>
      {' '}
      <Text className={ styles.inline } type='NORMAL_18'>
        { p.content }
      </Text>
    </li>));

  const thirdParagraphs = LABELS.sections.third.paragraphs.map((p, index) => (
    <li key={ index } className={ styles.paragraph }>
      <Text type='NORMAL_18'>
        { p }
      </Text>
    </li>));

  const fourthParagraphs = LABELS.sections.fourth.paragraphs.map((p, index) => (
    <li key={ index } className={ styles.paragraph }>
      <Text type='NORMAL_18'>
        { p }
      </Text>
    </li>));

  const tableFirstRow = LABELS.sections.fifth.table.firstRow.map((td, index) => (
    <td key={ index }>{ td }</td>));

  const sixthParagraphs = LABELS.sections.sixth.paragraphs.map((p, index) => (
    <li key={ index } className={ styles.requisite }>
      <Text type='NORMAL_18'>
        { p }
      </Text>
    </li>));

  return (
    <StyledWrapper className={ styles.wrapper }>
      <Text type='bold_22'>{ LABELS.title }</Text>
      <Text className={ styles.section }>
        <Text type='bold_18'>
          { LABELS.sections.first.title }
        </Text>
        <ul>
          { firstParagraphs }
        </ul>
      </Text>
      <Text className={ styles.section }>
        <Text type='bold_18'>
          { LABELS.sections.second.title }
        </Text>
        <ul>
          { secondParagraphs }
        </ul>
      </Text>
      <Text className={ styles.section }>
        <Text type='bold_18'>
          { LABELS.sections.third.title }
        </Text>
        <ul>
          { thirdParagraphs }
        </ul>
      </Text>
      <Text className={ styles.section }>
        <Text type='bold_18'>
          { LABELS.sections.fourth.title }
        </Text>
        <ul>
          { fourthParagraphs }
        </ul>
      </Text>
      <Text className={ styles.section }>
        <Text type='bold_18'>
          { LABELS.sections.fifth.title }
        </Text>
        <div className={ styles.table }>
          <Table
            labelsHeader={ [...LABELS.sections.fifth.table.headRow] }
          >
            <tr>
              { tableFirstRow }
            </tr>
          </Table>
        </div>
      </Text>
      <Text className={ styles.section }>
        <Text type='bold_18'>
          { LABELS.sections.sixth.title }
        </Text>
        <ul>
          { sixthParagraphs }
        </ul>
      </Text>
    </StyledWrapper>
  );
};

export default Offer;
