import { AirRoute } from '../../../types/airline';

/** Функция для получения названия города по коду */
export const createCityNameByCode = (
  code: string,
  ticketRoutes: AirRoute[],
): string => ticketRoutes
  .flatMap(route => route.Segments ?? [])
  .reduce((acc, segment) => {
    const { DepartureCityCode, ArrivalCityCode, DepartureCity, ArrivalCity } = segment;

    if (DepartureCityCode === code) {
      return DepartureCity;
    }

    if (ArrivalCityCode === code) {
      return ArrivalCity;
    }

    return acc;
  }, '');
