import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Text, RadioButton, Button, Tooltip } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context/index';
import { MOBX_STORES } from '../../../../bi/context/stores';

import MainAnalytic from '../../../../bi/utils/analytics/main';
import { isSmartAgent } from '../../../../bi/utils/env';
import { isAirTicketHasCertificate } from '../../../../bi/utils/airline';
import { getFreeFlightCertificate, getTransformationCompanySettings, SettingsType } from '../../../../bi/utils/accountSettings';
import { getEmployeeFullNameWithFeatureFlags } from '../../../../bi/utils/employees';

import { ADDITIONAL_TYPES, ADDITIONAL_MAIN, ADDITIONAL_UNIQ } from '../../../../bi/constants/airlineAdditionalServices';
import { SERVICETYPE, FLIGHT_CERTIFICATE_TYPES } from '../../../../bi/constants/serviceType';
import { TRIPSTATUS, SERVICE_SOURCE_TYPE } from '../../../../bi/constants/trips';
import { BUYTRIPSPERSONALRIGHT } from '../../../../bi/constants/rights';
import ROUTES from '../../../../bi/constants/routes';

import { SelectAdditionalServiceProps, TicketDataInterface } from '../../../../bi/types/airlineAdditionalServices';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('components:additionalServices.radioButtons.title'),
  CHOOSE_SEAT: getText('components:additionalServices.radioButtons.chooseSeat'),
  EXTRA_BAGGAGE: getText('components:additionalServices.radioButtons.extraBaggage'),
  VIP_HALL: getText('components:additionalServices.radioButtons.vipHall'),
  ESCORT: getText('components:additionalServices.radioButtons.escort'),
  ORDER_FLIGHT_CERTIFICATE: getText('components:additionalServices.radioButtons.orderFlightCertificate'),
  OTHER: getText('components:additionalServices.radioButtons.other'),
  NEXT: getText('components:additionalServices.radioButtons.next'),
  COMPLETED: getText('components:tripItem.orderHeader.tooltip.completed'),
  UNCOMPLETED: getText('components:tripItem.orderHeader.tooltip.uncompleted'),
  LIMIT_BUY_VIP_HALL: (name: string, employeeName: string[]) => getText('components:tripItem.orderHeader.tooltip.limitBuyVipHall', { name, employeeName }),
} as const;

const SelectAdditionalService: FC<SelectAdditionalServiceProps> = observer(({
  history,
  match,
  match: { params: { tripId, ticketId } },
  workspaceService: {
    rights: { BuyTripPersonal, EmployeeId },
    travelPolicy,
    canBuyOnlySelf,
    canBuyPersonalUnlimited,
  },
  accountSettings: {
    searchSettings,
  },
  details,
  setChangeTypeCrumbs,
  accessFlightCertificates,
  getTravelPolicyForEmployeeById,
  companiesSettings,
  flightCertificateStatus,
}) => {
  const { travelPolicyStore: { employeeTravelPolicy } } = useStores([MOBX_STORES.TRAVEL_POLICY]);
  const [changeType, setChangeType] = useState('');

  const ticket = details?.OrderItems.find(item => item.Id === +ticketId);
  const ticketEmployee: TicketDataInterface = ticket && ticket.ActualVersion.Employees[0].Id;

  const personalBuy = BuyTripPersonal === BUYTRIPSPERSONALRIGHT.OnlyAfterApprove
    || BuyTripPersonal === BUYTRIPSPERSONALRIGHT.ApprovalScheme;

  const checkTravelPolicy = () => {
    if (BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unlimited && ticketEmployee === EmployeeId) {
      return !travelPolicy || !travelPolicy?.VipHallRule?.AllowBook;
    }

    return false;
  };

  const disabled = !changeType;

  useEffect(() => {
    getTravelPolicyForEmployeeById(ticket.ActualVersion.Employees[0].Id);
  }, []);

  const handleGoPageChange = async () => {
    const isAdditionalMain = Object.keys(ADDITIONAL_MAIN).some((item) => ADDITIONAL_MAIN[item as keyof typeof ADDITIONAL_MAIN] === changeType);
    const isTripFromBackoffice = ticket.ActualVersion.ServiceSourceType === SERVICE_SOURCE_TYPE.BACKOFFICE;
    const checkedFlightSertificate = changeType === ADDITIONAL_UNIQ.ORDER_FLIGHT_CERTIFICATE;

    if ((isAdditionalMain || isTripFromBackoffice) && !checkedFlightSertificate && changeType !== ADDITIONAL_UNIQ.OTHER) {
      setChangeTypeCrumbs(ROUTES.ADDITIONAL_SERVICES.OTHER_CHANGES(tripId, ticketId, changeType));

      return history.push(ROUTES.ADDITIONAL_SERVICES.OTHER_CHANGES(tripId, ticketId, changeType));
    }

    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.VIP_HALL.ADDSERVICES_BUTTON_PRESSED, {
      service_choice: changeType,
    });

    setChangeTypeCrumbs(`${match.url}/${changeType}`);

    return history.push(`${match.url}/${changeType}`);
  };

  const fullName = getEmployeeFullNameWithFeatureFlags(ticket?.ActualVersion.Employees[0]);

  const renderRadioButton = (item: { [field: string]: string }, value: keyof typeof ADDITIONAL_TYPES | keyof typeof ADDITIONAL_MAIN) => {
    if ((item[value] === ADDITIONAL_TYPES.VIP_HALL || item[value] === ADDITIONAL_TYPES.ESCORT)
      && (!employeeTravelPolicy || !employeeTravelPolicy.VipHallRule.AllowBook)
      && !isSmartAgent) {
      const tpEmployeeName = employeeTravelPolicy?.Name || '';

      return (
        <Tooltip
          position='right'
          renderContent={ () => (
            <Text color='white' type='NORMAL_14_120' className={ styles['tooltip-wrapper'] }>
              { LABELS.LIMIT_BUY_VIP_HALL(tpEmployeeName, fullName) }
            </Text>
          ) }
        >
          <RadioButton
            key={ value }
            className={ styles.item }
            value={ item[value] }
            checked={ changeType === item[value] }
            alternativeDesign={ isSmartAgent }
            onChange={ setChangeType }
          >
            <Text color='red'>{ LABELS[value] }</Text>
          </RadioButton>
        </Tooltip>
      );
    }

    return (
      <RadioButton
        key={ value }
        className={ styles.item }
        value={ item[value] }
        checked={ changeType === item[value] }
        alternativeDesign={ isSmartAgent }
        onChange={ setChangeType }
      >
        { LABELS[value] }
      </RadioButton>
    );
  };

  const renderAdditionals = () => {
    if (details?.Status !== TRIPSTATUS.BOOKED) {
      return null;
    }

    const renderItems = (obj: { [key: string]: string }) => Object.keys(obj).map((item) =>
      renderRadioButton(obj, item as keyof typeof ADDITIONAL_TYPES | keyof typeof ADDITIONAL_MAIN));

    return (
      personalBuy ||
      checkTravelPolicy() ||
      (ticketEmployee !== EmployeeId && canBuyOnlySelf) ||
      !searchSettings.includes(SERVICETYPE.VIP_HALL)
        ? renderItems(ADDITIONAL_MAIN)
        : renderItems(ADDITIONAL_TYPES)
    );
  };

  const renderTooltipContent = (label: string) => (
    <Text
      type='NORMAL_14_130'
      color='white'
      className={ styles.tooltip_content }
    >
      { label }
    </Text>
  );

  const renderFlightCertificate = () => {
    const { ActualVersion: { ServiceType, CompanyId } } = ticket;

    const airTicketHasCertificate = details && isAirTicketHasCertificate(details.OrderItems, ticket.Id);
    const removeFlightCertificate = getTransformationCompanySettings({ companiesSettings, CompanyId, setting: SettingsType.removeFlightCertificate });
    const isFreeFlightCertificate = getFreeFlightCertificate(companiesSettings, CompanyId);

    if (!canBuyPersonalUnlimited && !accessFlightCertificates) {
      return null;
    }

    const checkRemoveFlightCertificate = accessFlightCertificates ?
      !accessFlightCertificates :
      removeFlightCertificate;

    if (
      ServiceType !== SERVICETYPE.AIR
      || airTicketHasCertificate
      || details?.Status === TRIPSTATUS.CANCELLED
      || checkRemoveFlightCertificate
      || isFreeFlightCertificate
      || isSmartAgent
    ) {
      return null;
    }

    const isFlightEnded = flightCertificateStatus === FLIGHT_CERTIFICATE_TYPES.UNAVAILABLE ||
      flightCertificateStatus === FLIGHT_CERTIFICATE_TYPES.WAITING;

    const isUnavailable = isFlightEnded ? checkRemoveFlightCertificate : isFlightEnded;

    const tooltipText = isUnavailable ? LABELS.UNCOMPLETED : LABELS.COMPLETED;

    const classDisabled = isUnavailable ? styles.item_disabled : '';

    return (
      <Tooltip
        className={ styles.tooltip_flightCertificate }
        renderContent={ () => renderTooltipContent(tooltipText) }
        position='right'
      >
        <RadioButton
          className={ `${styles.item} ${classDisabled}` }
          disabled={ isUnavailable }
          value={ ADDITIONAL_UNIQ.ORDER_FLIGHT_CERTIFICATE }
          checked={ changeType === ADDITIONAL_UNIQ.ORDER_FLIGHT_CERTIFICATE }
          alternativeDesign={ isSmartAgent }
          onChange={ setChangeType }
        >
          { LABELS.ORDER_FLIGHT_CERTIFICATE }
        </RadioButton>
      </Tooltip>
    );
  };

  const renderOther = () => {
    if (details?.Status !== TRIPSTATUS.BOOKED) {
      return null;
    }

    return (
      <RadioButton
        className={ styles.item }
        value={ ADDITIONAL_UNIQ.OTHER }
        checked={ changeType === ADDITIONAL_UNIQ.OTHER }
        alternativeDesign={ isSmartAgent }
        onChange={ setChangeType }
      >
        { LABELS.OTHER }
      </RadioButton>
    );
  };

  return (
    <div className={ styles.wrapper }>
      <Text type='SEMIBOLD_16' color='blue'>
        { LABELS.TITLE }
      </Text>
      { renderAdditionals() }
      { renderFlightCertificate() }
      { renderOther() }
      <Button
        className={ styles.button }
        type='secondary'
        disabled={ disabled }
        onClick={ handleGoPageChange }
      >
        { LABELS.NEXT }
      </Button>
    </div>
  );
});

export { SelectAdditionalService };
