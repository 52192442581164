import React, { FC } from 'react';
import { Button, Text, NoResults } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import styles from './index.module.css';

const LABELS = {
  NOT_FOUND: getText('transfer:transferResult.notFound'),
  RESET_FILTERS: getText('transfer:transferResult.resetFilters'),
};

interface EmptyFilterProps {
  onResetFilters(): void,
}

const EmptyFilter: FC<EmptyFilterProps> = ({
  onResetFilters,
}) => (
  <NoResults
    label={ LABELS.NOT_FOUND }
    renderContent={ () => (
      <div className={ styles.not_found }>
        <Text type='bold_20'>
          { LABELS.NOT_FOUND }
        </Text>
        <Button
          className={ styles.button }
          type='primary-outline'
          onClick={ onResetFilters }
        >
          { LABELS.RESET_FILTERS }
        </Button>
      </div>
    ) }
    styles={ { height: 'inherit' } }
  />
);

export { EmptyFilter };
