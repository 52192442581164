import React, { useEffect, useState } from 'react';

import { Text, LinkButton, Input, Button, Tooltip } from 'new-ui';
import { getText } from '../../../../../i18n';

import SettingsService from '../../../../bi/services/settings';

import { IAppService } from '../../../../bi/types/app';
import { NotificationService } from '../../../../bi/types/notification';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import { FormWrapper } from '../../../../components/FormWrapper';

import { validPassword } from '../../../../bi/utils/validPassword';

import CLASSNAMESFORTESTS from '../../../../bi/constants/classnamesfortests';
import { DOMEN_HARD_PASSWORD } from './constants/constants';

import styles from './index.module.css';

const LABELS = {
  INCORRECT_PASSWORD: getText('settings:profile.incorrectPassword'),
  SETUP_NEW_PASSWORD: getText('settings:profile.setupNewPassword'),
  PASSWORD_LESS_THAN_SIX: getText('settings:profile.passwordLessThanSix'),
  PASSWORD_LESS_THAN_EIGHT: getText('settings:profile.passwordLessThanEight'),
  OLD_PASSWORD: getText('settings:profile.oldPassword'),
  NEW_PASSWORD: getText('settings:profile.newPassword'),
  NEW_PASSWORD_AGAIN: getText('settings:profile.newPasswordAgain'),
  MISS_MATCH_PASSWORDS: getText('settings:profile.missMatchPasswords'),
  IS_HARD_PASSWORD: getText('settings:profile.isHardPassword'),
  CHANGE_PASSWORD: getText('settings:profile.changePassword'),
  CANCEL: getText('common:undo'),
  EDIT: getText('settings:profile.edit'),
  PASSWORD: getText('settings:profile.password'),
  PASSWORD_PLACEHOLDER: getText('settings:profile.passwordPlaceholder'),
  MY_DATA: getText('settings:profile.myData'),
  EMAIL: getText('settings:profile.email'),
  SAVE_PASSWORD_ERROR: getText('settings:profile.savePasswordError'),
  SAVE_PASSWORD_SUCCESS: getText('settings:profile.savePasswordSuccess'),
  PASSWORD_REQUIREMENTS: getText('settings:profile.passwordRequirements'),
  PASSWORD_RECOMENDATION: getText('settings:profile.passwordRecomendation'),
  PASSWORD_SAFE: {
    SAFE: getText('settings:profile.passwordSafe.yes'),
    NOT_SAFE: getText('settings:profile.passwordSafe.no'),
  },
  ERROR_HARD_PASSWORD: {
    LENGTH: getText('settings:profile.hardPassword.length'),
    EN_SYBMOL_DOWN: getText('settings:profile.hardPassword.enSybmolDown'),
    EN_SYMBOL_UP: getText('settings:profile.hardPassword.enSymbolUp'),
    NUMBERS: getText('settings:profile.hardPassword.numbers'),
    PUNCTUATION: getText('settings:profile.hardPassword.punctuationSign'),
    PUNCTUATIONS: getText('settings:profile.hardPassword.punctuationSigns'),
    CLONE_SYMBOL: getText('settings:profile.hardPassword.cloneSymbol'),
  },
};

interface IProfile {
  settingsService: SettingsService,
  appService: IAppService,
  notificationService: NotificationService,
}

const Profile = ({
  settingsService,
  appService,
  notificationService,
}: IProfile) => {
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');
  const [validationMessage, setValidationMessage] = useState('');

  const updateState = ({ user: { email: userEmail = '' } }) => setEmail(userEmail);

  useEffect(() => {
    const { user: { email: userEmail = '' } } = appService.get();

    const unsubscribe = appService.subscribe(updateState);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.PROFILE_OPEN);

    setEmail(userEmail);

    return unsubscribe;
  }, [appService]);

  const handleTogglePasswordForm = (value: boolean) => {
    setShowForm(value);

    setCurrentPassword('');
    setNewPassword('');
    setNewConfirmPassword('');

    setLoading(false);
  };

  const handleEdit = () => {
    handleTogglePasswordForm(true);
    setError(false);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.PROFILE_EDIT_BUTTON);
  };

  const changePassword = () => {
    setLoading(true);

    settingsService.changePassword({ currentPassword, newPassword, newConfirmPassword })
      .then(() => {
        notificationService.send({
          message: LABELS.SAVE_PASSWORD_SUCCESS,
          level: 'success',
        });

        handleTogglePasswordForm(false);
      })
      .catch(() => {
        notificationService.send({
          message: LABELS.SAVE_PASSWORD_ERROR,
          level: 'success',
        });

        handleTogglePasswordForm(false);
        setError(true);

        MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.PROFILE_SAVE_BUTTON);
      });
  };

  const getValidationMessage = (condition = false) => {
    if (!newPassword) {
      return LABELS.SETUP_NEW_PASSWORD;
    }

    if (condition && newPassword.length < 8) {
      return LABELS.PASSWORD_LESS_THAN_EIGHT;
    }

    if (newPassword.length < 6) {
      return LABELS.PASSWORD_LESS_THAN_SIX;
    }

    return '';
  };

  const renderLabel = (label: string) => (
    <Text
      className={ styles.label }
      color='gray'
    >
      { label }
    </Text>
  );

  const newPasswordLengthIsTrue = newPassword.length >= 6 && newPassword.length < 100;

  const newAndConfirmPasswordsIsSame = newPassword === newConfirmPassword;

  const formIsValid = !!currentPassword && !!newPassword && newPasswordLengthIsTrue && newAndConfirmPasswordsIsSame;

  const showTooltip = !newAndConfirmPasswordsIsSame && !formIsValid;

  const errorAfterSaveHtml = error && !showForm && (
    <Text
      color='red'
      className={ styles.error }
      type='NORMAL_14'
    >
      { LABELS.INCORRECT_PASSWORD }
    </Text>
  );

  const renderStandartPassword = (condition = false) => (
    <Input
      className={ styles.input }
      textClassName={ styles.text_classname }
      type='text'
      id={ CLASSNAMESFORTESTS.CHANGEPASSWORD.INPUTNEW }
      value={ newPassword }
      onChange={ setNewPassword }
      onBlur={ () => setValidationMessage(getValidationMessage(condition)) }
      error={ validationMessage }
    />
  );

  const renderHardPassword = (condition = false) => {
    const {
      validationResult,
      validResultNoRequired,
      validationResultNoRequired,
      isValid,
    } = validPassword(newPassword, email, condition);
    const {
      passwordMinLengthValid,
      passwordNotContainsUserNameSymbols,
    } = validationResult;
    const {
      includesLowercaseENCharsValid,
      includesCapitalENCharsValid,
      includesNumbersValid,
      includesSymbolsValid,
    } = validationResultNoRequired;

    const getTitleColorValid = (isValidRegex: boolean): 'green' | 'red' | 'default' => {
      if (!newPassword.length) return 'default';

      if (!isValidRegex) return 'red';

      return 'green';
    };

    const renderElList = (value: boolean, label: string, additionalLabel = '') => (
      <Text
        color={ getTitleColorValid(value) }
        type='NORMAL_10'
      >
        { label } { additionalLabel }
      </Text>
    );

    const safeText = isValid ? LABELS.PASSWORD_SAFE.SAFE : LABELS.PASSWORD_SAFE.NOT_SAFE;
    const validColor = isValid ? 'green' : 'red';
    const safeColor: 'green' | 'red' | 'default' = newPassword.length ? validColor : 'default';

    const renderTooltip = () => (
      <div className={ styles.tooltip_wrapper }>
        <div
          className={ styles.password_wrapper }
        >
          <Text
            type='NORMAL_12'
          >
            { LABELS.PASSWORD_REQUIREMENTS }
          </Text>
        </div>
        <ul>
          <li> { renderElList(passwordMinLengthValid, LABELS.ERROR_HARD_PASSWORD.LENGTH) } </li>
          <li> { renderElList(passwordNotContainsUserNameSymbols, LABELS.ERROR_HARD_PASSWORD.CLONE_SYMBOL) } </li>
          <li> { renderElList(validResultNoRequired, LABELS.PASSWORD_RECOMENDATION) }</li>
        </ul>
        <ul>
          <li
            className={ styles.li_footer }
          >
            { renderElList(includesLowercaseENCharsValid, LABELS.ERROR_HARD_PASSWORD.EN_SYBMOL_DOWN) }
          </li>
          <li
            className={ styles.li_footer }
          >
            { renderElList(includesCapitalENCharsValid, LABELS.ERROR_HARD_PASSWORD.EN_SYMBOL_UP) }
          </li>
          <li
            className={ styles.li_footer }
          >
            { renderElList(includesNumbersValid, LABELS.ERROR_HARD_PASSWORD.NUMBERS) }
          </li>
          <li
            className={ styles.li_footer }
          >
            { renderElList(includesSymbolsValid, LABELS.ERROR_HARD_PASSWORD.PUNCTUATION, LABELS.ERROR_HARD_PASSWORD.PUNCTUATIONS) }
          </li>
        </ul>
        <div
          className={ styles.password_safe }
        >
          <Text
            className={ styles.password_safe }
            type='NORMAL_12'
            color={ safeColor }
          >
            { safeText }
          </Text>
        </div>
      </div>
    );

    return (
      <Tooltip
        theme='white'
        childrenClassName={ styles.children_tooltip }
        renderContent={ () => renderTooltip() }
        position='right'
      >
        { renderStandartPassword(condition) }
      </Tooltip>
    );
  };

  const renderTooltip = (message: string) => (
    <Text color='white' type='NORMAL_14' className={ styles.tooltip }>
      { message }
    </Text>
  );

  const renderNewPassword = (condition: boolean) => (
    condition ? renderHardPassword(condition) : renderStandartPassword()
  );

  const renderEditForm = () => {
    const isHardPassword = DOMEN_HARD_PASSWORD.includes(email.slice(email.indexOf('@') + 1, email.length).toLowerCase());

    const { isValid } = validPassword(newPassword, email, isHardPassword);

    const isValidDone = !formIsValid || !isValid;
    const isShowTooltip = showTooltip || isValidDone;

    const validMessage = !isValid && isHardPassword ? LABELS.IS_HARD_PASSWORD : LABELS.MISS_MATCH_PASSWORDS;

    return (
      <FormWrapper
        className={ styles.form }
      >
        <div className={ styles.field }>
          { renderLabel(LABELS.OLD_PASSWORD) }
          <div className={ styles.content }>
            <Input
              className={ styles.input }
              type='password'
              id={ CLASSNAMESFORTESTS.CHANGEPASSWORD.INPUTOLD }
              onChange={ setCurrentPassword }
              value={ currentPassword }
            />
          </div>
        </div>
        <div className={ styles.field }>
          { renderLabel(LABELS.NEW_PASSWORD) }
          <div className={ styles.content }>
            { renderNewPassword(isHardPassword) }
          </div>
        </div>
        <div className={ styles.field }>
          { renderLabel(LABELS.NEW_PASSWORD_AGAIN) }
          <div className={ styles.content }>
            <Input
              className={ styles.input }
              type='password'
              id={ CLASSNAMESFORTESTS.CHANGEPASSWORD.INPUTNEWAGAIN }
              onChange={ setNewConfirmPassword }
              value={ newConfirmPassword }
            />
          </div>
        </div>
        <div className={ `${styles.actions} ${styles.field}` }>
          <Tooltip
            show={ isShowTooltip }
            renderContent={ () => renderTooltip(validMessage) }
          >
            <Button
              loading={ loading }
              className={ styles.button }
              onClick={ changePassword }
              disabled={ isValidDone }
            >
              { LABELS.CHANGE_PASSWORD }
            </Button>
          </Tooltip>
          <LinkButton
            className={ styles['second-button'] }
            theme='blue-without-border'
            onClick={ () => handleTogglePasswordForm(false) }
          >
            <Text
              type='NORMAL_14'
              color='accent'
            >
              { LABELS.CANCEL }
            </Text>
          </LinkButton>
        </div>
      </FormWrapper>
    );
  };

  const renderEditButton = (
    <div className={ styles.field }>
      <Text
        className={ styles.label }
        color='gray'
      >
        { LABELS.PASSWORD }
      </Text>
      <div className={ styles.content }>
        <Text>
          { LABELS.PASSWORD_PLACEHOLDER }
        </Text>
        <LinkButton
          className={ styles['second-button'] }
          theme='blue-without-border'
          onClick={ handleEdit }
        >
          <Text color='accent'>
            { LABELS.EDIT }
          </Text>
        </LinkButton>
      </div>
    </div>
  );

  const contentHtml = showForm
    ? renderEditForm()
    : renderEditButton;

  return (
    <div className={ styles.profile }>
      <Text type='bold_24' className={ styles.title }>
        { LABELS.MY_DATA }
      </Text>
      <div className={ styles.field }>
        { renderLabel(LABELS.EMAIL) }
        <Text className={ styles.content }>
          { email }
        </Text>
      </div>
      { contentHtml }
      { errorAfterSaveHtml }
    </div>
  );
};

export { Profile };
