// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text, Button, PROPS, Tooltip } from 'new-ui';

import { getText } from '../../../../../i18n';

import { AirItemApprove } from './components/AirItemApprove';

import { createFromToAirTicketChange } from '../../../../bi/utils/changeAirTrip';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import ROUTES from '../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { ANALYTIC_SERVICE_TYPES } from '../../../../bi/constants/serviceType';

import styles from './styles/index.module.css';

const {
  TEXT: {
    COLORS: {
      WHITE,
    },
    TYPES: {
      NORMAL_14_130,
      BOLD_32,
    },
  },
  ICON: {
    TYPES: {
      ARROWS: {
        ARROW_DOWN_APPROVE_STAGE,
      },
    },
    COLORS: {
      BLACK,
    },
  },
  BUTTON: {
    TYPES: {
      SECONDARY,
      TEXTUAL,
    },
  },
} = PROPS;

const LABELS = {
  TITLE: getText('components:changeAirTrip.confirmationChange.title'),
  INFO_TRAVEL_ASSISTANT: getText('components:changeAirTrip.confirmationChange.infoTravelAssistant'),
  SEND_REQUEST: getText('components:changeAirTrip.sendRequest'),
  DEMO_TOOLTIP: getText('components:changeAirTrip.demo'),
  BACK: getText('components:changeAirTrip.back'),
  NEW_DATA: getText('components:changeAirTrip.changeDocument.newData'),
};

const {
  changeTrip: {
    air: {
      byDateDirectionResult: {
        approve: {
          sendRequest,
          back,
          icon,
          title,
        },
      },
    },
  },
} = QA_ATTRIBUTES;

const ChangeAirTripApproveResult = ({
  history,
  chatService,
  newTicket,
  fareId,
  tripItem,
  tripId,
  onShowApproveChange,
  isDemo,
}) => {
  const itemData = JSON.parse(tripItem?.ActualVersion.JsonData || '');
  const newFare = newTicket.Fares.find(fare => fare.Id === fareId);

  const createMessage = () => {
    const fromTo = createFromToAirTicketChange(newTicket, newFare.Name);
    const message = `
      \n---------------
      ${LABELS.NEW_DATA}:${fromTo}
    `;

    return message;
  };

  const handleEditTripItem = () => {
    if (tripItem) {
      chatService.requestOnChangeByOrder(tripItem, createMessage());
      chatService.sendTypeForAirline(tripItem.Id);
    }
  };

  const handleSendRequest = () => {
    handleEditTripItem();

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_CHANGE_CONFIRM_PRESSED(Number(tripId), ANALYTIC_SERVICE_TYPES.AIR),
    );

    history.push(ROUTES.CHANGE_AIR_TRIP.TRIP(tripId));
  };

  return (
    <div className={ styles.wrapper }>
      <Text qaAttr={ title } type={ BOLD_32 }>
        { LABELS.TITLE }
      </Text>
      <AirItemApprove itemData={ itemData } />
      <div className={ styles.arrow_wrapper }>
        <Icon
          qaAttr={ icon }
          className={ styles.arrow }
          type={ ARROW_DOWN_APPROVE_STAGE }
          color={ BLACK }
        />
      </div>
      <AirItemApprove
        isNewTicket
        itemData={ newTicket }
        metaNewTicket={ { ...itemData, Fare: newFare } }
      />
      <Text className={ styles.info }>
        { LABELS.INFO_TRAVEL_ASSISTANT }
      </Text>
      <div className={ styles.action }>
        <Tooltip
          show={ isDemo }
          renderContent={ () => (
            <Text
              className={ styles.tooltip_text }
              color={ WHITE }
              type={ NORMAL_14_130 }
            >
              { LABELS.DEMO_TOOLTIP }
            </Text>
          ) }
        >
          <Button
            qaAttr={ sendRequest }
            className={ styles.button }
            type={ SECONDARY }
            disabled={ isDemo }
            onClick={ handleSendRequest }
          >
            { LABELS.SEND_REQUEST }
          </Button>
        </Tooltip>
        <Button
          qaAttr={ back }
          className={ styles.button }
          type={ TEXTUAL }
          onClick={ () => onShowApproveChange(false) }
        >
          { LABELS.BACK }
        </Button>
      </div>
    </div>
  );
};

ChangeAirTripApproveResult.propTypes = {
  chatService: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  newTicket: PropTypes.object,
  tripId: PropTypes.string.isRequired,
  fareId: PropTypes.string.isRequired,
  tripItem: PropTypes.object,
  isDemo: PropTypes.bool.isRequired,
  onShowApproveChange: PropTypes.func.isRequired,
};

ChangeAirTripApproveResult.defaultProps = {
  newTicket: null,
  tripItem: null,
};

export { ChangeAirTripApproveResult };
