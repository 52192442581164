import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { PageLoader } from 'new-ui';

import { useHistory } from 'react-router-dom';

import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import ROUTES from '../../../../../bi/constants/routes';

import ChartsAnalytics from '../../../../../bi/services/chartsAnalytics';

import { SummaryFirstPage } from '../../common/firstPages/summary';

import { ChartAnalyticsNoResult } from '../../noResult';

const TrainSummaryFirstPage = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => {
  const {
    chartsAnalyticsSummaryStore,
    chartsAnalyticsStore: { filtersType, filters: { employees, departments, projects, analytics, tags } },
  } = useStores([MOBX_STORES.CHARTS_ANALYTICS_STORE, MOBX_STORES.CHARTS_ANALYTICS_SUMMARY_STORE]);

  const {
    chart: {
      values,
      labels,
      legend,
      dates,
    },
    tableInfo: {
      labelsHeader,
      content,
    },
    loading,
  } = chartsAnalyticsSummaryStore;

  const { getTrainSummary } = chartsAnalyticsService;

  useEffect(() => {
    getTrainSummary();
  }, [
    filtersType,
    employees.selected,
    departments.selected,
    projects.selected,
    analytics.selected,
    tags.selected,
    getTrainSummary,
  ]);

  const history = useHistory();

  const redirectToSubPage = () => history.push(ROUTES.CHARTS_ANALYTICS.TRAIN.SUMMARY_NESTED);

  if (loading) {
    return <PageLoader />;
  }

  if (!values.length) {
    return <ChartAnalyticsNoResult />;
  }

  return (
    <SummaryFirstPage
      values={ values }
      legend={ legend }
      labels={ labels }
      dates={ dates }
      labelsHeader={ labelsHeader }
      content={ content }
      chartsAnalyticsService={ chartsAnalyticsService }
      redirectToSubPage={ redirectToSubPage }
    />
  );
});

export { TrainSummaryFirstPage };
