export const prepareCarStats = (value: { car: any; companies: any; train: { Route: any; StationDepart: any; StationArrive: any; }; }, add: boolean) => {
  const {
    car,
    companies,
    train: { Route, StationDepart, StationArrive },
  } = value;
  const { Number, TrainNumber } = car;

  const fullRoute = Route.join(' – ');
  const selectedRoute = `${StationDepart} – ${StationArrive}`;
  const accountId = companies[0].CompanyId;
  const date = new Date(Date.now()).toISOString();

  return {
    Date: date,
    TrainNumber,
    CarNumber: Number,
    FullRoute: fullRoute,
    SelectedRoute: selectedRoute,
    CompanyId: accountId,
    Event: add ? 1 : 0,
  };
};

export { prepareCarStats as default };
