import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Select, Paginate } from 'new-ui';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { SORT } from '../../../../bi/constants/vipHall';

import { VipHallItem } from '../VipHallItem';

import { VipHallResultPageProps, IItemType } from '../../../../bi/types/vipHall';

import styles from './index.module.css';

const VipHallResultPage: FC<VipHallResultPageProps> = observer(({
  match,
  isVipHallPage,
  onSortSearchResult,
  onChangePage,
}) => {
  const { vipHallStore: { itemsFiltered, paging } } = useStores([MOBX_STORES.VIP_STORE]);

  const [sortBy, setSortBy] = useState(SORT[0].value);

  const handleFilterItems = (item: any) => {
    setSortBy(item);
    onSortSearchResult(item);
  };

  const renderItems = () => itemsFiltered.map((item: IItemType) => (
    <VipHallItem
      key={ item.hallId }
      item={ item }
      match={ match }
      isVipHallPage={ isVipHallPage }
    />
  ));

  return (
    <div className={ styles.items }>
      <div className={ styles.header }>
        <div className={ styles.main }>
          <div className={ styles.select }>
            <Select
              theme='default-large'
              items={ SORT }
              onChange={ handleFilterItems }
              value={ sortBy }
            />
          </div>
        </div>
      </div>
      <div className={ styles.list }>
        { renderItems() }
        <div className={ styles.paging }>
          <Paginate onChange={ onChangePage } page={ paging.current } itemsPerPage={ paging.count } total={ paging.total } />
        </div>
      </div>
    </div>
  );
});

export { VipHallResultPage };
