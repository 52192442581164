import React from 'react';
import { Dialog, Button, Text, LinkButton } from 'new-ui';
import { getText } from '../../../../../i18n';

import { TravelPolicyRuleContainer } from '../TravelPolicyRuleContainer';
import { PolicyRuleHeaderText } from '../PolicyRuleHeader';
import { DirectionTypes } from '../DirectionTypes';
import { RoutesList } from '../RoutesList';

import { TRAVEL_POLICY_FIELDS_DICTIONARIES } from '../../../../bi/services/travelPolicy/consts';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';

import { ServiceTypeSmall } from '../../../../bi/types/order';
import { SuggestsRequestModel } from '../../../../bi/services/travelPolicy/types';

import styles from './index.module.css';

type DirectionsWrapperProps = {
  value: any,
  type: ServiceTypeSmall,
  suggestions: any,
  name: string,
  isUnique: boolean | undefined,
  onChange(p: any): void,
  onAutocompleteChange: (payload: SuggestsRequestModel) => void,
  onAutocompleteClear: () => void,
};

const LABELS = {
  ONLY_DIRECTIONS_TICKETS: getText('settings:travelPolicy.rules.directions.directionsTickets'),
  ONLY_DIRECTIONS_HOTELS: getText('settings:travelPolicy.rules.directions.directionsHotels'),
  ONLY_RUSSIA: getText('settings:travelPolicy.rules.directions.onlyRussia'),
  APPLY: getText('settings:travelPolicy.rules.directions.apply'),
  CANCEL: getText('common:undo'),
  EDIT: getText('settings:travelPolicy.rules.directions.edit'),
  ONLY: getText('settings:travelPolicy.rules.directions.only'),
  ROUTE_RESTRICTION: getText('settings:travelPolicy.rules.directions.routeRestriction'),
};

const getDirectionTypeText = (type: string) => (
  type === SERVICETYPE.HOTEL ? LABELS.ONLY_DIRECTIONS_HOTELS : LABELS.ONLY_DIRECTIONS_TICKETS
);

const getDirectionText = (checked: boolean, type: string, routes: any[], directionType: number) => {
  if (!checked) {
    return getDirectionTypeText(type);
  }

  if (directionType === TRAVEL_POLICY_FIELDS_DICTIONARIES.DIRECTION_TYPES.RUSSIA) {
    return LABELS.ONLY_RUSSIA;
  }

  const directionsToBeDisplayed = routes.filter(({ isValid, isEmpty }) => isValid && !isEmpty);

  if (directionsToBeDisplayed.length) {
    const chosenDirections = directionsToBeDisplayed
      .map(({ fullName }) => fullName)
      .join(', ');

    return `${getDirectionTypeText(type)}: ${chosenDirections}`;
  }

  return getDirectionTypeText(type);
};

const prepareCheckboxOnChangePayload = (val: boolean): any => {
  if (val) {
    return { showDialog: true };
  }

  return { IsEnabled: false };
};

const DirectionsWrapper = ({
  isUnique,
  value,
  type,
  suggestions,
  name,
  onChange,
  onAutocompleteChange,
  onAutocompleteClear,
}: DirectionsWrapperProps) => {
  const { IsEnabled: checked, showDialog, Type: directionsType, Routes: routes, showValidation } = value;
  const linkText = getDirectionText(checked, type, routes, directionsType);

  const preparedOnDialogClose = (onChangeFn: () => void) => {
    onChangeFn();
    onAutocompleteClear();
  };

  const preparedOnPreviewClick = () => {
    onChange({ ...value, showDialog: true });
  };

  const showRoutesList = showDialog && directionsType === TRAVEL_POLICY_FIELDS_DICTIONARIES.DIRECTION_TYPES.CHOSEN;

  const ruleContent = (
    <div className={ styles.rule }>
      <Text className={ styles.text }>
        { LABELS.ONLY }
        {' '}
        { checked && linkText }
      </Text>
      <LinkButton
        className={ styles.link }
        theme='blue-without-border'
        onClick={ preparedOnPreviewClick }
      >
        { checked ? LABELS.EDIT : linkText }
      </LinkButton>
    </div>
  );

  const dialogContent = (
    <>
      <Text
        className={ styles.header }
        type='bold_20'
      >
        { LABELS.ROUTE_RESTRICTION }
      </Text>
      <PolicyRuleHeaderText serviceType={ type } apply name={ name } />
      <DirectionTypes
        type={ type }
        value={ directionsType }
        onChange={ t => onChange({ ...value, Type: t, showValidation: false }) }
      />
      <div className={ styles.routes }>
        <RoutesList
          type={ type }
          list={ routes }
          showValidation={ showValidation }
          show={ showRoutesList }
          onChange={ r =>
            onChange({
              ...value,
              Routes: r,
              showValidation: false,
              Type: TRAVEL_POLICY_FIELDS_DICTIONARIES.DIRECTION_TYPES.CHOSEN,
            })
          }
          onAutocompleteChange={ onAutocompleteChange }
          suggestions={ suggestions }
        />
      </div>
    </>
  );

  const dialogActionsContent = (
    <div className={ styles.actions }>
      <Button
        onClick={ () => {
          const fn = () =>
            preparedOnDialogClose(() => onChange({ ...value, IsEnabled: true, showDialog: false }));

          if (directionsType === TRAVEL_POLICY_FIELDS_DICTIONARIES.DIRECTION_TYPES.CHOSEN) {
            const isRoutesValid = routes.every(({ isValid }: { isValid: boolean }) => isValid);

            return isRoutesValid ? fn() : onChange({ ...value, showValidation: true });
          }

          return fn();
        } }
      >
        { LABELS.APPLY }
      </Button>
      <Button
        type='textual-medium'
        className={ styles.cancel }
        onClick={ () =>
          preparedOnDialogClose(() => onChange({ ...value, IsEnabled: false, showDialog: false }))
        }
      >
        { LABELS.CANCEL }
      </Button>
    </div>
  );

  return (
    <>
      <TravelPolicyRuleContainer
        isUnique={ !!isUnique }
        value={ checked }
        onChange={ (val: boolean) => onChange({ ...value, ...prepareCheckboxOnChangePayload(val) }) }
        renderField={ () => ruleContent }
      />
      <Dialog
        showClosing
        show={ showDialog }
        onChange={ () => preparedOnDialogClose(() => onChange({ ...value, showDialog: false })) }
      >
        <div className={ styles.dialog }>
          { dialogContent }
          { dialogActionsContent }
        </div>
      </Dialog>
    </>
  );
};

export { DirectionsWrapper };
