import React, { useState, useEffect } from 'react';

import { Place } from '../Place';
import { SexIcon } from '../SexIcon';

import {
  ISection,
  ISelectedPlace,
  ICompartSection,
  IPlace,
  ICompartDetails,
} from '../../../../../../bi/types/train';
import AppService from '../../../../../../bi/services/app';

import styles from './index.module.css';

interface ILevelSection {
  appService: AppService,
  entireCompartment: boolean,
  section: ISection,
  selectedPlaces: ISelectedPlace[],
  handleSelectedPlace: (place: IPlace) => void,
  choiceInitiaPlace: () => void,
  onSelectedSection: (section: ISection) => void,
  isSapsanFamily: boolean,
  differentTrains: boolean,
}

const LevelSection: React.FC<ILevelSection> = ({
  entireCompartment,
  section,
  section: {
    places,
    litter,
    css,
  },
  selectedPlaces,
  handleSelectedPlace,
  choiceInitiaPlace,
  onSelectedSection,
  isSapsanFamily,
  differentTrains,
  appService,
}) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (entireCompartment) {
      onSelectedSection(section);
    } else {
      choiceInitiaPlace();
    }
  }, [entireCompartment]);

  const preparePrice = (
    price: number | undefined,
    travellers?: number,
    buyFullPrices?: Record<number, number> | null,
    buyFullPlaces?: Record<number, number> | null,
  ): number | undefined => {
    if (!buyFullPlaces || !buyFullPrices || !travellers) return price;

    const costByTravellers = buyFullPrices[travellers];

    return costByTravellers || price;
  };

  const loadCompartDetails = (
    place: IPlace,
    compartSection: ISection | ICompartSection,
  ): ICompartDetails | null => {
    if (compartSection.buyFullCompartment && compartSection.compartmentPlaces?.includes(place.number.toString())) {
      return {
        specialTariff: compartSection.SpecialTariff,
        compartmentPrice: compartSection.compartmentPrice,
        compartmentAltPrice: compartSection.compartmentAltPrice,
      };
    }

    return null;
  };

  const reduceMiniSections = (
    place: IPlace,
    miniSections: ICompartSection[],
  ): ICompartDetails | null =>
    miniSections.reduce<ICompartDetails | null>((result, miniSection) => {
      const details = loadCompartDetails(place, miniSection);

      return details ? { ...result, ...details } : result;
    }, null);

  const carPlacesHtml: JSX.Element[] = places.map((place: IPlace) => {
    const { buyFullPlaces, buyFullPrices, buyFullAltPrices, travellers, price, altPrice, number: placeNumber, qaAttr } = place;
    const selectedPlace = selectedPlaces.find(({ number }) => number === placeNumber);

    const priceOfSelected: number | undefined = selectedPlace ? selectedPlace.price : undefined;
    const altPriceOfSelected: number | undefined = selectedPlace ? selectedPlace.altPrice : undefined;

    const readyPrice: number | undefined = selectedPlace
      ? priceOfSelected
      : preparePrice(price, travellers, buyFullPrices, buyFullPlaces);

    const readyAllPrice: number | undefined = entireCompartment
      ? preparePrice(price, travellers, buyFullAltPrices, buyFullPlaces)
      : readyPrice;

    const readyAltPrice: number | undefined = selectedPlace
      ? altPriceOfSelected
      : preparePrice(altPrice, travellers, buyFullAltPrices, buyFullPlaces);

    const readyAllAltPrice: number | undefined = entireCompartment
      ? preparePrice(altPrice, travellers, buyFullAltPrices, buyFullPlaces)
      : readyAltPrice;

    const disabledPlace: boolean = entireCompartment
      ? !selectedPlaces.some(({ number }) => number === place.number)
      : place.disabled;

    const compartDetails: ICompartDetails | null = section.miniSections
      ? reduceMiniSections(place, section.miniSections)
      : loadCompartDetails(place, section);

    const updatedPlace: IPlace = {
      ...place,
      price: readyAllPrice,
      altPrice: readyAllAltPrice,
      disabled: disabledPlace,
      compartDetails,
    };

    return (
      <Place
        appService={ appService }
        key={ updatedPlace.number }
        place={ updatedPlace }
        selected={ !!selectedPlace }
        handleClick={ handleSelectedPlace }
        hovered={ hovered }
        onHover={ setHovered }
        qaAttr={ qaAttr }
        isSapsanFamily={ isSapsanFamily }
        differentTrains={ differentTrains }
      />
    );
  });

  const sexIconHtml: JSX.Element | null = litter ? (
    <SexIcon
      height={ css.height }
      litter={ litter }
    />
  ) : null;

  return (
    <div style={ { ...css } } className={ styles.section }>
      { carPlacesHtml }
      { sexIconHtml }
    </div>
  );
};

export { LevelSection };
