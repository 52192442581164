import moment, { Moment } from 'moment';

import ACTION from '../action';
import Store from '../../../store';

import { validateSearchFields } from '../../../utils/airline';

import { FLY_CLASS_ENUM } from '../../../constants/airline';
import DIRECTIONS from '../../../constants/directions';

import {
  AirSearchRoute,
  IAirSearchRouteItem,
  AirSearchRouteFromTo,
  RoutesHistory,
  IMapRoute,
} from '../../../types/airline';

interface IAirlineSearch {
  textTemplateWarning: string;
  isValid: boolean;
  isComplex: boolean;
  isDirect: boolean;
  travellers: number | null;
  flightClass: typeof FLY_CLASS_ENUM[keyof typeof FLY_CLASS_ENUM];
  routes: AirSearchRoute[];
  isTravelApproval: boolean;
  schemeLoading: boolean;
  isSkeleton: boolean;
  temperatureScale?: string;
  travellersSA: {
    infant: number,
    children: number,
    adult: number
  }
}

export type RouteField = 'from' | 'to';

const MAXROUTE = 6;

const createRoute = (
  date: Moment = moment().startOf('d'),
  from: Partial<AirSearchRouteFromTo> = {},
): AirSearchRoute => ({
  from: {
    label: '',
    selected: null,
    suggestions: [],
    ...from,
  },
  to: {
    label: '',
    selected: null,
    suggestions: [],
  },
  date,
  minDate: moment().startOf('d'),
  dateBack: '',
});

const createNewState = (): IAirlineSearch => ({
  textTemplateWarning: '',
  isValid: false,
  isComplex: false,
  isDirect: false,
  travellers: 1,
  flightClass: FLY_CLASS_ENUM.ECONOM,
  routes: [
    createRoute(),
  ],
  isTravelApproval: false,
  schemeLoading: false,
  isSkeleton: false,
  travellersSA: {
    infant: 0,
    children: 0,
    adult: 1,
  },
});

const formatDefaultSuggests = (
  list: IAirSearchRouteItem[],
  field: string,
  route: AirSearchRoute,
): IAirSearchRouteItem[] => {
  const clonedItems = [...list];

  const oppositeSelected = field === DIRECTIONS.FROM ? route.to.selected : route.from.selected;

  const indexToRemove = oppositeSelected && (oppositeSelected as IAirSearchRouteItem).LocationID
    ? clonedItems.findIndex(suggest => suggest.LocationID === (oppositeSelected as IAirSearchRouteItem).LocationID)
    : -1;

  if (indexToRemove === -1) {
    clonedItems.splice(4, 1);
  } else {
    clonedItems.splice(indexToRemove, 1);
  }

  return clonedItems;
};

const reducer = (
  action: { type: string, payload: any, value?: any, item?: any },
  state: IAirlineSearch,
) => {
  let newState = {
    ...state,
  };

  switch (action.type) {
    case ACTION.INITHISTORY: {
      const { Routes, Class, TravellersCount, IsDirect, TemperatureScale } = action.payload;

      const mapRoute = (route: RoutesHistory, dateBack: string = ''): AirSearchRoute => ({
        from: {
          label: route.DepartureName,
          selected: {
            Code: route.DepartureCode,
            City: route.DepartureName,
          },
          suggestions: [],
        },
        to: {
          label: route.ArrivalName,
          selected: {
            Code: route.ArrivalCode,
            City: route.ArrivalName,
          },
          suggestions: [],
        },
        minDate: moment().startOf('d'),
        date: moment(route.Date).startOf('d'),
        dateBack: dateBack ? moment(dateBack).startOf('d') : dateBack,
      });

      let isComplex = false;
      let routes = [];

      switch (Routes.length) {
        case 1: {
          routes.push(mapRoute(Routes[0]));
          break;
        }
        case 2: {
          const firstRoute = Routes[0];
          const secondRoute = Routes[1];

          if (firstRoute.DepartureCode === secondRoute.ArrivalCode && firstRoute.ArrivalCode === secondRoute.DepartureCode) {
            routes.push(mapRoute(Routes[0], secondRoute.Date));
          } else {
            isComplex = true;
            routes = Routes.map(mapRoute);
          }

          break;
        }
        default: {
          isComplex = true;
          routes = Routes.map(mapRoute);
          break;
        }
      }

      newState.isDirect = IsDirect;
      newState.isComplex = isComplex;
      newState.travellers = TravellersCount;
      newState.flightClass = Class;
      newState.routes = routes;
      newState.temperatureScale = TemperatureScale;
      newState.isValid = routes[0].from.selected && routes[0].to.selected;
      break;
    }
    case ACTION.NEWSEARCH: {
      newState = {
        ...createNewState(),
      };
      break;
    }

    case ACTION.SCHEME_LOADING: {
      return {
        ...state,
        schemeLoading: action.value,
      };
    }

    case ACTION.WARNINGTEMPLATE: {
      return {
        ...state,
        textTemplateWarning: action.payload?.content?.text || '',
      };
    }

    case ACTION.SKELETON_SEARCH: {
      return {
        ...state,
        isSkeleton: action.payload,
      };
    }

    case ACTION.SET_ROUTES: {
      return {
        ...state,
        routes: action.payload,
      };
    }

    case ACTION.NEW_SEARCH_FROM_APPROVE_REQUEST: {
      const { item } = action;

      if (item) {
        const employeesLength = item.Employees.length;

        const mapRoute = ({
          StartDate,
          EndDate,
          Destinations,
          minDate,
        }: IMapRoute): AirSearchRoute => ({
          from: {
            label: '',
            selected: {
              Code: '',
              City: '',
            },
            suggestions: [],
          },
          to: {
            label: Destinations ? `${Destinations?.City} (${Destinations?.Code})` : '',
            selected: Destinations,
            suggestions: [],
          },
          minDate,
          date: StartDate,
          dateBack: EndDate,
        });

        return {
          ...state,
          routes: [
            mapRoute(item),
          ],
          travellers: employeesLength > 9 ? 9 : employeesLength,
        };
      }

      return {
        ...createNewState(),
      };
    }

    case ACTION.UPDATECOMPLEXCHECKBOX: {
      newState.isComplex = action.payload;

      if (!newState.isComplex && newState.routes.length > 1) {
        newState.routes = newState.routes.splice(0, 1);
      }

      break;
    }
    case ACTION.UPDATEDIRECTCHECKBOX: {
      newState.isDirect = action.payload;
      break;
    }
    case ACTION.UPDATEFLYCLASS: {
      newState.flightClass = action.payload;
      break;
    }
    case ACTION.UPDATEADULT: {
      newState.travellers = action.payload;
      break;
    }
    case ACTION.UPDATE_CHILD: {
      newState.travellersSA = action.payload;
      // newState.travellers = null;
      // добавить потом

      break;
    }
    case ACTION.ADDSEARCHROUTE: {
      if (newState.routes.length < MAXROUTE) {
        const date = newState.routes[(newState.routes.length - 1)].date;
        const prevRouteTo = newState.routes[newState.routes.length - 1].to;
        newState.routes.push(createRoute(date, prevRouteTo));
        newState.isValid = validateSearchFields(newState.routes);
      }

      break;
    }
    case ACTION.REMOVESEARCHROUTE: {
      if (newState.routes.length > 1) {
        newState.routes.splice(action.payload, 1);

        newState.isValid = validateSearchFields(newState.routes);
      }

      break;
    }
    case ACTION.UPDATEDATE: {
      const route = newState.routes[action.payload.key];
      route.date = action.payload.value;

      if (route.dateBack && moment.isMoment(route.dateBack) && route.dateBack.isBefore(route.date)) {
        route.dateBack = route.date.clone().add(1, 'd');
      }

      newState.isValid = validateSearchFields(newState.routes);

      break;
    }
    case ACTION.UPDATEDATEBACK: {
      const route = newState.routes[action.payload.key];
      route.dateBack = action.payload.value || '';
      break;
    }
    case ACTION.UPDATESUGGESTS: {
      const { list, key, field, query, loading } = action.payload;

      const route: AirSearchRoute = newState.routes[key];
      route[field as RouteField].label = query;

      if (!query) route[field as RouteField].selected = null;

      const suggests: IAirSearchRouteItem[] = [];
      const readyList = query === '' ? formatDefaultSuggests(list, field, newState.routes[key]) : list;
      readyList.forEach((item: IAirSearchRouteItem) => suggests.push({
        ...item,
        items: item.ChildLocation ?? [],
      }));

      route[field as RouteField].suggestions = suggests;
      route[field as RouteField].loading = loading;
      newState.isValid = validateSearchFields(newState.routes);
      break;
    }
    case ACTION.UPDATE_SUGGESTS_LOADING: {
      const { field, key, loading } = action.payload;

      newState.routes[key][field as RouteField].loading = loading;
      break;
    }
    case ACTION.CLEARSUGGEST: {
      const { field, key } = action.payload;

      newState.routes[key][field as RouteField].suggestions = [];
      break;
    }
    case ACTION.SELECTSUGGEST: {
      const { key, value, field } = action.payload;

      const route = newState.routes[key];
      route[field as RouteField].selected = value;
      route[field as RouteField].suggestions = [];
      route[field as RouteField].label = `${value.City} (${value.Code})`;
      newState.isValid = validateSearchFields(newState.routes);
      break;
    }

    case ACTION.FLIPFROMTO: {
      const route = newState.routes[action.payload];
      const fromObj = { ...route.from };
      route.from = { ...route.to };
      route.to = fromObj;
      break;
    }
  }

  return newState;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
