import React from 'react';
import { observer } from 'mobx-react';
import { Button, Table, Text, PageLoader } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import { ChartAnalyticsFilters } from '../../../header/filters';

import { ContentType } from '../../../../../../bi/services/chartsAnalytics/types';

import styles from './index.module.css';

const LABELS = {
  EXCEL: getText('expenseReports:report.download.excel'),
};

interface ISmartIndexTable {
  loading: boolean,
  title?: string,
  tableInfo: {
    labelsHeader: string[],
    content: ContentType[],
  },
  chartsAnalyticsService: ChartsAnalytics,
  onOpen(subPageInfo: string, serviceType: string): void,
  serviceType: string,
  type: string,
}

const SmartIndexTable = observer(({
  loading,
  title = '',
  tableInfo: {
    labelsHeader,
    content,
  },
  chartsAnalyticsService,
  onOpen,
  serviceType: servType,
}: ISmartIndexTable) => {
  const {
    updateSmartIndexTable,
    setDepartmentsSelected,
    setProjectsSelected,
    setEmployeesSelected,
    setTagsSelected,
    setAutocompleteEmployees,
    setAnalyticsSelected,
    setAutocompleteDepartments,
    setAutocompleteProjects,
    setAutocompleteTags,
    downloadSmartXls,
    setFiltersType,
    resetPaginate,
  } = chartsAnalyticsService;

  const renderTitle = () => {
    if (!title.length) {
      return null;
    }

    return (
      <Text type='bold_24'>{ title }</Text>
    );
  };

  const handleDownload = () => downloadSmartXls();

  const renderHeader = () => (
    <div className={ styles.header }>
      <div className={ styles.row }>
        { renderTitle() }
        <Button type='textual' onClick={ () => handleDownload() }>
          { LABELS.EXCEL }
        </Button>
      </div>
    </div>
  );

  const mapperData = (value: string, indexData: number) => {
    if (indexData === 0) return null;

    return (
      <td key={ indexData }>{ value }</td>
    );
  };

  const renderTable = () => {
    if (loading) {
      return <PageLoader />;
    }

    return (
      <div className={ styles.table }>
        <Table labelsHeader={ labelsHeader }>
          { content.map(({ data, serviceType, subPageInfo }, indexRow: number) => (
            <tr key={ indexRow } onClick={ () => onOpen(subPageInfo, serviceType) }>
              <td className={ styles.name }>{ data[0] }</td>
              { data.map((value, indexData) => mapperData(value, indexData)) }
            </tr>
          )) }
        </Table>
      </div>
    );
  };

  const propsFilters = {
    type: servType.toLowerCase(),
    setDepartments: setDepartmentsSelected,
    setEmployees: setEmployeesSelected,
    setProjects: setProjectsSelected,
    getInfo: updateSmartIndexTable,
    setTags: setTagsSelected,
    setAnalytics: setAnalyticsSelected,
    setAutocompleteEmployees,
    setAutocompleteDepartments,
    setAutocompleteProjects,
    setAutocompleteTags,
    setFiltersType,
    resetPaginate,
  };

  return (
    <div className={ styles.wrapper }>
      { renderHeader() }
      <ChartAnalyticsFilters { ...propsFilters }/>
      { renderTable() }
    </div>
  );
});

export { SmartIndexTable };
