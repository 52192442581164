const ACTION = {
  UPDATEUSERNAME: 'update.username',
  UPDATEPASSWORD: 'update.password',
  LOGINRESULT: 'login.result',
  LOGINSTART: 'login.start',
  UPDATEBUTTONDISABLED: 'update.button.disabled',
  UPDATEERROR: 'update.error',
};

export default ACTION;
