import React from 'react';
import { observer } from 'mobx-react';
import { Text } from 'new-ui';

import { useServices } from '../../../../bi/context/services';

import { BlockTooltip } from '../BlockTooltip';
import { AddButton } from '../../../../components/AddButton';
import { RemovableListItem } from '../../../../components/RemovableListItem';

import { getEmployeeFullName } from '../../../../bi/utils/employees';

import { LABELS } from '../../consts';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { DialogTypes } from '../../../../bi/services/departments/types';

import styles from './styles.module.css';

export const HeadSelect = observer(() => {
  const {
    departmentsService: {
      removeHead,
      store: {
        isNewDepartment,
        headOfDepartment,
        companyNotSelected,
        headOfDepartment: { Id },
        openDialog,
      },
    },
  } = useServices(['Departments']);

  const handleRemoveAction = isNewDepartment
    ? removeHead
    : openDialog(DialogTypes.deleteHead, headOfDepartment);

  const renderHeadOfDepartmentContent = Id ? (
    <RemovableListItem
      value={ headOfDepartment }
      renderLabel={ getEmployeeFullName }
      onClick={ openDialog(DialogTypes.editHead, headOfDepartment) }
      onChangeStatus={ handleRemoveAction }
    />
  ) : (
    <AddButton
      disabled={ companyNotSelected }
      text={ LABELS.ADD_HEAD_OF_DEPARTMENT }
      onClick={ openDialog(DialogTypes.editHead) }
      qaAttr={ QA_ATTRIBUTES.settings.departments.create.leaderChoose }
    />
  );

  return (
    <div className={ styles.content }>
      <Text type='bold_24'>
        { LABELS.HEAD }
      </Text>
      <Text
        className={ styles.headHint }
        type='NORMAL_14'
        color='gray'
      >
        { LABELS.HEAD_HINT }
      </Text>
      <BlockTooltip>
        { renderHeadOfDepartmentContent }
      </BlockTooltip>
    </div>
  );
});
