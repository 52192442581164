import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Text, StyledWrapper, Checkbox, Price } from 'new-ui';

import { getText } from '../../../../../i18n/index';

import { ADDITIONAL_TYPES } from '../../../../bi/constants/vipHall';

import { SelectCountAdditional } from './components/SelectCountAdditional/index';

import { AdditionalsProps, AdditionalsVips } from '../../../../bi/types/vipHall';

import styles from './index.module.css';

const LABELS = {
  RUB: getText('vipHall:item.rub'),
  FREE: getText('vipHall:item.free'),
};

const Additionals: FC<AdditionalsProps> = observer(({
  index,
  service,
  additionalServices,
  onChangeAdditional,
  onChangeCount,
}) => {
  const { type_label, description, cost, billing, capacity } = service;

  const isHour = billing === ADDITIONAL_TYPES.HOUR;
  const isIncludedAdditional = additionalServices.some((item: AdditionalsVips) => item.index === index);

  const costHtml = cost !== 0 ? (
    <Price
      value={ cost }
      type='bold_24'
      costType={ LABELS.RUB }
    />
  ) : (
    <div className={ styles.pay }>
      <Text type='bold_24' className={ styles.cost }>{ LABELS.FREE }</Text>
    </div>
  );

  const inputHtml = isIncludedAdditional && isHour && capacity > 1 ? (
    <SelectCountAdditional
      index={ index }
      service={ service }
      additionalServices={ additionalServices }
      onChangeCount={ onChangeCount }
    />
  ) : null;

  return (
    <StyledWrapper className={ styles['additional-block'] } key={ index }>
      <div className={ styles.additional }>
        <div className={ styles.checkbox }>
          <Checkbox
            value={ isIncludedAdditional }
            onChange={ () => onChangeAdditional(service, index) }
          />
          <div className={ styles['checkbox-label'] }>
            <Text type='NORMAL_18' className={ styles['checkbox-name'] }>{ type_label }</Text>
            <Text type='NORMAL_14' color='gray'>{ description }</Text>
          </div>
        </div>
        { costHtml }
      </div>
      <div>
        { inputHtml }
      </div>
    </StyledWrapper>
  );
});

export { Additionals };
