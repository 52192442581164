const NOTEACTION = {
  LOAD: 'note.booking.load',
  UPDATE: 'note.booking.update',
  UPDATE_ERROR: 'note.booking.error',
  RESET_ERROR: 'note.booking.resetError',
  NOTELOAD: 'note.load',
  NOTEUPDATE: 'node.update',
  UPDATE_TRIP_BY_IDS: 'node.trip.byIds',
  NOTETYPEFILTERUPDATE: 'note.type.filter.update',
  FILTERCLEAR: 'note.filter.clear',
  SEARCHUPDATE: 'note.search.update',
  SELECTUPDATE: 'note.select.update',
  SELECTALLUPDATE: 'note.selectAll.update',
  SELECT_MULTI_UPDATE: 'note.selectMulti.update',
  FOULUPDATE: 'note.foul.update',
  UPDATEFOUL: 'note.update.foul',
  CLOSEDIALOG: 'note.close.dialog',
  NOTE_USER_ID: 'note.userId',
  UPDATE_TAGS: 'note.update.tags',
  UPDATE_RULES: 'note.update.rules',
};

export default NOTEACTION;
