import React, { useMemo } from 'react';
import { Text } from 'new-ui';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import styles from '../style.module.css';

const createLabels = (t: TFunction) => ({
  ADD_FEE: t('cart:addFee:itemLayout:addFee'),
  ADD_FEE_PRICE: (price: string) => t('cart:addFee:itemLayout:price', { price }),
});

interface IAddFeeProps {
  agentFee: string;
  isAddFee: boolean;
}

export const AddFee = ({
  agentFee,
  isAddFee,
}: IAddFeeProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  if (!isAddFee) {
    return null;
  }

  return (
    <div className={ styles.fee_wrapper }>
      <Text color='gray'>
        { LABELS.ADD_FEE }
      </Text>
      <Text color='gray'>
        { LABELS.ADD_FEE_PRICE(agentFee)}
      </Text>
    </div>
  );
};
