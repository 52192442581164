import React from 'react';
import { Icon, Text } from 'new-ui';
import { RouteComponentProps } from 'react-router-dom';

import { getText } from '../../../../../i18n';

import { MainAnalytic } from '../../../../bi/utils/analytics';
import { LABELS as ANALYTICS_LABELS } from '../../../../bi/utils/analytics/analyticConstants';
import { momentObject } from '../../../../bi/utils/formatDate';
import { isSmartAgent } from '../../../../bi/utils/env';

import ROUTES from '../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import HotelsService from '../../../../bi/services/hotels';
import AirlineService from '../../../../bi/services/airline';

import styles from './styles.module.css';

const LABELS = {
  ADD_HOTEL: getText('cart:addHotel'),
  SELECT_HOTEL_IN_CITY: (name: string) => getText('cart:selectHotelInCity', { name }),
  SELECT_HOTEL: getText('cart:selectHotel'),
};

interface ICartSuggestHotelProps {
  suggestedData: {
    airportId?: number,
    checkin?: string,
    region?: any,
    isOne?: boolean,
  },
  airlineService: AirlineService,
  hotelsService: HotelsService,
  history: RouteComponentProps['history'],
}

const CartSuggestHotel = ({
  suggestedData,
  airlineService,
  hotelsService,
  history,
}: ICartSuggestHotelProps) => {
  if (!suggestedData) {
    return null;
  }

  const { airportId = null, checkin = null, region = null, isOne = false } = suggestedData;

  const handleAddHotel = async () => {
    const preparedParams: { [key: string]: any } = {
      'checkout.date': (checkin && isOne) ? momentObject(checkin).add(1, 'days') : null,
      'checkin.date': (checkin && isOne) ? momentObject(checkin) : null,
      'region.selected': {},
    };

    if (airportId) {
      const foundRegion = await airlineService.findRegionByAirport(airportId);

      if (foundRegion) {
        region.Id = foundRegion.RegionId;
        region.Name = foundRegion.RegionName;
        region.IsRegion = true;

        preparedParams['region.selected' as keyof typeof preparedParams] = region;
      }
    }

    Object.keys(preparedParams)
      .forEach((key) => hotelsService
        .setSearch(key, preparedParams[key as keyof typeof preparedParams]),
      );

    history.push({ pathname: ROUTES.SEARCH.HOTEL, search: 'isSuggested=true' });

    MainAnalytic.send(MainAnalytic.CATEGORY.CART, MainAnalytic.ACTIONS.CART.HOTELPURCHASE, {
      label: ANALYTICS_LABELS.CART.GOTOSEARCH,
    });
  };

  const title = region?.Name ? LABELS.SELECT_HOTEL_IN_CITY(region.Name) : LABELS.SELECT_HOTEL;

  return (
    <div className={ styles['hotels-suggest-wrapper'] } onClick={ handleAddHotel } data-qa={ QA_ATTRIBUTES.cart.addHotel }>
      <Icon type='smartdeskHotel' alternativeDesign={ isSmartAgent }/>
      <div className={ styles.content }>
        <Text
          type='SEMIBOLD_18'
          color='default'
          className={ styles.text }
        >
          { LABELS.ADD_HOTEL }
        </Text>
        <Text
          type='NORMAL_16'
          color='default'
          className={ styles.sub_text }
        >
          { title }
        </Text>
      </div>
    </div>
  );
};

export { CartSuggestHotel };
