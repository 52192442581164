import React, { Component } from 'react';
import {
  withRouter,
} from 'react-router-dom';

import LoginDialog from '../../components/Login';
import { parseSearchString } from '../../bi/utils/convertSearchParams';

import STATUS from '../../bi/constants/status';

import { IHistoryInfo } from '../Employee';

import AppService from '../../bi/services/app';
import AuthService from '../../bi/services/auth';

import styles from './styles/index.module.css';

const ROUTE_QUICK_AUTH = '/auth';
const ROUTE_LOGIN = '/login';
const MODES = {
  DEFAULT: 'DEFAULT',
  QUICKAUTH: 'QUICKAUTH',
};

interface ILoginPageProps {
  authService: AuthService;
  appService: AppService;
  onLogin(value: string): Promise<void>;
  onClientSession(): Promise<void>;
  history: IHistoryInfo;
}

interface ILoginPageState {
  error: string;
  mode: string;
}

class LoginPage extends Component<ILoginPageProps, ILoginPageState> {
  unsubscribeFn: any;

  constructor(props: ILoginPageProps) {
    super(props);

    const { history: { location: { pathname, search } } } = props;
    const { authService: { setField } } = this.props;

    let mode = MODES.DEFAULT;

    if (pathname === ROUTE_QUICK_AUTH) {
      const queryParams = parseSearchString(search) as Record<string, string>;

      if (queryParams && queryParams.uuid) {
        this.handleLogin(queryParams.uuid);
        mode = MODES.QUICKAUTH;
      }
    }

    if (pathname === ROUTE_LOGIN) {
      const queryParams = parseSearchString(search) as Record<string, string>;

      if (queryParams && queryParams.username && queryParams.password) {
        setField('username', queryParams.username);
        setField('password', queryParams.password);

        this.handleClientSessionLogin();
      }
    }

    const {
      error,
    } = props.authService.store.getState();

    this.state = {
      error,
      mode,
    };
  }

  componentDidMount() {
    const { history, appService } = this.props;
    const { appStatus } = appService.get();

    if (appStatus === STATUS.OK) {
      history.push('/');
    }

    this.unsubscribeFn = this.props.authService.subscribe(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateState = (state: ILoginPageState) => {
    this.setState({
      ...this.state,
      ...state,
    });
  };

  handleLogin = (quickAuthUuid: string) => {
    const { onLogin, history } = this.props;

    return onLogin(quickAuthUuid).then(() => history.push('/'));
  };

  handleClientSessionLogin = () => {
    const { onClientSession, history } = this.props;

    return onClientSession().then(() => {
      history.push('/');
      window.location.reload();
    });
  };

  render() {
    const { authService, appService } = this.props;
    const { error, mode } = this.state;

    if (mode === MODES.QUICKAUTH && !error) return null;

    return (
      <div className={ styles.wrap }>
        <LoginDialog
          authService={ authService }
          appService={ appService }
          onLogin={ this.handleLogin }
          isDialogMode={ false }
        />
      </div>
    );
  }
}

export default withRouter(LoginPage as any);
