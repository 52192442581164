import moment, { DurationInputArg1 } from 'moment';

import { getText } from '../../../i18n';

import { lpad } from './pad';

const LABELS = {
  days: (days: number) => getText('utils:timeLabel.days', { days }),
  hours: (hours: number) => getText('utils:timeLabel.hours', { hours }),
  minutes: (minutes: number) => getText('utils:timeLabel.minutes', { minutes }),
};

const timeLabel = (timeSpan: DurationInputArg1) => {
  const travelTime = moment.duration(timeSpan);
  const days = travelTime.days();
  const hours = travelTime.hours();
  const minutes = travelTime.minutes();

  return `${days ? LABELS.days(days) : ''} ${LABELS.hours(hours)} ${LABELS.minutes(minutes)}`;
};

const timeLabelWithColon = (timeSpan: DurationInputArg1) => {
  const travelTime = moment.duration(timeSpan);
  const days = travelTime.days();
  const hours = lpad(travelTime.hours());
  const minutes = lpad(travelTime.minutes());

  return `${days ? `${days}. ` : ''}${hours}:${minutes}`;
};

export {
  timeLabel as default,
  timeLabel,
  timeLabelWithColon,
};
