import { observer } from 'mobx-react';
import { Button, Text, BackLink } from 'new-ui';
import React from 'react';

import { getText } from '../../../../../i18n';

import { isSmartAgent } from '../../../../bi/utils/env';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import ChartsAnalytics from '../../../../bi/services/chartsAnalytics';

import { ChartAnalyticsFilters } from './filters';

import styles from './styles.module.css';

const LABELS = {
  EXCEL: getText('expenseReports:report.download.excel'),
  DOUGHNUT: getText('expenseReports:report.download.doughnut'),
};

interface ChartAnalyticsHeaderProps {
  chartsAnalyticsService: ChartsAnalytics,
  title: string | any,
  prevPath: string | null | any,
  onChangePath(): void,
  show: boolean,
  showOnlyTitle: boolean | any,
  type: string | any,
  active: string,
}

const ChartAnalyticsHeader = observer(({
  chartsAnalyticsService,
  title,
  prevPath,
  onChangePath,
  showOnlyTitle,
  type,
  show,
  active,
}: ChartAnalyticsHeaderProps) => {
  const {
    setDepartmentsSelected,
    setProjectsSelected,
    setEmployeesSelected,
    setAutocompleteEmployees,
    setTagsSelected,
    setAnalyticsSelected,
    setAutocompleteDepartments,
    setAutocompleteProjects,
    setAutocompleteTags,
    downloadXls,
    setFiltersType,
    resetPaginate,
    getInfo,
  } = chartsAnalyticsService;

  const { chartsAnalyticsStore } = useStores([MOBX_STORES.CHARTS_ANALYTICS_STORE]);

  const { downloadXlsLoading } = chartsAnalyticsStore;

  if (!show) return null;

  const handleDownload = () => downloadXls(active);

  const renderTitle = () => {
    if (title) {
      return (
        <Text type='bold_24'>
          { title }
        </Text>
      );
    }

    return (
      <BackLink
        className={ styles.back }
        link={ prevPath }
        onClick={ onChangePath }
        alternativeDesign={ isSmartAgent }
      />
    );
  };

  const renderHeader = () => (
    <div>
      <div className={ styles.row }>
        { renderTitle() }
        <Button
          type='textual'
          onClick={ () => handleDownload() }
          loading={ downloadXlsLoading }
        >
          { LABELS.EXCEL }
        </Button>
      </div>
    </div>
  );

  if (showOnlyTitle) {
    return (
      <div className={ styles.row }>
        { renderTitle() }
      </div>
    );
  }

  return (
    <>
      { renderHeader() }
      <ChartAnalyticsFilters
        type={ type }
        setDepartments={ setDepartmentsSelected }
        setEmployees={ setEmployeesSelected }
        setProjects={ setProjectsSelected }
        getInfo={ getInfo }
        setAnalytics={ setAnalyticsSelected }
        setAutocompleteTags={ setAutocompleteTags }
        setAutocompleteEmployees={ setAutocompleteEmployees }
        setAutocompleteDepartments={ setAutocompleteDepartments }
        setAutocompleteProjects={ setAutocompleteProjects }
        setFiltersType={ setFiltersType }
        resetPaginate={ resetPaginate }
        setTags={ setTagsSelected }
      />
    </>
  );
});

export { ChartAnalyticsHeader };
