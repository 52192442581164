import React, { useEffect, useState } from 'react';
import {
  Text,
  Dialog,
  Button,
  MultiSelect,
} from 'new-ui';
import { getText } from '../../../../../i18n';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import styles from './styles.module.css';

const LABELS = {
  SELECT_COMPANIES: getText('analytics:selectCompanies'),
  DIALOG_COST_CENTER_TEXT: getText('analytics:costCenterText'),
  SAVE: getText('analytics:save'),
  CANCEL: getText('analytics:cancel'),
};

interface CostDialogProps {
  onChange(show: boolean): void;
  onSave(selected: number[]): void;
  selected?: number[];
  show: boolean;
  companies?: any[],
}

const CostDialog = ({
  show,
  companies = [],
  selected = [],
  onChange,
  onSave,
}: CostDialogProps) => {
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>(selected);

  useEffect(() => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_PROJECT_COMPANIES_OPEN), []);

  const handleSave = () => {
    onSave(selectedCompanies);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_PROJECT_COMPANY_SAVE_BUTTON);
  };

  const handleSelectedCompanies = (values: number[]) => {
    setSelectedCompanies(values);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_PROJECT_COMPANY_CHOSEN);
  };

  const handleCancel = () => onChange(false);

  return (
    <Dialog
      show={ show }
      showClosing
      onChange={ onChange }
    >
      <div className={ styles.cost_dialog }>
        <Text
          type='NORMAL_14_130'
          color='default'
        >
          {LABELS.DIALOG_COST_CENTER_TEXT}
        </Text>
        <div>
          <MultiSelect
            list={ companies }
            values={ selectedCompanies }
            placeholder={ LABELS.SELECT_COMPANIES }
            className={ styles.multi_select }
            onChange={ handleSelectedCompanies }
          />
        </div>
        <div className={ styles.buttons }>
          <Button
            className={ styles.cancel_button }
            type='textual-medium'
            onClick={ handleCancel }
          >
            { LABELS.CANCEL }
          </Button>
          <Button
            onClick={ handleSave }
          >
            { LABELS.SAVE }
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default CostDialog;
