import React from 'react';
import { Skeleton } from 'new-ui';

import styles from './styles/index.module.css';

const TicketItemSkeleton = () => (
  <section className={ styles.wrapper }>
    <div className={ styles.item_wrapper }>
      <div className={ styles.header }>
        <Skeleton className={ styles.header_item } width={ 140 } height={ 14 } />
        <Skeleton className={ styles.header_item } width={ 140 } height={ 14 } />
        <Skeleton className={ styles.header_item } width={ 140 } height={ 14 } />
      </div>
      <div className={ styles.item }>
        <div className={ styles.item_info }>
          <div className={ styles.item_time_and_direction }>
            <Skeleton className={ styles.logo } type='circle' width={ 40 } height={ 40 }/>
            <div className={ styles.info_wrapper }>
              <div className={ styles.block_info }>
                <div className={ styles.departure }>
                  <Skeleton width={ 58 } height={ 24 } />
                  <Skeleton className={ styles.city_field } width={ 96 } height={ 16 } />
                  <Skeleton width={ 94 } height={ 14 } />
                </div>
                <div className={ styles.time }>
                  <Skeleton width={ 118 } height={ 14 } />
                  <Skeleton className={ styles.city_field } width={ 84 } height={ 14 } />
                  <Skeleton width={ 86 } height={ 14 } />
                </div>
                <div className={ styles.arrival }>
                  <Skeleton width={ 58 } height={ 24 } />
                  <Skeleton className={ styles.city_field } width={ 96 } height={ 16 } />
                  <Skeleton width={ 94 } height={ 14 } />
                </div>
              </div>
            </div>
          </div>
          <div className={ styles.item_fare_wrapper }>
            <div className={ styles.fares }>
              <Skeleton className={ styles.fare } width={ 110 } height={ 58 }/>
              <Skeleton className={ styles.fare } width={ 110 } height={ 58 }/>
              <Skeleton className={ styles.fare } width={ 110 } height={ 58 }/>
            </div>
            <div className={ styles.fares_description }>
              <Skeleton className={ styles.description_row } width={ 260 } height={ 14 }/>
              <Skeleton className={ styles.description_row } width={ 560 } height={ 14 }/>
              <Skeleton className={ styles.description_row } width={ 310 } height={ 14 }/>
              <Skeleton className={ styles.description_row } width={ 460 } height={ 14 }/>
              <Skeleton className={ styles.description_row } width={ 250 } height={ 14 }/>
            </div>
          </div>
        </div>
        <div className={ styles.item_action }>
          <div className={ styles.price }>
            <Skeleton width={ 58 } height={ 24 }/>
            <Skeleton className={ styles.button } width={ 96 } height={ 38 }/>
          </div>
          <Skeleton width={ 80 } height={ 12 }/>
        </div>
      </div>
    </div>
  </section>
);

export { TicketItemSkeleton };
