import React from 'react';
import { Button, Text, Textarea, Tooltip } from 'new-ui';

import { getText } from '../../../../i18n';

import { isSmartAgent } from '../../../bi/utils/env';

import { QUALITY } from '../../../bi/constants/quality';

import styles from '../styles/mainPart.module.css';

const LABELS = {
  PLACEHOLDER: getText('quality:messageForm.placeholder'),
  BUTTON: getText('quality:messageForm.send'),
};

interface IMainPart {
  value: string,
  companyName: string,
  isDemo: boolean,
  loading: boolean,
  onClick(): void,
  onChange(value: string): void,
}

const MainPart = ({ value, companyName, isDemo, loading, onClick, onChange }: IMainPart) => {
  const labelOnlyForUser = isSmartAgent ? QUALITY.ONLY_FOR_USER_SMARTAGENT : QUALITY.ONLY_FOR_USER;
  const firstDiscription = isSmartAgent ? QUALITY.DESCRIPTION.FIRST_SMARTAGENT : QUALITY.DESCRIPTION.FIRST;

  return (
    <>
      <Text type='NORMAL_16_140'>
        { firstDiscription(companyName) }
      </Text>
      <Text type='NORMAL_16_140' className={ styles.paragraph }>
        { QUALITY.DESCRIPTION.SECOND(companyName) }
      </Text>
      <Textarea
        noneResize
        className={ styles.message }
        placeholder={ LABELS.PLACEHOLDER }
        value={ value }
        debounceMs={ 0 }
        onChange={ onChange }
      />
      <div className={ styles.actions }>
        <Tooltip
          show={ isDemo }
          renderContent={ () => (
            <Text
              color='white'
              type='NORMAL_16_140'
              className={ styles.tooltip_box }
            >
              { labelOnlyForUser(companyName) }
            </Text>
          ) }
        >
          <Button
            type='secondary'
            disabled={ isDemo || !value.length }
            loading={ loading }
            onClick={ onClick }
          >
            {LABELS.BUTTON}
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export default MainPart;
