// @ts-nocheck
import ACTION from '../intercom/action';

class CarrotQuest {
  constructor(api, store) {
    this.api = api;
    this.store = store;

    this.title = document.title;
  }

  getUserAuthorizeData = () => this.api.carrotQuestChat.userAuthorizeData();

  show = () => {
    if (window.carrotquest) {
      window.carrotquest.open();
    }
  };

  showMessages = () => {
    if (window.carrotquest) {
      window.carrotquest.openMessenger();
    }
  };

  hide = () => {
    if (window.carrotquest) {
      window.carrotquest.removeChat();
    }
  };

  openSession() { // нет такого метода в Intercom, заглушка для декоратора
    this.show();
  }

  closeSession() { // нет такого метода в Intercom, заглушка для декоратора
    if (window.carrotquest) {
      window.carrotquest.hide();
    }
  }

  updateSession() { return null; }

  sendMessageToAdmin = (user, msg, note = '', supressWidget = false) => {
    if (window.carrotquest) {
      const carrotQuestMessageSenderUser = 1;

      this.store.dispatch({ type: ACTION.STARTSENDINGMESSAGETOADMIN });

      return this.api.chat.sendMessageToAdmin({ Message: msg, Email: user.email, note, MessageSender: carrotQuestMessageSenderUser })
        .then(() => {
          if (supressWidget) {
            return;
          }

          this.showMessages();
        }, 1000)
        .finally(() => this.store.dispatch({ type: ACTION.ENDSENDINGMESSAGETOADMIN }));
    }

    return null;
  };

  requestError(errorMsg) {
    if (errorMsg) {
      this.sendMessageToAdmin('', errorMsg);
    }
  }
}

export default CarrotQuest;
