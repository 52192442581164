import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Dialog, Input, Text, LinkButton, Button } from 'new-ui';
import { getText, getTextArray } from '../../../../../../i18n';

import toDecline from '../../../../../bi/utils/toDecline';

import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import styles from '../index.module.css';
import { MainAnalytic } from '../../../../../bi/utils/analytics';

const LABELS = {
  GET_ACCESS: getText('settings:employees.employee.steps.signature.getAccess'),
  CONFIRM: getText('settings:employees.employee.steps.signature.confirm'),
  HEADER: getText('settings:employees.employee.steps.signature.codeDialog.inputSms'),
  SUB_HEADER: getText('settings:employees.employee.steps.signature.codeDialog.codeWillCome'),
  NOT_CODE: getText('settings:employees.employee.steps.signature.codeDialog.codeNotCome'),
  CHANGE_NUMBER: getText('settings:employees.employee.steps.signature.codeDialog.changeNumber'),
  REPEAT: getText('settings:employees.employee.steps.signature.codeDialog.repeat'),
  PLEASE_ENTER_CODE: getText('settings:employees.employee.steps.signature.codeDialog.enterCodePlease'),
  REPEAT_TIMER: (timer: number) => (
    `${getText('settings:employees.employee.steps.signature.codeDialog.repeat')}
    ${getText('settings:employees.employee.steps.signature.codeDialog.timer', { timer })}
    ${toDecline(timer, getTextArray('settings:employees.employee.steps.signature.codeDialog.secondDeclines'))}`),
};

const MINUTE = 60;

interface ICodeDialog {
  show: boolean,
  showChangeNumber: boolean,
  phoneNumber: string,
  amplitudeProperty: string,
  onSetActiveTimer(value: boolean): void,
  onClose(): void,
  onChange(value: string, key: number): void,
  onRepeat(): void,
  onSave(): void,
  onChangeNumber?(): void,
}

const CodeDialog = observer(({
  show,
  phoneNumber,
  showChangeNumber,
  onSetActiveTimer,
  onClose,
  onChange,
  onRepeat,
  onSave,
  onChangeNumber = () => {},
  amplitudeProperty,
}: ICodeDialog) => {
  const { simpleSignatureStore } = useStores([MOBX_STORES.SIMPLE_SIGNATURE_STORE]);

  const [timer, setTimer] = useState(MINUTE);

  const tabsRefs: React.MutableRefObject<any> = useRef([]);

  const {
    signatureCode,
    loadings: {
      loadingSendSignatureCode,
    },
    isActiveTimer,
  } = simpleSignatureStore;

  const updateTimer = (active = false) => {
    if (isActiveTimer || active) {
      let timeleft = MINUTE;
      const downloadTimer = setInterval(() => {
        if (timeleft <= 0) {
          clearInterval(downloadTimer);
          onSetActiveTimer(false);

          return;
        }

        timeleft -= 1;
        setTimer(timeleft);
      }, 1000);
    }
  };

  useEffect(() => {
    updateTimer();
  }, []);

  const setRef = (id: number, ref: React.SetStateAction<null>) => {
    if (ref) {
      tabsRefs.current[id] = ref;
    }
  };

  const handleChange = (val: string, key: number, index: number) => {
    if (val.length > 1 || (!Number(val) && val !== '' && val !== '0')) return;

    if (val === '' && key !== 1) {
      const preIndex = index - 1;
      tabsRefs.current[preIndex].focus();
    }

    const postIndex = index + 1;

    if (!signatureCode[index].value && signatureCode.length > postIndex) {
      tabsRefs.current[postIndex].focus();
    }

    onChange(val, key);
  };

  const handleConfirmCodeDialog = () => {
    onSave();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.USER_CONFIRMED_SMS_CODE_BUTTON_PRESSED, {
      confirmationCode: amplitudeProperty,
    });
  };

  const handleChangeNumber = () => {
    if (onChangeNumber) onChangeNumber();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.CHANGE_PHONE_BUTTON_PRESSED, {
      confirmationCode: amplitudeProperty,
    });
  };

  const handleRepeat = () => {
    onSetActiveTimer(true);
    onRepeat();
    updateTimer(true);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.REPEAT_SMS_CODE_BUTTON_PRESSED, {
      confirmationCode: amplitudeProperty,
    });
  };

  const codeContent = ({ key, value }: { key: number, value: string }, index: number) => (
    <div
      key={ key }
      className={ styles.code }
    >
      <Input
        textClassName={ styles.inputStyle }
        className={ styles.code_item }
        value={ value }
        onChange={ (val: string) => handleChange(val, key, index) }
        ref={ (ref: any) => setRef(index, ref) }
      />
    </div>
  );

  const content = () => {
    const label = timer === 0 ? LABELS.REPEAT : LABELS.REPEAT_TIMER(timer);
    const disabledRules = timer > 0;
    const wrapper_repeat = disabledRules ? '' : styles.repeat_text;

    return (
      <>
        <Text
          className={ styles.header }
          type='bold_16'
        >
          { LABELS.HEADER }
        </Text>
        <div className={ styles.sub_header }>
          <Text
            className={ styles.header }
            type='NORMAL_14_130'
          >
            { LABELS.PLEASE_ENTER_CODE }
          </Text>
        </div>
        <div className={ styles.sub_header }>
          <Text
            className={ styles.header }
            type='NORMAL_14_130'
          >
            { LABELS.SUB_HEADER }
          </Text>
          <Text
            className={ styles.phone }
            type='SEMIBOLD_14'
          >
            { phoneNumber }
          </Text>
        </div>
        <div className={ styles.code_content }>
          { signatureCode.map(codeContent) }
        </div>
        <div className={ styles.sub_header }>
          <Text
            className={ styles.repeat }
            type='NORMAL_14_130'
          >
            { LABELS.NOT_CODE }
          </Text>
          <LinkButton
            className={ wrapper_repeat }
            disabled={ disabledRules }
            theme='blue-without-border'
            onClick={ handleRepeat }
          >
            <Text
              type='NORMAL_14'
              color='accent'
            >
              { label }
            </Text>
          </LinkButton>
        </div>
      </>
    );
  };

  const actions = () => {
    const disabled = signatureCode.find(({ value }) => !value);

    const renderChangeNumber = showChangeNumber ? (
      <Button
        onClick={ handleChangeNumber }
        type='textual'
      >
        { LABELS.CHANGE_NUMBER }
      </Button>
    ) : null;

    return (
      <div className={ styles.buttons_wrapper }>
        <Button
          className={ styles.confirm }
          disabled={ !!disabled }
          onClick={ handleConfirmCodeDialog }
          loading={ loadingSendSignatureCode }
          type='secondary'
        >
          { LABELS.CONFIRM }
        </Button>
        { renderChangeNumber }
      </div>
    );
  };

  return (
    <Dialog
      showClosing
      show={ show }
      onChange={ onClose }
    >
      <div className={ styles.dialog }>
        { content() }
        { actions() }
      </div>
    </Dialog>
  );
});

export { CodeDialog };
