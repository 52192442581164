import Api from '../../api';

import CustomAnalyticsStore from './store';

import { IAnalyticsType, AnalyticsValueType, NewAnalyticsValueType } from './types';

class CustomAnalyticsService {
  api: Api['customAnalytics'];
  store: CustomAnalyticsStore;

  constructor(api: Api) {
    this.api = api.customAnalytics;
    this.store = new CustomAnalyticsStore();
  }

  setLoading = (value = false): void => {
    this.store.setLoading(value);
  };

  setAnalytics = (analytics: IAnalyticsType): void => {
    this.store.setAnalytics(analytics);
  };

  addAnalyticsValue = async (analyticsId: number, value: string): Promise<AnalyticsValueType | null> => {
    let res = null;
    try {
      res = await this.api.addValue(analyticsId, { Name: value });
    } catch {}

    return res;
  };

  updateAnalytics = (analytics: IAnalyticsType, valuesToAdd: NewAnalyticsValueType[], idsToDelete: number[]) => {
    const { Name, Required, Editable, ApplyToTrip } = analytics;

    return Promise.all([
      this.api.updateAnalytics(analytics.Id, {
        Name,
        Required,
        Editable,
        ApplyToTrip,
      }),
      ...valuesToAdd.map(({ Value }) => this.api.addValue(analytics.Id, { Name: Value })),
      ...idsToDelete.map(valueId => this.api.deleteValue(analytics.Id, valueId)),
    ]);
  };

  getCustomAnalytics = () => this.api.getAnalytics();

  getAnalytics = async (noLoading = false) => {
    this.store.setLoading(!noLoading);

    try {
      const analytics = await this.getCustomAnalytics();
      this.store.setAnalyticsList(analytics);
      this.store.setLoading(false);

      return analytics;
    } catch {}

    return [];
  };

  getAnalyticsById = async (id: number): Promise<void> => {
    try {
      const result = await this.api.getAnalyticsById(id);
      this.store.setAnalytics(result);
    } catch {}
  };

  deleteAnalytics = async (id: number) : Promise<void> => {
    const { deleteAnalytics, list, setAvailableValue } = this.store;

    setAvailableValue(true);

    await this.api.deleteAnalytics(id);
    deleteAnalytics(list, id);

    setAvailableValue(false);
  };

  createAnalytics = async (analytics: IAnalyticsType, values: NewAnalyticsValueType[]): Promise<void> => {
    this.store.setLoading(true);

    const { Name, Required, Editable, ApplyToTrip } = analytics;
    const mappedValues = values.map(({ Value }) => ({ Name: Value }));

    try {
      await this.api.createAnalytics({
        Name,
        Required,
        Editable,
        ApplyToTrip,
        Values: mappedValues,
      });
    } catch {}

    this.store.setLoading(false);
  };

  reset = (): void => {
    this.store.reset();
  };
}

export default CustomAnalyticsService;
