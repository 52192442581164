import React, { useEffect, useState } from 'react';
import { Button, CircleLoading, NoResults, SidePanel, Text } from 'new-ui';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { getText } from '../../../i18n';
import { useStores } from '../../bi/context';

import { ChatService } from '../../bi/types/chat';

import AddEvent from './components/AddEvent/AddEvent';
import { Events } from './components/Events/Events';
import FiltredEvents from './components/FiltredEvents';

import Card from '../../../images/mice/Card.svg';
import Frame from '../../../images/mice/Frame.svg';
import Modal from '../../../images/mice/Modal.webp';

import { MainAnalytic } from '../../bi/utils/analytics';

import ROUTES from '../../bi/constants/routes';
import { MOBX_STORES } from '../../bi/context/stores';

import { IEventService, TEventValue } from '../../bi/services/events/type';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('event:event'),
  INFO_MICE: getText('event:infoMice'),
  APPLICATION_TITLE: getText('event:applicationTitle'),
  ADD_LABEL: getText('event:addLabel'),
  PLACEHOLDER_SELECT: getText('event:placeholderSelect'),
  NO_RESULT_LABEL: getText('event:noResultLabel'),
  ERROR_LOADING: getText('event:errorLoading'),
};

interface IEventPage {
  eventService: IEventService,
  chatService: ChatService,
}

const EventPage = observer(({
  eventService,
  chatService,
  eventService: {
    getEvents,
    createEvent,
    setStatusesFiltred,
  },
}: IEventPage) => {
  const { eventStore } = useStores([MOBX_STORES.EVENT_STORE]);

  const isPath = useLocation().pathname.includes(ROUTES.EVENT_CONTEXT);

  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [showApp, setShowApp] = useState<boolean>(isPath);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getEvents();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.EVENT.OPEN);

    return () => {
      setStatusesFiltred([]);
    };
  }, []);

  useEffect(() => {
    if (!eventStore.loadingEvents) {
      setIsLoading(false);
    }
  }, [eventStore.loadingEvents]);

  const handleAddEvent = (value: TEventValue) => {
    createEvent(value as TEventValue);

    setShowApp(!showApp);

    chatService.show();
  };

  const handleShowPanel = (value: boolean) => {
    setShowPanel(!value);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.EVENT.INFO);
  };

  const handleAppPanel = (value: boolean) => {
    setShowApp(!value);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.EVENT.NEW);
  };

  const handleChangeFilter = (value: any) => {
    setStatusesFiltred(value);

    getEvents();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.EVENT.FILTER);
  };

  const renderModalAddApplication = () => {
    if (!showApp) {
      return null;
    }

    const onCloseAddEvent = (value: boolean) => {
      setShowApp(value);

      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.EVENT.CANCEL);
    };

    return (
      <AddEvent
        show={ showApp }
        // @ts-ignore
        onAddEvent={ handleAddEvent }
        onClose={ () => onCloseAddEvent(false) }
      />
    );
  };

  const renderEvents = () => {
    const { events, errorEvents } = eventStore;

    if (!events.length) {
      return (
        <NoResults
          label={ LABELS.NO_RESULT_LABEL }
          className={ styles.wrapper_no_result }
        />
      );
    }

    if (errorEvents) {
      return (
        <NoResults
          label={ LABELS.ERROR_LOADING }
        />
      );
    }

    return (
      <Events
        events={ events }
        eventService={ eventService }
      />
    );
  };

  const renderPanelMice = () => (
    <SidePanel
      show={ showPanel }
      onClose={ () => setShowPanel(!showPanel) }
    >
      <div
        className={ styles.wrapper_side }
      >
        <Text type='bold_24'>
          { LABELS.INFO_MICE }
        </Text>
        <img
          className={ styles.modal_img }
          src={ Modal }
          alt='modal_info'
        />
      </div>
    </SidePanel>
  );

  const renderFiltred = () => {
    const { filtered: { Statuses } } = eventStore;

    return (
      <FiltredEvents
        values={ Statuses }
        onChange={ handleChangeFilter }
        placeholder={ LABELS.PLACEHOLDER_SELECT }
        className={ styles.wrapper_filter }
      />
    );
  };

  const renderContent = () => {
    const loader = () => (
      <div
        className={ styles.loader_wrapper }
      >
        <CircleLoading
          size={ 100 }
        />
      </div>
    );

    return isLoading ? loader() : renderEvents();
  };

  return (
    <div className={ styles.wrapper }>
      { renderPanelMice() }
      { renderModalAddApplication() }
      <div className={ styles.title }>
        <Text type='bold_32'>
          { LABELS.TITLE }
        </Text>
      </div>
      <div
        className={ styles.image_wrapper }
      >
        <img
          className={ styles.frame_image }
          src={ Frame }
          alt='frame'
          onClick={ () => handleShowPanel(showPanel) }
        />
        <img
          className={ styles.card_image }
          src={ Card }
          alt='card'
        />
      </div>
      <div
        className={ styles.wrapper_list }
      >
        <Text
          type='bold_24'
        >
          { LABELS.APPLICATION_TITLE }
        </Text>
        <div
          className={ styles.wrapper_select }
        >
          { renderFiltred() }
          <Button
            onClick={ () => handleAppPanel(showApp) }
            className={ styles.wrapper_button }
            type='secondary'
          >
            { LABELS.ADD_LABEL }
          </Button>
        </div>
        { renderContent() }
      </div>
    </div>
  );
});

export { EventPage };
