import { getText } from '../../../i18n';

const LABELS = {
  NOT_PROCESSING: getText('constants:request.statuses.notProcessing'),
  PROCESSING: getText('constants:request.statuses.processing'),
  PROCESSED: getText('constants:request.statuses.processed'),
  CANCELED: getText('constants:request.statuses.cancelled'),
};

const STATUSES = {
  NOT_PROCESSING: 'NotProcessing',
  PROCESSING: 'Processing',
  PROCESSED: 'Processed',
  CANCELED: 'Canceled',
};

const REQUESTS_STATUSES = [
  { value: STATUSES.NOT_PROCESSING, label: LABELS.NOT_PROCESSING },
  { value: STATUSES.PROCESSING, label: LABELS.PROCESSING },
  { value: STATUSES.PROCESSED, label: LABELS.PROCESSED },
  { value: STATUSES.CANCELED, label: LABELS.CANCELED },
];

export { STATUSES, REQUESTS_STATUSES };
