import * as queryString from 'query-string';
import { ParsedQuery } from 'query-string';

const parseSearchString = (params: string): ParsedQuery | null => {
  if (params) {
    try {
      return queryString.parse(params);
    } catch (e) {
      window.console.error(`error: no valid string - ${params}`);
    }
  }

  return null;
};

const isRecord = (val: any): val is Record<string, unknown> => typeof val === 'object';

const stringifySearchParams = (params: string | object): string => {
  if (isRecord(params)) {
    try {
      return queryString.stringify(params);
    } catch (e) {
      window.console.error('error: no valid object');
      window.console.error(params);
    }
  }

  return '';
};

const convertToBase64 = (param: any): string =>
  btoa(unescape(encodeURIComponent(JSON.stringify(param))));

export {
  parseSearchString as default,
  parseSearchString,
  stringifySearchParams,
  convertToBase64,
};
