import {
  SmartwayCommonEnumsSortField,
  SmartwayCommonEnumsSortType,
} from '@sw/api';
import { NotificationsManager } from 'new-ui';

import CONFIG from '../../../../config';

import { apiNext } from '../../apiV2/apiNext';

import { getText } from '../../../../i18n';

import {
  checkDoubleDoc,
  getApprovalERSchemeIdForEmployee,
  getApprovalSchemeIdForEmployee,
} from '../../utils/employees';
import { bound, withInternalMethod } from '../utils/dectrators';

import NetworkStatesStore from '../utils/network/networkStatesStore';

import { FIELDRIGHTS } from '../../constants/rights';
import STATUS from '../../constants/employee';
import { LOADINGS_TYPE } from '../../constants/signature';

import EMPLOYEEACTION from './action';

import {
  SimpleSignatureStore,
  SimpleSignatureStoreType,
} from './store/simpleSignature';
import employeeStoreCreate from './store/employee';
import RightsStore from './store/rights';

import { ListType } from '../chartsAnalytics/types';
import {
  DocumentsTypes,
  IApprovalShemas,
  IApprovalShemasERA,
  IDocumentUser,
  IEmployees,
  IList,
  IListExpense,
  IPassportView,
  IPolicyData,
  ISchemeData,
  ITpRightLight,
  IUserIdentity,
  TAirlines,
} from './types';

const toUppercaseFields = ['Patronymic', 'Name', 'Surname'];

const RESPONSE = 'Ok';

const LABELS = {
  ERROR: getText('settings:documentsForSignature.error'),
};

class Employee {
  rightsStore = new RightsStore();
  api: any;
  xhr: any;
  employeeStore = employeeStoreCreate();
  simpleSignatureStore: SimpleSignatureStoreType;
  xhrAutocomplete: any;
  xhrAutocompleteEmployee: any;

  networkStore = new NetworkStatesStore<'getTgLink' | 'sendTgLink'>();

  constructor(api: any) {
    this.api = api;
    this.xhr = null;
    this.employeeStore = employeeStoreCreate();

    this.simpleSignatureStore = SimpleSignatureStore;
  }

  get = () => this.employeeStore.getState();

  clear = () => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEECLEAR,
  });

  setViewRightsLoading = (isLoading: boolean) => this.rightsStore.setViewRightsLoading(isLoading);

  setViewRights = (rights = null) => this.rightsStore.setViewRights(rights);

  @bound
  @withInternalMethod((o) => o.networkStore.withLoading('getTgLink'))
  @withInternalMethod((o) => o.networkStore.withErrorBoundary('getTgLink'))
  async getTgLink(id: number, label: string) {
    const { link } = await this.api.employee.getTgLink(id);
    await navigator.clipboard.writeText(link);
    NotificationsManager.addNotification({
      value: label,
    });
  }

  @bound
  @withInternalMethod((o) => o.networkStore.withLoading('getTgLink'))
  @withInternalMethod((o) => o.networkStore.withErrorBoundary('getTgLink'))
  async openTgLink(id: number) {
    const { link } = await this.api.employee.getTgLink(id);
    window.open(link, '_blank');
  }

  @bound
  @withInternalMethod((o) => o.networkStore.withLoading('getTgLink'))
  @withInternalMethod((o) => o.networkStore.withErrorBoundary('getTgLink'))
  async loadLinkedTgAccount(id: number) {
    const payload = await this.api.employee.getLinkedTgAccount(id);

    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.SET_IS_TG_LINKED_ACCOUNT,
      payload,
    });
  }

  @bound
  @withInternalMethod((o) => o.networkStore.withLoading('sendTgLink'))
  @withInternalMethod((o) => o.networkStore.withErrorBoundary('sendTgLink'))
  sendMailWithTgLink(...args: [number, string]) {
    return this.api.employee.sendMailWithTgLink(...args);
  }

  @bound
  @withInternalMethod((o) => o.networkStore.withLoading('sendTgLink'))
  @withInternalMethod((o) => o.networkStore.withErrorBoundary('sendTgLink'))
  async unlinkTgAccount(id: number) {
    await this.api.employee.unlinkEmployeeTg(id);

    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.SET_IS_TG_LINKED_ACCOUNT,
      payload: false,
    });
  }

  setRights = (approvalSchemes: IApprovalShemas[], approvalExpenseReportsSchemes: IApprovalShemasERA[]) => {
    this.rightsStore.setRights(approvalSchemes, approvalExpenseReportsSchemes);
  };

  setEmployee = (
    employee: IEmployees | null,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag = false,
    employeeNumSixDigFlag = false,
  ) => {
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEESET,
      payload: {
        employee,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });

    this.updateState(documentType);
    this.validateRequiredFields(travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag);
    this.transliteratePatronymic(documentType);
    this.validateRussianPassportRequiredFields(documentType, travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag);
    this.validateCommonInfoRequiredFields(documentType, travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag);
    this.forceUpdateBonus();

    if (employee && employee.Id) {
      this.updateApprovalScheme(employee.ApprovalSchemeId);
      this.updateApprovalERScheme(employee.ApprovalExpenseReportSchemeId);
      this.getEmployeeRights(employee.Id, documentType, travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag);
    }
  };

  setEmployeeCurrentDocument = (employee: IEmployees, documentType: IPassportView) => {
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.SET_EMPLOYEES_CURRENT_DOCUMENT,
      payload: employee,
    });

    this.updateState(documentType);
  };

  changeNoPatronymicValue = (
    type: string,
    documentType: IPassportView,
    index: number,
  ) => {
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.CHANGE_NO_PATRONYMIC_VALUE,
      payload: {
        type,
        documentType,
        index,
      },
    });
  };

  notValidationPatronymic = () => {
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.NOT_VALIDATION_PATRONYMIC,
    });
  };

  resetNotValidationPatronymic = () => {
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.RESET_NOT_VALIDATION_PATRONYMIC,
    });
  };

  setEmployeePreviousDocument = (employee: IEmployees, documentType: IPassportView) => {
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.SET_EMPLOYEES_PREVIOUS_DOCUMENT,
      payload: employee,
    });

    this.updateState(documentType);
  };

  downloadRegistryXlsx = () => this.api.employee.downloadRegistryXlsx();

  checkCyrillicPassport = (
    id: string,
    employee: IEmployees,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) =>
    this.api.employee.checkCyrillicPassport(id)
      .then((cyrillicPassportId: number) =>
        this.setEmployee({
          ...employee,
          employeeHasCyrillicDomesticPassport: cyrillicPassportId ?? 0,
        }, documentType, travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag),
      ).catch(() =>
        this.setEmployee({
          ...employee,
          employeeHasCyrillicDomesticPassport: 0,
        }, documentType, travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag),
      );

  loadEmployee = (
    id: string,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) => {
    if (!id) {
      this.setEmployee(null, documentType, travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag);
      this.stopLoad();

      return Promise.resolve();
    }

    this.load();

    return this.api.employee.getEmployee(id)
      .then((employee: IEmployees) =>
        this.checkCyrillicPassport(
          id,
          employee,
          documentType,
          travelPolicyFlag,
          employeeNumberFlag,
          employeeNumSixDigFlag,
        ),
      )
      .finally(() => this.stopLoad());
  };

  getEmployeeById = (id: number) => this.api.employee.getEmployee(id);

  get rights() {
    return this.get().Rights;
  }

  // вниз попиздели
  // todo: refactor
  getEmployeeName = (employee: IDocumentUser) => {
    let result = '';

    if (employee.Surname) {
      result += `${employee.Surname} `;
    } else if (employee.LastName) {
      result += `${employee.LastName} `;
    }

    if (employee.Name) {
      result += `${employee.Name} `;
    } else if (employee.FirstName) {
      result += `${employee.FirstName} `;
    }

    if (employee.Patronymic) {
      result += `${employee.Patronymic} `;
    }

    return result;
  };

  setTpRights = (
    id: string | null,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) => {
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.SETTPRIGHTS,
      payload: {
        id,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });

    this.validateRequiredFields(travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag);

    if (id !== null) {
      this.rightsStore.setSourceChosenTp(id);
    }
  };

  getEmployeeRights = (
    employeeId: number,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) => {
    this.setViewRightsLoading(true);
    this.api.employee.getRights(employeeId)
      .then((rights: any) => {
        this.setViewRights(rights);
        this.getAllApproversForRights();
        this.getAllApproversExpenseReportsForRights();
        this.getApproversListForRights(employeeId);
        this.getERApproversForRights(employeeId);
      })
      .catch(() => {
        this.setViewRights(null);
        this.getAllApproversForRights();
        this.getAllApproversExpenseReportsForRights();
        this.getApproversListForRights(employeeId);
        this.getERApproversForRights(employeeId);
      })
      .finally(() => {
        this.setViewRightsLoading(false);
      });

    this.api.employee.getUserIdentity(employeeId)
      .then((userIdentity: IUserIdentity) => {
        this.employeeStore.dispatch({
          type: EMPLOYEEACTION.SETIDENTITY,
          payload: userIdentity,
        });
      }).catch(() =>
        this.employeeStore.dispatch({
          type: EMPLOYEEACTION.SETIDENTITY,
          payload: null,
        }));
    // ушли вниз
    this.getTPBundle(employeeId)
      .then(({ Id }: any) => this.setTpRights(
        Id,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      ))
      .catch(() => this.setTpRights(
        null,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      ));
  };

  updateTpRightsByCopyUser = (tpRights: ITpRightLight) => this.rightsStore.updateTpRightsByCopyUser(tpRights);

  getApproversForCopyRights = async (id: number) => {
    try {
      const list = await this.getApproversForUser(id);

      const mappedList = list.map(({ Email }: { Email: string }) => Email);
      this.changeApproversForUser(mappedList || []);
    } catch {
      this.changeApproversForUser([]);
    }
  };
  getCopyUserRights = async (id: number) => {
    const res = await this.api.employee.getRights(id);

    this.rightsStore.setCopyRights(res);
    await this.getApproversForCopyRights(id);
    await this.getERApproversForRights(id);
  };

  getTPBundle = async (id: number): Promise<any> => {
    try {
      const tpRights = await this.api.travelPolicies.getBundle(id);

      this.updateChosenTP(tpRights.Id);
      this.updateTpRightsByCopyUser(tpRights);
    } catch {
      this.updateChosenTP('');
    }
  };

  setEmployees = (employees: IEmployees[]) => {
    const users = employees.filter(employee => employee.Status === STATUS.ACCESS.USER);

    this.rightsStore.setUsers(users);
  };

  getAllApprovers = () => this.api.employee.getAllApprovers();

  getAllApproversForRights = () => this.getAllApprovers().then((list: IList[]) => this.setAllApprovers(list));

  setApproversForExpenseReports = (list: IList[] & IListExpense[]) => this.rightsStore.setApproversForExpenseReports(list);

  getAllApproversExpenseReportsForRights = async () => {
    try {
      const list = await this.api.approvalExpenseReports.getApproversForExpenseReports();

      this.setApproversForExpenseReports(list);
    } catch (e) {
      this.setApproversForExpenseReports([]);
    }
  };

  getApproversForUser = (employeeId: number) => {
    this.rightsStore.setApproversLoading(true);

    return this.api.employee.getApproversForUser(employeeId)
      .then((list: ListType) => list)
      .finally(() => this.rightsStore.setApproversLoading(false));
  };

  getERApproversForUser = (employeeId: number) => {
    this.rightsStore.setApproversLoading(true);

    return this.api.approvalExpenseReports.getERApproversForUser(employeeId)
      .then((list: ListType) => list)
      .catch(() => [])
      .finally(() => this.rightsStore.setApproversLoading(false));
  };

  getApproversListForRights = (employeeId: number) => this.getApproversForUser(employeeId)
    .then((list: IList[]) => this.setApprovers(list))
    .catch(() => this.setApprovers([]));

  setAllApprovers = (list: IList[]) => this.rightsStore.setAllApprovers(list);

  getERApproversForRights = async (employeeId: number) => {
    try {
      const res = await this.getERApproversForUser(employeeId);

      this.rightsStore.setERApprovers(res);
    } catch {
      this.rightsStore.setERApprovers([]);
    }
  };

  setApprovers = (list: IList[]) => this.rightsStore.setApprovers(list);

  saveTPBundle = (data: IPolicyData) => this.api.travelPolicies.saveBundle(data);

  saveApprovalSchemeBundle = (data: ISchemeData) => this.api.approvalSchemes.linkToEmployee(data);

  saveApprovers = (employeeId: number, list: string[]) => {
    this.api.employee.saveApprovers(employeeId, list);
  };

  saveApproversId = (employeeId: number, list: number[]) => {
    this.api.employee.saveApproversId(employeeId, list);
  };

  saveApproversExpenseReports = (employeeId: number, list: string[]) => {
    this.api.approvalExpenseReports.saveApproversExpenseReports(employeeId, list);
  };

  saveApprovalExpenseReportsSchemeBundle = (data: ISchemeData) =>
    this.api.approvalExpenseReports.linkExpenseReportsSchemeToEmployee(data);

  load = () => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEELOAD,
  });

  stopLoad = () => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEESTOPLOAD,
  });

  updateState = (documentType: IPassportView) => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEEUPDATE,
    payload: documentType,
  });

  changeInput = (
    field: string,
    value: string,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) => {
    const inputValue = value.length && toUppercaseFields.includes(field)
      ? value[0].toUpperCase() + value.slice(1)
      : value;

    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEEUPDATEINPUT,
      payload: {
        field,
        value: inputValue,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });
  };

  checkDoubleDocument = (value: string, documentType: IPassportView) =>
    this.api.employee.checkDoublePas({ Number: value, Type: documentType });

  changeDocumentInput = (
    value: string,
    field: string,
    index: number,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) =>
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEEDOCUMENTSUPDATEINPUT,
      payload: {
        value,
        field,
        index,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });

  changeBonus = (value: string, index: number) => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEEUPDATEBONUS,
    payload: {
      value,
      index,
    },
  });

  forceUpdateBonus = () => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEEFORCEUPDATEBONUS,
  });

  changeCompanies = (value: string) => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEEUPDATECOMPANIES,
    payload: value,
  });

  employeeUpdatePassport = (
    value: string,
    index: number,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
    isDoubleDoc = false,
  ) =>
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEEUPDATERUPASSPORT,
      payload: {
        value,
        index,
        documentType,
        isDoubleDoc,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });

  changeRuNumberPassport = (
    value: string,
    index: number,
    documentType: IPassportView,
    previousDocuments: IDocumentUser[],
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) => {
    const Documents = [...this.get().employee.Documents];
    const ruPassport = Documents.find(document => document.Type === documentType.DomesticPassport);

    if (value.length === 10 && checkDoubleDoc(ruPassport, documentType, previousDocuments, value)) {
      this.checkDoubleDocument(value, ruPassport.Type)
        .then((isDoubleDoc: boolean) =>
          this.employeeUpdatePassport(
            value,
            index,
            documentType,
            travelPolicyFlag,
            employeeNumberFlag,
            employeeNumSixDigFlag,
            isDoubleDoc,
          ),
        );
    }

    this.employeeUpdatePassport(
      value,
      index,
      documentType,
      travelPolicyFlag,
      employeeNumberFlag,
      employeeNumSixDigFlag,
    );
  };

  changeCertificateNumber = (
    value: string,
    index: number,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) =>
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEEUPDATECERTIFICATENUMBER,
      payload: {
        value,
        index,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });

  employeeUpdateInterPassportNumber = (
    value: string,
    index: number,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
    isDoubleDoc = false,
  ) => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEEUPDATEINTPASSPORTNUMBER,
    payload: {
      value,
      index,
      documentType,
      isDoubleDoc,
      travelPolicyFlag,
      employeeNumberFlag,
      employeeNumSixDigFlag,
    },
  });

  changeIntNumberPassport = (
    value: string,
    index: number,
    documentType: IPassportView,
    previousDocuments: IDocumentUser[],
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) => {
    const Documents = [...this.get().employee.Documents];
    const document = Documents[index];

    if (value.length === 9 && checkDoubleDoc(document, document.Type, previousDocuments, value)) {
      this.checkDoubleDocument(value, document.Type)
        .then((isDoubleDoc: boolean) =>
          this.employeeUpdateInterPassportNumber(value,
            index,
            documentType,
            travelPolicyFlag,
            employeeNumberFlag,
            employeeNumSixDigFlag,
            isDoubleDoc,
          ),
        );
    }

    this.employeeUpdateInterPassportNumber(
      value,
      index,
      documentType,
      travelPolicyFlag,
      employeeNumberFlag,
      employeeNumSixDigFlag,
    );
  };

  changeDomesticPassportNumber = (value: string, index: number, isDoubleDoc = false) =>
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEEUPDATEDOMESTICPASSPORT,
      payload: {
        value,
        index,
        isDoubleDoc,
      },
    });

  checkDomesticPassportNumber = (value: string, index: number, previousDocuments: IDocumentUser[]) => {
    const Documents = [...this.get().employee.Documents];
    const document = Documents[index];

    if (value.length > 1 && checkDoubleDoc(document, document.Type, previousDocuments, value)) {
      this.checkDoubleDocument(value, document.Type)
        .then((isDoubleDoc: boolean) => this.changeDomesticPassportNumber(value, index, isDoubleDoc));
    }

    this.changeDomesticPassportNumber(value, index);
  };

  transliteratePatronymic = (documentType: IPassportView) => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEETRANSLITERATEPATRONYMIC,
    payload: {
      documentType,
    },
  });

  validateRussianPassportRequiredFields = (
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) =>
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEEVALIDATERUSSIANPASSPORTREQUIREDFIELDS,
      payload: {
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });

  validateCommonInfoRequiredFields = (
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) =>
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEEVALIDATECOMMONINFOREQUIREDFIELDS,
      payload: {
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });

  changeUserLogin = (email: string, employeeId: number) => this.api.employee.checkLogin(email, employeeId)
    .then(() => {
      this.rightsStore.setLogin(email);
      this.getAllApproversForRights();
      this.setViewRights(null);

      if (employeeId) {
        this.getApproversListForRights(employeeId);
      }

      return true;
    })
    .catch((err: any) => {
      if (err.status === 409) {
        this.updateErrorMsg(true);
      }

      return null;
    });

  addBonus = () => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEEADDBONUS,
  });

  addDocument = (documentType: IPassportView) => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEEADDDOCUMENT,
    payload: documentType,
  });

  setCitizenshipValue = (
    value: string,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) =>
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEECITIZENSHIP,
      payload: {
        value,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });

  setAirlineValue = (value: string, index: number) => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEEAIRLINE,
    payload: {
      value,
      index,
    },
  });

  setProjectValue = (id: number) => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.SET_PROJECT_VALUE,
    payload: id,
  });

  citizenshipAutocomplete = async (query: string) => {
    const citizenshipRes = await this.api.employee.citizenship(query);

    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEECITIZENSHIPSUGGEST,
      payload: {
        query,
        citizenship: citizenshipRes,
      },
    });
  };

  employeeAirline = (query: string, index: number, airlines: TAirlines[]) => {
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEEAIRLINESUGGEST,
      payload: {
        query,
        index,
        airlines,
      },
    });
  };

  validationInput = (field: string) => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEEVALIDATIONINPUT,
    payload: field,
  });

  validateRequiredFields = (
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) =>
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEEVALIDATEREQUIREDFIELDS,
      payload: { travelPolicyFlag, employeeNumberFlag, employeeNumSixDigFlag },
    });

  employeeValidationDocumentInput = (
    field: string,
    index: number,
    documentType: IPassportView,
    isDoubleDoc: boolean,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) => {
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEEVALIDATIONDOCUMENTINPUT,
      payload: {
        field,
        index,
        documentType,
        isDoubleDoc,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });
  };

  validationDocumentInput = (
    field: string,
    index: number,
    documentType: IPassportView,
    previousDocuments: IDocumentUser[],
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) => {
    const Documents = [...this.get().employee.Documents];
    const passport = Documents[index];

    if (passport.Number.length > 1 && checkDoubleDoc(passport, documentType, previousDocuments, passport.Number)) {
      this.checkDoubleDocument(passport.Number, passport.Type)
        .then((isDoubleDoc: boolean) =>
          this.employeeValidationDocumentInput(
            field,
            index,
            documentType,
            isDoubleDoc,
            travelPolicyFlag,
            employeeNumberFlag,
            employeeNumSixDigFlag,
          ),
        );
    }

    this.employeeValidationDocumentInput(
      field,
      index,
      documentType,
      false,
      travelPolicyFlag,
      employeeNumberFlag,
      employeeNumSixDigFlag,
    );
  };

  validationSuccess = () => this.get().isValid;

  resetErrors = (field: string) => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEERESETERRORS,
    payload: field,
  });

  setErrors = (
    field: string,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) =>
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEESETERRORS,
      payload: {
        field,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });

  resetDocumentErrors = (field: string, index: number) => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEERESETERRORSDOCUMENT,
    payload: {
      field,
      index,
    },
  });

  deleteBonus = (index: number) => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEEDELETEBONUS,
    payload: index,
  });

  deleteDocument = (
    index: number,
    documentType: IPassportView,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) =>
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEEDELETEDOCUMENT,
      payload: {
        index,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });

  /**
   * Можно ли пользователю редактировать сотрудников компании
   * @param user{string} - userName пользователя
   */
  hasDisabledPaxUser = (user: string) => CONFIG.WORKSPACE.PAX.DISABLED.includes(user);

  subscribe = (cb: any) => this.employeeStore.subscribe(cb);

  save = (worker: any) => this.api.employee.save(worker);

  update = (worker: any) => this.api.employee.update(worker);

  updateErrorMsg = (value: boolean) => this.rightsStore.updateErrorMsg(value);

  updateChosenTP = (value: string) => {
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.UPDATE_CHOOSEN_TP,
      payload: {
        id: value,
      },
    });

    this.rightsStore.updateChosenTP(value);
  };

  updateApprovalScheme = (value: number) => this.rightsStore.updateApprovalScheme(value);

  updateApprovalERScheme = (value: number) => this.rightsStore.updateApprovalERScheme(value);

  saveOrUpdate = (worker: any) => {
    const birthDate = typeof worker.Birthday === 'string'
      ? worker.Birthday
      : worker.Birthday.format('YYYY-MM-DD');

    const documents = worker.Documents.map((item: DocumentsTypes) => {
      let itemDueDate = '';

      if (item.DueDate) {
        // @ts-ignore
        itemDueDate = typeof item.DueDate === 'string' ? item.DueDate : item.DueDate.format('YYYY-MM-DD');
      }

      return {
        ...item,
        DueDate: itemDueDate,
      };
    });

    const workerItem = {
      ...worker,
      Birthday: birthDate,
      Documents: documents,
      ProjectId: worker.ProjectId || null,
    };

    if (workerItem.Id === 0) return this.save(workerItem);

    return this.update(workerItem);
  };

  changeChosenTp = (
    id: number,
    documentType: IDocumentUser,
    travelPolicyFlag: boolean,
    employeeNumberFlag: boolean,
    employeeNumSixDigFlag: boolean,
  ) =>
    this.employeeStore.dispatch({
      type: EMPLOYEEACTION.EMPLOYEEUPDATECHOSENTP,
      payload: {
        id,
        documentType,
        travelPolicyFlag,
        employeeNumberFlag,
        employeeNumSixDigFlag,
      },
    });

  saveRights = (employeeId: number, rights: any) => this.api.employee.saveRights(employeeId, rights);

  checkUserRelevance = (employeeId: any, newLogin: any, oldLogin: any) => {
    if (!!oldLogin && newLogin !== oldLogin) {
      return this.api.employee.disableUser(employeeId);
    }

    return Promise.resolve();
  };

  archive = (id: number) => this.api.employee.archive(id).then(() => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEEARCHIVED,
  }));

  toRestore = (id: number) => this.api.employee.toRestore(id).then(() => this.employeeStore.dispatch({
    type: EMPLOYEEACTION.EMPLOYEEUNARCHIVED,
  }));

  autocomplete = (query: string, onlyAdult = true) => {
    if (this.xhrAutocomplete) this.xhrAutocomplete.abort();

    this.xhrAutocomplete = this.api.employee.autocomplete(query.trim(), onlyAdult);

    return this.xhrAutocomplete;
  };

  autoCompleteEmployeeOnly = (query: string) => {
    if (this.xhrAutocompleteEmployee) this.xhrAutocompleteEmployee.abort();

    this.xhrAutocompleteEmployee = this.api.employee.autoCompleteEmployeeOnly(query.trim());

    return this.xhrAutocompleteEmployee;
  };

  changeRights = (
    field: string,
    value: boolean,
  ) => {
    switch (field) {
      case FIELDRIGHTS.MANUALLY: {
        return this.rightsStore.changeCopyAndManually(value);
      }
      case FIELDRIGHTS.COPY: {
        return this.rightsStore.changeCopyAndManually(!value);
      }
      case FIELDRIGHTS.EDIT: {
        return this.rightsStore.changeCloseAndEdit(!value);
      }
      case FIELDRIGHTS.CLOSE: {
        return this.rightsStore.changeCloseAndEdit(value);
      }
      case FIELDRIGHTS.BUYTRIPPERSONAL: {
        return this.rightsStore.changeBuyTripPersonal(value);
      }
      case FIELDRIGHTS.BUYTRIPACCOUNT: {
        return this.rightsStore.changeBuyTripAccount(value);
      }
      case FIELDRIGHTS.APPROVE: {
        return this.rightsStore.changeApprove(value);
      }
      case FIELDRIGHTS.CREATE_ER_APPROVAL_SCHEME: {
        return this.rightsStore.changeCreateApprovalScheme(value);
      }
      case FIELDRIGHTS.CREATE_ER_AFTER_APPROVE: {
        return this.rightsStore.changeCreateAfterApprove(value);
      }
      case FIELDRIGHTS.CREATE_ER_UNLIMITED: {
        return this.rightsStore.changeCreateERUnlimited(value);
      }
      case FIELDRIGHTS.FINANCE: {
        return this.rightsStore.changeFinance(value);
      }
      case FIELDRIGHTS.APPROVE_EXPENSE_REPORTS: {
        return this.rightsStore.changeApproveExpenseReports(value);
      }
      case FIELDRIGHTS.BUYUNLIMITED: {
        return this.rightsStore.changeBuyUnlimited(value);
      }
      case FIELDRIGHTS.BUYAFTERAPPROVE: {
        return this.rightsStore.changeBuyAfterApprove(value);
      }
      case FIELDRIGHTS.BUYTRAVELPOLICY: {
        return this.rightsStore.changeBuyTravelPolicy(value);
      }
      case FIELDRIGHTS.BUY_APPROVAL_SCHEME: {
        return this.rightsStore.changeBuyApprovalScheme(value);
      }
      case FIELDRIGHTS.EDITEMPLOYEE: {
        return this.rightsStore.changeEditEmployee(value);
      }
      case FIELDRIGHTS.VIEWTRIPS: {
        return this.rightsStore.changeViewTrips(value);
      }
      case FIELDRIGHTS.VIEW_EXPENSE_REPORTS: {
        return this.rightsStore.changeViewExpenseReports(value);
      }
      case FIELDRIGHTS.EDITRIGHTS: {
        return this.rightsStore.changeEditRights(value);
      }
      case FIELDRIGHTS.EDITTRAVELPOLICIES: {
        return this.rightsStore.changeEditTravelPolicy(value);
      }
      case FIELDRIGHTS.CANGETVOUCHERS: {
        return this.rightsStore.changeCanGetVouchers(value);
      }
      case FIELDRIGHTS.CANGETINVOICES: {
        return this.rightsStore.changeCanGetInvoices(value);
      }
      case FIELDRIGHTS.SENDDOCUMENTS: {
        return this.rightsStore.changeCanGetDocuments(value);
      }
      case FIELDRIGHTS.VIEW_MICE_EVENTS: {
        return this.rightsStore.changeViewMiceEvents(value);
      }
      case FIELDRIGHTS.BUY_INSURANCE: {
        return this.rightsStore.changeBuyInsurance(value);
      }
    }

    return null;
  };

  changeBuyWithPersonalFunds = (value: boolean) => this.rightsStore.changeBuyWithPersonalFunds(value);

  changeUserAndApprovalScheme = (employeeId: string) => {
    const { rights, users } = this.rightsStore;

    this.changeUser(employeeId);

    if (rights.buyApprovalScheme) {
      const approvalSchemeId = getApprovalSchemeIdForEmployee(users.all, rights.copyUser);

      if (approvalSchemeId) {
        this.updateApprovalScheme(approvalSchemeId);
      }
    }

    if (rights.createERApprovalScheme && getApprovalERSchemeIdForEmployee(users.all, rights.copyUser)) {
      const approvalERSchemeId = getApprovalERSchemeIdForEmployee(users.all, rights.copyUser);
      this.updateApprovalERScheme(approvalERSchemeId);
    }
  };

  changeUser = (value: string) => this.rightsStore.updateCopyUser(value);

  changeApproversForUser = (values: string[]) => this.rightsStore.updateApproversForUser(values);

  changeApproveERUsers = (values: string[]) => this.rightsStore.updateApproversERForUser(values);

  changeViewApprovers = (values: string[]) => this.rightsStore.updateViewApprovers(values);

  changeDocuments = (value: string) => this.rightsStore.updateDocuments(value);

  changeViewRights = () => this.rightsStore.updateViewRights();

  clearRights = () => this.rightsStore.clearRights();

  clearStore = () => this.rightsStore.clearStore();

  preparedRightsToSave = () => this.rightsStore.preparedRightsToSave();

  closeAccess = () => this.rightsStore.closeAccess();

  preparedApproversToSave = () => this.rightsStore.preparedApproversToSave();

  preparedApproversToSaveId = () => this.rightsStore.preparedApproversToSaveId();

  preparedApproversExpenseReportsToSave = () => this.rightsStore.preparedApproversExpenseReportsToSave();

  checkDifferencesRights = (chosenTP: string) => this.rightsStore.checkDifferencesRights(chosenTP);

  updateIntercomCommentForUser = (id: number) => this.api.employee.updateIntercomCommentForUser(id);

  changeAccountLng = (lng: string) => this.rightsStore.changeAccountLng(lng);

  setAccountLng = async (uId: string, aId: number, loc: string) => {
    try {
      const data = {
        UserId: uId || '',
        AccountId: aId,
        Locale: loc,
      };

      await this.api.uiSettings.updateAccountLngSettings(data);
    } catch {}
  };

  getAccountLng = async (uid: string, employees: IEmployees[]) => {
    if (uid) {
      const users = employees.filter(({ Status }: { Status: string }) => Status === STATUS.ACCESS.USER);
      const selectEmployee = users.find(({ Id }: { Id : number }) => Id.toString() === uid);

      if (selectEmployee && selectEmployee.Rights) {
        const { Rights: { AccountId, UserId } } = selectEmployee;

        try {
          const data = {
            UserId: UserId || '',
            AccountId,
          };

          const locale = await this.api.uiSettings.getLngSettings(data);

          if (locale) {
            this.changeAccountLng(locale);
          }
        } catch {}
      }
    }
  };

  updateDocumentChangeAirTrip = async (dataToBeSaved: any, employee: IEmployees, typesDocument: IPassportView) => {
    await this.saveOrUpdate(dataToBeSaved);
    this.setEmployeePreviousDocument(employee, typesDocument);
    this.setEmployeeCurrentDocument(employee, typesDocument);
  };

  setActiveTimer = (value: boolean) => this.simpleSignatureStore.setActiveTimer(value);

  getImagesSignature = async (employeeId: string) => {
    this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_SIGNATURE_IMAGES, true);

    try {
      const res = await this.api.simpleSignature.getImagesSignature(employeeId);

      this.simpleSignatureStore.setImagesSignature(res);
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_SIGNATURE_IMAGES, false);
    } catch {
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_SIGNATURE_IMAGES, false);
    }
  };

  setEmployeeForDocsSignature = (employee: IEmployees) => {
    this.simpleSignatureStore.setEmployeeForDocsSignature(employee);
  };

  getEmployeeForDocsSignature = async (employeeId: number) => {
    this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_DOCS_SIGNATURE, true);

    try {
      const employee = await this.getEmployeeById(employeeId);
      this.setEmployeeForDocsSignature(employee);
    } catch {
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_DOCS_SIGNATURE, false);
    }

    return Promise.resolve();
  };

  singingDocsCode = async () => {
    const { checkBoxValues } = this.simpleSignatureStore;

    try {
      const res = await this.api.simpleSignature.sendSingingDocsCode(checkBoxValues);

      this.simpleSignatureStore.setConfirmationId(res);
    } catch {
      this.simpleSignatureStore.setConfirmationId('');
    }
  };

  singingDocs = async () => {
    const { confirmationId, signatureCode } = this.simpleSignatureStore;
    const mapperCodes = signatureCode.map(({ value }) => Number(value));
    const code = mapperCodes.join('');

    const model = {
      ConfirmationId: confirmationId,
      Code: code,
    };

    try {
      const res = await this.api.simpleSignature.sendSingingDocs(model);
      const { description, status } = res;
      const statusDescription = status === RESPONSE ? '' : description;

      this.simpleSignatureStore.setCodeResponse(statusDescription);
    } catch {
      this.simpleSignatureStore.setCodeResponse(LABELS.ERROR);
    }

    this.simpleSignatureStore.setResetSignatureCode();
  };

  downloadReportXlsx = (employeeId: number) => this.api.simpleSignature.downloadXlsx(employeeId);

  getDocumentPreviewSignature = async (documentId: string, employeeId: number) => {
    this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_DOC_PREVIEW, true);

    try {
      const images = await this.api.simpleSignature.getDocumentPreviewSignature(documentId, employeeId);

      this.simpleSignatureStore.setDocumentPreview(images);
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_DOC_PREVIEW, false);
    } catch {
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_DOC_PREVIEW, false);
    }
  };

  downloadDocument = (documentId: string, employeeId: number) => this.api.simpleSignature.downloadDocument(documentId, employeeId);

  getDocumentsForSignature = async (employeeId: number) => {
    const { paginate: { currentPage, itemsPerPage }, sortType } = this.simpleSignatureStore;

    this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_DOCS_SIGNATURE, true);

    try {
      const res = await apiNext.documentAggregator.documents.get({
        query: {
          employeeId,
          page: currentPage,
          limit: itemsPerPage,
          sortType,
          sortField: SmartwayCommonEnumsSortField.Name,
        },
      });

      const { documents, count } = res;
      this.simpleSignatureStore.setDocumentsForSignature(documents);
      this.simpleSignatureStore.setDocumentsForSignaturePages(count);
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_DOCS_SIGNATURE, false);
    } catch {
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_DOCS_SIGNATURE, false);
      this.simpleSignatureStore.setDocumentsForSignature([]);
    }
  };

  setPageSignature = async (value: number) => {
    const { employee: { Id } } = this.simpleSignatureStore;

    this.simpleSignatureStore.resetCheckedDocumentsForSignature();
    this.simpleSignatureStore.setPageSignature(value);
    await this.getDocumentsForSignature(Id);
  };

  setSortType = async (type: SmartwayCommonEnumsSortType) => {
    const { employee } = this.simpleSignatureStore;

    this.simpleSignatureStore.setSortType(type);
    await this.getDocumentsForSignature(employee.Id);
  };

  setEmployeeAndGetDocsSignature = async (employee: IEmployees) => {
    this.setEmployeeForDocsSignature(employee);
    await this.getDocumentsForSignature(employee.Id);
  };

  getEmployeeAndDocsForSignature = async (employeeId: number) => {
    try {
      await Promise.all([
        this.getEmployeeForDocsSignature(employeeId),
        this.getDocumentsForSignature(employeeId),
      ]);
    } catch {
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_DOCS_SIGNATURE, false);
    }
  };

  createSimpleSignature = async (employeeId: string) => {
    this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_SIGNATURE_CODE, true);
    const { data: { email, phoneNumber } } = this.simpleSignatureStore;

    const prepareBody = {
      ApproveAgreement: true,
      Email: email,
      PhoneNumber: phoneNumber,
      EmployeeId: employeeId,
    };

    try {
      const res = await this.api.simpleSignature.createSimpleSignature(prepareBody);

      this.simpleSignatureStore.setConfirmationId(res);
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_SIGNATURE_CODE, false);
    } catch {
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.GET_SIGNATURE_CODE, false);
    }
  };

  deleteSignature = async (employeeId: string) => {
    this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.DELETE_SIGNATURE, true);

    try {
      await this.api.simpleSignature.deleteSignature(employeeId);

      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.DELETE_SIGNATURE, false);
    } catch {
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.DELETE_SIGNATURE, false);
    }
  };

  resetSignatureStore = () => this.simpleSignatureStore.resetStore();

  resetCheckedDocumentsForSignature = () => this.simpleSignatureStore.resetCheckedDocumentsForSignature();

  resetSignatureCode = () => this.simpleSignatureStore.resetSignatureCode();

  setSignatureCode = (value: string, key: number) => this.simpleSignatureStore.setSignatureCode(value, key);

  setCheckBoxValues = (values: any) => {
    this.simpleSignatureStore.setCheckBoxValues(values);
  };

  setResetSignatureCode = () => this.simpleSignatureStore.setResetSignatureCode();

  setRepeatGetCode = async (employeeId: string) => {
    await this.createSimpleSignature(employeeId);
    this.setResetSignatureCode();
  };

  sendSignatureCode = async () => {
    this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.SEND_SIGNATURE_CODE, true);
    const { confirmationId, signatureCode } = this.simpleSignatureStore;
    const mapperCodes = signatureCode.map(({ value }) => Number(value));
    const code = mapperCodes.join('');

    const prepareBody = {
      Code: code,
      ConfirmationId: confirmationId,
    };

    try {
      const res = await this.api.simpleSignature.sendSignatureCode(prepareBody);
      const { description, status } = res;
      const statusDescription = status === RESPONSE ? '' : description;

      this.simpleSignatureStore.setCodeResponse(statusDescription);
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.SEND_SIGNATURE_CODE, false);
    } catch (e) {
      // @ts-ignore
      this.simpleSignatureStore.setCodeResponse(e?.response);
      this.simpleSignatureStore.setLoadings(LOADINGS_TYPE.SEND_SIGNATURE_CODE, false);
    }
  };

  getSimpleSignature = async (employeeId: number) => {
    this.simpleSignatureStore.setLoading(true);

    try {
      const res = await this.api.simpleSignature.loadSimpleSignature(employeeId);

      this.simpleSignatureStore.setSignature(res);
      this.simpleSignatureStore.setLoading(false);
    } catch {
      this.simpleSignatureStore.setLoading(false);
    }
  };

  setDataSignature = (field: string, value: string) => {
    this.simpleSignatureStore.setDataSignature(field, value);
    this.setDataSignatureErrors(field, value);
  };

  setDataSignatureErrors = (field: string, value: string | boolean) => this.simpleSignatureStore.setDataSignatureErrors(field, value);

  sendDocumentsToEmail = async (email: string, documentId: string, employeeId: string) => {
    try {
      await this.api.simpleSignature.sendDocumentToEmail(email, documentId, employeeId);
    } catch {}
  };
}

export default Employee;
