import React from 'react';

import { useServices } from '../../../../bi/context/services';

import { ModalMap } from './consts';

export const useModal = () => {
  const { signatureService: { enhancedSignatureStore: { activeModal } } } = useServices(['Signature']);

  return (
    (activeModal || activeModal === 0)
      ? ModalMap[activeModal]
      : React.Fragment
  );
};
