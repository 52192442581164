import { observable, computed, action, makeObservable } from 'mobx';
import type { BaseServiceTypeTravelPolicyStore } from '../../types';
import type { HotelException, TravelPolicyHotelRule } from './types';
import {
  TRAVEL_POLICY_DEFAULT_PRICE, TRAVEL_POLICY_DEFAULT_ROUTE,
  TRAVEL_POLICY_FIELDS_DICTIONARIES,
  TRAVEL_POLICY_STORE_FIELDS,
} from '../../consts';
import {
  filterNewAndRemovedExceptions,
  mapTravelPolicyDirections,
  mapTravelPolicyExceptions,
  mapTravelPolicyRoutes,
} from '../../utils';
import { SERVICETYPE } from '../../../../constants/serviceType';

const DEFAULT_GENERAL_RULES = {
  MaxPrice: {
    IsEnabled: false,
    Value: TRAVEL_POLICY_DEFAULT_PRICE,
    IsVatIncluded: false,
    IsEclcNotIncluded: false,
  },
  NotGreaterPrice: {
    IsEnabled: false,
    ValueType: TRAVEL_POLICY_FIELDS_DICTIONARIES.NOT_GREATER_PRICE_TYPE.RUBLES,
    Value: TRAVEL_POLICY_DEFAULT_PRICE,
  },
  Refundable: {
    IsEnabled: false,
  },
  Vat: {
    IsEnabled: false,
  },
  DayOff: {
    IsEnabled: false,
  },
};

const TRAVEL_POLICY_HOTEL_DEFAULT_EXCEPTION = {
  isRemoved: false,
  isNew: true,
  Type: TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.COUNTRIES,
  Routes: [{ ...TRAVEL_POLICY_DEFAULT_ROUTE }],
  ...DEFAULT_GENERAL_RULES,
};

const DEFAULT_STORE = {
  Apply: false,
  ...DEFAULT_GENERAL_RULES,
  Directions: {
    IsEnabled: false,
    showDialog: false,
    Type: TRAVEL_POLICY_FIELDS_DICTIONARIES.DIRECTION_TYPES.RUSSIA,
    Routes: [{ ...TRAVEL_POLICY_DEFAULT_ROUTE }],
  },
  Exceptions: {
    dialog: { show: false, step: 0, chosenIndex: null },
    list: [],
  },
};

class HotelTravelPolicyStore implements BaseServiceTypeTravelPolicyStore {
  constructor() {
    makeObservable(this);
  }

  @observable store = DEFAULT_STORE;

  @computed
  get mappedDirections() {
    const value = this.store.Directions;

    return {
      ...value,
      Routes: mapTravelPolicyDirections(value.Routes, SERVICETYPE.HOTEL),
    };
  }

  @computed
  get mappedExceptions() {
    const value = this.store.Exceptions;

    return {
      ...value,
      list: mapTravelPolicyExceptions(value.list, SERVICETYPE.HOTEL),
    };
  }

  @computed
  get preparedToSaveModel(): TravelPolicyHotelRule {
    const { Exceptions, ...rest } = this.store;

    const preparedExceptions = filterNewAndRemovedExceptions(Exceptions.list)
      .map(ei => ({
        ...ei,
        [TRAVEL_POLICY_STORE_FIELDS.TARIFF]: {
          [TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE]: ei[TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE].IsEnabled,
          [TRAVEL_POLICY_STORE_FIELDS.VAT]: ei[TRAVEL_POLICY_STORE_FIELDS.VAT].IsEnabled,
        },
        [TRAVEL_POLICY_STORE_FIELDS.DAY_OFF]: ei[TRAVEL_POLICY_STORE_FIELDS.DAY_OFF].IsEnabled,
      }));

    // @ts-ignore
    return {
      ...rest,
      [TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS]: preparedExceptions,
      [TRAVEL_POLICY_STORE_FIELDS.TARIFF]: {
        [TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE]: this.store.Refundable.IsEnabled,
        [TRAVEL_POLICY_STORE_FIELDS.VAT]: this.store.Vat.IsEnabled,
      },
      [TRAVEL_POLICY_STORE_FIELDS.DAY_OFF]: this.store.DayOff.IsEnabled,
    };
  }

  @action
  mapServerModelToClient = (model: TravelPolicyHotelRule) => {
    const { Tariff = {}, Exceptions = [], Directions, ...rest } = model;

    const { Refundable = false, Vat = false } = Tariff;
    const { Routes } = Directions;

    this.store = {
      ...this.store,
      ...rest,
      Refundable: {
        IsEnabled: Refundable,
      },
      Vat: {
        IsEnabled: Vat,
      },
      Directions: {
        ...this.store.Directions,
        ...Directions,
        Routes: mapTravelPolicyRoutes(Routes),
      },
      Exceptions: {
        ...this.store.Exceptions,
        // @ts-ignore
        list: Exceptions.map((ei: HotelException) =>
          ({
            ...ei,
            [TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE]: {
              IsEnabled: ei.Tariff.Refundable,
            },
            [TRAVEL_POLICY_STORE_FIELDS.VAT]: {
              IsEnabled: ei.Tariff.Vat,
            },
            Routes: mapTravelPolicyRoutes(ei.Routes),
          }),
        ),
      },
    };
  };

  @action
  updateStore = (payload: any) => {
    this.store = {
      ...this.store,
      ...payload,
    };
  };

  @action
  clearStore = () => {
    this.store = DEFAULT_STORE;
  };
}

export default HotelTravelPolicyStore;

export { TRAVEL_POLICY_HOTEL_DEFAULT_EXCEPTION };
