import { MetricsStore, MetricsStoreType } from './store';

import { HotelsMetrics } from './types';

class Metrics {
  api: any;
  store: MetricsStoreType;

  constructor(api: any) {
    this.api = api.metrics;
    this.store = MetricsStore;
  }

  startTime = (type = '') => {
    this.store.setStartTime(new Date().getTime());
    this.store.setType(type);
  };

  endTime = () => {
    const { type, startTime } = this.store;

    if (type.length) {
      const result = new Date().getTime() - startTime;

      const data = { TimeDuration: result, Method: type };

      this.api.sendMetric(data);
    }
  };

  sendMetric = (data: any, method: string) => this.api.sendMetric({ data, Method: method });

  startTimeHotels = (method: string) => {
    if (!this.store.hotelsMetrics[method as keyof HotelsMetrics].active) {
      this.store.setStartTimeHotel(method, new Date().getTime());
    }
  };

  endTimeHotels = (method = '', params: any) => {
    const { hotelsMetrics } = this.store;

    if (hotelsMetrics[method as keyof HotelsMetrics].active) {
      const duration = new Date().getTime() - hotelsMetrics[method as keyof HotelsMetrics].startTime;
      this.store.setEndTimeHotel(method);
      const data = {
        ...params,
        Method: method,
        Duration: duration,
      };

      this.api.sendHotelsMetric(data);
    }
  };
}

export { Metrics };
