const throttle = <T extends any[], R>(
  func: (...args: T) => R,
  ms: number,
): (...args: T) => void => {
  let isThrottled = false;
  let savedArgs: T | null = null;
  let savedThis: typeof this | null = null;

  const wrapper = (...args: T) => {
    if (isThrottled) {
      savedArgs = args;
      savedThis = this;

      return;
    }

    func.apply(this, args);

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;

      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = null;
        savedThis = null;
      }
    }, ms);
  };

  return wrapper;
};

export { throttle };
