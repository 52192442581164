import { toJS } from 'mobx';
import { NotificationsManager } from 'new-ui';

import Api from '../../api';

import { DepartmentsStore, DepartmentStatus } from './store';

import { omit } from '../../utils/common';
import { history } from '../../../../utils/history';
import { bound, withInternalMethod } from '../utils/dectrators';
import NetworkStatesStore from '../utils/network/networkStatesStore';

import TEXTS from '../../constants/texts';
import ROUTES from '../../constants/routes';
import { LABELS } from '../../../page/Department/consts';
import { FILTER_KEYS } from '../../constants/employee';
import { DEFAULT_FORM_VALUE } from '../../constants/departments';
import { QA_ATTRIBUTES } from '../../constants/attributesForTests';
import { ERROR_MESSAGE } from '../../constants/app';

import {
  DepartmentFormStore,
  DialogTypes,
  GroupedDepartmentStatus,
  ICompanyList,
  IDepartmentSearchArgs,
  LoadingFields,
  UserByCompany,
} from './types';

class DepartmentsService {
  store: typeof DepartmentsStore;
  networkStore = new NetworkStatesStore<LoadingFields>();

  xhrAutocomplete: { abort: () => void } | null = null;
  api: Api;

  constructor(api: Api) {
    this.api = api;
    this.store = DepartmentsStore;
  }

  justLoadList = () => this.api.userSession.getDepartments();

  @bound
  @withInternalMethod((o) => o.networkStore.withLoaderFlow(LoadingFields.loadDepartment))
  async getDepartment(id: number | string) {
    this.openForm(await this.api.userSession.getDepartment(id));
  }

  loadList = () => {
    this.store.updateList({ loading: true });
    this.justLoadList()
      .then((value: any) => this.store.updateList({ loading: false, value }))
      .catch((error: Error) => this.store.updateList({
        loading: false,
        error,
      }));
  };

  @bound
  @withInternalMethod((o) => o.networkStore.withLoaderFlow(LoadingFields.loadDepartmentForSelectList))
  async getDepartmentForSelectList() {
    const employees = await this.api.userSession.getGroupedDepartments(GroupedDepartmentStatus.active);
    this.store.setDepartmentForSelectList(employees);
  }

  deleteDepartment = async (departmentId: number) => {
    this.store.setDepartmentDeleteLoader(true);

    await this.api.userSession.deleteDepartment(departmentId);

    this.store.setDepartmentDeleteLoader(false);
    this.getDepartmentsPaginationListNew();
  };

  clearList = () => this.store.updateList({ value: [] });

  clearForm = () => {
    this.store.clearChanges();
    this.store.updateForm({ value: DEFAULT_FORM_VALUE });
  };

  updateForm = (value: Partial<Omit<DepartmentFormStore, 'hasChanges'>>, hasChanges = true) =>
    this.store.updateForm({ ...value, hasChanges });

  setDefaultCompanyId = (id: number) => this.store.updateForm({
    ...this.store.form,
    value: { ...this.store.form.value, CompanyId: id },
  });

  @bound
  @withInternalMethod(o => o.networkStore.withLoaderFlow(LoadingFields.loadUserList))
  async loadEmployees(companyId: number) {
    const employees = await this.api.employee.getEmployeesByCompany(companyId, true) as UserByCompany[];

    this.store.updateForm({
      employeesHash: employees.reduce((r, e) => ({ ...r, [e.Id]: e }), {}),
      employeesList: employees.map(
        ({ Id, Name, Surname, Patronymic }) => ({
          value: Id, label: `${Surname} ${Name} ${Patronymic}`,
        }),
      ),
    });
  }

  openForm = (value = { EmployeesIds: [], HeadOfDepartmentId: null, Id: null, Name: '' }) => {
    const { EmployeesIds, HeadOfDepartmentId, Name } = value;

    this.store.setInitialName(Name);
    this.store.updateForm({
      loading: true,
      forcedPrompt: false,
      value: {
        ...value,
        employees: EmployeesIds ? EmployeesIds.reduce((r, id) => ({ ...r, [id]: { id, isRemoved: false } }), {}) : {},
        headOfDepartment: HeadOfDepartmentId ? { Id: HeadOfDepartmentId, isRemoved: false } : {},
      },
    });
  };

  setFormError = (message: any) => {
    const result = () => {
      switch (message.status) {
        case 409:
          return LABELS.DUPLICATE_DEPARTMENT;
        default:
          return TEXTS.SOMETHING_WENT_WRONG;
      }
    };

    this.store.openDialog(DialogTypes.error, result())();
  };

  @bound
  @withInternalMethod((o) =>
    o.networkStore.withLoaderFlow(LoadingFields.saveDepartment, o.setFormError),
  )
  async saveForm(data: any) {
    const { Name } = await this.api.userSession.saveDepartment(data);

    await this.store.asyncClearChanges();
    history.push(ROUTES.SETTINGS.DEPARTMENTS);

    NotificationsManager.addNotification({
      type: 'success',
      value: LABELS.SAVE_SUCCESS(Name),
      qaAttr: QA_ATTRIBUTES.settings.departments.notification,
    });
  }

  loadSettingsDepartmentsPage = (field?: string | number) => {
    const data = this.getDataSearchDepartmentsAndChangePagination(field);

    Promise.all([
      this.getDepartmentsCount(field),
      this.getDepartmentsByFilter(data),
    ])
      .then((list) => {
        this.store.setDepartmentsCount(list[0]);
        this.store.setDepartments(list[1]);
      }).catch(() => {
        this.store.setDepartmentsCount(0);
        this.store.setDepartments([]);
      }).finally(() => this.setDepartmentsWaitingResponse(false));
  };

  setDepartmentsWaitingResponse = (waitingResponse: boolean) => this.store.setWaitingResponse(waitingResponse);

  getDepartmentsCount = (field: any) => {
    const data = this.getDataSearchDepartmentsAndChangePagination(field);

    return this.api.userSession.getDepartmentsCount(data);
  };

  getDepartmentsByFilter = (data: string) => this.api.userSession.getDepartmentsByFilter(data);

  changeFilters = (field: string | number, value: any) => this.store.setDepartmentsFilters(field, value);

  getDataSearchDepartmentsAndChangePagination = (filterField: any) => {
    this.changePagination(filterField);

    return this.getDataSearchDepartments();
  };

  getDepartmentsPaginationListNew = () => {
    this.setDepartmentsWaitingResponse(true);
    this.getFilterDepartments(FILTER_KEYS.COMPANY_ID, 0);
  };

  getDataSearchDepartments = () => {
    const {
      filter: { companyId, search, departmentStatus },
      paginate: { currentPage, itemsPerPage },
    } = this.store;

    let filters: IDepartmentSearchArgs['Filters'] = {};

    if (companyId !== 0) {
      filters = { ...filters, CompanyId: companyId };
    }

    if (search !== '') {
      filters = { ...filters, SearchString: search };
    }

    if (departmentStatus !== DepartmentStatus.all) {
      filters = { ...filters, DepartmentStatus: departmentStatus };
    }

    const data: IDepartmentSearchArgs = {
      Page: currentPage,
      Step: itemsPerPage,
      Filters: filters,
    };

    return JSON.stringify(data);
  };

  changePagination = (filterField: any) => {
    if (filterField) {
      this.changePageDepartmentsOnly(1);
    }
  };

  changePageDepartmentsOnly = (page: number) => this.store.setPageDepartment(page);

  getFilterDepartments = (field?: string | number, value?: number) => {
    if (field) {
      this.changeFilters(field, value);
    }

    return this.loadSettingsDepartmentsPage(field);
  };

  changePageDepartments = (page: number) => {
    this.changePageDepartmentsOnly(page);
    this.getFilterDepartments();
  };

  getDepartmentsCompanies = async () => {
    try {
      const list: ICompanyList[] = await this.api.userSession.getDepartmentsCompanies();

      this.store.setCompanies(list);
    } catch (error: any) {
      if (error.message === ERROR_MESSAGE.FORBIDDEN) {
        history.push('/settings');
      }
    }
  };

  @bound
  @withInternalMethod((o) =>
    o.networkStore.withLoaderFlow(LoadingFields.updateName, o.setFormError),
  )
  async updateName() {
    await this.api.userSession.setDepartmentName(
      this.store.currentId, this.store.form.value.Name,
    );
    this.store.setInitialName(this.store.form.value.Name);
    await this.store.asyncClearChanges();
  }

  @bound
  @withInternalMethod((o) =>
    o.networkStore.withLoaderFlow(LoadingFields.setOrRemoveEmployee),
  )
  async setEmployeeList(ids: number[]) {
    if (!this.store.isNewDepartment) {
      await this.api.userSession.setDepartmentEmployees(
        this.store.currentId, ids,
      );
    }

    this.store.updateFormValue('employees')(
      Object.fromEntries(
        ids.map((num) => [num, { id: num, isRemoved: false }]),
      ),
    );
    this.store.closeDialog();
  }

  @bound
  @withInternalMethod((o) =>
    o.networkStore.withLoaderFlow(LoadingFields.setOrRemoveEmployee),
  )
  async removeEmployee(id: number) {
    if (!this.store.isNewDepartment) {
      await this.api.userSession.removeDepartmentEmployees(
        this.store.currentId, [id],
      );
    }

    this.store.updateFormValue('employees')(
      omit(toJS(this.store.form.value.employees), [String(id)]),
    );
    this.store.closeDialog();
  }

  @bound
  @withInternalMethod((o) =>
    o.networkStore.withLoaderFlow(LoadingFields.setOrRemoveEmployee),
  )
  async removeHead() {
    if (!this.store.isNewDepartment) {
      await this.api.userSession.removeDepartmentHead(
        this.store.currentId,
      );
    }

    this.store.updateFormValue('headOfDepartment')({ Id: null });
    this.store.closeDialog();
  }

  @bound
  @withInternalMethod((o) =>
    o.networkStore.withLoaderFlow(LoadingFields.setOrRemoveEmployee),
  )
  async setHead(id: number) {
    if (!this.store.isNewDepartment) {
      await this.api.userSession.setDepartmentHead(
        this.store.currentId, id,
      );
    }

    this.store.updateFormValue('headOfDepartment')({ Id: id });
    this.store.closeDialog();
  }

  @bound
  @withInternalMethod((o) =>
    o.networkStore.withLoaderFlow(LoadingFields.loadDepartmentForSelectList),
  )
  async loadListForApprove() {
    this.store.setDepartmentForSelectList(
      await this.justLoadList(),
    );
  }

  autocomplete = (query: string) => {
    if (this.xhrAutocomplete) this.xhrAutocomplete.abort();

    this.xhrAutocomplete = this.api.employee.getAutocompleteForDepartment({
      onlyAdult: true,
      search: query.trim(),
      companyId: Number(this.store.form.value.CompanyId),
    });

    return this.xhrAutocomplete;
  };
}

export default DepartmentsService;
