import React from 'react';
import { observer } from 'mobx-react';

import {
  Collapse,
  DefaultBarChart,
  Icon,
  PROPS,
  Scale,
  StyledWrapper,
  Text,
  Tooltip,
} from 'new-ui';

import { getText } from '../../../../../../../i18n';

import styles from './index.module.css';

const { CHART_COLORS } = PROPS;

const LABELS = {
  HEADER: getText('chartsAnalytics:menuItems.smart.indexChart.header'),
  RECOMMEND: getText('chartsAnalytics:menuItems.smart.indexChart.recommend'),
  BUDGET_OPTIMIZED: getText('chartsAnalytics:menuItems.smart.indexChart.budgetOptimized'),
  BUDGET_SPENT_OPTIMALLY: getText('chartsAnalytics:menuItems.smart.indexChart.budgetSpentOptimally'),
  BUDGET_SPENT_NOT_OPTIMALLY: getText('chartsAnalytics:menuItems.smart.indexChart.budgetSpentNotOptimally'),
};

interface SmartIndexChartProps {
  title: string,
  values: number[],
  labels: string[],
  tooltip: string,
  summaryIndex: {
    level: number,
    recommendation: string,
  }
}

const SmartIndexChart = observer(({
  title,
  values,
  labels,
  tooltip,
  summaryIndex: {
    level,
    recommendation,
  },
}: SmartIndexChartProps) => {
  const colors = CHART_COLORS.ALTERNATIVE_3;

  const renderIcon = () => (
    <Tooltip
      renderContent={ () => (
        <div className={ styles['tooltip-content'] }>
          <Text
            type='NORMAL_14_130'
            color='white'
          >
            { tooltip }
          </Text>
        </div>
      ) }
      className={ styles.tooltip }
      position='right'
    >
      <Icon type='question' />
    </Tooltip>
  );

  const renderRecommendation = () => {
    if (!recommendation) {
      return null;
    }

    return (
      <div className={ styles.recommend }>
        <div className={ styles['recommend-title'] }>
          <Icon type='warning' />
          <Text className={ styles.text }>{LABELS.RECOMMEND}</Text>
        </div>
        <div
          className={ styles.content }
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={ { __html: recommendation } }
        />
      </div>
    );
  };

  const recommendationHtml = renderRecommendation();

  return (
    <StyledWrapper className={ styles.wrapper }>
      <div className={ styles['wrapper-content'] }>
        <div className={ styles.header }>
          { title } { renderIcon() }
        </div>
        <Scale
          index={ level }
          greenText={ LABELS.BUDGET_OPTIMIZED }
          yellowText={ LABELS.BUDGET_SPENT_OPTIMALLY }
          redText={ LABELS.BUDGET_SPENT_NOT_OPTIMALLY }
        />
        {recommendationHtml}
      </div>
      <hr className={ styles.hr } />
      <Collapse
        besideArrow
        label={ LABELS.HEADER }
        opened={ false }
        className={ styles.collapse }
        classNameText={ styles.text }
        classNameChildren={ styles.children }
      >
        <DefaultBarChart
          values={ values }
          barLabels={ labels }
          colors={ colors }
        />
      </Collapse>
    </StyledWrapper>
  );
});

export { SmartIndexChart };
