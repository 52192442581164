import { api } from '../../apiV2';

import { EmployeePaymentStoreType, EmployeePaymentStore } from './store';

import { PaginationOptions, ServerResponse } from './types';
import { CommissionType } from '../../types/paymentMethod';

class EmployeePaymentService {
  api: typeof api['employeePersonalPayment'];
  store: EmployeePaymentStoreType;

  constructor() {
    this.api = api.employeePersonalPayment;
    this.store = EmployeePaymentStore;
  }

  getEmployeePersonalTransactions = async (sort: PaginationOptions): Promise<ServerResponse> => {
    try {
      const employeeTransactions = await this.api.getEmployeePersonalTransactions({ ...sort });
      this.store.setTransactions(employeeTransactions);

      return employeeTransactions;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getCommissionFee = async (price: number, type: CommissionType): Promise<number> => {
    this.store.setCommissionLoading(true);
    try {
      const result = await this.api.getCommissionInfo(price, type);
      this.store.setCommission(result);

      return result;
    } catch (error) {
      return await Promise.reject(error);
    } finally {
      this.store.setCommissionLoading(false);
    }
  };

  getPersonalBalance = async (): Promise<number> => {
    try {
      const result = await this.api.getPersonalBalance();
      this.store.setPersonalBalance(result);

      return result;
    } catch (error) {
      this.store.setPersonalBalance(0);

      return 0;
    }
  };
}

export default EmployeePaymentService;
