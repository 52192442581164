// @ts-nocheck
import { getText } from '../../../../i18n';

import Api from '../../api';
import debounce from '../../api/debounce';

import { lpad } from '../../utils/pad';
import { Amplitude, MainAnalytic, MixPanel } from '../../utils/analytics';
import { formatDate, momentObject } from '../../utils/formatDate';

import { PATTERN } from '../../constants/dateFormats';

import { TrainStore } from './store/train';
import { TrainSearchStore } from './store/search';
import { TrainTicketsStore } from './store/tickets';
import { tripCacheStore } from '../order/stores/tripCache';
import { TrainSavedTicketStore } from './store/savedTicket';
import { TrainSavedTicketsStore } from './store/savedTicketsWithTransfer';

import { prepareCarStats } from './analytics';

import { ACTION_TYPES, TRACK_TYPES } from '../../constants/mixpanel';
import { CARTYPE } from '../../constants/train';
import { TRAVELPOLICYFILTER } from '../../constants/travelPolicy';

import { getTrainNameByNumber, modifySearchResponse } from '../../utils/train';
import NetworkStatesStore from '../utils/network/networkStatesStore';
import { bound, withInternalMethod } from '../utils/dectrators';

import { getSapsanType, isSapsan } from './corporateTrains/sapsan';
import { isStrij } from './corporateTrains/strij';
import { isNevskij } from './corporateTrains/nevskij';
import { isLastochka } from './corporateTrains/lastochka';

const DEBOUNCE_TIME = 200;

const COMPARTMENT = {
  ONECOMPARTMENT: 0,
  COMPARTMENTPARTCAR: 1,
  ONESECTION: '2',
};

const LABELS = {
  FILTER_TRAVEL_POLICY_NOT_APPLIED: getText('components:filterTravelPolicy.notApplied'),
};

const COMPARTMENTVALUE = [
  { label: getText('services:trains.compartmentValue.default'), value: null },
  { label: getText('services:trains.compartmentValue.coupePart'), value: COMPARTMENT.COMPARTMENTPARTCAR },
  { label: getText('services:trains.compartmentValue.platzPart'), value: COMPARTMENT.ONESECTION },
];

const getRoutesDirectionsAndDate = (from, to, date, dateBack, isBack) => {
  if (isBack) {
    return { fromRoute: to, toRoute: from, departureDate: dateBack };
  }

  return { fromRoute: from, toRoute: to, departureDate: date };
};

const getRoutesDirections = (from, to) => ({ fromRoute: to, toRoute: from });

class Trains {
  api: Api['train'];
  netStore = new NetworkStatesStore<'addToCartStatus'>();

  constructor(api) {
    this.api = api.train;
    this.xhr = null;
    this.searchStore = TrainSearchStore;
    this.ticketsStore = TrainTicketsStore;
    this.trainStore = TrainStore;
    this.savedTicketStore = TrainSavedTicketStore;
    this.savedTicketsStore = TrainSavedTicketsStore;

    this.debounceAutocomplete = debounce(this.api.autocomplete, DEBOUNCE_TIME);
  }

  saveCarsWithoutSchemaStats = (data, add = false) => {
    const params = prepareCarStats(data, add);

    this.api.saveCarsWithoutSchemaStats(params);
  };

  updateCarsDescription = () => {
    const { train } = this.trainStore;

    const promises = [];
    train.Cars.forEach(({ Tariffs }) => {
      Tariffs.forEach((tariff) => {
        promises.push(
          this.getCarDescription(tariff, train).then(carDescription => ({
            ...carDescription,
            carNumber: tariff.Number,
            id: tariff.Id,
          })),
        );
      });
    });

    Promise.all(promises).then((descriptions) => {
      const carsWithTariffs = train.Cars.map((car) => {
        const tariffWithDescription = car.Tariffs.map((tariff) => {
          const tariffDescription = descriptions.find(descriptionItem =>
            descriptionItem.carNumber === car.Number && descriptionItem.id === tariff.Id);

          return { ...tariff, tariffDescription };
        });

        return { ...car, Tariffs: tariffWithDescription };
      });

      this.trainStore.setTrainInfo({ ...train, Cars: [...carsWithTariffs] });
    });
  };

  getCarPlacesDetails = async (
    trainId,
    searchId,
    number,
    carId,
    companies,
    trainNumber,
    carService,
    carType,
    carDetails,
    currentLng,
  ) => {
    this.trainStore.setLoadingCarDetails(true);

    const {
      CarType,
      CarNumber,
      FirmName,
      PlacesPrices,
      PlacesByCompartmentPrices = [],
    } = await this.api.getCarPlacesDetails(trainId, searchId, number, carId);

    const params = {
      CarNumber: number,
      CarClass: carService,
      CarType: carType,
      TwoFloor: !!carDetails,
    };

    const {
      type,
      carImage,
      carJSON: carJSONString,
      direction,
    } = await this.api.getCarPlacesSchema(
      trainNumber,
      currentLng,
      params,
    );

    const carJSON = carJSONString ? JSON.parse(carJSONString) : null;

    const placeNumbers = [];
    PlacesPrices.forEach(({ Places }) => Places.forEach(place => placeNumbers.push(place)));

    let car = {
      Places: placeNumbers,
      Type: CarType,
      Number: CarNumber,
      FirmName,
      PlacesPrices,
      TrainNumber: trainNumber,
      trainType: type,
      carJSON,
      carImage,
      direction,
      PlacesByCompartmentPrices,
    };

    const { train } = this.trainStore;

    const numberCarId = parseInt(carId, 10);

    train.Cars.some(({ Tariffs }) => {
      const selectedTariff = Tariffs.find(({ Id }) => Id === numberCarId);

      if (selectedTariff) {
        car = {
          ...selectedTariff,
          ...car,
        };

        return true;
      }

      return false;
    });

    const { Description } = await this.getCarDescription(car, train);
    car.description = Description;

    if (!car.trainType) {
      this.saveCarsWithoutSchemaStats({ car, companies, train }, false);
    }

    return this.trainStore.setPlaceDetails(car);
  };

  getTrainById = (trainId, searchId) => {
    this.trainStore.setLoading(true);

    return this.api.getTrainById(trainId, searchId)
      .then(({ SearchRequest, Train }) => {
        const { date } = this.searchStore;
        const { savedTickets } = this.savedTicketsStore;
        const savedTicket = this.getSavedTicket();

        const updatedSearchRequest = savedTicket || savedTickets.length ? {
          ...SearchRequest,
          DepartureDate: date,
        } : SearchRequest;

        this.searchStore.setHistoryInstance(updatedSearchRequest, searchId);

        const updatedCars = Train.Cars.map((car) => {
          const updatedTariffs = car.Tariffs.map(tariff => ({
            ...tariff,
            Number: car.Number,
          }));

          return {
            ...car,
            Tariffs: updatedTariffs,
          };
        });

        this.trainStore.setTrainInfo({ ...Train, Cars: updatedCars });
        this.trainStore.setLoading(false);
      });
  };

  getCarDescription = ({ Number, Carrier, ClassService, AddSigns }, train) => {
    const trainType = getTrainNameByNumber(train) || null;

    return this.api.getCarDescription({
      CarNumber: `${Number}`,
      CompanyName: `${Carrier}`,
      ServiceClass: `${ClassService}`,
      TrainNumber: `${train.Number}`,
      TrainType: trainType,
      AddSigns,
    });
  };

  getHistory = () => this.api.getSearchHistory().then(this.setHistory);

  getCompartmentValue = () => COMPARTMENTVALUE;

  getSapsanType = type => getSapsanType(type);

  setHistory = list => this.searchStore.setHistoryList(list);

  setWarningTemplate = () => {
    this.api.getWarningTemplate().then((res) => {
      this.searchStore.setWarningTemplate(res);
    });
  };

  checkSeatsPlace = (car, place) => {
    let result = lpad(place, 3);

    for (let i = 0; i < car.Places.length; i++) {
      if (car.Places[i].indexOf(result) !== -1) {
        result = car.Places[i];
        break;
      }
    }

    return result;
  };

  deleteTag = tag => this.ticketsStore.deleteFilterTag(tag);

  setNewSearch = () => {
    this.searchStore.setNewSearch();
    this.ticketsStore.setNewSearch();
    this.savedTicketStore.clearStore();
    this.savedTicketsStore.clearStore();
  };

  setImmediateSearch = (value) => {
    if (value) {
      this.ticketsStore.setNewSearch();
    }

    return this.searchStore.setImmediateSearch(value);
  };

  setFlipFromTo = () => {
    this.searchStore.setFlipFromTo();

    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.CHANGEDIRECTION);
  };

  autocomplete = (query, storeCB, fnLoading) => {
    fnLoading();

    if (this.xhrAutocomplete) this.xhrAutocomplete.abort();

    if (this.autocompleteLoadingTimer) clearTimeout(this.autocompleteLoadingTimer);

    this.xhrAutocomplete = this.debounceAutocomplete(query.trim());

    this.autocompleteLoadingTimer = setTimeout(() => storeCB(query, [], true), 1000);

    return this.xhrAutocomplete.then((res) => {
      clearTimeout(this.autocompleteLoadingTimer);
      storeCB(query, res, false);
    });
  };

  autocompleteFrom = query => this.autocomplete(query, this.searchStore.setFromSuggests, () => this.searchStore.setFromSuggestsLoading(true));

  autocompleteTo = query => this.autocomplete(query, this.searchStore.setToSuggests, () => this.searchStore.setToSuggestsLoading(true));

  setSchemeLoading = value => this.searchStore.setSchemeLoading(value);

  setSearchFromApproveRequest = async (travelApproval) => {
    const { Destinations } = travelApproval;
    this.setSchemeLoading(true);

    try {
      const res = await this.api.autocomplete(travelApproval.Destinations[0].Name);
      const updateTravelApproval = {
        ...travelApproval,
        Destinations: Destinations[0]?.Name.trim() ? res[0] : { Name: '', Id: null },
      };
      this.searchStore.setSearchFromApprovalRequest(updateTravelApproval);
    } catch (e) {
      this.searchStore.setSearchFromApprovalRequest(null);
    } finally {
      this.setSchemeLoading(false);
      this.ticketsStore.setNewSearch();
      this.savedTicketStore.clearStore();
      this.savedTicketsStore.clearStore();
    }
  };

  saveAnalyticsBeforeSearch = (origin, destination, startDate, people) => MixPanel.track(ACTION_TYPES.SEARCH, {
    type: TRACK_TYPES.TRAIN,
    origin,
    destination,
    startDate,
    people,
  });

  saveAnalyticsAfterSearch = () => {
    const { subMenu } = this.ticketsStore;
    const { from, to, travellers } = this.searchStore;

    Amplitude.pushEvent(Amplitude.TYPE.TRAIN.SEARCH);

    const label = `${from.label} - ${to.label}`;
    const opts = {
      label,
      value: travellers,
    };

    if (subMenu) {
      MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.UPDATESEARCHONEWAY, opts);
    } else {
      MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.SEARCHONEWAY, opts);
    }
  };

  saveAnalyticsAfterSearchError = () => {
    const { from, to, travellers } = this.searchStore;

    return MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.SEARCHNORESULT, {
      label: `${from.label} - ${to.label}`,
      value: travellers,
    });
  };

  getSavedArriveDate = (trains) => {
    const savedTicket = this.getSavedTicket();
    const { savedTickets } = this.savedTicketsStore;

    if (!savedTicket && !savedTickets.length) return trains;

    return savedTickets.length ?
      momentObject(savedTickets[savedTickets.length - 1].train.ArrivalDate) :
      momentObject(savedTicket.train.ArrivalDate);
  };

  filterTrains = trains => trains.filter(({ DepartureDate }) => this.getSavedArriveDate(trains).isBefore(DepartureDate));

  filterTrainWithTransfer = trains =>
    trains.filter((items) => items.every(item => this.getSavedArriveDate(trains).isBefore(item.Trains[0].DepartureDate)));

  search = (settings) => {
    const { from, to, date, dateBack, travellers, fromHistory, immediate, firstSearch } = this.searchStore;
    const savedTicket = this.getSavedTicket();
    const { isChoosingBack } = this.savedTicketsStore;

    const isBack = !!dateBack && (!!savedTicket || isChoosingBack);

    this.setIsBackTrainChosing(isBack);

    const { fromRoute, toRoute, departureDate } = getRoutesDirectionsAndDate(from, to, date, dateBack, isBack);

    const params = {
      StationFrom: fromRoute.selected.Code,
      StationTo: toRoute.selected.Code,
      DepartureDate: departureDate.format(PATTERN.YEARMONTHDAY),
      Travellers: Number(travellers),
      FromHistory: fromHistory,
    };

    this.saveAnalyticsBeforeSearch(params.StationFrom, params.StationTo, params.DepartureDate, travellers);

    return this.api.search(params).then((res) => {
      this.saveAnalyticsAfterSearch();

      if (immediate) {
        this.setImmediateSearch(false);
      }

      if (firstSearch) {
        this.setFirstSearch(false);
      }

      const searchRes = modifySearchResponse(res);

      const filteredTrains = isBack && searchRes.Trains ? this.filterTrains(searchRes.Trains) : searchRes.Trains;
      const filteredTrainsWithTransfer = isBack && searchRes.TrainsWithTransfer ?
        this.filterTrainWithTransfer(searchRes.TrainsWithTransfer) : searchRes.TrainsWithTransfer;

      return this.ticketsStore.setTicketsList({
        search: {
          ...searchRes,
          Trains: filteredTrains,
          TrainsWithTransfer: filteredTrainsWithTransfer,
        },
        settings,
      });
    }).catch(() => {
      this.saveAnalyticsAfterSearchError();

      return this.ticketsStore.setTicketsList({
        search: {
          Id: null,
          Trains: [],
          TrainsWithTransfer: [],
        },
        settings,
      });
    });
  };

  searchTransfers = (settings) => {
    const { from, to, date, dateBack, travellers, fromHistory } = this.searchStore;
    const { tickets } = this.ticketsStore;
    const savedTicket = this.getSavedTicket();
    const { isChoosingBack } = this.savedTicketsStore;

    const isBack = !!dateBack && (!!savedTicket || isChoosingBack);

    const { fromRoute, toRoute, departureDate } = getRoutesDirectionsAndDate(from, to, date, dateBack, isBack);

    const params = {
      StationFrom: fromRoute.selected.Code,
      StationTo: toRoute.selected.Code,
      DepartureDate: departureDate.format(PATTERN.YEARMONTHDAY),
      Travellers: Number(travellers),
      FromHistory: fromHistory,
    };

    return this.api.searchTransfers(params)
      .then((res) => {
        const filteredTrainsWithTransfer = isBack && res.TrainsWithTransfer ?
          this.filterTrainWithTransfer(res.TrainsWithTransfer) : res.TrainsWithTransfer;

        return this.ticketsStore.setTicketsList({
          search: {
            ...res,
            Trains: tickets,
            TrainsWithTransfer: filteredTrainsWithTransfer,
          },
          settings,
        });
      })
      .catch(() => this.ticketsStore.setTicketsList({
        search: {
          Id: null,
          Trains: [],
          TrainsWithTransfer: [],
        },
        settings,
      }));
  };

  getSearchObjectByHistoryItem = item => ({
    StationFrom: item.StationFrom.Code,
    StationTo: item.StationTo.Code,
    StationToCity: item.StationTo.City,
    StationFromCity: item.StationFrom.City,
    DepartureDate: item.DepartureDate,
    Travellers: item.Travellers,
    FromHistory: true,
  });

  searchByHistoryItem = (params, settings) => {
    const { StationFromCity, StationToCity, StationTo, StationFrom, DepartureDate, Travellers, FromHistory } = params;

    const mixPanelData = {
      type: TRACK_TYPES.TRAIN,
      origin: StationFromCity,
      destination: StationToCity,
      startDate: DepartureDate,
      people: Travellers,
    };

    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.SEARCHONEWAYFROMHISTORY, {
      label: `${StationFromCity} - ${StationToCity}`,
      value: Travellers,
    });

    const changedParams = {
      ...params,
      DepartureDate: formatDate(DepartureDate, PATTERN.YEARMONTHDAY),
      FromHistory: FromHistory === 'true',
      Travellers: Number(Travellers),
    };

    return this.api.search(changedParams).then((res) => {
      MixPanel.track(ACTION_TYPES.SEARCH, mixPanelData);

      const searchState = {
        StationFrom: {
          City: StationFromCity,
          Code: StationFrom,
        },
        StationTo: {
          City: StationToCity,
          Code: StationTo,
        },
        Travellers: Number(Travellers),
        DepartureDate,
      };

      this.searchStore.setHistoryInstance(searchState, res.Id);

      this.ticketsStore.setTicketsList({
        search: modifySearchResponse(res),
        settings,
      });
    }).catch(() => {
      this.ticketsStore.setTicketsList({
        search: {
          Id: null,
          Trains: [],
          TrainsWithTransfer: [],
        },
        settings,
      });
    });
  };

  searchByParams = (params, settings) => {
    const { sp_code, sp_name, ep_code, ep_name, date, t_count, number } = params;

    const searchParams = {
      StationFrom: sp_code,
      StationTo: ep_code,
      DepartureDate: date,
      Travellers: Number(t_count),
    };

    return this.api.search(searchParams).then((res) => {
      const searchState = {
        StationFrom: {
          City: sp_name,
          Code: sp_code,
        },
        StationTo: {
          City: ep_name,
          Code: ep_code,
        },
        Travellers: Number(t_count),
        DepartureDate: date,
      };

      this.searchStore.setHistoryInstance(searchState, res.Id);

      this.ticketsStore.setTicketsList({
        search: modifySearchResponse(res),
        settings,
        filters: {
          number,
        },
        favorite: {
          number,
          date,
        },
      });
    }).catch(() => {
      this.ticketsStore.setTicketsList({
        search: {
          Id: null,
          Trains: [],
          TrainsWithTransfer: [],
        },
        favorite: {
          number,
          date,
        },
        settings,
      });
    });
  };

  searchFromRequest = (requestItem, settings) => {
    const { SearchOptions } = requestItem;
    const { DepartureStation, ArrivalStation } = SearchOptions;
    const departureDate = formatDate(SearchOptions.DepartureDate, PATTERN.YEARMONTHDAY);

    const searchParams = {
      StationFrom: DepartureStation.Code,
      StationTo: ArrivalStation.Code,
      DepartureDate: departureDate,
      Travellers: requestItem.EmployeesNames.length,
      FromHistory: false,
    };

    return this.api.search(searchParams).then((res) => {
      const searchState = {
        StationFrom: {
          City: DepartureStation.Name,
          Code: DepartureStation.Code,
        },
        StationTo: {
          City: ArrivalStation.Name,
          Code: ArrivalStation.Code,
        },
        Travellers: requestItem.EmployeesNames.length,
        DepartureDate: departureDate,
      };

      this.searchStore.setHistoryInstance(searchState, res.Id);

      this.ticketsStore.setTicketsList({
        search: modifySearchResponse(res),
        settings,
      });
    }).catch(() => {
      this.ticketsStore.setTicketsList({
        search: {
          Id: null,
          Trains: [],
          TrainsWithTransfer: [],
        },
        settings,
      });
    });
  };

  saveAnalyticsOnAddToCart = ({ parentCar: { TypeShow, ClassService } }, placeNumber, totalPrice) => {
    const label = `${TypeShow} (${ClassService})`;

    const analyticsData = {
      label,
      value: totalPrice || null,
    };

    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.ADDTOCART, analyticsData);
  };

  saveAnalyticsOnAddToNote = ({ parentCar: { TypeShow, ClassService } }, placeNumber, totalPrice) => {
    const label = `${TypeShow} (${ClassService})`;

    const analyticsData = {
      label,
      value: totalPrice || null,
    };

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.TRAIN_SEARCH_TO_NOTE);
    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.ADDTONOTE, analyticsData);
  };

  @bound
  @withInternalMethod(o => o.netStore.withLoaderFlow('addToCartStatus'))
  async addToCart(train, car, places, totalPrice, requestItemId) {
    const { TrainId, SearchId } = train;
    const { CarNumber, ClassService, Bedding, OneCompartment, Up, Down, SpecialTariff, CompartmentNumber } = car;
    const placeNumber = [];
    const requests = [];

    const TripId = tripCacheStore.tripId;

    const req = ({ place, many }) => {
      if (place !== null) {
        placeNumber.push(place[0]);
      }

      const promise = this.api.addToCart({
        CarNumber,
        ClassService,
        Bedding,
        OneCompartment,
        Up,
        Down,
        TrainId,
        SearchId,
        CarId: car.parentCar.Id,
        RequestItemId: requestItemId,
        Many: many,
        Place: place,
        IsAlternative: car.IsAlternative,
        TripId,
        SpecialTariff,
        CompartmentNumber,
      });

      requests.push(promise);

      return promise;
    };

    const [firstPlace, ...otherPlaces] = places;

    // Разруливаем гонку на фронте, почему бы нет :chompy:
    const firstResponse = req(firstPlace);
    await firstResponse;

    otherPlaces.forEach(place => req(place));

    const responses = [firstResponse, ...await Promise.all(requests)];

    this.saveAnalyticsOnAddToCart(car, placeNumber, totalPrice);
    Amplitude.pushEvent(Amplitude.TYPE.TRAIN.ADDTOCART, responses);

    return responses;
  }

  // временное решение, все это для гонки, потом уедет на бэк
  addToCartTwo = async (train, car, places, totalPrice, requestItemId) => {
    const { TrainId, SearchId } = train;
    const { CarNumber, ClassService, Bedding, OneCompartment, Up, Down } = car;
    const placeNumber = [];
    const requests = [];

    const req = ({ place, many }) => {
      if (place !== null) {
        placeNumber.push(place[0]);
      }

      const promise = this.api.addToCart({
        CarNumber,
        ClassService,
        Bedding,
        OneCompartment,
        Up,
        Down,
        TrainId,
        SearchId,
        CarId: car.parentCar.Id,
        RequestItemId: requestItemId,
        Many: many,
        Place: place,
        IsAlternative: car.IsAlternative,
      });

      requests.push(promise);

      return promise;
    };
    places.forEach(place => req(place));

    return Promise.all(requests)
      .then((res) => {
        this.saveAnalyticsOnAddToCart(car, placeNumber, totalPrice);
        Amplitude.pushEvent(Amplitude.TYPE.TRAIN.ADDTOCART, res);

        return res;
      });
  };

  // addToSmthWithTransfer = async (fn, requestItemId) => {
  //   const { savedTickets } = this.savedTicketsStore;
  //
  //   const list = savedTickets.reduce((acc, { places, car, train }) => {
  //     const { TrainId, SearchId } = train;
  //     const { CarNumber, ClassService, Bedding, OneCompartment, Up, Down } = car;
  //
  //     const data = {
  //       CarNumber,
  //       ClassService,
  //       Bedding,
  //       OneCompartment,
  //       Up,
  //       Down,
  //       TrainId,
  //       SearchId,
  //       CarId: car.parentCar.Id,
  //       RequestItemId: requestItemId,
  //       IsAlternative: car.IsAlternative
  //     };
  //
  //     const result = places.map(({ many, place }) => ({
  //       ...data,
  //       Many: many,
  //       Place: place,
  //     }));
  //
  //     return [...acc, ...result];
  //   }, []);
  //
  //   await fn(list);
  // }
  //
  // addToCartWithTransfer = requestItemId =>
  //   this.addToSmthWithTransfer(this.api.addToCartWithTransfer, requestItemId);

  addToNote = async (train, car, places, totalPrice, TripId) => {
    const { TrainId, SearchId } = train;
    const { CarNumber, ClassService, Bedding, OneCompartment, Up, Down, SpecialTariff, CompartmentNumber } = car;
    const placeNumber = [];
    const requests = [];

    const req = ({ place, many }) => {
      if (place !== null) {
        placeNumber.push(place[0]);
      }

      const promise = this.api.addToNote({
        CarNumber,
        ClassService,
        Bedding,
        OneCompartment,
        Up,
        Down,
        TrainId,
        SearchId,
        CarId: car.parentCar.Id,
        Many: many,
        Place: place,
        TripId,
        IsAlternative: car.IsAlternative,
        SpecialTariff,
        CompartmentNumber,
      });

      requests.push(promise);

      return promise;
    };

    // Разруливаем гонку на фронте, почему бы нет :chompy:
    const firstReq = req(places[0]);
    await firstReq;

    [...places.slice(1)].forEach(place => req(place));

    return Promise.all(requests)
      .then((res) => {
        this.saveAnalyticsOnAddToNote(car, placeNumber, totalPrice);
        Amplitude.pushEvent(Amplitude.TYPE.TRAIN.ADDTONOTE, res);

        return res;
      });
  };

  addToNoteWithTransfer = requestItemId =>
    this.addToSmthWithTransfer(this.api.addToCartWithTransfer, requestItemId);

  addToFavorite = (ticket) => {
    const params = {
      ArrivalDate: ticket.ArrivalDate,
      ArrivalDateLocal: ticket.ArrivalDateLocal,
      DepartureDate: ticket.DepartureDate,
      DepartureDateLocal: ticket.DepartureDateLocal,
      ProviderName: ticket.ProviderName,
      StationFrom: ticket.StationFrom,
      StationTo: ticket.StationTo,
      CodeStationFrom: ticket.StationCodeFrom,
      CodeStationTo: ticket.StationCodeTo,
      TrainId: ticket.TrainId,
      TrainName: ticket.TrainName,
      TrainNumber: ticket.TrainNumber,
      TrainNumberLocal: ticket.TrainNumberLocal,
      TravelTime: ticket.TravelTime,
    };

    return this.api.addToFavorite(params);
  };

  getAvailability = payload => this.api.getTicketAvailability(payload);

  hasChooseUpDownSeats = car => car.Type === CARTYPE.RESERVEDSEATS || car.Type === CARTYPE.COMPARTMENT || car.Type === CARTYPE.SUITE;

  hasChooseWindowSeats = car => car.Type === CARTYPE.SEDENTARY;

  hasReservedSeats = car => car.Type === CARTYPE.RESERVEDSEATS;

  hasCompartment = car => car.Type === CARTYPE.COMPARTMENT;

  hasNotChooseSeats = car => car.Type === CARTYPE.COMMON;

  hasTwoFloors = (train, car) => {
    if (this.isCorporateTrain(train)) {
      return false;
    }

    return car.Places.filter(item => parseInt(item, 10) > 80).length > 0;
  };

  isLastochka = train => isLastochka(train);

  isSapsan = train => isSapsan(train);

  isStrij = train => isStrij(train);

  isNevskij = train => isNevskij(train);

  isCorporateTrain = train =>
    this.isLastochka(train)
    || this.isSapsan(train)
    || this.isStrij(train)
    || this.isNevskij(train);

  resetFilters = () => this.ticketsStore.resetFilters();

  mapStateToSearchObject = () => {
    const { from, to, date, dateBack, travellers } = this.searchStore;
    const savedTicket = this.getSavedTicket();

    const isBack = !!dateBack && !!savedTicket;

    this.setIsBackTrainChosing(isBack);

    const { fromRoute, toRoute, departureDate } = getRoutesDirectionsAndDate(from, to, date, dateBack, isBack);

    return this.getSearchObject(
      {
        CodeStationFrom: fromRoute.selected.Code,
        StationFrom: fromRoute.selected.Name || fromRoute.selected.City,
        CodeStationTo: toRoute.selected.Code,
        StationTo: toRoute.selected.Name || toRoute.selected.City,
      },
      {
        date: departureDate,
        travellers,
      });
  };

  mapStateToSearchObjectFromWithTransfer = () => {
    const { from, to, date, travellers } = this.searchStore;
    const { fromRoute, toRoute } = getRoutesDirections(from, to);

    this.setFromAndTo(fromRoute, toRoute);

    return this.getSearchObject(
      {
        CodeStationFrom: fromRoute.selected.Code,
        StationFrom: fromRoute.selected.Name || fromRoute.selected.City,
        CodeStationTo: toRoute.selected.Code,
        StationTo: toRoute.selected.Name || toRoute.selected.City,
      },
      {
        date,
        travellers,
      });
  };

  getSearchObject = (item, { date, travellers }) => {
    const { CodeStationFrom, StationFrom, CodeStationTo, StationTo, TrainName, TrainNumber: number } = item;

    return ({
      date: date.format(PATTERN.YEARMONTHDAY),
      sp_code: CodeStationFrom,
      sp_name: StationFrom,
      ep_code: CodeStationTo,
      ep_name: StationTo,
      name: TrainName,
      number,
      t_count: travellers,
    });
  };

  changeFavoriteStatus = (id, favoriteId) => this.ticketsStore.setFavorite(id, favoriteId);

  getSavedTicket = () => this.savedTicketStore.savedTicket;

  setSavedTicket = value => this.savedTicketStore.setSavedTicket(value);

  setIsBackTrainChosing = value => this.savedTicketStore.setIsChoosingBackTrain(value);

  setDate = value => this.searchStore.setDate(value);

  setDateBack = value => this.searchStore.setDateBack(value);

  setTravellers = value => this.searchStore.setTravellers(value);

  setFromSelected = value => this.searchStore.setFromSelected(value);

  setToSelected = value => this.searchStore.setToSelected(value);

  setFromAndTo = (from, to) => this.searchStore.setFromAndTo(from, to);

  setFirstSearch = value => this.searchStore.setFirstSearch(value);

  setPriceFilter = (values) => {
    const cacheFilterTags = this.ticketsStore.tags;

    this.ticketsStore.setPriceFilter(values);

    const sliderOldTag = cacheFilterTags.find(item => item.key === 'price');

    if (!sliderOldTag) {
      const sliderNewTag = this.ticketsStore.tags.find(item => item.key === 'price');

      if (sliderNewTag) {
        MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.FILTERSLIDER, {
          label: sliderNewTag.name,
        });
      }
    }
  };

  setTypeFilter = (value) => {
    this.ticketsStore.setTypeFilter(value);

    if (value.value) {
      MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.FILTERCHECKBOX, {
        label: value.type,
      });
    }
  };

  setFavoriteFilter = value => this.ticketsStore.setFavoriteFilter(value);

  setNumberFilter = value => this.ticketsStore.setNumberFilter(value);

  setTrainNumberFilter = value => this.ticketsStore.setTrainNumberFilter(value);

  setIsSapsanFilter = value => this.ticketsStore.setIsSapsanFilter(value);

  setTimeFilter = (type, values) => {
    const cacheFilterTags = this.ticketsStore.tags;
    this.ticketsStore.setTimeFilter(type, values);

    const sliderOldTag = cacheFilterTags.find(item => item.key === type);

    if (!sliderOldTag) {
      const sliderNewTag = this.ticketsStore.tags.find(item => item.key === type);

      if (sliderNewTag) {
        MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.FILTERSLIDER, {
          label: sliderNewTag.name,
        });
      }
    }
  };

  setTravelPolicyFilter = (value) => {
    this.ticketsStore.setTravelPolicyFilter(value);

    const { travelPolicyAllList } = this.ticketsStore.travelPolicyAllList;

    if (travelPolicyAllList) {
      const selectedTp = travelPolicyAllList.find(item => item.Id === value);

      if (selectedTp) {
        MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.FILTERSWITCH, {
          label: selectedTp.Name,
        });
      }

      if (value === TRAVELPOLICYFILTER.NOTAPPLIED) {
        MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.FILTERSWITCH, {
          label: LABELS.FILTER_TRAVEL_POLICY_NOT_APPLIED,
        });
      }
    }
  };

  setTransfersFilter = (value) => {
    this.ticketsStore.setTransfersFilter(value);

    if (value.value) {
      MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.FILTERCHECKBOX, {
        label: value.transfers,
      });
    }
  };

  setSortBy = (value) => {
    this.ticketsStore.setSortBy(value);

    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.SORTING, {
      label: MainAnalytic.LABELS.TRAINS[value],
    });
  };

  setTrainFavorite = (value) => this.trainStore.setTrainFavorite(value);

  setCacheTickets = tickets => this.savedTicketsStore.setCacheTickets(tickets);

  setSavedTickets = tickets => this.savedTicketsStore.setSavedTickets(tickets, !!this.searchStore.dateBack);

  setSavedTicketsWithoutTransfer = tickets => this.savedTicketsStore.setSavedTicketsWithoutTransfer(tickets);

  setFromSavedTicket = () => {
    const { savedTicket } = this.savedTicketStore;
    const { TrainId } = this.trainStore.train;

    const item = {
      ...savedTicket,
      TrainId,
    };

    return this.savedTicketsStore.setFromSavedTicket(item, !!this.searchStore.dateBack);
  };

  clearAfterGoBack = () => this.savedTicketsStore.clearAfterGoBack();

  clearSavedTicketsWithTransfer = () => this.savedTicketsStore.clearStore();

  removeFavoritesStatus = item =>
    item.Trains.forEach(({ TrainId }) => this.ticketsStore.setFavoritesWithTransfer(TrainId, ''));

  addFavorites = (item) => {
    const requests = [];
    const list = item.Trains.filter(({ FavoriteId }) => !FavoriteId);

    list.forEach(train => requests.push(this.addToFavorite(train)
      .then(res => ({ TrainId: train.TrainId, FavoriteId: res }))
      .catch(() => null),
    ));

    return Promise.all(requests);
  };

  // addFavoritesWithTransfer = async (item) => {
  //   const list = item.Trains.reduce((acc, {
  //     ArrivalDate,
  //     ArrivalDateLocal,
  //     DepartureDate,
  //     DepartureDateLocal,
  //     FavoriteId,
  //     ProviderName,
  //     StationFrom,
  //     StationTo,
  //     StationCodeFrom,
  //     StationCodeTo,
  //     TrainId,
  //     TrainName,
  //     TrainNumber,
  //     TrainNumberLocal,
  //     TravelTime,
  //   }) => {
  //     if (!FavoriteId) {
  //       const params = {
  //         ArrivalDate,
  //         ArrivalDateLocal,
  //         DepartureDate,
  //         DepartureDateLocal,
  //         ProviderName,
  //         StationFrom,
  //         StationTo,
  //         CodeStationFrom: StationCodeFrom,
  //         CodeStationTo: StationCodeTo,
  //         TrainId,
  //         TrainName,
  //         TrainNumber,
  //         TrainNumberLocal,
  //         TravelTime,
  //       };
  //
  //       return [...acc, params];
  //     }
  //
  //     return acc;
  //   }, []);
  //
  //   return this.api.addFavoritesWithTransfer(list)
  //     .then(() => {
  //
  //     })
  //     .catch();
  // };

  addFavoritesStatus = items =>
    items.forEach(({ TrainId, FavoriteId }) => this.ticketsStore.setFavoritesWithTransfer(TrainId, FavoriteId));
}

export default Trains;
