import React from 'react';
import { Text, Price as PriceComponent, Tooltip } from 'new-ui';
import { Link } from 'react-router-dom';

import ROUTES from '../../../../bi/constants/routes';
import { TAXI_CLASSES_TITLE } from '../../../../bi/constants/taxi';

import { formatRangeDateWithSimplicity } from '../../../../bi/utils/formatDate';

import { VoucherStoreType } from '../../../../bi/services/taxi/types';

import styles from './styles.module.css';

interface VouchersProps {
  vouchers: VoucherStoreType[],
  className?: string,
}

const Vouchers = ({ vouchers = [], className = '' }: VouchersProps) => {
  const renderClasses = (classes: number[]) =>
    classes.map(name => TAXI_CLASSES_TITLE[name].toLowerCase()).join(', ');

  const renderRow = ({
    id,
    employee: { surname, name, patronymic },
    startDate,
    endDate,
    classes,
    currentAmount,
    tripId,
    displayResult: {
      isOpenTrip,
      error,
    },
  }: VoucherStoreType) => {
    const fullName = `${surname} ${name[0]}.${patronymic ? ` ${patronymic[0]}.` : ''}`;

    const renderDate = () => (
      <Text type='NORMAL_16' color='accent'>
        { formatRangeDateWithSimplicity(startDate, endDate) }
      </Text>
    );

    const renderLink = () => {
      if (isOpenTrip) {
        return (
          <Link to={ `${ROUTES.TRIP.MAIN}/${tripId}` }>
            { renderDate() }
          </Link>
        );
      }

      return (
        <Tooltip renderContent={ () => (
          <div className={ styles['tooltip-content'] }>
            <Text color='white' type='NORMAL_14_130'>{ error }</Text>
          </div>
        ) }
        >
          { renderDate() }
        </Tooltip>
      );
    };

    return (
      <li key={ id }>
        <div className={ styles.row }>
          { fullName }, &nbsp;
          { renderLink() }
          , &nbsp;
          { renderClasses(classes) }
          &nbsp;
          <PriceComponent
            value={ currentAmount }
            type='bold_18'
            typeCurrency='NORMAL_16'
            marginSmall
          />
        </div>
      </li>
    );
  };

  const wrapperClassNames = [styles.wrapper];

  if (className) {
    wrapperClassNames.push(className);
  }

  const vouchersHtml = vouchers.map(voucher => renderRow(voucher));

  return (
    <div className={ wrapperClassNames.join('') }>
      <ul>{ vouchersHtml }</ul>
    </div>
  );
};

export { Vouchers };
