import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { UseStoresInterface, withStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';
import { UseServicesReturnType } from '../../../../bi/context/services';

import { SearchHistory } from '../../../../components/SearchHistory';
import { TrainsHistoryItem } from '../TrainsHistoryItem';

import { TrainHistoryItem } from '../../../../bi/types/train';

interface IServices extends UseServicesReturnType<'Trains'> {}
interface IStores extends Pick<UseStoresInterface, 'trainSearchStore'> {}

type TrainHistoryType = IServices & {
  onClick: (item: TrainHistoryItem) => void;
  stores?: IStores;
};

@withStores([MOBX_STORES.TRAIN_SEARCH])
@observer
class TrainHistory extends Component<TrainHistoryType> {
  componentDidMount() {
    this.props.trainsService.getHistory();
  }

  componentWillUnmount() {
    this.props.trainsService.setHistory([]);
  }
  render() {
    const { stores, onClick } = this.props;
    const historyList = stores?.trainSearchStore?.historyList;

    if (!historyList || historyList.length === 0) {
      return null;
    }

    return (
      <SearchHistory
        items={ historyList }
        renderItem={ (i: TrainHistoryItem) => <TrainsHistoryItem item={ i } /> }
        onClick={ onClick }
        qaAttrLabel={ QA_ATTRIBUTES.search.train.history.text }
        qaAttrWrapper={ QA_ATTRIBUTES.search.train.history.wrapper }
      />
    );
  }
}

export { TrainHistory };
