import { AxiosHeaders } from 'axios';

import { LOCALSTORAGEKEY } from '../api';

export const bearer = (value: string | null) => `Bearer ${value}`;

class AxiosHeadersCustom extends AxiosHeaders {
  setBearer(token: string | null) {
    if (token) this.setAuthorization(bearer(token), true);
  }
}

export const axiosHeaders = new AxiosHeadersCustom();

axiosHeaders.setBearer(window.localStorage.getItem(LOCALSTORAGEKEY.TOKEN));
