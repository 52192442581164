import React from 'react';

import { featureFlagsStore } from '../../../../bi/services/featureFlags/store';

import { TitleOfDocForSignature } from './components/TitleOfDocForSignature';

import { isSmartAgent } from '../../../../bi/utils/env';

import { KEYS } from '../../../../bi/constants/settings';
import { LABELS } from './index';

import { ItemMenu, ItemMenuLeaf } from '../../types';

const baseItemRoot = {
  title: LABELS.MENU_TITLE_ROOT,
  id: KEYS.DOCUMENTS_FOR_SIGNATURE,
};

const baseItem = {
  title: <TitleOfDocForSignature title={ LABELS.MENU_TITLE } />,
  id: KEYS.DOCUMENTS_FOR_SIGNATURE,
};

const baseOnlySES = {
  title: <TitleOfDocForSignature title={ LABELS.TOOLTIP.DOCUMENTS_FOR_SIGNATURE } />,
  id: KEYS.DOCUMENTS_FOR_SIGNATURE,
};

export const configDocumentsForSign = (nested: ItemMenuLeaf[] = []): ItemMenu[] => {
  if (!featureFlagsStore.getFeatureFlags.SimpleSignature && !isSmartAgent) {
    return [];
  }

  const isDeep = nested.length > 0;

  const items = isDeep ? [baseItem, ...nested] : [];

  return [{
    ...(isDeep ? baseItemRoot : baseOnlySES),
    items,
  }];
};
