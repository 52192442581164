import ACTION from './action';
import Store from './store';

import { ENUMS, PROJECT_HEAD, DEPARTMENT_HEAD } from '../../constants/userSession';

import { IEnumsUserSession, IProject, IUserSessionStore } from '../../types/userSession';
import { IProjects } from '../../types/employees';
import { ITravelPolicyListItem } from '../../types/travelPolicy';

class UserSession {
  api: any;
  store: any;
  xhr: any;

  constructor(api: any) {
    this.api = api.userSession;
    this.xhr = null;
    this.store = Store();
  }

  get = (): IUserSessionStore => this.store.getState();

  getEnums = (): Promise<void> => this.api.getEnums([ENUMS.BONUS, ENUMS.CITIZENSHIP, ENUMS.DOCUMENTS])
    .then((res: IEnumsUserSession) => this.store.dispatch({
      type: ACTION.GETENUMS,
      payload: res,
    }));

  getProjects = (): Promise<void> => this.api.getProjects().then((res: IProjects[]) => this.store.dispatch({
    type: ACTION.GETPROJECTS,
    payload: res,
  }));

  getTravelPolicies = (): Promise<void> => this.api.getTravelPolicies()
    .then((res: ITravelPolicyListItem[]) => this.store.dispatch({
      type: ACTION.GETTRAVELPOLICIES,
      payload: res,
    }));

  saveProject = (data: IProject): Promise<void> => this.api.saveProject(data);

  setProjectHeadSuccess = (value: boolean): void => this.store.dispatch({
    type: ACTION.PROJECT_HEAD_SUCCESS,
    payload: value,
  });

  setProjectHeadApprove = (value: boolean): void => this.store.dispatch({
    type: ACTION.PROJECT_HEAD_APPROVE,
    payload: value,
  });

  defaultProjectHead = (): void => {
    this.setProjectHeadSuccess(true);
    this.setProjectHeadApprove(true);
  };

  setDepartmentsApprove = (value: boolean): void => this.store.dispatch({
    type: ACTION.DEPARTMENTS_APPROVE,
    payload: value,
  });

  setDepartmentsSuccess = (value: boolean): void => this.store.dispatch({
    type: ACTION.DEPARTMENTS_SUCCESS,
    payload: value,
  });

  defaultDepartmentHead = (): void => {
    this.setDepartmentsApprove(true);
    this.setDepartmentsSuccess(true);
  };

  getProjectHead = (id: number): Promise<void> => this.api.getProjectHead(id)
    .then((res: any) => { // не нашла, как вызывать этот запрос и не имею понятия, что тут приходит
      const { Approve } = res;

      this.setProjectHeadApprove(Approve !== PROJECT_HEAD.APPROVE.UNAVAILABLE);

      return Promise.resolve(res);
    })
    .catch(() => {
      this.setProjectHeadSuccess(false);
      this.setProjectHeadApprove(false);

      return Promise.reject();
    });

  getDepartmentHead = (id: number): Promise<void> => this.api.getDepartmentHead(id)
    .then((res: any) => { // не нашла, как вызывать этот запрос и не имею понятия, что тут приходит
      const { Approve } = res;

      this.setDepartmentsApprove(Approve !== DEPARTMENT_HEAD.APPROVE.UNAVAILABLE);

      return Promise.resolve(res);
    })
    .catch(() => {
      this.setDepartmentsSuccess(false);
      this.setDepartmentsApprove(false);

      return Promise.reject();
    });

  subscribe = (cb: any) => this.store.subscribe(cb);
}

export default UserSession;
