import React, { ReactNode } from 'react';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

import closeSvg from './styles/images/close.svg';

interface SidePanelProps {
  show: boolean;
  onClose(): void;
  children: ReactNode;
}

interface SidePanelState {
  show: boolean;
  animate: boolean;
  closing: boolean;
}

class SidePanel extends React.Component<SidePanelProps, SidePanelState> {
  state = {
    show: false,
    animate: false,
    closing: false,
  };

  componentDidUpdate = ({ show: pShow }: SidePanelProps) => {
    const currentShowValue = this.props.show;

    if (currentShowValue === pShow) {
      return null;
    }

    if (currentShowValue) {
      return this.setState({ show: currentShowValue, closing: false, animate: true });
    }

    return this.handleClose();
  };

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({ closing: true, animate: true }, () => setTimeout(() => {
      this.setState({ show: false });
      onClose();
    }, 260));
  };

  render() {
    const { children } = this.props;
    const { show, animate, closing } = this.state;

    if (!show) {
      return null;
    }

    const wrappersClass = animate ?
      (() => (show && !closing ? styles.show : styles.hide))()
      : '';

    return (
      <div className={ `${styles.wrapper} ${wrappersClass}` } onClick={ this.handleClose }>
        <div className={ `${styles.content} ${wrappersClass}` } onClick={ e => e.stopPropagation() }>
          <div data-qa={ QA_ATTRIBUTES.sidePanel.close } className={ styles.close_btn } onClick={ this.handleClose }>
            <img src={ closeSvg } alt='' />
          </div>
          <div className={ styles.children_container }>
            { children }
          </div>
        </div>
      </div>
    );
  }
}

export default SidePanel;
