import { observable, action, computed, makeObservable } from 'mobx';

import type { BaseServiceTypeTravelPolicyStore } from '../../types';
import type { TravelPolicyVipHallRule } from './types';

import { TRAVEL_POLICY_STORE_FIELDS } from '../../consts';

const DEFAULT_STORE = {
  Apply: false,
  AllowBook: {
    IsEnabled: false,
  },
};

class VipHallTravelPolicyStore implements BaseServiceTypeTravelPolicyStore {
  constructor() {
    makeObservable(this);
  }

  @observable store: TravelPolicyVipHallRule = DEFAULT_STORE;

  @computed
  get preparedToSaveModel(): TravelPolicyVipHallRule {
    const { ...rest } = this.store;

    return {
      ...rest,
      [`${TRAVEL_POLICY_STORE_FIELDS.ALLOW_BOOK}`]: this.store.AllowBook.IsEnabled,
    };
  }

  @action
  mapServerModelToClient = (model: TravelPolicyVipHallRule) => {
    const { ...rest } = model;

    this.store = {
      ...this.store,
      ...rest,
    };
  };

  @action
  updateStore = (payload: any) => {
    this.store = {
      ...this.store,
      ...payload,
    };
  };

  @action
  clearStore = () => {
    this.store = DEFAULT_STORE;
  };
}

export default VipHallTravelPolicyStore;
