import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Text, Button, Price, TableWithSort, Paginate, Icon } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import EmployeePaymentService from '../../../../bi/services/employeePayment';
import CloudPaymentService from '../../../../bi/services/cloudPayment';
import { PersonalPaymentProtocol } from '../../../../bi/protocols/personalPayment';

import { PaymentWidget } from '../../../../components/PaymentWidget';

import { formatDate, getLocaleTimeFromUtc } from '../../../../bi/utils/formatDate';

import { PATTERN } from '../../../../bi/constants/dateFormats';
import ROUTES from '../../../../bi/constants/routes';

import styles from './index.module.css';

const LABEL = {
  EMPLOYEE_PERSONAL_ACCOUNT: getText('constants:settings.employeePersonalAccount'),
  AVAILABLE_BALANCE: getText('constants:settings.availableBalance'),
  TOP_UP_CARD: getText('constants:settings.topUpCard'),
  RETURN_TO_CARD: getText('constants:settings.returnMoneyToCard'),
  USER_TRANSACTIONS: getText('constants:settings.userTransactions'),
  INCOMING_TRANSACTION: getText('constants:settings.incoming'),
  OUTGOING_TRANSACTION: getText('constants:settings.outgoing'),
  TABLE_HEADERS: {
    SERVICE: getText('constants:settings.transactionsHistoryTable.service'),
    TRANSACTION_TYPE: getText('constants:settings.transactionsHistoryTable.transactionType'),
    AMOUNT: getText('constants:settings.transactionsHistoryTable.amount'),
    DATE: getText('constants:settings.transactionsHistoryTable.date'),
    FEE: getText('constants:settings.transactionsHistoryTable.fee'),
  },
  NOTHING_FOUND: getText('constants:settings.transactions_not_found'),
};

const COLUMNS_LABELS = [
  { label: LABEL.TABLE_HEADERS.SERVICE },
  { label: LABEL.TABLE_HEADERS.TRANSACTION_TYPE },
  { label: LABEL.TABLE_HEADERS.AMOUNT, hasSort: true, value: 'Amount' },
  { label: LABEL.TABLE_HEADERS.FEE },
  { label: LABEL.TABLE_HEADERS.DATE, hasSort: true, value: 'Date' },
];

const ITEM_PER_PAGE = 6;

interface IEmployeePersonalAccount {
  employeePaymentService: EmployeePaymentService,
  cloudPaymentService: CloudPaymentService,
  personalPaymentProtocol: PersonalPaymentProtocol,
}

const EmployeePersonalAccount = observer(({
  employeePaymentService,
  cloudPaymentService,
  personalPaymentProtocol,
}: IEmployeePersonalAccount) => {
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenWidget, setIsOpenWidget] = useState(false);
  const {
    employeePaymentStore: {
      employeeTransactionsList: {
        transactions,
        metadata: { totalCount },
      },
      personalBalance,
    } } = useStores([MOBX_STORES.EMPLOYEE_PAYMENT]);

  useEffect(() => {
    employeePaymentService.getPersonalBalance();
  }, []);

  useEffect(() => {
    employeePaymentService.getEmployeePersonalTransactions({
      Limit: ITEM_PER_PAGE,
      Offset: ITEM_PER_PAGE * (currentPage - 1),
      SortBy: sortBy,
      Order: sortOrder,
    });
  }, [sortBy, sortOrder, currentPage]);

  const handleClickSort = (order: string, sortByValue: string) => {
    setSortBy(sortByValue);
    setSortOrder(order);
  };

  const handleChangePage = (page: number) => setCurrentPage(page);

  const handleCloseWidget = () => {
    setIsOpenWidget(false);
  };

  const renderPersonalFunds = () => (
    <div className={ styles.personal_funds_container }>
      <div className={ styles.column }>
        <Text type='bold_20'> { LABEL.AVAILABLE_BALANCE } </Text>
        <Price
          value={ personalBalance }
          type='bold_16'
        />
        <Button onClick={ () => {
          setIsOpenWidget(true);
        } }
        >
          {LABEL.TOP_UP_CARD}
        </Button>
      </div>
      <div className={ styles.column }>
        <Text type='NORMAL_14_130' color='blue' className={ styles.link }> { LABEL.RETURN_TO_CARD } </Text>
      </div>
    </div>
  );

  const renderTransactionsLabel = () => <Text type='bold_20'> { LABEL.USER_TRANSACTIONS} </Text>;

  const renderNothingFound = () => (
    <div className={ styles.no_result }>
      <Icon type='notFoundBig' />
      <Text type='NORMAL_16_130'>
        { LABEL.NOTHING_FOUND }
      </Text>
    </div>
  );

  const renderTableRows = () => transactions
    .map(({ amount, description, eventDate, fee, type, id, tripId }) => {
      const transactionType = type === 'incoming' ? LABEL.INCOMING_TRANSACTION : LABEL.OUTGOING_TRANSACTION;
      const formattedDate = formatDate(eventDate, PATTERN.SHORT_FULL_YEAR);
      const formattedTime = getLocaleTimeFromUtc(eventDate);
      const transactionAmountColor = type === 'incoming' ? 'green' : 'red';
      const renderDescription = tripId ?
        <td>
          <Link to={ ROUTES.ADDITIONAL_SERVICES.TRIP(tripId) }>
            {description}
          </Link>
        </td> :
        <td className={ styles.text }>
          {description}
        </td>;

      return (
        <tr key={ id }>
          <td>{renderDescription}</td>
          <td>{transactionType}</td>
          <td><Price value={ amount } type='NORMAL_14' marginSmall color={ transactionAmountColor }/></td>
          <td><Price value={ fee } type='NORMAL_14' marginSmall /></td>
          <td>{ `${formattedTime}, ${formattedDate}` }</td>
        </tr>
      );
    });

  const renderTableContent = transactions.length ? renderTableRows() : renderNothingFound();

  return (
    <div className={ styles.wrapper }>
      <PaymentWidget
        isOpen={ isOpenWidget }
        cloudPaymentService={ cloudPaymentService }
        onCancel={ handleCloseWidget }
        onConfirm={ handleCloseWidget }
        onChangeDialog={ handleCloseWidget }
        personalPaymentProtocol={ personalPaymentProtocol }
      />
      <Text type='bold_24'> {LABEL.EMPLOYEE_PERSONAL_ACCOUNT} </Text>
      {renderPersonalFunds()}
      {renderTransactionsLabel()}
      <div className={ styles.table }>
        <TableWithSort labelsHeader={ COLUMNS_LABELS } handleClick={ handleClickSort }>
          { renderTableContent }
        </TableWithSort>
        <Paginate page={ currentPage } total={ totalCount } itemsPerPage={ ITEM_PER_PAGE } onChange={ handleChangePage } />
      </div>
    </div>
  );
});

export { EmployeePersonalAccount };
