import ACTION from './action';
import Store from '../../store';
import { IAccount } from './types';

const reducer = (action: { [key: string]: any, type: string }, state: IAccount) => {
  switch (action.type) {
    case ACTION.GETACCOUNT: {
      return { ...action.payload };
    }
  }

  return state;
};

const createStore = () => new (Store as any)(reducer, {});

export default createStore;
