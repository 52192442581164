import React from 'react';
import { RadioButton, Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import { isSmartAgent } from '../../../../bi/utils/env';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { TRAVEL_POLICY_FIELDS_DICTIONARIES } from '../../../../bi/services/travelPolicy/consts';

import styles from './index.module.css';

type DirectionTypesProps = {
  type: string,
  value: number,
  onChange: (val: number) => void,
};

const LABELS = {
  RUSSIA_TICKETS: getText('settings:travelPolicy.rules.directions.onlyRussiaTickets'),
  RUSSIA_HOTELS: getText('settings:travelPolicy.rules.directions.onlyRussiaHotels'),
  DIRECTION_TICKETS: getText('settings:travelPolicy.rules.directions.onlyDirectionsTickets'),
  DIRECTION_HOTELS: getText('settings:travelPolicy.rules.directions.onlyDirectionsHotels'),
};

const prepareRussiaLabel = (type: string) => {
  switch (type) {
    case SERVICETYPE.AIR:
    case SERVICETYPE.TRAIN:
      return LABELS.RUSSIA_TICKETS;
    default:
      return LABELS.RUSSIA_HOTELS;
  }
};

const prepareDirectionLabel = (type: string) => {
  switch (type) {
    case SERVICETYPE.AIR:
    case SERVICETYPE.TRAIN:
      return LABELS.DIRECTION_TICKETS;
    default:
      return LABELS.DIRECTION_HOTELS;
  }
};

const DirectionTypes = ({
  type,
  value,
  onChange,
}: DirectionTypesProps) => (
  <>
    <RadioButton
      alternativeDesign={ isSmartAgent }
      checked={ value === TRAVEL_POLICY_FIELDS_DICTIONARIES.DIRECTION_TYPES.RUSSIA }
      onChange={ () => onChange(TRAVEL_POLICY_FIELDS_DICTIONARIES.DIRECTION_TYPES.RUSSIA) }
      className={ styles.checkbox }
    >
      <Text>{ prepareRussiaLabel(type) }</Text>
    </RadioButton>
    <RadioButton
      alternativeDesign={ isSmartAgent }
      onChange={ () => onChange(TRAVEL_POLICY_FIELDS_DICTIONARIES.DIRECTION_TYPES.CHOSEN) }
      checked={ value === TRAVEL_POLICY_FIELDS_DICTIONARIES.DIRECTION_TYPES.CHOSEN }
      className={ styles.checkbox }
    >
      <Text>{ prepareDirectionLabel(type) }</Text>
    </RadioButton>
  </>
);

export { DirectionTypes };
