import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { getText } from '../../../i18n';

import { SearchLoading } from '../../components/SearchLoading';
import { ChangeAirTripSearchMenu } from '../../components/ChangeAirTripSearchMenu';

import { conditionFlightClass } from '../../bi/utils/changeAirTrip';

import ROUTES from '../../bi/constants/routes';
import { PATH } from '../../bi/constants/changeAirTrip';
import { FIELDS_SEARCH_MENU } from '../../bi/constants/airline';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { AirlineJsonData, AirlineService, AirlineMatch } from '../../bi/types/airline';
import { WorkspaceService } from '../../bi/types/workspace';
import { UserSessionService } from '../../bi/types/userSession';
import { OrderService, IOrderDetailsData } from '../../bi/types/order';
import { BreadCrumbsService } from '../../bi/types/breadCrumbs';

import styles from './styles/index.module.css';

const LABELS = {
  SEARCH: getText('air:result.loader'),
};

const {
  changeTrip: {
    air: {
      byDateDirectionSearch: {
        searchLoading: searchLoadingTest,
      },
    },
  },
} = QA_ATTRIBUTES;

interface ChangeAirTripDateOrDirectionPageProps {
  airlineService: AirlineService,
  workspaceService: WorkspaceService,
  userSessionService: UserSessionService,
  orderService: OrderService,
  breadCrumbsService: BreadCrumbsService,
  match: AirlineMatch,
  history: RouteComponentProps['history'],
}

const ChangeAirTripDateOrDirectionPage = ({
  airlineService,
  workspaceService,
  userSessionService,
  orderService,
  breadCrumbsService,
  match: {
    params: { tripId, ticketId },
    path,
  },
  history,
}: ChangeAirTripDateOrDirectionPageProps) => {
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [detailsData, setDetailsData] = useState<IOrderDetailsData | null>(null);

  const tripItem = detailsData && detailsData.OrderItems.find((ticket) => ticket.Id === +ticketId);
  const ticketData: AirlineJsonData = tripItem && JSON.parse(tripItem.ActualVersion.JsonData);

  useEffect(() => {
    breadCrumbsService.setCurrentPath(path);
    const { details } = orderService.get();

    setDetailsData(details);

    return () => {
      breadCrumbsService.setCurrentPath(PATH.MAIN);
    };
  }, []);

  const handleAddFlightClass = () => {
    const resultFlightClass = conditionFlightClass(ticketData.Class);

    airlineService.setSearchValue(FIELDS_SEARCH_MENU.FLIGHT_CLASS, resultFlightClass);
  };

  const handleMoveToResult = (searchId: string) => {
    setLoadingSearch(false);

    if (path === PATH.DATE) {
      return history.push({
        pathname: ROUTES.CHANGE_AIR_TRIP.DATE_RESULT(tripId, ticketId, searchId),
      });
    }

    return history.push({
      pathname: ROUTES.CHANGE_AIR_TRIP.DIRECTION_RESULT(tripId, ticketId, searchId),
    });
  };

  const handleSearch = async () => {
    setLoadingSearch(true);
    handleAddFlightClass();

    const rightsBuyTrip = workspaceService.rights;
    const accountTravelPolicy = workspaceService.travelPolicy;
    const travelPolicyAllList = userSessionService.get().travelPolicies;
    const airlineCode = ticketData.Routes[0].Segments[0].MarketingAirline.Code;
    const fareName = ticketData.Fare?.Name;

    const settings = {
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    };

    const searchId = await airlineService.searchAirTripChange(airlineCode, settings, fareName);

    if (searchId) {
      airlineService.setDefaultSortValue();
      handleMoveToResult(searchId);
    }
  };

  if (loadingSearch) {
    return (
      <>
        <div className={ styles.search_wrapper }>
          <SearchLoading qaAttrLoadingText={ searchLoadingTest } text={ LABELS.SEARCH } />;
        </div>
      </>
    );
  }

  return (ticketData &&
    <div className={ styles.animation }>
      <ChangeAirTripSearchMenu
        airlineService={ airlineService }
        history={ history }
        path={ path }
        ticketData={ ticketData }
        onSearch={ handleSearch }
      />
    </div>
  );
};

export { ChangeAirTripDateOrDirectionPage };
