import React from 'react';
import { observer } from 'mobx-react';

import ChartsAnalytics from '../../../../../bi/services/chartsAnalytics';

import ROUTES from '../../../../../bi/constants/routes';

import { MicePageSubSections } from '../../common/smartIndex/components/micePageSubSection';

const SmartMice = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => (
  <MicePageSubSections
    chartsAnalyticsService={ chartsAnalyticsService }
    path={ ROUTES.CHARTS_ANALYTICS.SMART.MICE.SECOND }
  />
));

export { SmartMice };
