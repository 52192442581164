import { AirlineSource, ITemperatureScale } from '../types/airline';

interface AirlineType {
  ID: string,
  Name: string,
}

interface AirportType {
  ID: string,
  Name: string,
}

interface SegmentType {
  DepartureCountry: string,
  DepartureCity: string,
  DepartureCityCode?: string,
  ArrivalCityCode?: string,
  ArrivalCountry: string,
  ArrivalCity: string,
  OperatingAirline: AirlineType,
  MarketingAirline: AirlineType,
  DepartureAirport: AirportType,
  ArrivalAirport: AirportType,
  AircraftName: string,
  FlightNumber: string,
  AvailableSeats: number,
  ChangeDuration: number,
  DepartureTime: number,
  ArrivalTime: number,
  ArrivalTerminal: string,
  DepartureTerminal: string,
  TechnicalStop: any[],
}

interface RouteType {
  Duration: number,
  Segments: SegmentType[],
}

export interface FaresType {
  Id: string,
  Name: string,
  IsDefault: boolean,
  Price: {
    Base: number,
    Tax: number,
    Taxes: number,
    Fee: number,
    Commission: number,
    VAT: number,
    TotalPrice: number
  },
  IsCarryonIncluded: string,
  CarryonPlaces: string,
  IsBaggageIncluded: string,
  BaggageIncludedWithCondition: any,
  BaggagePlaces: string,
  CanBuyBaggage: string,
  IsTicketRefundable: string,
  IsTicketRefundableAfterDeparture: string,
  RefundTicketWithCondition: any,
  IsTicketChangeable: string,
  IsTicketChangeableAfterDeparture: string,
  ChangeTicketWithCondition: any,
  CanRegistrationSeat: string,
  SeatDescription: null,
  IsBusinessHallIncluded: string,
  HaveInternetAccess: string,
  IsPriorityRegistration: string,
  IsPriorityBaggageReception: string,
  AirlineBonusInformation: string,
  CanUpgradeRate: string,
  AdditionalServices: any,
  TravelPolicy: {
    Apply: boolean,
    Errors: any
  },
  Bonus: number
}

interface IPrice {
  Base: number,
  Tax: number,
  Taxes: number,
  Fee: number,
  Commission: number,
  VAT: number,
  TotalPrice: number,
  AgentFee?: number
}

export interface TripType {
  Id: number,
  Price: IPrice,
  Routes: RouteType[],
  Duration: number,
  Fares: FaresType[],
  FavoriteId: string,
  TravelPolicy: { Apply: boolean, Errors: any },
  LastTripDate: string,
  AFlag: boolean,
  Metadata?: string,
  OriginalPrice?: IPrice,
  ProviderName?: string,
  SearchType?: number,
}

type TripTag = 'Cheapest' | 'Fastest' | 'Recommended';

export interface MappedTripType extends TripType {
  OriginalTrips: TripType[],
}

interface MappedReponseType {
  SearchId: string,
  Trips: MappedItinerariesToTrips[],
}

interface ItineraryType {
  CustomFares: any[],
  Trips: TripType[],
  Tags: TripTag[];
}

interface SearchOptionsRouteType {
  DepartureCode: string,
  DepartureName: string,
  ArrivalCode: string,
  ArrivalName: string,
  Date: string,
}

interface SearchOptionsType {
  Routes: SearchOptionsRouteType[],
  Class: 'Econom' | 'Business' | 'First',
  IsDirect: boolean,
  TravellersCount: number,
  FromHistory: boolean,
}

export interface ResponseType {
  SearchId: string;
  Itineraries: ItineraryType[];
  SearchOptions: SearchOptionsType;
  TemperatureScale: ITemperatureScale;
}

const mapPrice = (trips: TripType[]): any =>
  trips.reduce(
    (
      acc,
      { Price: { Base, Commission, Fee, Tax, Taxes, VAT, TotalPrice } },
    ) => ({
      Base: acc.Base + Base,
      Commission: acc.Commission + Commission,
      Fee: acc.Fee + Fee,
      Tax: acc.Tax + Tax,
      Taxes: acc.Taxes + Taxes,
      TotalPrice: acc.TotalPrice + TotalPrice,
      VAT: acc.VAT + VAT,
    }),
    {
      Base: 0,
      Commission: 0,
      Fee: 0,
      Tax: 0,
      Taxes: 0,
      TotalPrice: 0,
      VAT: 0,
    },
  );

const groupRoutes = (
  routes: RouteType[],
  searchRoutes: SearchOptionsRouteType[],
): RouteType[][] => {
  const result: RouteType[][] = [];

  let currentSearchRouteIndex = 0;
  let currentGroup: any[] = [];

  routes.forEach((route) => {
    currentGroup.push(route);

    const lastSegment = route.Segments[route.Segments.length - 1];
    const {
      ArrivalCityCode,
      ArrivalAirport: { ID: arrivalAirportId },
    } = lastSegment;
    const { ArrivalCode: arrivalCode } = searchRoutes[currentSearchRouteIndex];

    // Проверка совпадения конечной точки маршрута
    if (arrivalCode === ArrivalCityCode || arrivalCode === arrivalAirportId) {
      result.push(currentGroup);

      currentGroup = [];
      currentSearchRouteIndex++;
    }
  });

  // Добавление последней группы маршрутов, если она не пуста
  if (currentGroup.length) {
    result.push(currentGroup);
  }

  return result;
};

const getChangeDuration = (
  currentSegments: SegmentType[],
  nextSegments: SegmentType[],
): number => {
  const lastOfCurrentSegments = currentSegments[currentSegments.length - 1];
  const firstOfNextSegments = nextSegments[0];

  return firstOfNextSegments.DepartureTime - lastOfCurrentSegments.ArrivalTime;
};

const processSegments = (
  segments: SegmentType[] = [],
  changeDuration = 0,
): SegmentType[] => {
  if (changeDuration === 0) {
    return segments;
  }

  const [lastSegment, ...restSegments] = [...segments].reverse();

  return [
    ...restSegments.reverse(),
    {
      ...lastSegment,
      ChangeDuration: changeDuration,
    },
  ];
};

const mapTravelPolicy = (trips: TripType[]) => trips.reduce((acc, { TravelPolicy: { Apply = false, Errors = {} } }) => ({
  Apply: acc.Apply || Apply,
  Errors: {
    ...acc.Errors,
    ...Errors,
  },
}), { Apply: false, Errors: {} });

const mapRouteGroupsToRoutes = (routeGroups: RouteType[][] = []): RouteType[] =>
  routeGroups.map((group: RouteType[]) =>
    group.reduce(
      (acc: RouteType, { Segments, Duration }: RouteType, index) => {
        const isLastRoute = index === group.length - 1;
        const changeDuration = isLastRoute
          ? 0
          : getChangeDuration(Segments, group[index + 1].Segments);

        return {
          Duration: acc.Duration + Duration + changeDuration,
          Segments: [
            ...acc.Segments,
            ...processSegments(Segments, changeDuration),
          ],
        };
      },
      {
        Duration: 0,
        Segments: [],
      },
    ),
  );

const mapTripsToRoutes = (
  trips: TripType[],
  searchOptions: SearchOptionsType,
): RouteType[] => {
  const { Routes: searchRoutes } = searchOptions;
  const routes = trips.flatMap(({ Routes }) => Routes);

  const groupedRoutes = groupRoutes(routes, searchRoutes);

  return mapRouteGroupsToRoutes(groupedRoutes);
};

interface MappedItinerariesToTrips {
  OriginalTrips: TripType[],
  AvailablCustomFares: boolean,
}

const mapItinerariesToTrips = (
  itineraries: ItineraryType[],
  searchOptions: SearchOptionsType,
): MappedItinerariesToTrips[] =>
  itineraries.map(({ CustomFares, Trips, Tags }) => {
    const firstTrip = Trips[0];
    const hasNoManualJoins = !CustomFares || !CustomFares.length;

    if (hasNoManualJoins) {
      return {
        ...firstTrip,
        Tags,
        OriginalTrips: [firstTrip],
        AvailablCustomFares: false,
      };
    }

    const favoriteId = Trips.some(({ FavoriteId }) => !!FavoriteId)
      ? 'favoriteId'
      : null;

    const routes = mapTripsToRoutes(Trips, searchOptions);
    const duration = routes.reduce((acc, { Duration }) => Duration + acc, 0);

    return {
      ...firstTrip,
      TravelPolicy: mapTravelPolicy(Trips),
      Duration: duration,
      Price: mapPrice(Trips),
      Routes: routes,
      Fares: CustomFares,
      FavoriteId: favoriteId,
      OriginalTrips: Trips,
      AvailablCustomFares: true,
      Tags,
    };
  });

const mapResponse = ({
  SearchId,
  Itineraries,
  SearchOptions,
}: ResponseType): MappedReponseType => ({
  SearchId,
  Trips: mapItinerariesToTrips(Itineraries, SearchOptions),
});

const extractOriginalData = (
  { OriginalTrips }: AirlineSource,
  currentFareId: string,
): Array<{
  tripId: number,
  fareId?: string | null,
}> => {
  const Ids = currentFareId ? currentFareId.split(';') : [null];

  return OriginalTrips.map(({ Id: tripId }, ind: number) => ({
    tripId,
    fareId: Ids[ind],
  }));
};

const temperaturePriceWidth = (currentTickets: number, allTickets: number) => (currentTickets * 100) / allTickets;

export { mapResponse, extractOriginalData, temperaturePriceWidth };
export type { TripTag };
