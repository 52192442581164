import React, { Component } from 'react';
import { Button, Dialog, MultiSelect, Tooltip, Text, Datepicker } from 'new-ui';
import { Moment } from 'moment';

import { getText, isRuLng } from '../../../../../i18n';

import { FIELDS_DATE } from '../../../../bi/constants/dateFormats';

import {
  momentObject,
  getEarlyMonthFromCurrent,
  endOfPeriod, getMoment,
} from '../../../../bi/utils/formatDate';

import styles from './styles/index.module.css';

const LABELS = {
  COMPANIES: getText('approve:reportDialog.companies'),
  COMPANIES_WITH_DEPARTMENTS: getText('approve:reportDialog.companiesWithDepartments'),
  DEPARTMENTS: getText('approve:reportDialog.departments'),
  DOWNLOAD: getText('approve:download'),
  CANCEL: getText('common:undo'),
  TITLE: getText('approve:reportDialog.title'),
  SELECT_DATES: getText('approve:reportDialog.selectDates'),
  FULL_INFO: getText('approve:reportDialog.fullInfo'),
  TOOLTIP_TEXT: getText('approve:tooltip'),
};

const DATA_START = '2020.05.01';

interface Company {
  value: string;
  label: string;
  nested: Array<{ value: string; label: string }>;
}

interface SelectedItem {
  main: string;
  nested: string[];
}

interface MultiSelectList {
  value: string;
  label: string;
}

interface NestedItem {
  value: number | null;
  label: string;
}

interface ReportApproveDialogProps {
  onDownload: (startDate: Moment, endDate: Moment, selected: SelectedItem[]) => void;
  onClose: (download: boolean) => void;
  companies: any;
  show: boolean;
  loading: boolean;
}

interface ReportApproveDialogState {
  startDate: Moment;
  endDate: Moment;
  selected: SelectedItem[];
  disabledButton: boolean;
}

const prepareSelectedItems =
(companies: Company[]) =>
  companies.map(company => ({
    main: company.value,
    nested: company.nested.map(nest => nest.value),
  }));

class ReportApproveDialog extends Component<ReportApproveDialogProps, ReportApproveDialogState> {
  constructor(props: ReportApproveDialogProps) {
    super(props);
    const date = getMoment();
    const { companies } = this.props;
    const selectedItems = prepareSelectedItems(companies);

    const startDate = getEarlyMonthFromCurrent(date);
    const endDate = endOfPeriod(date, 'month');

    this.state = {
      startDate,
      endDate,
      selected: selectedItems,
      disabledButton: false,
    };
  }

  componentDidUpdate({ companies: prevCompanies }: { companies: Company[] }) {
    const { companies } = this.props;

    if (companies !== prevCompanies) {
      const selected = prepareSelectedItems(companies);

      this.setState({
        ...this.state,
        selected,
      });
    }
  }

  handleChangeSelect = (values: SelectedItem[]) => this.setState({ selected: values });

  handleChangeDate = (field: string, value: string | Moment | null) => {
    this.setState({ [field]: value } as unknown as Pick<ReportApproveDialogState, keyof ReportApproveDialogState>);
  };

  renderCompaniesSelect = () => {
    const { selected } = this.state;
    const { companies } = this.props;

    const hasDepartments = companies.some(({ nested }: { nested: NestedItem[] }) => nested && nested.length);
    const selectName = hasDepartments ? LABELS.COMPANIES_WITH_DEPARTMENTS : LABELS.COMPANIES;

    if (companies.length === 1 && hasDepartments) {
      const companyId = companies[0].value;
      const values = selected[0] ? selected[0].nested : [];

      return (
        <div className={ styles['company-select'] }>
          <Text className={ styles.title }>{ LABELS.DEPARTMENTS }</Text>
          <MultiSelect
            list={ companies.reduce((r: any, { nested }: { nested: NestedItem[] }) => ([...r, ...nested]), [] as MultiSelectList[]) }
            values={ values }
            onChange={ (nested: string[]) => this.handleChangeSelect([{ main: companyId, nested }]) }
          />
        </div>
      );
    }

    if (companies.length > 1) {
      return (
        <div className={ styles['company-select'] }>
          <Text className={ styles.title }>{ selectName }</Text>
          <MultiSelect
            values={ selected }
            list={ companies }
            onChange={ (values: SelectedItem[]) => this.handleChangeSelect(values) }
          />
        </div>
      );
    }

    return null;
  };

  render() {
    const { onClose, onDownload, show, loading } = this.props;
    const { startDate, endDate, selected, disabledButton } = this.state;

    const minDate = momentObject(DATA_START);

    return (
      <>
        <Tooltip
          position='bottom'
          renderContent={ () => (
            <div className={ styles['tooltip-content'] }>
              <Text
                className={ styles.text }
                type='NORMAL_14_130'
                color='white'
              >
                { LABELS.TOOLTIP_TEXT }
              </Text>
            </div>
          ) }
        >
          <Button
            type='textual'
            onClick={ () => onClose(true) }
          >
            { LABELS.DOWNLOAD }
          </Button>
        </Tooltip>
        <Dialog
          showClosing
          show={ show }
          onChange={ onClose }
        >
          <div className={ styles.wrapper }>
            <Text type='bold_20' className={ styles.header }>{LABELS.TITLE}</Text>
            <Text className={ styles['date-title'] }>{LABELS.SELECT_DATES}</Text>
            <div className={ styles.dates }>
              <Datepicker
                value={ startDate }
                inputClassName={ styles.input_wrap }
                inputTheme='open'
                onChange={ v => this.handleChangeDate(FIELDS_DATE.START_DATE, v) }
                isDuration
                durationDates={ [startDate, endDate] }
                modalClassName={ styles.wrapper }
              />
              &mdash;
              <Datepicker
                value={ endDate }
                min={ minDate }
                inputClassName={ styles.input_wrap }
                inputTheme='open'
                onChange={ v => this.handleChangeDate(FIELDS_DATE.END_DATE, v) }
                isDuration
                durationDates={ [startDate, endDate] }
                modalClassName={ styles.wrapper }
              />
            </div>
            { this.renderCompaniesSelect() }
            <div className={ styles.buttons }>
              <Tooltip
                show={ disabledButton && isRuLng() }
                renderContent={ () => (
                  <div className={ styles['tooltip-content'] }>
                    <Text color='white' type='NORMAL_14_130'>
                      { LABELS.FULL_INFO }
                    </Text>
                  </div>
                ) }
              >
                <Button
                  onClick={ () => onDownload(startDate, endDate, selected) }
                  disabled={ disabledButton }
                  loading={ loading }
                >
                  { LABELS.DOWNLOAD }
                </Button>
              </Tooltip>
              <Button
                type='textual-medium'
                onClick={ () => onClose(true) }
                className={ styles.cancel }
              >
                { LABELS.CANCEL }
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default ReportApproveDialog;
