import React from 'react';
import { observer } from 'mobx-react';
import { Dialog, Text, Button } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import { MainAnalytic } from '../../../../../bi/utils/analytics';

import styles from '../index.module.css';

const LABELS = {
  CHANGE_NUMBER: getText('settings:employees.employee.steps.signature.codeDialog.changeNumber'),
  CONFIRM_TEXT: getText('settings:employees.employee.steps.signature.codeDialog.confirm'),
  CONFIRM_SUB_TEXT: getText('settings:employees.employee.steps.signature.codeDialog.sub'),
  SEND_SMS_CODE: getText('settings:employees.employee.steps.signature.codeDialog.sendSmsCode'),
  OK: getText('settings:employees.employee.steps.signature.codeDialog.ok'),
};

interface IConfirmationDialog {
  show: boolean,
  onClose(): void,
  onChangeNumber(): void,
  onSendCode(value: boolean): void,
}

const ConfirmationDialog = observer(({
  show,
  onClose,
  onSendCode,
  onChangeNumber,
}: IConfirmationDialog) => {
  const { simpleSignatureStore } = useStores([MOBX_STORES.SIMPLE_SIGNATURE_STORE]);

  const {
    codeResponse,
  } = simpleSignatureStore;

  const handleCloseDialog = () => {
    onClose();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.SIGNATURE_CREATED_BUTTON_PRESSED);
  };

  const renderActions = () => (
    <div className={ styles.buttons_confirmation }>
      <Button
        className={ styles.confirmation }
        onClick={ () => onSendCode(false) }
        loading={ false }
        type='secondary'
      >
        { LABELS.SEND_SMS_CODE }
      </Button>
      <Button
        onClick={ onChangeNumber }
        type='textual'
      >
        { LABELS.CHANGE_NUMBER }
      </Button>
    </div>
  );

  const errorHtml = (
    <div className={ styles.confirmation_dialog }>
      <Text
        className={ styles.header }
        type='NORMAL_14_130'
      >
        { codeResponse }
      </Text>
      { renderActions() }
    </div>
  );

  const confirmHtml = (
    <div className={ styles.confirmation_dialog }>
      <Text
        className={ styles.header }
        type='bold_16'
      >
        { LABELS.CONFIRM_TEXT }
      </Text>
      <Text
        className={ styles.header }
        type='NORMAL_14'
      >
        { LABELS.CONFIRM_SUB_TEXT }
      </Text>
      <Button
        className={ styles.confirmation }
        onClick={ handleCloseDialog }
        loading={ false }
        type='secondary'
      >
        { LABELS.OK }
      </Button>
    </div>
  );

  const html = codeResponse ? errorHtml : confirmHtml;

  return (
    <Dialog
      showClosing
      show={ show }
      onChange={ handleCloseDialog }
    >
      { html }
    </Dialog>
  );
});

export { ConfirmationDialog };
