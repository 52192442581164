import React from 'react';

import { Text, Collapse } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { formatDate } from '../../../../../bi/utils/formatDate';

import { DATEFORMATS } from '../../../../../bi/constants/dateFormats';
import { BRAND_NAMES } from '../../../../../bi/services/settings/stores/settings';

import styles from '../index.module.css';

const LABELS = {
  SHORT_NAME: getText('settings:companies.shortName'),
  CONTRACT_NUMBER: getText('settings:companies.contractNumber'),
  CONTRACT_DATE: getText('settings:companies.contractDate'),
  INN: getText('settings:companies.inn'),
  KPP: getText('settings:companies.kpp'),
  LEGAL_ADDRESS: getText('settings:companies.legalAddress'),
  ACTUAL_ADDRESS: getText('settings:companies.actualAddress'),
  CEO: getText('settings:companies.ceo'),
  COLLAPSE: getText('settings:companies.collapse'),
  EXPAND: getText('settings:companies.expand'),
  IIN_BIN: getText('settings:companies.iinBin'),
};

interface IRenderCompany {
  company: {
    CompanyId: string,
    CompanyName: string,
    ShortCompanyName: string,
    Contract: {
      Number: string,
      StartDate: Date,
    }
    INN: string,
    KPP: string,
    IINBIN: string,
    LegalAddress: string,
    ActualAddress: string,
    CEO: string,
  },
  isMulti: boolean;
  brandName: string,
}

const RenderCompany = ({
  company: {
    CompanyId,
    CompanyName,
    ShortCompanyName,
    Contract,
    INN,
    KPP,
    LegalAddress,
    ActualAddress,
    CEO,
    IINBIN,
  },
  isMulti,
  brandName,
}: IRenderCompany) => {
  const companyName = ShortCompanyName || CompanyName;

  const renderField = (title: string, value: string) => (
    <div className={ styles.item }>
      <Text
        className={ styles.subtitle }
        color='gray'
      >
        { title }
      </Text>
      <Text
        className={ styles.text }
      >
        { value }
      </Text>
    </div>
  );

  const renderInfoKzCompany = () => renderField(LABELS.IIN_BIN, IINBIN);

  const renderInfoRuCompany = () => (
    <>
      { renderField(LABELS.INN, INN) }
      { renderField(LABELS.KPP, KPP) }
    </>
  );

  const renderInfoByTypeCompany = brandName === BRAND_NAMES.SMARTWAY_KZ ? renderInfoKzCompany() : renderInfoRuCompany();

  const renderContractNumber = Contract ? renderField(LABELS.CONTRACT_NUMBER, Contract.Number) : null;
  const renderContractDate = Contract ? renderField(LABELS.CONTRACT_DATE, formatDate(Contract.StartDate, DATEFORMATS.DATE)) : null;

  return (
    <div
      className={ styles.collapse_wrapper }
    >
      <Collapse
        type='information'
        abilityToCollapse={ !isMulti }
        opened={ !isMulti }
        label={ companyName }
        labelRollUp={ LABELS.COLLAPSE }
        labelExpand={ LABELS.EXPAND }
      >
        <div
          key={ `${CompanyId}_${CompanyName}` }
          className={ styles.info_wrapper }
        >
          { renderField(LABELS.SHORT_NAME, ShortCompanyName) }
          { renderContractNumber }
          { renderContractDate }
          { renderInfoByTypeCompany }
          { renderField(LABELS.LEGAL_ADDRESS, LegalAddress) }
          { renderField(LABELS.ACTUAL_ADDRESS, ActualAddress) }
          { renderField(LABELS.CEO, CEO) }
        </div>
      </Collapse>
    </div>
  );
};

export default RenderCompany;
