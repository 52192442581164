import ACTION from '../action';
import Store from '../../../store/index';

import { HotelAutocompleteObj } from '../types';

import { createSuggests } from '../../../utils/hotels';

interface NewStore {
  region: {
    label: string,
    selected: object,
    suggests: HotelAutocompleteObj[],
  },
}

const createNewState = () => ({
  region: {
    label: '',
    selected: {},
    suggests: [],
  },
});

const reducer = (action: { [key: string]: any, type: string }, state: NewStore) => {
  switch (action.type) {
    case ACTION.SET_SCHEME_HOTEL_STORE:
      return action.payload;

    case ACTION.SCHEME_UPDATE_REGION_SUGGEST: {
      return {
        ...state,
        region: {
          ...state.region,
          label: action.payload.query,
          suggests: createSuggests(action.payload.items),
          selected: {},
          loading: action.payload.loading || false,
        },
      };
    }

    case ACTION.SCHEME_HOTEL_STORE_RESET: {
      return {
        region: {
          label: '',
          selected: {},
          suggests: [],
        },
      };
    }
  }

  return state;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
