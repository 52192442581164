const STAT_AUTOCOMPLETE_EVENTS = {
  SHOW: {
    TYPE: 'show',
    VALUE: 'Показ',
  },
  CLICK: {
    TYPE: 'click',
    VALUE: 'Клик',
  },
  EMPTY: {
    TYPE: 'empty',
    VALUE: 'Пусто',
  },
};

const STAT_HOTEL_EVENTS = {
  SEARCH: {
    VALUE: 'Search',
  },
  REGIONEMPTYRESULT: {
    VALUE: 'RegionEmptyResult',
  },
  OPENHOTEL: {
    VALUE: 'SelectedHotel',
  },
  ADDTOCART: {
    VALUE: 'AddToCart',
    TYPES: {
      FROMHOTEL: 'FromHotel',
      FROMNOTE: 'FromNote',
    },
  },
  BOOKING: {
    VALUE: 'Booking',
  },
  EMPTYRESULT: {
    VALUE: 'HotelEmptyResult',
    TYPES: {
      SHOWPAGE: 'ShowPage',
      SENDREQUEST: 'SendRequest',
    },
    PARAMS: {
      NOTFOUND: 'NotFound',
      WITHOUTONLINECONFIRM: 'WithoutOnlineConfirm',
      HASOFFLINEROOM: 'HasOfflineRoom',
    },
  },
};

const PLACE_TYPES = {
  HOTEL: {
    TYPE: 'Гостиницы',
    VALUE: 'Объект',
  },
  REGION: {
    TYPE: 'Регионы',
    VALUE: 'Регион',
  },
};

export { STAT_AUTOCOMPLETE_EVENTS, STAT_HOTEL_EVENTS, PLACE_TYPES };
