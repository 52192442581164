import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Paginate, Table, Text, PageLoader } from 'new-ui';

import { getText } from '../../../../../../i18n';

import ROUTES from '../../../../../bi/constants/routes';

import { getImageLogo } from '../../../../../bi/utils/airline';

import { ChartAnalyticsNoResult } from '../../noResult';

import { INestedTable, ISmartData } from '../type';
import { SubTableStringsType } from '../../../../../bi/services/chartsAnalytics/types';

import styles from './styles.module.css';

const LABELS = {
  BUSINESS: getText('chartsAnalytics:business'),
  CANCELED: getText('chartsAnalytics:canceled'),
};

const AirlineNestedTable = observer(({ store, onPageChange }: INestedTable) => {
  const {
    subTableInfo: {
      strings,
      columnsName,
    },
    paginate: {
      currentPage,
      itemsPerPage,
      total,
    },
    loading,
  } = store;

  const renderBusiness = (isBusiness: boolean) => isBusiness && (
    <div className={ styles.business }>{ LABELS.BUSINESS }</div>
  );

  const routeHtml = (route: string) => (
    <div className={ styles.route_name }>{ route }</div>
  );

  const nameHtml = (route: string, tripId: number) => {
    if (tripId) {
      return (
        <Link
          to={ `${ROUTES.TRIP.MAIN}/${tripId}` }
          target='_blank'
        >
          <Button
            className={ styles.link }
            type='textual'
          >
            { routeHtml(route) }
          </Button>
        </Link>
      );
    }

    return routeHtml(route);
  };

  const renderIcon = (id: string) => (
    <img
      className={ styles.img }
      src={ getImageLogo(id) }
      alt={ id }
    />
  );

  const renderCanceled = (isCanceled: boolean, data: string[], indexData: number) => {
    if (isCanceled && indexData === data.length - 1) {
      return <div className={ styles.canceled }>{ LABELS.CANCELED }</div>;
    }

    return null;
  };

  const mapperData = (value: string, indexData: number, isCanceled: boolean, data: any) => {
    if (indexData === 0) return null;

    return (
      <td key={ indexData }>
        <div className={ styles.value_wrap }>
          { value }
          { renderCanceled(isCanceled, data, indexData) }
        </div>
      </td>
    );
  };

  const renderСolumn = (items: string[], info: ISmartData) => items.map((value, indexData) => mapperData(value, indexData, info?.isCanceled, items));

  const renderTable = (items: SubTableStringsType[]) =>
    items.map(({ data, subPageInfo }, indexRow) => (
      <tr key={ indexRow }>
        <td>
          <div className={ styles.route_wrap }>
            <div className={ styles.name }>
              <div className={ styles.icon }> { renderIcon(subPageInfo?.airlineCode) } </div>
              { nameHtml(data[0], subPageInfo?.tripId) }
            </div>
            { renderBusiness(subPageInfo?.isBusiness) }
          </div>
        </td>
        {renderСolumn(data, subPageInfo)}
      </tr>
    ));

  const renderResult = () => {
    if (!strings?.length) return <ChartAnalyticsNoResult />;

    const firstLabel = (<Text className={ styles.route } type='SEMIBOLD_14'>{ columnsName[0] }</Text>);
    const header = [firstLabel, ...columnsName.slice(1)];

    return (
      <>
        <div className={ styles.table }>
          <Table
            labelsHeader={ header as any }
          >
            { renderTable(strings) }
          </Table>
        </div>
        <div className={ styles.footer }>
          <div className={ styles.paginate }>
            <Paginate
              page={ currentPage }
              itemsPerPage={ itemsPerPage }
              total={ total }
              onChange={ onPageChange }
            />
          </div>
        </div>
      </>
    );
  };

  const renderLoading = () => <PageLoader />;

  return (
    loading ? renderLoading() : renderResult()
  );
});

export { AirlineNestedTable };
