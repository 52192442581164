// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flushSync } from 'react-dom';
import { Text, PageLoader } from 'new-ui';

import { getText } from '../../../i18n';

import AirlineSearchMenu from '../../components/Menu/AirlineSearchMenu';
import AirlineHistory from './components/AirlinesHistory';
import { WarningMessage } from '../../components/WarningMessage';
import { AirlinesResultSkeleton } from '../AirlineResult/components/AirlinesResultSkeleton';
import { WarningBlock } from '../../components/WarningBlock';
import { DateInSearch } from '../../components/DateInSerch';
import { BackToTripLink } from '../../components/BackToTripLink';

import { ANALYTIC_SERVICE_TYPES, SMART_AGENT_SERVICE_TYPE } from '../../bi/constants/serviceType';
import { HISTORY_ACTIONS } from '../../bi/constants/history';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import MainAnalytic from '../../bi/utils/analytics/main';
import { parseSearchString } from '../../bi/utils/convertSearchParams';
import { isSmartAgent } from '../../bi/utils/env';

import styles from './styles/airline.module.css';

const LABELS = {
  loader: getText('search:air.loader'),
  TITLE: getText('search:air.title'),
  warning: getText('search:air.warning'),
  ORDER_FOR_A_TRIP: getText('components:trip.orderForATrip'),
};

class AirlineSearchPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    airlineService: PropTypes.object.isRequired,
    userSessionService: PropTypes.object.isRequired,
    workspaceService: PropTypes.object.isRequired,
    requestsService: PropTypes.object.isRequired,
    travelApprovalService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    smartdeskService: PropTypes.object.isRequired,
    isShowTitle: PropTypes.bool,
  };

  static defaultProps = {
    isShowTitle: true,
  };

  constructor(props) {
    super(props);

    const { airlineService } = props;
    const { isTravelApproval, schemeLoading, textTemplateWarning } = airlineService.getSearchStore();
    const searchParams = parseSearchString(location.search);

    this.state = {
      loading: false,
      notWaitingRequest: false,
      isTravelApproval,
      schemeLoading,
      textTemplateWarning,
      tripId: searchParams?.tripId || '',
      tripDate: searchParams?.tripDate || '',
      tripName: searchParams?.tripName || '',
    };
  }

  isFavoriteLastPath = false;
  idRequest = 0;
  idRequestItem = 0;

  componentDidUpdate({ location: { key: prevKey } }) {
    const { location: { key }, airlineService } = this.props;
    const { tripId, tripDate, tripName } = this.state;

    const searchParams = parseSearchString(this.props.history.location.search);
    const searchTripId = searchParams?.tripId || '';
    const searchTripDate = searchParams?.tripDate || '';
    const searchTripName = searchParams?.tripName || '';

    if (prevKey !== key) {
      airlineService.setNewSearch();
      airlineService.getWarningTemplate();
    }

    if (searchTripId !== tripId || searchTripDate !== tripDate || searchTripName !== tripName) {
      this.setState({
        tripId: searchParams?.tripId || '',
        tripDate: searchParams?.tripDate || '',
        tripName: searchParams?.tripName || '',
      });
    }
  }

  componentDidMount() {
    const {
      location,
      airlineService,
      userSessionService,
      workspaceService,
      requestsService,
      history: {
        action,
      },
    } = this.props;
    const { isTravelApproval, schemeLoading } = this.state;

    const searchParams = parseSearchString(location.search);

    if (!searchParams?.is_request) {
      airlineService.getWarningTemplate();
    }

    if (isSmartAgent) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.MAIN_SEARCH_OPENED, { SAservice: SMART_AGENT_SERVICE_TYPE.AVIA });
    }

    this.unsubscribeFn = airlineService.subscribeSearch(this.updateState);

    const historyActionIsPop = action === HISTORY_ACTIONS.POP;
    const searchParamsIsNullOrNotChanging = !searchParams || (searchParams && !searchParams.isChanging);

    if (searchParamsIsNullOrNotChanging && !historyActionIsPop && !isTravelApproval && !schemeLoading) {
      airlineService.setNewSearch();
    }

    if (searchParams && !searchParams.isChanging && !searchParams.tripId) {
      if (searchParams.is_favorite) {
        this.isFavoriteLastPath = true;
      }

      const rightsBuyTrip = workspaceService.rights;
      const accountTravelPolicy = workspaceService.travelPolicy;
      const travelPolicyAllList = userSessionService.get().travelPolicies;
      const { request, requestItem } = requestsService.get();

      if (searchParams.is_request && request && requestItem) {
        this.idRequest = request.Id;
        this.idRequestItem = requestItem.Id;
      }

      const settings = {
        rightsBuyTrip,
        accountTravelPolicy,
        travelPolicyAllList,
      };

      this.setState({
        loading: true,
      }, () => {
        if (this.idRequestItem > 0) {
          this.xhr = airlineService.searchFromRequest(requestItem, settings);
        } else {
          this.xhr = airlineService.searchByParams(searchParams, settings);
        }

        if (this.xhr) {
          this.xhr.then(this.handleMoveToResult);
        }
      });
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AIRLINE.SEARCH_SCREEN_OPENED);
  }

  componentWillUnmount() {
    if (this.state.isTravelApproval) {
      this.props.airlineService.setClearSearch();
    }

    if (this.xhr && this.xhr.abort) {
      this.xhr.abort();
    }

    this.props.travelApprovalService.setResetTravelApprovalsForCartStore();
    this.unsubscribeFn();
  }

  updateState = ({ isTravelApproval, schemeLoading, textTemplateWarning }) => this.setState({ isTravelApproval, schemeLoading, textTemplateWarning });

  search = () => {
    const {
      airlineService,
      smartdeskService: {
        setSearchSmartdesk,
      },
      featureFlagsService: {
        getNewAirlineSearch,
        getShowElementForSmartagent,
      },
      userSessionService,
      workspaceService,
    } = this.props;
    const { setSkeletonSearch } = airlineService;

    setSearchSmartdesk(true);
    setSkeletonSearch(false);
    flushSync(this.setState(prev => ({ ...prev })));

    if (getShowElementForSmartagent() && isSmartAgent) {
      MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.MAINSEARCH_SEARCH_PRESSED('searchAvia'),
      );
    }

    this.setState({
      loading: true,
      notWaitingRequest: false,
    }, () => {
      setSkeletonSearch(true);

      const rightsBuyTrip = workspaceService.rights;
      const accountTravelPolicy = workspaceService.travelPolicy;
      const travelPolicyAllList = userSessionService.get().travelPolicies;

      const settings = {
        rightsBuyTrip,
        accountTravelPolicy,
        travelPolicyAllList,
        isNewTravellers: isSmartAgent && getNewAirlineSearch(),
      };

      this.xhr = airlineService.search(settings);

      if (this.xhr) {
        this.xhr.then(this.handleMoveToResult);
      }
    });
  };

  handleMoveToResult = (searchId) => {
    const {
      smartdeskService: {
        setSearchSmartdesk,
      },
      airlineService: {
        setSkeletonSearch,
      },
    } = this.props;
    const { notWaitingRequest, tripId } = this.state;

    if (notWaitingRequest) return;

    let search = '';

    if (this.isFavoriteLastPath) {
      search = 'is_favorite=true';
    } else if (this.isRequest) {
      search = 'is_request=true';
    }

    if (tripId) {
      search += search ? `?tripId=${tripId}` : `tripId=${tripId}`;
    }

    this.props.history.push({
      pathname: `/search/airline/${searchId}`,
      search,
    });

    setSkeletonSearch(false);
    setSearchSmartdesk(false);
  };

  handleSearch = () => {
    this.search();

    const state = this.props.airlineService.airlineSearchStore.getState();
    let action = MainAnalytic.ACTIONS.AIRLINE.SEARCHONEWAY;

    if (state.isComplex) {
      action = MainAnalytic.ACTIONS.AIRLINE.SEARCHCOMPLEXROUTE;
    } else if (state.routes.length === 1 && state.routes[0].dateBack) {
      action = MainAnalytic.ACTIONS.AIRLINE.SEARCHTHEREAGAIN;
    }

    MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, action, {
      label: MainAnalytic.LABELS.AIRLINE[state.flightClass],
      value: state.travellers,
    });

    MainAnalytic.sendAmplitude(
      MainAnalytic.ACTIONS.AIRLINE.SEARCH_AIR_FIND_BUTTON_PRESSED,
      { service: ANALYTIC_SERVICE_TYPES.AIR },
    );
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_FIND_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.AIR),
    );
  };

  handleSearchSubMenu = () => this.search();

  handleStartHistorySearch = ({ details }) => this.setState({ loading: true }, () => {
    const { airlineService, userSessionService, workspaceService } = this.props;
    const { setSkeletonSearch } = airlineService;

    setSkeletonSearch(true);

    const rightsBuyTrip = workspaceService.rights;
    const accountTravelPolicy = workspaceService.travelPolicy;
    const travelPolicyAllList = userSessionService.get().travelPolicies;

    const settings = {
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    };

    const data = {
      ...details,
      FromHistory: true,
    };

    this.xhr = airlineService.searchByHistory(data, settings);
    this.xhr.then(this.handleMoveToResult);
  });

  renderSearchMenu() {
    const {
      airlineService,
      requestsService,
      featureFlagsService,
      featureFlagsService: {
        getShowElementForSmartagent,
      },
      isShowTitle,
    } = this.props;

    const wrapper = isSmartAgent && getShowElementForSmartagent() && !isShowTitle
      ? `${styles.menu} ${styles.menu_sa}`
      : styles.menu;

    return (
      <div className={ wrapper }>
        <AirlineSearchMenu
          requestsService={ requestsService }
          airlineService={ airlineService }
          onSearch={ this.handleSearch }
          onSearchSubMenu={ this.handleSearchSubMenu }
          qaAttrs={ QA_ATTRIBUTES.search.airline.panel }
          featureFlagsService={ featureFlagsService }
        />
      </div>
    );
  }

  handleReturnToSearch = () => this.setState({ loading: false, notWaitingRequest: true });

  renderLoading = () => {
    const { airlineService, requestsService, featureFlagsService } = this.props;
    const { isSkeleton } = airlineService.getSearchStore();

    if (!isSkeleton) {
      return null;
    }

    return (
      <>
        <AirlineSearchMenu
          subMenu
          requestsService={ requestsService }
          airlineService={ airlineService }
          onSearch={ this.handleSearch }
          onSearchSubMenu={ this.handleSearchSubMenu }
          handleReturnToSearch={ this.handleReturnToSearch }
          featureFlagsService={ featureFlagsService }
          qaAttrs={ QA_ATTRIBUTES.search.airline.panel }
        />
        <div className={ `${styles.wrapper} ${styles.wrapper_skeleton}` } data-qa={ QA_ATTRIBUTES.search.airline.loadingAirlines }>
          <AirlinesResultSkeleton />
        </div>
      </>
    );
  };

  renderMixvelWarning = () => {
    const { textTemplateWarning } = this.state;

    if (!textTemplateWarning) return null;

    return <WarningBlock text={ textTemplateWarning } type='SEMIBOLD_16' color='red' />;
  };

  renderWarningMessage = this.props.featureFlagsService.getTripRuleCustomMessage() ? <WarningMessage /> : null;
  //
  renderTitle = (title: string) => {
    const { featureFlagsService: { getShowElementForSmartagent }, isShowTitle } = this.props;

    if (isSmartAgent && getShowElementForSmartagent() && !isShowTitle) {
      return null;
    }

    return (
      <Text
        type='bold_32'
        className={ styles.header }
        qaAttr={ QA_ATTRIBUTES.search.airline.text }
      >
        { title }
      </Text>
    );
  };

  renderHistory = () => {
    const {
      airlineService,
      featureFlagsService: {
        getShowElementForSmartagent,
      },
    } = this.props;

    if (isSmartAgent && getShowElementForSmartagent()) {
      return null;
    }

    return (
      <AirlineHistory
        airlineService={ airlineService }
        onClick={ this.handleStartHistorySearch }
        qaAttrs={ QA_ATTRIBUTES.search.airline.history }
      />
    );
  };

  render() {
    const { featureFlagsService: { getShowElementForSmartagent }, isShowTitle } = this.props;
    const { loading, schemeLoading, tripId, tripName, tripDate } = this.state;

    const title = tripId ? LABELS.ORDER_FOR_A_TRIP : LABELS.TITLE;

    if (loading) return this.renderLoading();

    if (schemeLoading) {
      return <PageLoader />;
    }

    const addingTriipDate = tripId ? <DateInSearch name={ tripName } date={ tripDate }/> : null;

    const wrapper = isSmartAgent && getShowElementForSmartagent() && !isShowTitle
      ? styles.wrapper
      : `${styles.wrapper} ${styles.wrapper_sw}`;

    return (
      <div className={ wrapper }>
        <BackToTripLink tripId={ tripId } />
        { this.renderTitle(title) }
        { addingTriipDate }
        { this.renderSearchMenu() }
        { this.renderMixvelWarning() }
        { this.renderWarningMessage }
        { this.renderHistory() }
      </div>
    );
  }
}

export default AirlineSearchPage;
