import React, { createRef } from 'react';
import { observer } from 'mobx-react';
import { Moment } from 'moment';
import {
  Datepicker,
  DotLoading,
  Input,
  LinkButton,
  MultiSelect,
  MultiSelectValuesNested,
  Suggest,
  Text,
  Tooltip,
} from 'new-ui';

import { getText } from '../../../../../i18n';

import { useServices } from '../../../../bi/context/services';

import { SexSwitcher } from '../../../../components/SexSwitcher';
import { InputPhone } from '../../../../components/InputPhone';
import { FieldLabel } from '../../../../components/FieldLabel';
import { FormWrapper } from '../../../../components/FormWrapper';

import { EMPLOYEEFIELD } from '../../../../bi/constants/employee';
import { IDS_FOR_INTEGRATION_TESTS } from '../../../../bi/constants/idsForInregrationTests';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { LoadingFields } from '../../../../bi/services/departments/types';
import { LoadingStatus } from '../../../../bi/services/utils/network/types';
import { ICompanyEasy, IEmployee } from '../../../../bi/services/employee/types';
import { CitizenshipSuggestions } from '../../../../bi/types/employees';

import styles from './index.module.css';

const LABELS = {
  ORGANIZATION: getText('settings:employees.employee.steps.general.organization'),
  DEPARTMENT: getText('settings:employees.employee.steps.general.department'),
  PATRONYMIC: getText('settings:employees.employee.steps.general.patronymic'),
  CITIZENSHIP: getText('settings:employees.employee.steps.general.citizenship'),
  NAME: getText('settings:employees.employee.steps.general.name'),
  SURNAME: getText('settings:employees.employee.steps.general.surname'),
  EMAIL: getText('settings:employees.employee.steps.general.email'),
  MOBILEPHONE: getText('settings:employees.employee.steps.general.mobilePhone'),
  BIRTHDAY: getText('settings:employees.employee.steps.general.birthday'),
  LOGIN: getText('settings:employees.employee.steps.general.login'),
  SEX: getText('settings:employees.employee.steps.general.sex'),
  WHY_WE_NEED: getText('settings:employees.employee.steps.general.whyWeNeed'),
  PHONE_TOOLTOP: {
    ONE: getText('settings:employees.employee.steps.general.phoneTooltip.one'),
    TWO: getText('settings:employees.employee.steps.general.phoneTooltip.two'),
  },
  EMAIL_TOOLTIP: {
    ONE: getText('settings:employees.employee.steps.general.emailTooltip.one'),
    TWO: (email: string) => getText('settings:employees.employee.steps.general.emailTooltip.two', { email }),
  },
};

const DATEPICKER_PLACEHOLDER = '__.__.____';

const { employee: { general } } = QA_ATTRIBUTES;

interface IGeneralStep {
  employee: IEmployee,
  errors: {
    Email: string,
    Name: string,
    Surname: string,
    Patronymic: string,
    Birthday: string,
    MobilePhone: string,
  },
  citizenshipSuggestions: CitizenshipSuggestions,
  citizenshipName: string,
  currentDate: Moment | null,
  handleCompaniesChange(value: string[] | number[] | MultiSelectValuesNested[]): void,
  handleInputChange(value: string, field: string): void,
  handleResetErrors(field: string): void,
  handleBlurInput(field: string, value: string): void,
  handleDateChange(field: string, date: Moment | string): void,
  handleValidationInput(value: string): void,
  handleCitizenshipSuggestSelected(value: any): void,
  handleGetCitizenshipSuggests(value: string): void,
  handleSexChange(field: string, value: string): void,
  onPhoneInputBlur(value: boolean): void,
  errorMsg: {
    error: string,
    title: string,
  },
  DepartmentOnly: boolean,
  isAdmin: boolean,
  isTraveler: boolean,
}

const GeneralStep = observer(({
  employee: {
    Email,
    Name,
    Surname,
    Patronymic,
    Birthday,
    Sex,
    EmailEditable,
    MobilePhone,
    Companies,
  },
  errors: {
    Email: errEmail,
    Name: errName,
    Surname: errSurname,
    Patronymic: errPatronymic,
    Birthday: errBirthday,
    MobilePhone: errMobilePhone,
  },
  errorMsg,
  citizenshipSuggestions,
  citizenshipName,
  currentDate,
  handleCompaniesChange,
  handleInputChange,
  handleResetErrors,
  handleBlurInput,
  handleDateChange,
  handleValidationInput,
  handleCitizenshipSuggestSelected,
  handleGetCitizenshipSuggests,
  handleSexChange,
  onPhoneInputBlur,
  DepartmentOnly,
  isAdmin,
  isTraveler,
}: IGeneralStep) => {
  const {
    departmentsService: {
      networkStore: { getIsStatus },
      store: { departmentForSelectList },
    },
  } = useServices(['Departments']);
  const surnameRef: React.MutableRefObject<any> = createRef();
  const citizenshipRef: React.MutableRefObject<any> = createRef();

  const nestedWithFFMapper = () => Companies.map(company => ({
    ...company,
    nested: company.nested.filter(id =>
      departmentForSelectList.every(({ nested }) =>
        nested.every(({ value }) => value !== Number(id)))),
  }));

  const companiesValueMapper = () => Companies.map(company => ({
    ...company,
    nested: company.nested.filter(id =>
      departmentForSelectList.some(({ nested }) =>
        nested.some(({ value }) => value === Number(id)))),
  }));

  const nestedWithFF = DepartmentOnly && Companies[0].nested
    ? nestedWithFFMapper()
    : [];

  const renderCitizenshipSuggestions = ({ Name: label }: { Name: string }): JSX.Element => (
    <Text>
      { label }
    </Text>
  );

  const renderDepartments = () => {
    const { nested: departments, value } = departmentForSelectList[0];

    if (!departments.length) {
      return null;
    }

    const { nested = [] } = Companies[0];

    return (
      <div className={ styles.row }>
        <div className={ styles.item }>
          <FieldLabel
            text={ LABELS.DEPARTMENT }
          />
          <MultiSelect
            list={ departments }
            values={ nested }
            // @ts-ignore
            onChange={ v => handleCompaniesChange([{ main: value, nested: v }]) }
            theme='border'
            qaAttr={ general.department }
          />
        </div>
      </div>
    );
  };

  const renderCompanies = () => {
    if (!getIsStatus(LoadingFields.loadDepartmentForSelectList, LoadingStatus.LOADED)) {
      return (
        <div className={ styles.loading }>
          <DotLoading />
        </div>
      );
    }

    if (departmentForSelectList.length === 1) {
      return renderDepartments();
    }

    const companiesValue = DepartmentOnly && !isAdmin && nestedWithFF.length
      ? companiesValueMapper()
      : Companies;

    const isValue = (index: number) => (nestedWithFF[index] ? nestedWithFF[index].nested : []);

    const onAddCompany = (value: ICompanyEasy[]) => {
      const valueMapper = value.map((item, index) => ({ ...item,
        nested: [
          ...item.nested,
          ...isValue(index),
        ],
      }));

      handleCompaniesChange(valueMapper);
    };

    if (isTraveler) return null;

    return (
      <div className={ styles.row }>
        <div className={ styles.item }>
          <FieldLabel
            text={ LABELS.ORGANIZATION }
            mustHave
          />
          <MultiSelect
            list={ departmentForSelectList }
            values={ companiesValue }
            onChange={ onAddCompany }
            theme='border'
            qaAttr={ general.organization }
          />
        </div>
      </div>
    );
  };

  const getErrorEmail = (): any => {
    if (errEmail.length) {
      return errEmail;
    }

    if (!errEmail.length && errorMsg.error.length) {
      return (
        <div className={ styles.error }>
          <div>{ errorMsg.title }</div>
          <div>{ errorMsg.error }</div>
        </div>
      );
    }

    return null;
  };

  const emailContent = (
    <Tooltip
      show={ !EmailEditable }
      renderContent={ () => (
        <Text
          className={ styles.tooltip }
          color='white'
          type='NORMAL_14_130'
        >
          { LABELS.EMAIL_TOOLTIP.ONE }
          <br />
          { LABELS.EMAIL_TOOLTIP.TWO(Email) }
        </Text>
      ) }
    >
      <Input
        id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.GENERAL.EMAIL }
        value={ Email }
        onChange={ (value) => handleInputChange(value, EMPLOYEEFIELD.EMAIL) }
        onFocus={ () => handleResetErrors(EMPLOYEEFIELD.EMAIL) }
        onBlur={ () => handleBlurInput(Email, EMPLOYEEFIELD.EMAIL) }
        disabled={ !EmailEditable }
        error={ getErrorEmail() }
        qaAttr={ general.email }
        qaAttrError={ general.emailError }
      />
    </Tooltip>
  );

  const namesHtml = (
    <FormWrapper className={ styles.row }>
      <div className={ styles.item }>
        <FieldLabel
          text={ LABELS.SURNAME }
          mustHave
        />
        <Input
          ref={ surnameRef }
          id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.GENERAL.SURNAME }
          value={ Surname }
          onFocus={ () => handleResetErrors(EMPLOYEEFIELD.SURNAME) }
          onBlur={ () => handleBlurInput(Surname, EMPLOYEEFIELD.SURNAME) }
          onChange={ (value) => handleInputChange(value, EMPLOYEEFIELD.SURNAME) }
          debounceMs={ 0 }
          error={ errSurname }
          qaAttr={ general.surname }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel
          text={ LABELS.NAME }
          mustHave
        />
        <Input
          id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.GENERAL.NAME }
          value={ Name }
          onFocus={ () => handleResetErrors(EMPLOYEEFIELD.NAME) }
          onBlur={ () => handleBlurInput(Name, EMPLOYEEFIELD.NAME) }
          onChange={ (value) => handleInputChange(value, EMPLOYEEFIELD.NAME) }
          debounceMs={ 0 }
          error={ errName }
          qaAttr={ general.name }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel
          text={ LABELS.PATRONYMIC }
        />
        <Input
          id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.GENERAL.PATRONYMIC }
          value={ Patronymic }
          onFocus={ () => handleResetErrors(EMPLOYEEFIELD.PATRONYMIC) }
          onChange={ v => handleInputChange(v, EMPLOYEEFIELD.PATRONYMIC) }
          onBlur={ () => handleBlurInput(Patronymic, EMPLOYEEFIELD.PATRONYMIC) }
          debounceMs={ 0 }
          error={ errPatronymic }
          qaAttr={ general.patronymic }
        />
      </div>
    </FormWrapper>
  );

  const birthdayCitizenshipAndSexHtml = (
    <FormWrapper className={ styles.row }>
      <div className={ styles.item }>
        <FieldLabel
          text={ LABELS.BIRTHDAY }
          mustHave
        />
        <Datepicker
          closeOnTabOut
          inputClassName={ styles.datepicker }
          id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.GENERAL.BIRTHDAY }
          value={ Birthday }
          max={ currentDate }
          onChange={ (value: Moment | string) => handleDateChange(EMPLOYEEFIELD.BIRTHDAY, value) }
          onFocus={ () => handleResetErrors(EMPLOYEEFIELD.BIRTHDAY) }
          onBlur={ () => handleValidationInput(EMPLOYEEFIELD.BIRTHDAY) }
          placeholder={ DATEPICKER_PLACEHOLDER }
          error={ errBirthday }
          qaAttr={ general.birthday }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel
          text={ LABELS.CITIZENSHIP }
          mustHave
        />
        <Suggest
          preventTab={ false }
          theme='border'
          ref={ citizenshipRef }
          withLabel={ false }
          value={ citizenshipName || citizenshipSuggestions.label }
          items={ citizenshipSuggestions.suggestions }
          onSelect={ handleCitizenshipSuggestSelected }
          onChange={ handleGetCitizenshipSuggests }
          renderItem={ (value: any) => renderCitizenshipSuggestions(value) }
          qaAttr={ general.citizenship }
          qaAttrFirstEl={ general.citizenshipFirstEl }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel
          text={ LABELS.SEX }
        />
        <SexSwitcher
          sex={ Sex }
          onChange={ value => handleSexChange(EMPLOYEEFIELD.SEX, value) }
          qaAttrMan={ general.man }
          qaAttrFemale={ general.female }
        />
      </div>
    </FormWrapper>
  );

  const mobileAndEmailHtml = (
    <FormWrapper className={ styles.row }>
      <div className={ styles.item }>
        <FieldLabel
          text={ LABELS.MOBILEPHONE }
          mustHave
        />
        <InputPhone
          value={ MobilePhone }
          onFocus={ () => handleResetErrors(EMPLOYEEFIELD.MOBILEPHONE) }
          onBlur={ onPhoneInputBlur }
          onChange={ (value: string) => handleInputChange(value, EMPLOYEEFIELD.MOBILEPHONE) }
          debounceMs={ 0 }
          error={ errMobilePhone }
          qaAttr={ general.phone }
          qaAttrPrefix={ general.prefix }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel
          text={ LABELS.EMAIL }
          mustHave
        />
        { emailContent }
      </div>
    </FormWrapper>
  );

  const phoneTooltipHtml = (
    <div className={ styles.row }>
      <div className={ styles['phone-tooltip'] }>
        <Tooltip
          position='bottom'
          renderContent={ () => (
            <Text
              color='white'
              className={ styles.tooltip }
              type='NORMAL_14_130'
            >
              { LABELS.PHONE_TOOLTOP.ONE }
              <br />
              { LABELS.PHONE_TOOLTOP.TWO }
            </Text>
          ) }
        >
          <LinkButton
            tabIndex={ -1 }
            className={ styles.link }
            theme='large-default'
          >
            { LABELS.WHY_WE_NEED }
          </LinkButton>
        </Tooltip>
      </div>
    </div>
  );

  return (
    <div className={ styles.main }>
      { namesHtml }
      { birthdayCitizenshipAndSexHtml }
      { mobileAndEmailHtml }
      { phoneTooltipHtml }
      { renderCompanies() }
    </div>
  );
});

export { GeneralStep };
