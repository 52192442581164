import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { History } from 'history';
import * as queryString from 'query-string';
import { Text, Button, BackLink, NoResults } from 'new-ui';
import { getText } from '../../../i18n';

import { UseStoresInterface, withStores } from '../../bi/context';
import { UseServicesReturnType } from '../../bi/context/services';
import { MOBX_STORES } from '../../bi/context/stores';

import AppService from '../../bi/services/app';

import { parseSearchString } from '../../bi/utils/convertSearchParams';
import { MainAnalytic } from '../../bi/utils/analytics';
import { formatDate } from '../../bi/utils/formatDate';
import { isSmartAgent } from '../../bi/utils/env';

import { PATTERN } from '../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { ANALYTIC_SERVICE_TYPES } from '../../bi/constants/serviceType';

import { SearchLoading } from '../../components/SearchLoading';
import Search from '../../components/Search';
import { TrainsSearchMenu } from '../../components/Menu/TrainSearchMenu';

import { TrainResultWrap } from './components/result';
import { TrainsSearchFilter } from './components/searchFilter';

import { IAccountTravelPolicy } from '../../bi/services/workspace/types';
import { ITrain } from '../../bi/types/train';
import { ITravelPolicyListItem } from '../../bi/types/travelPolicy';
import { Rights } from '../../bi/types/workspace';

import styles from './styles/index.module.css';

const LABELS = {
  SEARCH: getText('trains:results.loader'),
  SEARCH_BACK: getText('trains:results.loaderBack'),
  CHANGE_PARAMS_OF_SEARCH: getText('trains:results.changeSearchParams'),
  CHANGE_PARAMS_OF_FILTERS: getText('trains:results.changeFilterParams'),
  BACK_LINK: getText('trains:results.request'),
  RESET_FILTERS: getText('trains:results.resetFilters'),
  FAVORITE_NOT_FOUND_MESSAGE: (
    number: number | null,
    date: string,
  ) => getText('trains:results.notFoundFavorite.message', { number, date }),
  NOT_FOUND: getText('trains:results.notFound'),
};

interface IServices extends UseServicesReturnType<'Trains'
| 'Popups'
| 'AccountSettings'
| 'Favorites'
| 'Requests'
| 'Notification'
| 'UserSession'
| 'Workspace'
| 'FeatureFlags'
> {}

interface IStores extends Pick<UseStoresInterface, 'trainSearchStore'
| 'trainSavedTicketStore'
| 'trainSavedTicketsStore'
| 'trainTicketsStore'
> {}

type TrainsResultWrapType = IServices & {
  history: History;
  appService: AppService;
  location: Location;
  isFavoriteLastPath: boolean;
  stores: IStores;
  aggregationId: number | null;
};

interface ITrainsResultWrapStore {
  loading: boolean;
  travelPolicyAllList: ITravelPolicyListItem[];
  rightsBuyTrip: Rights,
  accountTravelPolicy: IAccountTravelPolicy;
  isShownContext: boolean;
}

@withStores([
  MOBX_STORES.TRAIN_SEARCH,
  MOBX_STORES.TRAIN_TICKETS,
  MOBX_STORES.TRAIN_SAVED_TICKET,
  MOBX_STORES.TRAIN_SAVED_TICKETS,
])
@observer
class TrainsResultWrap extends Component<TrainsResultWrapType, ITrainsResultWrapStore> {
  constructor(props: TrainsResultWrapType) {
    super(props);

    const {
      userSessionService,
      workspaceService,
      stores: {
        trainSearchStore: {
          immediate,
        },
      },
      popupsService: {
        store: {
          popupsState: {
            mice_train_group_search,
          },
        },
      },
    } = props;

    const searchParams = parseSearchString(location.search);

    this.state = {
      loading: !!searchParams || !!immediate,
      travelPolicyAllList: userSessionService.get().travelPolicies,
      rightsBuyTrip: workspaceService.rights,
      accountTravelPolicy: workspaceService.travelPolicy,
      isShownContext: !mice_train_group_search,
    };
  }

  isFavoriteLastPath = false;
  idRequest = 0;
  idRequestItem = 0;

  componentDidMount() {
    const {
      trainsService,
      location,
      isFavoriteLastPath,
      requestsService,
      stores: { trainSearchStore: { immediate } },
    } = this.props;
    const { travelPolicyAllList, rightsBuyTrip, accountTravelPolicy } = this.state;
    const { request, requestItem } = requestsService.get();
    const searchParams = parseSearchString(location.search);

    const settings = {
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    };

    if (searchParams) {
      if (searchParams.is_request && request && requestItem) {
        this.idRequest = request.Id;
        this.idRequestItem = requestItem.Id;
      }

      this.isFavoriteLastPath = isFavoriteLastPath
        ? !!searchParams.number && searchParams.number.length > 0
        : false;

      const searchResult = () => {
        if (searchParams.FromHistory) {
          return trainsService.searchByHistoryItem(searchParams, settings);
        }

        if (this.idRequestItem > 0) {
          return trainsService.searchFromRequest(requestItem, settings);
        }

        return trainsService.searchByParams(searchParams, settings);
      };

      searchResult().then(() => {
        this.setState({
          loading: false,
        });
      });
    } else if (immediate) {
      this.handleSearch();
    }

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_SCREEN_OPENED(ANALYTIC_SERVICE_TYPES.TRAIN),
    );
  }

  search = (searchFn: () => Promise<void>) => this.setState({
    loading: true,
  }, () => {
    searchFn().then(() => {
      this.setState({
        loading: false,
      });
    });
  });

  handleShownContext = (value: boolean) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CONTEXT.TRAIN.CLOSED);

    this.setState({ isShownContext: value });
  };

  handleSearch = () => {
    const { trainsService, history, stores: { trainSearchStore: { isValid, firstSearch } } } = this.props;
    const { travelPolicyAllList, rightsBuyTrip, accountTravelPolicy } = this.state;

    const settings = {
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    };

    if (isValid) {
      const mapState = firstSearch ? trainsService.mapStateToSearchObjectFromWithTransfer()
        : trainsService.mapStateToSearchObject();

      history.push({
        search: queryString.stringify(mapState),
      });
      this.search(trainsService.search.bind(trainsService, settings));
    }
  };

  handleSearchTransfers = () => {
    const { trainsService } = this.props;
    const { travelPolicyAllList, rightsBuyTrip, accountTravelPolicy } = this.state;

    const settings = {
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    };

    return trainsService.searchTransfers(settings);
  };

  handleSaveCacheTickets = (tickets: ITrain[]) => {
    const { trainsService, stores: { trainSavedTicketStore: { savedTicket } } } = this.props;

    if (savedTicket) {
      trainsService.setFromSavedTicket();
    }

    return this.props.trainsService.setCacheTickets(tickets);
  };

  handleGetLinkTrip = () => {
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_LINK_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.TRAIN),
    );
    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.GETLINK);
  };

  handleCopyLinkTrip = () =>
    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.COPYLINK);

  handleAddToFavorite = (label: string, value: number) => {
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_FAVORITES_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.TRAIN),
    );
    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.ADDTOFAVORITES, {
      label,
      value,
    });
  };

  handleSelectTrain = (label: string, value: number) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_TRAIN_RAILW_CARRIAGE_CHOSEN);
    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.SELECTTRAIN, {
      label,
      value,
    });
  };

  handleResetFilters = () => {
    this.isFavoriteLastPath = false;
    this.props.trainsService.resetFilters();
  };

  renderBackToRequestButton = () => {
    const { request: { Id: reqId } } = this.props.requestsService.get();

    const requestId = this.idRequest > 0 ? reqId : 0;

    return !!requestId && requestId > 0 && (
      <BackLink link={ `/requests/${requestId}` } text={ LABELS.BACK_LINK } alternativeDesign={ isSmartAgent }/>
    );
  };

  renderEmptyFilter = () => {
    const {
      stores: {
        trainTicketsStore: { favorite: { number, date } },
      },
    } = this.props;

    const title = this.isFavoriteLastPath
      ? LABELS.FAVORITE_NOT_FOUND_MESSAGE(number, formatDate(date, PATTERN.DAY_OF_MONTH))
      : LABELS.NOT_FOUND;

    const subtext = this.isFavoriteLastPath
      ? LABELS.CHANGE_PARAMS_OF_SEARCH
      : LABELS.CHANGE_PARAMS_OF_FILTERS;

    return (
      <div className={ styles.items_empty }>
        <NoResults
          renderContent={ () => (
            <div className={ styles.no_results }>
              <Text type='bold_18' className={ styles.title }>
                { title }
              </Text>
              <Text className={ styles.subtext }>
                { subtext }
              </Text>
              <Button
                type='primary-outline'
                onClick={ this.handleResetFilters }
                className={ styles.reset_btn }
              >
                { LABELS.RESET_FILTERS }
              </Button>
            </div>
          ) }
        />
      </div>
    );
  };

  renderResult = () => {
    const {
      trainsService,
      accountSettingsService,
      favoritesService,
      notificationService,
      userSessionService,
      history,
      requestsService,
      popupsService,
      aggregationId,
      stores: {
        trainTicketsStore: {
          sources,
          tickets,
          sourcesWithTransfer,
          ticketsWithTransfer,
        },
      },
      appService,
      featureFlagsService,
    } = this.props;
    const { isShownContext } = this.state;

    if ((sources.length || sourcesWithTransfer.length) && !tickets.length && !ticketsWithTransfer.length) {
      return this.renderEmptyFilter();
    }

    const {
      request: { Comment },
      requestItem: { Id: reqItemID },
    } = requestsService.get();

    const requestItemId = this.idRequestItem > 0 ? reqItemID : 0;
    const requestItemComment = this.idRequest > 0 ? Comment : '';
    const travelPolicyList = userSessionService.get().travelPolicies;

    return (
      // @ts-ignore
      <TrainResultWrap
        trainsService={ trainsService }
        accountSettingsService={ accountSettingsService }
        favoritesService={ favoritesService }
        notificationService={ notificationService }
        history={ history }
        appService={ appService }
        travelPolicyList={ travelPolicyList }
        requestItemId={ requestItemId }
        requestItemComment={ requestItemComment }
        onGetLinkTrip={ this.handleGetLinkTrip }
        onCopyLinkTrip={ this.handleCopyLinkTrip }
        onAddToFavorite={ this.handleAddToFavorite }
        onSelectTrain={ this.handleSelectTrain }
        onSearch={ this.handleSearch }
        onSearchTransfers={ this.handleSearchTransfers }
        onSaveCacheTickets={ this.handleSaveCacheTickets }
        popupsService={ popupsService }
        aggregationId={ aggregationId }
        isShownContext={ isShownContext }
        onSetShownContext={ this.handleShownContext }
        featureFlagsService={ featureFlagsService }
      />
    );
  };

  renderResultWrap = () => {
    const {
      trainsService: {
        setPriceFilter,
        setTypeFilter,
        setFavoriteFilter,
        setTimeFilter,
        setTrainNumberFilter,
        setIsSapsanFilter,
        setTravelPolicyFilter,
        setTransfersFilter,
      },
      userSessionService,
    } = this.props;

    const travelPolicyList = userSessionService.get().travelPolicies;

    return (
      <div className={ styles.result }>
        <div>
          { this.renderBackToRequestButton() }
          {/* @ts-ignore */}
          <TrainsSearchFilter
            travelPolicyList={ travelPolicyList }
            updatePriceFilter={ setPriceFilter }
            updateTypeFilter={ setTypeFilter }
            updateFavoriteFilter={ setFavoriteFilter }
            updateTimeFilter={ setTimeFilter }
            updateTrainNumberFilter={ setTrainNumberFilter }
            updateIsSapsanFilter={ setIsSapsanFilter }
            updateTravelPolicyFilter={ setTravelPolicyFilter }
            updateTransfersFilter={ setTransfersFilter }
          />
        </div>
        { this.renderResult() }
      </div>
    );
  };

  render() {
    const { loading } = this.state;
    const {
      trainsService,
      stores: {
        trainSearchStore: {
          firstSearch,
        },
      },
      featureFlagsService,
    } = this.props;

    if (loading) {
      const label = firstSearch ? LABELS.SEARCH_BACK : LABELS.SEARCH;

      return (
        <div className={ styles['search-loading'] }>
          <Search>
            <SearchLoading
              text={ label }
              qaAttrLoadingText={ QA_ATTRIBUTES.search.train.bestTrain }
            />
          </Search>
        </div>
      );
    }

    return (
      <div>
        <TrainsSearchMenu
          subMenu
          trainsService={ trainsService }
          onSearch={ this.handleSearch }
          featureFlagsService={ featureFlagsService }
        />
        { this.renderResultWrap() }
      </div>
    );
  }
}

export { TrainsResultWrap };
