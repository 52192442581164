import ACTION from './action';
import Store from '../../store';
import { INoteItem } from './types';

interface State {
  loading: boolean;
  item: INoteItem;
  isError: boolean;
}

interface Action {
  type: keyof typeof ACTION;
  payload: INoteItem;
}

const reducer = (
  action: Action,
  state: State,
) => {
  switch (action.type) {
    case ACTION.LOAD: {
      // eslint-disable-next-line no-param-reassign
      state.loading = true;
      break;
    }

    case ACTION.UPDATE: {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
      // eslint-disable-next-line no-param-reassign
      state.item = action.payload;
      break;
    }

    case ACTION.UPDATE_ERROR: {
      // eslint-disable-next-line no-param-reassign
      state.isError = true;
      break;
    }

    case ACTION.RESET_ERROR: {
      // eslint-disable-next-line no-param-reassign
      state.isError = false;
      break;
    }
  }

  return state;
};

const createStore = () => new (Store as any)(reducer, {
  loading: false,
  item: {},
  isError: false,
});

export default createStore;
