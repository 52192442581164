import React, { useState, useLayoutEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Text, Button, Dialog, List, PageLoader, NoResults } from 'new-ui';
import { useHistory } from 'react-router-dom';

import { getText } from '../../../../../i18n';

import { useSchemes } from '../../../../bi/services/applicationScheme/hooks';
import { KEYS } from '../../../../bi/services/applicationScheme/constants';

import { TableRow } from '../../../../components/TableRow';

import TEXTS from '../../../../bi/constants/texts';
import ROUTES from '../../../../bi/constants/routes';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import { api } from '../../../../bi/apiV2';

import { IShemasApp } from '../../types';

import styles from './index.module.css';

const LABELS = {
  ADD: getText('settings:approvalSchemes.add'),
  REMOVE: getText('settings:approvalSchemes.remove'),
  CANCEL: getText('common:undo'),
  UNABLE_TO_DELETE: getText('settings:approvalSchemes.unableToDeleteDialog.title'),
  UNABLE_TO_DELETE_DESC: getText('settings:approvalSchemes.unableToDeleteDialog.description'),
  DELETE_CONFIRM: getText('settings:approvalSchemes.deleteConfirm'),
  EMPTY_DESC: getText('settings:applicationSchemes.emptyDesc'),
};

const ApplicationSchemeSettings = () => {
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const [schemeRemoveDialog, setSchemeRemoveDialog] = useState<IShemasApp>({ Name: '', Id: '', IsApplied: false });
  const history = useHistory();

  useLayoutEffect(() => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_SETTINGS_PAGE_OPENED);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_SETTINGS_PAGE_OPENED);
  }, []);

  const queryClient = useQueryClient();
  const { isLoading, data, error } = useSchemes();

  const deleteScheme = useMutation({
    mutationFn: (schemeId:string) => api.applicationScheme.deleteSchemeById(schemeId),
    onSuccess: async () => {
      await queryClient.cancelQueries({ queryKey: [KEYS.getAllSchemes] });

      return queryClient.invalidateQueries([KEYS.getAllSchemes]);
    },
  });

  const handleNavigateToForm = () => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_CREATE_NEW_SCHEME_BUTTON);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_CREATE_NEW_SCHEME_BUTTON);

    history.push(ROUTES.APPLICATION_SCHEME_CREATE);
  };

  const handleNavigateToEditForm = ({ Id, IsApplied }: IShemasApp) => history.push(ROUTES.APPLICATION_SCHEME_BY_ID(Id, IsApplied));

  const handleOpenDialog = (item: IShemasApp) => {
    setShowRemoveDialog(true);
    setSchemeRemoveDialog(item);
  };

  const handleCloseDialog = () => setShowRemoveDialog(false);

  const handleRemoveScheme = async (Id: string) => deleteScheme.mutate(Id);

  const onClickRemoveScheme = (Id: string) => {
    handleRemoveScheme(Id);
    handleCloseDialog();

    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_PRESSED_DELETE_SCHEME_BUTTON);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_PRESSED_DELETE_SCHEME_BUTTON);
  };

  const handleEditForm = (Id: string, IsApplied: boolean) => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_PRESSED_EDIT_SCHEME_BUTTON);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_PRESSED_EDIT_SCHEME_BUTTON);

    handleNavigateToEditForm({ Id, IsApplied });
  };

  const handleClickItem = (item: IShemasApp) => handleNavigateToEditForm(item);

  const handleRemove = (IsApplied: boolean, item: IShemasApp) => ({
    action: !IsApplied ? () => handleOpenDialog(item) : () => null,
  });

  const handleEdit = (IsApplied: boolean, Id: string) => ({ action: !IsApplied ? () => handleEditForm(Id, IsApplied) : () => null });

  const handleWatch = (IsApplied: boolean, Id: string) => ({ action: IsApplied ? () => handleNavigateToEditForm({ Id, IsApplied }) : () => null });

  const renderAddButton = () => (
    <Button
      className={ styles.button }
      type='primary'
      onClick={ handleNavigateToForm }
    >
      { LABELS.ADD }
    </Button>
  );

  const renderEmpty = () => (
    <div className={ styles.empty }>
      <Text
        className={ styles.text }
        type='NORMAL_16_140'
      >
        { LABELS.EMPTY_DESC }
      </Text>
      { renderAddButton() }
    </div>
  );

  const renderItem = (item: IShemasApp) => {
    const { IsApplied, Name, Id } = item;

    return (
      <TableRow
        title={ String(Name) }
        onClick={ () => handleNavigateToEditForm({ Id, IsApplied }) }
        remove={ handleRemove(IsApplied, item) }
        edit={ handleEdit(IsApplied, Id) }
        watch={ handleWatch(IsApplied, Id) }
      />
    );
  };

  const renderList = () => {
    if (!data.length) {
      return renderEmpty();
    }

    const newListContent = (
      <List
        onClickItem={ (item: IShemasApp) => handleClickItem(item) }
        keyExtractor={ ({ Id }) => Id }
        renderItem={ item => renderItem(item) }
        items={ data }
      />
    );

    return (
      <div className={ styles.list }>
        <div className={ styles.header }>
          { renderAddButton() }
        </div>
        <div className={ styles.content }>
          { newListContent }
        </div>
      </div>
    );
  };

  const renderBasicRemoveDialogContent = () => {
    const { Name, Id } = schemeRemoveDialog;

    return (
      <>
        <Text>
          { LABELS.DELETE_CONFIRM }
          <b>{ ` ${Name}?` }</b>
        </Text>
        <div className={ styles.actions }>
          <Button
            loading={ deleteScheme.isLoading }
            onClick={ () => onClickRemoveScheme(Id) }
          >
            { LABELS.REMOVE }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual-medium'
            onClick={ handleCloseDialog }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </>
    );
  };

  const renderRemoveDialog = () => {
    if (!showRemoveDialog) return null;

    return (
      <Dialog
        show={ showRemoveDialog }
        onChange={ handleCloseDialog }
      >
        { renderBasicRemoveDialogContent() }
      </Dialog>
    );
  };

  if (isLoading) {
    return <PageLoader />;
  }

  if (error) {
    return (
      <div className={ styles.empty }>
        <NoResults label={ TEXTS.SOMETHING_WENT_WRONG } />
      </div>
    );
  }

  return (
    <>
      { renderList() }
      { renderRemoveDialog() }
    </>
  );
};

export { ApplicationSchemeSettings };
