import React from 'react';
import { Skeleton } from 'new-ui';

import { FilterPanel } from '../../../../../../components/FilterPanel';
import { CollapseSkeleton } from '../../../../../../components/Skeletons/CollapseSkeleton';
import { PriceSliderSkeleton } from '../../../../../../components/Skeletons/PriceSliderSkeleton';
import { CheckboxSkeleton } from '../../../../../../components/Skeletons/CheckboxSkeleton';

import styles from './styles/index.module.css';

const FiltersSkeleton = () => (
  <div>
    <FilterPanel >
      <div className={ styles.block }>
        <CollapseSkeleton width={ 126 }>
          <PriceSliderSkeleton width={ 320 } />
        </CollapseSkeleton>
      </div>
      <div className={ styles.block }>
        <CollapseSkeleton width={ 126 }>
          <CheckboxSkeleton className={ styles.checkbox }>
            <Skeleton width={ 240 } height={ 14 } />
          </CheckboxSkeleton>
        </CollapseSkeleton>
      </div>
      <div className={ styles.block }>
        <CollapseSkeleton width={ 126 }>
          <CheckboxSkeleton className={ styles.checkbox }>
            <Skeleton width={ 240 } height={ 14 } />
          </CheckboxSkeleton>
          <CheckboxSkeleton className={ styles.checkbox }>
            <Skeleton width={ 240 } height={ 14 } />
          </CheckboxSkeleton>
        </CollapseSkeleton>
      </div>
      <div className={ `${styles.block} ${styles.block_indented}` }>
        <Skeleton width={ 320 } height={ 38 }/>
      </div>
      <div className={ styles.block }>
        <CollapseSkeleton width={ 126 }>
          <CheckboxSkeleton className={ styles.checkbox }>
            <Skeleton width={ 240 } height={ 14 } />
          </CheckboxSkeleton>
          <CheckboxSkeleton className={ styles.checkbox }>
            <Skeleton width={ 240 } height={ 14 } />
          </CheckboxSkeleton>
          <CheckboxSkeleton className={ styles.checkbox }>
            <Skeleton width={ 240 } height={ 14 } />
          </CheckboxSkeleton>
        </CollapseSkeleton>
      </div>
      <div className={ styles.block }>
        <CollapseSkeleton width={ 126 }>
          <Skeleton width={ 320 } height={ 14 } className={ styles.checkbox }/>
        </CollapseSkeleton>
      </div>
      <div className={ styles.block }>
        <CollapseSkeleton width={ 126 }>
          <CheckboxSkeleton className={ styles.checkbox }>
            <Skeleton width={ 240 } height={ 14 } />
          </CheckboxSkeleton>
          <CheckboxSkeleton className={ styles.checkbox }>
            <Skeleton width={ 240 } height={ 14 } />
          </CheckboxSkeleton>
        </CollapseSkeleton>
      </div>
      <div className={ styles.block }>
        <CollapseSkeleton width={ 126 }>
          <Skeleton width={ 320 } height={ 14 } className={ styles.checkbox }/>
          <Skeleton width={ 320 } height={ 14 } className={ styles.checkbox }/>
        </CollapseSkeleton>
      </div>
      <div className={ `${styles.block} ${styles.block_indented}` }>
        <Skeleton width={ 320 } height={ 38 }/>
      </div>
    </FilterPanel>
  </div>
);

export { FiltersSkeleton };
