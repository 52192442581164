import React from 'react';
import { observer } from 'mobx-react';
import { DotLoading, LinkButton, Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import styles from './index.module.css';

interface ISimpleSignatureStep {
  isAdmin: boolean;
  canEditRights: boolean;
  onOpenSignatureDialog(value: boolean): void;
  onDeleteSignature(): void;
}

const LABELS = {
  GET_ACCESS: getText('settings:employees.employee.steps.signature.getAccess'),
  SETTINGS_OF_ACCESS: getText('settings:employees.employee.settingsOfAccess'),
  REGISTRATION_NUMBER: getText('settings:employees.employee.steps.signature.registrationNumber'),
  PHONE_NUMBER: getText('settings:employees.employee.steps.signature.phoneNumber'),
  EMAIL: getText('settings:employees.employee.steps.signature.email'),
  DELETE_SIGNATURE: getText('settings:employees.employee.steps.signature.deleteSignature'),
};

const SimpleSignatureStep = observer(({
  isAdmin,
  canEditRights,
  onOpenSignatureDialog,
  onDeleteSignature,
}: ISimpleSignatureStep) => {
  const { simpleSignatureStore } = useStores([MOBX_STORES.SIMPLE_SIGNATURE_STORE]);

  const {
    loading,
    signature: {
      registrationNumber,
      phoneNumber,
      email,
    },
    loadings: {
      loadingDeleteSignature,
    },
  } = simpleSignatureStore;

  const handleOpenSignatureDialog = () => {
    onOpenSignatureDialog(true);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.USER_CLICKED_PROVIDE_SS_BUTTON);
  };

  const showDeleteButton = isAdmin || canEditRights ? (
    <LinkButton
      loading={ loadingDeleteSignature }
      tabIndex={ -1 }
      theme='blue-without-border'
      onClick={ onDeleteSignature }
    >
      { LABELS.DELETE_SIGNATURE }
    </LinkButton>
  ) : null;

  const renderSimpleSignatureContent = () => (
    <div className={ styles.content }>
      <div className={ styles.row }>
        <Text type='bold_16'>
          { LABELS.REGISTRATION_NUMBER }
        </Text>
        { showDeleteButton }
      </div>
      <Text type='NORMAL_16'>
        { registrationNumber }
      </Text>
      <Text type='bold_16' className={ styles.signature_header }>
        { LABELS.PHONE_NUMBER }
      </Text>
      <Text type='NORMAL_16'>
        { phoneNumber }
      </Text>
      <Text type='bold_16' className={ styles.signature_header }>
        { LABELS.EMAIL }
      </Text>
      <Text type='NORMAL_16'>
        { email }
      </Text>
    </div>
  );

  if (loading || loadingDeleteSignature) {
    return <div className={ styles.loading }>
      <DotLoading />
    </div>;
  }

  if (registrationNumber) {
    return renderSimpleSignatureContent();
  }

  return (
    <>
      <Text
        type='bold_18'
        className={ styles.subtitle }
      >
        { LABELS.SETTINGS_OF_ACCESS }
      </Text>
      <div className={ styles.link }>
        <LinkButton
          tabIndex={ -1 }
          onClick={ handleOpenSignatureDialog }
          theme='large-default'
        >
          { LABELS.GET_ACCESS }
        </LinkButton>
      </div>
    </>
  );
});

export { SimpleSignatureStep };
