const PROD_NODE_ENV = [
  'production',
  'sandbox',
  'bs',
  'production-gost',
];

const alertDev = (error: string) => {
  if (!PROD_NODE_ENV.includes(process.env.NODE_ENV as string)) {
    // eslint-disable-next-line no-console
    console.log('error: ', error);

    // const message = error.length > 50 ? error.substr(0, 49) : error;
    //
    // NotificationsManager.addNotification(
    //   { value: message, type: 'error', onClick: () => {}, qaAttr: '' }
    // );
  }
};

export { alertDev };
