const ENUMS = {
  BONUS: 'bonus',
  DOCUMENTS: 'documents',
  CITIZENSHIP: 'citizenship',
};

const DEPARTMENT_HEAD = {
  APPROVE: {
    AVAILABLE: 'Available',
    UNAVAILABLE: 'Unavailable',
  },
};

const PROJECT_HEAD = {
  APPROVE: {
    AVAILABLE: 'Available',
    UNAVAILABLE: 'Unavailable',
  },
};

export { ENUMS, DEPARTMENT_HEAD, PROJECT_HEAD };
