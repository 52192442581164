import { detectBrowser } from '../../utils/detectBrowser';
import getAccountId from '../../utils/getAccountId';
import { formatMomentUtc } from '../../utils/formatDate';

import type { DataType, DataCutType } from '../../services/productAnalytics/types';

class ProductAnalytics {
  private Workspace: any;
  private ProductAnalytics: any;

  constructor(services: any) {
    Object.keys(services).forEach((s: string) => {
      // @ts-ignore
      this[s] = services[s];
    });
  }

  getData = (): DataCutType => {
    const { name, version, os } = detectBrowser();

    return {
      AccountId: getAccountId(),
      UserEmail: this.Workspace.AccountEmail,
      EventDate: formatMomentUtc(),
      String1: `browser ${name}`,
      String2: `version ${version}`,
      String3: os,
    };
  };

  sendIntercomAfterLoadPage = () => {
    const data: DataType = {
      ...this.getData(),
      Event: 'Intercom not load',
    };

    return this.ProductAnalytics.sendData(data);
  };

  sendIntercomClick = () => {
    const data: DataType = {
      ...this.getData(),
      Event: 'Intercom click',
    };

    return this.ProductAnalytics.sendData(data);
  };

  sendIntercomAfterClickError = () => {
    const data: DataType = {
      ...this.getData(),
      Event: 'Intercom not open after click',
    };

    return this.ProductAnalytics.sendData(data);
  };

  clickIntercom = () => {
    this.sendIntercomClick();

    const intercomIframe = document.getElementById('intercom-frame');

    if (!intercomIframe) {
      this.sendIntercomAfterClickError();
    }
  };
}

export { ProductAnalytics };
