import { getText } from '../../../../../i18n';

const TYPEUPD = {
  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  BIMONTHLY: 'Bimonthly',
  THREETIMESFORMONTH: 'ThreeTimesForMonth',
  FIVETIMESFORMONTH: 'FiveTimesForMonth',
  TENTIMESFORMONTH: 'TenTimesForMonth',
};

const TYPEUPDRU_SMARTAGENT = {
  [TYPEUPD.DAILY]: getText('services:workspace.periodsOfClosingDoc.dailySmartagent'),
  [TYPEUPD.MONTHLY]: getText('services:workspace.periodsOfClosingDoc.monthlySmartagent'),
  [TYPEUPD.BIMONTHLY]: getText('services:workspace.periodsOfClosingDoc.bimonthlySmartagent'),
  [TYPEUPD.THREETIMESFORMONTH]: getText('services:workspace.periodsOfClosingDoc.threeTimesForMonthSmartagent'),
  [TYPEUPD.FIVETIMESFORMONTH]: getText('services:workspace.periodsOfClosingDoc.fiveTimesForMonthSmartagent'),
  [TYPEUPD.TENTIMESFORMONTH]: getText('services:workspace.periodsOfClosingDoc.tenTimesForMonthSmartagent'),
};

const TYPEUPDRU = {
  [TYPEUPD.DAILY]: getText('services:workspace.periodsOfClosingDoc.daily'),
  [TYPEUPD.MONTHLY]: getText('services:workspace.periodsOfClosingDoc.monthly'),
  [TYPEUPD.BIMONTHLY]: getText('services:workspace.periodsOfClosingDoc.bimonthly'),
  [TYPEUPD.THREETIMESFORMONTH]: getText('services:workspace.periodsOfClosingDoc.threeTimesForMonth'),
  [TYPEUPD.FIVETIMESFORMONTH]: getText('services:workspace.periodsOfClosingDoc.fiveTimesForMonth'),
  [TYPEUPD.TENTIMESFORMONTH]: getText('services:workspace.periodsOfClosingDoc.tenTimesForMonth'),
};

export {
  TYPEUPD,
  TYPEUPDRU,
  TYPEUPDRU_SMARTAGENT,
};
