import { action, computed, makeObservable, observable } from 'mobx';

import { displayTripsEmployeeMapper, searchSettingsMapper } from '../../utils/mapperAccountSettings';

import { IAccountSettingsProps } from './types';

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable accountSettings: IAccountSettingsProps = {
    searchSettings: [],
    noBookingTaxi: false,
    noAnalytics: false,
    cartEmployeeBirthday: false,
    displayTripsEmployee: {},
    noChangeOrReturnTrip: false,
    salary1C: false,
    chatType: null,
    noExpenseReport: false,
    hidePerDiem: false,
    hideAdditionalOrders: false,
    noPersonalBonusCards: false,
    forceTravelPolicy: false,
    forceEmployeeNumber: false,
    companySettings: {
      companyId: null,
      freeFlightCertificate: false,
      isMandatory: false,
      prohibitionVouchers: false,
      removeFlightCertificate: false,
    },
    deleteProject: false,
    bookByPassengerScheme: false,
    noMice: false,
  };

  @action
  setAccountSettings = (accountSettings: IAccountSettingsProps) => {
    this.accountSettings = {
      ...displayTripsEmployeeMapper(accountSettings),
      searchSettings: [...searchSettingsMapper(accountSettings.searchSettings)],
    };
  };

  @action
  getChatType = () => this.accountSettings.chatType;

  @computed
  get noBookingTaxi() {
    return this.accountSettings.noBookingTaxi || false;
  }

  @computed
  get analytics() {
    return this.accountSettings.noAnalytics || false;
  }

  @computed
  get cartEmployeeBirthday() {
    return this.accountSettings.cartEmployeeBirthday || false;
  }

  @computed
  get displayTripsEmployee() {
    return this.accountSettings.displayTripsEmployee || {};
  }

  @computed
  get noChangeOrReturnTrip() {
    return this.accountSettings.noChangeOrReturnTrip || false;
  }

  @computed
  get salary1C() {
    return this.accountSettings.salary1C || false;
  }

  @computed
  get noExpenseReport() {
    return this.accountSettings.noExpenseReport || false;
  }

  @computed
  get hidePerDiem() {
    return this.accountSettings.hidePerDiem || false;
  }

  get hideAdditionalOrders() {
    return this.accountSettings.hideAdditionalOrders || false;
  }

  @computed
  get searchServiceType() {
    return this.accountSettings.searchSettings || [];
  }

  @computed
  get noPersonalBonusCards() {
    return this.accountSettings.noPersonalBonusCards || false;
  }

  @computed
  get forceTravelPolicy() {
    return this.accountSettings.forceTravelPolicy || false;
  }

  @computed
  get forceEmployeeNumber() {
    return this.accountSettings.forceEmployeeNumber || false;
  }

  @computed
  get companySettings() {
    return this.accountSettings.companySettings || [];
  }

  @computed
  get deleteProject() {
    return this.accountSettings.deleteProject || false;
  }

  @computed
  get noMice() {
    return this.accountSettings.noMice || false;
  }

  @computed
  get canBookByPassengerScheme() {
    return this.accountSettings.bookByPassengerScheme || false;
  }
}

export default Store;
