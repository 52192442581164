import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Text, Button, Input, LinkButton, Dialog, PageLoader } from 'new-ui';
import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import {
  IBalances,
  IBonusProgram,
  CompaniesType,
} from '../../../../bi/services/bonusProgram/types';

import { FieldLabel } from '../../../../components/FieldLabel';
import { ExternalLink, TARGETS } from '../../../../components/ExternalLink';

import { Registration } from './components/Registration';
import { Calculator } from './components/Calculator';
import { Save } from './components/Save';
import { Feature } from './components/Feature';
import { Steps } from './components/Steps';

import { isS7BonusCode } from '../../../../bi/utils/validation';
import MoneyFormat from '../../../../bi/utils/money';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { S7_STATUSES } from '../../../../bi/constants/bonusProgram';

import styles from './styles/index.module.css';

const LABELS = {
  REGISTER: getText('settings:bonusProgram.register'),
  ALREADY: getText('settings:bonusProgram.already'),
  ADD: getText('settings:bonusProgram.add'),
  BACK: getText('settings:bonusProgram.back'),
  IS_OK: getText('settings:bonusProgram.isOk'),
  EDIT: getText('settings:bonusProgram.edit'),
  CLOSE: getText('settings:bonusProgram.close'),
  AVAILABLE_MILES: getText('settings:bonusProgram.availableMiles'),
  YOUR_CODE: getText('settings:bonusProgram.yourCode'),
  ANY_QUESTIONS: getText('settings:bonusProgram.anyQuestions'),
  IS_REGISTER: getText('settings:bonusProgram.isRegister'),
  WARNING: {
    INFO: getText('settings:bonusProgram.warning.info'),
    LINK: getText('settings:bonusProgram.warning.link'),
    DESCRIPTION: getText('settings:bonusProgram.warning.description'),
  },
  UPDATE_PROGRAM_INFO: getText('settings:bonusProgram.updateProgramInfo'),
  SETUP_SUCCESS: getText('settings:bonusProgram.setupSuccess'),
  FAILED_ADDING_CODE: getText('settings:bonusProgram.failedAddingCode'),
  TO_TRAVEL_HELPER: getText('settings:bonusProgram.toTravelHelper'),
  INN: getText('settings:bonusProgram.inn'),
  COMPANY_CODE: (company: string) => getText('settings:bonusProgram.companyCode', { company }),
  BONUS_BALANCE_FAIL: (name: string) => getText('settings:bonusProgram.bonusBalanceFail', { name }),
  INDICATED_CODE: (code: string) => getText('settings:bonusProgram.indicatedCode', { code }),
  S7_PROFI: getText('settings:bonusProgram.s7Contacts.name'),
  S7_PHONE: getText('settings:bonusProgram.s7Contacts.phone'),
  S7_EMAIL: getText('settings:bonusProgram.s7Contacts.email'),
  S7_WEBSITE: getText('settings:bonusProgram.s7Contacts.details'),
  SUCCESS: {
    MAY_HAVE_PAY_BONUSES: getText('settings:bonusProgram.success.mayHavePayBonuses'),
    WARNING: getText('settings:bonusProgram.success.warning'),
    EMAIL: getText('settings:bonusProgram.success.orEmail'),
  },
  CHECK_DATA: getText('settings:bonusProgram.addingModal.checkData'),
  EXEPT_ERRORS: getText('settings:bonusProgram.addingModal.exceptErrors'),
  ERROR_MODAL: {
    NO_REGISTER: (codeValue: string, companies: any) =>
      getText('settings:bonusProgram.codeErrorModal.noRegister', { codeValue, companies }),
    CHECK_DATA: getText('settings:bonusProgram.codeErrorModal.checkData'),
    ERROR: getText('settings:bonusProgram.codeErrorModal.ifError'),
  },
};

const PROMO_LABELS = {
  TITLE: getText('settings:bonusProgram.promo.labels.title'),
  ECONOMY: getText('settings:bonusProgram.promo.labels.economy'),
  COMFORT: getText('settings:bonusProgram.promo.labels.comfort'),
  POINTS_EXCHANGE: getText('settings:bonusProgram.promo.labels.pointsExchange'),
  HOW_IT_WORK: getText('settings:bonusProgram.promo.labels.howItWork'),
};

const ERROR_TYPES = {
  WRONG: 'WrongFormat',
  ALREADY: 'CodeAlreadySaved',
  MATCH: 'CodeDoesNotMatchCompanyInn',
};

const TITLES = {
  COMMON: getText('settings:bonusProgram.screenTitles.common'),
  REGISTRATION: getText('settings:bonusProgram.screenTitles.registration'),
};

const PLACEHOLDERS = {
  ADD: getText('settings:bonusProgram.placeholders.add'),
};

const VALIDATION_ERRORS = {
  CODE: getText('settings:bonusProgram.validationErrors.code'),
};

const S7_CORP_MASK = 'quw';

const BonusProgram = observer(({
  bonusProgramService,
  chatService,
  workspaceService,
  productAnalyticsProtocol,
}: IBonusProgram) => {
  const { bonusProgramStore } = useStores([MOBX_STORES.BONUS_PROGRAM]);
  const {
    bonuses: {
      Balances = [],
      FailedBalances = [],
      Status,
      loading,
    },
    addingCode: {
      loading: addingLoading,
      Companies,
    },
  } = bonusProgramStore;

  const [isAddingCode, setAddingCode] = useState(false);
  const [isRegistration, setRegistration] = useState(false);
  const [showAddingCodeModal, setShowAddingCodeModal] = useState(false);
  const [showCodeErrModal, setShowCodeErrModal] = useState(false);
  const [codeValue, setCodeValue] = useState('');
  const [codeIsValid, setCodeIsValid] = useState(false);

  useEffect(() => {
    bonusProgramService.loadBonuses();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.S7_OPEN);
  }, [bonusProgramService.loadBonuses]);

  const handleOpenChat = () => {
    chatService.show();
    productAnalyticsProtocol.clickIntercom();
  };

  const handleOpenChatAfterCloseModal = () => {
    setShowAddingCodeModal(false);
    setShowCodeErrModal(false);

    handleOpenChat();
  };

  const handleChangeCode = (value = '') => {
    setCodeValue(value.toUpperCase());
    setCodeIsValid(isS7BonusCode(value));
  };

  const handleBack = () => {
    setRegistration(false);
    setAddingCode(false);
  };

  const handleBackToAddingCode = () => {
    setRegistration(false);
    setAddingCode(true);
  };

  const handleCloseErrorModal = () => {
    setShowCodeErrModal(false);
    setShowAddingCodeModal(false);
  };

  const onSuccess = () => {
    setAddingCode(false);
    setRegistration(false);

    bonusProgramService.loadBonuses();
  };

  const handleSendCode = async () => {
    await bonusProgramService.setBonusCode(codeValue);

    const { store: { addingCode: { Error } } } = bonusProgramService;

    if (Error === ERROR_TYPES.MATCH) {
      setShowCodeErrModal(true);

      return;
    }

    onSuccess();
  };

  const getCompaniesWithInn = (companies: CompaniesType[] | undefined) => {
    if (!companies) {
      return null;
    }

    const companiesText = companies.map(({ Company, Inn }) => `${Company} (${LABELS.INN} ${Inn})`);

    return companiesText.join(', ');
  };

  const renderBalancesContent = (balances: IBalances[], status: number | undefined) => (
    balances.map(({ Company, Balance, Code }) => {
      const companyText = balances.length > 1 ? LABELS.COMPANY_CODE(Company) : LABELS.YOUR_CODE;
      const showBalances = status !== S7_STATUSES.DISCOUNT;

      return (
        <div className={ styles['balance-wrapper'] } key={ Company }>
          <div className={ styles['code-wrapper'] }>
            <Text>{ companyText }</Text>
            &nbsp;
            <Text type='bold_16'>{ Code }</Text>
          </div>
          { showBalances && (
            <div className={ styles['code-wrapper'] }>
              <Text>{ LABELS.AVAILABLE_MILES }</Text>
              &nbsp;
              <Text type='bold_16'>
                { MoneyFormat.moneyWithDecimal(Balance) }
              </Text>
            </div>
          ) }
        </div>
      );
    })
  );

  const renderFailedBalancesContent = (balances: IBalances[], failedBalances: IBalances[]) => (
    failedBalances.map(({ Company, Code = '' }) => {
      const companyName = failedBalances.length > 1 || !!balances.length ? Company : '';

      const label = Code.toLowerCase().includes(S7_CORP_MASK)
        ? LABELS.INDICATED_CODE(Code)
        : LABELS.BONUS_BALANCE_FAIL(companyName);

      return (
        <div className={ styles['code-wrapper'] } key={ Company }>
          <Text>{ label }</Text>
        </div>
      );
    })
  );

  const renderSuccessDescription = () => (
    <>
      <div className={ styles['success-description'] }>
        <Text type='NORMAL_16_140'>
          { LABELS.SUCCESS.MAY_HAVE_PAY_BONUSES }
          {' '}
          <ExternalLink
            text={ LABELS.S7_PROFI }
            link='https://b2b.s7.ru/profile/login'
          />
        </Text>
        <Text type='NORMAL_16_140'>
          { LABELS.SUCCESS.WARNING }
          {' '}
          <ExternalLink
            text={ LABELS.S7_PHONE }
            link='tel:88005005077'
            target={ TARGETS.SELF }
          />
          {' '}
          { LABELS.SUCCESS.EMAIL }
          {' '}
          <ExternalLink
            text={ LABELS.S7_EMAIL }
            link='mailto:corp.support@s7.ru'
            target={ TARGETS.SELF }
          />
        </Text>
      </div>
      <ExternalLink
        text={ LABELS.S7_WEBSITE }
        link='https://www.s7.ru/ru/corp/'
      />
    </>
  );

  const renderWarningLink = () => (
    <div className={ styles.warningWrap }>
      <Text type='NORMAL_16_140'>
        { LABELS.WARNING.INFO }
        <ExternalLink
          text={ LABELS.WARNING.LINK }
          link='https://b2b.s7.ru/profile/login-b2b?ibe_conversation=3.76:cf533423-d3d0-45ed-b258-34e3c62d6f44'
        />
        { LABELS.WARNING.DESCRIPTION }
      </Text>
    </div>
  );

  const renderUpdateProgramInfo = () => (
    <div>
      <Text className={ styles.description }>
        { LABELS.UPDATE_PROGRAM_INFO }
      </Text>
    </div>
  );

  const renderAlreadyContent = () => {
    const showTitle = !!Balances.length;

    const balancesContentHtml = !!Balances.length && renderBalancesContent(Balances, Status);
    const failedBalancesContentHtml = !!FailedBalances.length && renderFailedBalancesContent(Balances, FailedBalances);

    return (
      <div>
        { showTitle && (
          <>
            <Text className={ styles.description }>
              { LABELS.SETUP_SUCCESS }
            </Text>
            { renderUpdateProgramInfo() }
          </>
        ) }
        { balancesContentHtml }
        { failedBalancesContentHtml }
        { renderSuccessDescription() }
      </div>
    );
  };

  const renderActionButtons = (
    successLabel: string,
    secondaryLabel: string | null,
    successAction: React.MouseEventHandler<HTMLButtonElement> | undefined,
    secondaryAction = () => {},
    disabled = false,
    buttonLoading = false,
  ) => (
    <div className={ styles.actions }>
      <Button
        type='secondary'
        onClick={ successAction }
        disabled={ disabled }
        loading={ buttonLoading }
      >
        { successLabel }
      </Button>
      { secondaryAction && secondaryLabel && (
        <Button
          className={ styles.secondary }
          onClick={ secondaryAction }
          type='textual-medium'
        >
          { secondaryLabel }
        </Button>
      ) }
    </div>
  );

  const renderAddingModal = () => (
    <Dialog
      show={ showAddingCodeModal }
      onChange={ () => setShowAddingCodeModal(false) }
    >
      <div className={ styles.modal }>
        <Text
          type='normal_20'
          className={ styles.title }
        >
          { LABELS.YOUR_CODE } —&nbsp;
          <Text type='bold_20'>
            { codeValue }
          </Text>
        </Text>
        <Text
          type='NORMAL_16_140'
          className={ styles.description }
        >
          { LABELS.CHECK_DATA }<br/>
          { LABELS.EXEPT_ERRORS }
        </Text>
        {
          renderActionButtons(
            LABELS.IS_OK,
            LABELS.EDIT,
            handleSendCode,
            () => setShowAddingCodeModal(false),
            false,
            addingLoading,
          )
        }
      </div>
    </Dialog>
  );

  const renderCodeErrorModal = () => (
    <Dialog
      show={ showCodeErrModal }
      onChange={ handleCloseErrorModal }
    >
      <div className={ styles.modal }>
        <Text
          type='bold_24'
          className={ styles.title }
        >
          { LABELS.FAILED_ADDING_CODE }
        </Text>
        <Text type='NORMAL_16_140' className={ styles.description }>
          { LABELS.ERROR_MODAL.NO_REGISTER(codeValue, getCompaniesWithInn(Companies)) } <br/>
          { LABELS.ERROR_MODAL.CHECK_DATA } <br/>
          { LABELS.ERROR_MODAL.ERROR }
          <LinkButton
            theme='large-default'
            className={ styles.link }
            onClick={ handleOpenChatAfterCloseModal }
          >
            { LABELS.TO_TRAVEL_HELPER }
          </LinkButton>.
        </Text>
        {
          renderActionButtons(
            LABELS.CLOSE,
            null,
            handleCloseErrorModal,
            () => {},
            false,
            addingLoading,
          )
        }
      </div>
    </Dialog>
  );

  const renderCodeInput = () => {
    const codeError = !codeIsValid && !!codeValue ? VALIDATION_ERRORS.CODE : '';

    return (
      <div className={ styles['wrap-input'] }>
        <FieldLabel text={ LABELS.YOUR_CODE } />
        <Input
          value={ codeValue }
          placeholder={ PLACEHOLDERS.ADD }
          onChange={ handleChangeCode }
          error={ codeError }
        />
      </div>
    );
  };

  const renderAddingCode = () => (
    <div>
      <Text
        type='NORMAL_16_140'
        className={ styles.description }
      >
        { LABELS.IS_REGISTER }
      </Text>
      { renderWarningLink() }
      { renderCodeInput() }
      {
        renderActionButtons(
          LABELS.ADD,
          LABELS.BACK,
          () => setShowAddingCodeModal(true),
          () => setAddingCode(false),
          !codeIsValid,
        )
      }
      { renderAddingModal() }
      { renderCodeErrorModal() }
    </div>
  );

  const renderPromoContent = () => (
    <div className={ styles.promo }>
      <Text type='bold_24'>
        { PROMO_LABELS.ECONOMY }
      </Text>
      <div className={ styles.save }>
        <Calculator />
        <Save />
      </div>
      <Text type='bold_24'>
        { PROMO_LABELS.COMFORT }
      </Text>
      <Text className={ styles.description }>
        { PROMO_LABELS.POINTS_EXCHANGE }
      </Text>
      <div className={ styles.services }>
        <Feature />
      </div>
      <Text type='bold_24'>
        { PROMO_LABELS.HOW_IT_WORK }
      </Text>
      <div className={ styles.steps }>
        <Steps />
      </div>
    </div>
  );

  const renderAnyQuestionsContent = () => (
    <div className={ styles.questions }>
      <Text color='gray'>{ LABELS.ANY_QUESTIONS }</Text>
      &nbsp;
      <LinkButton
        theme='large-gray'
        onClick={ handleOpenChat }
      >
        { LABELS.TO_TRAVEL_HELPER }
      </LinkButton>
    </div>
  );

  const renderSetupContent = () => {
    const { Email, PhoneNumber } = workspaceService.get();

    if (isAddingCode) {
      return renderAddingCode();
    }

    if (isRegistration) {
      return (
        <Registration
          bonusProgramService={ bonusProgramService }
          onRegistrationSuccess={ onSuccess }
          handleOpenChat={ handleOpenChat }
          handleBackToAddingCode={ handleBackToAddingCode }
          handleBack={ handleBack }
          userEmail={ Email }
          userPhone={ PhoneNumber }
        />
      );
    }

    const actionButtons = renderActionButtons(
      LABELS.REGISTER,
      LABELS.ALREADY,
      () => setRegistration(true),
      () => setAddingCode(true),
    );

    return (
      <>
        <Text type='NORMAL_16_140' className={ styles.description }>
          { PROMO_LABELS.TITLE }
        </Text>
        { actionButtons }
        { renderPromoContent() }
        { actionButtons }
        { renderAnyQuestionsContent() }
      </>
    );
  };

  const renderContent = () => {
    const title = isRegistration ? TITLES.REGISTRATION : TITLES.COMMON;
    const contentHtml = Status ? renderAlreadyContent() : renderSetupContent();

    return (
      <div className={ styles.wrapper }>
        <Text type='bold_24'>
          { title }
        </Text>
        <div className={ styles.container }>
          { contentHtml }
        </div>
      </div>
    );
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className={ styles.wrapper }>
      { renderContent() }
    </div>
  );
});

export { BonusProgram };
