import React from 'react';
import { Text, Stars as StarsComponent } from 'new-ui';

import { IHotelItem } from '../../../../bi/types/favorites';

import styles from './style.module.css';

interface IHotelItemProps {
  favorite: { Item: IHotelItem },
  qaAttrs: { name: string }
}

const HotelItem = ({
  favorite: {
    Item: {
      Name,
      Stars,
      Longitude,
      Latitude,
      Address,
    },
  },
  qaAttrs: {
    name,
  },
}: IHotelItemProps) => (
  <div className={ styles.wrapper }>
    <div className={ styles.main }>
      <Text type='SEMIBOLD_16' qaAttr={ name }>
        { Name }
      </Text>
      <StarsComponent
        count={ Stars }
        className={ styles.stars }
      />
    </div>
    <a
      href={ `https://yandex.ru/maps/?pt=${Longitude}, ${Latitude}&z=18` }
      target='_blank'
      rel='noopener noreferrer'
    >
      <Text type='NORMAL_14' color='accent' >{ Address }</Text>
    </a>
  </div>
);

export default HotelItem;
