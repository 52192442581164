import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { PageLoader } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import ROUTES from '../../../../../bi/constants/routes';

import { ChartAnalyticsNoResult } from '../../noResult';
import { TwoCharts } from '../../common/firstPages/twoBarCharts';
import ChartsAnalytics from '../../../../../bi/services/chartsAnalytics';

const LABELS = {
  TITLE: getText('chartsAnalytics:airline.directions'),
  TICKETS: getText('chartsAnalytics:airline.charts.tickets'),
};

const ChartAnalyticsStructureServiceType = observer(({
  chartsAnalyticsService,
}: { chartsAnalyticsService: ChartsAnalytics }) => {
  const {
    chartsAnalyticsSummaryStore,
    chartsAnalyticsStore: {
      filtersType,
      filters: {
        employees,
        departments,
        projects,
        analytics,
        tags,
      },
    },
  } = useStores([MOBX_STORES.CHARTS_ANALYTICS_SUMMARY_STORE, MOBX_STORES.CHARTS_ANALYTICS_STORE]);

  const {
    chart,
    donutChart,
    tableInfo: {
      labelsHeader,
      content,
    },
    loading,
  } = chartsAnalyticsSummaryStore;

  const {
    getStructureServiceType,
  } = chartsAnalyticsService;

  useEffect(() => {
    getStructureServiceType();
  }, [
    getStructureServiceType,
    employees.selected,
    departments.selected,
    projects.selected,
    analytics.selected,
    tags.selected,
    filtersType,
  ]);

  const history = useHistory();

  const redirectToSubPage = () => history.push(ROUTES.CHARTS_ANALYTICS.STRUCTURE.SERVICE_TYPE_NESTED);

  if (loading) {
    return <PageLoader />;
  }

  if (!chart?.values.length || !content?.length) {
    return <ChartAnalyticsNoResult />;
  }

  return (
    <TwoCharts
      chartsAnalyticsService={ chartsAnalyticsService }
      labelDoughnut={ LABELS.TICKETS }
      chart={ chart }
      donutChart={ donutChart }
      labelsHeader={ labelsHeader }
      content={ content }
      redirectToSubPage={ redirectToSubPage }
    />
  );
});

export { ChartAnalyticsStructureServiceType };
