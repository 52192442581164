import { groupBy } from '../../../../../bi/utils/groupBy';
import { getRouteTitleAdditionalBaggage } from '../../../../../bi/utils/getRouteTitleAdditionalBaggage';

import { ADDITIONAL_SERVICES } from '../../../../../bi/constants/airline';

import { AirRoute } from '../../../../../bi/types/airline';

interface IGetPaidBaggageInfoProps {
  itemData: { Routes: AirRoute[] };
  labels: string;
}
/** Утилита для сбора данных для отрисовки выбранных багажей */
const getPaidBaggageInfo = ({
  itemData,
  labels,
}: IGetPaidBaggageInfoProps) => {
  /** Получаем первый и последний сегменты из каждого маршрута для составления названия роута */
  const routeSegments = itemData.Routes.map(route => ({
    firstSegment: route.Segments[0],
    lastSegment: route.Segments[route.Segments.length - 1],
  }));

  /** Извлекаем дополнительные услуги только для последнего сегмента с ценами на багаж */
  const paidBaggage = routeSegments
    .flatMap(({ lastSegment }) => lastSegment.AdditionalServices || [])
    .filter(service => service.Type === ADDITIONAL_SERVICES.Baggage);

  if (!paidBaggage.length) return null;

  const paidBaggageBySegments = groupBy(paidBaggage, ({ SegmentId }) => String(SegmentId));

  return Object.keys(paidBaggageBySegments).map((key, index) => {
    const currentSegment = routeSegments
      ?.map(({ lastSegment }) => lastSegment)
      ?.find(({ ID }) => ID === Number(key));

    const currentRoute = routeSegments?.find(({ lastSegment }) => lastSegment.ID === Number(key));

    const routeTitle = currentRoute
    && getRouteTitleAdditionalBaggage([currentRoute.firstSegment, currentRoute.lastSegment], false);

    const segmentPrice = paidBaggageBySegments[key]
      .reduce(((allPrice, segment) => allPrice + segment.Service.Price), 0);

    return ({
      keyInd: `${currentSegment?.ID}_${index}`,
      serviceLabel: labels,
      routeTitle,
      customAnalytics: null,
      services: paidBaggageBySegments[key],
      segmentPrice,
    });
  });
};

export { getPaidBaggageInfo };
