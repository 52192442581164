import React, { useLayoutEffect, useState } from 'react';

import { PageLoader } from 'new-ui';

import { useMutation, useQuery } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';

import { getText } from '../../../i18n';

import { api } from '../../bi/apiV2';

import { ApplicationSchemePage } from './SchemeComponent';

import { MainAnalytic } from '../../bi/utils/analytics';

import ROUTES from '../../bi/constants/routes';

import { ErrorState } from './types';

const LABELS = {
  EDIT_SCHEME: getText('applicationScheme:editScheme'),
};

const ApplicationSchemeEdit = () => {
  const { schemeId, isApplied } = useParams<{ schemeId: string, isApplied: string }>();
  const [enabled, setEnabled] = useState(true);
  const [error, setError] = useState<ErrorState>({ show: false, code: null });
  const history = useHistory();

  const goBack = () => history.push(ROUTES.SETTINGS.APPLICATION_SCHEME);

  const handleChangeError: (newError: ErrorState) => void = (newError) => setError(newError);

  useLayoutEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_OPENED_CREATE_PAGE, {
      schemeSource: 'edit',
    });
  }, []);

  const useScheme = (Id: string) => useQuery({
    queryKey: ['applicationSchemeById', Id],
    queryFn: () => api.applicationScheme.getSchemeById(Id),
    staleTime: 0,
    enabled,
    // eslint-disable-next-line @tanstack/query/no-deprecated-options
    onSuccess: () => {
      setEnabled(false);
    },
  });

  const editScheme = useMutation({
    mutationFn: (templateScheme) => api.applicationScheme.updateScheme(templateScheme, schemeId),
    onSuccess: goBack,
    onError: (err: any) => {
      const errorCode = err.response.status;

      setError({ show: true, code: errorCode });
    },
  });

  const { data, isFetching, isLoading } = useScheme(schemeId);

  if (isFetching || isLoading) return <PageLoader />;

  return (
    <ApplicationSchemePage
      onSubmit={ editScheme }
      scheme={ data }
      header={ LABELS.EDIT_SCHEME }
      goBack={ goBack }
      isApplied={ isApplied }
      handleChangeError={ handleChangeError }
      error={ error }
      isNew={ false }
    />
  );
};

export { ApplicationSchemeEdit };
