import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Select, Text, List, Input, Paginate, PageLoader, Dialog } from 'new-ui';

import { getText } from '../../../../../i18n';

import { TableRow } from '../../../../components/TableRow';
import { Alphabet } from '../../../../components/Alphabet';

import scrollToTop from '../../../utils/scrollToTop';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { ICompany } from '../../../../bi/services/employee/types';
import { IDepartmentsTab, ItemDepartments, ItemDepartmentsLigth } from '../../../../bi/services/departments/types';

import ROUTES from '../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { FILTER_KEYS } from '../../../../bi/constants/employee';

import styles from './index.module.css';

const LABELS = {
  ADD: getText('settings:departments.add'),
  EDIT: getText('settings:departments.edit'),
  SEARCH_INPUT: getText('settings:departments.searchInput'),
  EMPTY_TEXT: getText('settings:departments.emptyText'),
  DEPARTMENTS_EMPTY: getText('settings:departments.departmentsEmpty'),
  ALL_ORGANIZATIONS: getText('settings:employees.allOrganizations'),
  COUNT_OF_DEPARTMENTS: (count: number) => getText('settings:departments.countOfDepartments', { count }),
  DELETE: getText('settings:departments.delete'),
  ARE_YOU_SURE: getText('settings:departments.dialog.areYouSure'),
  CONFIRM: getText('settings:departments.dialog.confirm'),
  CANCEL: getText('settings:departments.dialog.cancel'),
};

const ALPHABET_SHOW_CONDITION = 7;

const DepartmentsTab = observer(({
  departmentsService,
  history,
}: IDepartmentsTab) => {
  const { departmentsStore } = useStores([MOBX_STORES.DEPARTMENTS]);
  const {
    filteredValue: {
      value: listValue,
      loading,
    },
    paginate: {
      currentPage,
      itemsPerPage,
      total,
    },
    departmentsIsFiltered,
    companies,
    departmentDeleteLoading,
    hideDeleteDepartmentButton,
  } = departmentsStore;

  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [departmentItem, setDepartmentItem] = useState<ItemDepartmentsLigth>({ Name: '', departmentId: null });

  useEffect(() => {
    departmentsService.getDepartmentsPaginationListNew();

    return departmentsService.clearList();
  }, [departmentsService]);

  useEffect(() => {
    departmentsService.getDepartmentsCompanies();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.DEPARTMENTS_OPEN);

    return departmentsService.clearList;
  }, [departmentsService]);

  const handleOnExistingDepartureOpen = (data: ItemDepartments) => {
    history.push(ROUTES.DEPARTMENT.BY_ID(data.Id));
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.DEPARTMENTS_EDIT_ITEM);

    departmentsService.openForm(data);
  };

  const handleOnNewDepartureOpen = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.DEPARTMENTS_ADD_BUTTON);
    history.push(ROUTES.DEPARTMENT.NEW);
  };

  const addButton = (
    <Button
      className={ styles.button }
      type='primary'
      qaAttr={ QA_ATTRIBUTES.settings.departments.add }
      onClick={ () => handleOnNewDepartureOpen() }
    >
      { LABELS.ADD }
    </Button>
  );

  const handleFilterDepartments = (field: string, v: string | number) => {
    departmentsService.getFilterDepartments(field, v);

    if (field === FILTER_KEYS.COMPANY_ID && v) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.DEPARTMENTS_ORG_SELECT);
    }
  };

  const handleOpenDialog = (departmentId: number, Name: string) => {
    setDepartmentItem({ departmentId, Name });
    setShowRemoveDialog(true);
  };

  const handleOpenRemoveDepartmentDialog = ({ CanBeDeleted, Id, Name }: ItemDepartments) =>
    ({ action: CanBeDeleted ? () => handleOpenDialog(Id, Name) : () => {}, tooltip: '', text: CanBeDeleted ? LABELS.DELETE : '' });

  const handleFocusDepartments = () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.DEPARTMENTS_SEARCH_PRESSED);

  const companiesMapper = (item: ICompany[]) => (item.map(({ CompanyId, ShortCompanyName, CompanyName }) => ({
    value: CompanyId,
    label: ShortCompanyName || CompanyName,
  })));

  const companiesItems = [{
    value: 0,
    label: LABELS.ALL_ORGANIZATIONS,
  }, ...companiesMapper(companies)];

  const renderCompaniesSelect = () => {
    const { filter: { companyId } } = departmentsStore;

    return (
      <div className={ styles.companies }>
        <Select
          items={ companiesItems }
          value={ companyId }
          theme='default-large'
          onChange={ v => handleFilterDepartments(FILTER_KEYS.COMPANY_ID, v) }
        />
      </div>
    );
  };

  const companiesSelect = companies.length > 1 && renderCompaniesSelect();

  const handlePageChange = (page: number) => {
    departmentsService.changePageDepartments(page);

    scrollToTop();
  };

  const handleRemoveDepartment = (departmentId: number) => {
    departmentsService.deleteDepartment(departmentId);
    setShowRemoveDialog(false);
  };

  const renderBasicRemoveDialogContent = () => {
    const { Name, departmentId } = departmentItem;

    const renderConfirmQuest = (departmentName: string) => `${LABELS.ARE_YOU_SURE} ${departmentName} ?`;

    return (
      <>
        <Text>
          { renderConfirmQuest(Name) }
        </Text>
        <div className={ styles.actions }>
          <Button
            onClick={ () => handleRemoveDepartment(departmentId as number) }
            loading={ departmentDeleteLoading }
          >
            { LABELS.CONFIRM }
          </Button>
          <Button
            type='textual-medium'
            onClick={ () => setShowRemoveDialog(false) }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </>
    );
  };

  const renderRemoveDialog = () => {
    if (!showRemoveDialog) return null;

    return (
      <Dialog
        show={ showRemoveDialog }
        onChange={ () => setShowRemoveDialog(false) }
      >
        { renderBasicRemoveDialogContent() }
      </Dialog>
    );
  };

  const emptyHtml = (
    <div className={ styles.empty }>
      <Text
        className={ styles.text }
        type='NORMAL_16_140'
      >
        { LABELS.EMPTY_TEXT }
      </Text>
      { addButton }
    </div>
  );

  const renderEmptyDepartments = (
    <Text className={ styles.text } type='NORMAL_16_130'>
      { LABELS.DEPARTMENTS_EMPTY }
    </Text>
  );

  const renderSearchInput = () => {
    const { filter: { search } } = departmentsStore;

    return (
      <Input
        qaAttr={ QA_ATTRIBUTES.settings.search }
        theme='light-shadow'
        className={ styles.search }
        isCleansing
        value={ search }
        placeholder={ LABELS.SEARCH_INPUT }
        onChange={ v => handleFilterDepartments(FILTER_KEYS.SEARCH, v) }
        onFocus={ handleFocusDepartments }
      />
    );
  };

  const renderTableRow = (item: ItemDepartments) => {
    const {
      HeadOfDepartment,
      EmployeesIds,
      Name,
    } = item;

    const headOfDepartment = HeadOfDepartment ? HeadOfDepartment.Name : '';
    const id = `letter${(Name.toUpperCase() || ' ')[0]}`;

    const onRemove = () => (
      hideDeleteDepartmentButton ? { action: () => null, tooltip: '', text: '' } : handleOpenRemoveDepartmentDialog(item)
    );

    return (
      <TableRow
        isDepartments
        title={ Name }
        id={ id }
        headOfDepartment={ headOfDepartment }
        countOfEmployees={ EmployeesIds.length }
        onClick={ () => handleOnExistingDepartureOpen(item) }
        edit={ {
          action: () => handleOnExistingDepartureOpen(item),
        } }
        remove={ onRemove() } // CLAIM-137734
      />
    );
  };

  const renderList = () => {
    const title = (
      <Text
        type='bold_20'
        className={ styles.subtitle }
      >
        { LABELS.COUNT_OF_DEPARTMENTS(total) }
      </Text>
    );

    const listContent = () => {
      const pagesCount = Math.ceil(total / itemsPerPage);

      const activeLetters = listValue.map(departament => departament.Name[0].toUpperCase());
      const showAlphabet = activeLetters.length ? activeLetters.some((item) => item !== activeLetters[0]) : false;

      const conditionAlphabet = listValue && listValue.length > ALPHABET_SHOW_CONDITION && showAlphabet && (
        <Alphabet
          className={ styles.alphabet }
          anchorPrefix={ '#letter' }
          activeLetters={ activeLetters }
          currentPage={ currentPage }
          pagesCount={ pagesCount }
          onChangePage={ handlePageChange }
        />
      );

      if (listValue.length === 0) {
        return renderEmptyDepartments;
      }

      const content = (
        <div className={ styles.deprtments }>
          <List
            qaAttrFirstEl={ QA_ATTRIBUTES.settings.departments.list.firstItem.item }
            items={ listValue }
            onClickItem={ handleOnExistingDepartureOpen }
            keyExtractor={ ({ Id }) => String(Id) }
            renderItem={ renderTableRow }
          />
          { conditionAlphabet }
          <div className={ styles.paginate }>
            <Paginate
              page={ currentPage }
              itemsPerPage={ itemsPerPage }
              total={ total }
              onChange={ handlePageChange }
            />
          </div>
        </div>
      );

      return (
        <div
          className={ styles.list }
        >
          { title }
          { content }
        </div>
      );
    };

    return (
      <div className={ styles['list-view'] }>
        <div className={ styles.row }>
          {renderSearchInput()}
        </div>
        <div className={ styles.row } >
          <div>
            { companiesSelect }
          </div>
          { addButton }
        </div>
        <div className={ styles.content }>
          { listContent() }
        </div>
      </div>
    );
  };

  const addList = listValue.length || departmentsIsFiltered ? renderList() : emptyHtml;

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className={ styles.wrapper }>
      { addList }
      { renderRemoveDialog() }
    </div>
  );
});

export { DepartmentsTab };
