import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { logEvent } from 'firebase/analytics';
import { Button, List, NoResults, PageLoader, Paginate, Text } from 'new-ui';

import { firebaseAnalytics } from '../../../utils/firebase';

import { getText } from '../../../i18n';

import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import ROUTES from '../../bi/constants/routes';
import { CREATE_EXPENSE_REPORT } from '../../bi/constants/rights';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { IntroDialog } from '../../components/AddVideo';

import scrollToTop from '../utils/scrollToTop';
import { MainAnalytic } from '../../bi/utils/analytics';

import { ExpenseReportItem } from './components/item';
import { CreateExpenseReportDialog } from './components/createDialog';
import { Filters } from './components/filters';

import styles from './styles/index.module.css';

import qrCodeVideo from '../../../video/qr_code.mp4';
import { ExpenseItem, ExpenseReportsPageProps, ExpenseReportsPageState, ReportsType } from '../../bi/services/expenseReports/types';

const LABELS = {
  TITLE: getText('expenseReports:title'),
  EMPTY: getText('expenseReports:empty'),
  BUTTON: getText('expenseReports:create'),
  LOADING: getText('expenseReports:loader'),
  NOT_FOUND: getText('expenseReports:noResults'),
  HOW_WORK_SCANNER: getText('expenseReports:howWorksScanner'),
  IN_MOBILE: getText('expenseReports:inMobile'),
};

@withStores([MOBX_STORES.EXPENSE_REPORTS_STORE])
@observer
class ExpenseReportsPage extends Component<ExpenseReportsPageProps, ExpenseReportsPageState> {
  showPromptForScan = this.props.featureFlagsService.getShowExpenseReports();

  state = {
    showDialog: false,
    showIntroDialog: false,
  };

  createExpenseReports = this.props.workspaceService.canCreateExpenseReports;
  userEmployee = this.props.workspaceService.dataUserEmployee;

  componentDidMount() {
    const {
      expenseReportsService,
      workspaceService,
    } = this.props;

    const name = workspaceService.get().UserName;
    logEvent(firebaseAnalytics, 'expense_screen_opened', {
      name,
    });

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.MAIN_EXPENSE_OPENED);

    return expenseReportsService.loadExpenseReports(name);
  }

  handleToggleIntroDialog = (value: boolean) => {
    this.setState({ showIntroDialog: value });
  };

  handleOpenReport = async (item: ReportsType | null = null, _: number, employee?: any): Promise<void> => {
    const { expenseReportsService, history, stores: { expenseReportsStore: { dialog: { trips } } } } = this.props;
    const { employeeId } = this.userEmployee;

    const selectedTrips = trips.filter(({ selected }) => selected);

    if (employee) {
      expenseReportsService.openReport(null, employee);
    }

    const route = item ? ROUTES.EXPENSE.EXPENSE_REPORT_ID(item.Id) : ROUTES.EXPENSE.EXPENSE_REPORT;

    if (employee && this.createExpenseReports === CREATE_EXPENSE_REPORT.ApprovalScheme) await expenseReportsService.getApproversSteps(employee.Id, selectedTrips);

    if (employee && this.createExpenseReports === CREATE_EXPENSE_REPORT.OnlyAfterApprove) await expenseReportsService.getApprovers(employeeId);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_SAVE_BUTTON_PRESSED);

    return history.push(route);
  };

  handleChangePaginate = (page: number) => {
    this.props.expenseReportsService.setPaginate(page);
    scrollToTop();
  };

  handleToggleDialog = (value: boolean) => {
    if (!value) {
      this.props.expenseReportsService.setClearDialog(false);
    }

    this.setState({
      showDialog: value,
    });

    MainAnalytic.sendAmplitude(
      value
        ? MainAnalytic.ACTIONS.REPORTING.EXPENSE_CREATE_REPORT_PRESSED
        : MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_CANCEL_BUTTON_PRESSED,
    );
  };

  scanButtonHtml = this.showPromptForScan ? (
    <Button
      type='textual'
      onClick={ () => this.handleToggleIntroDialog(true) }
    >
      { LABELS.HOW_WORK_SCANNER }
    </Button>
  ) : null;

  renderButton = () => (
    <Button
      onClick={ () => this.handleToggleDialog(true) }
      qaAttr={ QA_ATTRIBUTES.expenseReport.buttonCreate }
    >
      { LABELS.BUTTON }
    </Button>
  );

  renderDialog = () => {
    const { showDialog } = this.state;
    const {
      stores: {
        expenseReportsStore: {
          dialog,
        },
      },
      workspaceService: { dataUserEmployee, isDemo },
      expenseReportsService: {
        setEmployee,
        setCompany,
        updateCheckbox,
        updateDate,
        updateCheckboxTrip,
        openAllTrips,
        getEmployee,
      },
      employeeService: { autocomplete },
    } = this.props;

    if (!showDialog) {
      return null;
    }

    return (
    // @ts-ignore
      <CreateExpenseReportDialog
        show={ showDialog }
        isDemo={ isDemo }
        dataUserEmployee={ dataUserEmployee }
        dialog={ dialog }
        getEmployees={ value => autocomplete(value, false) }
        onSelectEmployee={ setEmployee }
        onSelectCompany={ setCompany }
        onClose={ () => this.handleToggleDialog(false) }
        onCreate={ this.handleOpenReport }
        onChangeCheckbox={ updateCheckbox }
        onChangeDate={ updateDate }
        onChangeCheckboxTrip={ updateCheckboxTrip }
        onOpenAllTrips={ openAllTrips }
        getUserEmployee={ getEmployee }
      />
    );
  };

  renderEmpty = () => (
    <div className={ styles.empty }>
      <Text
        type='NORMAL_16_140'
        className={ styles.text }
      >
        { LABELS.EMPTY }
      </Text>
      { this.renderButton() }
    </div>
  );

  renderItem = (item: ExpenseItem) => {
    const {
      stores: {
        expenseReportsStore: {
          onlyOwn,
          isIntegration1S,
        },
      },
      expenseReportsService: {
        sendEmail,
        downloadReport,
      },
    } = this.props;

    return (
      <ExpenseReportItem
        key={ item.Id }
        item={ item }
        onlyOwn={ onlyOwn }
        isIntegration1S={ isIntegration1S }
        onDownload={ downloadReport }
        onSendEmail={ sendEmail }
      />
    );
  };

  renderNoResult = () => (
    <NoResults
      renderContent={ () => (
        <div className={ styles.no_results }>
          <Text type='bold_18'>{LABELS.NOT_FOUND}</Text>
          <Text type='NORMAL_14' className={ styles.subtext }>
            {LABELS.NOT_FOUND}
          </Text>
        </div>
      ) }
    />
  );

  renderList = () => {
    const {
      stores: {
        expenseReportsStore: {
          cacheItems,
          paging: { total, current, count },
        },
      },
    } = this.props;

    if (!cacheItems.length) {
      return this.renderNoResult();
    }

    return (
      <>
        <List
          className={ styles.list }
          renderItem={ this.renderItem }
          items={ cacheItems }
          onClickItem={ this.handleOpenReport }
          qaAttrFirstEl={ QA_ATTRIBUTES.expenseReport.firstItem }
        />
        <Paginate
          total={ total }
          page={ current }
          itemsPerPage={ count }
          onChange={ this.handleChangePaginate }
        />
      </>
    );
  };

  renderIntroDialog = () => {
    const { showIntroDialog } = this.state;

    return <IntroDialog
      show={ showIntroDialog }
      onChange={ this.handleToggleIntroDialog }
      label={ `${LABELS.HOW_WORK_SCANNER} ${LABELS.IN_MOBILE}` }
      path={ qrCodeVideo }
    />;
  };

  renderPage = () => {
    const {
      stores: {
        expenseReportsStore: {
          sources,
        },
      },
      expenseReportsService: {
        updateFilters,
        resetFilters,
      },
    } = this.props;

    if (!sources.length) {
      return this.renderEmpty();
    }

    return (
      <>
        <div>
          { this.scanButtonHtml }
          <Filters
              // @ts-ignore
            onChange={ updateFilters }
            onResetFilters={ resetFilters }
          />
        </div>
        { this.renderList() }
      </>
    );
  };

  render() {
    const { sources, loading } = this.props.stores.expenseReportsStore;

    if (loading) {
      return <PageLoader text={ LABELS.LOADING } />;
    }

    const { showDialog } = this.state;

    const buttonHtml = !!sources.length && this.renderButton();
    const dialogHtml = showDialog && this.renderDialog();

    return (
      <div className={ styles.wrapper }>
        <div className={ styles.title }>
          <Text type='bold_32' qaAttr={ QA_ATTRIBUTES.expenseReport.title }>{ LABELS.TITLE }</Text>
          { buttonHtml }
        </div>
        { this.renderPage() }
        { dialogHtml }
        {this.renderIntroDialog()}
      </div>
    );
  }
}

export default ExpenseReportsPage;
