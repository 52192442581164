import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Button, Icon, Text, Textarea, PageLoader, DialogCarousel } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { EditVouchersPropsType } from '../../../../../../bi/types/voucherSettings';

import { isSmartAgent } from '../../../../../../bi/utils/env';
import { MainAnalytic } from '../../../../../../bi/utils/analytics';

import DownloadDialog from '../../../../../../components/UploadDocument';
import UploadLogoVoucher from '../UploadLogoVouchers/UploadLogoVoucher';

import styles from '../../styles/index.module.css';

const MAX_SYMBOL = 250;

const LABELS = {
  HEADER: {
    FORMATION: getText('settings:vouchers:header:formation'),
    SETTING: getText('settings:vouchers:header:setting'),
    PREVIEW: getText('settings:vouchers:header:preview'),
    TITLE_PREVIEW: getText('settings:vouchers:preview'),
  },
  LOGO: {
    TITLE: getText('settings:vouchers:logo:title'),
    LOAD: getText('settings:vouchers:logo:load'),
    TOOLTIP: getText('settings:vouchers:logo:formatTooltip'),
    LOAD_FILES: 'Загрузка файла, ',
  },
  INFO: {
    TOOLTIP: getText('settings:vouchers:info:formatTooltip'),
    ERROR: getText('settings:vouchers:info:errorMaxSymbol'),
  },
  CONTACT_INFO: {
    TITLE: getText('settings:vouchers:contactInfo:title'),
    SUBTITLE: getText('settings:vouchers:contactInfo:information'),
  },
  ADDITIONAL_INFO: {
    TITLE: getText('settings:vouchers:additionalInfo:title'),
    SUBTITLE: getText('settings:vouchers:additionalInfo:information'),
    ERROR: getText('settings:vouchers:additionalInfo:error'),
  },
  ERRORS: {
    NO_LOGO: getText('settings:vouchers:validationDownloadDocument:errors:noLogo'),
  },
  BUTTON_SAVE: getText('settings:vouchers:save'),
  BUTTON_CANCEL: getText('settings:vouchers:cancel'),
  TOOLTIP_SAVE: getText('settings:vouchers:tooltipSave'),
};

const EditVouchers = observer(({
  settingsService: {
    setSettingsVoucher,
    getPreview,
    loadTestVoucher,
    uploadImage,
    setShowUpload,
    setInterLogo,
    setEditFile,
    setValidationDownload,
    setShowPreview,
    setIsLogo,
    setInterIsLogo,
  },
  stores: {
    settingsStore: {
      voucherSetting: {
        AdditionalInfo,
        ContactInfo,
        InterLogoFileId,
        loaderPreview,
        previewPayload,
        IsErrorLogo,
        ErrorUplpadLogo,
        SuccessUpload,
      },
      editVoucherSetting: {
        fileName,
        fileSize,
        loadProgress,
        isValid,
        showUpload,
        showPreview,
        isLogo,
      },
    },
  },
  setShow,
  isDemo,
}: EditVouchersPropsType) => {
  const [valueContactInfo, setValueContactInfo] = useState(ContactInfo);
  const [valueAddInfo, setValueAddInfo] = useState(AdditionalInfo);

  if (!SuccessUpload) {
    return <PageLoader/>;
  }

  const isDisabled = (value: string) => !value && isLogo;

  const isCondition = (line: string) => line?.length > MAX_SYMBOL;

  const disabledSave = isDemo
    || !isLogo
    || IsErrorLogo
    || valueAddInfo.length > MAX_SYMBOL
    || valueContactInfo.length > MAX_SYMBOL;

  const disabledPreview = !isLogo
  || IsErrorLogo
  || valueAddInfo.length > MAX_SYMBOL
  || valueContactInfo.length > MAX_SYMBOL;

  const logoWrapper = isLogo ? styles.logo_upload_wrapper_edit : styles.logo_wrapper;

  const errorLogoFlag = !valueAddInfo || !valueContactInfo;

  const errorLogoFlags = !valueAddInfo && !valueContactInfo;

  if (errorLogoFlag && !isLogo && !isValid && !errorLogoFlags) {
    setValidationDownload(true, LABELS.ERRORS.NO_LOGO);
  }

  if (ErrorUplpadLogo === LABELS.ERRORS.NO_LOGO && errorLogoFlags) {
    setValidationDownload(false);
  }

  const handleSaveVoucher = () => {
    setSettingsVoucher(
      valueAddInfo,
      valueContactInfo,
      InterLogoFileId,
      fileName,
      fileSize,
      isLogo,
    );

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.SETTINGS_FORM_SAVE_BUTTON);
    setShow();
  };

  const handleSetLogo = (payload: any) => {
    setEditFile(payload);
    setIsLogo(true);
  };

  const handleReset = () => {
    setInterLogo();
    setIsLogo(false);

    if (valueAddInfo || valueContactInfo) {
      setValidationDownload(true, LABELS.ERRORS.NO_LOGO);
    }
  };

  const handleCancel = () => {
    setValueContactInfo('');
    setValueAddInfo('');
    setValidationDownload(false);
    setInterIsLogo(false);

    setShow();
  };

  const handlePreview = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.SETTINGS_FORM_PREVIEW);
    getPreview(valueAddInfo, valueContactInfo, InterLogoFileId);
  };

  const handleLoadTestVoucher = (payload: string) => {
    const dataOnDownload = { AdditionalInfo: valueAddInfo, ContactInfo: valueContactInfo, LogoFileId: InterLogoFileId };

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.SETTINGS_FORM_PREVIEW_DOWNLOAD);
    loadTestVoucher(payload, dataOnDownload);
  };

  const renderPreviewDialog = () => {
    if (!previewPayload) {
      return null;
    }

    return (
      <DialogCarousel
        type='svg'
        label={ LABELS.HEADER.TITLE_PREVIEW }
        show={ showPreview }
        items={ previewPayload }
        onChange={ () => setShowPreview(false) }
        onDownload={ (payload: string) => handleLoadTestVoucher(payload) }
      />
    );
  };

  const tooltipContent = (text: string, style: string) => (
    <div>
      <Text
        className={ style }
        color='white'
        type='NORMAL_14'
      >
        { text }
      </Text>
    </div>
  );

  const tooltipSave = () => (
    <div>
      <Text
        className={ styles.tooltip_demo }
        color='white'
        type='NORMAL_14'
      >
        { LABELS.TOOLTIP_SAVE }
      </Text>
    </div>
  );

  const renderTooltip = (tooltipText: string, style: string) => (
    <Tooltip
      className={ styles.tooltip_wrapper }
      show
      clickable={ false }
      renderContent={ () => tooltipContent(tooltipText, style) }
    >
      <Icon
        className={ styles.icon }
        type='question'
        alternativeDesign={ isSmartAgent }
      />
    </Tooltip>
  );

  const renderErrorLogo = () => {
    if (!IsErrorLogo) {
      return null;
    }

    const errorMessage = ErrorUplpadLogo || '';

    return (
      <div>
        <Text
          type='NORMAL_10'
          color='orange'
          className={ styles.logo_error }
        >
          { errorMessage }
        </Text>
      </div>
    );
  };

  const renderError = (error: string, disabled: boolean, line: string) => {
    const textError = isCondition(line) ? LABELS.INFO.ERROR : error;

    return ((isCondition(line) || disabled) && isLogo) && (
      <Text
        type='NORMAL_10'
        color='orange'
        className={ styles.logo_error }
      >
        { textError }
      </Text>
    );
  };

  const renderPrewiev = () => {
    if (disabledPreview) {
      return null;
    }

    if (loaderPreview) {
      return (
        <div
          className={ styles.loader_wrapper }
        >
          <PageLoader/>
        </div>
      );
    }

    return (
      <Button
        type='textual'
        onClick={ handlePreview }
      >
        <Text
          color='green'
        >
          { LABELS.HEADER.PREVIEW }
        </Text>
      </Button>
    );
  };

  return (
    <div
      className={ styles.wrapper_edit }
    >
      <div>
        <DownloadDialog
          show={ showUpload }
          onClose={ () => setShowUpload(false) }
          onFileAdded={ uploadImage }
          onSave={ handleSetLogo }
        />
        { renderPreviewDialog() }
        <div
          className={ styles.header_wrapper }
        >
          <Text
            type='bold_24'
          >
            { LABELS.HEADER.FORMATION }
          </Text>
          { renderPrewiev() }
        </div>
        <Text
          type='NORMAL_14'
          color='gray'
          className={ styles.text_wrapper }
        >
          { LABELS.HEADER.SETTING }
        </Text>
        <div
          className={ logoWrapper }
        >
          <Text
            type='NORMAL_16'
          >
            { LABELS.LOGO.TITLE }
          </Text>
          <UploadLogoVoucher
            isLogo={ isLogo }
            IsErrorLogo={ IsErrorLogo }
            loadProgress={ loadProgress }
            fileName={ fileName }
            fileSize={ fileSize }
            handleReset={ handleReset }
            setShowUpload={ setShowUpload }
            isSmartAgent={ isSmartAgent }
            renderErrorLogo={ renderErrorLogo }
            renderTooltip={ renderTooltip }
          />
        </div>
        <div
          className={ styles.wrapper_info_edit }
        >
          <Text>
            { LABELS.CONTACT_INFO.TITLE }
          </Text>
          <div>
            <Textarea
              type='opacityPlaceholder'
              noneResize
              value={ valueContactInfo }
              placeholder={ LABELS.CONTACT_INFO.SUBTITLE }
              className={ styles.input_size }
              onChange={ setValueContactInfo }
            />
            { renderError(LABELS.ADDITIONAL_INFO.ERROR, isDisabled(valueContactInfo), valueContactInfo) }
          </div>
          { renderTooltip(LABELS.INFO.TOOLTIP, styles.tooltip_info) }
        </div>
        <div
          className={ styles.wrapper_info_edit }
        >
          <Text>
            { LABELS.ADDITIONAL_INFO.TITLE }
          </Text>
          <div>
            <Textarea
              noneResize
              type='opacityPlaceholder'
              value={ valueAddInfo }
              placeholder={ LABELS.ADDITIONAL_INFO.SUBTITLE }
              className={ styles.input_size }
              onChange={ setValueAddInfo }
            />
            { renderError(LABELS.ADDITIONAL_INFO.ERROR, isDisabled(valueAddInfo), valueAddInfo) }
          </div>
          { renderTooltip(LABELS.INFO.TOOLTIP, styles.tooltip_info) }
        </div>
        <div
          className={ styles.button_save_wrapper_edit }
        >
          <Tooltip
            className={ styles.tooltip_wrapper }
            show={ isDemo }
            clickable={ false }
            renderContent={ () => tooltipSave() }
          >
            <Button
              className={ styles.button_save }
              disabled={ disabledSave }
              onClick={ () => handleSaveVoucher() }
            >
              { LABELS.BUTTON_SAVE }
            </Button>
          </Tooltip>
          <Button
            type='textual'
            className={ styles.button_cancel }
            onClick={ () => handleCancel() }
          >
            { LABELS.BUTTON_CANCEL }
          </Button>
        </div>
      </div>
    </div>
  );
});

export { EditVouchers };
