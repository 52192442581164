import React, { FC } from 'react';
import { Text, Datepicker, RadioButton } from 'new-ui';

import { getText } from '../../../../../i18n';

import { isSmartAgent } from '../../../../bi/utils/env';
import { currentDate, formatDate, momentObject, textualMonthWithHours } from '../../../../bi/utils/formatDate';

import { RouteSegmentsProps } from '../../../../bi/types/vipHall';

import styles from './index.module.css';

const LABELS = {
  DEPARTURE_DATE: getText('components:additionalServices.departureDate'),
};

const RouteSegments: FC<RouteSegmentsProps> = ({
  index,
  defaultRoute,
  selectedRoute,
  onChangeSelectedRoute,
}) => {
  const firstSegment = defaultRoute.Segments[0];
  const lastSegment = defaultRoute.Segments[defaultRoute.Segments.length - 1];

  return (
    <div className={ styles.wrapper }>
      <RadioButton
        className={ styles.checkbox }
        alternativeDesign={ isSmartAgent }
        onChange={ () => onChangeSelectedRoute(index, defaultRoute.Segments) }
        checked={ selectedRoute === index }
      />
      <div className={ styles.flight_info }>
        <Text className={ styles.duration }>
          { `${firstSegment.DepartureCity} (${firstSegment.DepartureAirport.Code}) - ` }
          { `${lastSegment.ArrivalCity} (${lastSegment.ArrivalAirport.Code})` }
        </Text>
        <Text className={ styles.duration } color='gray'>
          { `${formatDate(firstSegment.DepartureDate, textualMonthWithHours)} - ${formatDate(lastSegment.ArrivalDate, textualMonthWithHours)} ` }
        </Text>
      </div>
      <div className={ styles.date }>
        <Datepicker
          wrapperClassName={ styles.wrapper_data }
          inputClassName={ styles.input }
          inputTheme='open'
          withLabel
          placeholder={ LABELS.DEPARTURE_DATE }
          disabled
          value={ momentObject(firstSegment.DepartureDate) }
          onChange={ () => {} }
          min={ currentDate() }
        />
      </div>
    </div>
  );
};

export { RouteSegments };
