import React from 'react';
import { observer } from 'mobx-react';

import { RadioButton } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { RightsDialogWrap } from '../RightsDialogWrap';

import { isSmartAgent } from '../../../../../bi/utils/env';

import { STEPSRIGHTS, FIELDRIGHTS } from '../../../../../bi/constants/rights';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import styles from '../index.module.css';

const LABELS = {
  EDIT: getText('settings:employees.employee.steps.rights.steps.edit.edit'),
  CLOSE: getText('settings:employees.employee.steps.rights.steps.edit.close'),
};

const { employee: { documents: { access: { modals } } } } = QA_ATTRIBUTES;

interface IEditStep {
  active: string,
  header: string,
  rights: {
    edit: string,
    close: string,
  },
  onClose(): void,
  onChangeStep(value: string): void,
  onChangeRights(field: string, value: string): void,
}

const EditStep = observer(({
  active,
  header,
  rights: {
    edit,
    close,
  },
  onClose,
  onChangeStep,
  onChangeRights,
}: IEditStep) => {
  const nextStep = edit ? STEPSRIGHTS.MAIN : STEPSRIGHTS.CLOSE;

  return (
    <RightsDialogWrap
      header={ header }
      theme={ active }
      onClickButton={ () => onChangeStep(nextStep) }
      onClickCancel={ onClose }
      qaAttrButton={ modals.buttonNext }
      qaAttrLink={ modals.edit.buttons.cancel }
    >
      <RadioButton
        checked={ !!edit }
        value={ edit }
        alternativeDesign={ isSmartAgent }
        onChange={ value => onChangeRights(FIELDRIGHTS.EDIT, value) }
        className={ styles.item }
        qaAttr={ modals.edit.radioButtons.edit }
      >
        { LABELS.EDIT }
      </RadioButton>
      <RadioButton
        checked={ !!close }
        value={ close }
        alternativeDesign={ isSmartAgent }
        onChange={ value => onChangeRights(FIELDRIGHTS.CLOSE, value) }
        className={ styles.item }
        qaAttr={ modals.edit.radioButtons.close }
      >
        { LABELS.CLOSE }
      </RadioButton>
    </RightsDialogWrap>
  );
});

export { EditStep };
