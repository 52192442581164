import React, { PropsWithChildren } from 'react';

import { Text } from 'new-ui';

import styles from './index.module.css';

const PromoText = ({ children }: PropsWithChildren) => (
  <Text
    type='NORMAL_16_140'
    className={ styles.text }
  >
    { children }
  </Text>
);

export { PromoText };
