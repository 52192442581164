import React from 'react';
import { Text } from 'new-ui';

import FlightSegmentComponent from './FlightSegmentComponent';

import { prepareTransfersLabel } from '../../../../bi/utils/minimalFares';
import { formatDateTemplate, formatLocationTemplate } from '../../../../bi/utils/formatDate';

import { PATTERN } from '../../../../bi/constants/dateFormats';

import { FlightDetailsProps } from './types';

import styles from './styles/index.module.css';

const FlightDetails = ({
  routeFares,
  fare: { DepartureTime, ArrivalTime, DeparturePlace, ArrivalPlace },
  lastFare: { ArrivalTime: lastArrivalTime, ArrivalPlace: lastArrivalPlace },
  totalDuration,
  isFirstSegment,
  index,
}: FlightDetailsProps) => {
  const renderFirst = () => {
    if (!isFirstSegment) {
      return null;
    }

    return (
      <div className={ styles.flight_item_container_first }>
        <div>
          <div className={ styles.flight_info_details }>
            <div className={ styles.flight_info_item_time }>
              { formatDateTemplate(DepartureTime, lastArrivalTime) }
              <div className={ styles.flight_info_item_duration }>
                {totalDuration}
              </div>
              <Text className={ styles.flight_info_without_transfers }>
                { prepareTransfersLabel(routeFares) }
              </Text>
            </div>
            <Text
              color='gray'
              className={ styles.flight_info_item }
            >
              { formatLocationTemplate(DeparturePlace, lastArrivalPlace) }
            </Text>
            <Text
              color='gray'
              className={ styles.flight_info_item }
            >
              { formatDateTemplate(DepartureTime, lastArrivalTime, PATTERN.DATEWITHWEEKDAY) }
            </Text>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={ styles.air_item_container }>
      {renderFirst()}
      <div>
        <FlightSegmentComponent
          index={ index }
          departureTime={ DepartureTime }
          arrivalTime={ ArrivalTime }
          departurePlace={ DeparturePlace }
          arrivalPlace={ ArrivalPlace }
          isFirstSegment={ isFirstSegment }
        />
      </div>
    </div>
  );
};

export default FlightDetails;
