import { getText } from '../../../../i18n/index';

import { ADDITIONAL_TYPES } from '../../constants/airlineAdditionalServices';
import {
  FIELDS_DIRECTION,
  SORT_TYPES,
  FLIGHT_TYPE_FORMAT,
  OPTIONS_TYPES,
} from '../../constants/vipHall';

import { formatDate, fullFormYear } from '../../utils/formatDate';
import { prepareDataFlight } from '../../utils/vipHall';

import { IVipHallSearchStore, VipHallSearchStore } from './store/search';
import { IVipHallStore, VipHallStore } from './store/vipHall';

import {
  IOptionCategories,
  AirportsListType,
  Segment,
  AdditionalServices,
  AdditionalsForUpdateType,
  TicketActualVersionType,
} from './types';

const CATEGORY_TYPES = {
  VIP: 'vip',
  ASSIST: 'assist',
};

const LABELS = {
  NEED_PAYMENT: getText('vipHall:item.needPayment'),
};

class VipHall {
  api: any;
  xhr: any;
  searchStore: IVipHallSearchStore;
  resultStore: IVipHallStore;

  constructor(api: any) {
    this.api = api.vipHall;
    this.xhr = null;

    this.searchStore = VipHallSearchStore;
    this.resultStore = VipHallStore;
  }

  searchByGuid = async (guid: string, terminal: string) => {
    this.resultStore.setLoading(true);

    const paramsMain = {
      sort_type: SORT_TYPES.BY_DESCENDING,
      uuid: guid,
    };

    const params = terminal ? { ...paramsMain, selected_terminal: terminal } : { ...paramsMain };

    try {
      const { response, uuid } = await this.api.search(params);

      this.resultStore.setItems(response[0].services);
      this.resultStore.setMainInfo(response[0].item);

      this.resultStore.setLoading(false);

      return uuid;
    } catch (error) {
      this.resultStore.setLoading(false);
    }

    return null;
  };

  getInfoVipHall = async (id: string, date: string | null = '') => {
    this.resultStore.setLoading(true);

    const params = { id, date };

    try {
      const data = await this.api.getInfoVipHall(params);

      this.resultStore.setChoosenVipHall(data);
      this.resultStore.setLoading(false);
    } catch (error) {
      this.resultStore.setLoading(false);
    }
  };

  getAvailableAirports = async () => {
    const data = await this.api.getAvailableAirports();

    this.searchStore.setAvailableAirports(data);
  };

  getTextWarning = async () => {
    const data = await this.api.getTextWarning();

    this.searchStore.setTextTemplateWarning(data?.content?.text || '');
  };

  setNewSearch = (): void => {
    this.resultStore.resetStore();
    this.searchStore.resetStore();
  };

  setPagingItems = (paging: number, items: AirportsListType[]) => {
    this.resultStore.setPagingItems(paging, items);
  };

  changeAdditionalService = (service: AdditionalServices) => {
    this.resultStore.changeAdditionalService(service);
  };

  changeAdditionalCount = (index: number, count: number) => {
    this.resultStore.changeAdditionalCount(index, count);
  };

  updateCost = async (birthDate: string[]) => {
    const { additionalServices, choosenVipHall } = this.resultStore;

    const additionals = additionalServices.map(({ type, billing, capacity, count }: AdditionalsForUpdateType) => ({
      type,
      billing,
      capacity,
      quantity: count,
    }));

    const params = {
      json_data: JSON.stringify(choosenVipHall),
      dates_of_birth: birthDate,
      additional_services: additionals,
    };

    try {
      const cost = await this.api.updateCost(params);

      this.resultStore.updateCost(cost);
    } catch (error) {}
  };

  getTerminalsAndVips = async (selectedItem: number, type: string, selectedTypeFlight: string) => {
    const { route } = this.searchStore;

    const {
      DepartureAirport,
      ArrivalAirport,
      DepartureDate,
      ArrivalDate,
      DepartureTerminal,
      ArrivalTerminal,
      DepartureCountry,
      ArrivalCountry,
    } = route[selectedItem];

    this.resultStore.setLoading(true);

    const items = [
      {
        iata: DepartureAirport.Code,
        date: formatDate(DepartureDate, fullFormYear),
        type: selectedTypeFlight === FIELDS_DIRECTION.TRANSIT ? FIELDS_DIRECTION.TRANSIT : FIELDS_DIRECTION.DEPARTURE,
        terminal: DepartureTerminal,
        country: DepartureCountry,
      },
      {
        iata: ArrivalAirport.Code,
        date: formatDate(ArrivalDate, fullFormYear),
        type: selectedTypeFlight === FIELDS_DIRECTION.TRANSIT ? FIELDS_DIRECTION.TRANSIT : FIELDS_DIRECTION.ARRIVAL,
        terminal: ArrivalTerminal,
        country: ArrivalCountry,
      },
    ];

    const params = {
      sort_type: SORT_TYPES.BY_DESCENDING,
      category: type === ADDITIONAL_TYPES.VIP_HALL
        ? CATEGORY_TYPES.VIP : CATEGORY_TYPES.ASSIST,
      items,
      selected_iata: selectedTypeFlight === FIELDS_DIRECTION.DEPARTURE || selectedTypeFlight === FIELDS_DIRECTION.TRANSIT
        ? DepartureAirport.Code : ArrivalAirport.Code,
    };

    let id = '';
    let terminal;

    try {
      const result = await this.api.search(params);
      const { response, uuid } = result;

      id = uuid;

      if (!response[0].item.terminal_id) {
        this.searchStore.setTerminals(response[0], uuid);

        const listTerminals = response[0].services.map(({ terminal_name }: { terminal_name: string }) => terminal_name);

        if (listTerminals.length === 1) {
          terminal = [...new Set(listTerminals)][0];
        }
      }

      this.resultStore.setItems(response[0].services);
      this.resultStore.setMainInfo(response[0].item);

      this.resultStore.setLoading(false);
    } catch (error) {
      this.resultStore.setLoading(false);
    }

    return { id, terminal: terminal || '' };
  };

  filterItems = (type: string) => this.resultStore.filterItems(type);

  setSelectedRoute = (value: Segment[]) => this.searchStore.setSelectedRoute(value);

  setSelectedTerminal = (value: string) => this.searchStore.setSelectedTerminal(value);

  recalculatePrice = async (id: string, birthDate: string) => {
    const { additionalServices, choosenVipHall, newCost } = this.resultStore;
    const { one_passenger_cost } = choosenVipHall;

    const params = {
      hallId: id,
      total_price: newCost || one_passenger_cost,
      calculation_parameters: {
        dates_of_birth: birthDate,
        additional_services: additionalServices.map(({ type, billing, capacity, count }: AdditionalsForUpdateType) => ({
          type,
          billing,
          capacity,
          quantity: count,
        })),
        json_data: '',
      },
    };

    try {
      const res = await this.api.recalculatePrice(params);

      this.resultStore.changeCost(res.TotalPrice);

      return res;
    } catch (err) {
      return { AllowedBooking: false };
    }
  };

  confirmPayment = async (
    id: string,
    ticket: TicketActualVersionType,
    route: number,
    tripId: number,
    ticketId: number,
    segment: number,
  ) => {
    const { items, choosenVipHall, additionalServices, mainInfo, newCost } = this.resultStore;
    const {
      name: vipHallName,
      option_categories,
      hours_to_stay,
      parking_info_required,
      passport_data_required,
      ticket_class_required,
      latin_names_required,
      citizenship_required,
      opening_hours,
      location,
      detailed_description,
      description,
    } = choosenVipHall;

    const ticketData = JSON.parse(ticket.JsonData);
    const iataVipHallInfo = items.find(({ hallId }: { hallId: string }) => hallId === id);
    const segments = ticketData.Routes[route].Segments;
    const segmentItem = ticketData.Routes[route].Segments[segment];

    const additionals = additionalServices.map(({ name, type, billing, capacity, count, cost }: any) => (
      { name, type, billing, capacity, quantity: count, price: cost }
    ));

    const additionalInfo = option_categories.map(({ name, included }: IOptionCategories) =>
      `${name}${included ? '' : ` - ${LABELS.NEED_PAYMENT}`}`);

    const helpWithLuggage = option_categories.find(({ type }) => type === OPTIONS_TYPES.LUGGAGE)?.DetailDescription || '';

    const props = {
      hallId: id,
      name: vipHallName,
      totalPrice: newCost,
      timeToPenalty: iataVipHallInfo?.time_to_penalty,
      tripType: iataVipHallInfo?.flightType || FLIGHT_TYPE_FORMAT.DOMESTIC.toLowerCase(),
      flight: mainInfo.type === FIELDS_DIRECTION.TRANSIT ?
        prepareDataFlight(segments, iataVipHallInfo, mainInfo, segment) :
        prepareDataFlight([segmentItem], iataVipHallInfo, mainInfo, segment),
      employeeIds: [ticket.Employees[0].Id],
      additionalInfo,
      additionalServices: additionals,
      ticketClassRequired: ticket_class_required,
      citizenshipRequired: citizenship_required,
      parkingInfoRequired: parking_info_required,
      latinNamesRequired: latin_names_required,
      passportDataRequired: passport_data_required,
      departureDate: segmentItem.DepartureDate,
      arrivalDate: segmentItem.ArrivalDate,
      minPossibleTime: iataVipHallInfo?.min_possible_time,
      hoursToStay: hours_to_stay,
      openingHoursDescription: opening_hours,
      locationDescription: location || description,
      registrationProcedureDescription: detailed_description,
      helpWithLuggageDescription: helpWithLuggage,
    };

    this.resultStore.setLoading(true);

    try {
      if (tripId) {
        const { Success, Error, TripItemId } = await this.api.confirmPaymentInMicroserviceWithProjectId(tripId, ticketId, props);

        this.resultStore.setLoading(false);

        return { Success, TripItemId, Error };
      }

      const { Success, Error, TripItemId } = await this.api.confirmPaymentInMicroservice(ticketId, props);

      this.resultStore.setLoading(false);

      return { Success, TripItemId, Error };
    } catch (error) {
      this.resultStore.setLoading(false);
    }

    return false;
  };
}

export default VipHall;
