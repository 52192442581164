import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Text, Tooltip, PageLoader } from 'new-ui';
import { getText } from '../../../../../i18n';

import { NumberInput } from '../../../../components/NumberInput';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import TEXTS from '../../../../bi/constants/texts';

import { IExpenseReportsPage } from '../../types';

import styles from './index.module.css';

const LABELS = {
  HEADER: getText('settings:expenseReports.title'),
  SUB_HEADER: getText('settings:expenseReports.subTitle'),
  RU: getText('settings:expenseReports.ruTrip'),
  ABROAD: getText('settings:expenseReports.abroad'),
  CHECKBOX: getText('settings:expenseReports.allowEmployeesEdit'),
  SAVE: getText('common:save'),
};

@observer
class ExpenseReports extends Component<IExpenseReportsPage> {
  componentDidMount = () => {
    this.props.expenseReportsService.loadSettingsExpense();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.EXPENSE_REPORTS_OPEN);
  };

  componentWillUnmount() {
    this.props.expenseReportsService.resetStoreSettings();
  }

  handleUpdateRewritable = (value: boolean) => {
    const { expenseReportsService: { updateRewritable } } = this.props;

    if (value) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.EXPENSE_REPORTS_CHECKBOX);
    }

    updateRewritable(value);
  };

  handleSaveSettings = () => {
    const { expenseReportsService: { saveSettings } } = this.props;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.EXPENSE_REPORTS_SAVE_BUTTON);
    saveSettings();
  };

  renderTooltip = () => (
    <Text
      color='white'
      type='NORMAL_14_130'
      className={ styles.tooltip_box }
    >
      {TEXTS.NOT_FOR_DEMO}
    </Text>
  );

  render() {
    const {
      expenseReportsService: {
        updateRuExpense,
        updateAbroadExpense,
      },
      settingsExpenseStore: {
        loading,
        loadingButton,
        isValid,
        sources: {
          RuDailyExpenses,
          OverseasDailyExpenses,
          Rewritable,
        },
      },
      workspaceService: {
        isDemo,
      },
    } = this.props;

    if (loading) {
      return <PageLoader />;
    }

    return (
      <div className={ styles.wrapper }>
        <Text type='bold_24'>
          { LABELS.HEADER }
        </Text>
        <Text type='bold_18' className={ styles.sub_text }>
          { LABELS.SUB_HEADER }
        </Text>
        <div className={ styles.inputs }>
          <div className={ styles.col_1 }>
            <Text color='gray' className={ styles.labels }>
              { LABELS.RU }
            </Text>
            <NumberInput
              className={ styles.input }
              onChange={ updateRuExpense }
              value={ String(RuDailyExpenses) }
            />
          </div>
          <div>
            <Text color='gray' className={ styles.labels }>
              { LABELS.ABROAD }
            </Text>
            <NumberInput
              className={ styles.input }
              onChange={ updateAbroadExpense }
              value={ String(OverseasDailyExpenses) }
            />
          </div>
        </div>
        <Checkbox
          value={ Rewritable }
          onChange={ this.handleUpdateRewritable }
        >
          { LABELS.CHECKBOX }
        </Checkbox>
        <Tooltip
          show={ isDemo }
          className={ styles.button }
          renderContent={ this.renderTooltip }
        >
          <Button
            loading={ loadingButton }
            disabled={ !isValid || isDemo }
            onClick={ this.handleSaveSettings }
          >
            { LABELS.SAVE }
          </Button>
        </Tooltip>
      </div>
    );
  }
}

export default ExpenseReports;
