import React from 'react';
import { observer } from 'mobx-react';

import SidePanel from './index';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import SidePanelService from '../../../../bi/services/sidePanel';

interface SidePanelWrapperProps {
  sidePanelService: SidePanelService,
}

const SidePanelWrapper = observer(({ sidePanelService }: SidePanelWrapperProps) => {
  const { sidePanelStore } = useStores([MOBX_STORES.SIDE_PANEL]);

  const { show, renderFn } = sidePanelStore;

  return (
    <SidePanel
      show={ show }
      onClose={ () => sidePanelService.setShow(false) }
    >
      { renderFn() }
    </SidePanel>
  );
});

export { SidePanelWrapper };
