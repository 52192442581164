import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { PageLoader } from 'new-ui';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import { SubSectionsOfTable } from './SubSectionsOfTable';

const PageSubSectionsOfTable = observer(({
  chartsAnalyticsService,
  onOpen,
  tooltip,
}: {
  chartsAnalyticsService: ChartsAnalytics,
  onOpen(subPageInfo: any, servType: string): void,
  tooltip: string,
}) => {
  const {
    chartsAnalyticsSmartSummaryStore,
    chartsAnalyticsSmartSummaryStore: {
      loading,
      type,
      subSmartType,
    },
  } = useStores([
    MOBX_STORES.CHARTS_ANALYTICS_SMART_SUMMARY_STORE,
  ]);

  const {
    getSmartServiceType,
  } = chartsAnalyticsService;

  useEffect(() => {
    getSmartServiceType();
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <SubSectionsOfTable
      chartsAnalyticsService={ chartsAnalyticsService }
      store={ chartsAnalyticsSmartSummaryStore }
      onOpen={ onOpen }
      serviceType={ type }
      tooltip={ tooltip }
      type={ subSmartType }
    />
  );
});

export { PageSubSectionsOfTable };
