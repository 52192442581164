import React, { useState, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { api } from '../../bi/apiV2';

import { getText } from '../../../i18n';

import { ApplicationSchemePage } from './SchemeComponent';

import ROUTES from '../../bi/constants/routes';

import { MainAnalytic } from '../../bi/utils/analytics';

import { ErrorState } from './types';

const LABELS = {
  CREATE_SCHEME: getText('applicationScheme:createScheme'),
};

const ApplicationSchemeCreate = () => {
  const [error, setError] = useState<ErrorState>({ show: false, code: null });
  const history = useHistory();

  const goBack = () => history.push(ROUTES.SETTINGS.APPLICATION_SCHEME);

  const handleChangeError: (newError: ErrorState) => void = (newError) => setError(newError);

  useLayoutEffect(() => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_OPENED_CREATE_PAGE);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_OPENED_CREATE_PAGE, {
      schemeSource: 'new',
    });
  }, []);

  const createScheme = useMutation({
    mutationFn: (templateScheme) => api.applicationScheme.createScheme(templateScheme),
    onSuccess: goBack,
    onError: (err: any) => {
      const errorCode = err.response.status;

      setError({ show: true, code: errorCode });
    },
  });

  return (
    <ApplicationSchemePage
      onSubmit={ createScheme }
      header={ LABELS.CREATE_SCHEME }
      goBack={ goBack }
      handleChangeError={ handleChangeError }
      error={ error }
      isNew
    />
  );
};

export { ApplicationSchemeCreate };
