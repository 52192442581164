import React from 'react';
import { Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import { firstCapitalLetter } from '../../../../bi/utils/strings';

import { MAPCARCLASS_RU, MAPCARCLASS } from '../../../../bi/constants/transfer';

import { ITransferItem } from '../../../../bi/types/favorites';

import styles from './style.module.css';

const LABELS = {
  COUNT_OF_PLACES: getText('favorites:countOfSeats'),
  STREET: getText('favorites:street'),
};

interface TransferItemProps {
  favorite: {
    Item: ITransferItem,
  },
}

const preparedInfo = (carClass: string, capacity: number) =>
  `${firstCapitalLetter(MAPCARCLASS_RU[carClass])}, ${LABELS.COUNT_OF_PLACES} ${capacity}`;

const TransferItem = ({
  favorite: {
    Item: {
      Places: {
        StartPlace,
        FinishPlace,
      },
      Class,
      Capacity,
    },
  },
}: TransferItemProps) => (
  <div className={ styles.wrapper }>
    <div className={ styles.place }>
      <Text type='SEMIBOLD_16'>
        { StartPlace.Address }
      </Text>
      <Text type='NORMAL_14' color='gray' className={ styles.count }>
        { preparedInfo(Class, Capacity) }
      </Text>
    </div>
    <div className={ styles.transfer }>
      <img
        src={ `/images/newTransfer/${MAPCARCLASS[Class as keyof typeof MAPCARCLASS]}.png` }
        alt={ Class }
        className={ styles['transfer-img'] }
      />
    </div>
    <div className={ styles.place }>
      <Text type='SEMIBOLD_16'>
        { FinishPlace.Address }
      </Text>
    </div>
  </div>
);

export default TransferItem;
