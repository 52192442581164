import React, { FC, ReactNode } from 'react';
import { Input, Select, IconType } from 'new-ui';

import { getText } from '../../../../../i18n';

import { isSmartAgent } from '../../../../bi/utils/env';

import { FILTERS_TYPES } from '../../../../bi/constants/note';

import { INoteFilters } from '../../../../bi/services/notepad/types';

import { Tab } from '../../../../bi/types/shared';

import styles from './index.module.css';

const LABELS = {
  FAST_SEARCH: getText('note:search.fastSearch'),
};

type Item = {
  label: string,
  value: number | string,
};

interface AdditionalSearchProps {
  filters: INoteFilters
  renderItem(value: Item, selectorType: string): ReactNode,
  onInputChange(searchValue: string): void,
  onChangeFilter(value: Tab): void,
  onBlurFastSearch(label: string, selectedCount: number): void,
  onChangeNoteTypeFilter(type: string): void,
  renderIconByNoteType(type: string, isHtml?: boolean): ReactNode | IconType,
  renderIconByServiceType(type: string, isHtml?: boolean): ReactNode | IconType,
}

const AdditionalSearch: FC<AdditionalSearchProps> = ({
  filters,
  renderItem,
  onInputChange,
  onChangeFilter,
  onBlurFastSearch,
  onChangeNoteTypeFilter,
  renderIconByNoteType,
  renderIconByServiceType,
}) => {
  const { type, noteType, query, selectedCount } = filters;

  return (
    <div className={ styles.wrapper }>
      <div className={ styles['search-content'] }>
        <div className={ styles.search }>
          <Input
            className={ styles.input }
            onChange={ onInputChange }
            value={ query }
            isCleansing
            placeholder={ LABELS.FAST_SEARCH }
            onBlur={ () => onBlurFastSearch(LABELS.FAST_SEARCH, selectedCount) }
          />
        </div>
        <div className={ styles.actions }>
          <div className={ `${styles.action} ${styles.emoloyees}` }>
            <Select
              icon={ renderIconByNoteType(noteType.current as string) as IconType }
              value={ noteType.current as string }
              items={ noteType.tabs }
              theme='default-small'
              className={ styles.space }
              alternativeDesign={ isSmartAgent }
              onChange={ onChangeNoteTypeFilter }
              renderItem={ (value) => renderItem(value, FILTERS_TYPES.NOTE) }
            />
          </div>
          <div className={ `${styles.action} ${styles.types}` }>
            <Select
              icon={ renderIconByServiceType(type.current as string) as IconType }
              value={ type.current as string }
              items={ type.tabs }
              theme='default-small'
              className={ styles.space }
              alternativeDesign={ isSmartAgent }
                // @ts-ignore
              onChange={ onChangeFilter }
              renderItem={ (item) => renderItem(item, FILTERS_TYPES.SERVICE) }
              contentWithImage
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalSearch;
