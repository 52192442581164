import React from 'react';
import { Button, Dialog, Text } from 'new-ui';
import { getText } from '../../../../../../../i18n';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('settings:integration.errorDialog.title'),
  OK: getText('settings:integration.errorDialog.ok'),
};

interface IErrorDialog {
  show: boolean,
  onClick(): void,
  message: string,
}

const ErrorDialog = ({
  show,
  onClick,
  message,
}: IErrorDialog) => (
  <Dialog
    show={ show }
    onChange={ onClick }
  >
    <>
      <Text
        type='bold_24'
        className={ styles.title }
      >
        { LABELS.TITLE }
      </Text>
      <Text>
        { message }
      </Text>
      <Button
        type='secondary'
        className={ styles.button }
        onClick={ onClick }
      >
        { LABELS.OK }
      </Button>
    </>
  </Dialog>
);

export { ErrorDialog };
