import { Moment } from 'moment';

import { OrderItem } from '../types/order';

import { momentObject } from './formatDate';

const getTripDateBorders = (items: OrderItem[]) => {
  let min: Moment | null = null;
  let max: Moment | null = null;

  items.forEach(({ ActualVersion: { CheckinDate, CheckoutDate } }) => {
    const checkin = momentObject(CheckinDate);
    const checkout = momentObject(CheckoutDate);

    if (min === null || min > checkin) {
      min = checkin;
    }

    if (max === null || max < checkout) {
      max = checkout;
    }
  });

  return { min, max };
};

export default getTripDateBorders;
