import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import scrollToTop from '../../../../../utils/scrollToTop';

import { TrainNestedTable } from '../../../common/trainNestedTable';

const TransferDirectionsSecondPage = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => {
  const {
    chartsAnalyticsStore: { filtersType, filters: { employees, departments, projects, analytics, tags } },
    chartsAnalyticsSummaryStore,
  } = useStores([MOBX_STORES.CHARTS_ANALYTICS_STORE, MOBX_STORES.CHARTS_ANALYTICS_SUMMARY_STORE]);

  const {
    getTransferDirectionsSubPage,
    changePageTransferDirections,
  } = chartsAnalyticsService;

  useEffect(() => {
    getTransferDirectionsSubPage();
  }, [
    getTransferDirectionsSubPage,
    employees.selected,
    departments.selected,
    projects.selected,
    analytics.selected,
    tags.selected,
    filtersType,
  ]);

  const handlePageChange = (page: number) => changePageTransferDirections(page).then(() => scrollToTop());

  return (
    <TrainNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );
});

export { TransferDirectionsSecondPage };
