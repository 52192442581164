import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useServices } from '../../../../bi/context/services';

import styles from './styles.module.css';

const LABELS = {
  TEXT: getText('air:result.cpsWarning'),
};

export const MessageCPS = () => {
  const { featureFlagsService } = useServices(['FeatureFlags']);
  const hide = !featureFlagsService.getPlateForGazpromCPS();

  if (hide) return null;

  return (
    <div className={ styles.container }>
      <Text type='normal_20'>
        { LABELS.TEXT }
      </Text>
    </div>
  );
};
