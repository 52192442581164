import React, { FC, ReactElement } from 'react';

import { Text, Tooltip } from 'new-ui';

import { formatDate, defaultPattern } from '../../../../bi/utils/formatDate';

import COUNTRIES from '../../../../bi/constants/countries';
import PASSPORTS from '../../../../bi/constants/passport';
import { DOCUMENTS_TYPE } from '../../../../bi/constants/employee';

import { ICitizenship } from '../../../../bi/types/userSession';
import type { PassportsType } from '../../../../bi/constants/passport';

import styles from '../../index.module.css';

interface TravelersTableRowProps {
  employees: [
    {
      Id:string,
      Surname:string,
      Name:string,
      Patronymic:string,
      Birthday:string,
      MobilePhone:string,
      Email:string,
      Code:string,
      Tags: [{
        Id: number,
        Value: string,
      }],
      Documents: { Type: string, Id: number }[]
    },
  ];
  enumsCitizenship: ICitizenship[],
  anchorPrefix: string,
  onEditEmployee: (Id:string) => void,
}

const DOCUMENT_COUNT = 3;

const TravelersTableRow: FC<TravelersTableRowProps> = ({
  employees,
  enumsCitizenship,
  anchorPrefix,
  onEditEmployee,
}: TravelersTableRowProps) => {
  const content = employees.map(({ Surname, Name, Patronymic, Id, Tags, Code, Birthday, Documents, MobilePhone, Email }) => {
    const prefixId = `${anchorPrefix}${(Surname || ' ')[0]}`;
    const fullName = `${Surname} ${Name} ${Patronymic} `;
    const renderBirthday = formatDate(Birthday, defaultPattern);

    const renderTags = Tags.map((tag: { Value: string }) => tag.Value).join(', ');
    const renderCitizenship = enumsCitizenship.find((item) => Code === item.Code)?.Name;

    const isFroeign = (type: string) => (PASSPORTS.DOMESTIC_PASSPORT.TYPE === type
      ? PASSPORTS.FOREIGNER_DOMESTIC_PASSPORT.VALUE
      : PASSPORTS[DOCUMENTS_TYPE[type] as keyof PassportsType].VALUE);

    const choisDocument = (type: string) => (Code === COUNTRIES.RU.SHORTNAME && type === PASSPORTS.DOMESTIC_PASSPORT.TYPE
      ? PASSPORTS.RU_PASSPORT.VALUE
      : isFroeign(type));

    const documentHtml = (type: string, passportNumber: number | string = '') => (
      <Text className={ styles.tooltip_text } color='white'>
        { `${choisDocument(type)} ${passportNumber}` }
      </Text>
    );

    const renderTooltipContent = () => Documents.map((document:{ Type: string, Id: number }, index) => {
      if (index === 0) return null;

      const tooltipContent = (documentElement: ReactElement) => (
        <div className={ styles.tooltip } key={ Id + index }>
          {documentElement}
        </div>
      );

      if (Documents.length >= DOCUMENT_COUNT) return (tooltipContent(documentHtml(document.Type, index)));

      return (tooltipContent(documentHtml(document.Type)));
    });

    const renderPassport = () => {
      if (!Documents || !Documents.length) return <div className={ styles['col-4'] }/>;

      const renedrMultiDocuments = Documents.length > 1 && (
        <Tooltip
          className={ styles.additional_documents }
          renderContent={ () => renderTooltipContent() }
        >
          <Text color='blue'>
            +{Documents.length - 1}
          </Text>
        </Tooltip>
      );

      return (
        <div className={ styles['col-4'] } key={ Documents[0].Id }>
          <Text className={ styles.document }>
            { choisDocument(Documents[0].Type)}
          </Text>
          {renedrMultiDocuments}
        </div>
      );
    };

    return (
      <div
        className={ `${styles.row} ${styles.row_content}` }
        key={ Id }
        onClick={ () => onEditEmployee(Id) }
      >
        <div className={ styles.anchor } id={ prefixId } />
        <div className={ styles['col-1'] }>
          <div>
            <Text className={ styles.no_wrap }>
              {fullName}
            </Text>
          </div>
          <div className={ styles.tags }>
            <Text className={ styles.no_wrap } type='NORMAL_14' color='gray'>
              {renderTags}
            </Text>
          </div>
        </div>
        <div className={ styles['col-2'] }>
          <Text className={ styles.no_wrap }>
            {renderBirthday}
          </Text>
        </div>
        <div className={ styles['col-3'] }>
          <Text className={ styles.no_wrap }>
            {renderCitizenship}
          </Text>
        </div>
        {renderPassport()}
        <div className={ styles['col-5'] }>
          <Text className={ styles.no_wrap }>
            {MobilePhone}
          </Text>
        </div>
        <div className={ styles['col-6'] }>
          <Text className={ styles.no_wrap }>
            {Email}
          </Text>
        </div>
        <div className={ styles.line }/>
        <div className={ styles.fake_line }/>
      </div>
    );
  });

  return (
    <div>
      {content}
    </div>
  );
};

export { TravelersTableRow };
