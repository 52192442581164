import React, { useEffect, useState } from 'react';
import { BackLink, Button, Icon, Progress, StyledWrapper, Text, Checkbox, Input, RadioButton, PageLoader } from 'new-ui';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';

import { api } from '../../bi/apiV2';

import { getText } from '../../../i18n';

import {
  initCheckboxValues,
  initSelectedValue,
  makeDataForMainAnalyticsQuiz,
  analyticsQuizBackData,
} from '../../bi/utils/travelPolicyQuiz';
import { MainAnalytic } from '../../bi/utils/analytics';

import { QuizUserChoises } from '../../bi/types/travelPolicyQuiz';

import ROUTES from '../../bi/constants/routes';

import styles from './styles.module.css';

const LABELS = {
  BACKLINK: getText('travelPolicyQuiz:backlink'),
  TITLE: getText('travelPolicyQuiz:title'),
  BUTTONS: {
    BACK: getText('travelPolicyQuiz:buttons.back'),
    NEXT: getText('travelPolicyQuiz:buttons.next'),
  },
  INPUT: {
    PLACEHOLDER: getText('travelPolicyQuiz:input.placeholder'),
  },
  PAGE_LOADER: getText('travelPolicyQuiz:pageLoader'),
  ERROR: {
    MAX_VALUE: (value: number) => getText('travelPolicyQuiz:error.maxValue', { value }),
    CHOOSE_ANSWER: getText('travelPolicyQuiz:error.chooseAnswer'),
    INPUT_VALUE: getText('travelPolicyQuiz:error.inputValue'),
  },
};

const MAX_VALUE = 1000000;

const useTravelPolicyQuestions = (questionNumber: number) => useQuery({
  queryKey: ['travelPolicyQuizQuestions', questionNumber],
  queryFn: () => api.travelPolicyQuiz.getQuestion(questionNumber),
});

const TravelPolicyQuiz = () => {
  const { id } = useParams<{ id: string }>();
  const questionNumber = Number(id);
  const [checkBoxValues, setCheckBoxValues] = useState<string[]>([]);
  const [selectValue, setSelectValue] = useState<string>('');
  const [customValue, setCustomValue] = useState<string>();
  const [errorMessage, setErrorMessage] = useState('');

  const history = useHistory();
  const { data, isFetching } = useTravelPolicyQuestions(questionNumber);

  const setDefaultState = () => {
    setErrorMessage('');
    setCheckBoxValues([]);
    setSelectValue('');
    setCustomValue('');
  };

  const handleSubmitMutation = useMutation({
    mutationFn: (userChoises: QuizUserChoises) => api.travelPolicyQuiz.setQuestionData(userChoises),
    onSuccess: () => {
      if (data?.type === 7) {
        history.push(ROUTES.TRAVEL_POLICY_UNIQUE);
      } else {
        history.push(`/travel-policy-quiz/${questionNumber + 1}`);
      }
    },
  });

  useEffect(() => {
    const initSelectValue = data && data.answersCondition === 'OnlyOne' ? initSelectedValue(data, 'type') : '';
    const initCustomValue = data && data.answersCondition === 'OnlyOne' ? initSelectedValue(data, 'selectedManualValue') : '';

    setCheckBoxValues(data ? initCheckboxValues(data) : []);
    setSelectValue(initSelectValue);
    setCustomValue(initCustomValue);
  }, [data]);

  if (!data) return <PageLoader text={ LABELS.PAGE_LOADER } />;

  const progressValue = data.type / data.questionsCount;

  const handleGoBack = async () => {
    const preparedAnalyticsData = analyticsQuizBackData(data.type);

    if (preparedAnalyticsData) {
      MainAnalytic.sendFirebase(preparedAnalyticsData);
    }

    if (data.type === 1) return history.push('/');

    setDefaultState();

    return history.push(`/travel-policy-quiz/${questionNumber - 1}`);
  };

  const handleCheckboxChange = (checked: boolean, answerType: string) => {
    setErrorMessage('');

    if (checked) {
      return setCheckBoxValues(prev => ([...prev, answerType]));
    }

    return setCheckBoxValues(prev => prev.filter(type => type !== answerType));
  };

  const handleChangeInput = (value: string, type: string) => {
    if (Number(value) >= MAX_VALUE) {
      setErrorMessage(LABELS.ERROR.MAX_VALUE(MAX_VALUE));
    } else {
      setErrorMessage('');
    }

    const newValue = value.replace(/[^0-9]/g, '');

    if (newValue.length) {
      setCustomValue(newValue);
      setSelectValue(type);
    } else {
      setCustomValue('');
      setSelectValue('');
    }
  };

  const handleSelectValue = (value: string) => {
    setSelectValue(value);
    setErrorMessage('');
  };

  const redirectToSmartdesk = () => {
    history.push(ROUTES.SMARTDESK);
  };

  const handleSubmit = () => {
    if (selectValue === 'Custom') {
      if (Number(customValue) >= MAX_VALUE) {
        return setErrorMessage(LABELS.ERROR.MAX_VALUE(MAX_VALUE));
      }

      if (!Number(customValue)) {
        return setErrorMessage(LABELS.ERROR.INPUT_VALUE);
      }
    }

    if (errorMessage.length) return null;

    if (!checkBoxValues.length && !selectValue.length && !customValue?.length) {
      return setErrorMessage(LABELS.ERROR.CHOOSE_ANSWER);
    }

    const value = data.answersCondition === 'OnlyOne' ? [selectValue] : checkBoxValues;
    const manualValue = customValue || null;

    const preparedAnalyticsData = makeDataForMainAnalyticsQuiz(data.type, value, manualValue);

    if (preparedAnalyticsData) {
      MainAnalytic.sendFirebase(preparedAnalyticsData.name, { ...preparedAnalyticsData.data });
    }

    return handleSubmitMutation.mutate({
      Type: data!.type,
      Answer: {
        Values: value,
        ManualValue: manualValue,
      },
    });
  };

  const renderAnswers = () => {
    if (data.answersCondition === 'OnlyOne') {
      return data.answers.map(({ text, type }) => {
        const radioButtonData = type === 'Custom' ? (
          <Input
            value={ customValue }
            onChange={ (value: string) => handleChangeInput(value, type) }
            className={ styles.input }
            placeholder={ LABELS.INPUT.PLACEHOLDER }
          />
        ) : <Text type='NORMAL_18'>{ text }</Text>;

        return (
          <RadioButton
            key={ type }
            checked={ selectValue === type }
            onChange={ (value: string) => handleSelectValue(value) }
            value={ type }
            className={ styles['radio-button'] }
          >
            { radioButtonData }
          </RadioButton>
        );
      });
    }

    return data.answers.map(({ text, type }) => (
      <Checkbox
        key={ type }
        value={ checkBoxValues.includes(type) }
        onChange={ (value: boolean) => handleCheckboxChange(value, type) }
        className={ styles['checkbox-wrapper'] }
        contentClassName={ styles['checkbox-value'] }
      >
        <Text type='NORMAL_18'>{ text }</Text>
      </Checkbox>
    ));
  };

  const error = errorMessage && <Text color='red' className={ styles['error-message'] }>{errorMessage}</Text>;

  return (
    <div className={ styles.wrapper }>
      <BackLink
        text={ LABELS.BACKLINK }
        link={ ROUTES.SMARTDESK }
        onClick={ redirectToSmartdesk }
      />

      <StyledWrapper className={ styles['quiz-wrapper'] }>
        <div className={ styles.header }>
          <div className={ styles.left }>
            <Icon type='quiz' />
            <Text type='normal_20' className={ styles.label }>{ LABELS.TITLE }</Text>
          </div>
          <div className={ styles.right }>
            <div className={ styles['status-bar'] }>
              <Progress value={ progressValue } rounded />
            </div>
            { data.type }/{ data.questionsCount }
          </div>
        </div>

        <Text type='bold_20_130' className={ styles.title }>{ data.text }</Text>

        <div className={ styles.answers }>
          { renderAnswers() }
        </div>

        { error }

        <div className={ styles.buttons }>
          <Button type='secondary-outline' className={ styles.back } onClick={ handleGoBack }>{ LABELS.BUTTONS.BACK }</Button>
          <Button
            type='secondary'
            className={ styles.next }
            onClick={ () => handleSubmit() }
            loading={ isFetching }
          >
            { LABELS.BUTTONS.NEXT }
          </Button>
        </div>
      </StyledWrapper>
    </div>
  );
};

export { TravelPolicyQuiz };
