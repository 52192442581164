import { RouteInterface } from './changeAirTrip/types';

const createFromToAirTicket = ({ Routes }: { Routes: RouteInterface[] }) => {
  const result: string[] = [];

  Routes.forEach((route) => {
    const firstSegment = route.Segments[0];
    const lastSegment = route.Segments[route.Segments.length - 1];

    result.push(`${firstSegment.DepartureCity}-${lastSegment.ArrivalCity}`);
  });

  return result.join('; ');
};

export default createFromToAirTicket;
