import React, { useEffect, useRef, useState } from 'react';
import { Moment } from 'moment';

import { getText } from '../../../../../i18n';

import { MainAnalytic } from '../../../../bi/utils/analytics';
import { isEmail } from '../../../../bi/utils/validation';

import { ITripPlanItem } from '../../../../bi/types/order';
import OrderService from '../../../../bi/services/order';

import { TripPlanItemDefault } from '../../../../bi/constants/tripPlanItem';

import InputModal from '../../../../components/InputModal';

import { TripPlanHeader } from './component/TripPlanHeader';
import { TripPlanContent } from './component/TripPlanContent';

import styles from './index.module.css';

const LABELS = {
  SEND_TO_EMAIL: getText('trip:tripPlan.sendTrip'),
};

interface TripPlanProps {
  orderService: OrderService
  tripPlan: ITripPlanItem[],
  tripId: number,
  locale: string,
}

const TripPlan = ({
  orderService,
  tripPlan,
  tripId,
  locale,
}:TripPlanProps) => {
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const [voucher, setVoucher] = useState<boolean>(false);
  const [selectEmployee, setSelectEmployee] = useState<number | null>(null);
  const [employeeTrips, setEmployeeTrips] = useState<ITripPlanItem[]>([]);
  const [employees, setEmployees] = useState<{ value: number, label: string }[] >([]);
  const [tripPlanEvent, setTripPlanEvent] = useState<ITripPlanItem>(TripPlanItemDefault);
  const [sendMailDialog, setSendMailDialog] = useState< { show: boolean, tripPlanEventId: number | null }>({ show: false, tripPlanEventId: null });
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [isShowEdit, setShowEdit] = useState<boolean>(false);
  const collapseRef = useRef(null);

  const handleSetCollapseOpen = () => {
    if (collapseRef?.current) {
      // @ts-ignore
      collapseRef.current?.showInfo();
    }

    setShowEdit(false);
  };

  const handleSetVoucher = (value: boolean) => {
    setVoucher(value);

    if (value) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.TRIP_PLAN_VOUCHER_CHECKBOX_CHOSEN);
    }
  };

  const emailValidate = (email: string): void => setIsValidEmail(isEmail(email));

  const filteredTrips = (employeeId: number) => tripPlan.filter(({ OwnerId }) => OwnerId === employeeId);

  const handleSelectEmployee = (value: number) => {
    const trips = filteredTrips(value);

    setEmployeeTrips(trips);
    setSelectEmployee(value);
  };

  useEffect(() => {
    if (selectEmployee) {
      const trips = filteredTrips(selectEmployee);
      setEmployeeTrips(trips);
    }
  }, [tripPlan, selectEmployee]);

  useEffect(() => {
    orderService.getTripPlanDetails(tripId).then(async (res) => {
      const mappedEmployees = res.reduce((acc: { value: number, label: string }[], item: ITripPlanItem) => {
        const employeeAlreadyExist = acc.some((employee: { value: number | null; }) => employee.value === item.OwnerId);

        if (employeeAlreadyExist || !item.Owner) return acc;

        return [...acc, { value: item.OwnerId, label: item.Owner }];
      }, []);

      if (mappedEmployees.length === 1) handleSelectEmployee(mappedEmployees[0].value);

      const sortedMappedEmployees = mappedEmployees.sort((employee: { label: number[]; }, employeeSec: { label: number[]; }) =>
        (employee?.label[0] > employeeSec?.label[0] ? 1 : -1));

      setEmployees(sortedMappedEmployees);
      setFirstLoading(false);
    },
    );
  }, []);

  const handleCreateEvent = async (date: { Text: string, Name:string, EventDate: Moment, EventTime: string }) => {
    setFirstLoading(true);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.TRIP_PLAN_EVENT_BUTTON_PRESSED);

    await orderService.createPlanTripEvent({
      ...date,
      TripId: tripId,
      EmployeeId: selectEmployee,
    });

    setFirstLoading(false);
    handleSetCollapseOpen();
  };

  const handleDeleteEvent = async (tripEventId: number) => {
    setFirstLoading(true);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.TRIP_PLAN_DELETE_EVENT_BUTTON_PRESSED);

    await orderService.deletePlanTripEvent(tripEventId, tripId);

    setFirstLoading(false);
  };

  const handleUpdateEvent = async (TripEvent: { Text: string, Name:string, EventDate: Moment, EventTime: string, Id: string }) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.TRIP_PLAN_EDIT_EVENT_BUTTON_PRESSED);

    await orderService.updatePlanTripEvent({
      ...TripEvent,
      TripId: tripId,
      EmployeeId: selectEmployee,
      Id: tripPlanEvent?.Id as string,
    });

    setShowEdit(false);
  };

  const handleEditEvent = (TripEvent: ITripPlanItem, isEdit: boolean) => {
    setTripPlanEvent({ ...TripEvent, isEdit });
    setShowEdit(!isShowEdit);
  };

  const handleChangeEventData = (dataKey: string, value: string | number | null) => {
    setTripPlanEvent({
      ...tripPlanEvent,
      [dataKey]: value,
    });
  };

  const handleResetEventData = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.TRIP_PLAN_EVENT_PRESSED);
    setTripPlanEvent(TripPlanItemDefault);
  };

  const handleSendToMail = async (email: string) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.TRIP_PLAN_EMAIL_SEND_BUTTON_PRESSED);
    setSendMailDialog({
      show: false,
      tripPlanEventId: null,
    });

    const data: {
      tripId: number;
      ownerId: number;
      addvouchers: boolean;
      locale: string;
      email: string;
    } = {
      tripId,
      ownerId: selectEmployee as number,
      addvouchers: voucher,
      locale,
      email,
    };

    await orderService.sendToEmailPlanTripEvent(data);
  };

  const handleSendToMailDialog = () => {
    setSendMailDialog({
      show: true,
      tripPlanEventId: null,
    });
  };

  const handleDownloadTripPlanEvent = async () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRIPS.TRIP_PLAN_TP_DOWNLOAD_BUTTON_PRESSED);
    await orderService.downloadPlanTripEvent(tripId, selectEmployee as number, voucher);
  };

  return (
    <div className={ styles.wrapper }>
      <TripPlanHeader
        loading={ firstLoading }
        employees={ employees }
        selectEmployee={ selectEmployee }
        voucher={ voucher }
        tripPlanEvent={ tripPlanEvent }
        onSetVoucher={ handleSetVoucher }
        onSelectEmployee={ handleSelectEmployee }
        onCreateEvent={ handleCreateEvent }
        onEditEvent={ handleUpdateEvent }
        onDownloadTripPlanEvent={ handleDownloadTripPlanEvent }
        onOpenMailDialog={ handleSendToMailDialog }
        onSetCollapseOpen={ handleSetCollapseOpen }
        onChangePlanEventData={ handleChangeEventData }
        onResetTripPlanEvent={ handleResetEventData }
        ref={ collapseRef }
      />
      <TripPlanContent
        firstLoading={ firstLoading }
        isShowEdit={ isShowEdit }
        trips={ employeeTrips }
        selectEmployee={ selectEmployee }
        tripPlanEvent={ tripPlanEvent }
        onDeleteEvent={ handleDeleteEvent }
        onEdit={ handleEditEvent }
        onSetCollapseOpen={ handleSetCollapseOpen }
        onChangePlanEventData={ handleChangeEventData }
        onEditEvent={ handleUpdateEvent }
        onCreateEvent={ handleCreateEvent }
      />
      <InputModal
        show={ sendMailDialog.show }
        onCancel={ () => setSendMailDialog({ show: false, tripPlanEventId: null }) }
        title={ LABELS.SEND_TO_EMAIL }
        onSend={ handleSendToMail }
        onChange={ emailValidate }
        isValid={ isValidEmail }
      />
    </div>
  );
};

export { TripPlan };
