// @ts-nocheck
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Tag,
  Text,
  Button,
  Paginate,
  Dialog,
  LinkButton,
  PROPS,
  NoResults,
} from 'new-ui';

import { getText } from '../../../../../i18n';

import { AirlineFilters } from '../../../AirlineResult/components/filters';
import { SortSelect } from '../../../../components/SortSelect';
import AirlineSearchItem from '../../../../components/AirlineSearchItem';
import { ChangeAirTripFormOther } from '../../../../components/ChangeAirTripFormOther';

import slideToTop from '../../../utils/scrollToTop';
import MoneyFormat from '../../../../bi/utils/money';
import { stringifySearchParams } from '../../../../bi/utils/convertSearchParams';
import parseUnix from '../../../../bi/utils/parseDateTime';
import { formatDate, formatRangeDateWithSimplicity } from '../../../../bi/utils/formatDate';

import ROUTES from '../../../../bi/constants/routes';
import { PATTERN } from '../../../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import AIRFILTERTYPE from '../../../../bi/constants/tagsFilterType';
import { CHANGE_AIR_TRIP_FIELDS } from '../../../../bi/constants/airline';

import styles from './styles/index.module.css';

const LABELS = {
  NO_MATCHING_TICKET: getText('components:changeAirTrip.noMatchingTicket'),
  NOT_FOUND: getText('air:result.notFound'),
  CHANGE_PARAMS_OF_SEARCH: getText('air:result.changeParamsOfSearch'),
  CHANGE_PARAMS_OF_FILTERS: getText('air:result.changeParamsOfFilters'),
  RESET_FILTERS: getText('air:result.resetFilters'),
  FAVORITE_NOT_FOUND_MESSAGE: {
    ONLY_FROM: (number, date) => getText('air:result.favoriteNotFoundMessages.onlyFrom', { number, date }),
    FROM_AND_BACK: (number, date) => getText('air:result.favoriteNotFoundMessages.fromAndBack', { number, date }),
  },
  FLIGHT: getText('air:result.flight'),
  OF: getText('air:result.of'),
};

const {
  changeTrip: {
    air: {
      byDateDirectionResult: {
        item: itemTest,
        itemWrapper: itemWrapperTest,
        sortSelect,
        openOtherRequest,
        dialogOtherRequest,
        filters: filtersTest,
        notFound,
        notFoundParamsSearch,
        notFoundParamsFilter,
        resetParamsFilter,
      },
    },
  },
} = QA_ATTRIBUTES;

const {
  TEXT: {
    TYPES: {
      BOLD_18,
    },
  },
  BUTTON: {
    TYPES: {
      PRIMARYOUTLINE,
    },
  },
  LINK_BUTTON: {
    THEMES: {
      RED,
    },
  },
} = PROPS;

const ChangeAirTripResult = ({
  airlineService,
  workspaceService,
  featureFlagsService,
  userSessionService,
  chatService,
  appService,
  travelPolicyList,
  history,
  tripItem,
  dataInfo,
  tripId,
  isComplex,
  onAddTicketChanged,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const isFavoriteLastPathRef = useRef(false);

  const preparedLabelOfFavorite = (numbers, { from, to }, { from: airlineFrom, to: airlineTo }) => {
    const { ONLY_FROM, FROM_AND_BACK } = LABELS.FAVORITE_NOT_FOUND_MESSAGE;

    const airlineNumbers = numbers
      .map((item, index) => (index === 0 ? `${airlineFrom} ${item}` : `${airlineTo} ${item}`))
      .join(', ');

    if (numbers.length === 1) {
      return ONLY_FROM(airlineNumbers, formatDate(from, PATTERN.DAY_OF_MONTH));
    }

    return FROM_AND_BACK(airlineNumbers, formatRangeDateWithSimplicity(from, to));
  };

  const handleUpdateFilters = (field, changes) => {
    if (field === AIRFILTERTYPE.FLIGHTSNUMBERS) {
      airlineService.setFlightsNumbersValue(changes);
    }

    airlineService.setResultValue(`${CHANGE_AIR_TRIP_FIELDS.SEARCH_FILTERS}.${field}`, changes);
  };

  const handleGetLinkAction = (item) => {
    const url = ROUTES.SEARCH.AIR;
    const { travellers: travelers, routes, isDirect, flightClass } = airlineService.getSearch();
    const segments = item.Routes.map((route) => route.Segments[0]);

    const mapRoutes = item.Routes.map(({ Segments }) => {
      const { ArrivalAirport, DepartureAirport } = Segments[0];

      return {
        Arrival: { Code: ArrivalAirport.ID, Name: ArrivalAirport.Name },
        Departure: { Code: DepartureAirport.ID, Name: DepartureAirport.Name },
        Segments,
      };
    });

    const mappedItem = {
      Routes: mapRoutes,
      Class: flightClass,
      isDirect,
    };

    const searchParams = airlineService.getSearchObject(mappedItem, { dateFrom: routes[0].date, dateTo: routes[0].dateBack, travelers });
    const searchString = stringifySearchParams(searchParams);
    const link = window.location.origin + history.createHref({ pathname: url, search: searchString });
    let description = '';

    segments.forEach((segment) => {
      const departureInfo = `${segment.FlightNumber} ${segment.DepartureCity} ${segment.DepartureAirport.ID}`;
      const arrivalInfo = `${segment.ArrivalCity} ${segment.ArrivalAirport.ID}`;
      const departureTime = parseUnix(segment.DepartureTime).format(PATTERN.DAY_WITH_DIGITALMONTH);
      const arrivalTime = parseUnix(segment.ArrivalTime).format(PATTERN.DAY_WITH_DIGITALMONTH);

      description += `\n${LABELS.FLIGHT} ${departureInfo} - ${arrivalInfo}, ${departureTime} - ${arrivalTime}, `;
    });

    description += `${LABELS.OF} ${MoneyFormat.money(item.Price.TotalPrice, true)}`;

    return `${description} \n\n${link}`;
  };

  const handlePageChange = (page) => {
    airlineService.setResultValue(CHANGE_AIR_TRIP_FIELDS.SEARCH_PAGING, page);
    slideToTop();
  };

  const handleResetFilters = () => {
    isFavoriteLastPathRef.current = false;
    airlineService.resetFilters();
  };

  const renderNoResults = () => (
    <div className={ styles.wrapper_no_results }>
      <NoResults
        renderContent={ () => (
          <div className={ styles.no_results }>
            <Text qaAttr={ notFound } type={ BOLD_18 }>
              { LABELS.NOT_FOUND }
            </Text>
            <Text qaAttr={ notFoundParamsSearch } className={ styles.subtext }>
              { LABELS.CHANGE_PARAMS_OF_SEARCH }
            </Text>
          </div>
        ) }
      />
    </div>
  );

  const renderFullPage = (html) => {
    const {
      filters,
      routeInfo,
      isAnyFavorite,
      unavailableTravelPolicy,
    } = dataInfo;

    const airlinesDictionary = Object.assign({}, ...userSessionService.get().enums.bonus.Air.map((item) => ({ [item.Code.toLowerCase()]: item.Code })));

    return (
      <div className={ styles.result }>
        <AirlineFilters
          airlineService={ airlineService }
          isComplex={ isComplex }
          filters={ filters }
          travelPolicyList={ travelPolicyList }
          routeInfo={ routeInfo }
          isAnyFavorite={ isAnyFavorite }
          unavailableTravelPolicy={ unavailableTravelPolicy }
          airlinesDictionary={ airlinesDictionary }
          updateFilters={ handleUpdateFilters }
          isChangeAirTrip
          onBlurAirlineNumber={ () => {} }
          qaAttrs={ filtersTest }
        />
        { html }
      </div>
    );
  };

  const renderFilterEmptyPanel = () => {
    const { favorite } = dataInfo;
    const { numbers, dates, airline } = favorite || {
      numbers: [],
      dates: { from: null, to: null },
      airline: { from: null, to: null },
    };

    const title = isFavoriteLastPathRef.current || favorite
      ? preparedLabelOfFavorite(numbers, dates, airline)
      : LABELS.NOT_FOUND;

    const subtext = isFavoriteLastPathRef.current
      ? LABELS.CHANGE_PARAMS_OF_SEARCH
      : LABELS.CHANGE_PARAMS_OF_FILTERS;

    return (
      <div className={ styles.wrapper_no_results }>
        <NoResults
          renderContent={ () => (
            <div className={ styles.no_results }>
              <Text qaAttr={ notFound } type={ BOLD_18 }>
                { title }
              </Text>
              <Text qaAttr={ notFoundParamsFilter } className={ styles.subtext }>
                { subtext }
              </Text>
              <Button
                qaAttr={ resetParamsFilter }
                type={ PRIMARYOUTLINE }
                onClick={ handleResetFilters }
              >
                { LABELS.RESET_FILTERS }
              </Button>
            </div>
          ) }
        />
      </div>
    );
  };

  const renderEmptyFilter = () => renderFullPage(renderFilterEmptyPanel());

  const renderList = () => {
    const {
      sortBy,
      items,
      tags,
      paging,
      travelPolicyAllList,
      filters,
    } = dataInfo;

    const sortItems = airlineService.sortChangeAirTrip;

    const { travellers } = airlineService.getSearch();

    const ticketHtml = items.map((ticket, index) => (
      <AirlineSearchItem
        index={ index }
        key={ ticket.Id }
        item={ ticket }
        appService={ appService }
        workspaceService={ workspaceService }
        featureFlagsService={ featureFlagsService }
        preventDuplication={ [] }
        selectedTravelPolicy={ filters.selectedTravelPolicy }
        travelPolicyList={ travelPolicyAllList }
        isComplex={ isComplex }
        hideBookmarks
        travellers={ travellers }
        onUpdateCheckbox={ (value, route) => airlineService.setResultCheckbox(value, route) }
        onAddToCart={ onAddTicketChanged }
        onAddToNotepad={ () => {} }
        onAddToFavorite={ () => {} }
        onGetLink={ handleGetLinkAction }
        onCopyLink={ () => {} }
        isChangeAirTrip
        qaAttrItem={ itemTest }
        qaAttrItemWrapper={ itemWrapperTest }
      />
    ));

    const tagsHtml = !!tags.length && tags.map((tag) => {
      const { name, key, readOnly } = tag;

      return (
        <Tag
          label={ name }
          readOnly={ readOnly }
          onClick={ () => airlineService.deleteTags(tag) }
          key={ key }
          className={ styles.tag }
          qaAttr={ tag.qaAttr }
        />
      );
    });

    const html = (
      <div className={ styles.content }>
        <div className={ styles.header }>
          <div className={ styles.main }>
            <div className={ styles.select }>
              <SortSelect
                items={ sortItems }
                value={ sortBy }
                onChange={ (value) => airlineService.setResultValue(CHANGE_AIR_TRIP_FIELDS.SEARCH_SORT, value) }
                qaAttr={ sortSelect }
              />
            </div>
            <div className={ styles.tags } >
              { tagsHtml }
            </div>
          </div>
        </div>
        <LinkButton
          qaAttr={ openOtherRequest }
          className={ styles.other_change }
          theme={ RED }
          onClick={ () => setShowDialog(true) }
        >
          { LABELS.NO_MATCHING_TICKET }
        </LinkButton>
        <div className={ styles.list }>
          <div className={ styles.items }>
            { ticketHtml }
          </div>
          <Paginate
            total={ paging.total }
            page={ paging.current }
            itemsPerPage={ paging.count }
            onChange={ handlePageChange }
          />
        </div>
        <Dialog
          qaAttr={ dialogOtherRequest }
          show={ showDialog }
          showClosing
          onChange={ () => setShowDialog(false) }
        >
          <ChangeAirTripFormOther
            chatService={ chatService }
            history={ history }
            tripId={ tripId }
            tripItem={ tripItem }
            isDemo={ workspaceService.isDemo }
          />
        </Dialog>
      </div>
    );

    return renderFullPage(html);
  };

  if (!dataInfo) return null;

  const { sources, items } = dataInfo;

  if (!sources.length) return renderNoResults();

  if (!items.length) return renderEmptyFilter();

  return renderList();
};

ChangeAirTripResult.propTypes = {
  airlineService: PropTypes.object.isRequired,
  chatService: PropTypes.object.isRequired,
  workspaceService: PropTypes.object.isRequired,
  userSessionService: PropTypes.object.isRequired,
  featureFlagsService: PropTypes.object.isRequired,
  travelPolicyList: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  isComplex: PropTypes.bool.isRequired,
  tripId: PropTypes.string.isRequired,
  dataInfo: PropTypes.object,
  tripItem: PropTypes.object,
  onAddTicketChanged: PropTypes.func.isRequired,
};

ChangeAirTripResult.defaultProps = {
  tripItem: null,
  dataInfo: null,
};

export { ChangeAirTripResult };
