import { MultiSelectValuesNested } from 'new-ui';

import debounce from '../../api/debounce';

import { TravelPoliciesListStore } from './travelPoliciesListStore';
import { TravelPolicyStore } from './travelPolicyStore';
import AirTravelPolicyStore from './serviceTypeStores/air';
import type { SuggestsRequestModel, TravelPolicyServerModel } from './types';
import { TRAVEL_POLICY_FIELDS_DICTIONARIES, TRAVEL_POLICY_STORES_TYPES } from './consts';
import TrainTravelPolicyStore from './serviceTypeStores/train';
import HotelTravelPolicyStore from './serviceTypeStores/hotel';
import TaxiTravelPolicyStore from './serviceTypeStores/taxi';
import TransferTravelPolicyStore from './serviceTypeStores/transfer';
import VipHallTravelPolicyStore from './serviceTypeStores/vipHall';

const DEBOUNCE_TIME = 200;

const CAN_NOT_BE_DELETED_CODE = 406;

const prepareAutocompleteParams = (query: string, type: string) => {
  switch (type) {
    case TRAVEL_POLICY_STORES_TYPES.AIR:
      return { query };
    default:
      return query;
  }
};

class TravelPolicyService {
  // @ts-ignore
  travelPoliciesListStore: TravelPoliciesListStore = TravelPoliciesListStore;

  // @ts-ignore
  travelPolicyStore: TravelPolicyStore = TravelPolicyStore;
  airTravelPolicyStore: AirTravelPolicyStore = new AirTravelPolicyStore();
  trainTravelPolicyStore: TrainTravelPolicyStore = new TrainTravelPolicyStore();
  hotelTravelPolicyStore: HotelTravelPolicyStore = new HotelTravelPolicyStore();
  taxiTravelPolicyStore: TaxiTravelPolicyStore = new TaxiTravelPolicyStore();
  transferTravelPolicyStore: TransferTravelPolicyStore = new TransferTravelPolicyStore();
  vipHallTravelPolicyStore: VipHallTravelPolicyStore = new VipHallTravelPolicyStore();

  api: any;
  apiEmployee: any;
  apiPopups: any;
  debounceAirlineAutocomplete: () => void;
  debounceTrainAutocomplete: () => void;
  debounceHotelAutocomplete: () => void;
  debounceTaxiAutocomplete: () => void;
  // @ts-ignore
  debounceTransferAutocomplete: () => void;
  debounceVipHallAutocomplete: () => void;
  debounceCountriesAutocomplete: () => void;
  xhrAutocomplete: any;

  constructor({ travelPolicies, airline, train, hotel, taxi, employee, popups }: any) {
    this.api = travelPolicies;
    this.apiEmployee = employee;
    this.apiPopups = popups;

    this.debounceAirlineAutocomplete = debounce(airline.autocomplete, DEBOUNCE_TIME);
    this.debounceTrainAutocomplete = debounce(train.autocomplete, DEBOUNCE_TIME);
    this.debounceHotelAutocomplete = debounce(hotel.autocomplete, DEBOUNCE_TIME);
    this.debounceTaxiAutocomplete = debounce(taxi.autocomplete, DEBOUNCE_TIME);
    this.debounceCountriesAutocomplete = debounce(employee.citizenship, DEBOUNCE_TIME);
  }

  loadList = () => {
    this.travelPoliciesListStore.setLoading(true);

    this.api.load().then((travelPolicies: any) => {
      this.travelPoliciesListStore.setList(travelPolicies);
      this.travelPoliciesListStore.setLoading(false);
    });
  };

  clearList = () => this.travelPoliciesListStore.setList([]);

  removeTravelPolicy = (policyId: string) => {
    this.travelPoliciesListStore.setLoading(true);
    this.api.remove(policyId)
      .then(() => this.loadList())
      // @ts-ignore
      .catch(({ status }) => {
        this.travelPoliciesListStore.setLoading(false);

        if (status === CAN_NOT_BE_DELETED_CODE) {
          this.setListDialog(true);
        }
      });
  };

  setListDialog = (value: boolean) => this.travelPoliciesListStore.setDialog(value);

  get isFormValid() {
    return this.travelPolicyStore.isValid && (
      this.airTravelPolicyStore.store.Apply ||
      this.trainTravelPolicyStore.store.Apply ||
      this.hotelTravelPolicyStore.store.Apply ||
      this.taxiTravelPolicyStore.store.Apply ||
      this.vipHallTravelPolicyStore.store.Apply ||
      this.transferTravelPolicyStore.store.Apply);
  }

  get policyIsApplied() {
    return this.airTravelPolicyStore.store.Apply
      || this.trainTravelPolicyStore.store.Apply
      || this.hotelTravelPolicyStore.store.Apply
      || this.taxiTravelPolicyStore.store.Apply
      || this.transferTravelPolicyStore.store.Apply
      || this.vipHallTravelPolicyStore.store.Apply;
  }

  setTravelPolicyFromServerModel = (model: TravelPolicyServerModel | null) => {
    this.travelPolicyStore.clearStore();
    this.airTravelPolicyStore.clearStore();
    this.trainTravelPolicyStore.clearStore();
    this.hotelTravelPolicyStore.clearStore();
    this.taxiTravelPolicyStore.clearStore();
    this.transferTravelPolicyStore.clearStore();
    this.vipHallTravelPolicyStore.clearStore();

    if (!model) {
      return;
    }

    const {
      AirlineRule,
      HotelRule,
      TrainRule,
      TaxiVoucherRule,
      TransferVoucherRule,
      VipHallRule,
      ...general
    } = model;

    this.travelPolicyStore.mapServerModelToClient(general);
    this.airTravelPolicyStore.mapServerModelToClient(AirlineRule);
    this.trainTravelPolicyStore.mapServerModelToClient(TrainRule);
    this.hotelTravelPolicyStore.mapServerModelToClient(HotelRule);
    this.taxiTravelPolicyStore.mapServerModelToClient(TaxiVoucherRule);
    this.transferTravelPolicyStore.mapServerModelToClient(TransferVoucherRule);
    this.vipHallTravelPolicyStore.mapServerModelToClient(VipHallRule);
  };

  updateStore = (storeType: string, payload: any) => {
    const MAP_STORE_TYPE_TO_STORE = {
      [TRAVEL_POLICY_STORES_TYPES.COMMON]: this.travelPolicyStore,
      [TRAVEL_POLICY_STORES_TYPES.AIR]: this.airTravelPolicyStore,
      [TRAVEL_POLICY_STORES_TYPES.HOTEL]: this.hotelTravelPolicyStore,
      [TRAVEL_POLICY_STORES_TYPES.TRAIN]: this.trainTravelPolicyStore,
      [TRAVEL_POLICY_STORES_TYPES.TAXI]: this.taxiTravelPolicyStore,
      [TRAVEL_POLICY_STORES_TYPES.TRANSFER]: this.transferTravelPolicyStore,
      [TRAVEL_POLICY_STORES_TYPES.VIP_HALL]: this.vipHallTravelPolicyStore,
    };

    const store = MAP_STORE_TYPE_TO_STORE[storeType];

    if (storeType === TRAVEL_POLICY_STORES_TYPES.TAXI && payload?.prohibitBook?.IsEnabled) {
      store.updateStore(store.store.DayOff.IsEnabled = false);
      store.updateStore(store.store.Class.IsEnabled = false);
      store.updateStore(store.store.DayOff.ProhibitIsEnabled = true);
      store.updateStore(store.store.Class.ProhibitIsEnabled = true);
    }

    if (storeType === TRAVEL_POLICY_STORES_TYPES.TAXI && !payload?.prohibitBook?.IsEnabled) {
      store.updateStore(store.store.DayOff.ProhibitIsEnabled = false);
      store.updateStore(store.store.Class.ProhibitIsEnabled = false);
    }

    store.updateStore(payload);
  };

  saveForm = () => {
    const { preparedToSaveModel: generalFields, store: { editTP }, employees: { selected }, isSelectAll } = this.travelPolicyStore;

    const modelTP = {
      ...generalFields,
      AirlineRule: this.airTravelPolicyStore.preparedToSaveModel,
      TrainRule: this.trainTravelPolicyStore.preparedToSaveModel,
      HotelRule: this.hotelTravelPolicyStore.preparedToSaveModel,
      TaxiVoucherRule: this.taxiTravelPolicyStore.preparedToSaveModel,
      TransferVoucherRule: this.transferTravelPolicyStore.preparedToSaveModel,
      VipHallRule: this.vipHallTravelPolicyStore.preparedToSaveModel,
    };

    const preparedData = {
      EmployeeIds: selected,
      ApplyForAll: isSelectAll,
      TravelPolicy: modelTP,
    };

    this.travelPolicyStore.updateStore({ loading: true });

    return this.api.save(preparedData).then(() => {
      if (editTP) this.api.updateIntercomCommentForUsers();

      this.travelPolicyStore.updateStore({ loading: false });
      this.travelPolicyStore.setResetTravelPolicyUnique();

      return generalFields;
    });
  };

  getSuggestions = ({ query, type, exceptionType }: SuggestsRequestModel) => {
    const MAP_TYPE_TO_SUGGEST_TYPE = {
      [TRAVEL_POLICY_STORES_TYPES.AIR]: () => this.debounceAirlineAutocomplete,
      [TRAVEL_POLICY_STORES_TYPES.HOTEL]: () => (
        // @ts-ignore
        exceptionType && exceptionType === TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.COUNTRIES
          ? this.debounceCountriesAutocomplete : this.debounceHotelAutocomplete
      ),
      [TRAVEL_POLICY_STORES_TYPES.TRAIN]: () => this.debounceTrainAutocomplete,
      [TRAVEL_POLICY_STORES_TYPES.TAXI]: () => this.debounceTaxiAutocomplete,
      [TRAVEL_POLICY_STORES_TYPES.TRANSFER]: () => this.debounceTransferAutocomplete,
      [TRAVEL_POLICY_STORES_TYPES.VIP_HALL]: () => this.debounceVipHallAutocomplete,
    };

    if (this.xhrAutocomplete) {
      this.xhrAutocomplete.abort();
    }

    const method = MAP_TYPE_TO_SUGGEST_TYPE[type]();
    // @ts-ignore
    this.xhrAutocomplete = method(prepareAutocompleteParams(query, type));

    this.travelPolicyStore.setSuggestionsLoading(true);
    this.xhrAutocomplete
      .then((list: any) => {
        const preparedList = type === TRAVEL_POLICY_STORES_TYPES.HOTEL &&
        // @ts-ignore
        (exceptionType === TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.ROUTES || !exceptionType)
          // @ts-ignore
          ? list.filter(({ IsRegion }) => !!IsRegion)
          : list;

        this.travelPolicyStore.setSuggestions(preparedList);
      })
      .catch(() => {
        this.travelPolicyStore.setSuggestionsLoading(false);
      });
  };

  getTravelPolicyForEmployeeById = async (id: number) => {
    const result = await this.api.getTravelPolicyForEmployeeById(id);

    this.travelPolicyStore.setEmployeeTravelPolicy(result);
  };

  clearSuggestions = () => {
    this.travelPolicyStore.setSuggestions([]);
  };

  updateUniqueTravelPolicyLoading = (value: boolean) => this.travelPolicyStore.setUniqueTravelPolicyLoading(value);

  setNotQuestionnaire = (value: boolean) => this.travelPolicyStore.setNotQuestionnaire(value);

  setEmployeeAutocompleteList = (query: string) => {
    this.apiEmployee.autoCompleteOnly(query.trim())
      .then((list: any) => this.travelPolicyStore.setEmployeeList(list));
  };

  setEmployeesSelected = (values: number[] | string[] | MultiSelectValuesNested[]) => this.travelPolicyStore.setEmployeeSelected(values);

  setSelectAll = (value: boolean) => this.travelPolicyStore.setSelectAll(value);

  setTravelPolicyModel = (values: string[]) => this.travelPolicyStore.setTravelPolicyModel(values);

  setResetUniqueTravelPolicy = () => this.travelPolicyStore.setResetTravelPolicyUnique();

  closePopupState = (name: string) => this.apiPopups.setStatePopups(name);

  saveUniqueTravelPolicy = () => {
    const { uniqueTravelPolicy: { travelPolicyModel }, employees: { selected }, isSelectAll } = this.travelPolicyStore;

    const preparedModel = {
      EmployeeIds: selected,
      ApplyForAll: isSelectAll,
      TravelPolicy: {
        ...travelPolicyModel,
        AirlineRule: this.airTravelPolicyStore.preparedToSaveModel,
        TrainRule: this.trainTravelPolicyStore.preparedToSaveModel,
        HotelRule: this.hotelTravelPolicyStore.preparedToSaveModel,
        TaxiVoucherRule: this.taxiTravelPolicyStore.preparedToSaveModel,
        TransferVoucherRule: this.transferTravelPolicyStore.preparedToSaveModel,
        VipHallRule: this.vipHallTravelPolicyStore.preparedToSaveModel,
      },
    };

    return this.api.saveUniqueTravelPolicy(preparedModel);
  };

  deleteQuestions = () => this.api.deleteQuestions();

  modifiedParams = (policy: any) => {
    const preparedModel = this.travelPoliciesListStore.setList([policy]);

    this.setTravelPolicyFromServerModel(preparedModel[0]);
    this.setTravelPolicyModel(preparedModel[0]);
    this.setNotQuestionnaire(true);
    this.updateUniqueTravelPolicyLoading(false);
  };

  getUniqueTravelPolicy = async () => {
    this.updateUniqueTravelPolicyLoading(true);

    try {
      const policy = await this.api.getUniqueTravelPolicy();

      this.modifiedParams(policy);
    } catch {
      this.updateUniqueTravelPolicyLoading(false);
    }
  };

  getDoneUniqueTravelPolicy = async () => {
    this.updateUniqueTravelPolicyLoading(true);

    try {
      const policy = await this.api.getDoneUniqueTravelPolicy();

      this.modifiedParams(policy);
    } catch {
      this.updateUniqueTravelPolicyLoading(false);
    }
  };

  setFormedTP = (value: string) => this.travelPolicyStore.setFormedTP(value);
}

export default TravelPolicyService;
export type TravelPolicyServiceType = TravelPolicyService;
