import React, { FC } from 'react';

import { FilterUserAnalytic } from './UserAnalytic';

import { UserAnalytic } from '../../../../../bi/types/trips';

interface FilterUserAnalyticsProps {
  userAnalytics: UserAnalytic[];
  values: number[];
  onChange(checkedTags: number[], checkedUserAnalyticsWithLabels: any): void;
}

const FilterUserAnalytics: FC<FilterUserAnalyticsProps> = ({
  userAnalytics,
  values,
  onChange,
}) => (
  <>
    {userAnalytics.map(userAnalytic => (
      <FilterUserAnalytic
        key={ userAnalytic.Id }
        userAnalytic={ userAnalytic }
        values={ values }
        onChange={ onChange }
      />
    ))}
  </>
);

export { FilterUserAnalytics };
