import React from 'react';
import { Collapse, Text, TextColor } from 'new-ui';

import { getText } from '../../../../../i18n';

import { TripCompact } from '../../../../components/TripCompact';
import { StatusMarker } from '../../../../components/StatusMarker';

import { formatRangeDateWithSimplicity, getDateDescriptorTime } from '../../../../bi/utils/formatDate';
import MoneyFormat from '../../../../bi/utils/money';

import { IEventService, ITripData, TEventValue } from '../../../../bi/services/events/type';

import { TRIPSTATUS } from '../../../../bi/constants/trips';

import styles from './styles/index.module.css';

interface IEvents {
  events: TEventValue[],
  eventService: IEventService,
}

const LABELS = {
  FIELD: {
    CREATED: getText('event:events.fields.created'),
    NAME: getText('event:events.fields.name'),
    PLACE: getText('event:events.fields.place'),
    DATE: getText('event:events.fields.date'),
    BUDGET: getText('event:events.fields.budget'),
    ROUBLES: getText('event:events.fields.roubles'),
    MEMBERS: getText('event:events.fields.members'),
    STATUS: getText('event:events.fields.status'),
    COMMENT: getText('event:events.fields.comment'),
  },
  DELETE_MODAL: {
    DELETE: (request: string) => getText('event:events.deleteRequest.delete', { request }),
    CANCEL: (request: string) => getText('event:events.deleteRequest.cancel', { request }),
    DONE: getText('event:events.deleteRequest.done'),
    NO: getText('event:events.deleteRequest.no'),
  },
  TRAIN: getText('event:events.train'),
  COLLAPSE: getText('event:events.collapse'),
  EXPAND: getText('event:events.expand'),
};

const Events = ({
  events,
  eventService,
}: IEvents) => {
  const renderField = (title: string, value: string | undefined | null, additValue?: string) => {
    if (!value) {
      return null;
    }

    const isCreated = title === LABELS.FIELD.CREATED;

    const color: TextColor = isCreated ? 'dim-gray' : 'default';
    const comma = isCreated ? ', ' : null;

    return (
      <div className={ styles.item }>
        <Text
          className={ styles.subtitle }
          color='gray'
        >
          { title }
        </Text>
        <Text
          color={ color }
          className={ styles.text }
        >
          { value }
          { comma }
          { additValue }
        </Text>
      </div>
    );
  };

  const renderTrips = (status: string, value: ITripData[]) => {
    if ((status !== TRIPSTATUS.BOOKED && status !== TRIPSTATUS.COMPLETED) || !value.length) {
      return null;
    }

    const renderTrip = () => value.map((item, index) => (
      <TripCompact
        key={ index }
        value={ item }
      />
    ));

    return (
      <div>
        <Text
          className={ styles.text_trip }
          type='bold_16'
        >
          { LABELS.TRAIN }
        </Text>
        { renderTrip() }
      </div>
    );
  };

  const renderStatusMark = (status: string) => !!status && (
    <div
      className={ styles.wrapper_mark }
    >
      <StatusMarker
        status={ status }
      />
    </div>
  );

  const renderContent = () => !!events.length && events.map((item, index) => (
    <div
      className={ styles.wrapper_collapse }
      key={ `${item.Id}` }
    >
      <Collapse
        label={ item.Name }
        type='information'
        opened={ false }
        labelRollUp={ LABELS.COLLAPSE }
        labelExpand={ LABELS.EXPAND }
        editName={ (value: string) => eventService.editName(item.Id as string, index, value) }
        isEditName
        alternariveChild={ renderStatusMark(item.Status as string) }
        widthInput='42'
        fontsizeInput='20'
      >
        <div
          className={ styles.wrapper_header_table }
        >
          { renderField(LABELS.FIELD.CREATED, item.User, getDateDescriptorTime(item.CreatedDate)) }
        </div>
        { renderField(LABELS.FIELD.NAME, item.Name) }
        { renderField(LABELS.FIELD.PLACE, item.Place) }
        { renderField(LABELS.FIELD.DATE, formatRangeDateWithSimplicity(item.StartDate, item.EndDate)) }
        { renderField(LABELS.FIELD.BUDGET, MoneyFormat.moneyWithDecimalField(item.Budget, true)) }
        { renderField(LABELS.FIELD.MEMBERS, item.Member) }
        { renderField(LABELS.FIELD.COMMENT, item.Comment) }
        { renderTrips(item.Status as string, item.Trips as ITripData[]) }
      </Collapse>
    </div>
  ));

  return (
    <div
      className={ styles.wrapper }
    >
      { renderContent() }
    </div>
  );
};

export { Events };
