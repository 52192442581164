import React, { useEffect, useState } from 'react';
import { PageLoader, Button } from 'new-ui';

import { getText } from '../../../../../i18n';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import { DraggableSingleList } from '../../../../components/DragAndDrop';

import RenderCompany from './components/RenderCompany';

import styles from './index.module.css';

const LABELS = {
  EDIT_ORDER: getText('settings:companies.editOrder'),
};

export type CompanyProps = {
  CompanyId: string,
  CompanyName: string,
  ShortCompanyName: string,
  Contract: {
    Number: string,
    StartDate: Date,
  }
  INN: string,
  KPP: string,
  LegalAddress: string,
  ActualAddress: string,
  CEO: string,
  IINBIN: string,
};

type CompaniesProps = {
  workspaceService: {
    isAdmin: boolean,
    isMultiCompany(): boolean,
  },
  stores: {
    settingsStore: {
      setCompany(value: number[]): void,
      companies: CompanyProps[],
      brandName: string,
    },
  },
  settingsService: {
    setCompanyOrder(data: number[]): void,
  }
};

const Companies = ({
  workspaceService: {
    isAdmin,
    isMultiCompany,
  },
  stores: {
    settingsStore: {
      setCompany,
      companies,
      brandName,
    },
  },
  settingsService: {
    setCompanyOrder,
  },
}: CompaniesProps) => {
  const [showDrag, setShowDrag] = useState(false);
  const [draggableItem, setDraggableItem] = useState<any[]>([]);

  useEffect(() => {
    setDraggableItem(companies.map((
      item: {
        CompanyId: string,
        CompanyName: string
        ShortCompanyName: string,
      },
      index: number,
    ) => ({
      ...item,
      id: item.CompanyId,
      name: item.ShortCompanyName || item.CompanyName,
      order: index,
    })));
  }, [companies]);

  if (!companies) {
    return <PageLoader />;
  }

  const wrapperClassName = isMultiCompany() ? styles.wrapper_items : styles.wrapper_item;

  const handleSetShowDrag = () => {
    setShowDrag(!showDrag);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.REQUISITES_EDIT_BUTTON);
  };

  const handleSetCompanyOrder = (data: number[]) => {
    setCompanyOrder(data);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.REQUISITES_SAVE_BUTTON);
  };

  const renderButtonEdit = () => {
    if (!isAdmin || showDrag || !isMultiCompany()) {
      return null;
    }

    return (
      <Button
        className={ styles.button_edit }
        type='textual-medium'
        onClick={ handleSetShowDrag }
      >
        { LABELS.EDIT_ORDER }
      </Button>
    );
  };

  const renderCompany = () => {
    if (showDrag) {
      return (
        <div className={ styles.wrapper }>
          <DraggableSingleList
            data={ draggableItem }
            setData={ setDraggableItem }
            setCompany={ setCompany }
            onCancel={ setShowDrag }
            onSave={ handleSetCompanyOrder }
            setAnalytics={ () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.REQUISITES_COMPANY_DRAG_N_DROP) }
          />
        </div>
      );
    }

    const companyInfoHtml = (item: CompanyProps) => (
      <div
        key={ item.CompanyId }
      >
        <RenderCompany
          company={ item }
          isMulti={ isMultiCompany() }
          brandName={ brandName }
        />
      </div>
    );

    return draggableItem.map(companyInfoHtml);
  };

  return (
    <>
      <div className={ styles.button_wrapper }>
        { renderButtonEdit() }
      </div>
      <div
        className={ wrapperClassName }
      >
        { renderCompany() }
      </div>
    </>
  );
};

export { Companies };
