import { useQuery } from '@tanstack/react-query';

import { api } from '../../apiV2';

import { KEYS } from './constants';

const useSchemes = () => useQuery({
  queryKey: [KEYS.getAllSchemes],
  queryFn: async () => {
    try {
      const response = await api.applicationScheme.getSchemes();

      return response;
    } catch (e) {
      return [];
    }
  },
  keepPreviousData: true,
  staleTime: 0,
});

export { useSchemes };
