import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Text } from 'new-ui';

import { useServices } from '../../../../bi/context/services';

import { LABELS } from '../../consts';

import styles from './styles.module.css';

export const BlockTooltip = observer(({ children }: PropsWithChildren) => {
  const { departmentsService } = useServices(['Departments']);

  const renderError = () => (
    <Text color='white' className={ styles.tooltipError }>
      { LABELS.COMPANY_NULL_ERROR }
    </Text>
  );

  return (
    <div className={ styles.tooltipWrap }>
      <Tooltip
        renderContent={ renderError }
        show={ departmentsService.store.companyNotSelected }
      >
        { children }
      </Tooltip>
    </div>
  );
});
