import { getText } from '../../../i18n';

import { DEPARTMENTS } from '../constants/departments';

interface Department {
  Id: string;
}

interface Item {
  CompanyId?: string;
  Departments?: Department[];
  Id?: string;
}

const LABELS = {
  VALUE: getText('trips:companies.companies'),
};

const getObjectForSelect = (item: string | [string, string]) => (
  Array.isArray(item) ? { label: item[1], value: item[0] } : { label: item, value: item }
);

const mapQueryParams = (query: string | string[], isNumbers: boolean) => {
  const arrFromQuery = Array.isArray(query) ? query : [query];

  return isNumbers ? arrFromQuery.map(item => Number(item || 0)) : arrFromQuery;
};

const getSelectedFromSet = (
  itemsSet: Set<number | string>,
  query: string | string[],
  isNumbers: boolean,
) => {
  const arrFromQuery = mapQueryParams(query, isNumbers);

  return arrFromQuery.filter(item => itemsSet.has(item));
};

const getSelectedFromArray = (
  arr: Item[],
  query: string | string[],
  type: string,
) => {
  const arrFromQuery = mapQueryParams(query, true);

  if (type === LABELS.VALUE) {
    return arrFromQuery.filter(item => arr.some(({ CompanyId }) => CompanyId === item));
  }

  if (type === DEPARTMENTS) {
    return arrFromQuery.filter(item => arr.some(({ Departments }) => Departments?.some(({ Id }) => Id === item)));
  }

  return arrFromQuery.filter(item => arr.some(({ Id }) => Id === item));
};

export {
  getObjectForSelect,
  getSelectedFromSet,
  getSelectedFromArray,
};
