const ACTION = {
  GETENUMS: 'enums',
  GETPROJECTS: 'projects',
  GETCITIZENSHIP: 'citizenship',
  GETTRAVELPOLICIES: 'travelPolicies',
  GETDEPARTMENTS: 'departments',
  DEPARTMENTS_SUCCESS: 'departments.success',
  DEPARTMENTS_APPROVE: 'department.approve',
  PROJECT_HEAD_SUCCESS: 'project.head.success',
  PROJECT_HEAD_APPROVE: 'project.head.approve',
};

export default ACTION;
