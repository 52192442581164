import { AirlineSource } from '../types/airline';

const isEveryTransfer = (segments: any[], selectedTransfers: string[]): boolean => {
  const segmentsWithoutLast: any[] = segments.slice(0, segments.length - 1);

  return segmentsWithoutLast.every(({ ArrivalCity }) =>
    selectedTransfers.includes(ArrivalCity),
  );
};

const isSomeTransfer = (segments: any[], selectedTransfers: string[]): boolean =>
  segments.some(({ ChangeDuration, ArrivalCity }) =>
    ChangeDuration !== 0 && selectedTransfers.includes(ArrivalCity),
  );

const filterByTransferAirports = (list: AirlineSource[], selectedTransfers: string[]): any[] => {
  const isOnlyTransfer: boolean = selectedTransfers.length > 1 &&
    list.some(({ Routes }) =>
      Routes.some(({ Segments }) => Segments.length > 2 && isEveryTransfer(Segments, selectedTransfers)),
    );

  const isTwoRoutes: boolean = list.some(({ Routes }) => Routes.length === 2);

  return list.filter(({ Routes }) => {
    if (selectedTransfers.length === 1) {
      return Routes.some(({ Segments }) => isSomeTransfer(Segments, selectedTransfers));
    }

    const filteredRoutes = Routes.filter(({ Segments }) => {
      if (isOnlyTransfer && Segments.length > 2 && (Segments.length - 1) === selectedTransfers.length) {
        return isEveryTransfer(Segments, selectedTransfers);
      }

      if (isTwoRoutes && isOnlyTransfer) {
        return Segments.length > 2 &&
          Segments.filter(({ ChangeDuration, ArrivalCity }) =>
            ChangeDuration !== 0 && selectedTransfers.includes(ArrivalCity),
          ).length > 1;
      }

      return isSomeTransfer(Segments, selectedTransfers);
    });

    if (isTwoRoutes && filteredRoutes.length === 1) {
      return Routes.every(({ Segments }) => Segments.length === 1 ||
        Segments.some(({ ArrivalCity }) => selectedTransfers.includes(ArrivalCity)));
    }

    return !!filteredRoutes.length;
  });
};

export { filterByTransferAirports };
