import React, { Component } from 'react';

import { Collapse, Checkbox, Input, Text, BackLink } from 'new-ui';

import { getText } from '../../../../i18n';

import { PriceSlider } from '../../../components/PriceSlider';
import { DateTimeSlider } from '../../../components/DateTimeSlider';
import { TimeSlider } from '../../../components/TimeSlider';
import { FilterTravelPolicy } from '../../../components/FilterTravelPolicy';
import { FilterPanel } from '../../../components/FilterPanel';

import { isSmartAgent } from '../../../bi/utils/env';

import { FIELDS_FILTERS } from '../../../bi/constants/airline';

import { AirlineFiltersProps, Airports, BorderTimeItem, RouteInfo, TravelTimeItem } from './types';

import styles from '../styles/filters.module.css';

const DATEFORMAT = 'D MMMM';

const LABELS = {
  back: getText('air:result.filters.back'),
  transferAirports: getText('air:result.filters.transferAirports'),
  flightNumber: getText('air:result.filters.flightNumber'),
  flightNumbers: getText('air:result.filters.flightNumbers'),
  price: getText('air:result.filters.price'),
  tp: getText('air:result.filters.tp'),
  baggage: {
    label: getText('air:result.filters.baggage.label'),
    checkbox: getText('air:result.filters.baggage.checkbox'),
  },
  refund: {
    label: getText('air:result.filters.refund.label'),
    checkboxes: {
      withCharge: getText('air:result.filters.refund.checkboxes.withCharge'),
      withoutCharge: getText('air:result.filters.refund.checkboxes.withoutCharge'),
    },
  },
  airlines: {
    label: getText('air:result.filters.airlines.label'),
    checkbox: getText('air:result.filters.airlines.checkbox'),
    text: getText('air:result.filters.airlines.text'),
  },
  favorite: getText('air:result.filters.favorite'),
  transferDuration: getText('air:result.filters.transferDuration'),
  transfers: getText('air:result.filters.transfers'),
  airports: {
    from: (from: string) => getText('air:result.filters.airports.from', { from }),
    to: (to: string) => getText('air:result.filters.airports.to', { to }),
  },
  borderTime: {
    from: (from: string) => getText('air:result.filters.borderTime.from', { from }),
    to: (to: string) => getText('air:result.filters.borderTime.to', { to }),
  },
  travelTime: getText('air:result.filters.travelTime'),
};

class AirlineFilters extends Component<AirlineFiltersProps> {
  static defaultProps = {
    isComplex: false,
    requestId: null,
    isChangeAirTrip: false,
    qaAttrs: null,
  };

  directCountString = this.props.airlineService.directCountString;

  handleChangePrice = (value: number[]) => this.props.updateFilters('price', value);

  handleChangeDurationTime = (value: any) => this.props.updateFilters('duration.time', value);

  renderAirportPart(
    field: string,
    airports: Airports,
    city: string,
    label: string,
    ind: number,
  ) {
    const airportsKeys = Object.keys(airports);
    const airportsNames = Object.keys(airports).map(key => airports[key].name).sort();

    if (airportsKeys.length < 2) {
      return null;
    }

    const { updateFilters } = this.props;

    const fromCheckboxHtml = airportsNames.map((name, index) => {
      const checkbox = airportsKeys.find(key => airports[key].name === name);

      if (checkbox !== undefined) {
        return (
          <Checkbox
            className={ styles.checkbox }
            key={ `check_airport_${field}_${index}_${checkbox}` }
            // id={ `check_airport_${field}_${index}_${checkbox}` }
            value={ airports[checkbox].selected }
            onChange={ value => updateFilters('airport', { field, index: ind, checkbox, value }) }
          >
            { name }
          </Checkbox>
        );
      }

      return null;
    });

    return (
      <Collapse
        opened
        className={ styles.item }
        label={ label }
        key={ `airport_${city}_${ind}` }
      >
        { fromCheckboxHtml }
      </Collapse>
    );
  }

  renderTravelTimePart(
    item: TravelTimeItem,
    info: RouteInfo,
    ind: number,
  ) {
    const { updateFilters } = this.props;
    const { from, to, border } = item.duration;

    if (border.from !== border.to) {
      return (
        <Collapse
          opened
          className={ styles.item }
          key={ `travel_time_${info.from}_${info.to}_${ind}` }
          label={ `${LABELS.travelTime} ${info.from} - ${info.to}, ${info.departureTimeSource.format(DATEFORMAT)}` }
        >
          <TimeSlider
            min={ border.from }
            max={ border.to }
            from={ from }
            to={ to }
            onSlide={ value => updateFilters(FIELDS_FILTERS.TRAVEL_TIME, { field: FIELDS_FILTERS.TRAVEL_TIME_FIELD, ind, value }) }
          />
        </Collapse>
      );
    }

    return null;
  }

  renderBorderTimePart(
    item: BorderTimeItem,
    info: RouteInfo,
    ind: number,
  ) {
    const { updateFilters } = this.props;

    return (
      <Collapse
        opened
        className={ styles.item }
        key={ `border_time_${info.from}_${info.to}_${ind}` }
        label={ `${info.from} - ${info.to}, ${info.departureTimeSource.format(DATEFORMAT)}` }
      >
        <DateTimeSlider
          label={ LABELS.borderTime.from(info.from) }
          min={ item.departure.border.from }
          max={ item.departure.border.to }
          from={ item.departure.from }
          to={ item.departure.to }
          onChange={ value => updateFilters('border.time', { field: 'departure', ind, value }) }
        />
        <DateTimeSlider
          label={ LABELS.borderTime.to(info.to) }
          min={ item.arrival.border.from }
          max={ item.arrival.border.to }
          from={ item.arrival.from }
          to={ item.arrival.to }
          onChange={ value => updateFilters('border.time', { field: 'arrival', ind, value }) }
        />
      </Collapse>
    );
  }

  renderDirectCount() {
    const { filters, updateFilters, qaAttrs } = this.props;

    const directCount = filters.directCount;
    const checkboxName = Object.keys(directCount);

    const html = checkboxName.map((checkbox, index) => {
      const qaAttr = qaAttrs?.directCount ? `${qaAttrs?.directCount}-${index}` : '';

      return (
        <Checkbox
          className={ styles.checkbox }
          key={ `direct_count__${checkbox}_${index}` }
          // id={ `direct_count__${checkbox}_${index}` }
          value={ directCount[checkbox] }
          onChange={ value => updateFilters('direct.count', { value, checkbox }) }
          qaAttr={ qaAttr }
        >
          { this.directCountString(checkbox) }
        </Checkbox>
      );
    });

    return (
      <Collapse
        opened
        className={ styles.item }
        label={ LABELS.transfers }
      >
        { html }
      </Collapse>
    );
  }

  renderTransferAirports = () => {
    if (this.props.isComplex) {
      return null;
    }

    const { filters: { transferAirports }, updateFilters } = this.props;

    const checkboxes = Object.keys(transferAirports).sort();

    if (!checkboxes.length) {
      return null;
    }

    const checkboxesHtml = checkboxes.map((checkbox, index) => (
      <Checkbox
        className={ styles.checkbox }
        key={ `check_transfer_airport_${index}_${checkbox}` }
        // id={ `check_transfer_airport_${index}_${checkbox}` }
        value={ transferAirports[checkbox].selected }
        onChange={ value => updateFilters(FIELDS_FILTERS.TRANSFER_AIRPORTS, { checkbox, value }) }
      >
        { transferAirports[checkbox].name }
      </Checkbox>
    ));

    return (
      <Collapse
        opened
        className={ styles.item }
        label={ LABELS.transferAirports }
      >
        { checkboxesHtml }
      </Collapse>
    );
  };

  renderAirlines() {
    const { filters, updateFilters, qaAttrs } = this.props;

    const airlines = filters.airlines;
    const checkboxNames = Object.keys(airlines).map(key => airlines[key].name).sort();
    const checkboxKeys = Object.keys(airlines);

    return checkboxNames.map((name, index) => {
      const checkbox = checkboxKeys.find(key => airlines[key].name === name);

      if (checkbox !== undefined) {
        return (
          <Checkbox
            className={ styles.checkbox }
            key={ `check_airline_${index}_${checkbox}` }
            // id={ `check_airline_${index}_${checkbox}` }
            value={ airlines[checkbox].selected }
            onChange={ value => updateFilters('airline', { checkbox, value }) }
            qaAttr={ qaAttrs?.airlineCompany || '' }
          >
            { name }
          </Checkbox>
        );
      }

      return null;
    });
  }

  renderAirports() {
    if (this.props.isComplex) {
      return null;
    }

    const { filters, routeInfo } = this.props;
    const airports = filters.airports;
    const html: React.ReactNode[] = [];

    airports.forEach((airport, ind) => {
      const { from, to } = airport;
      const info = routeInfo[ind];

      const fromAirportsHtml = this.renderAirportPart('from', from, info.from, LABELS.airports.from(info.from), ind);
      const toAirportsHtml = this.renderAirportPart('to', to, info.to, LABELS.airports.to(info.to), ind);

      if (fromAirportsHtml !== null) {
        html.push(fromAirportsHtml);
      }

      if (toAirportsHtml !== null) {
        html.push(toAirportsHtml);
      }
    });

    return html;
  }

  renderTravelTime() {
    const { filters, routeInfo } = this.props;

    return filters.routesTravelTime.map((item, ind) => this.renderTravelTimePart(item, routeInfo[ind], ind));
  }

  renderBorderTime() {
    const { filters, routeInfo } = this.props;
    const routesBorderTime = filters.routesBorderTime;

    return routesBorderTime.map((item, ind) => this.renderBorderTimePart(item, routeInfo[ind], ind));
  }

  renderDurationTime() {
    const { filters } = this.props;

    if (filters.directTime.border.from !== filters.directTime.border.to) {
      return (
        <Collapse
          opened
          className={ styles.item }
          label={ LABELS.transferDuration }
        >
          <TimeSlider
            min={ filters.directTime.border.from }
            max={ filters.directTime.border.to }
            to={ filters.directTime.to }
            from={ filters.directTime.from }
            onSlide={ this.handleChangeDurationTime }
          />
        </Collapse>
      );
    }

    return null;
  }

  renderFavorite() {
    const {
      isAnyFavorite,
      filters: { favoriteId },
      updateFilters,
    } = this.props;

    if (isSmartAgent || !isAnyFavorite) return null;

    return (
      <Collapse
        opened
        className={ styles.item }
        label={ LABELS.favorite }
      >
        <Checkbox
          className={ styles.checkbox }
          // id={ 'check_favorite_filter' }
          value={ favoriteId }
          onChange={ value => updateFilters('favorite', value) }
        >
          { LABELS.favorite }
        </Checkbox>
      </Collapse>
    );
  }

  renderAirlineCompanies() {
    const {
      filters: { fewAirlineCompanies },
      updateFilters,
      isChangeAirTrip,
      qaAttrs,
    } = this.props;

    if (isChangeAirTrip) return null;

    return (
      <Collapse
        opened
        className={ styles.item }
        label={ LABELS.airlines.label }
      >
        <Checkbox
          className={ styles.checkbox }
          onChange={ value => updateFilters('fewAirlineCompanies', { value }) }
          value={ fewAirlineCompanies }
          qaAttr={ qaAttrs?.fewAirlineCompanies || '' }
        >
          { LABELS.airlines.checkbox }
        </Checkbox>
        <Text
          className={ styles['few-airline-companies-text'] }
          type='NORMAL_12_120'
          color='gray'
        >
          { LABELS.airlines.text }
        </Text>
        { this.renderAirlines() }
      </Collapse>
    );
  }

  renderTicketRefundable() {
    const { filters: { refundable: { Charge, Included } }, updateFilters, qaAttrs } = this.props;

    const refundableQAAttrs = qaAttrs?.refundable || { withCharge: '', withoutCharge: '' };

    return (
      <Collapse
        opened
        className={ styles.item }
        label={ LABELS.refund.label }
      >
        <Checkbox
          className={ styles.checkbox }
          onChange={ value => updateFilters('refundable', { value, checkbox: 'Charge' }) }
          value={ Charge }
          qaAttr={ refundableQAAttrs.withCharge }
        >
          { LABELS.refund.checkboxes.withCharge }
        </Checkbox>
        <Checkbox
          className={ styles.checkbox }
          onChange={ value => updateFilters('refundable', { value, checkbox: 'Included' }) }
          value={ Included }
          qaAttr={ refundableQAAttrs.withoutCharge }
        >
          { LABELS.refund.checkboxes.withoutCharge || '' }
        </Checkbox>
      </Collapse>
    );
  }

  renderBaggage() {
    const { filters: { baggage: { Included10AndMore } }, updateFilters, qaAttrs } = this.props;

    return (
      <Collapse
        opened
        className={ styles.item }
        label={ LABELS.baggage.label }
      >
        <Checkbox
          className={ styles.checkbox }
          onChange={ value => updateFilters('baggage', { value, checkbox: 'Included10AndMore' }) }
          value={ Included10AndMore }
          qaAttr={ qaAttrs?.baggage || '' }
        >
          { LABELS.baggage.checkbox }
        </Checkbox>
      </Collapse>
    );
  }

  renderTravelPolitics = () => {
    const {
      filters,
      travelPolicyList,
      updateFilters,
      unavailableTravelPolicy,
      qaAttrs,
    } = this.props;

    if (filters.travelPolicyList.length > 1) {
      return (
        <Collapse
          opened
          className={ styles.item }
          label={ LABELS.tp }
          qaAttrWrapper={ qaAttrs?.tp || '' }
        >
          <FilterTravelPolicy
            list={ filters.travelPolicyList }
            travelPolicyList={ travelPolicyList }
            readOnly={ unavailableTravelPolicy }
            selected={ filters.selectedTravelPolicy }
            onChangeCheckbox={ value => updateFilters('travelPolicy', { value }) }
          />
        </Collapse>
      );
    }

    return null;
  };

  renderPrice() {
    const { filters: { price }, isChangeAirTrip, qaAttrs } = this.props;

    if (isChangeAirTrip) return null;

    return (
      <Collapse
        opened
        className={ styles.item }
        label={ LABELS.price }
      >
        <PriceSlider
          min={ price.border.from }
          max={ price.border.to }
          start={ price.from }
          end={ price.to }
          // roundBy={ price.roundBy }
          onSlide={ this.handleChangePrice }
          qaAttrPriceMin={ qaAttrs?.priceMin || '' }
          qaAttrPriceMax={ qaAttrs?.priceMax || '' }
        />
      </Collapse>
    );
  }

  handleFlightsNumbersChange = (value: string) => {
    const { updateFilters, airlinesDictionary } = this.props;

    updateFilters('flightsNumbers', { value, airlines: airlinesDictionary });
  };

  renderFlightsNumbers() {
    const { filters: { flightsNumbers }, routeInfo, onBlurAirlineNumber, qaAttrs } = this.props;
    const placeholder = routeInfo.length > 1 ? LABELS.flightNumbers : LABELS.flightNumber;

    return (
      <div className={ styles.item }>
        <Input
          isCleansing
          className={ styles.search }
          value={ flightsNumbers }
          placeholder={ placeholder }
          onChange={ this.handleFlightsNumbersChange }
          onBlur={ onBlurAirlineNumber }
          qaAttr={ qaAttrs?.flightNumber || '' }
        />
      </div>
    );
  }

  renderBackToRequestButton() {
    return this.props.requestId
      ? <BackLink
          link={ `/requests/${this.props.requestId}` }
          text={ LABELS.back }
          alternativeDesign={ isSmartAgent }
      />
      : '';
  }

  render() {
    return (
      <div className={ styles.wrap }>
        { this.renderBackToRequestButton() }
        <FilterPanel>
          { this.renderTravelPolitics() }
          { this.renderPrice() }
          { this.renderBaggage() }
          { this.renderTicketRefundable() }
          { this.renderFavorite() }
          { this.renderFlightsNumbers() }
          { this.renderDirectCount() }
          { this.renderTransferAirports() }
          { this.renderTravelTime() }
          { this.renderBorderTime() }
          { this.renderDurationTime() }
          { this.renderAirports() }
          { this.renderAirlineCompanies() }
        </FilterPanel>
      </div>
    );
  }
}

export { AirlineFilters };
