import React, { Component } from 'react';
import { Button, Datepicker, Select, Text } from 'new-ui';
import moment, { Moment } from 'moment';

import { getText } from '../../../../../../i18n';

import createDateFromTo from '../../../../../bi/utils/createDateFromTo';
import { getMoment, isAfterDate, addDays } from '../../../../../bi/utils/formatDate';

import { generateTravelers } from '../../../../../bi/constants/travelers';
import { DATEFORMATS, PATTERN } from '../../../../../bi/constants/dateFormats';
import { SELECTTRAVELLERS } from '../../../../../bi/constants/hotelsSearch';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import { SearchObjectParams } from '../../../../../bi/services/hotels/types';

import styles from './style.module.css';

const LABELS = {
  PLACING: getText('favorites:dialogs.hotel.placing'),
  TRAVELLERS: getText('favorites:dialogs.hotel.travellers'),
  TITLE: getText('favorites:dialogs.hotel.title'),
  START_SEARCH: getText('favorites:dialogs.hotel.startSearch'),
  CANCEL: getText('common:undo'),
  THERE: getText('favorites:dialogs.hotel.there'),
  BACK: getText('favorites:dialogs.hotel.back'),
};

const FIELDS = {
  CHECKIN: 'checkin',
  CHECKOUT: 'checkout',
  TRAVELLERS: 'travellersCount',
  PLACING_TYPE: 'placingType',
};

type TypeOfFieldData = typeof FIELDS.CHECKIN | typeof FIELDS.CHECKOUT;
type TypeOfFieldTravellers = typeof FIELDS.TRAVELLERS | typeof FIELDS.PLACING_TYPE;

interface HotelSearchStartDialogProps {
  onClose: () => void;
  onSearch: (data: SearchObjectParams) => void;
  qaAttrs: {
    wrapper: string;
    header: string;
    dateFrom: string;
    dateTo: string;
    guests: string;
    placing: string;
    buttons: {
      startSearch: string;
      cancel: string;
    };
  };
}

const TRAVELLERS = generateTravelers(6);

const createState = () => {
  const currentDate = getMoment();

  const { start, end, minStart } = createDateFromTo(currentDate) as { start: Moment, end: Moment, minStart: Moment };

  return {
    checkin: start.set({ hour: 0, minute: 0 }),
    checkout: end.set({ hour: 0, minute: 0 }),
    customCheckin: null,
    customCheckout: null,
    minFrom: minStart,
    travellersCount: TRAVELLERS[0].value,
    placingType: 1,
  };
};

const datesMap: { [key: TypeOfFieldData]: string } = {
  checkin: 'customCheckin',
  checkout: 'customCheckout',
};

class HotelSearchStartDialog extends Component<HotelSearchStartDialogProps> {
  state = {
    ...createState(),
  };

  getHoursAndMinutes = (date: Moment) => ({ hour: date.get('hour'), minute: date.get('minute') });

  handleChangeDate = (field: TypeOfFieldData, value: string | Moment | null, withTime: boolean | string | Moment) => {
    if (withTime) {
      const newValue = value instanceof moment ? value.clone() : null;

      return this.setState({
        [datesMap[field]]: newValue,
      });
    }

    this.setState({
      [datesMap[field]]: null,
    });

    if (field === FIELDS.CHECKIN) {
      const { end, minStart } = createDateFromTo(value as Moment) as { end: Moment, minStart: Moment };
      const { checkout, customCheckout } = this.state;

      const dateTo = isAfterDate(value, checkout)
        ? end
        : checkout;

      return this.setState({
        checkin: value,
        checkout: dateTo,
        customCheckout: customCheckout ? dateTo.clone().set(this.getHoursAndMinutes(customCheckout)) : null,
        minFrom: minStart,
      });
    }

    return this.setState({
      [field]: value,
    });
  };

  handleChangeTravelers = (field: TypeOfFieldTravellers, value: number | string) => {
    if (field === FIELDS.TRAVELLERS && value === 1) {
      this.setState({ placingType: 1 });
    }

    this.setState({ [field]: value });
  };

  render() {
    const { checkin, checkout, customCheckin, customCheckout, minFrom, travellersCount, placingType } = this.state;
    const { onClose, onSearch, qaAttrs } = this.props;

    const preparedCheckin = customCheckin || checkin;
    const preparedCheckout = customCheckout || checkout;
    const checkinFormat = customCheckin ? PATTERN.FULLDATEWITHTIME : DATEFORMATS.DATE;
    const checkoutFormat = customCheckout ? PATTERN.FULLDATEWITHTIME : DATEFORMATS.DATE;

    const placingTypeHtml = travellersCount > 1 && (
      <Select
        withLabel
        theme='default-border'
        value={ placingType }
        className={ styles.select }
        placeholder={ LABELS.PLACING }
        items={ SELECTTRAVELLERS[travellersCount] }
        onChange={ value => this.handleChangeTravelers(FIELDS.PLACING_TYPE, value) }
        qaAttr={ qaAttrs.placing }
      />
    );

    return (
      <div className={ styles.wrapper }>
        <Text type='bold_20' qaAttr={ qaAttrs.header }>
          { LABELS.TITLE }
        </Text>
        <div className={ styles.dates }>
          <Datepicker
            type='dateTimeList'
            inputClassName={ styles.input }
            wrapperClassName={ styles.from }
            value={ preparedCheckin }
            min={ minFrom }
            format={ checkinFormat }
            onChange={ (value, withTime) => this.handleChangeDate(FIELDS.CHECKIN, value, withTime) }
            isDuration
            durationDates={ [preparedCheckin as Moment, preparedCheckout as Moment] }
            qaAttr={ qaAttrs.dateFrom }
            qaAttrNextMonth={ QA_ATTRIBUTES.favorites.chooseDateDialog.checkInNextMonth }
          />
          <Datepicker
            type='dateTimeList'
            direction='to'
            inputClassName={ styles.input }
            wrapperClassName={ styles.to }
            value={ preparedCheckout }
            min={ addDays(checkin as Moment) }
            format={ checkoutFormat }
            onChange={ (value, withTime) => this.handleChangeDate(FIELDS.CHECKOUT, value, withTime) }
            isDuration
            durationDates={ [preparedCheckin as Moment, preparedCheckout as Moment] }
            qaAttr={ qaAttrs.dateTo }
          />
        </div>
        <Select
          withLabel
          theme='default-border'
          className={ styles.select }
          value={ travellersCount }
          placeholder={ LABELS.TRAVELLERS }
          items={ TRAVELLERS }
          onChange={ value => this.handleChangeTravelers(FIELDS.TRAVELLERS, value) }
          qaAttr={ qaAttrs.guests }
        />
        { placingTypeHtml }
        <div className={ styles.action }>
          <Button
            onClick={ () => onSearch({
              dateFrom: checkin as Moment,
              dateTo: checkout as Moment,
              travellersCount: travellersCount as number,
              placingType,
              timeFrom: customCheckin as Moment | null,
              timeTo: customCheckout as Moment | null,
            }) }
            qaAttr={ qaAttrs.buttons.startSearch }
          >
            { LABELS.START_SEARCH }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual-medium'
            onClick={ onClose }
            qaAttr={ qaAttrs.buttons.cancel }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </div>
    );
  }
}

export { HotelSearchStartDialog };
