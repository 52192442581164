import React from 'react';
import { Skeleton } from 'new-ui';

import { FilterPanel } from '../../../../../../components/FilterPanel';
import { CollapseSkeleton } from '../../../../../../components/Skeletons/CollapseSkeleton';
import { PriceSliderSkeleton } from '../../../../../../components/Skeletons/PriceSliderSkeleton';
import { CheckboxSkeleton } from '../../../../../../components/Skeletons/CheckboxSkeleton';

import styles from '../../styles/index.module.css';
import filtersStyles from '../../../Filters/style.module.css';

const FiltersSkeleton = () => {
  const renderDistanceFromCenter = () => (
    <div className={ filtersStyles.block }>
      <CollapseSkeleton width={ 145 }>
        <div className={ filtersStyles.distance }>
          <Skeleton width={ 15 } height={ 10 }/>
          <Skeleton className={ filtersStyles.distance_input } width={ 255 } height={ 38 }/>
        </div>
      </CollapseSkeleton>
    </div>
  );

  const renderPricePerNight = () => (
    <div className={ filtersStyles.block }>
      <CollapseSkeleton width={ 126 }>
        <PriceSliderSkeleton />
      </CollapseSkeleton>
    </div>
  );

  const renderBreakfast = () => (
    <div className={ `${filtersStyles.block} ${filtersStyles.breakfast}` }>
      <CheckboxSkeleton>
        <Skeleton width={ 225 } height={ 14 }/>
      </CheckboxSkeleton>
    </div>
  );

  const renderRefundability = () => (
    <div className={ `${filtersStyles.block} ${filtersStyles.refundability}` }>
      <CheckboxSkeleton>
        <Skeleton width={ 225 } height={ 14 }/>
      </CheckboxSkeleton>
    </div>
  );

  const renderOnline = () => (
    <div className={ `${filtersStyles.block} ${filtersStyles.online}` }>
      <CheckboxSkeleton>
        <Skeleton width={ 225 } height={ 14 }/>
      </CheckboxSkeleton>
    </div>
  );

  const renderSmartHotels = () => (
    <div className={ `${filtersStyles.block} ${filtersStyles.smart}` }>
      <CheckboxSkeleton>
        <Skeleton width={ 225 } height={ 14 } className={ filtersStyles.text }/>
      </CheckboxSkeleton>
      <Skeleton width={ 254 } height={ 14 } className={ filtersStyles.hint }/>
    </div>
  );

  const renderFavoriteHotels = () => (
    <div className={ filtersStyles.block }>
      <CollapseSkeleton width={ 126 }>
        <CheckboxSkeleton className={ filtersStyles.favorite }>
          <Skeleton width={ 225 } height={ 14 } />
        </CheckboxSkeleton>
      </CollapseSkeleton>
    </div>
  );

  const renderHotelName = () => (
    <div className={ `${filtersStyles.block} ${filtersStyles.name}` }>
      <Skeleton width={ 280 } height={ 38 } className={ styles.input } />
    </div>
  );

  return (
    <div className={ filtersStyles.wrap }>
      <FilterPanel>
        <div className={ `${filtersStyles.blocks}` }>
          <div>
            { renderDistanceFromCenter() }
          </div>
          { renderPricePerNight() }
          <div>
            { renderBreakfast() }
            { renderRefundability() }
            { renderOnline() }
          </div>
          { renderSmartHotels() }
          { renderFavoriteHotels() }
          { renderHotelName() }
        </div>
      </FilterPanel>
    </div>
  );
};

export { FiltersSkeleton };
