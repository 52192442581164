import React from 'react';
import { Skeleton } from 'new-ui';

import { FiltersSkeleton } from './components/FilterSkeleton/index';
import { TicketItemSkeleton } from './components/TicketItemSkeleton/index';

import styles from '../../styles/skeleton.module.css';

const AirlinesResultSkeleton = () => {
  const htmlList = [...Array(5).keys()].map((_, index) => <TicketItemSkeleton key={ index }/>);

  return (
    <div className={ styles.wrapper }>
      <FiltersSkeleton />
      <section className={ styles.content }>
        <Skeleton className={ styles.sort } width={ 280 } height={ 42 }/>
        { htmlList }
      </section>
    </div>
  );
};

export { AirlinesResultSkeleton };
