import React from 'react';
import { Text } from 'new-ui';
import { getText, currentLng } from '../../../../../i18n';

import { SERVICETYPE, SERVICETYPERUMN } from '../../../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { ServiceTypeSmall } from '../../../../bi/types/order';

import styles from './index.module.css';

const TRAVEL_POLICY_TEXT = getText('settings:travelPolicy.travelPolicy');

type PolicyRuleHeaderTextProps = {
  name: string,
  isUnique?: boolean,
  apply: boolean,
  serviceType: ServiceTypeSmall,
  exceptionTitle?: string,
};

type PolicyRuleHeaderProps = {
  name: string,
  isUnique?: boolean,
  apply: boolean,
  serviceType: ServiceTypeSmall,
};

const LABELS = {
  THIS: getText('settings:travelPolicy.this'),
  EMPLOYEE_MAY: (name: string) => getText('settings:travelPolicy.policyRuleHeader.employeesMay', { name }),
  WITH_APPROVE: getText('settings:travelPolicy.policyRuleHeader.withoutApprove'),
  BUY: (text: string) => getText('settings:travelPolicy.policyRuleHeader.buy', { text }),
  ANY: getText('settings:travelPolicy.any'),
  UNIQUE: getText('settings:travelPolicy.policyRuleHeader.unique'),
};

const prepareTpName = (name: string) => {
  if (name) {
    return `${TRAVEL_POLICY_TEXT} ${name}`;
  }

  return `${LABELS.THIS} ${currentLng !== 'ru' ? TRAVEL_POLICY_TEXT.toLowerCase() : TRAVEL_POLICY_TEXT}`;
};

const EXCEPTION_TITLE_LABELS = {
  [SERVICETYPE.AIR]: getText('settings:travelPolicy.exceptionLabels.forAir'),
  [SERVICETYPE.TRAIN]: getText('settings:travelPolicy.exceptionLabels.forTrips'),
  [SERVICETYPE.HOTEL]: getText('settings:travelPolicy.exceptionLabels.forTrips'),
  [SERVICETYPE.TAXI]: getText('settings:travelPolicy.exceptionLabels.forTrips'),
  [SERVICETYPE.TRANSFER]: getText('settings:travelPolicy.exceptionLabels.forTrips'),
};

const SERVICE_TYPE_LABELS = {
  [SERVICETYPE.AIR]: getText('settings:travelPolicy.serviceLabels.air'),
  [SERVICETYPE.HOTEL]: getText('settings:travelPolicy.serviceLabels.hotel'),
  [SERVICETYPE.TRAIN]: getText('settings:travelPolicy.serviceLabels.train'),
  [SERVICETYPE.TAXI]: getText('settings:travelPolicy.serviceLabels.taxi'),
  [SERVICETYPE.TRANSFER]: getText('settings:travelPolicy.serviceLabels.transfer'),
  [SERVICETYPE.VIP_HALL]: getText('settings:travelPolicy.serviceLabels.vipHall'),
};

const prepareAppliedTpText = (serviceType: string, exceptionTitle = '') =>
  (exceptionTitle
    ? `${SERVICE_TYPE_LABELS[serviceType]} ${EXCEPTION_TITLE_LABELS[serviceType]} ${exceptionTitle}: `
    : `${SERVICE_TYPE_LABELS[serviceType]}: `);

const preparePurchaseAvailabilityText = (apply: boolean, serviceType: string, exceptionTitle = '') =>
  (apply ? prepareAppliedTpText(serviceType, exceptionTitle) : `${LABELS.ANY} ${SERVICE_TYPE_LABELS[serviceType]}`);

const PolicyRuleHeaderText = ({ name, isUnique, apply, serviceType, exceptionTitle = '' }: PolicyRuleHeaderTextProps) => {
  const preparedUniqueName = isUnique ? LABELS.UNIQUE : prepareTpName(name);

  return (
    <Text
      className={ styles.header }
      type='NORMAL_16_140'
    >
      { LABELS.EMPLOYEE_MAY(preparedUniqueName) }
      <Text
        type='SEMIBOLD_16'
        className={ styles.semitext }
      >
        { LABELS.WITH_APPROVE }
      </Text>
      { LABELS.BUY(preparePurchaseAvailabilityText(apply, serviceType, exceptionTitle)) }
    </Text>
  );
};

const PolicyRuleHeader = ({ name, apply, serviceType, isUnique }: PolicyRuleHeaderProps) => {
  const policyRuleHeaderTextCheck = (serviceType !== SERVICETYPE.TAXI && serviceType !== SERVICETYPE.VIP_HALL) && (
    <PolicyRuleHeaderText
      { ...{ name, isUnique, apply, serviceType } }
    />
  );

  const serviceTypeLowerCase = serviceType.toLowerCase() as PolicyRuleHeaderProps['serviceType'];

  return (
    !isUnique || (isUnique && apply) ? (
      <>
        <Text
          type='bold_24'
          className={ styles.type }
          qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.titles[serviceTypeLowerCase] }
        >
          { SERVICETYPERUMN[serviceType] }
        </Text>
        { policyRuleHeaderTextCheck }
      </>
    ) : null
  );
};

export { PolicyRuleHeader, PolicyRuleHeaderText };
