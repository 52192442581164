import { action, computed, makeObservable, observable } from 'mobx';

import { AirException, TravelPolicyAirlineRule } from './types';
import type { BaseServiceTypeTravelPolicyStore } from '../../types';

import {
  TRAVEL_POLICY_DEFAULT_PRICE,
  TRAVEL_POLICY_DEFAULT_ROUTE,
  TRAVEL_POLICY_FIELDS_DICTIONARIES,
  TRAVEL_POLICY_STORE_FIELDS,
} from '../../consts';
import {
  filterNewAndRemovedExceptions,
  mapTravelPolicyDirections,
  mapTravelPolicyExceptions,
  mapTravelPolicyRoutes,
} from '../../utils';
import { SERVICETYPE } from '../../../../constants/serviceType';

const DEFAULT_GENERAL_RULES = {
  [TRAVEL_POLICY_STORE_FIELDS.ONE_WAY]: {
    IsEnabled: false,
    Value: TRAVEL_POLICY_DEFAULT_PRICE,
  },
  [TRAVEL_POLICY_STORE_FIELDS.NOT_GREATER_PRICE]: {
    IsEnabled: false,
    ValueType: TRAVEL_POLICY_FIELDS_DICTIONARIES.NOT_GREATER_PRICE_TYPE.RUBLES,
    Value: TRAVEL_POLICY_DEFAULT_PRICE,
  },
  [TRAVEL_POLICY_STORE_FIELDS.DAYS_COUNT]: {
    IsEnabled: false,
    Days: 7,
  },
  [TRAVEL_POLICY_STORE_FIELDS.CLASS]: {
    IsEnabled: false,
    Value: TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.ECONOM,
  },
  [TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE]: {
    IsEnabled: false,
  },
  [TRAVEL_POLICY_STORE_FIELDS.DAY_OFF]: {
    IsEnabled: false,
  },
  [TRAVEL_POLICY_STORE_FIELDS.INCLUDED_BAGGAGE]: {
    IsEnabled: false,
  },
};

const TRAVEL_POLICY_AIR_DEFAULT_EXCEPTION = {
  isRemoved: false,
  isNew: true,
  Type: TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.HOURS,
  Hours: 6,
  Routes: [{ ...TRAVEL_POLICY_DEFAULT_ROUTE }],
  ...DEFAULT_GENERAL_RULES,
};

const DEFAULT_STORE = {
  [TRAVEL_POLICY_STORE_FIELDS.APPLY]: false,
  ...DEFAULT_GENERAL_RULES,
  [TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS]: {
    IsEnabled: false,
    showDialog: false,
    showValidation: false,
    Type: TRAVEL_POLICY_FIELDS_DICTIONARIES.DIRECTION_TYPES.RUSSIA,
    Routes: [{ ...TRAVEL_POLICY_DEFAULT_ROUTE }],
  },
  [TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS]: {
    dialog: { show: false, step: 0, chosenIndex: null },
    list: [] as AirException[],
  },
};

class AirTravelPolicyStore implements BaseServiceTypeTravelPolicyStore {
  constructor() {
    makeObservable(this);
  }

  @observable store = DEFAULT_STORE;

  @computed
  get preparedToSaveModel(): TravelPolicyAirlineRule {
    const { Exceptions, Refundable, IncludedBaggage, ...rest } = this.store;

    const preparedExceptions = filterNewAndRemovedExceptions(Exceptions.list)
      .map(ei => ({
        ...ei,
        [TRAVEL_POLICY_STORE_FIELDS.TARIFF]: {
          [TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE]: ei[TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE].IsEnabled,
          [TRAVEL_POLICY_STORE_FIELDS.INCLUDED_BAGGAGE]: ei[TRAVEL_POLICY_STORE_FIELDS.INCLUDED_BAGGAGE].IsEnabled,
        },
        [TRAVEL_POLICY_STORE_FIELDS.DAY_OFF]: ei[TRAVEL_POLICY_STORE_FIELDS.DAY_OFF].IsEnabled,
      }));

    return {
      ...rest,
      [TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS]: preparedExceptions,
      [TRAVEL_POLICY_STORE_FIELDS.TARIFF]: {
        [TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE]: this.store[TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE].IsEnabled,
        [TRAVEL_POLICY_STORE_FIELDS.INCLUDED_BAGGAGE]: this.store[TRAVEL_POLICY_STORE_FIELDS.INCLUDED_BAGGAGE].IsEnabled,
      },
      [TRAVEL_POLICY_STORE_FIELDS.DAY_OFF]: this.store[TRAVEL_POLICY_STORE_FIELDS.DAY_OFF].IsEnabled,
    };
  }

  @computed
  get mappedDirections() {
    const value = this.store[TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS];

    return {
      ...value,
      Routes: mapTravelPolicyDirections(value.Routes, SERVICETYPE.AIR),
    };
  }

  @computed
  get mappedExceptions() {
    const value = this.store[TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS];

    return {
      ...value,
      list: mapTravelPolicyExceptions(value.list, SERVICETYPE.AIR),
    };
  }

  @action
  mapServerModelToClient = (model: TravelPolicyAirlineRule) => {
    const { Tariff = {}, Exceptions = [], Directions, ...rest } = model;

    const { Refundable, IncludedBaggage } = Tariff;
    const { Routes } = Directions;

    this.store = {
      ...this.store,
      ...rest,
      [TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE]: {
        IsEnabled: Refundable,
      },
      [TRAVEL_POLICY_STORE_FIELDS.INCLUDED_BAGGAGE]: {
        IsEnabled: IncludedBaggage,
      },
      [TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS]: {
        ...this.store[TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS],
        ...Directions,
        Routes: mapTravelPolicyRoutes(Routes),
      },
      [TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS]: {
        ...this.store[TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS],
        list: Exceptions.map(ei =>
          ({
            ...ei,
            [TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE]: {
              IsEnabled: ei.Tariff.Refundable,
            },
            [TRAVEL_POLICY_STORE_FIELDS.DAY_OFF]: {
              IsEnabled: ei.DayOff.IsEnabled,
            },
            [TRAVEL_POLICY_STORE_FIELDS.INCLUDED_BAGGAGE]: {
              IsEnabled: ei.Tariff.IncludedBaggage,
            },
            Routes: mapTravelPolicyRoutes(ei.Routes),
          }),
        ),
      },
    } as typeof this.store;
  };

  @action
  updateStore = (payload: any): void => {
    this.store = {
      ...this.store,
      ...payload,
    };
  };

  @action
  clearStore = (): void => {
    this.store = DEFAULT_STORE;
  };
}

export default AirTravelPolicyStore;

export { TRAVEL_POLICY_AIR_DEFAULT_EXCEPTION };
