import React, { useState, useEffect, FC } from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { Table } from './table';

import { PackagesItems, ReportService } from '../../../../bi/types/report';
import { WorkspaceService } from '../../../../bi/types/workspace';

import styles from '../../styles/report-upd.module.css';

const LABELS = {
  TITLE: getText('reports:documents.title'),
};

interface DocumentPackagesProps {
  reportService: ReportService,
  workspaceService: WorkspaceService,
  currentCompany: number,
}

const renderCloseDocumentsText = (currentCompany = 0, workspaceService: WorkspaceService) => {
  const text = workspaceService.getPeriodSchemeDescription(currentCompany);

  return <Text type='NORMAL_16_140'>{text}</Text>;
};

const DocumentPackages: FC<DocumentPackagesProps> = ({ reportService, workspaceService, currentCompany = 0 }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState(reportService.getPackages().items);

  const updateDocuments = ({ loading: updateLoading, items: updateItems }: { loading: boolean, items: PackagesItems[] }): void => {
    setLoading(updateLoading);
    setItems(updateItems);
  };

  useEffect((): void => {
    const unsubscribeFn = reportService.subscribePackages(updateDocuments);
    reportService.loadDocumentPackages(currentCompany);

    setLoading(true);

    return unsubscribeFn;
  }, [currentCompany, reportService]);

  return (
    <>
      <Text type='bold_24' className={ styles.title }>{LABELS.TITLE}</Text>
      <div className={ styles.description }>
        { renderCloseDocumentsText(currentCompany, workspaceService) }
      </div>
      <Table
        items={ items }
        loading={ loading }
        currentCompany={ currentCompany }
        reportService={ reportService }
      />
    </>
  );
};

export { DocumentPackages };
