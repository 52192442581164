import React from 'react';

import { ConsistentCartItem } from '../ConsistentCartItem';

import { ConsistentCartModes } from '../../../../../bi/constants/approve';

export interface IListItem {
  id: string;
  sources: object[]
}

interface IConsistentCartListProps<Item = IListItem> {
  list: Item[]
  type: string
  onDelete?: (i: Item) => void
  onPurchase: (i: Item, isUnderAge: boolean, val: boolean) => void
  onClick?: (i: Item) => void
  onTimeIsUp: (i: object) => any
  readonly: boolean
  mode: ConsistentCartModes
  checkUnderage: boolean
  qaAttrFirstEl: string
  qaAttr: string
  showApproversCKR: boolean
}

const ConsistentCartList = ({
  list = [],
  type,
  onDelete,
  onPurchase,
  onClick,
  onTimeIsUp = () => {},
  readonly = false,
  mode = ConsistentCartModes.viewer,
  checkUnderage,
  qaAttrFirstEl = '',
  qaAttr = '',
  showApproversCKR,
}: IConsistentCartListProps) => {
  const html = list.map(
    (item, index) => {
      const qaAttrFirst = index === 0 ? qaAttrFirstEl : '';
      const qaAttrResult = qaAttr || qaAttrFirst;

      return (
        <ConsistentCartItem
          key={ item.id }
          type={ type }
          item={ item }
          onDelete={ onDelete }
          onPurchase={ onPurchase }
          onClick={ onClick }
          onTimeIsUp={ onTimeIsUp }
          readonly={ readonly }
          mode={ mode }
          checkUnderage={ checkUnderage }
          qaAttr={ qaAttrResult }
          showApproversCKR={ showApproversCKR }
        />
      );
    },
  );

  return (
    <>
      { ...html }
    </>
  );
};

export { ConsistentCartList };
