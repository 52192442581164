import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, RadioButton, StyledWrapper, Text, Tooltip } from 'new-ui';

import { getText } from '../../../../i18n';

import { useStores } from '../../../bi/context';
import { MOBX_STORES } from '../../../bi/context/stores';

import { isSmartAgent } from '../../../bi/utils/env';

import {
  TRAVEL_APPROVAL,
  TRAVEL_APPROVAL_RU,
  APPROVAL,
  APPROVAL_RU,
  StatusScheme,
} from '../../../bi/constants/approvalSchemes';
import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import styles from '../styles/questionnaire.module.css';

const LABELS = {
  TITLE: getText('approvalScheme:questionnaire.title'),
  WHO_BUYS: getText('approvalScheme:questionnaire.1.label'),
  TRAVEL_APPROVAL: getText('approvalScheme:questionnaire.2.label'),
  APPROVAL: getText('approvalScheme:questionnaire.3.label'),
  TOOLTIP: getText('approvalScheme:questionnaire.button.tooltip'),
  BUTTON: getText('approvalScheme:questionnaire.button.label'),
  SETTING_EMPLOYEES: {
    TEXT: getText('approvalScheme:questionnaire.settingEmployees.text'),
    BUTTON: getText('approvalScheme:questionnaire.settingEmployees.button'),
    LINK: getText('approvalScheme:questionnaire.settingEmployees.link'),
  },
};

interface IQuestionnaire {
  status: string,
  onChangeWhoBuys(id: string, checked: boolean): void,
  onChangeTravelApproval(type: string): void,
  onChangeApproval(type: string): void,
  onContinue(): void,
  onSettingEmployees(): void,
  onBack(): void,
}

const Questionnaire = observer(({
  status,
  onChangeWhoBuys,
  onChangeTravelApproval,
  onChangeApproval,
  onContinue,
  onSettingEmployees,
  onBack,
}: IQuestionnaire) => {
  const { settingSchemeStore } = useStores([MOBX_STORES.SETTING_SCHEME]);

  const {
    whoBuys,
    travelApproval,
    approval,
    isTravelApprovalUnavailable,
    isApprovalEvery,
    isApprovalDisrupted_TP,
    isApprovalUnavailable,
    isValid,
  } = settingSchemeStore;

  const renderQuestion = (question: string, answers: () => ReactNode) => (
    <div className={ styles.block }>
      <Text type='NORMAL_16_140'>{ question }</Text>
      <div className={ styles.answers }>{ answers() }</div>
    </div>
  );

  const whoBuysAnswers = () => whoBuys.map(({ value, name, id }, index) => {
    const qaAttrListBuyer = `${QA_ATTRIBUTES.approvalSchemes.trips.questionnaire.whoBuys}-${index}`;

    return (
      <Checkbox
        key={ `first_answers_${id}` }
        className={ styles.answer }
        value={ value }
        onChange={ checked => onChangeWhoBuys(id, checked) }
        qaAttr={ qaAttrListBuyer }
      >
        <Text type='NORMAL_14'>{ name }</Text>
      </Checkbox>
    );
  });

  const travelApprovalAnswers = () => (
    <>
      <RadioButton
        className={ styles.answer }
        checked={ isTravelApprovalUnavailable }
        value={ travelApproval }
        alternativeDesign={ isSmartAgent }
        onChange={ () => onChangeTravelApproval(TRAVEL_APPROVAL.UNAVAILABLE) }
      >
        <Text type='NORMAL_14'>{ TRAVEL_APPROVAL_RU.UNAVAILABLE }</Text>
      </RadioButton>
      <RadioButton
        className={ styles.answer }
        checked={ !isTravelApprovalUnavailable }
        value={ travelApproval }
        alternativeDesign={ isSmartAgent }
        onChange={ () => onChangeTravelApproval(TRAVEL_APPROVAL.AVAILABLE) }
        qaAttr={ QA_ATTRIBUTES.approvalSchemes.trips.questionnaire.travelApproval }
      >
        <Text type='NORMAL_14'>{ TRAVEL_APPROVAL_RU.AVAILABLE }</Text>
      </RadioButton>
    </>
  );

  const approvalAnswers = () => (
    <>
      <RadioButton
        className={ styles.answer }
        checked={ isApprovalEvery }
        value={ approval }
        alternativeDesign={ isSmartAgent }
        onChange={ () => onChangeApproval(APPROVAL.EVERY) }
      >
        <Text type='NORMAL_14'>{ APPROVAL_RU.EVERY }</Text>
      </RadioButton>
      <RadioButton
        className={ styles.answer }
        checked={ isApprovalDisrupted_TP }
        value={ approval }
        alternativeDesign={ isSmartAgent }
        onChange={ () => onChangeApproval(APPROVAL.DISRUPTED_TP) }
      >
        <Text type='NORMAL_14'>{ APPROVAL_RU.DISRUPTED_TP }</Text>
      </RadioButton>
      <RadioButton
        className={ styles.answer }
        checked={ isApprovalUnavailable }
        value={ approval }
        alternativeDesign={ isSmartAgent }
        onChange={ () => onChangeApproval(APPROVAL.UNAVAILABLE) }
      >
        <Text type='NORMAL_14'>{ APPROVAL_RU.UNAVAILABLE }</Text>
      </RadioButton>
    </>
  );

  const tooltip = () => (
    <Text
      className={ styles.tooltip }
      color='white'
      type='NORMAL_14_130'
    >
      { LABELS.TOOLTIP }
    </Text>
  );

  const isEnabledButton = isValid ? QA_ATTRIBUTES.approvalSchemes.trips.questionnaire.buttonContinue : '';

  const renderCreateStatus = () => status === StatusScheme.CREATE && (
    <>
      { renderQuestion(LABELS.WHO_BUYS, whoBuysAnswers) }
      { renderQuestion(LABELS.TRAVEL_APPROVAL, travelApprovalAnswers) }
      { renderQuestion(LABELS.APPROVAL, approvalAnswers) }
      <div className={ styles.button }>
        <Tooltip
          show={ !isValid }
          position='right'
          renderContent={ tooltip }
        >
          <Button
            disabled={ !isValid }
            type='secondary'
            onClick={ onContinue }
            qaAttr={ isEnabledButton }
          >
            { LABELS.BUTTON }
          </Button>
        </Tooltip>
      </div>
    </>
  );

  const renderSettingEmployeesStatus = () => status === StatusScheme.SETTING_EMPLOYEES && (
    <div className={ styles.setting_employees }>
      <Text type='NORMAL_16_140'>
        { LABELS.SETTING_EMPLOYEES.TEXT }
      </Text>
      <div className={ styles.actions }>
        <Button
          type='secondary'
          onClick={ onSettingEmployees }
        >
          { LABELS.SETTING_EMPLOYEES.BUTTON }
        </Button>
        <Button
          className={ styles.back }
          type='textual-medium'
          onClick={ onBack }
        >
          {LABELS.SETTING_EMPLOYEES.LINK}
        </Button>
      </div>
    </div>
  );

  return (
    <StyledWrapper className={ styles.wrapper } qaAttr={ QA_ATTRIBUTES.approvalSchemes.trips.questionnaire.wrapper }>
      <Text type='bold_18'>{ LABELS.TITLE }</Text>
      { renderCreateStatus() }
      { renderSettingEmployeesStatus() }
    </StyledWrapper>
  );
});

export { Questionnaire };
