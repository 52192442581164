import React, { useEffect } from 'react';
import { Button, Dropdown } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useServices } from '../../../../bi/context/services';

import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import styles from '../../index.module.css';

const LABELS = {
  LINK_TO: getText('settings:employees.employee.telegram.linkTo'),
  COPY_LINK: getText('settings:employees.employee.telegram.linkCopy'),
  LINK_COPIED: getText('settings:employees.employee.telegram.linkCopied'),
  SEND_TO_MAIL: getText('trip:documents.sendToEmail'),
  UNLINK: getText('settings:employees.employee.telegram.unlinkAccount'),
};

interface ITgOptionsProps {
  id: number,
  openModal: (value: boolean) => void
}

export const TgOptions = ({ id, openModal }: ITgOptionsProps) => {
  const { employeeService } = useServices(['Employee']);

  useEffect(() => {
    employeeService.loadLinkedTgAccount(id);
  }, [id]);

  if (isSmartAgent) {
    return null;
  }

  const handleUnlinkClick = () => {
    employeeService.unlinkTgAccount(id);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.UNLINK_TG_BOT);
  };

  if (employeeService.get().isTgLinked) {
    return (
      <Button onClick={ handleUnlinkClick }>
        { LABELS.UNLINK }
      </Button>
    );
  }

  return (
    <Dropdown
      hoverOpenMenu
      title={ LABELS.LINK_TO }
      onClick={ () => {} }
    >
      <div className={ styles.dropdownTelegram }>
        <Button
          type='textual'
          onClick={ () => employeeService.getTgLink(id, LABELS.LINK_COPIED) }
        >
          { LABELS.COPY_LINK }
        </Button>
        <Button
          type='textual'
          onClick={ () => openModal(true) }
        >
          { LABELS.SEND_TO_MAIL }
        </Button>
      </div>
    </Dropdown>
  );
};
