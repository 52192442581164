import { Text } from 'new-ui';
import React from 'react';

import { formatDate } from '../../../../bi/utils/formatDate';
import { FIRST_LETTER } from '../../../../bi/constants/regExp';
import { TrainHistoryItem, Station } from '../../../../bi/types/train';

import styles from './index.module.css';

interface ITrainHistoryItem {
  item: TrainHistoryItem,
}

const TrainsHistoryItem: React.FC<ITrainHistoryItem> = ({ item: { DepartureDate, StationFrom, StationTo } }) => {
  const getCityOrStation = (station: Station) => {
    const nameStr = station.City || station.Name || '';
    const lowerCaseName = nameStr.toLowerCase();

    return lowerCaseName.replace(FIRST_LETTER, (word) => word.toUpperCase());
  };

  const location = `${getCityOrStation(StationFrom)} – ${getCityOrStation(StationTo)}`;

  return (
    <>
      <Text type='NORMAL_14' color='gray'>{formatDate(DepartureDate)}</Text>
      <Text type='NORMAL_14' className={ styles.label }>{location}</Text>
    </>
  );
};

export { TrainsHistoryItem };
