import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { BreadCrumbs } from 'new-ui';

import { getText } from '../../../i18n';

import App from '../../bi/services/app';

import Search from '../../components/Search';
import { SearchLoading } from '../../components/SearchLoading';
import { ChangeAirTripHeaderResult } from './components/ChangeAirTripHeaderResult';
import { ChangeAirTripResult } from './components/ChangeAirTripResult';
import { ChangeAirTripApproveResult } from './components/ChangeAirTripApproveResult';

import { contentCrumbsChangeAir } from '../../bi/utils/changeAirTrip';

import ROUTES from '../../bi/constants/routes';
import { PATH } from '../../bi/constants/changeAirTrip';
import { HISTORY_ACTIONS } from '../../bi/constants/history';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import {
  AirlineService,
  AirlineMatch,
  AirSearchRoute,
  AirlineResultData,
  AirlineSource,
  AirRouteInfo,
  AirlineCacheItem,
} from '../../bi/types/airline';
import {
  OrderService,
  IOrderDetailsData,
  OrderStore,
} from '../../bi/types/order';
import { WorkspaceService } from '../../bi/types/workspace';
import { UserSessionService } from '../../bi/types/userSession';
import { ChatService } from '../../bi/types/chat';
import { IFeatureFlagsService } from '../../bi/types/featureFlags';
import { ITravelPolicyListItem } from '../../bi/types/travelPolicy';

import styles from './styles/index.module.css';

const LABELS = {
  SEARCH: getText('air:result.loader'),
};

const {
  changeTrip: {
    air: {
      byDateDirectionResult: {
        pageLoading,
        searchLoading: searchLoadingTest,
      },
    },
  },
} = QA_ATTRIBUTES;

interface ChangeAirTripDateOrDirectionResultPageProps {
  airlineService: AirlineService,
  orderService: OrderService,
  chatService: ChatService,
  workspaceService: WorkspaceService,
  userSessionService: UserSessionService,
  featureFlagsService: IFeatureFlagsService,
  match: AirlineMatch,
  appService: App,
  history: RouteComponentProps['history'],
}

const ChangeAirTripDateOrDirectionResultPage = ({
  airlineService,
  orderService,
  chatService,
  workspaceService,
  appService,
  userSessionService,
  featureFlagsService,
  match: {
    path,
    params: { tripId, ticketId, guid },
  },
  history,
}: ChangeAirTripDateOrDirectionResultPageProps) => {
  const [dataInfo, setDataInfo] = useState<AirlineResultData | null>(null);
  const [routesInfo, setRoutesInfo] = useState<AirRouteInfo[]>([]);
  const [sourcesInfo, setSourcesInfo] = useState<AirlineSource[]>([]);
  const [routesSearch, setRoutesSearch] = useState<AirSearchRoute[]>([]);
  const [loading, setLoading] = useState(true);
  const [travelPolicyList, setTravelPolicyList] = useState<ITravelPolicyListItem[]>([]);
  const [detailsData, setDetailsData] = useState<IOrderDetailsData | null>(null);
  const [showApproveChange, setShowApproveChange] = useState(false);
  const [newTicket, setNewTicket] = useState<AirlineCacheItem | null>(null);
  const [fareId, setFareId] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);

  const isComplex = airlineService.getSearch().isComplex;
  const tripItem = detailsData && detailsData.OrderItems.find(ticket => ticket.Id === +ticketId);
  const ticketData = tripItem && JSON.parse(tripItem.ActualVersion.JsonData);

  const loadOrderServiceInfo = async () => {
    orderService.start();
    await orderService.load(tripId);
  };

  const updateStateAirline = (data: AirlineResultData) => {
    setDataInfo(data);
    setRoutesInfo(data.routeInfo);
  };

  const updateStateOrder = (data: OrderStore) => {
    setDetailsData(data.details);
  };

  const searchByGuid = async () => {
    const rightsBuyTrip = workspaceService.rights;
    const accountTravelPolicy = workspaceService.travelPolicy;
    const travelPolicyAllList = userSessionService.get().travelPolicies;

    const settings = {
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyAllList,
    };

    setLoading(true);

    loadOrderServiceInfo();
    await airlineService.searchByGuid(guid, settings);

    airlineService.setDefaultSortValue();

    setLoading(false);
  };

  useEffect(() => {
    const data = airlineService.getResult();
    const { routes } = airlineService.getSearch();

    setDataInfo(data);
    setSourcesInfo(data.sources);
    setRoutesInfo(data.routeInfo);
    setRoutesSearch(routes);
    setTravelPolicyList(userSessionService.get().travelPolicies);

    loadOrderServiceInfo();

    const unsubscribeFnAirlineService = airlineService.subscribeResult(updateStateAirline);
    const unsubscribeFnOrderService = orderService.subscribeCart(updateStateOrder);

    if (history.action === HISTORY_ACTIONS.POP && sourcesInfo.length === 0) {
      searchByGuid();
    }

    if (history.action === HISTORY_ACTIONS.PUSH) {
      setLoading(false);
    }

    return () => {
      // @ts-ignore
      unsubscribeFnAirlineService();
      // @ts-ignore
      unsubscribeFnOrderService();
    };
  }, []);

  const handleAddTicketChanged = (item: AirlineCacheItem, currentFareId: string) => {
    setFareId(currentFareId);
    setNewTicket(item);
    setShowApproveChange(true);
  };

  const renderChangeAirTripHeaderResult = !showApproveChange && (
    <ChangeAirTripHeaderResult
      airlineService={ airlineService }
      workspaceService={ workspaceService }
      userSessionService={ userSessionService }
      history={ history }
      ticketData={ ticketData }
      routesInfo={ routesInfo }
      routesSearch={ routesSearch }
      tripId={ tripId }
      ticketId={ ticketId }
      path={ path }
      setLoadingSearch={ setLoadingSearch }
    />
  );

  const renderHeader = () => (
    <div className={ styles.wrapper_header }>
      <BreadCrumbs
        className={ styles.bread_crumbs }
        content={ contentCrumbsChangeAir(tripId, ticketId, path) }
      />
      { renderChangeAirTripHeaderResult }
    </div>
  );

  const renderContent = showApproveChange ? (
    <ChangeAirTripApproveResult
      history={ history }
      chatService={ chatService }
      newTicket={ newTicket }
      fareId={ fareId }
      tripItem={ tripItem }
      tripId={ tripId }
      isDemo={ workspaceService.isDemo }
      onShowApproveChange={ setShowApproveChange }
    />
  ) : (
    <ChangeAirTripResult
      airlineService={ airlineService }
      workspaceService={ workspaceService }
      featureFlagsService={ featureFlagsService }
      userSessionService={ userSessionService }
      chatService={ chatService }
      travelPolicyList={ travelPolicyList }
      tripItem={ tripItem }
      history={ history }
      dataInfo={ dataInfo }
      isComplex={ isComplex }
      tripId={ tripId }
      appService={ appService }
      onAddTicketChanged={ handleAddTicketChanged }
    />
  );

  if (loading) {
    return (
      <Search>
        <SearchLoading qaAttrLoadingText={ pageLoading } text={ LABELS.SEARCH }/>
      </Search>
    );
  }

  if (!loading && !routesInfo && history.action === HISTORY_ACTIONS.POP) {
    if (path === PATH.DATE_RESULT) {
      history.push(ROUTES.CHANGE_AIR_TRIP.DATE_EDIT(tripId, ticketId));
    } else {
      history.push(ROUTES.CHANGE_AIR_TRIP.DIRECTION_EDIT(tripId, ticketId));
    }
  }

  const renderLoadingOrContent = loadingSearch ? (
    <div className={ styles.absolute }>
      <SearchLoading qaAttrLoadingText={ searchLoadingTest } text={ LABELS.SEARCH } />
    </div>
  ) : renderContent;

  return (
    <div className={ styles.wrapper }>
      { renderHeader() }
      { renderLoadingOrContent }
    </div>
  );
};

export { ChangeAirTripDateOrDirectionResultPage };
