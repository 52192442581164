import React from 'react';
import { Skeleton } from 'new-ui';

import { HotelMaplItemSkeleton } from './compoments/HotelMaplItemSkeleton';
import { FiltersSkeleton } from './compoments/FiltersSkeleton';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import hotelsRegionResultStyles from '../styles/hotels.module.css';
import hotelProgressStyles from '../HotelProgress/style.module.css';
import styles from './styles/index.module.css';

const HotelProgress = () => (
  <div className={ hotelProgressStyles.wrapper }>
    <Skeleton height={ 20 } className={ hotelProgressStyles.count }/>
    <div className={ hotelProgressStyles.text }>
      <Skeleton height={ 20 }/>
    </div>
  </div>
);

const renderHeaderContent = () => {
  const sortContent = (
    <div className={ hotelsRegionResultStyles.select }>
      <Skeleton width={ 260 } height={ 20 } />
    </div>
  );

  return (
    <div
      className={ `${hotelsRegionResultStyles['loading-map-btn-wrapper']}
      ${styles['loading-map-btn-wrapper-skeleton']}` }
    >
      <HotelProgress />
      <div className={ styles['tags-skeleton'] }>
        <Skeleton width={ 146 } height={ 42 } className={ hotelsRegionResultStyles.tag }/>
      </div>
      <div className={ hotelsRegionResultStyles['filters-and-modes-wrapper'] }>
        <div className={ hotelsRegionResultStyles.filters }>{sortContent}</div>
        <div className={ hotelsRegionResultStyles['modes-wrapper'] }>
          <Skeleton width={ 20 } height={ 20 } className={ hotelsRegionResultStyles['modes-button'] } />
          <Skeleton width={ 20 } height={ 20 } className={ hotelsRegionResultStyles['modes-button'] } />
        </div>
      </div>
    </div>
  );
};

const renderHeader = () => (
  <div className={ `${hotelsRegionResultStyles.header}` }>
    <div className={ styles['sticky-panel-skeleton'] }>
      <div className={ hotelsRegionResultStyles.main }>
        {renderHeaderContent()}
      </div>
    </div>
  </div>
);

const renderRow = () => (
  <div className={ styles.row }>
    <HotelMaplItemSkeleton />
  </div>
);

const renderHotelMapList = () => (
  <div className={ styles['list-map-skeleton'] }>
    {renderRow()}
    {renderRow()}
    {renderRow()}
  </div>
);

const renderMapView = () => (
  <div className={ hotelsRegionResultStyles['map-wrap'] } style={ { height: `${700}px` } }>
    <Skeleton width={ 620 } height={ 600 } />
  </div>
);

const renderListMapView = () => (
  <div className={ hotelsRegionResultStyles['list-map-wrap'] }>
    <div className={ hotelsRegionResultStyles.list }>
      {renderHotelMapList()}
    </div>
    {renderMapView()}
  </div>
);

const HotelResult = () => (
  <div className={ hotelsRegionResultStyles.result }>
    <FiltersSkeleton />
    <div className={ `${hotelsRegionResultStyles.items}` }>
      {renderHeader()}
      {renderListMapView()}
    </div>
  </div>

);

const HotelsRegionResultSkeleton = () => (
  <div className={ styles['wrapper-skeleton'] } data-qa={ QA_ATTRIBUTES.hotels.result.skeleton }>
    <HotelResult />
  </div>
);

export { HotelsRegionResultSkeleton };
