import React, { ReactNode, useRef } from 'react';
import { Checkbox } from 'new-ui';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './index.module.css';

type TravelPolicyRuleContainerProps = {
  value: boolean,
  taxiCheck?: boolean,
  isUnique?: boolean,
  onChange: (val: boolean) => void,
  renderField: (p: (ref: any) => void) => ReactNode,
};

const TravelPolicyRuleContainer = ({
  value,
  onChange,
  taxiCheck,
  isUnique,
  renderField,
}: TravelPolicyRuleContainerProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  if (isUnique && !value) return null;

  if (isUnique && value) {
    return (
      <div className={ styles.unique_flex }>
        <ul>
          <li>
            { renderField((ref) => {
              inputRef.current = ref;
            }) }
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className={ styles.flex }>
      <Checkbox
        className={ styles.checkbox }
        disabled={ !!taxiCheck }
        qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.addRules.checkbox }
        onChange={ (val) => {
          if (val && inputRef.current) {
            inputRef.current.focus();
          }

          onChange(val);
        } }
        value={ value }
      >
        { renderField((ref) => {
          inputRef.current = ref;
        }) }
      </Checkbox>
    </div>
  );
};

export { TravelPolicyRuleContainer };
