import { observer } from 'mobx-react';
import React from 'react';

import { StyledWrapper, Text, Price } from 'new-ui';

import styles from '../styles/summaryCommon.module.css';

const SummaryCommon = observer(({ list, className }: { list: any[], className?: string }) => {
  const listHtml = list.map(({ number, id, string, isPrice }) => {
    const label = String(number);

    const spanHtml = isPrice ? (
      <Price
        marginSmall
        value={ number }
        type='bold_20'
        className={ styles.number }
      />
    ) : (
      <Text
        type='bold_20'
        className={ styles.number }
      >
        { label }
      </Text>
    );

    return (
      <Text key={ id } className={ styles.summary }>
        { spanHtml }
        { string }
      </Text>
    );
  });

  const wrapperStyles = [styles.wrapper];

  if (className) {
    wrapperStyles.push(className);
  }

  return (
    <StyledWrapper className={ wrapperStyles.join(' ') }>
      { listHtml }
    </StyledWrapper>
  );
});

export { SummaryCommon };
