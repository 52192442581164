import React, { useCallback } from 'react';
import { Dialog, Button, LinkButton, Text } from 'new-ui';
import { getText, getTextArray } from '../../../../../i18n';

import { ExceptionsList } from '../ExceptionsList';
import { ExceptionForm } from '../ExceptionForm';

import { TRAVEL_POLICY_AIR_DEFAULT_EXCEPTION } from '../../../../bi/services/travelPolicy/serviceTypeStores/air';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { TRAVEL_POLICY_TRAIN_DEFAULT_EXCEPTION } from '../../../../bi/services/travelPolicy/serviceTypeStores/train';
import { TRAVEL_POLICY_HOTEL_DEFAULT_EXCEPTION } from '../../../../bi/services/travelPolicy/serviceTypeStores/hotel';
import {
  TRAVEL_POLICY_FIELDS_DICTIONARIES,
  TRAVEL_POLICY_GENERAL_FIELDS_TO_BE_RENDERED,
} from '../../../../bi/services/travelPolicy/consts';

import { Exception, ExpectionsWrapperProps, Labels } from './types';

import styles from './index.module.css';

const LABELS: Labels = {
  STEPS_ACTION: getTextArray('settings:travelPolicy.rules.exceptions.actionSteps'),
  CLOSE_DIALOG: getText('common:undo'),
  EXCEPTIONS: getText('settings:travelPolicy.rules.exceptions.exceptions2'),
};

const getDefaultException = (type: string) => ({
  [SERVICETYPE.AIR]: { ...TRAVEL_POLICY_AIR_DEFAULT_EXCEPTION },
  [SERVICETYPE.TRAIN]: { ...TRAVEL_POLICY_TRAIN_DEFAULT_EXCEPTION },
  [SERVICETYPE.HOTEL]: { ...TRAVEL_POLICY_HOTEL_DEFAULT_EXCEPTION },
})[type];

const ExceptionsWrapper = ({
  isUnique,
  value,
  generalFieldsStore,
  type,
  name,
  suggestions,
  onChange,
  onAutocompleteChange,
  onAutocompleteClear,
}: ExpectionsWrapperProps) => {
  const {
    list,
    dialog,
    dialog: {
      show,
      chosenIndex,
      step,
      showValidation,
      isNotTheSame,
    },
  } = value;

  const prepareNewList = useCallback((index: number | null, payload: any) => list.map((i, ind) => {
    if (ind === index) {
      return { ...payload };
    }

    return i;
  }), [list]);

  const handleAdd = useCallback((cList: any[]) =>
    onChange({
      list: [...cList, { ...getDefaultException(type), ...generalFieldsStore }],
      dialog: { show: true, step: 0, chosenIndex: cList.length },
    }), [generalFieldsStore, onChange, type]);

  const handleEdit = useCallback((ind: number, cList: any[]) =>
    onChange({
      list: [...cList],
      dialog: { ...dialog, chosenIndex: ind, step: 1, show: true },
    }), [dialog, onChange]);

  const handleListChange = useCallback((ind: number, cValue: any) => {
    const newList = [...prepareNewList(ind, { ...cValue })];
    onChange({ dialog: { ...dialog }, list: newList });
  }, [dialog, onChange, prepareNewList]);

  const handleOnChange = useCallback((payload: any, dialogValue?: any) => {
    const newList = [...prepareNewList(chosenIndex, payload)];

    if (dialogValue) {
      return onChange({ ...value, list: [...newList], dialog: { ...value.dialog, ...dialogValue } });
    }

    return onChange({ ...value, list: [...newList], dialog: { ...value.dialog, showValidation: false, isNotTheSame: true } });
  }, [chosenIndex, onChange, prepareNewList, value]);

  const handleOnCloseDialog = useCallback((fn: () => void) => {
    fn();
    onAutocompleteClear();
  }, [onAutocompleteClear]);

  const getMainButtonCb = (exception: any) => {
    const { Type, Routes, Hours, ...rules } = exception;

    const onClick = step === 0
      ? () => {
        const fn = () => onChange({ ...value, dialog: { ...value.dialog, step: 1 } });

        if (Type === TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.ROUTES || Type === TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.COUNTRIES) {
          const isRoutesValid = Routes.every(({ isValid }: { isValid: boolean }) => isValid);

          return isRoutesValid ? fn() : onChange({ ...value, dialog: { ...value.dialog, showValidation: true } });
        }

        if (Type === TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.HOURS) {
          const isHoursValid = !!Number(Hours);

          return isHoursValid ? fn() : onChange({ ...value, dialog: { ...value.dialog, showValidation: true } });
        }

        return fn();
      }
      : () => {
        const fn = () => handleOnChange({ ...exception, isNew: false }, { step: 0, chosenIndex: null, show: false });

        const isNotTheSameValue = TRAVEL_POLICY_GENERAL_FIELDS_TO_BE_RENDERED[type].some((k) => {
          const { IsEnabled: rChecked, Value: rValue, Type: rType } = rules[k];
          const { IsEnabled: gChecked, Value: gValue, Type: gType } = generalFieldsStore[k];

          return rChecked !== gChecked || rValue !== gValue || rType !== gType;
        });

        return isNotTheSameValue ? fn() : onChange({ ...value, dialog: { ...value.dialog, showValidation: true, isNotTheSame: isNotTheSameValue } });
      };

    return () => handleOnCloseDialog(() => onClick());
  };

  const renderDialogActions = (exception: Exception) => (
    <div className={ styles.actions }>
      <Button
        onClick={ getMainButtonCb(exception) }
      >
        { LABELS.STEPS_ACTION[step] }
      </Button>
      <LinkButton
        className={ styles.cancel }
        theme='blue-without-border'
        onClick={ () =>
          handleOnCloseDialog(() => onChange({ ...value, dialog: { ...value.dialog, chosenIndex: null, show: false, step: 0 } }))
        }
      >
        <Text
          type='NORMAL_14'
          color='accent'
        >
          { LABELS.CLOSE_DIALOG }
        </Text>
      </LinkButton>
    </div>
  );

  const renderDialog = () => {
    const chosenException = chosenIndex !== null ? list[chosenIndex] : null;

    if (!chosenException) {
      return null;
    }

    return (
      <Dialog
        showClosing
        show={ show }
        onChange={ () =>
          handleOnCloseDialog(() => onChange({ ...value, dialog: { show: false, chosenIndex: null, step: 0 } }))
        }
      >
        <div className={ styles.dialog }>
          <Text type='bold_20'>
            { LABELS.EXCEPTIONS }
          </Text>
          <ExceptionForm
            isUnique={ isUnique }
            type={ type }
            value={ chosenException }
            step={ step }
            name={ name }
            showValidation={ showValidation }
            showContent={ show }
            isNotTheSame={ isNotTheSame }
            suggestions={ suggestions }
            onChange={ handleOnChange }
            onAutocompleteChange={ onAutocompleteChange }
          />
          { renderDialogActions(chosenException) }
        </div>
      </Dialog>
    );
  };

  return (
    <>
      <ExceptionsList
        isUnique={ isUnique }
        list={ list }
        type={ type }
        onAdd={ handleAdd }
        onEdit={ handleEdit }
        onChange={ handleListChange }
      />
      { renderDialog() }
    </>
  );
};

export { ExceptionsWrapper };
