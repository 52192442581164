import React from 'react';
import { MultiSelect } from 'new-ui';

import { STATUS_EVENTS } from '../../../../bi/services/events/const';

interface IFiltredEvents {
  values: string[],
  onChange(value: string[]): void,
  placeholder: string,
  className: string,
}

const FiltredEvents = ({
  values,
  onChange,
  placeholder,
  className,
}: IFiltredEvents) => (
  <div>
    <MultiSelect
      overflow
      theme='large'
      withArrow
      list={ STATUS_EVENTS }
      values={ values }
      onChange={ (value: any) => onChange(value) }
      placeholder={ placeholder }
      searchPlaceholder={ placeholder }
      className={ className }
    />
  </div>
);

export default FiltredEvents;
