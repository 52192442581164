import { QualityStore, QualityStoreType } from './store';

class Quality {
  store: QualityStoreType;
  api: any;

  constructor(api: any) {
    this.api = api;
    this.store = QualityStore;
  }

  setMessage = (value: string): void => {
    this.store.setMessage(value);
  };

  send = (email: string | null = null): Promise<void> => {
    const { message, updateLoadingButton, setSuccessResponse } = this.store;

    if (message.length) {
      updateLoadingButton(true);

      const { sendToAggregator, send } = this.api.quality;

      const fn = () => (email ?
        sendToAggregator({
          Message: message,
          Email: email,
        }) :
        send(message));

      return fn()
        .then((res: boolean) => {
          this.store.setResult(res);

          updateLoadingButton(false);
          setSuccessResponse(true);
        });
    }

    return Promise.reject(null);
  };

  goBack = (): void => {
    this.store.setSuccessResponse(false);
  };

  clear = (): void => this.store.clearMessage();
}

export default Quality;
