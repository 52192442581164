// @ts-nocheck
import { MainAnalytic } from '../../utils/analytics';
import { CONSISTENTCARTMODES } from '../../constants/approve';
import { CART_STATUS } from '../../constants/cart';

const FASTAPPROVEACTIONS = {
  ACCEPT: 'accept',
  DECLINE: 'decline',
};

const APPROVEANALYTICACTIONBYCARTSTATUS = {
  [CART_STATUS.IN_PROGRESS]: MainAnalytic.ACTIONS.APPROVEMENT.DELETEWAITINGAPPROVE,
  [CART_STATUS.REJECTED]: MainAnalytic.ACTIONS.APPROVEMENT.DELETEDECLINED,
  [CART_STATUS.AUTHORISED]: MainAnalytic.ACTIONS.APPROVEMENT.DELETEAPPROVED,
};

const DEFAULTSTATOFAPPROVE = 0;

const sendStatOnOpenApproveDialog = cart => (cart ? MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.OPENSENDTOAPPROVEDIALOG, {
  label: cart.name,
}) : null);

const sendStatOnSendToApprove = (chosenApprovers, comment, approversList, getApproverName) => {
  const haveComment = comment ? 1 : 0;

  if (chosenApprovers.length === 1) {
    const approver = approversList.find(approverItem => approverItem.Email === chosenApprovers[0]);

    if (!approver) return null;

    const approverName = getApproverName(approver);

    return MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.SENDTOAPPROVETOONEAPPROVER, {
      label: approverName,
      value: haveComment,
    });
  }

  if (chosenApprovers.length > 1) {
    const approvers = [];
    chosenApprovers.forEach((chosenApprover) => {
      const approver = approversList.find(approverItem => approverItem.Email === chosenApprover);

      if (approver) {
        const approverName = getApproverName(approver);

        approvers.push(approverName);
      }
    });

    const approversText = approvers.join('; ');

    return MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.SENDTOAPPROVETOSEVERALAPPROVERS, {
      label: approversText,
      value: haveComment,
    });
  }

  return null;
};

const sendStatOnDeleteApproveCart = (mode, type) => {
  const analyticAction = mode === CONSISTENTCARTMODES.VIEWER
    ? APPROVEANALYTICACTIONBYCARTSTATUS[type]
    : MainAnalytic.ACTIONS.APPROVEMENT.DELETEBYAPPROVER;

  MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, analyticAction);
};

const sendStatOnOpenApprovePage = (cartService, cart, id) => {
  cartService.loadWaitingApproveList().then((res) => {
    const index = res.findIndex(item => item.Id === Number(id));

    if (index > -1 && cart) {
      MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.OPENAPPROVEPAGE, {
        label: cart.Name,
        value: index + 1,
      });
    }
  });
};

const sendStatOnApprove = (shouldApprove) => {
  if (shouldApprove) {
    MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.APPROVE, {
      value: DEFAULTSTATOFAPPROVE,
    });
  }
};

const sendStatOnPurchase = (TYPES, type) => {
  if (type === TYPES.APPROVER) {
    MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.BUYTRIP);
  }
};

const sendStatOnDecline = (declineComment) => {
  const haveComment = declineComment ? 1 : 0;

  MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.DECLINE, {
    value: haveComment,
  });
};

const sendStatOnDeleteOption = (confirmDelete) => {
  if (confirmDelete) {
    MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.DELETEBYAPPROVEROPTION);
  }
};

const sendStatOnFastApprove = (act) => {
  switch (act) {
    case FASTAPPROVEACTIONS.ACCEPT: {
      MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.FASTAPPROVE);
      break;
    }
    case FASTAPPROVEACTIONS.DECLINE: {
      MainAnalytic.send(MainAnalytic.CATEGORY.APPROVEMENT, MainAnalytic.ACTIONS.APPROVEMENT.FASTDECLINE);
      break;
    }
  }
};

export {
  sendStatOnOpenApproveDialog,
  sendStatOnSendToApprove,
  sendStatOnDeleteApproveCart,
  sendStatOnOpenApprovePage,
  sendStatOnApprove,
  sendStatOnDecline,
  sendStatOnDeleteOption,
  sendStatOnFastApprove,
  sendStatOnPurchase,
};
