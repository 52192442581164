import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, StyledBorderWrapper, Text } from 'new-ui';

import { api } from '../../../bi/apiV2';

import { getText } from '../../../../i18n';

import { MainAnalytic } from '../../../bi/utils/analytics';

import styles from './index.module.css';

const LABELS = {
  ACTIONS: {
    DOWNLOAD: getText('applicationScheme:applicationStatusCard:download'),
    DELETE: getText('applicationScheme:applicationStatusCard:delete'),
    SEND_TO: getText('applicationScheme:applicationStatusCard:send'),
    TO_ARCHIVE: getText('applicationScheme:applicationStatusCard:archive'),
  },
  STATUS: {
    Archived: getText('applicationScheme:applicationStatusCard:archived'),
    Accepted: getText('applicationScheme:applicationStatusCard:approved'),
    WaitingApprove: getText('applicationScheme:applicationStatusCard:awaiting'),
    Declined: getText('applicationScheme:applicationStatusCard:declined'),
  },
};

type statusColor = 'green' | 'gray' | 'yellow' | 'red';

const COLOR_STATUS: Record<string, statusColor> = {
  Accepted: 'green',
  Archived: 'gray',
  WaitingApprove: 'yellow',
  Declined: 'red',
};

interface ApprovalStatusCardProps {
  data: {
    date: null | string,
    DestinationCity: string,
    employee: string,
    Approves: [],
    CanBeArchived: boolean,
    CanBeDeleted: boolean
  }
  status: 'Archived' | 'Accepted' | 'WaitingApprove' | 'Declined',
  onDeleteApprovalRequest: () => void,
  useAddToArchive: { [key: string]: any },
  Id: number,
  handleSendToMailDialog: (requestId: number, e: any) => void,
  setRemoveDialogData: React.Dispatch<React.SetStateAction<object>>,
  handleGoTravelApproval: (id: number) => void,
}

const ApprovalStatusCard = ({
  data,
  status = 'Accepted',
  onDeleteApprovalRequest,
  useAddToArchive,
  Id,
  setRemoveDialogData,
  handleGoTravelApproval,
  handleSendToMailDialog,
}: ApprovalStatusCardProps) => {
  const useDownloadApprovalRequest = useMutation({
    mutationFn: (requestId: number) => api.approvalScheme.downloadAllDocuments(requestId),
  });

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setRemoveDialogData(data);
    onDeleteApprovalRequest();
  };

  const handleAddToArchive = () => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_REQUEST_ADD_TO_ARCHIVE);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_REQUEST_ADD_TO_ARCHIVE);

    useAddToArchive.mutate(Id);
  };

  const handleDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_REQUEST_DOWNLOAD_TRAVEL_APPROVAL);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_REQUEST_DOWNLOAD_TRAVEL_APPROVAL);

    useDownloadApprovalRequest.mutate(Id);
  };

  const handleClickToArchive = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    handleAddToArchive();
  };

  const renderDeleteRequest = () => {
    const { CanBeDeleted } = data;

    return CanBeDeleted ? (
      <Button type='textual' onClick={ handleDelete }>
        { LABELS.ACTIONS.DELETE }
      </Button>
    ) : null;
  };

  const renderSendToArchive = () => {
    const { CanBeArchived } = data;

    if (!CanBeArchived) return null;

    return (
      <Button
        type='textual'
        loading={ useAddToArchive.isLoading }
        onClick={ handleClickToArchive }
      >
        { LABELS.ACTIONS.TO_ARCHIVE }
      </Button>
    );
  };

  const statusColor: statusColor = COLOR_STATUS[status];

  return (
    <StyledBorderWrapper className={ styles.wrapper } color={ statusColor } onClick={ () => handleGoTravelApproval(Id) }>
      <div className={ styles.info } onClick={ () => handleGoTravelApproval(Id) }>
        <Text type='bold_18' className={ styles['destination-city'] }> { data.DestinationCity } </Text>
        <Text className={ styles.employee }> { data.employee } </Text>
        <Text type='SEMIBOLD_14_130' className={ styles['date-period'] }> { data.date } </Text>
      </div>

      <div className={ styles['info-panel'] }>
        <div className={ styles.actions }>
          { renderSendToArchive() }
          <Button
            type='textual'
            onClick={ handleDownload }
            loading={ useDownloadApprovalRequest.isLoading }
          >
            { LABELS.ACTIONS.DOWNLOAD }
          </Button>

          { renderDeleteRequest() }
          <Button type='textual' onClick={ (event) => handleSendToMailDialog(Id, event) }>
            { LABELS.ACTIONS.SEND_TO }
          </Button>
        </div>
        <div className={ styles.status }>
          <Text color='gray' className={ styles['status-label'] }>
            { LABELS.STATUS[status] }
          </Text>
          <div className={ `${styles['status-icon']} ${styles[statusColor]}` } />
        </div>
      </div>
    </StyledBorderWrapper>
  );
};

export { ApprovalStatusCard };
