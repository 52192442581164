import { action, makeObservable, observable } from 'mobx';

import { HotelsMetrics } from './types';

const defaultHotelsMetrics = {
  active: false,
  startTime: new Date().getTime(),
};

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable startTime: number = new Date().getTime();
  @observable type = '';
  @observable hotelsMetrics: HotelsMetrics = {
    RegionDisplayPreparation: {
      ...defaultHotelsMetrics,
    },
    RegionFirstDisplay: {
      ...defaultHotelsMetrics,
    },
    RegionCompleteDisplay: {
      ...defaultHotelsMetrics,
    },
    HotelDisplay: {
      ...defaultHotelsMetrics,
    },
    HotelDisplayPreparation: {
      ...defaultHotelsMetrics,
    },
  };

  @action
  setStartTime = (value: number) => {
    this.startTime = value;
  };

  @action
  setType = (value: string) => {
    this.type = value;
  };

  @action
  setStartTimeHotel = (method: string, value: number) => {
    const newHotelsMetrics = this.hotelsMetrics;
    newHotelsMetrics[method as keyof HotelsMetrics].active = true;
    newHotelsMetrics[method as keyof HotelsMetrics].startTime = value;

    this.hotelsMetrics = newHotelsMetrics;
  };

  @action
  setEndTimeHotel = (method: string) => {
    this.hotelsMetrics[method as keyof HotelsMetrics] = {
      ...defaultHotelsMetrics,
      params: {
        Method: method,
      },
    };
  };
}

const MetricsStore = new Store();

export { MetricsStore, Store as MetricsStoreType };
