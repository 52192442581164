import React from 'react';
import { observer } from 'mobx-react';
import { PageLoader } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { SERVICETYPE } from '../../../../../../bi/constants/serviceType';

import { ChartAnalyticsNoResult } from '../../../noResult';

import { SubSections } from '../../../smart/components/subSections';
import { SmartIndexChart } from '../../../smart/components/SmartIndexChart';

const LABELS = {
  HEADER: getText('chartsAnalytics:menuItems.smart.indexChart.header_smart_second_page'),
  BY_AIR: getText('chartsAnalytics:menuItems.airline.subtitle'),
  BY_HOTEL: getText('chartsAnalytics:menuItems.hotel.subtitle'),
  BY_TRAIN: getText('chartsAnalytics:menuItems.train.subtitle'),
  BY_TAXI: getText('chartsAnalytics:menuItems.taxi.subtitle'),
  BY_AEROEXPRESS: getText('chartsAnalytics:menuItems.aeroexpress.subtitle'),
  BY_TRANSFER: getText('chartsAnalytics:menuItems.transfer.subtitle'),
  BY_MICE: getText('chartsAnalytics:menuItems.mice.subtitle'),
};

const getSmartTitle = (type: string) => ({
  [SERVICETYPE.AIR]: LABELS.BY_AIR,
  [SERVICETYPE.HOTEL]: LABELS.BY_HOTEL,
  [SERVICETYPE.TRAIN]: LABELS.BY_TRAIN,
  [SERVICETYPE.TAXI_VOUCHER]: LABELS.BY_TAXI,
  [SERVICETYPE.AEROEXPRESS]: LABELS.BY_AEROEXPRESS,
  [SERVICETYPE.TRANSFER]: LABELS.BY_TRANSFER,
  [SERVICETYPE.MICE]: LABELS.BY_MICE,
})[type];

interface CommonWithSubSectionsProps {
  typeStore: any,
  onPageChange({ type, label }: { type: string, label: string }): void,
  tooltip: string,
  type: string,
}

const CommonWithSubSections = observer(({
  typeStore,
  onPageChange,
  tooltip,
  type,
}: CommonWithSubSectionsProps) => {
  const {
    loading,
    chart: {
      values,
      labels,
    },
    summaryIndex,
    indexes,
  } = typeStore;

  if (loading) {
    return <PageLoader />;
  }

  if (!values?.length || !summaryIndex) {
    return <ChartAnalyticsNoResult />;
  }

  const title = `${LABELS.HEADER} ${getSmartTitle(type)}`;

  return (
    <>
      <SmartIndexChart
        title={ title }
        values={ values }
        labels={ labels }
        tooltip={ tooltip }
        summaryIndex={ summaryIndex }
      />
      <SubSections
        serviceType={ type }
        indexes={ indexes }
        onOpen={ onPageChange }
      />
    </>
  );
});

export { CommonWithSubSections };
