import React, { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MultiSelect } from 'new-ui';

import { api } from '../../../../../bi/apiV2';

import { getText } from '../../../../../../i18n';

import { isSmartAgent } from '../../../../../bi/utils/env';

import { Employee } from '../../../../../bi/types/trips';

const getEmployeeForSelect = (item: Employee) => ({ label: item.FullName, value: item.EmployeeId });

const LABELS = {
  SMART_AGENT: {
    EMPLOYEE: getText('trips:traveler'),
    PLACEHOLDER: getText('trips:travelers'),
  },
  DEFAULT: {
    EMPLOYEE: getText('trips:employee'),
    PLACEHOLDER: getText('trips:employees'),
  },
  SEARCH_PLACEHOLDER: (user:string) => getText('trips:lastNameOfTheEmployee', { user }),
};

const useEmployees = (query: string) => useQuery({
  queryKey: ['tripsFilters', query],
  queryFn: () => api.trips.autocompleteEmployees(query),
  staleTime: 60000,
  enabled: !!query.length,
});

interface FilterEmployeesProps {
  employees: Employee[];
  values: number[];
  onChange(checkedEmployees: number[], checkedEmployeesWithLabels: { label: string, value: number }[]): void;
}

const FilterEmployees: FC<FilterEmployeesProps> = ({
  employees,
  values,
  onChange,
}) => {
  const [autoComplete, setAutoComplete] = useState('');
  const { data } = useEmployees(autoComplete);

  const labels = isSmartAgent ? LABELS.SMART_AGENT : LABELS.DEFAULT;
  const employeesToSelect = data?.length ? data : employees;

  const employeesList = employeesToSelect.map((employee: Employee) => getEmployeeForSelect(employee));

  return (
    <MultiSelect
      withLabel
      placeholder={ labels.PLACEHOLDER }
      list={ employeesList }
      values={ values }
      search
      searchPlaceholder={ LABELS.SEARCH_PLACEHOLDER(labels.EMPLOYEE) }
      onChange={ onChange }
      onSearchFunction={ setAutoComplete }
    />
  );
};

export { FilterEmployees };
