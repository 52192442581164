import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Text, StyledWrapper, Button, RadioButton } from 'new-ui';
import { observer } from 'mobx-react';
import * as queryString from 'query-string';

import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { isSmartAgent } from '../../bi/utils/env';
import MainAnalytic from '../../bi/utils/analytics/main';

import { ADDITIONAL_TYPES, PATH } from '../../bi/constants/airlineAdditionalServices';
import ROUTES from '../../bi/constants/routes';

import { SearchLoading } from '../../components/SearchLoading';

import { VipHallProps } from '../../bi/types/vipHall';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('components:additionalServices.selectTerminal'),
  NEXT: getText('components:additionalServices.radioButtons.next'),
  SEARCH: getText('vipHall:loader'),
};

const VipHallSelectTerminal: FC<VipHallProps> = observer(({
  vipHallService,
  match: { params: { tripId, ticketId }, path },
  breadCrumbsService,
}) => {
  const history = useHistory();

  const { vipHallSearchStore: { terminals, uuid } } = useStores([MOBX_STORES.VIP_SEARCH_STORE]);
  const { vipHallStore: { loading } } = useStores([MOBX_STORES.VIP_STORE]);

  const [selectedTerminal, setSelectedTerminal] = useState<string | null>(null);

  const { route, type, segment } = queryString.parse(location.search);

  const isVipHallPage = type === ADDITIONAL_TYPES.VIP_HALL;

  useEffect(() => {
    if (!terminals.length) {
      history.push(ROUTES.ADDITIONAL_SERVICES.ROUTE(tripId, ticketId, type));
    }

    breadCrumbsService.setCurrentPath(path);

    return () => {
      breadCrumbsService.setCurrentPath(PATH.MAIN);
    };
  }, []);

  const handleSelect = async () => {
    await vipHallService.searchByGuid(uuid, selectedTerminal);

    const analyticType = isVipHallPage
      ? MainAnalytic.ACTIONS.VIP_HALL.VIP_TERMINAL_BUTTON_PRESSED
      : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_TERMINAL_BUTTON_PRESSED;

    MainAnalytic.sendFirebase(analyticType, {
      name: selectedTerminal,
    });

    if (isVipHallPage) {
      breadCrumbsService.setCurrentPath(ROUTES.ADDITIONAL_SERVICES.RESULT_VIP(uuid, tripId, ticketId, route, segment, selectedTerminal));

      return history.push(ROUTES.ADDITIONAL_SERVICES.RESULT_VIP(uuid, tripId, ticketId, route, segment, selectedTerminal));
    }

    breadCrumbsService.setCurrentPath(ROUTES.ADDITIONAL_SERVICES.RESULT_ESCORT(uuid, tripId, ticketId, route, segment, selectedTerminal));

    return history.push(ROUTES.ADDITIONAL_SERVICES.RESULT_ESCORT(uuid, tripId, ticketId, route, segment, selectedTerminal));
  };

  const handleChangeSelectedTerminal = (value: string | null) => {
    setSelectedTerminal(value);
    vipHallService.setSelectedTerminal(value);
  };

  const renderSearchRoutes = () =>
    terminals && terminals.map((terminal: string, index: number) => (
      <div className={ styles.wrapper_checkbox } key={ index }>
        <RadioButton
          className={ styles.checkbox }
          alternativeDesign={ isSmartAgent }
          onChange={ () => handleChangeSelectedTerminal(terminal) }
          checked={ selectedTerminal === terminal }
        />
        <div className={ styles.flight_info }>
          <Text className={ styles.duration }>
            { terminal }
          </Text>
        </div>
      </div>
    ));

  if (loading || !terminals) {
    return (
      <div className={ styles.search_wrapper }>
        <SearchLoading text={ LABELS.SEARCH } />
      </div>
    );
  }

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_20'>
        { LABELS.TITLE }
      </Text>
      <StyledWrapper className={ styles.routes }>
        { renderSearchRoutes() }
        <div className={ styles.search }>
          <Button
            type='secondary'
            className={ styles.search_button }
            onClick={ handleSelect }
            disabled={ typeof selectedTerminal !== 'string' }
          >
            <Text type='NORMAL_18' color='white'>
              { LABELS.NEXT }
            </Text>
          </Button>
        </div>
      </StyledWrapper>
    </div>
  );
});

export { VipHallSelectTerminal };
