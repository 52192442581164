// @ts-nocheck
import React, { Component } from 'react';
import { Button, PROPS, PageLoader, ItemPanel } from 'new-ui';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as queryString from 'query-string';
import { getText } from '../../../i18n';

import ItemLayout from '../../components/ItemLayout';
import RequestAirItem from '../../components/RequestAirItem';
import RequestTrainItem from '../../components/RequestTrainItem';
import RequestTransferItem from '../../components/RequestTransferItem';
import RequestHotelItem from '../../components/RequestHotelItem';
import RequestHeader from '../../components/RequestHeader';
import RequestItemFooter from '../../components/RequestItemFooter';

import { momentObject } from '../../bi/utils/formatDate';

import { statuses, STATUSES, TYPES } from '../../../constants/Request';
import ROUTES from '../../bi/constants/routes';

import styles from './styles/request.module.css';

const Services = {
  [TYPES.AIR]: RequestAirItem,
  [TYPES.HOTEL]: RequestHotelItem,
  [TYPES.TRANSFER]: RequestTransferItem,
  [TYPES.TRAIN]: RequestTrainItem,
};

const DATE = {
  CHECKOUT: 'checkout.date',
  CHECKIN: 'checkin.date',
};

const HOTEL_REGION_SELECTED = 'region.selected';

const LABELS = {
  ALL_REQUESTS: getText('requests:allRequests'),
  TO_CART: getText('requests:toCart'),
  TO_TRIP: getText('requests:toTrip'),
  SEARCH: getText('common:search'),
  ADDED_TO_REUQEST: IniciatorName => getText('requests:addedToRequest', { IniciatorName }),
};

class Request extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    formatService: PropTypes.object.isRequired,
    requestsService: PropTypes.object.isRequired,
    hotelsService: PropTypes.object.isRequired,
    trainService: PropTypes.object.isRequired,
    cartService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      request: null,
    };
  }

  componentDidMount() {
    const { requestsService, match: { params: { requestId } } } = this.props;

    this.unsubscribeFn = requestsService.subscribe(this.update);

    requestsService.loadRequest(requestId);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  update = ({ loading, request }) => this.setState({ loading, request });

  goToHotel = ({ SearchOptions, EmployeesNames }) => {
    const query = {
      CheckinDate: SearchOptions.CheckinDate,
      CheckoutDate: SearchOptions.CheckoutDate,
      GuestsCount: EmployeesNames.length,
      Label: SearchOptions.Name,
      TravellersCount: EmployeesNames.length,
    };

    this.props.history.push({
      pathname: `/search/hotel/${SearchOptions.Code}`,
      search: queryString.stringify(query),
    });
  };

  checkRequestsList = () => {
    const checkRequestsList = this.props.requestsService.get().requestsList.length === 0;

    if (checkRequestsList) {
      this.props.requestsService.updateLoadingRequestsFlag();
    }
  };

  findTrip = (type, item) => {
    const { requestsService, hotelsService, trainService, history, cartService } = this.props;
    const { SearchOptions: { IsRegion, Name, CheckinDate, CheckoutDate, Code, Address } } = item;
    const uri = ROUTES.SEARCH[type.toUpperCase()];

    const preparedParams = {
      [DATE.CHECKOUT]: momentObject(CheckoutDate),
      [DATE.CHECKIN]: momentObject(CheckinDate),
    };

    cartService.setPreselectRequest1c({ type, id: item?.RequestId });

    const hotelSetSearch = () => {
      hotelsService.setNewSearch();
      Object.keys(preparedParams).forEach(key => hotelsService.setSearch(key, preparedParams[key]));
    };

    if (uri && type === TYPES.AIR) {
      const { SearchOptions: { Routes } } = item;

      const arrivalEmpty = Routes.some(({ ArrivalAirport: { Code: ArrivalCode } }) => ArrivalCode === '');
      const departureEmpty = Routes.some(({ DepartureAirport: { Code: DepartureCode } }) => DepartureCode === '');

      if (arrivalEmpty || departureEmpty) {
        return history.push({ pathname: ROUTES.SEARCH.AIR });
      }
    }

    if (uri) {
      requestsService.setRequestItem(item);
    }

    if (uri && type === TYPES.HOTEL && (!IsRegion || !Name) && Name) {
      return this.goToHotel(item);
    }

    if (uri && type === TYPES.HOTEL && (!IsRegion || !Name) && !Name) {
      hotelSetSearch();

      return history.push({ pathname: ROUTES.SEARCH.HOTEL, search: 'is_request=true' });
    }

    if (uri && type === TYPES.HOTEL && IsRegion && Name) {
      hotelSetSearch();
      hotelsService.setSearch(HOTEL_REGION_SELECTED, { Name, Id: Code, IsRegion, FullName: Address });
    }

    if (uri && type === TYPES.TRAIN) {
      const {
        SearchOptions: {
          ArrivalStation: {
            Code: ArrivalCode,
            City: ArrivalCity,
            Name: ArrivalName,
          },
          DepartureStation: {
            Code: DepartureCode,
            City: DepartureCity,
            Name: DepartureName,
          },
          DepartureDate,
        },
        EmployeesNames,
      } = item;

      const from = {
        label: DepartureName,
        selected: {
          BaseCode: '',
          Code: DepartureCode,
          Hint: '',
          Name: DepartureCity,
        },
        suggests: [],
      };

      const to = {
        label: ArrivalName,
        selected: {
          BaseCode: '',
          Code: ArrivalCode,
          Hint: '',
          Name: ArrivalCity,
        },
        suggests: [],
      };

      const travellers = EmployeesNames.length || 1;

      trainService.setNewSearch();
      trainService.setFromAndTo(from, to);
      trainService.setDate(momentObject(DepartureDate));
      trainService.setTravellers(travellers);

      return history.push({ pathname: uri, search: 'is_request=true' });
    }

    if (uri) {
      return history.push({ pathname: uri, search: 'is_request=true' });
    }

    return null;
  };

  getEmployeesNames() {
    const { request: { AirItems = [], TrainItems = [], HotelItems = [], TransferItems = [] } } = this.state;

    let employees = new Set();

    const extractEmployees = item => item.EmployeesNames.forEach(employee => employees.add(employee));

    AirItems.forEach(extractEmployees);
    TrainItems.forEach(extractEmployees);
    HotelItems.forEach(extractEmployees);
    TransferItems.forEach(extractEmployees);

    employees = [...employees]; // Set -> Array

    return employees.join(', ');
  }

  renderLoading = () => <PageLoader />;

  renderHeader() {
    const { request: { Id, Status, CreatedDate, Comment } } = this.state;
    const createdDate = this.props.formatService.date(CreatedDate, 'DD.MM.YYYY HH:MM');

    return (
      <RequestHeader
        id={ Id }
        status={ Status }
        createdDate={ createdDate }
        comment={ Comment }
        employees={ this.getEmployeesNames() }
      />
    );
  }

  renderItemsList() {
    const { request: { Id: requestId, AirItems = [], TrainItems = [], HotelItems = [], TransferItems = [] } } = this.state;

    const items = [];

    AirItems.forEach(item => items.push(this.renderItem('Air', item, requestId)));
    TrainItems.forEach(item => items.push(this.renderItem('Train', item, requestId)));
    HotelItems.forEach(item => items.push(this.renderItem('Hotel', item, requestId)));
    TransferItems.forEach(item => items.push(this.renderItem('Transfer', item, requestId)));

    return <div>{ items }</div>;
  }

  renderItemPanelButton(type, item, requestId) {
    const { formatService: { money }, history } = this.props;

    switch (item.Status.Name) {
      case STATUSES.NOTPROCESSING: {
        return (
          <div className={ styles.find }>
            <Button
              type={ PROPS.BUTTON.TYPES.SECONDARY }
              onClick={ () => this.findTrip(type, item, requestId) }
            >
              {LABELS.SEARCH}
            </Button>
          </div>
        );
      }
      case STATUSES.PROCESSING: {
        return (
          <div className={ styles.find }>
            <div className={ styles.price }>{ money(item.Status.Price) } р.</div>
            <Button
              onClick={ () => {
                history.push({ pathname: '/cart' });
              } }
            >
              {LABELS.TO_CART}
            </Button>
          </div>
        );
      }
      case STATUSES.PROCESSED: {
        return (
          <div className={ styles.find }>
            <div className={ styles.price }>{ money(item.Status.Price) } р.</div>
            <Button
              onClick={ () => { history.push({ pathname: `/trip/${item.Status.Id}` }); } }
            >
              {LABELS.TO_TRIP}
            </Button>
          </div>
        );
      }
      case STATUSES.CANCELED: {
        return null;
      }
    }

    return null;
  }

  renderItemPanelHeader(item) {
    const { InitiatorName, Status: { Name } } = item;

    const InitiatorNameHtml = (
      <span> {LABELS.ADDED_TO_REUQEST(InitiatorName)} </span>
    );

    const isAddingTrip = InitiatorName && InitiatorNameHtml;
    const status = statuses.get(Name);

    return (
      <div className={ styles['header-info'] }>
        <div>
          {isAddingTrip}
        </div>
        <div>
          <span className={ styles['status-name'] }>{ status }</span>
          <span className={ `${styles['status-label']} ${styles[Name.toLowerCase()]}` } />
        </div>
      </div>
    );
  }

  renderItem(type, item, requestId) {
    const html = this.renderItemPanelButton(type, item, requestId);

    return (
      <div key={ item.Id } className={ styles.wrap }>
        <ItemPanel renderHeader={ () => this.renderItemPanelHeader(item) }>
          <div className={ styles.item }>
            <ItemLayout
              serviceType={ type }
              html={ html }
            >
              { this.renderServiceItem(item, type) }
            </ItemLayout>
            <div>{ this.renderItemFooter(item.EmployeesNames) }</div>
          </div>
        </ItemPanel>
      </div>
    );
  }

  renderServiceItem = (item, type) => {
    if (type in Services) {
      const Service = Services[type];

      return (
        <Service
          formatService={ this.props.formatService }
          searchOptions={ item.SearchOptions }
          comment={ item.Comment }
        />
      );
    }

    return '';
  };

  renderItemFooter = employees => (<RequestItemFooter employees={ employees } />);

  render() {
    const { loading } = this.state;

    return loading ? this.renderLoading() : (
      <div>
        <div className={ styles.back }>
          <Link to='/requests' className={ styles['back-link'] } onClick={ this.checkRequestsList }>
            <i className='material-icons'>keyboard_backspace</i>
            {LABELS.ALL_REQUESTS}
          </Link>
        </div>
        { this.renderHeader() }
        { this.renderItemsList() }
      </div>
    );
  }
}

export default Request;
