import { NotificationsManager } from 'new-ui';

import { ISendProps } from '../../types/notification';

class Notification {
  send({ message, onClick = () => {}, qaAttr = '', type }: ISendProps) {
    NotificationsManager.addNotification({
      value: message,
      type,
      onClick,
      qaAttr,
    });
  }
}

export default Notification;
