import React from 'react';

import { Paginate, Text, NoResults } from 'new-ui';

import { getText } from '../../../../i18n';

import { ApprovalStatusCard } from '../ApprovalStatusCard';

import { formatRangeDateWithSimplicity } from '../../../bi/utils/formatDate';
import { employeeFullNameWithComas } from '../../../bi/utils/employees';

import { PATTERN } from '../../../bi/constants/dateFormats';

import styles from './index.module.css';

const LABELS = {
  NO_RESULTS: getText('applicationScheme:noResults'),
  NO_DATE: getText('components:travelApproval.noDate'),
};

interface ApprovalStatusCardListProps {
  approvalRequests: { [key: string]: any }[],
  onDeleteApprovalRequest: () => void,
  useAddToArchive: { [key: string]: any },
  handleSendToMailDialog: (requestId: number, e: any) => void,
  pagination: { [key: string]: number },
  setRemoveDialogData: React.Dispatch<React.SetStateAction<object>>,
  handleGoTravelApproval: (id: number) => void,
  onPageChange: (page: number) => void,
}

const ApprovalStatusCardList = ({
  approvalRequests = [],
  onDeleteApprovalRequest,
  useAddToArchive,
  setRemoveDialogData,
  handleGoTravelApproval,
  handleSendToMailDialog,
  pagination,
  onPageChange,
}: ApprovalStatusCardListProps) => {
  const renderNoResults = () => (
    <div className={ styles.no_results }>
      <NoResults
        renderContent={ () => (
          <Text type='bold_18' className={ styles.subtext }>
            { LABELS.NO_RESULTS }
          </Text>
        ) }
      />
    </div>

  );

  const renderApprovalRequestCards = approvalRequests.map(({
    Approves,
    StartDate,
    EndDate,
    Employees,
    Id,
    Status,
    Destinations,
    CanBeArchived,
    CanBeDeleted,
  }) => {
    const { Name } = !!Destinations.length && Destinations[0];
    const employees = employeeFullNameWithComas(Employees);
    const date = !StartDate && !EndDate ? LABELS.NO_DATE : formatRangeDateWithSimplicity(
      StartDate,
      EndDate,
      { fullPattern: PATTERN.DAY_OF_MONTH_LONG },
    );

    const data = {
      date,
      DestinationCity: Name,
      employee: employees,
      Approves,
      Id,
      CanBeArchived,
      CanBeDeleted,
    };

    return (
      <ApprovalStatusCard
        data={ data }
        status={ Status }
        key={ Id }
        onDeleteApprovalRequest={ onDeleteApprovalRequest }
        useAddToArchive={ useAddToArchive }
        handleSendToMailDialog={ handleSendToMailDialog }
        setRemoveDialogData={ setRemoveDialogData }
        Id={ Id }
        handleGoTravelApproval={ handleGoTravelApproval }
      />
    );
  });

  const renderPaginate = pagination?.PageSize ? (
    <Paginate
      page={ pagination?.CurrentPage || 1 }
      itemsPerPage={ pagination?.PageSize }
      total={ pagination?.TotalCount }
      onChange={ onPageChange }
    />
  ) : null;

  const content = approvalRequests?.length ? renderApprovalRequestCards : renderNoResults();

  return (
    <>
      {content}
      {renderPaginate}
    </>
  );
};

export { ApprovalStatusCardList };
