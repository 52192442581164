import React, { useState, useEffect, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { BreadCrumbs, PageLoader } from 'new-ui';
import { RouteComponentProps } from 'react-router-dom';

import { getText } from '../../../i18n';

import { ChangeAirTripHeader } from '../../components/ChangeAirTripHeader';
import ChangeTypeSelection from './components/ChangeTypeSelection';

import { contentCrumbsChangeAir } from '../../bi/utils/changeAirTrip';

import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { PATH } from '../../bi/constants/changeAirTrip';

import { IOrderDetailsData, OrderService } from '../../bi/types/order';
import { BreadCrumbsService } from '../../bi/types/breadCrumbs';
import { WorkspaceService } from '../../bi/types/workspace';
import { TicketDataInterface } from '../../components/ChangeAirTripHeader/types';
import { AirlineMatchParams } from '../../bi/types/airline';

import styles from './styles/index.module.css';

const LABELS = {
  LOADER: getText('components:changeAirTrip.loader'),
};

const { changeTrip: { air: { select: { loading: loadingTest } } } } = QA_ATTRIBUTES;

interface IChangeAirTripProps {
  orderService: OrderService;
  breadCrumbsService: BreadCrumbsService;
  workspaceService: WorkspaceService;
  match: RouteComponentProps<AirlineMatchParams>['match'];
  store: {
    currentPath: string;
  },
  history: RouteComponentProps['history'];
  children: ReactNode;
}

const ChangeAirTrip = observer(({
  orderService,
  breadCrumbsService,
  workspaceService,
  match,
  store: {
    currentPath,
  },
  match: {
    params: { tripId, ticketId },
    path,
  },
  history,
  children,
}: IChangeAirTripProps) => {
  const [detailsData, setDetailsData] = useState<IOrderDetailsData | null>(null);

  const tripItem = detailsData && detailsData.OrderItems.find(ticket => ticket.Id === +ticketId);
  const ticketData: TicketDataInterface = tripItem && JSON.parse(tripItem.ActualVersion.JsonData);

  const loadOrderServiceInfo = async () => {
    orderService.start();
    await orderService.load(tripId);
  };

  const updateState = ({ details }: { details: IOrderDetailsData }) => {
    setDetailsData(details);
  };

  useEffect(() => {
    breadCrumbsService.setCurrentPath(path);
    const { details } = orderService.get();

    if (!details) {
      loadOrderServiceInfo();
    }

    setDetailsData(details);

    const unsubscribeFn = orderService.subscribeCart(updateState);

    return () => {
      // @ts-ignore
      unsubscribeFn();
    };
  }, []);

  const renderHeader = () => (
    <>
      <BreadCrumbs
        className={ styles.bread_crumbs }
        content={ contentCrumbsChangeAir(tripId, ticketId, currentPath) }
      />
      <ChangeAirTripHeader details={ detailsData } ticketId={ ticketId }/>
    </>
  );

  const renderSelect = () => (currentPath === PATH.MAIN ? (
    <ChangeTypeSelection
      history={ history }
      match={ match }
      ticketData={ ticketData }
      tripId={ tripId }
      setChangeTypeCrumbs={ breadCrumbsService.setCurrentPath }
      canEditEmployee={ workspaceService.canEditEmployee }
      canEditSelf={ workspaceService.canEditSelf }
      employeeId={ workspaceService.rights.EmployeeId }
    />
  ) : null);

  if (!detailsData) {
    return <PageLoader qaAttr={ loadingTest } text={ LABELS.LOADER } />;
  }

  return detailsData && (
    <>
      { renderHeader() }
      { renderSelect() }
      { children }
    </>
  );
});

export { ChangeAirTrip };
