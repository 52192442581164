import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Text, Dialog, List, PageLoader, NoResults } from 'new-ui';
import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import TEXTS from '../../../../bi/constants/texts';
import ROUTES from '../../../../bi/constants/routes';
import { StatusScheme } from '../../../../bi/constants/approvalSchemes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { TableRow } from '../../../../components/TableRow';

import { IApprovalERSchemesTab, IRSShemas, SchemeApprovalLigth } from '../../types';

import styles from './index.module.css';

const LABELS = {
  ADD: getText('settings:approvalSchemes.add'),
  REMOVE: getText('settings:approvalSchemes.remove'),
  CANCEL: getText('common:undo'),
  UNABLE_TO_DELETE: getText('settings:approvalSchemes.unableToDeleteDialog.title'),
  UNABLE_TO_DELETE_DESC: getText('settings:approvalSchemes.unableToDeleteDialog.description'),
  DELETE_CONFIRM: getText('settings:approvalSchemes.deleteConfirm'),
  EMPTY_DESC: {
    FIRST_PART: getText('settings:approvalSchemes.emptyDesc.1'),
    SECOND_PART: getText('settings:approvalSchemes.emptyDesc.2'),
    THIRD_PART: getText('settings:approvalSchemes.emptyDesc.4'),
  },
};

const ApprovalERSchemesTab = observer(({
  isDemo,
  history,
  approvalERSchemesService,
}: IApprovalERSchemesTab) => {
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [schemeRemoveDialog, setSchemeRemoveDialog] = useState<SchemeApprovalLigth>({
    Name: '',
    Id: 0,
  });
  const [loadingRemoveDialog, setLoadingRemoveDialog] = useState(false);
  const [errorRemoveDialog, setErrorRemoveDialog] = useState<{
    show: boolean,
    code?: number | null,
    integration?: number | null,
  }>({
    show: false,
    integration: null,
  });

  const { approvalERSchemesStore } = useStores([MOBX_STORES.APPROVAL_ER_SCHEMES]);
  const {
    list: {
      value,
      loading,
      error,
    },
  } = approvalERSchemesStore;

  useEffect(() => {
    (async () => {
      await approvalERSchemesService.loadExpenseReportsSchemeList();
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.EXPENSE_REPORTS_SCHEME_OPEN);
    })();
  }, [approvalERSchemesService]);

  const handleNavigateToForm = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.REPORT_SCHEME_SCHEME_OPEN);
    history.push(ROUTES.APPROVAL_EXPENSE_REPORT);
  };

  const handleOpenCreateScheme = () => {
    const { setLoading } = approvalERSchemesService;

    setLoading(true);
    handleNavigateToForm();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.REPORT_SCHEME_ADD_BUTTON);
  };

  const handleNavigateToEditForm = (data: IRSShemas) => {
    const { setLoading, updateStatus, initForm } = approvalERSchemesService;

    setLoading(true);
    updateStatus(StatusScheme.EDIT);
    initForm(data);

    handleNavigateToForm();
  };

  const handleOpenDialog = (item: IRSShemas) => {
    setShowRemoveDialog(true);
    setSchemeRemoveDialog(item);
  };

  const handleCloseDialog = () => setShowRemoveDialog(false);

  const handleSetDialogLoading = (isLoadong: boolean) => setLoadingRemoveDialog(isLoadong);

  const handleRemoveScheme = (Id: number) => {
    const { removeScheme, loadExpenseReportsSchemeList } = approvalERSchemesService;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.REPORT_SCHEME_DELETE);
    handleSetDialogLoading(true);

    removeScheme(Id)
      .then(() => {
        loadExpenseReportsSchemeList();
        handleCloseDialog();
      })
      .catch(({ status }: { status: number }) => {
        setErrorRemoveDialog({ integration: status, show: true });
      });

    handleSetDialogLoading(false);
  };

  const addButtonHtml = (
    <Button
      qaAttr={ QA_ATTRIBUTES.approvalSchemes.expenseReport.addSchema }
      className={ styles.button }
      type='primary'
      onClick={ () => handleOpenCreateScheme() }
    >
      { LABELS.ADD }
    </Button>
  );

  const errorHtml = (
    <div className={ styles.empty }>
      <NoResults label={ TEXTS.SOMETHING_WENT_WRONG } />
    </div>
  );

  const prepareRemoveErrorContent = (code: number | null) => {
    if (code === 409) {
      return (
        <div className={ styles.error }>
          <Text type='bold_20'>
            { LABELS.UNABLE_TO_DELETE }
          </Text>
          <Text className={ styles.description }>
            { LABELS.UNABLE_TO_DELETE_DESC }
          </Text>
        </div>
      );
    }

    return (
      <NoResults label={ TEXTS.SOMETHING_WENT_WRONG } />
    );
  };

  const renderEmpty = () => (
    <div className={ styles.empty }>
      <Text
        className={ styles.text }
        type='NORMAL_16_140'
      >
        { LABELS.EMPTY_DESC.FIRST_PART } <br />
        { LABELS.EMPTY_DESC.SECOND_PART } <br />
        { LABELS.EMPTY_DESC.THIRD_PART }
      </Text>
      { addButtonHtml }
    </div>
  );

  const handleRemove = (isFlag: boolean, item: IRSShemas) => (
    isFlag ? () => null : () => handleOpenDialog(item)
  );

  const renderList = () => {
    if (!value.length) {
      return renderEmpty();
    }

    const renderItem = (item: IRSShemas) => {
      const { IsApplied, Name } = item;

      const tooltipText = isDemo ? TEXTS.NOT_FOR_DEMO : '';

      return (
        <TableRow
          title={ Name }
          onClick={ () => handleNavigateToEditForm(item) }
          remove={ { action: handleRemove(IsApplied, item), tooltip: tooltipText } }
          edit={ { action: () => handleNavigateToEditForm(item), tooltip: '' } }
          qaAttrEdit={ QA_ATTRIBUTES.approvalSchemes.expenseReport.buttons.edit }
          qaAttrRemove={ QA_ATTRIBUTES.approvalSchemes.expenseReport.buttons.remove }
        />
      );
    };

    const newListContent = (
      <List
        onClickItem={ handleNavigateToEditForm }
        keyExtractor={ ({ Id }) => String(Id) }
        items={ value }
        renderItem={ i => renderItem(i) }
        qaAttrFirstEl={ QA_ATTRIBUTES.approvalSchemes.expenseReport.scheme }
      />
    );

    return (
      <div className={ styles.list }>
        <div className={ styles.header }>
          { addButtonHtml }
        </div>
        <div className={ styles.content }>
          { newListContent }
        </div>
      </div>
    );
  };

  const renderBasicRemoveDialogContent = () => {
    const { Name, Id } = schemeRemoveDialog;

    return (
      <>
        <Text>
          { LABELS.DELETE_CONFIRM } <b>{ Name }</b>?
        </Text>
        <div className={ styles.actions }>
          <Button
            onClick={ () => handleRemoveScheme(Id) }
            loading={ loadingRemoveDialog }
            qaAttr={ QA_ATTRIBUTES.approvalSchemes.expenseReport.dialog.buttons.remove }
          >
            { LABELS.REMOVE }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual-medium'
            onClick={ handleCloseDialog }
            qaAttr={ QA_ATTRIBUTES.approvalSchemes.expenseReport.dialog.buttons.cancel }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </>
    );
  };

  const renderRemoveDialog = () => {
    const { show: showError, code } = errorRemoveDialog;

    const content = showError
      ? prepareRemoveErrorContent(code as number)
      : renderBasicRemoveDialogContent();

    return (
      <Dialog
        show={ showRemoveDialog }
        onChange={ handleCloseDialog }
      >
        { content }
      </Dialog>
    );
  };

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return errorHtml;
  }

  return (
    <>
      { renderList() }
      { renderRemoveDialog() }
    </>
  );
});

export { ApprovalERSchemesTab };
