import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { PageLoader } from 'new-ui';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import ChartsAnalytics from '../../bi/services/chartsAnalytics';
import Workspace from '../../bi/services/workspace';
import { Metrics } from '../../bi/services/metriсs';
import PopupsService from '../../bi/services/popups';

import { MENU_KEYS, HEADER_PROPS } from '../../bi/constants/chartsAnalytics';
import { METRICS_TYPES } from '../../bi/constants/metrics';

import { ChartAnalyticsHeader } from './components/header';

import { ChartAnalyticsDates } from './components/dates';
import { ChartAnalyticsMenu } from './components/menu';

import styles from './styles/chartsAnalytics.module.css';

interface ChartsAnalyticsPageProps {
  chartsAnalyticsService: ChartsAnalytics,
  workspaceService: Workspace,
  metricsService: Metrics,
  chartsAnalyticsProtocol: any,
  popupsService: PopupsService,
  children: any,
  showChartsAnal: boolean,
}

const ChartsAnalyticsPage = observer(({
  chartsAnalyticsService,
  workspaceService,
  metricsService,
  popupsService,
  chartsAnalyticsProtocol,
  children,
  showChartsAnal,
}: ChartsAnalyticsPageProps) => {
  const {
    chartsAnalyticsSummaryStore,
    chartsAnalyticsStore,
    chartsAnalyticsStore: {
      commonChartsAnalytics: common,
    },
    popupsStore,
  } = useStores([
    MOBX_STORES.CHARTS_ANALYTICS_STORE,
    MOBX_STORES.CHARTS_ANALYTICS_SUMMARY_STORE,
    MOBX_STORES.POPUPS_STORE,
  ]);

  const history = useHistory();

  const { popupsState, showAnalyticsPopup } = popupsStore;

  useEffect(() => {
    if (!popupsState.travel_policy_questionnaire_popup && showAnalyticsPopup && workspaceService.isDemo) {
      popupsService.addAnalyticsPopup();
    }

    if (!showChartsAnal) {
      history.push('/');
    }
  }, [showChartsAnal]);

  const { preparedData } = chartsAnalyticsProtocol;
  const {
    setRouteId,
    changeDates,
    setCachedDates,
    resetPaginate,
    getInfo,
    changeSelectedCompanies,
    changeStartDate,
    changeEndDate,
    resetFilters,
  } = chartsAnalyticsService;

  const { params, url } = useRouteMatch<{ tabId: string, id: string }>();

  const { id: paramsId, tabId } = params;

  const smartCommonAnalytics = () => {
    if (tabId?.includes('smart')) {
      try {
        metricsService.sendMetric(workspaceService.AccountEmail, METRICS_TYPES.CHARTS_ANALYTICS.SMART_COMMON);
      } catch {}
    }
  };

  const mounted = useRef();

  useEffect(() => {
    preparedData();

    if (!mounted.current) {
      smartCommonAnalytics();
      history.push(`/chartsAnalytics/${MENU_KEYS.GENERAL}`);
    }
  }, [preparedData]);

  const getRouteId = () => {
    const {
      id: customAnalyticsId,
    } = chartsAnalyticsStore;

    if (paramsId) {
      setRouteId(+paramsId);
    }

    return paramsId ?? customAnalyticsId;
  };

  const handleOpenChapter = (id: string | any) => {
    history.push(id);
  };

  const renderHeader = () => {
    const {
      cachedDates,
      commonChartsAnalytics: {
        startDate: start,
        endDate: end,
      },
    } = chartsAnalyticsStore;

    const {
      title: customAnalyticsTitle,
    } = chartsAnalyticsSummaryStore;

    const pathForStructure = url.split('/')[2];

    const active = pathForStructure === MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS ? `${pathForStructure}/${paramsId}` : tabId;

    const { show, showOnlyTitle, title, prevPath, type } = HEADER_PROPS(active, getRouteId(), customAnalyticsTitle, start, end);

    const changePath = () => {
      if (cachedDates) {
        const { startDate, endDate } = cachedDates;

        changeDates(startDate, endDate);
        setCachedDates(true);
      }

      handleOpenChapter(prevPath);
      resetPaginate();
    };

    return (
      <ChartAnalyticsHeader
        title={ title }
        chartsAnalyticsService={ chartsAnalyticsService }
        prevPath={ prevPath }
        type={ type }
        show={ show }
        showOnlyTitle={ showOnlyTitle }
        onChangePath={ changePath }
        active={ active }
      />
    );
  };

  if (chartsAnalyticsStore.loading) {
    return <PageLoader/>;
  }

  const getInfoHandler = getInfo(tabId);

  return (
    <div className={ styles.wrapper }>
      <ChartAnalyticsDates
        getInfo={ getInfoHandler }
        onSelectCompanies={ changeSelectedCompanies }
        onChangeStartDate={ changeStartDate }
        onChangeEndDate={ changeEndDate }
        common={ common }
      />
      <div className={ styles.row }>
        <ChartAnalyticsMenu
          resetFilters={ resetFilters }
        />
        <div className={ styles.content }>
          { renderHeader() }
          { children }
        </div>
      </div>
    </div>
  );
});

export { ChartsAnalyticsPage };
