import React, { FC } from 'react';
import { MultiSelect } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { isSmartAgent } from '../../../../../bi/utils/env';

import { ServiceTypes } from '../../../../../bi/types/trips';

const LABELS = {
  PLACEHOLDER: getText('trips:services'),
  FILTERS: {
    Air: getText('trips:tripServices.air'),
    Hotel: getText('trips:tripServices.hotel'),
    Transfer: getText('trips:tripServices.transfer'),
    Train: getText('trips:tripServices.train'),
    AeroExpress: getText('trips:tripServices.aeroexpress'),
    TaxiVoucher: getText('trips:tripServices.taxiVoucher'),
    VipHall: getText('trips:tripServices.vipHall'),
    Custom: getText('trips:tripServices.custom'),
    FlightCertificate: getText('trips:tripServices.flightCertificate'),
    AirAdditionalService: getText('trips:tripServices.airAdditionalService'),
    MICE: getText('trips:tripServices.mice'),
    Insurance: getText('trips:tripServices.insurance'),
    Bus: getText('trips:tripServices.bus'),
  },
};

const getServicesForSelect = (serviceType: ServiceTypes) => ({ value: serviceType, label: LABELS.FILTERS[serviceType] });

interface FilterServicesProps {
  services: ServiceTypes[];
  values: string[];
  onChange(checkedEmployees: string[]): void;
}

const FilterServices: FC<FilterServicesProps> = ({
  services,
  values,
  onChange,
}) => {
  const servicesList = services.map((serviceType: ServiceTypes) => getServicesForSelect(serviceType));

  const list = isSmartAgent
    ? servicesList.filter(({ label }) =>
      label !== LABELS.FILTERS.FlightCertificate && label !== LABELS.FILTERS.TaxiVoucher)
    : servicesList;

  return (
    <MultiSelect
      withLabel
      placeholder={ LABELS.PLACEHOLDER }
      list={ list }
      values={ values }
      onChange={ (checkedServices: string[]) => onChange(checkedServices) }
    />
  );
};

export { FilterServices };
