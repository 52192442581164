import React from 'react';
import { observer } from 'mobx-react';
import { Price, Text } from 'new-ui';

import { useServices } from '../../../../bi/context/services';

import AnotherExpenses from '../../../ExpenseReport/components/anotherExpenses';

import { LABELS } from '../../index';

import styles from './styles.module.css';

interface ISpendingBlockProps {
  hide?: boolean
}

export const SpendingBlock = observer(({ hide }: ISpendingBlockProps) => {
  const {
    travelApprovalService: {
      travelApprovalStore: {
        travelApproval: {
          Spendings,
          AvailableBalance,
          Employees,
        },
      },
    },
  } = useServices(['TravelApproval', 'Workspace']);

  const balanceGtZero = AvailableBalance ? AvailableBalance > 0 : false;

  const colorBalance = balanceGtZero ? 'green' : 'default';
  const classNameBalance = balanceGtZero ? '' : styles.error;

  if (Number(Spendings.length || 0) === 0 || hide) return null;

  const list = Spendings.map((item, index) => {
    const updateEmployees = Employees.filter(e => item.Employees.includes(e.Id as number)) || [];

    return (
      <AnotherExpenses
        key={ `${item.TripId}_${index}` }
        item={ item }
        employee={ updateEmployees }
        className={ styles.spending_text }
        isApprovalPage
      />
    );
  });

  return (
    <div className={ styles.spending }>
      <Text type='bold_24' className={ styles.spending_header }>
        { LABELS.SPENDING_REQUESTS }
      </Text>
      <div className={ styles.spending_list }>
        { ...list }
        <div className={ `${styles.spending_balance} ${classNameBalance}` }>
          { LABELS.AVAILABLE_BALANCE }
          <Price marginSmall type='SEMIBOLD_16' color={ colorBalance } value={ AvailableBalance || 0 } />
        </div>
      </div>
    </div>
  );
});
