import {
  CAPITAL_EN_CHARS,
  INTEGER_NUMBER,
  LOWERCASE_EN_CHARS,
  SYMBOLS,
} from '../constants/regExp';

const passwordMinLength = 8;
const sameSymbols = 4;

const validPassword = (password = '', userName = '', isHardPassword: boolean) => {
  const validationResult = {
    passwordMinLengthValid: false,
    passwordNotContainsUserNameSymbols: true,
  };

  const validationResultNoRequired = {
    includesNumbersValid: false,
    includesCapitalENCharsValid: false,
    includesLowercaseENCharsValid: false,
    includesSymbolsValid: false,
  };

  for (let i = 0; i < userName.length - sameSymbols; i++) {
    if (password.toLowerCase().includes(userName.toLowerCase().slice(i, i + sameSymbols))) {
      validationResult.passwordNotContainsUserNameSymbols = false;
    }
  }

  validationResultNoRequired.includesNumbersValid = INTEGER_NUMBER.test(password);
  validationResultNoRequired.includesCapitalENCharsValid = CAPITAL_EN_CHARS.test(password);
  validationResultNoRequired.includesLowercaseENCharsValid = LOWERCASE_EN_CHARS.test(password);
  validationResultNoRequired.includesSymbolsValid = SYMBOLS.test(password);

  if (password.length >= passwordMinLength) {
    validationResult.passwordMinLengthValid = true;
  }

  const validResult = Object.values(validationResult).every(Boolean);
  const validResultNoRequired = Object.values(validationResultNoRequired).filter((item) => !item).length < 2;

  const isValid = isHardPassword
    ? validResult && validResultNoRequired
    : !isHardPassword;

  return {
    validationResult,
    validationResultNoRequired,
    validResultNoRequired,
    isValid,
  };
};

export {
  validPassword,
};
