import Api from '../../api';
import { api as apiAxios } from '../../apiV2';

import { approveStore } from './store';
import { sendStatOnFastApprove } from '../cart/approveAnalytic';

import NetworkStatesStore from '../utils/network/networkStatesStore';
import { bound, withInternalMethod } from '../utils/dectrators';
import { LoadingStatus } from '../utils/network/types';

import { ExpenseApprovalStatus, QuickApprovalActionTypes, TravelApprovalStatus } from '../../constants/approve';

const LABELS = {
  SERVER_ERROR: 'Сервер не может обработать Ваш запрос, обратитесь к Travel помощнику',
  REQUEST_INCORRECT: 'Запрос сформирован неверно, обратитесь к Travel помощнику',
};

class Approve {
  api: Api;
  store = approveStore;
  networkStore = new NetworkStatesStore<'quickApprove' | 'approveList'>();

  constructor(api: Api) {
    this.api = api;
  }

  successQuickApprove = ({ Message, Success }: { Message: string, Success: boolean }) => {
    this.store.setQuickApprove(Message);

    if (!Success) {
      this.networkStore.setStatus('quickApprove', LoadingStatus.ERROR);
    }
  };

  requestIncorrect = () => {
    this.store.setQuickApprove(LABELS.REQUEST_INCORRECT);
    this.networkStore.setStatus('quickApprove', LoadingStatus.ERROR);
  };

  serverError = () => {
    this.store.setQuickApprove(LABELS.SERVER_ERROR);
  };

  @bound
  @withInternalMethod((o) => o.networkStore.withLoading('quickApprove'))
  @withInternalMethod((o) => o.networkStore.withErrorBoundary('quickApprove', o.serverError))
  async confirmQuickApprove(id: string, action: QuickApprovalActionTypes) {
    sendStatOnFastApprove(action);

    if (id && action) {
      const res = await this.api.approve.confirmQuickApprove(id, action);
      this.successQuickApprove(res);
    } else {
      this.requestIncorrect();
    }
  }

  @bound
  @withInternalMethod((o) => o.networkStore.withLoading('quickApprove'))
  @withInternalMethod((o) => o.networkStore.withErrorBoundary('quickApprove', o.serverError))
  async confirmQuickApproveTravelApproval(approveId: string, userId: string, action: TravelApprovalStatus) {
    if (approveId && userId && action) {
      const res = await this.api.approve.confirmQuickApproveTravelApproval(Number(approveId), userId, action);
      this.successQuickApprove(res);
    } else {
      this.requestIncorrect();
    }
  }

  @bound
  @withInternalMethod((o) => o.networkStore.withLoading('quickApprove'))
  @withInternalMethod((o) => o.networkStore.withErrorBoundary('quickApprove', o.serverError))
  async confirmQuickApproveExpenseReportApproval(approveId: string, userId: string, action: ExpenseApprovalStatus) {
    if (approveId && userId && action) {
      const res = await this.api.approve.confirmQuickApproveExpenseReportApproval(approveId, userId, action);
      this.successQuickApprove(res);
    } else {
      this.requestIncorrect();
    }
  }

  @bound
  @withInternalMethod((o) => o.networkStore.withLoaderFlow('approveList'))
  async getApproveList() {
    this.store.setFactualApprovesLiteResponse(await apiAxios.approve.getList());
  }
}

export default Approve;
