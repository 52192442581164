import React, { useEffect, useState } from 'react';
import { Button, Dialog, Input, RadioButton, Text } from 'new-ui';
import { RouteComponentProps } from 'react-router-dom';
import { getText } from '../../../../../i18n';

import ReportService from '../../../../bi/services/report';
import FeatureFlags from '../../../../bi/services/featureFlags';
import Workspace from '../../../../bi/services/workspace';
import PaymentService from '../../../../bi/services/payment';

import { DownloadDepositDialog } from '../../../../components/DownloadDepositDialog';
import { CloudPaymentsDialog } from '../../../../components/CloudPaymentsDialog';

import ROUTES from '../../../../bi/constants/routes';

import { isSmartAgent } from '../../../../bi/utils/env';
import { additionalFeeValidation } from '../../../../bi/utils/additionalFee';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { PRICE_FLOAT_REG_EXP_BIG, PRICE_FLOAT_REG_EXP_NO_OGR_BIG } from '../../../../bi/constants/regExp';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('settings:replishment.title'),
  PLACEHOLDER: getText('settings:replishment.placeholder'),
  SUM: getText('settings:replishment.sum'),
  SELECT: getText('settings:replishment.select'),
  CREATE_ACCOUNT: getText('settings:replishment.createAccount'),
  TOP_UP_BALANCE: getText('settings:replishment.topUpBalance'),
  UP_BALANCE: getText('settings:replishment.upBalance'),
};

const CHECKBOX = {
  CREATE: 'create',
  TOP_UP: 'top_up',
};

export interface IReplishemtBalance {
  history: RouteComponentProps['history'],
  reportService: ReportService,
  paymentService: PaymentService,
  workspaceService: Workspace,
  featureFlagsService: FeatureFlags,
}

const ReplishmentBalance = ({
  history,
  reportService,
  paymentService,
  workspaceService,
  featureFlagsService: {
    getShowElementForSmartagent,
  },
}: IReplishemtBalance) => {
  const [sum, setSum] = useState<string>('');
  const [check, setCheck] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showTopUp, setShowTopUp] = useState<boolean>(false);
  const [showApproveForm, setShowApproveForm] = useState<boolean>(true);
  const [showPayment, setShowPayment] = useState<boolean>(false);
  const [showCompaniesSelect, setShowCompaniesSelect] = useState<boolean>(false);

  useEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.SETTINGS_PAYMENT_OPENED);
  }, []);

  useEffect(() => {
    setIsValid(additionalFeeValidation(sum, PRICE_FLOAT_REG_EXP_BIG, PRICE_FLOAT_REG_EXP_NO_OGR_BIG));
  }, [sum]);

  const { Companies, CompanyId, Email, CompanyName, CompanyInn } = workspaceService.get();

  const isDisabled = !isValid || !check || !sum.length;

  if (!getShowElementForSmartagent()) {
    history.push(ROUTES.SMARTDESK);
  }

  const onChangeCheckbox = (value: string) => {
    if (value === check) {
      return setCheck('');
    }

    return setCheck(value);
  };

  const handleShow = () => {
    if (check === CHECKBOX.CREATE) {
      MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.SETTINGSPAYMENT_ADD_PRESSED('invoice'));

      return setShowCreate(true);
    }

    MainAnalytic.sendAmplitudeArrayArgs(MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.SETTINGSPAYMENT_ADD_PRESSED('card'));

    return setShowTopUp(true);
  };

  const handleDownloadDeposit = (value: string, format: string) => (value
    ? reportService.getDeposit(CompanyId, sum, format)
    : null
  );

  const renderDownloadDialog = () => {
    if (!showCreate) {
      return null;
    }

    return (
      <DownloadDepositDialog
        show={ showCreate }
        defaultValue={ sum }
        onDownloadDeposit={ (value: string, format: string) => handleDownloadDeposit(value, format) }
        onClose={ () => setShowCreate(false) }
      />
    );
  };

  const handleToggleShowCompaniesSelect = () => {
    setShowCompaniesSelect(showCompaniesSelect);
    setShowApproveForm(!showApproveForm);
  };

  const onChangeApproveForm = () => {
    setShowApproveForm(!showApproveForm);
    setShowPayment(!showPayment);
  };

  const renderCartPayDialog = () => {
    if (!showTopUp) {
      return null;
    }

    return (
      <Dialog
        show={ showTopUp }
        onChange={ () => setShowTopUp(prev => !prev) }
      >
        <CloudPaymentsDialog
          onCloseDialog={ () => setShowTopUp(prev => !prev) }
          onChangeCompaniesSelect={ handleToggleShowCompaniesSelect }
          onChangeApproveForm={ () => onChangeApproveForm() }
          companyEmail={ Email }
          companyName={ CompanyName }
          companyInn={ CompanyInn }
          currentCompany={ CompanyId }
          companiesItems={ Companies }
          showCompaniesSelect={ showCompaniesSelect }
          showApproveForm={ showApproveForm }
          paymentService={ paymentService }
          showPayment={ showPayment }
          allCompanies={ Companies }
          autoFocusInput={ showTopUp }
          price={ sum }
        />
      </Dialog>
    );
  };

  return (
    <div className={ styles.wrapper }>
      { renderDownloadDialog() }
      { renderCartPayDialog() }
      <Text type='bold_24' className={ styles.title }>
        { LABELS.TITLE }
      </Text>
      <div className={ styles.wrapper_content }>
        <div className={ styles.content }>
          <Text type='bold_16' className={ styles.sum }>
            { LABELS.SUM }
          </Text>
          <Input
            className={ styles.input }
            value={ sum }
            placeholder={ LABELS.PLACEHOLDER }
            onChange={ setSum }
          />
          <Text type='bold_16' className={ styles.select }>
            { LABELS.SELECT }
          </Text>
          <div className={ styles.wrapper_checkbox }>
            <RadioButton
              className={ styles.checkbox }
              checked={ check === CHECKBOX.CREATE }
              value={ CHECKBOX.CREATE }
              alternativeDesign={ isSmartAgent }
              onChange={ () => onChangeCheckbox(CHECKBOX.CREATE) }
            >
              <Text type='NORMAL_14'>
                { LABELS.CREATE_ACCOUNT }
              </Text>
            </RadioButton>
            <RadioButton
              className={ styles.checkbox }
              checked={ check === CHECKBOX.TOP_UP }
              value={ CHECKBOX.TOP_UP }
              alternativeDesign={ isSmartAgent }
              onChange={ () => onChangeCheckbox(CHECKBOX.TOP_UP) }
            >
              <Text type='NORMAL_14'>
                { LABELS.TOP_UP_BALANCE }
              </Text>
            </RadioButton>
          </div>
          <div className={ styles.up }>
            <Button
              disabled={ isDisabled }
              onClick={ handleShow }
              type='secondary'
            >
              { LABELS.UP_BALANCE }
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ReplishmentBalance };
