import { IconType } from 'new-ui';
import { ItemsSearcher } from 'new-ui/src/components/Searcher/type';

import { getText } from '../../../i18n';

import ROUTES from '../../bi/constants/routes';

const LABELS = {
  MENU: {
    AIR: getText('constants:serviceType.typesRuMn.air'),
    HOTEL: getText('constants:serviceType.typesRuMn.hotel'),
    TRANSFER: getText('constants:serviceType.typesRuMn.transfer'),
    TRAIN: getText('constants:serviceType.typesRuMn.train'),
    VIP_HALL: getText('constants:serviceType.typesRuMnV2.vipHall'),
    AERO: getText('constants:serviceType.typesRuMn.aero'),
  },
};

const TYPE_SEARCH: { [name: string]: IconType } = {
  AIR: 'searchAir',
  TRAIN: 'searchTrain',
  HOTEL: 'searchHotel',
  TRANSFER: 'searchTransfer',
  VIP: 'searchVip',
  AEROEXPRESS: 'searchAeroexpress',
};

export const ROUTES_SMARTDESK_SA = [
  ROUTES.SMARTDESK,
  ROUTES.SEARCH_SA.AIR,
  ROUTES.SEARCH_SA.HOTEL,
  ROUTES.SEARCH_SA.TRAIN,
  ROUTES.SEARCH_SA.TRANSFER,
  ROUTES.SEARCH_SA.VIP_HALL,
  ROUTES.SEARCH_SA.AEROEXPRESS,
];

export const ITEM_MENU_SA = {
  AIR: { label: LABELS.MENU.AIR, type: TYPE_SEARCH.AIR, link: ROUTES.SEARCH_SA.AIR },
  TRAIN: { label: LABELS.MENU.TRAIN, type: TYPE_SEARCH.TRAIN, link: ROUTES.SEARCH_SA.TRAIN },
  HOTEL: { label: LABELS.MENU.HOTEL, type: TYPE_SEARCH.HOTEL, link: ROUTES.SEARCH_SA.HOTEL },
  AERO: { label: LABELS.MENU.AERO, type: TYPE_SEARCH.AEROEXPRESS, link: ROUTES.SEARCH_SA.AEROEXPRESS },
  TRANSFER: { label: LABELS.MENU.TRANSFER, type: TYPE_SEARCH.TRANSFER, link: ROUTES.SEARCH_SA.TRANSFER },
  VIP_HALL: { label: LABELS.MENU.VIP_HALL, type: TYPE_SEARCH.VIP, link: ROUTES.SEARCH_SA.VIP_HALL },
};

export const DEFAULT_ITEMS_MENU_SA: ItemsSearcher[] = [
  ITEM_MENU_SA.AIR,
  ITEM_MENU_SA.TRAIN,
  ITEM_MENU_SA.HOTEL,
];
