import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Text, RadioButton, Button, Tooltip } from 'new-ui';
import { observer } from 'mobx-react';
import * as queryString from 'query-string';

import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { isSmartAgent } from '../../bi/utils/env';
import MainAnalytic from '../../bi/utils/analytics/main';

import { ADDITIONAL_TYPES, PATH, VALUES_DIRECTION } from '../../bi/constants/airlineAdditionalServices';
import ROUTES from '../../bi/constants/routes';

import { SearchLoading } from '../../components/SearchLoading';

import { Segment } from '../../bi/services/vipHall/types';
import { VipHallProps } from '../../bi/types/vipHall';

import styles from './index.module.css';

const LABELS = {
  SEARCH: getText('vipHall:loader'),
  TITLE: getText('components:additionalServices.selectDirection'),
  ON_DEPARTURE: getText('components:additionalServices.onDeparture'),
  ON_ARRIVAL: getText('components:additionalServices.onArrival'),
  TRANSIT: getText('components:additionalServices.transit'),
  NEXT: getText('components:additionalServices.pickOptions'),
  NO_AIRPORTS: getText('components:additionalServices.noAirports'),
  TOOLTIP: getText('components:additionalServices.tooltip'),
};

const VipHallSelectDirection: FC<VipHallProps> = observer(({
  vipHallService,
  breadCrumbsService,
  match: {
    params: { tripId, ticketId },
    path,
  },
}) => {
  const history = useHistory();

  const { vipHallSearchStore: { route, availableAirports } } = useStores([MOBX_STORES.VIP_SEARCH_STORE]);
  const { vipHallStore: { loading } } = useStores([MOBX_STORES.VIP_STORE]);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [selectedSegment, setSelectedSegment] = useState<Segment | null>(null);
  const [selectedTypeFlight, setSelectedTypeFlight] = useState<string | null>(null);

  const { route: routeId, type } = queryString.parse(location.search);

  const isVipHallPage = type === ADDITIONAL_TYPES.VIP_HALL;

  useEffect(() => {
    if (!route[0]) {
      history.push(ROUTES.ADDITIONAL_SERVICES.ROUTE(tripId, ticketId, type));

      breadCrumbsService.setCurrentPath(ROUTES.ADDITIONAL_SERVICES.ROUTE(tripId, ticketId, type));
    } else {
      breadCrumbsService.setCurrentPath(path);
    }

    vipHallService.getAvailableAirports();

    return () => {
      breadCrumbsService.setCurrentPath(PATH.MAIN);
    };
  }, []);

  const handleChangeSegment = (index: number, segment: Segment, typeFlight: string) => {
    setSelectedItem(index);
    setSelectedSegment(segment);
    setSelectedTypeFlight(typeFlight);
  };

  const handleSearch = async () => {
    const { id, terminal } = await vipHallService.getTerminalsAndVips(selectedItem, type, selectedTypeFlight);

    const analyticType = isVipHallPage
      ? MainAnalytic.ACTIONS.VIP_HALL.VIP_AIRPORT_AIRPORT_CHOSEN
      : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_AIRPORT_AIRPORT_CHOSEN;

    MainAnalytic.sendFirebase(analyticType, {
      name: selectedTypeFlight === VALUES_DIRECTION.DEPARTURE
        ? selectedSegment?.DepartureAirport.Name
        : selectedSegment?.ArrivalAirport.Name,
    });

    if (isVipHallPage) {
      breadCrumbsService.setCurrentPath(ROUTES.ADDITIONAL_SERVICES.RESULT_VIP(id, tripId, ticketId, routeId, selectedItem, terminal));

      return history.push(ROUTES.ADDITIONAL_SERVICES.RESULT_VIP(id, tripId, ticketId, routeId, selectedItem, terminal));
    }

    breadCrumbsService.setCurrentPath(ROUTES.ADDITIONAL_SERVICES.RESULT_ESCORT(id, tripId, ticketId, routeId, selectedItem, terminal));

    return history.push(ROUTES.ADDITIONAL_SERVICES.RESULT_ESCORT(id, tripId, ticketId, routeId, selectedItem, terminal));
  };

  const handleGoToChat = () => {
    const additionalType = location.href.includes('vipHall')
      ? `${ADDITIONAL_TYPES.VIP_HALL}Direction`
      : `${ADDITIONAL_TYPES.ESCORT}Direction`;

    breadCrumbsService.setCurrentPath(ROUTES.ADDITIONAL_SERVICES.OTHER_CHANGES(tripId, ticketId, additionalType));

    const analyticType = isVipHallPage
      ? MainAnalytic.ACTIONS.VIP_HALL.VIP_AIRPORT_BUTTON_PRESSED
      : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_AIRPORT_BUTTON_PRESSED;

    MainAnalytic.sendFirebase(analyticType);

    return history.push(ROUTES.ADDITIONAL_SERVICES.OTHER_CHANGES(tripId, ticketId, additionalType));
  };

  const renderTooltipContent = () => (
    <Text
      type='NORMAL_14_130'
      color='white'
      className={ styles.tooltip_content }
    >
      { LABELS.TOOLTIP }
    </Text>
  );

  const renderButton = (index: number, item: Segment, typeButton: string) => {
    const isChecked = (typeFlight: string): boolean =>
      item === selectedSegment && selectedTypeFlight === typeFlight;

    const labelDeparture = route.length > 0 && index !== 0 ?
      LABELS.TRANSIT :
      LABELS.ON_DEPARTURE;
    const labelArrival = route.length > 0 && index !== route.length - 1 ?
      LABELS.TRANSIT :
      LABELS.ON_ARRIVAL;

    const valueDeparture = route.length > 0 && index !== 0 ?
      VALUES_DIRECTION.TRANSIT :
      VALUES_DIRECTION.DEPARTURE;
    const valueArrival = route.length > 0 && index !== route.length - 1 ?
      VALUES_DIRECTION.TRANSIT :
      VALUES_DIRECTION.ARRIVAL;

    const isUnavailableDeparture = !availableAirports.includes(item.DepartureAirport.Code);
    const isUnavailableArrival = !availableAirports.includes(item.ArrivalAirport.Code);

    switch (typeButton) {
      case VALUES_DIRECTION.DEPARTURE:
        return (
          <Tooltip
            className={ styles.tooltip }
            renderContent={ renderTooltipContent }
            position='right'
            show={ isUnavailableDeparture }
          >
            <RadioButton
              className={ `${styles.item} ${isUnavailableDeparture ? styles.item_disabled : ''}` }
              value={ valueDeparture }
              alternativeDesign={ isSmartAgent }
              checked={ isChecked(valueDeparture) }
              onChange={ () => handleChangeSegment(index, item, valueDeparture) }
              disabled={ isUnavailableDeparture }
            >
              { item.DepartureCity } { labelDeparture }
            </RadioButton>
          </Tooltip>
        );

      case VALUES_DIRECTION.ARRIVAL:
        return (
          <Tooltip
            className={ styles.tooltip }
            renderContent={ renderTooltipContent }
            position='right'
            show={ isUnavailableArrival }
          >
            <RadioButton
              className={ `${styles.item} ${isUnavailableArrival ? styles.item_disabled : ''}` }
              value={ valueArrival }
              alternativeDesign={ isSmartAgent }
              checked={ isChecked(valueArrival) }
              onChange={ () => handleChangeSegment(index, item, valueArrival) }
              disabled={ isUnavailableArrival }
            >
              { item.ArrivalCity } { labelArrival }
            </RadioButton>
          </Tooltip>
        );
    }

    return null;
  };

  const renderRoutes = () => (route[0] ? route.map((item: Segment, index: number) => {
    const departureHtml = renderButton(index, item, VALUES_DIRECTION.DEPARTURE);

    const arrivalWithSegments = index !== (route.length - 1)
      ? null
      : renderButton(index, item, VALUES_DIRECTION.ARRIVAL);

    const arrivalHtml = route.length > 1
      ? arrivalWithSegments
      : renderButton(index, item, VALUES_DIRECTION.ARRIVAL);

    return (
      <div key={ index }>
        { departureHtml }
        { arrivalHtml }
      </div>
    );
  }) : null);

  if (loading) {
    return (
      <div className={ styles.search_wrapper }>
        <SearchLoading text={ LABELS.SEARCH } />;
      </div>
    );
  }

  return route && (
    <div className={ styles.wrapper }>
      <div className={ styles.title }>
        <Text type='SEMIBOLD_16' color='blue'>
          { LABELS.TITLE }
        </Text>
      </div>
      { renderRoutes() }
      <div className={ styles.buttons }>
        <Button
          className={ styles.button }
          type='secondary'
          disabled={ typeof selectedItem !== 'number' }
          onClick={ handleSearch }
        >
          { LABELS.NEXT }
        </Button>
        <Button
          className={ styles.button }
          type='textual-medium'
          onClick={ handleGoToChat }
        >
          { LABELS.NO_AIRPORTS }
        </Button>
      </div>
    </div>
  );
});

export { VipHallSelectDirection };
