import { action, computed, observable, autorun, makeObservable } from 'mobx';
import { IAnalyticsType, AnalyticsTypeNullable } from './types';

const DEFAULT_ANALYTICS: AnalyticsTypeNullable = {
  Id: null,
  Name: '',
  Required: false,
  Editable: false,
  ApplyToTrip: false,
  Values: [],
};

class CustomAnalyticsStore {
  @observable analytics: IAnalyticsType | null = { ...DEFAULT_ANALYTICS } as IAnalyticsType;
  @observable list: IAnalyticsType[] = [];
  @observable loading = false;
  @observable sortedCustomAnalytics: IAnalyticsType[] = [];
  @observable requiredCustomAnalytics: IAnalyticsType[][] = [[], []];
  @observable availableValue = false;

  constructor() {
    makeObservable(this);

    autorun(() => {
      this.sortedCustomAnalytics = this.computedSortedCustomAnalytics;
    });

    autorun(() => {
      this.requiredCustomAnalytics = this.computedRequiredCustomAnalytics;
    });
  }

  @computed
  get computedRequiredCustomAnalytics() {
    return this.list.reduce<IAnalyticsType[][]>(([tripAnalytics, serviceAnalytics], item) => {
      const { ApplyToTrip: isTripAnalytics, Required } = item;

      if (!Required) {
        return [tripAnalytics, serviceAnalytics];
      }

      return isTripAnalytics
        ? [[...tripAnalytics, item], serviceAnalytics]
        : [tripAnalytics, [...serviceAnalytics, item]];
    }, [[], []]);
  }

  @computed
  get computedSortedCustomAnalytics(): IAnalyticsType[] {
    return this.list.slice().sort((a, b) =>
      Number(b.Required) - Number(a.Required)
      || a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()),
    );
  }

  @action
  setAnalyticsList = (list: IAnalyticsType[] = []): void => {
    this.list = list;
  };

  @action
  setAnalytics = (result: IAnalyticsType | null = null): void => {
    this.analytics = result;
  };

  @action
  deleteAnalytics = (list: IAnalyticsType[] = [], id: number): void => {
    this.list = list.filter((el) => el.Id !== id);
  };

  @action
  setAvailableValue = (value = false): void => {
    this.availableValue = value;
  };

  @action
  setLoading = (value = false): void => {
    this.loading = value;
  };

  @action
  reset = (): void => {
    this.loading = false;
    this.list = [];
    this.sortedCustomAnalytics = [];
    this.requiredCustomAnalytics = [[], []];
    this.analytics = { ...DEFAULT_ANALYTICS } as IAnalyticsType;
  };
}

export default CustomAnalyticsStore;
