import React, { useState } from 'react';
import { Button, Dropdown, Text } from 'new-ui';
import { ApproveStatus } from '../../../bi/constants/expenseReport';
import { getText } from '../../../../i18n';

import { getEmployeeFullNameWithSimplicity } from '../../../bi/utils/employees';
import {
  formatRangeDateWithSimplicity,
  normalizeOurClusterLocalTime,
} from '../../../bi/utils/formatDate';

import { PATTERN } from '../../../bi/constants/dateFormats';
import FORMATS from '../../../bi/constants/formats';

import InputModal from '../../../components/InputModal';
import { StatusMarker } from '../../../components/StatusMarker';

import styles from '../styles/item.module.css';
import { ExpenseReportItemProps } from '../../../bi/services/expenseReports/types';

const LABELS = {
  UPDATED: getText('expenseReports:item.updated'),
  CREATED: getText('expenseReports:item.created'),
  PDF: getText('expenseReports:item.download.pdf'),
  XLSX: getText('expenseReports:item.download.xlsx'),
  EMAIL: getText('expenseReports:item.toEmail'),
  SEND: getText('expenseReports:item.send'),
  SEND_1S: getText('expenseReports:item.send_1c'),
  NOT_SEND_1S: getText('expenseReports:item.not_send_1c'),
  SEND_TO_APPROVE: getText('expenseReports:item.waitingApprove'),
  NOT_APPROVE: getText('expenseReports:item.notApprove'),
  APPROVE: getText('expenseReports:item.approve'),
};

const ExpenseReportItem: React.FC<ExpenseReportItemProps> = ({
  onlyOwn,
  isIntegration1S,
  item: {
    Id,
    Name,
    Stamp,
    CheckinDate,
    CheckoutDate,
    Updated,
    Status,
    Data: {
      Employee,
    },
  },
  onSendEmail,
  onDownload,
}) => {
  const [dialog, setDialog] = useState(false);

  const handleChange = (e: React.MouseEvent<HTMLButtonElement> | any, field: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (field === FORMATS.EMAIL) {
      return setDialog(true);
    }

    return onDownload(field, Id);
  };

  const statusHtml = isIntegration1S && (<StatusMarker isExpense status={ Status } rightIcon />);

  const sendEndDownloadHtml = () => (Status === 0 ? (
    <Dropdown
      onClick={ e => handleChange(e, FORMATS.PDF) }
      title={ LABELS.PDF }
    >
      <div className={ styles.content }>
        <Button
          className={ styles.content_button }
          type='textual'
          onClick={ e => handleChange(e, FORMATS.XLSX) }
        >
          { LABELS.XLSX }
        </Button>
        <Button
          className={ styles.content_button }
          type='textual'
          onClick={ e => handleChange(e, FORMATS.EMAIL) }
        >
          { LABELS.EMAIL }
        </Button>
      </div>
    </Dropdown>
  ) : null);

  const employeeHtml = !onlyOwn && (
    <Text type='NORMAL_14' className={ styles.employee }>
      {getEmployeeFullNameWithSimplicity(Employee, true)}
    </Text>
  );
  const dropdownStyles = isIntegration1S ? styles.not_last : styles.last;
  const generalStyles = !onlyOwn ? styles.opts : '';
  const generalDateLabel = Updated ? LABELS.UPDATED : LABELS.CREATED;

  const labelStatus = () => ({
    [ApproveStatus.WAITING_APPROVE]: LABELS.SEND_TO_APPROVE,
    [ApproveStatus.DECLINED]: LABELS.NOT_APPROVE,
    [ApproveStatus.APPROVED]: LABELS.APPROVE,
  }[Status]);

  const status = Status === 4 || Status === 5 || Status === 6 ?
    <Text
      type='NORMAL_14'
      color='gray'
      className={ styles.general_date }
    >
      {labelStatus()}
    </Text> : null;

  return (
    <>
      <div className={ styles.wrapper }>
        <div className={ `${styles.general} ${generalStyles}` }>
          <>
            <Text type='bold_18'>{Name}</Text>
            {employeeHtml}
          </>
          <Text
            type='NORMAL_14'
            color='gray'
            className={ styles.general_date }
          >
            {generalDateLabel}: {normalizeOurClusterLocalTime(Stamp, PATTERN.DAY_OF_MONTH)}
          </Text>
          {status}
        </div>
        <Text type='NORMAL_18' className={ styles.date }>
          {formatRangeDateWithSimplicity(CheckinDate, CheckoutDate)}
        </Text>
        <div className={ `${styles.dropdown} ${dropdownStyles}` }>
          {sendEndDownloadHtml()}
        </div>
        {statusHtml}
      </div>
      <InputModal
        show={ dialog }
        title={ LABELS.SEND }
        onCancel={ () => setDialog(false) }
        onSend={ (value: string) => {
          onSendEmail(value, Id);
          setDialog(false);
        } }
      />
    </>
  );
};

export { ExpenseReportItem };
