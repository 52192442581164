import { EventStore } from './stores/events';

import { EVENT_STATUSES } from './const';

import { IEventStore, TEventToBack, TEventValue } from './type';

class EventService {
  api: any;
  store: IEventStore;

  constructor(api: any) {
    this.api = api;
    this.store = EventStore;
  }

  statusFiltredEvent = () => (this.store.filtered.Statuses.length ? this.store.filtered.Statuses : EVENT_STATUSES.DEFAULT);

  getEvents = async (filtered?: string[]) => {
    this.store.setLoadingEvents(true);

    try {
      const Statuses = filtered || this.store.filtered.Statuses;

      const data = await this.api.event.getEvents({ Statuses });

      this.store.getEvents(data);
      this.store.setLoadingEvents(false);
    } catch (e) {
      this.store.setErrorEvents(true);
    }
  };

  getEvent = async (id: string) => {
    this.store.setLoadingEvents(true);

    try {
      const data = await this.api.event.getEvent(id);

      this.store.getEvent(data);

      this.store.setLoadingEvents(false);
    } catch (e) {
      this.store.setErrorEvents(true);
    }
  };

  setStatusesFiltred = (value: string[]) => {
    this.store.setStatusesFiltred(value);
  };

  createEvent = async (value: TEventValue | TEventToBack) => {
    this.store.setLoadingEvents(true);

    try {
      await this.api.event.createEvent(value);

      this.store.createEvent(value as TEventValue);

      await this.getEvents(this.statusFiltredEvent());
    } catch (e) {
      this.store.setErrorEvents(true);
    }
  };

  editName = async (id: string, index: number, value: string) => {
    await this.api.event.renameEvent({ EventId: id, Name: value });

    this.store.editName(index, value);
  };

  linkApplication = async (data: any) => {
    await this.api.event.linkApplication(data);
  };

  setOpenSwitcher = (value: boolean) => {
    this.store.setOpenSwitcher(value);
  };

  setValueSwitcher = (value: string | null) => {
    this.store.setValueSwitcher(value);
  };
}

export default EventService;
