import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Dialog, Text, Textarea, ItemLoader } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { HistoryComments } from './history';

import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import { sanitizeText } from '../../../../../bi/utils/text';

import { DialogCommentsProps } from '../../../../../bi/services/tripComments/types';

import styles from '../styles/dialog.module.css';

const LABELS = {
  TITLES: {
    ADD_COMMENT: getText('trip:comments.dialog.titles.addComment'),
    COMMENTS: getText('trip:comments.dialog.titles.comments'),
  },
  TEXTS: {
    ONE: getText('trip:comments.dialog.texts.one'),
    TWO: getText('trip:comments.dialog.texts.two'),
  },
  PLACEHOLDER: getText('trip:comments.dialog.placeholder'),
  BUTTONS: {
    ADD: getText('trip:comments.dialog.buttons.add'),
    CANCEL: getText('trip:comments.dialog.buttons.cancel'),
  },
  HISTORY_LOADING: getText('trip:comments.dialog.history.loading'),
};

const DialogComments = observer(({
  show,
  onClose,
  onAdd,
}: DialogCommentsProps) => {
  const {
    tripCommentsStore: { loading, isHistory },
  } = useStores([MOBX_STORES.TRIP_COMMENTS]);

  const [comment, setComment] = useState('');

  const handleAddComment = () => {
    setComment('');
    const text = sanitizeText(comment);

    return onAdd(text);
  };

  const renderHistory = () => isHistory && !loading && <HistoryComments />;

  const renderLoader = () => loading && (
    <div className={ styles.loading }>
      <ItemLoader
        text={ LABELS.HISTORY_LOADING }
        typeText='NORMAL_14'
      />
    </div>
  );

  const title = isHistory ? LABELS.TITLES.COMMENTS : LABELS.TITLES.ADD_COMMENT;
  const disabled = loading || !comment.length || !sanitizeText(comment).length;

  return (
    <Dialog
      showClosing
      show={ show }
      className={ styles.wrapper }
      onChange={ onClose }
    >
      <Text
        type='bold_20'
        className={ styles.title }
      >
        { title }
      </Text>
      { renderLoader() }
      { renderHistory() }
      <Text className={ styles.text_one }>
        { LABELS.TEXTS.ONE }
      </Text>
      <Text className={ styles.text_two }>
        { LABELS.TEXTS.TWO }
      </Text>
      <Textarea
        value={ comment }
        placeholder={ LABELS.PLACEHOLDER }
        className={ styles.textarea }
        onChange={ setComment }
      />
      <div className={ styles.actions }>
        <Button
          disabled={ disabled }
          type='secondary'
          onClick={ handleAddComment }
        >
          { LABELS.BUTTONS.ADD }
        </Button>
        <Button
          type='textual-medium'
          className={ styles.cancel }
          onClick={ onClose }
        >
          { LABELS.BUTTONS.CANCEL }
        </Button>
      </div>
    </Dialog>
  );
});

export { DialogComments };
