declare global {
  interface Promise<T> {
    currentFn: any
    abort: () => void
    __type: T
  }
}

const debounceXhr = <
  Fn extends (...args: any[]) => any,
  Params extends Parameters<Fn>,
  Return extends ReturnType<Fn>,
>(fn: Fn, timer: number) => {
  let timeout: number | null = null;

  return (...args: Params): Return => {
    const promise = new Promise((resolve) => {
      const later = () => {
        timeout = null;
        promise.currentFn = fn.apply(this, args);
        resolve(promise.currentFn);
      };

      clearTimeout(timeout as number);
      timeout = setTimeout(later, timer) as unknown as number;
    });

    promise.abort = () => {
      clearTimeout(timeout as number);

      if (promise.currentFn && promise.currentFn.abort) {
        promise.currentFn.abort();
      }
    };

    return promise as Return;
  };
};

export default debounceXhr;
