// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import { Text, PROPS } from 'new-ui';

import { formatDate } from '../../../../bi/utils/formatDate';
import toDecline from '../../../../bi/utils/toDecline';

import { TRAVELLERS } from '../../../../bi/constants/airline';

import styles from '../../styles/history.module.css';

const {
  TYPES: {
    NORMAL_14,
  },
  COLORS: {
    DEFAULT,
    GRAY,
  },
} = PROPS.TEXT;

const AirlinesHistoryItem = ({ item: { dates, label, details: { TravellersCount } } }) => {
  const dateHtml = dates.map(date => formatDate(date)).join(' – ');
  const travellers = toDecline(TravellersCount, TRAVELLERS);

  const textHtml = (text, color = GRAY, style = '') => (
    <Text
      type={ NORMAL_14 }
      color={ color }
      className={ style }
    >
      { text }
    </Text>
  );

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.route }>
        {textHtml(dateHtml)}
        {textHtml(label, DEFAULT, styles.label)}
      </div>
      {textHtml(`${TravellersCount} ${travellers}`)}
    </div>
  );
};

AirlinesHistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export { AirlinesHistoryItem };
