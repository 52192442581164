import React, { FC, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Text, Checkbox, Button, Tooltip } from 'new-ui';
import * as queryString from 'query-string';

import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { prepareAirportName } from '../../bi/utils/vipHall';
import { formatDate, fullFormYear } from '../../bi/utils/formatDate';
import MoneyFormat from '../../bi/utils/money';
import MainAnalytic from '../../bi/utils/analytics/main';

import { PATH } from '../../bi/constants/airlineAdditionalServices';
import ROUTES from '../../bi/constants/routes';

import { VipHallSearchMenu } from '../../components/Menu/VipHallSearchMenu';
import { SearchLoading } from '../../components/SearchLoading';
import { VipHallCard } from './components/VipHallCard';
import { VipHallInfo } from './components/VipHallInfo';
import { Additionals } from './components/Additionals';

import { VipHallOrderProps } from '../../bi/types/vipHall';
import { IDetails } from '../../bi/types/airlineAdditionalServices';

import styles from './index.module.css';

const LABELS = {
  ADDITIONAL: getText('vipHall:item.additional'),
  RUB: getText('vipHall:item.rub'),
  AGREEMENT: getText('vipHall:item.agreement'),
  PAYMENT: getText('vipHall:item.payment'),
  SEARCH: getText('vipHall:item.search'),
  DEMO: getText('cart:tooltipTexts.demo'),
  UNAVAILABLE: getText('cart:tooltipTexts.unavailable'),
};

const VipHallOrder: FC<VipHallOrderProps> = observer(({
  vipHallService,
  breadCrumbsService,
  orderService,
  workspaceService,
  match: { params: { guid, tripId, ticketId }, path },
}) => {
  const history = useHistory();

  const { IsDemo } = workspaceService.get();

  const [detailsData, setDetailsData] = useState<IDetails | null>(null);
  const [agreement, setAgreement] = useState(false);
  const [scroll, setScroll] = useState<number>(0);
  const scrollRef = useRef(0);

  const { vipHallStore: { choosenVipHall, additionalServices, loading, mainInfo, items } } = useStores([MOBX_STORES.VIP_STORE]);
  const { name, additional_services, one_passenger_cost } = choosenVipHall;

  const { guid: id, route, segment, terminal }: any = queryString.parse(location.search);

  const isVipHallPage = path === PATH.INFO_VIP;

  const handleGetInfoVipHall = async () => {
    await vipHallService.getInfoVipHall(guid);
  };

  const handleScroll = () => {
    const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolledPersent = Math.round((windowScroll / height) * 100);

    setScroll(scrolledPersent);
  };

  useEffect(() => {
    if (scroll > scrollRef.current) {
      scrollRef.current = scroll;
    }
  }, [scroll]);

  useEffect(() => {
    handleGetInfoVipHall();

    const { details } = orderService.get();

    setDetailsData(details);

    vipHallService.searchByGuid(id, terminal);

    breadCrumbsService.setCurrentPath(path);

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      breadCrumbsService.setCurrentPath(PATH.MAIN);

      const analyticType = isVipHallPage
        ? MainAnalytic.ACTIONS.VIP_HALL.VIP_DETAIL_PAGE_SCROLLED
        : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_DETAIL_PAGE_SCROLLED;

      MainAnalytic.sendFirebase(analyticType, {
        deep: scrollRef.current,
      });

      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const tripItem = detailsData && detailsData.OrderItems.find((ticket: any) => ticket.Id === +ticketId);
  const ticketData = tripItem && JSON.parse(tripItem.ActualVersion.JsonData);

  const airport = ticketData && prepareAirportName(ticketData, mainInfo, route, segment);
  const birthDates = tripItem && formatDate(tripItem.ActualVersion.Employees[0].Birthday, fullFormYear);
  const category = (items[0] && items.find((item: any) => item.hallId === guid)?.category) || '';

  const fullPriceHtml = MoneyFormat.money(one_passenger_cost);

  const handleChangeAdditional = (service: any, index: number) => {
    vipHallService.changeAdditionalService({ ...service, index, count: 1 });
    vipHallService.updateCost([birthDates]);
  };

  const handleChangeCount = (value: number, index: number) => {
    vipHallService.changeAdditionalCount(index, value);
    vipHallService.updateCost([birthDates]);
  };

  const handleChangeAgreement = () => {
    setAgreement(!agreement);

    const analyticType = isVipHallPage
      ? MainAnalytic.ACTIONS.VIP_HALL.VIP_DETAIL_CHECKBOX_CHECKED
      : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_DETAIL_CHECKBOX_CHECKED;

    MainAnalytic.sendFirebase(analyticType);
  };

  const handlePayVipHall = () => {
    const pathType = isVipHallPage
      ? ROUTES.ADDITIONAL_SERVICES.PAYMENT_VIP(guid, tripId, ticketId, id, route, segment, terminal)
      : ROUTES.ADDITIONAL_SERVICES.PAYMENT_ESCORT(guid, tripId, ticketId, id, route, segment, terminal);

    history.push(pathType);

    breadCrumbsService.setCurrentPath(pathType);

    const analyticType = isVipHallPage
      ? MainAnalytic.ACTIONS.VIP_HALL.VIP_DETAIL_BUTTON_PRESSED
      : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_DETAIL_BUTTON_PRESSED;

    MainAnalytic.sendFirebase(analyticType);
  };

  const renderAdditionals = additional_services && !!additional_services.length
    ? additional_services.map((service: any, index: any) => (
      <Additionals
        key={ index }
        index={ index }
        service={ service }
        additionalServices={ additionalServices }
        onChangeAdditional={ handleChangeAdditional }
        onChangeCount={ handleChangeCount }
      />
    ))
    : null;

  const renderAdditionalsServices = () => (additional_services && !!additional_services.length ? (
    <>
      <div className={ styles.title }>
        <Text type='bold_32'>{ LABELS.ADDITIONAL }</Text>
      </div>
      <div className={ styles.additionals }>
        { renderAdditionals }
      </div>
    </>
  ) : null);

  if (loading || !ticketData) {
    return (
      <div className={ styles.search_wrapper }>
        <SearchLoading text={ LABELS.SEARCH } />
      </div>
    );
  }

  const renderPaymentButton = () => {
    if (IsDemo) {
      return (
        <Tooltip
          renderContent={ () => (
            <div className={ styles['tooltip-content'] }>
              <Text color='white' type='NORMAL_14_130'>{LABELS.UNAVAILABLE}</Text>
              <Text color='white' type='NORMAL_14_130'>{LABELS.DEMO}</Text>
            </div>
          ) }
        >
          <Button
            type='secondary'
            onClick={ () => {} }
            disabled
          >{ LABELS.PAYMENT }
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button
        type='secondary'
        onClick={ handlePayVipHall }
        disabled={ !agreement || IsDemo }
      >{ LABELS.PAYMENT }
      </Button>
    );
  };

  return (
    <>
      <VipHallSearchMenu
        ticketData={ ticketData }
        mainInfo={ mainInfo }
      />
      <div className={ styles.page }>
        <div className={ styles.text }>
          <Text type='bold_32'>{ name }</Text>
        </div>
        <VipHallCard item={ choosenVipHall } airport={ airport } guid={ guid } />
        <VipHallInfo item={ choosenVipHall } category={ category } />
        { renderAdditionalsServices() }
        <div className={ styles['agreement-checkbox'] }>
          <Checkbox
            value={ agreement }
            onChange={ handleChangeAgreement }
          >
            <Text type='NORMAL_14'>{ LABELS.AGREEMENT }</Text>
          </Checkbox>
        </div>
        <div className={ styles.pay }>
          <Text type='bold_24' className={ styles.cost }>{ fullPriceHtml }</Text>
          <Text type='NORMAL_16'>{ LABELS.RUB }</Text>
        </div>
        <div className={ styles.button }>
          {renderPaymentButton()}
        </div>
      </div>
    </>
  );
});

export { VipHallOrder };
