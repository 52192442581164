import React from 'react';
import { Checkbox, Select, Text } from 'new-ui';

import { observer } from 'mobx-react';
import { getText } from '../../../../../../i18n';

import { PersonalPaymentProtocol } from '../../../../../bi/protocols/personalPayment';
import FeatureFlags from '../../../../../bi/services/featureFlags';

import { isSmartAgent } from '../../../../../bi/utils/env';

import { RightsDialogWrap } from '../RightsDialogWrap';

import {
  FIELDRIGHTS,
  STEPSRIGHTS,
  ACQUIRING_LEVEL_TEXT,
} from '../../../../../bi/constants/rights';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';
import { ACCOUNT_LANGUAGES_VALUES } from '../../../../../bi/constants/accounts';

import styles from '../index.module.css';

const LABELS = {
  EMPLOYEE_MAY: (empl: string) => getText('settings:employees.employee.steps.rights.steps.main.employeeMay', { empl }),
  SELF_BUY: getText('settings:employees.employee.steps.rights.steps.main.selfBuy'),
  INSURANCE_BUY: getText('settings:employees.employee.steps.rights.steps.main.buyInsurance'),
  FOR_ANOTHER_EMPLOYEES: getText('settings:employees.employee.steps.rights.steps.main.forAnotherEmployees'),
  FOR_ANOTHERS: getText('settings:employees.employee.steps.rights.steps.main.forAnothers'),
  APPROVE: getText('settings:employees.employee.steps.rights.steps.main.approve'),
  FINANCE: getText('settings:employees.employee.steps.rights.steps.main.finance'),
  APPROVE_EXPENSE_REPORTS: getText('settings:employees.employee.steps.rights.steps.main.approveExpenseReports'),
  ACCOUNT_LANGUAGE: getText('settings:employees.employee.steps.rights.steps.main.accountLanguage'),
  BUY_WITH_PERSONAL_FUNDS: getText('settings:employees.employee.steps.rights.steps.main.buyWithPersonalFunds'),
};

const { employee: { documents: { access: { modals } } } } = QA_ATTRIBUTES;

interface IMainStep {
  showReports: boolean,
  showExpenseReportApprove: boolean,
  employee: string,
  active: string,
  header: string,
  rights: {
    showSecondStep: boolean,
    buyTripPersonal: boolean,
    disableBuyTripPersonal: boolean,
    buyTripAccount: boolean,
    approve: boolean,
    approveExpenseReports: boolean,
    finance: boolean,
    buyWithPersonalFunds: string,
    buyInsurance: boolean,
  },
  accountLng: string,
  personalPaymentProtocol: PersonalPaymentProtocol,
  featureFlagsService: FeatureFlags,
  onClose(): void,
  onChangeStep(value: string): void,
  onChangeRights(field: string, value: boolean): void,
  onChangeAccountLng(value: string): void,
  onChangeBuyWithPersonalFunds(value: string | boolean): void,
  isWhiteLabel: boolean,
}

const MainStep = observer(({
  showReports,
  showExpenseReportApprove,
  employee,
  active,
  header,
  rights: {
    showSecondStep,
    buyTripPersonal,
    disableBuyTripPersonal,
    buyTripAccount,
    approve,
    approveExpenseReports,
    finance,
    buyWithPersonalFunds,
    buyInsurance,
  },
  accountLng,
  featureFlagsService,
  personalPaymentProtocol,
  onClose,
  onChangeStep,
  onChangeRights,
  onChangeAccountLng,
  onChangeBuyWithPersonalFunds,
  isWhiteLabel,
}: IMainStep) => {
  const commonNextStep = showSecondStep ? STEPSRIGHTS.LIMIT : STEPSRIGHTS.APPROVE_EXPENSE_REPORTS;
  const nextStep = isSmartAgent ? STEPSRIGHTS.ADMIN : commonNextStep;
  const buyForAnother = isSmartAgent ? LABELS.FOR_ANOTHERS : LABELS.FOR_ANOTHER_EMPLOYEES;
  const isDispayInsurance = featureFlagsService.getDisplayInsurance();

  const renderSelfBy = () => !isSmartAgent && (
    <Checkbox
      value={ buyTripPersonal }
      disabled={ disableBuyTripPersonal }
      onChange={ value => onChangeRights(FIELDRIGHTS.BUYTRIPPERSONAL, value) }
      className={ styles.item }
      qaAttr={ modals.main.selfBuy }
    >
      { LABELS.SELF_BUY }
    </Checkbox>
  );

  const renderApprove = () => !isSmartAgent && (
    <Checkbox
      value={ approve }
      onChange={ value => onChangeRights(FIELDRIGHTS.APPROVE, value) }
      className={ styles.item }
      qaAttr={ modals.main.approve }
    >
      { LABELS.APPROVE }
    </Checkbox>
  );

  const renderInsurance = () => !isSmartAgent && !isWhiteLabel && isDispayInsurance && (
    <Checkbox
      value={ buyInsurance }
      onChange={ value => onChangeRights(FIELDRIGHTS.BUY_INSURANCE, value) }
      className={ styles.item }
    >
      { LABELS.INSURANCE_BUY }
    </Checkbox>
  );

  const renderApprovedER = () => !isSmartAgent && showExpenseReportApprove && (
    <Checkbox
      value={ approveExpenseReports }
      onChange={ value => onChangeRights(FIELDRIGHTS.APPROVE_EXPENSE_REPORTS, value) }
      className={ styles.item }
      qaAttr={ modals.main.approveExpenseReports }
    >
      { LABELS.APPROVE_EXPENSE_REPORTS }
    </Checkbox>
  );

  const renderBuyWithPersonalFunds = () => {
    if (!personalPaymentProtocol.canPayWithPersonalFunds) {
      return null;
    }

    const label = ACQUIRING_LEVEL_TEXT.find(({ value }) => value === buyWithPersonalFunds)?.label;
    const mayBuyWithPersonalFunds = personalPaymentProtocol.canPayWithPersonalFunds;

    return (
      <Checkbox
        value={ mayBuyWithPersonalFunds }
        onChange={ value => onChangeBuyWithPersonalFunds(value) }
        className={ styles.item }
        qaAttr={ modals.main.approveExpenseReports }
      >
        <Text className={ styles.two_items }>
          { LABELS.BUY_WITH_PERSONAL_FUNDS }
          <Text color='blue'> { label } </Text>
        </Text>
      </Checkbox>
    );
  };

  return (
    <RightsDialogWrap
      header={ header }
      theme={ active }
      onClickButton={ () => onChangeStep(nextStep) }
      onClickCancel={ onClose }
      qaAttrHeader={ modals.main.title }
      qaAttrButton={ modals.buttonNext }
      qaAttrLink={ modals.main.buttons.cancel }
    >
      <Text className={ styles.subtitle }>
        {LABELS.EMPLOYEE_MAY(employee)}
      </Text>
      { renderSelfBy() }
      <Checkbox
        value={ buyTripAccount }
        onChange={ value => onChangeRights(FIELDRIGHTS.BUYTRIPACCOUNT, value) }
        className={ styles.item }
        qaAttr={ modals.main.forAnother }
      >
        { buyForAnother }
      </Checkbox>
      { renderApprove() }
      { renderInsurance() }
      { showReports && (
        <Checkbox
          value={ finance }
          onChange={ value => onChangeRights(FIELDRIGHTS.FINANCE, value) }
          className={ styles.item }
          qaAttr={ modals.main.finance }
        >
          { LABELS.FINANCE }
        </Checkbox>
      ) }
      { renderApprovedER() }
      { renderBuyWithPersonalFunds() }
      <div className={ `${styles['multiple-item']} ${styles.item}` }>
        <Text>{ LABELS.ACCOUNT_LANGUAGE }</Text>
        <Select
          theme='open-blue'
          className={ styles.select }
          items={ ACCOUNT_LANGUAGES_VALUES }
          onChange={ onChangeAccountLng }
          value={ accountLng }
        />
      </div>
    </RightsDialogWrap>
  );
});

export { MainStep };
