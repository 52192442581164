// @ts-nocheck
import REQUESTSACTION from './action';
import RequestsStore from './store';

import { alertDev } from '../../utils/alert';

class Requests {
  constructor(api) {
    this.api = api.requests;
    this.apiUserSession = api.userSession;
    this.requestsStore = RequestsStore();
  }

  get = () => this.requestsStore.getState();

  loadRequests = async () => {
    try {
      const payloadRequests = await this.api.loadRequests();
      const payloadDepartments = await this.apiUserSession.getDepartments();

      return this.requestsStore.dispatch({
        type: REQUESTSACTION.LOADREQUESTS,
        payload: { payloadRequests, payloadDepartments },
      });
    } catch (e) {
      return this.requestsStore.dispatch({
        type: REQUESTSACTION.DISABLELOADING,
      });
    }
  };

  updateLoadingRequestsFlag = () => {
    this.requestsStore.dispatch({ type: REQUESTSACTION.UPDATE_LOADING_REQUESTS_FLAG });
    this.loadRequests();
  };

  updateLoadingRequests = value => this.requestsStore.dispatch({
    type: REQUESTSACTION.UPDATE_LOADING,
    payload: value,
  });

  changeFilters = value => this.requestsStore.dispatch({
    type: REQUESTSACTION.CHANGE_FILTERS,
    payload: value,
  });

  loadRequest = id => this.api.loadRequest(id).then((res) => {
    this.requestsStore.dispatch({
      type: REQUESTSACTION.LOADREQUEST,
      payload: res,
    });
  });

  getTripsRequests = async (filter) => {
    await this.requestsStore.dispatch({ type: REQUESTSACTION.UPDATE_LOADING_REQUESTS_FLAG });

    try {
      const res = await this.api.getTripsRequests(filter);
      this.requestsStore.dispatch({ type: REQUESTSACTION.LOAD_TRIPS_REQUEST, payload: res });
    } catch (e) {
      alertDev(e);
    }
  };

  setTripsRequests = async (TripItemId, RequestId) => {
    this.requestsStore.dispatch({ type: REQUESTSACTION.UPDATE_LOADING_REQUESTS_FLAG });
    this.updateLoadingTripRequest();

    try {
      const res = await this.api.setTripsRequests({ TripItemId, RequestId });
      this.updateLoadingTripRequest();

      return await Promise.resolve(res);
    } catch (e) {
      return alertDev(e);
    }
  };

  updateLoadingTripRequest = () => {
    this.requestsStore.dispatch({
      type: REQUESTSACTION.UPDATE_LOADING_TRIP_REQUEST,
    });
  };

  setRequestItem = (item) => {
    this.requestsStore.dispatch({
      type: REQUESTSACTION.SETREQUESTITEM,
      payload: item,
    });
  };

  getIntegration = async () => {
    const payload = await this.api.getIntegration();
    this.requestsStore.dispatch({
      type: REQUESTSACTION.GETINTEGRATION,
      payload,
    });
  };

  enableIntegration = () => {
    this.requestsStore.dispatch({
      type: REQUESTSACTION.ENABLEINTEGRATION,
    });
  };

  disableIntegration = async (confirmed = false) => {
    if (confirmed) {
      await this.api.disableIntegration();
    }

    this.requestsStore.dispatch({ type: REQUESTSACTION.DISABLEINTEGRATION });
  };

  toggleEmptyIdentifier = value => {
    this.requestsStore.dispatch({ type: REQUESTSACTION.TOGGLEEMPTYIDENTIFIER, payload: value });
  };

  switchIntegration = ({ guid, companies, previousGuid, previousCompanies }) => {
    this.requestsStore.dispatch({
      type: REQUESTSACTION.SETADDINTEGRATIONLOADING,
      payload: true,
    });

    this.api.switchIntegration({ guid, companies })
      .then(r => (
        this.requestsStore.dispatch({
          type: REQUESTSACTION.SWITCHINTEGRATION,
          payload: { guid, companies, previousGuid, previousCompanies, response: r },
        })
      ))
      .catch(() => (
        this.requestsStore.dispatch({
          type: REQUESTSACTION.FAILEDSWITCHINTEGRATION,
        })
      ));
  };

  toggleEditMode = ({ currentGuid, previousGuid }) => {
    this.requestsStore.dispatch({
      type: REQUESTSACTION.TOGGLEEDITMODE,
      payload: { currentGuid, previousGuid },
    });
  };

  hideErrorDialog = () => {
    this.requestsStore.dispatch({
      type: REQUESTSACTION.HIDEERRORDIALOG,
    });
  };

  changePage = (page) => {
    this.requestsStore.dispatch({
      type: REQUESTSACTION.CHANGEPAGE,
      payload: page,
    });
  };

  clearStore = () => {
    this.requestsStore.dispatch({
      type: REQUESTSACTION.CLEAR,
    });
  };

  subscribe = cb => this.requestsStore.subscribe(cb);
}

export default Requests;
