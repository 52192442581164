import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ChangeAirTripFormOther } from '../../components/ChangeAirTripFormOther';

import { PATH } from '../../bi/constants/changeAirTrip';

import { ChatService } from '../../bi/types/chat';
import { WorkspaceService } from '../../bi/types/workspace';
import { OrderService, IOrderDetailsData } from '../../bi/types/order';
import { AirlineMatch } from '../../bi/types/airline';
import { BreadCrumbsService } from '../../bi/types/breadCrumbs';

import styles from './styles/index.module.css';

interface ChangeAirTripOtherProps {
  chatService: ChatService,
  workspaceService: WorkspaceService,
  orderService: OrderService,
  breadCrumbsService: BreadCrumbsService,
  match: AirlineMatch,
  history: RouteComponentProps['history'],
}

const ChangeAirTripOther = ({
  chatService,
  workspaceService,
  orderService,
  breadCrumbsService,
  match: {
    params: { tripId, ticketId },
    path,
  },
  history,
}: ChangeAirTripOtherProps) => {
  const [detailsData, setDetailsData] = useState<IOrderDetailsData | null>(null);

  const tripItem = detailsData && detailsData.OrderItems.find(ticket => ticket.Id === +ticketId);

  useEffect(() => {
    breadCrumbsService.setCurrentPath(path);
    const { details } = orderService.get();

    setDetailsData(details);

    return () => {
      breadCrumbsService.setCurrentPath(PATH.MAIN);
    };
  }, []);

  return (
    <ChangeAirTripFormOther
      classNameWrapper={ styles.wrapper_form }
      chatService={ chatService }
      history={ history }
      tripItem={ tripItem }
      tripId={ tripId }
      ticketId={ ticketId }
      isDemo={ workspaceService.isDemo }
      isSectionOther
    />
  );
};

export { ChangeAirTripOther };
