import React, { FC, useState } from 'react';
import { Dialog, Button, Checkbox, Text, Textarea } from 'new-ui';
import { getText } from '../../../../../i18n';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { ApproversType } from '../../../../bi/services/expenseReports/types';

import styles from './styles/usualApprove.module.css';

const LABELS = {
  COMMENT_FOR_APPROVER: getText('components:approveExpenseReportsDialog.commentForApprover'),
  SELECT_APPROVER: getText('components:approveExpenseReportsDialog.selectApprover'),
  NEEDED_COMMENT: getText('components:approveExpenseReportsDialog.neededComment'),
  CONTACT_WITH_ADMIN: getText('components:approveExpenseReportsDialog.contactWithAdmin'),
  NO_APPROVERS: getText('components:approveExpenseReportsDialog.noApprovers'),
  REPORT_WILL_BE_SENT: getText('components:approveExpenseReportsDialog.reportWillBeSent'),
  SEEN_NEEDED_COMMENT: getText('components:approveExpenseReportsDialog.seenNeededComment'),
  SEND_TO_APPROVE: getText('components:approveExpenseReportsDialog.sendToApprove'),
  SEND: getText('common:send'),
};

interface UsualApproveDialogProps {
  approvers: ApproversType[];
  show: false;
  loading: false;
  onClose(): void;
  onSubmit({}): void;
}

const UsualApproveDialog: FC<UsualApproveDialogProps> = ({
  approvers,
  show,
  loading,
  onClose,
  onSubmit,
}) => {
  const [chosenApprovers, setChosenApprovers] = useState<string[]>([]);
  const [comment, setComment] = useState('');

  const getApproverName = ({ Name, Email }: { Name: string, Email: string }) => {
    if (Name === Email) {
      return Email;
    }

    return (
      <div className={ styles.name }>
        <Text type='SEMIBOLD_16'>{ Name }</Text>
        &nbsp;
        {'—'}
        &nbsp;
        <Text>{ Email }</Text>
      </div>
    );
  };

  const handleChosenApprovers = (email: string) => {
    const index = chosenApprovers.indexOf(email);

    if (index !== -1) {
      chosenApprovers.splice(index, 1);

      return setChosenApprovers([...chosenApprovers]);
    }

    chosenApprovers.push(email);

    return setChosenApprovers([...chosenApprovers]);
  };

  const handleSubmitModel = () => {
    if (!chosenApprovers.length) handleChosenApprovers(approvers[0].Email);

    const model = {
      Users: chosenApprovers,
      Comment: comment,
    };

    onSubmit(model);
  };

  const renderApproversList = () => {
    const commentHtml = (
      <Textarea
        qaAttr={ QA_ATTRIBUTES.expenseReport.sendToApproveDialogs.usual.comment }
        value={ comment }
        onChange={ value => setComment(value) }
        placeholder={ LABELS.COMMENT_FOR_APPROVER }
      />
    );

    if (approvers.length === 1) {
      const approver = approvers[0].Name === approvers[0].Email ?
        approvers[0].Email :
        `${approvers[0].Email} - ${approvers[0].Name}.`;

      return (
        <>
          <Text type='NORMAL_16_140' className={ styles.row }>
            { LABELS.REPORT_WILL_BE_SENT }&nbsp;
            <Text type='SEMIBOLD_16' className={ styles.item }>{ approver }</Text>
          </Text>
          <Text type='NORMAL_16_140' className={ `${styles['comment-title']} ${styles.row}` }>
            { LABELS.SEEN_NEEDED_COMMENT }&nbsp;
            <Text type='SEMIBOLD_16' className={ styles.item }>{ approvers[0].Name }.</Text>
          </Text>
          { commentHtml }
        </>
      );
    }

    if (approvers.length > 1) {
      return (
        <>
          <Text className={ styles['list-title'] }>{ LABELS.SELECT_APPROVER }</Text>
          <div className={ styles['approvers-list'] }>
            { approvers.map(item => (
              <div key={ item.Email } className={ styles.item }>
                <Checkbox
                  qaAttr={ QA_ATTRIBUTES.expenseReport.sendToApproveDialogs.usual.add }
                  value={ chosenApprovers.includes(item.Email) }
                  onChange={ () => handleChosenApprovers(item.Email) }
                >
                  { getApproverName(item) }
                </Checkbox>
              </div>))
           }
          </div>
          <Text className={ styles['comment-title'] }>{ LABELS.NEEDED_COMMENT }</Text>
          { commentHtml }
        </>
      );
    }

    return (
      <>
        <Text type='NORMAL_16_140'>{ LABELS.NO_APPROVERS }</Text>
        <Text type='NORMAL_16_140'>{ LABELS.CONTACT_WITH_ADMIN }</Text>
      </>
    );
  };

  const disabledRules = (approvers.length > 1 && !chosenApprovers.length) || approvers.length === 0;

  return (
    <Dialog
      showClosing
      qaAttr={ QA_ATTRIBUTES.expenseReport.sendToApproveDialogs.usual.close }
      show={ show }
      onChange={ onClose }
    >
      <div className={ styles.wrapper }>
        <Text
          qaAttr={ QA_ATTRIBUTES.expenseReport.sendToApproveDialogs.usual.title }
          type='bold_20_130'
          className={ styles.header }
        >
          { LABELS.SEND_TO_APPROVE }
        </Text>
        { renderApproversList() }
        <div className={ styles.actions }>
          <Button
            qaAttr={ QA_ATTRIBUTES.expenseReport.sendToApproveDialogs.usual.send }
            type='secondary'
            loading={ loading }
            onClick={ handleSubmitModel }
            disabled={ disabledRules }
          >
            { LABELS.SEND }
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { UsualApproveDialog };
