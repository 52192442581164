import { getText } from '../../../../../i18n';

import CONFIG from '../../../../../config';

import ACTION from './action';

import { SERVICETYPE } from '../../../constants/serviceType';
import { FIELD_HOTEL_FORM_RADIO } from '../../../constants/hotel';
import { DATEFORMATS, PATTERN } from '../../../constants/dateFormats';
import { AEROEXPRESS_TARIFF_MAP } from '../../aeroexpress/const';
import COUNTRIES from '../../../constants/countries';

import createFromToAirTicket from '../../../utils/createFromToAirTicket';
import createNewDateString from '../../../utils/createNewDateString';
import Favicon from '../../../utils/favicon';
import { defaultPattern, defaultPatternWithTime, formatDate } from '../../../utils/formatDate';
import { OrderItem } from '../../../types/order';
import { ICurrentUser, IRequestReservationObj, IRequestReservationParams } from './types';

const APPID = CONFIG.INTERCOM.APPID;

declare global {
  interface Window {
    Intercom: {
      apply: (...args: unknown[]) => void;
      boot: () => void;
      update: () => void;
      (...args: unknown[]): void;
    };
  }
}

const LABELS = {
  TITLE: getText('services:chat.title'),
  CANCEL_ORDER: (abroad: string) => getText('services:chat.cancelOrder', { abroad }),
  EDIT_ORDER: (abroad: string) => getText('services:chat.editOrder', { abroad }),
  NEW_DATES: getText('services:chat.newDates'),
  AIR: getText('services:chat.air'),
  HOTEL: (
    name: string,
    city: string,
    oldDate: string,
    newDate: string,
    category: string,
    employee: string,
    guestCount: number,
    price: string,
  ) => getText('services:chat.hotel', { name, city, oldDate, newDate, category, employee, guestCount, price }),
  TRANSFER: getText('services:chat.transfer'),
  TRAIN: getText('services:chat.train'),
  PERIOD_FROM: getText('services:chat.periodFrom'),
  PERIOD_TO: getText('services:chat.periodTo'),
  ALL_TIME: getText('services:chat.allTime'),
  MESSAGE: getText('services:chat.message'),
  ABROAD: getText('services:chat.abroad'),
  AEROEXPRESS: '',
};

const windowInstance = window;

class Intercom {
  APPID = APPID;
  api: any;
  currentUser: ICurrentUser | null;
  title: string;
  store: any;
  windowState: string;

  constructor(api: any, store: any) {
    this.api = api;
    this.currentUser = null;
    this.store = store;

    this.title = document.title;
  }

  openSession(
    user: { email: string },
    { companyName, companyId }: { companyName: string, companyId: number },
    directoryName: string,
  ) {
    if (user.email) {
      this.closeSession();
      this.currentUser = user;

      if (windowInstance.Intercom) {
        window.Intercom('boot', {
          app_id: APPID,
          email: user.email,
          name: companyName,
          company: {
            id: companyId,
            name: companyName,
            plan: 'NEW DESIGN',
          },
        });

        windowInstance.Intercom('onUnreadCountChange', () => this.updateCountChange(directoryName));

        windowInstance.onblur = () => {
          if (this.windowState !== 'blur') {
            this.windowState = 'blur';
          }
        };
        windowInstance.onfocus = () => {
          if (this.windowState !== 'focus') {
            this.windowState = 'focus';
            this.updateCountChange(directoryName);
          }
        };
      }
    }
  }

  closeSession() {
    this.currentUser = null;
  }

  updateSession(user: { email: string, companyName: string }) {
    if (windowInstance.Intercom) {
      windowInstance.Intercom('update', {
        email: user.email,
        name: user.companyName,
      });
    }
  }

  sendRequestOffline = (data: IRequestReservationObj): Promise<void> | null => {
    if (CONFIG.INTERCOM.SWITCHON) {
      this.store.dispatch({ type: ACTION.STARTSENDINGMESSAGETOADMIN });

      return this.api.intercomChat.sendRequestOffline(data)
        .then(() => setTimeout(() => {
          this.showMessages();
        }, 1000))
        .finally(() => this.store.dispatch({ type: ACTION.ENDSENDINGMESSAGETOADMIN }));
    }

    return null;
  };

  sendTypeForAirline = (id: number) => this.api.intercomChat.sendType(id);

  sendMessageToAdmin = (
    user: ICurrentUser | null,
    msg: string,
    note = '',
    supressWidget: boolean = false,
  ) => {
    if (CONFIG.INTERCOM.SWITCHON) {
      const intercomMessageSenderUser = 1;
      this.store.dispatch({ type: ACTION.STARTSENDINGMESSAGETOADMIN });

      return this.api.chat.sendMessageToAdmin({
        note,
        Message: msg,
        Email: user?.email,
        MessageSender: intercomMessageSenderUser,
      })
        .then(() => setTimeout(() => {
          if (supressWidget) {
            return;
          }

          this.showMessages();
        }, 1000))
        .finally(() => this.store.dispatch({ type: ACTION.ENDSENDINGMESSAGETOADMIN }));
    }

    return null;
  };

  createMessageByOrder(order: OrderItem, newDateRange: any | null = null) {
    let mainText;
    const actualVersion = order.ActualVersion;
    const employee = actualVersion.Employees.map(em => `${em.Surname} ${em.Name}`).join(',');
    const data = JSON.parse(actualVersion.JsonData);

    const checkinDate = data.CustomCheckInDate
      ? formatDate(actualVersion.CheckinDate, PATTERN.DAY_MONTH_TIME_WITHOUT_SECONDS)
      : formatDate(actualVersion.CheckinDate, DATEFORMATS.DAY_MONTH);

    const checkoutDate = data.CustomCheckOutDate
      ? formatDate(actualVersion.CheckoutDate, PATTERN.DATE_TIME_WITHOUT_SECONDS)
      : formatDate(actualVersion.CheckoutDate, DATEFORMATS.DATE);

    const oldDateRange = `${checkinDate}-${checkoutDate}`;

    switch (actualVersion.ServiceType) {
      case SERVICETYPE.AIR: {
        const newDate = newDateRange ? `\n${LABELS.NEW_DATES}: ${createNewDateString(newDateRange)}` : '';
        const ticketNum = data.TicketsExtended && data.TicketsExtended.length ? data.TicketsExtended[0].Num : '';
        const fromTo = createFromToAirTicket(data);
        mainText = `${LABELS.AIR} ${employee}, ${ticketNum}, ${oldDateRange}, ${fromTo} ${newDate}`;
        break;
      }
      case SERVICETYPE.HOTEL: {
        const newDate = newDateRange
          ? `\n${LABELS.NEW_DATES}: ${createNewDateString(newDateRange, false)}`
          : '';
        mainText = LABELS.HOTEL(
          data.Hotel.Name,
          actualVersion.City,
          oldDateRange,
          newDate,
          data.Room.Category,
          employee,
          data.GuestCount,
          data.PriceDetails && data.PriceDetails.Total,
        );
        break;
      }
      case SERVICETYPE.TRANSFER: {
        mainText = `${LABELS.TRANSFER} ${actualVersion.City} ${employee}, ${oldDateRange}`;
        break;
      }
      case SERVICETYPE.TRAIN: {
        mainText = `${LABELS.TRAIN} ${data.StationDepart} - ${data.StationArrive} ${employee}, ${oldDateRange}`;
        break;
      }
      case SERVICETYPE.AEROEXPRESS: {
        const { Trip: { TariffType } } = data;
        mainText = `${LABELS.AEROEXPRESS} (${AEROEXPRESS_TARIFF_MAP[TariffType]}) ${employee}, ${oldDateRange}`;
        break;
      }
      case SERVICETYPE.CUSTOM: {
        const newDate = newDateRange ? `\n${LABELS.NEW_DATES}: ${createNewDateString(newDateRange)}` : '';
        mainText = `${data.Name} ${employee}, ${oldDateRange} ${newDate}`;
        break;
      }
    }

    return mainText;
  }

  createNoteByOrder = ({ ActualVersion: { JsonData, ServiceType } }: OrderItem) => {
    if (ServiceType === SERVICETYPE.HOTEL) {
      const { ProviderName, ReservationNumber } = JSON.parse(JsonData);

      return `\n${ProviderName} ${ReservationNumber}`;
    }

    return '';
  };

  requestOnChangeByOrder(order: OrderItem, text: string = '') {
    let abroad = '';

    if (order.ActualVersion.ServiceType === SERVICETYPE.HOTEL) {
      const data = JSON.parse(order.ActualVersion.JsonData);
      const { CountryCode } = data.Hotel;
      abroad = CountryCode && CountryCode !== COUNTRIES.RU.SHORTNAME
        ? LABELS.ABROAD
        : '';
    }

    const mainText = this.createMessageByOrder(order);
    const note = this.createNoteByOrder(order);
    const msg = mainText ? `${LABELS.EDIT_ORDER(abroad)} ${order.Id}:\n${mainText}\n\n${text}` : null;

    if (msg) {
      return this.sendMessageToAdmin(this.currentUser, msg, note);
    }

    return Promise.reject();
  }

  requestCancelByOrder(order: OrderItem): Promise<void> {
    let abroad = '';

    if (order.ActualVersion.ServiceType === SERVICETYPE.HOTEL) {
      const data = JSON.parse(order.ActualVersion.JsonData);
      const { CountryCode } = data.Hotel;
      abroad = CountryCode && CountryCode !== COUNTRIES.RU.SHORTNAME
        ? LABELS.ABROAD
        : '';
    }

    const mainText = this.createMessageByOrder(order);
    const msg = mainText ? `${LABELS.CANCEL_ORDER(abroad)} ${order.Id}:\n${mainText}` : null;

    if (msg) {
      return this.sendMessageToAdmin(this.currentUser, msg);
    }

    return Promise.reject();
  }

  requestFlightCertificate(tripItemId: number) {
    if (CONFIG.INTERCOM.SWITCHON) {
      this.store.dispatch({ type: ACTION.STARTSENDINGMESSAGETOADMIN });

      return this.api.intercomChat.sendMessageByFlightCertificate(tripItemId)
        .then(() => setTimeout(() => {
          this.showMessages();
        }, 1000))
        .finally(() => this.store.dispatch({ type: ACTION.ENDSENDINGMESSAGETOADMIN }));
    }

    return null;
  }

  requestAdditionalService(description: string) {
    return this.sendMessageToAdmin(this.currentUser, description);
  }

  requestError(errorMsg: string) {
    if (errorMsg) {
      this.sendMessageToAdmin(this.currentUser, errorMsg);
    }
  }

  requestReservation({
    hotel,
    room: { Price, Name, IsContract },
    checkin,
    checkout,
    adult,
    bedType,
    customCheckin,
    customCheckout,
    travellersCount,
    roomCount,
    breakfast = '',
    freeCancellation = '',
    comment,
    arrayEmployees,
  }: IRequestReservationParams) {
    const checkinDate = customCheckin
      ? formatDate(customCheckin, defaultPatternWithTime)
      : formatDate(checkin, defaultPattern);
    const checkoutDate = customCheckout
      ? formatDate(customCheckout, defaultPatternWithTime)
      : formatDate(checkout, defaultPattern);
    const totalPrice = Price?.TotalPrice ?? '';
    const hasBreakfast = breakfast === FIELD_HOTEL_FORM_RADIO.INCLUDES;
    const hasFreeCancellation = freeCancellation === FIELD_HOTEL_FORM_RADIO.INCLUDES;
    const defaultBedType = 0;
    const newBedType = travellersCount === 2 && adult === 2 ? bedType : defaultBedType;

    const request: IRequestReservationObj = {
      classificator: hotel.ClassificatorId,
      hotelname: hotel.Name,
      hoteladdress: hotel.Address,
      roomname: Name,
      roomcount: roomCount,
      person: travellersCount,
      persontype: adult,
      checkin: checkinDate,
      checkout: checkoutDate,
      price: totalPrice,
      isContract: IsContract,
      hasBreakfast,
      hasFreeCancellation,
      comment,
      bedType: newBedType,
      employees: arrayEmployees,
    };

    return this.sendRequestOffline(request);
  }

  hasDisabledUser(user: string): boolean {
    return CONFIG.INTERCOM.DISABLEDUSER.includes(user);
  }

  createFakeIntercomObj() {
    // @ts-ignore
    windowInstance.Intercom = (action: string) => {
      if (action === 'show' || action === 'hide') {
        this.store.dispatch({
          type: ACTION.UPDATECHATSTATE,
          payload: action,
        });
      }
    };
  }

  // clearIntercomObj() {
  //   windowInstance.Intercom = null;
  // }

  updateCountChange = (directoryName: string) => {
    let action = 'remove';

    if (this.windowState === 'blur') {
      action = 'set';
    }

    this.updateFavicon(action, directoryName);
  };

  updateFavicon = (action = 'set', directoryName: string) => {
    if (action === 'set') {
      Favicon.setDot(directoryName);

      document.title = LABELS.TITLE;
    } else {
      Favicon.removeDot();
      document.title = this.title;
    }
  };

  showMessages = () => {
    if (windowInstance.Intercom) {
      windowInstance.Intercom('showMessages');
    }
  };

  show = () => {
    if (windowInstance.Intercom) {
      windowInstance.Intercom('show');
    }
  };

  hide = () => {
    if (windowInstance.Intercom) {
      windowInstance.Intercom('hide');
    }
  };

  // sendRequestTrips = (start, end) => {
  //   let period = LABELS.ALL_TIME;
  //
  //   if (start && end) {
  //     period = `${LABELS.PERIOD_FROM} ${start} ${LABELS.PERIOD_TO} ${end}`;
  //   }
  //
  //   if (start && !end) {
  //     period = start;
  //   }
  //
  //   const msg = `${LABELS.MESSAGE} ${period}`;
  //
  //   return this.sendMessageToAdmin(this.currentUser, msg);
  // };
}

export default Intercom;
