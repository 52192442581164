import React, { RefObject, useEffect, useRef } from 'react';
import { Suggest, IconButton, Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import { AddButton } from '../../../../components/AddButton';
import { RouteTicketItem } from '../RouteTicketItem';
import { FormWrapper } from '../../../../components/FormWrapper';

import type { TravelPolicyRoute } from '../../../../bi/services/travelPolicy/types';
import { RouteSegment, SuggestItem } from '../RouteTicketItem/types';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import {
  TRAVEL_POLICY_DEFAULT_ROUTE,
  TRAVEL_POLICY_FIELDS_DICTIONARIES,
} from '../../../../bi/services/travelPolicy/consts';

import styles from './index.module.css';

const LABELS = {
  FROM: getText('settings:travelPolicy.rules.routes.from'),
  TO: getText('settings:travelPolicy.rules.routes.to'),
  AND_BACK: getText('settings:travelPolicy.rules.routes.back'),
  CITY: getText('settings:travelPolicy.rules.routes.city'),
  COUNTRY: getText('settings:travelPolicy.rules.routes.country'),
  ADD_ROUTE: getText('settings:travelPolicy.rules.routes.add'),
  CHECK_THE_FIELD: getText('settings:travelPolicy.rules.routes.checkField'),
};

type RoutesListProps = {
  list: TravelPolicyRoute[],
  type: string,
  exceptionType?: string | number,
  showValidation?: boolean,
  show: boolean,
  suggestions: any,
  onChange: (p: any) => void,
  onAutocompleteChange: (p: any) => void,
};

const getSuggestText = (type: string, suggest: any) => ({
  [SERVICETYPE.AIR]: () => {
    const { City, Name, Code } = suggest;

    const text = `${City} (${Name} - ${Code})`;

    return <Text>{text}</Text>;
  },
  [SERVICETYPE.TRAIN]: () => {
    const { Hint, Name } = suggest;

    const hint = Hint && (
      <Text
        type='NORMAL_14'
        color='gray'
        className={ styles.hint }
      >
        { Hint }
      </Text>
    );

    return <Text>{ Name } { hint }</Text>;
  },
  [SERVICETYPE.HOTEL]: () => {
    const { Name, FullName } = suggest;

    const fullName = FullName && `, ${FullName}`;

    return <Text>{ Name }{ fullName }</Text>;
  },
})[type]();

const getSuggestModel = (type: string, suggest: SuggestItem): RouteSegment => ({
  [SERVICETYPE.AIR]: () => {
    const { Code, City } = suggest;

    return { Code: String(Code), Name: `${City}` };
  },
  [SERVICETYPE.TRAIN]: () => {
    const { Code, Name } = suggest;

    return { Code: String(Code), Name };
  },
  [SERVICETYPE.HOTEL]: () => {
    const { Id, Name, Code } = suggest;

    const code = Id || Code;

    return { Code: String(code), Name };
  },
})[type]();

const prepareSuggestList = (list: any, showSuggests?: boolean) => (showSuggests ? list : []);

const RoutesList = ({
  list,
  type,
  exceptionType = '',
  showValidation,
  show,
  suggestions,
  onChange,
  onAutocompleteChange,
}: RoutesListProps) => {
  const firstEmptyFromField = useRef(null);

  const { results: suggestionRes, loading: suggestionLoading } = suggestions;

  useEffect(() => {
    if (firstEmptyFromField?.current && show) {
      // @ts-ignore
      setTimeout(() => firstEmptyFromField.current.focus(), 250);
    }
  }, [show, exceptionType]);

  const handleOnAddNewRoute = () => {
    firstEmptyFromField.current = null;

    onChange([...list, { ...TRAVEL_POLICY_DEFAULT_ROUTE }]);
  };

  const handleRemoveRoute = (ind: number) => onChange([
    ...list.slice(0, ind),
    ...list.slice(ind + 1),
  ]);

  const handleOnChangeField = (payload: any, ind: number) => {
    const newList = [...list];
    newList[ind] = payload;

    onChange(newList);
  };

  const handleOnFieldRender = (ref: any | null, value: string) => {
    if (!value && ref && !firstEmptyFromField.current) {
      firstEmptyFromField.current = ref;
      ref.focus();
    }
  };

  const renderSuggest = (s: any) => getSuggestText(type, s);

  const renderHotelItem = (i: TravelPolicyRoute, onChangeRoute: (p: any) => void, onAutocomplete: (p: any, exceptionType: string | number | undefined) => void) => {
    const {
      isValid,
      To,
      To: {
        Name,
        Code,
        showSuggests,
      },
    } = i;

    const label = exceptionType && exceptionType === TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.COUNTRIES
      ? LABELS.COUNTRY
      : LABELS.CITY;

    const errorText = showValidation && !isValid ? LABELS.CHECK_THE_FIELD : '';

    return (
      <Suggest
        className={ styles.suggest }
        isFetching={ suggestionLoading }
        inputClassName={ styles.input }
        theme='border'
        placeholder={ label }
        value={ Name }
        error={ errorText }
        onChange={ n => {
          onChangeRoute({ ...i, To: { ...To, Name: n, isSelected: false, showSuggests: true } });
          onAutocomplete(n, exceptionType);
        } }
        onSelect={ v =>
          v && onChangeRoute({ ...i, To: { ...getSuggestModel(type, v), isSelected: true, showSuggests: false } })
        }
        items={ prepareSuggestList(suggestionRes, showSuggests) }
        renderItem={ renderSuggest }
        ref={ ref => handleOnFieldRender(ref, Code) }
      />
    );
  };

  const renderTicketItem = (i: TravelPolicyRoute, onChangeRoute: () => void, onAutocomplete: () => void) => (
    <RouteTicketItem
      value={ i }
      type={ type }
      suggestions={ suggestions }
      showValidation={ showValidation }
      renderSuggest={ renderSuggest }
      prepareSuggestList={ prepareSuggestList }
      onChange={ onChangeRoute }
      onAutocomplete={ onAutocomplete }
      getSuggestModel={ getSuggestModel }
      firstFieldRef={ (ref: RefObject<HTMLInputElement>) => handleOnFieldRender(ref, i.From.Code) }
    />
  );

  const renderItem = (i: TravelPolicyRoute, ind: number) => {
    const removeBtn = list.length > 1 && (
      <IconButton
        className={ styles.icon }
        iconType='closeButton'
        onClick={ () => handleRemoveRoute(ind) }
      />
    );
    const renderFn = type === SERVICETYPE.HOTEL ? renderHotelItem : renderTicketItem;

    const preparedOnChange = (payload?: any) => handleOnChangeField(payload, ind);
    const preparedOnAutocomplete = (q?: string) => onAutocompleteChange({ query: q, type, exceptionType });

    return (
      <div className={ styles.item } key={ ind }>
        <div>
          { renderFn(i, preparedOnChange, preparedOnAutocomplete) }
        </div>
        { removeBtn }
      </div>
    );
  };

  const content = list.map(renderItem);

  return show && (
    <FormWrapper>
      { content }
      <AddButton
        className={ styles.button }
        text={ LABELS.ADD_ROUTE }
        onClick={ handleOnAddNewRoute }
      />
    </FormWrapper>
  );
};

export { RoutesList };
