import React, { Component } from 'react';

import { SearchHistory } from '../../../../components/SearchHistory';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import HotelsService from '../../../../bi/services/hotels';

import { HistoryObj } from '../../../../bi/services/hotels/types';

import { HotelsHistoryItem } from '../HotelsHistoryItem';

interface HotelsHistoryProps {
  hotelService: HotelsService,
  onClick(item: HistoryObj): void,
}

interface HotelsHistoryState {
  items: HistoryObj[],
}

class HotelsHistory extends Component<HotelsHistoryProps, HotelsHistoryState> {
  unsubscribeFn: () => void;

  state = {
    items: [],
  };

  componentDidMount() {
    const { hotelService } = this.props;

    this.unsubscribeFn = hotelService.subscribeHistory(this.updateHistory);
    hotelService.getHistory();
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateHistory = (items: HistoryObj[]) => {
    this.setState({
      items: items.map(item => ({
        ...item,
        GuestsCount: item.GuestsCount || 1,
        TravellersCount: item.TravellersCount || 1,
        RoomCount: item.RoomCount || 1,
      })),
    });
  };

  render() {
    const { items } = this.state;
    const { onClick } = this.props;

    if (!items.length) {
      return null;
    }

    return (
      <SearchHistory
        items={ items }
        onClick={ onClick }
        renderItem={ (item: HistoryObj) => <HotelsHistoryItem item={ item } /> }
        qaAttrLabel={ QA_ATTRIBUTES.hotels.search.youSearch }
        qaAttrWrapper={ QA_ATTRIBUTES.hotels.search.fromHistory.wrapper }
      />
    );
  }
}

export { HotelsHistory };
