import React, { useState } from 'react';
import { LinkButton, DotLoading } from 'new-ui';
import { clsx } from 'clsx';

import { getText } from '../../../../../../../i18n';

import styles from './index.module.css';

const LABELS = {
  DOWNLOAD_ORIGINAL: getText('trip:header.downloadOriginal'),
};

interface IVoucherLinkProps {
  className: string;
  onDownload(): Promise<void>;
}

const VoucherLink = ({
  className,
  onDownload,
}: IVoucherLinkProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const classNames = clsx([styles.wrapper], {
    [className]: className,
  });

  const handleDownload = () => {
    setLoading(true);
    onDownload().finally(() => setLoading(false));
  };

  const loadingContent = (
    <DotLoading color='white' />
  );

  const buttonContent = (
    <LinkButton
      theme='white'
      onClick={ handleDownload }
    >
      { LABELS.DOWNLOAD_ORIGINAL }
    </LinkButton>
  );

  const html = loading ? loadingContent : buttonContent;

  return (
    <div className={ classNames }>
      { html }
    </div>
  );
};

export { VoucherLink };
