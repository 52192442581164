import React, { useState } from 'react';
import { Button, Datepicker, Select, Text } from 'new-ui';
import { Moment } from 'moment/moment';
import { getText } from '../../../../../../i18n';

import createDateFromTo, { ICreateDateFromToObj } from '../../../../../bi/utils/createDateFromTo';
import { getMoment, isSameOrAfter } from '../../../../../bi/utils/formatDate';

import { generateTravelers } from '../../../../../bi/constants/travelers';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import styles from './style.module.css';

const LABELS = {
  TITLE: getText('favorites:dialogs.airline.title'),
  START_SEARCH: getText('favorites:dialogs.airline.startSearch'),
  CANCEL: getText('common:undo'),
  THERE: getText('favorites:dialogs.airline.there'),
  BACK: getText('favorites:dialogs.airline.back'),
  TRAVELLERS: getText('favorites:dialogs.airline.travellers'),
};

const FIELDS = {
  FROM: 'dateFrom',
  TO: 'dateTo',
};

interface IQaAttrs {
  wrapper: string;
  header: string;
  date: string;
  passengers: string;
  buttons: {
    startSearch: string;
    cancel: string;
  },
}

const DEFAULT_QA_ATTRS = {
  wrapper: '',
  header: '',
  date: '',
  passengers: '',
  buttons: {
    startSearch: '',
    cancel: '',
  },
};

const TRAVELERS = generateTravelers(9);

const createState = (): IStateObj => {
  const currentDate = getMoment();
  const {
    start,
    end,
    minStart,
  }: ICreateDateFromToObj = createDateFromTo(currentDate) as ICreateDateFromToObj;

  return {
    dateFrom: start,
    dateTo: end,
    minFrom: minStart,
    travelers: TRAVELERS[0].value,
  };
};

interface IStateObj {
  dateFrom: Moment;
  dateTo: Moment;
  minFrom: Moment;
  travelers: number;
}

interface IAirlineSearchStartDialogProps {
  isBothSide?: boolean;
  onSearch(item: { dateFrom: Moment, dateTo: Moment, travelers: number, withNumber: boolean }): void;
  onClose(): void;
  qaAttrs: IQaAttrs;
}

const AirlineSearchStartDialog = ({
  isBothSide,
  onSearch,
  onClose,
  qaAttrs = DEFAULT_QA_ATTRS,
}: IAirlineSearchStartDialogProps) => {
  const [stateObj, setStateObj] = useState<IStateObj>({ ...createState() });
  const { dateFrom, dateTo, minFrom, travelers } = stateObj;

  const handleChangeState = (data: Partial<IStateObj>) => {
    setStateObj({
      ...stateObj,
      ...data,
    });
  };

  const handleChangeDate = (field: string, value: Moment) => {
    if (field === FIELDS.FROM) {
      const { end, minStart } = createDateFromTo(value) as ICreateDateFromToObj;

      const dateBack = isSameOrAfter(value, dateTo)
        ? end
        : dateTo;

      return handleChangeState({
        dateFrom: value,
        dateTo: dateBack,
        minFrom: minStart,
      });
    }

    return handleChangeState({ [field]: value });
  };

  const backDateHtml = isBothSide && (
  <Datepicker
    placeholder={ LABELS.BACK }
    inputClassName={ styles.input }
    wrapperClassName={ styles.back }
    value={ dateTo }
    min={ dateFrom }
    onChange={ (value) => handleChangeDate(FIELDS.TO, value as Moment) }
  />
  );

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_20' qaAttr={ qaAttrs.header }>
        { LABELS.TITLE }
      </Text>
      <div className={ styles.dates }>
        <Datepicker
          placeholder={ LABELS.THERE }
          inputClassName={ styles.input }
          wrapperClassName={ styles.there }
          value={ dateFrom }
          min={ minFrom }
          onChange={ (value) => handleChangeDate(FIELDS.FROM, value as Moment) }
          qaAttr={ qaAttrs.date }
          qaAttrNextMonth={ QA_ATTRIBUTES.favorites.chooseDateDialog.checkInNextMonth }
        />
        { backDateHtml }
      </div>
      <Select
        withLabel
        theme='default-border'
        className={ styles.select }
        value={ travelers }
        placeholder={ LABELS.TRAVELLERS }
        items={ TRAVELERS }
        onChange={ (value) => handleChangeState({ travelers: value as number }) }
        qaAttr={ qaAttrs.passengers }
      />
      <div className={ styles.action }>
        <Button
          onClick={ () => onSearch({ dateFrom, dateTo, travelers, withNumber: true }) }
          qaAttr={ qaAttrs.buttons.startSearch }
        >
          { LABELS.START_SEARCH }
        </Button>
        <Button
          className={ styles.cancel }
          type='textual'
          onClick={ onClose }
          qaAttr={ qaAttrs.buttons.cancel }
        >
          { LABELS.CANCEL }
        </Button>
      </div>
    </div>
  );
};

export { AirlineSearchStartDialog };
