import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { BreadCrumbs, PageLoader } from 'new-ui';

import { getText } from '../../../i18n';

import getAccountId from '../../bi/utils/getAccountId';
import { contentCrumbsChangeAdditional } from '../../bi/utils/airlineAdditionalServices';
import { getAllAirIds, getAllFlightCertificateIds } from '../../bi/utils/airline';
import { getFreeFlightCertificate } from '../../bi/utils/accountSettings';

import { PATH } from '../../bi/constants/airlineAdditionalServices';

import { AdditionalServicesHeader } from '../../components/AdditionalServicesHeader';
import { SelectAdditionalService } from './components/SelectAdditionalService';

import { AirlineAdditionalServicesProps, IAllFlightCertificateStatus, IDetails } from '../../bi/types/airlineAdditionalServices';

import styles from './index.module.css';

const LABELS = {
  LOADER: getText('components:additionalServices.loader'),
};

const AirlineAdditionalServices: FC<AirlineAdditionalServicesProps> = observer(({
  breadCrumbsService,
  orderService,
  workspaceService,
  accountSettingsService: {
    getAccountSettings,
    getCompanySettings,
    store: {
      accountSettings: {
        accessFlightCertificates,
      },
    },
  },
  settingsService,
  match,
  store: { currentPath },
  travelPolicyService: { getTravelPolicyForEmployeeById },
  match: {
    params: { tripId, ticketId },
    path,
  },
  history,
  children,
}) => {
  const [detailsData, setDetailsData] = useState<IDetails | null>(null);
  const [allFlightCertificateStatusData, setAllFlightCertificateStatusData] = useState<IAllFlightCertificateStatus>({});

  const isLoading = !detailsData || Object.keys(allFlightCertificateStatusData).length === 0;

  const loadOrderServiceInfo = async () => {
    orderService.start();
    await orderService.load(tripId);

    const { OrderItems } = orderService.get().details;

    const allFlightCertificateIds = getAllFlightCertificateIds(OrderItems);

    const companiesSettings = getCompanySettings();
    const isFreeFlightCertificate = getFreeFlightCertificate(companiesSettings, OrderItems[0].ActualVersion.CompanyId);

    const allAirIds = getAllAirIds(OrderItems);

    await orderService.getAllStatusesFlightCertificate([...allAirIds, ...allFlightCertificateIds], isFreeFlightCertificate);
  };

  const updateState = ({ details, allFlightCertificateStatus }: { details: IDetails, allFlightCertificateStatus: IAllFlightCertificateStatus }) => {
    setDetailsData(details);
    setAllFlightCertificateStatusData(allFlightCertificateStatus);
  };

  useEffect(() => {
    breadCrumbsService.setCurrentPath(path);
    const { details, allFlightCertificateStatus } = orderService.get();

    if (!details) {
      loadOrderServiceInfo();
    }

    setDetailsData(details);
    setAllFlightCertificateStatusData(allFlightCertificateStatus);

    settingsService.getCompaniesWithCostCenter(getAccountId());

    const unsubscribeFn = orderService.subscribeCart(updateState);

    return () => {
      unsubscribeFn();
    };
  }, []);

  const renderHeader = () => (
    <>
      <BreadCrumbs
        className={ styles.bread_crumbs }
        content={ contentCrumbsChangeAdditional(tripId, ticketId, currentPath) }
      />
      <AdditionalServicesHeader details={ detailsData } ticketId={ ticketId }/>
    </>
  );

  const renderSelect = () => (currentPath === PATH.MAIN ? (
    <SelectAdditionalService
      history={ history }
      match={ match }
      workspaceService={ workspaceService }
      accountSettings={ getAccountSettings() }
      companiesSettings={ getCompanySettings() }
      accessFlightCertificates={ accessFlightCertificates }
      setChangeTypeCrumbs={ breadCrumbsService.setCurrentPath }
      details={ detailsData }
      flightCertificateStatus={ allFlightCertificateStatusData[ticketId] || '' }
      getTravelPolicyForEmployeeById={ getTravelPolicyForEmployeeById }
      ticketId={ ticketId }
    />
  ) : null);

  if (isLoading) {
    return <PageLoader text={ LABELS.LOADER } />;
  }

  return (
    <>
      { renderHeader() }
      { renderSelect() }
      { children }
    </>
  );
});

export { AirlineAdditionalServices };
