import React, { Component } from 'react';
import { Location, History } from 'history';
import * as queryString from 'query-string';
import { Text, PageLoader } from 'new-ui';
import clsx from 'clsx';

import { getText } from '../../../i18n';

import { HotelSearchMenu } from '../../components/Menu/HotelSearchMenu';
import { WarningMessage } from '../../components/WarningMessage';
import { HotelsHistory } from './components/HotelsHistory';
import { DateInSearch } from '../../components/DateInSerch';
import { BackToTripLink } from '../../components/BackToTripLink';
import { WarningBlock } from '../../components/WarningBlock';

import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { PATTERN } from '../../bi/constants/dateFormats';
import { MainAnalytic } from '../../bi/utils/analytics';
import { formatDate, momentObject } from '../../bi/utils/formatDate';
import { isSmartAgent } from '../../bi/utils/env';

import { SELECTTRAVELLERS } from '../../bi/constants/hotelsSearch';
import { HISTORY_ACTIONS } from '../../bi/constants/history';
import { ANALYTIC_SERVICE_TYPES, SMART_AGENT_SERVICE_TYPE } from '../../bi/constants/serviceType';

import HotelsService from '../../bi/services/hotels';
import TravelApprovalService from '../../bi/services/travelApproval';
import FeatureFlags from '../../bi/services/featureFlags';

import { HistoryObj, ISearchStore } from '../../bi/services/hotels/types';
import Smartdesk from '../../bi/services/smartdesk';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('search:hotel.title'),
  ORDER_FOR_A_TRIP: getText('components:trip.orderForATrip'),
  BANNER_TEXT: {
    TEXT1: getText('search:hotel.bannerText.text1'),
    TEXT2: getText('search:hotel.bannerText.text2'),
    TEXT3: getText('search:hotel.bannerText.text3'),
    TEXT4: getText('search:hotel.bannerText.text4'),
    TEXT5: getText('search:hotel.bannerText.text5'),
  },
  BANNER_TEXT_FOREIGN_HOTELS: {
    TEXT1: getText('search:hotel.bannerTextForeignHotels.text1'),
    TEXT2: getText('search:hotel.bannerTextForeignHotels.text2'),
  },
};

interface HotelsSearchPageProps {
  history: History,
  location: Location,
  hotelsService: HotelsService,
  travelApprovalService: TravelApprovalService,
  featureFlagsService: FeatureFlags,
  aggregationId: number | null,
  smartdeskService: Smartdesk,
  isShowTitle?: boolean,
}

interface HotelsSearchPageState {
  isTravelApproval: boolean,
  schemeLoading: boolean,
  tripId: string | string[],
  tripDate: string | string[],
  tripName: string | string[],
}

class HotelsSearchPage extends Component<HotelsSearchPageProps, HotelsSearchPageState> {
  unsubscribeFn: () => void;

  static defaultProps = {
    isShowTitle: true,
  };

  constructor(props: HotelsSearchPageProps) {
    super(props);

    const { hotelsService, location } = props;
    const { isTravelApproval, schemeLoading } = hotelsService.getSearchState();
    const parsedQuery = queryString.parse(location.search);
    const { tripId, tripName, tripDate } = parsedQuery;

    this.state = {
      isTravelApproval,
      schemeLoading,
      tripId: tripId || '',
      tripName: tripName || '',
      tripDate: tripDate || '',
    };
  }

  componentDidUpdate({ location: { key: prevKey } }: HotelsSearchPageProps) {
    const { location: { key }, hotelsService, aggregationId, location } = this.props;
    const { tripId, tripDate, tripName } = this.state;

    const searchParams = queryString.parse(location.search);
    const searchTripId = searchParams.tripId || '';
    const searchTripDate = searchParams.tripDate || '';
    const searchTripName = searchParams.tripName || '';

    if (prevKey !== key) {
      hotelsService.setNewSearch(!!aggregationId);
    }

    if (searchTripId !== tripId || searchTripDate !== tripDate || searchTripName !== tripName) {
      this.setState({
        tripId: searchTripId || '',
        tripDate: searchTripDate || '',
        tripName: searchTripName || '',
      });
    }
  }

  componentDidMount() {
    const {
      history: { action },
      hotelsService,
      aggregationId,
    } = this.props;
    const { isTravelApproval, schemeLoading } = this.state;

    if (isSmartAgent) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.MAIN_SEARCH_OPENED, { SAservice: SMART_AGENT_SERVICE_TYPE.HOTEL });
    }

    this.unsubscribeFn = hotelsService.subscribeSearch(this.updateState);

    hotelsService.getTemplateWarning();

    if (action === HISTORY_ACTIONS.POP) {
      return;
    }

    const { isSuggested, is_request } = queryString.parse(this.props.location.search);

    if (!isSuggested && !isTravelApproval && !schemeLoading && !is_request) {
      hotelsService.setNewSearch(!!aggregationId);
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SEARCH.SEARCH_HOTEL_SCREEN_OPENED);
  }

  componentWillUnmount() {
    const { aggregationId, hotelsService, travelApprovalService } = this.props;
    hotelsService.resetSearchStore(!!aggregationId);
    travelApprovalService.setResetTravelApprovalsForCartStore();
    this.unsubscribeFn();
  }

  updateState = ({ isTravelApproval, schemeLoading }: ISearchStore) => this.setState({ isTravelApproval, schemeLoading });

  search = () => {
    const {
      smartdeskService: {
        setSearchSmartdesk,
      },
      featureFlagsService: {
        getShowElementForSmartagent,
      },
    } = this.props;

    setSearchSmartdesk(true);

    if (getShowElementForSmartagent() && isSmartAgent) {
      MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.MAINSEARCH_SEARCH_PRESSED('searchHotel'),
      );
    }

    if (this.props.hotelsService.isRegion()) {
      this.goToRegionSearch(this.props.hotelsService.mapStateToSearchObject());
    } else {
      this.handleDetail();
    }

    setSearchSmartdesk(false);
  };

  goToRegionSearch = (searchObj: { [key: string]: unknown }) => {
    this.props.history.push({
      pathname: ROUTES.SEARCH.HOTELS_REGION,
      search: queryString.stringify({ ...searchObj, tripId: this.state.tripId }),
    });
  };

  goToHotelSearch = (hotelId: number, query: { [key: string]: unknown }) => {
    this.props.history.push({
      pathname: ROUTES.SEARCH.HOTEL_FAVORITE(hotelId),
      search: queryString.stringify({ ...query, tripId: this.state.tripId }),
    });
  };

  handleSearchByHistoryItem = (item: HistoryObj) => {
    const { hotelsService } = this.props;

    const preparedCheckin = item.CheckInTime ? momentObject(item.CheckInTime) : null;
    const preparedCheckout = item.CheckOutTime ? momentObject(item.CheckOutTime) : null;

    if (item.TravellersCount > 1) {
      MainAnalytic.send(
        MainAnalytic.CATEGORY.HOTELS,
        MainAnalytic.ACTIONS.HOTELS.MULTIPLEROOMSSEARCH,
        {
          label: SELECTTRAVELLERS[item.TravellersCount as keyof typeof SELECTTRAVELLERS].find(({ value }) => value === item.GuestsCount)?.label || '',
        },
      );
    }

    if (item.IsRegion) {
      MainAnalytic.send(
        MainAnalytic.CATEGORY.HOTELS,
        MainAnalytic.ACTIONS.HOTELS.SEARCHBYREGIONFROMHISTORY,
        {
          label: item.Name,
          value: item.GuestsCount,
        },
      );

      const searchObj = hotelsService.getSearchObject(
        {
          RegionName: item.Name,
          RegionId: item.Id,
          Name: '',
          IsForeign: false,
        },
        {
          dateFrom: momentObject(item.CheckinDate),
          dateTo: momentObject(item.CheckoutDate),
          travellersCount: item.TravellersCount,
          placingType: item.GuestsCount,
          timeFrom: preparedCheckin,
          timeTo: preparedCheckout,
        },
      );

      this.goToRegionSearch(searchObj);
    } else {
      this.goToHotelSearch(item.Id, {
        CheckinDate: formatDate(item.CheckinDate, PATTERN.YEARMONTHDAY),
        CheckoutDate: formatDate(item.CheckoutDate, PATTERN.YEARMONTHDAY),
        GuestsCount: item.GuestsCount,
        TravellersCount: item.TravellersCount,
        Label: item.Name,
        FromHistory: true,
        ...hotelsService.prepareCustomTime(preparedCheckin, preparedCheckout),
      });

      MainAnalytic.send(
        MainAnalytic.CATEGORY.HOTELS,
        MainAnalytic.ACTIONS.HOTELS.SEARCHBYNAMEFROMHISTORY,
        {
          label: item.Name,
          value: item.GuestsCount,
        },
      );
    }
  };

  handleDetail = () => {
    const { id } = this.props.hotelsService.getParamForHotelDetail();

    const query = this.props.hotelsService.mapStateToHotelPageSearchSettings();
    this.goToHotelSearch(id, query);
  };

  handleSearch = () => {
    const { hotelsService } = this.props;

    if (hotelsService.searchFormIsValid()) {
      this.search();
    }

    const searchSettings = hotelsService.getSearchState();

    if (searchSettings.travellersCount > 1) {
      MainAnalytic.send(
        MainAnalytic.CATEGORY.HOTELS,
        MainAnalytic.ACTIONS.HOTELS.MULTIPLEROOMSSEARCH,
        {
          label: SELECTTRAVELLERS[searchSettings.travellersCount].find(({ value }) => value === searchSettings.adult)?.label || '',
        },
      );
    }

    const action = hotelsService.isRegion()
      ? MainAnalytic.ACTIONS.HOTELS.SEARCHBYREGION
      : MainAnalytic.ACTIONS.HOTELS.SEARCHBYNAME;
    MainAnalytic.send(MainAnalytic.CATEGORY.HOTELS, action,
      { label: searchSettings.region.label, value: searchSettings.adult });

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_FIND_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.HOTEL),
    );
  };

  renderWarningText = () => {
    const { hotelsService } = this.props;
    const { textTemplateWarning } = hotelsService.getSearchState();

    if (!textTemplateWarning) return null;

    return <WarningBlock text={ textTemplateWarning } type='SEMIBOLD_16' color='red' />;
  };

  renderWarningMessages = this.props.featureFlagsService.getTripRuleCustomMessage() ? <WarningMessage /> : null;

  renderTitle = (title: string) => {
    const { featureFlagsService: { getShowElementForSmartagent }, isShowTitle } = this.props;

    if (isSmartAgent && getShowElementForSmartagent() && !isShowTitle) {
      return null;
    }

    return (
      <Text
        type='bold_32'
        className={ styles.title }
        qaAttr={ QA_ATTRIBUTES.hotels.search.title }
      >
        { title }
      </Text>
    );
  };

  renderHotelHistory = () => {
    const {
      featureFlagsService: {
        getShowElementForSmartagent,
      },
      hotelsService,
      isShowTitle,
    } = this.props;

    if (isSmartAgent && getShowElementForSmartagent() && !isShowTitle) {
      return null;
    }

    return (
      <HotelsHistory
        hotelService={ hotelsService }
        onClick={ this.handleSearchByHistoryItem }
      />
    );
  };

  render() {
    const { schemeLoading, tripId, tripName, tripDate } = this.state;
    const {
      featureFlagsService,
      featureFlagsService: {
        getShowElementForSmartagent,
      },
      isShowTitle,
    } = this.props;

    if (schemeLoading) {
      return <PageLoader />;
    }

    const title = tripId ? LABELS.ORDER_FOR_A_TRIP : LABELS.TITLE;
    const addingTriipDate = tripId ? <DateInSearch name={ tripName } date={ tripDate }/> : null;

    const wrapperSearch = clsx(
      styles.search,
      isSmartAgent && getShowElementForSmartagent() && !isShowTitle && styles.search_sa,
    );

    return (
      <div className={ styles.wrapper }>
        <BackToTripLink tripId={ tripId } />
        { this.renderTitle(title) }
        { addingTriipDate }
        <div className={ wrapperSearch }>
          <HotelSearchMenu
            hotelsService={ this.props.hotelsService }
            onSearch={ this.handleSearch }
            onSearchSubMenu={ () => {} }
            onChangeForm={ this.handleSearch }
            featureFlagsService={ featureFlagsService }
          />
          { this.renderWarningText() }
        </div>
        { this.renderWarningMessages }
        { this.renderHotelHistory() }
      </div>
    );
  }
}

export default HotelsSearchPage;
