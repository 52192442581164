import { BreadCrumbsStore, BreadCrumbsStoreType } from './store';

class BreadCrumbs {
  store: BreadCrumbsStoreType;

  constructor() {
    this.store = BreadCrumbsStore;
  }

  setCurrentPath = (path: string) => this.store.setCurrentPath(path);
}

export { BreadCrumbs };
