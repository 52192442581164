// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Suggest, PROPS, Text, IconButton } from 'new-ui';

import { getText } from '../../../../../i18n';

import { FieldLabel } from '../../../../components/FieldLabel';

import { HOTEL_FORM_REQUEST } from '../../../../bi/constants/hotel';

import styles from './index.module.css';

const LABELS = {
  CITY: getText('components:travelApproval.city'),
  INTERMEDIATE_CITY: getText('approvalScheme:intermediateCity'),
  ADD_INTERMEDIATE_CITY: getText('components:travelApproval.addIntermediateCity'),
};

const {
  SUGGEST: { THEME: { BORDER } },
  TEXT: { TYPES: { NORMAL_12 }, COLORS: { GRAY } },
  ICON: { TYPES: { PLUS: { ROUND }, CLOSE_BUTTONS: { DEFAULT } } },
  ICON_BUTTON: { THEMES: { DEFAULT_RED } },
} = PROPS;

const SelectCity = ({
  travelApproval,
  region,
  onSuggestSelected,
  onGetSuggests,
  onDeleteSelected,
  disabledField,
  suggestRef,
  onAddIntermediateCity,
  onDeleteIntermediateCity,
  isRequired,
  qaAttrCity,
}) => {
  const handleSuggestSelected = (suggest, index) => {
    if (suggest !== null) {
      onSuggestSelected(suggest, index);
    }
  };

  const handleDeleteSelected = (suggest, index) => {
    onDeleteSelected(suggest, index);
  };

  const handleClose = (suggest, index, suggests) => {
    if (!suggests.length) {
      handleDeleteSelected(suggest, index);
    } else {
      handleSuggestSelected({
        FullName: '',
        Name: '',
        Id: null,
      }, index);
    }
  };

  const renderSuggestionItem = ({ Name, FullName }) => {
    const hintHtml = FullName && (
      <Text
        type={ NORMAL_12 }
        className={ styles.hint }
        color={ GRAY }
      >
        { FullName }
      </Text>
    );

    return (
      <>
        <Text>{ Name }</Text>
        { hintHtml }
      </>
    );
  };

  const renderDeleteIntermediateCityIcon = (Id, index) => {
    if (index === 0 || disabledField) return null;

    return (
      <IconButton
        iconType={ DEFAULT }
        className={ styles.button }
        theme={ DEFAULT_RED }
        onClick={ () => onDeleteIntermediateCity(index) }
      />
    );
  };

  const renderAddIntermediateCityIcon = (index) => {
    if (disabledField) return null;

    if (travelApproval.Destinations && travelApproval.Destinations.length - 1 !== index) return null;

    return (
      <div className={ styles.add_city }>
        <IconButton
          iconType={ ROUND }
          theme={ DEFAULT_RED }
          onClick={ onAddIntermediateCity }
        >
          { LABELS.ADD_INTERMEDIATE_CITY }
        </IconButton>
      </div>
    );
  };

  const renderCity = ({ Name, Id }, i) => {
    const filterSuggests = region.suggests.filter(it => it.title === HOTEL_FORM_REQUEST.REGIONS);
    const suggests = filterSuggests.length > 0 ? filterSuggests[0].items : filterSuggests;
    const name = Name.split(',')[0];
    const inputStyles = [styles.input];
    const label = i === 0 ? LABELS.CITY : `${LABELS.INTERMEDIATE_CITY} ${i}`;

    if (disabledField) {
      inputStyles.push(styles.input_disabled);
    }

    return (
      <div className={ styles.flex_column }>
        <div className={ `${styles.item} ${styles.suggest}` }>
          <FieldLabel text={ label } mustHave={ isRequired } />
          <div className={ styles.flex_row }>
            <Suggest
              theme={ BORDER }
              ref={ suggestRef }
              withLabel={ false }
              shouldFocusItem={ () => false }
              value={ name }
              items={ suggests }
              onSelect={ suggest => handleSuggestSelected(suggest, i) }
              onClose={ suggest => handleClose(suggest, i, suggests) }
              onChange={ query => onGetSuggests(query, i) }
              renderItem={ item => renderSuggestionItem(item) }
              inputClassName={ inputStyles.join(' ') }
              contentClassName={ styles.input_content }
              disabled={ disabledField }
              qaAttr={ qaAttrCity?.input || '' }
              qaAttrFirstEl={ qaAttrCity?.suggestFirst || '' }
            />
            {renderDeleteIntermediateCityIcon(Id, i)}
          </div>
        </div>
        {renderAddIntermediateCityIcon(i)}
      </div>
    );
  };

  return travelApproval.Destinations.map((item, i) => renderCity(item, i));
};

SelectCity.propTypes = {
  travelApproval: PropTypes.object.isRequired,
  region: PropTypes.object.isRequired,
  suggestRef: PropTypes.object.isRequired,
  disabledField: PropTypes.bool.isRequired,
  onSuggestSelected: PropTypes.func.isRequired,
  onDeleteSelected: PropTypes.func.isRequired,
  onGetSuggests: PropTypes.func.isRequired,
  onAddIntermediateCity: PropTypes.func.isRequired,
  onDeleteIntermediateCity: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired,
  qaAttrCity: PropTypes.object,
};

export { SelectCity };
