import { SearchMenuItemTypes } from 'new-ui';

import { getText } from '../../../i18n';

import { SEARCH_SERVICE_TYPE, SERVICESSEARCHID } from '../constants/serviceType';
import ROUTES from '../constants/routes';

interface SearchType {
  type: SearchMenuItemTypes;
  title: string;
  url: string;
  id: string;
}

const LABELS = {
  AIR: getText('components:emptyPanel.add.air'),
  TRAIN: getText('components:emptyPanel.add.train'),
  HOTEL: getText('components:emptyPanel.add.hotel'),
  AEROEXPRESS: getText('components:emptyPanel.add.aeroexpress'),
  TAXI: getText('components:emptyPanel.add.taxi'),
  TRANSFER: getText('components:emptyPanel.add.transfer'),
};

const SEARCHES: SearchType[] = [
  {
    type: 'searchAirline',
    title: LABELS.AIR,
    url: ROUTES.SEARCH.AIR,
    id: SERVICESSEARCHID.AIR,
  },
  {
    type: 'searchTrain',
    title: LABELS.TRAIN,
    url: ROUTES.SEARCH.TRAIN,
    id: SERVICESSEARCHID.TRAIN,
  },
  {
    type: 'searchHotel',
    title: LABELS.HOTEL,
    url: ROUTES.SEARCH.HOTEL,
    id: SERVICESSEARCHID.HOTEL,
  },
  {
    type: 'searchTaxi',
    title: LABELS.TAXI,
    url: ROUTES.SEARCH.TAXI,
    id: SERVICESSEARCHID.TAXI,
  },
  {
    type: 'searchTransfer',
    title: LABELS.TRANSFER,
    url: ROUTES.SEARCH.TRANSFER,
    id: SERVICESSEARCHID.TRANSFER,
  },
];

const SEARCH_AEROEXPRESS: SearchType = {
  type: 'searchAeroexpress',
  title: LABELS.AEROEXPRESS,
  url: ROUTES.SEARCH.AEROEXPRESS,
  id: SERVICESSEARCHID.AEROEXPRESS,
};

const getDataForEmptyPanel = (searchServiceType: SearchServiceType[], noBookingTaxi: boolean) => {
  const items = searchServiceType && searchServiceType.length ?
    SEARCHES.filter(({ type }) => searchServiceType.includes(SEARCH_SERVICE_TYPE[type]))
    : [...SEARCHES];

  return noBookingTaxi && searchServiceType?.some((el) => SEARCH_SERVICE_TYPE[SEARCH_AEROEXPRESS.type] === el) ?
    [...items, SEARCH_AEROEXPRESS]
    : items;
};

export type SearchServiceType = 'Air' | 'Train' | 'Hotel' | 'AeroExpress' | 'Transfer' | ' VipHall' | 'Conference' | 'GroupCheckin' | 'Event' | 'Taxi';

export { getDataForEmptyPanel };
