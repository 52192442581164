import { dateNowWithOffset } from '../../utils/formatDate';
import parseJsonString from '../../utils/parseJsonString';

import { PLACE_TYPES, STAT_AUTOCOMPLETE_EVENTS, STAT_HOTEL_EVENTS } from './constants/stats';
import { SERVICETYPE } from '../../constants/serviceType';
import { HOTELFILTERTYPE } from '../../constants/tagsFilterType';
import { PrepareAutocompleteStatsType, StatsArray } from './types';

const prepareAutocompleteStats = (query: string, suggests: any[], type: string, selected: any | null = null): PrepareAutocompleteStatsType => {
  const actionType = type === STAT_AUTOCOMPLETE_EVENTS.SHOW.TYPE
    ? STAT_AUTOCOMPLETE_EVENTS.SHOW.VALUE
    : STAT_AUTOCOMPLETE_EVENTS.CLICK.VALUE;

  const result = [];
  const timeNow = dateNowWithOffset();

  if (type === STAT_AUTOCOMPLETE_EVENTS.CLICK.TYPE && selected && selected.Name) {
    const onePartSuggests = suggests.find((pack) => {
      if (selected.IsRegion) {
        return pack.title === PLACE_TYPES.REGION.TYPE;
      }

      return pack.title !== PLACE_TYPES.REGION.TYPE;
    });

    const indexInSuggests = onePartSuggests.items.findIndex((suggestItem: any) => suggestItem.Id === selected.Id);

    const placeType = selected.IsRegion
      ? PLACE_TYPES.REGION.VALUE
      : PLACE_TYPES.HOTEL.VALUE;

    result.push({
      Request: query,
      Result: selected.Name,
      Type: placeType,
      Position: indexInSuggests + 1,
      Action: actionType,
      Date: timeNow,
    });
  }

  if (type === STAT_AUTOCOMPLETE_EVENTS.SHOW.TYPE && Array.isArray(suggests) && suggests.length > 0) {
    suggests.forEach((pack) => {
      pack.items.forEach((suggestItem: any, index: number) => {
        const placeType = pack.title === PLACE_TYPES.HOTEL.TYPE
          ? PLACE_TYPES.HOTEL.VALUE
          : PLACE_TYPES.REGION.VALUE;

        result.push({
          Request: query,
          Result: suggestItem.Name,
          Type: placeType,
          Position: index + 1,
          Action: actionType,
          Date: timeNow,
        });
      });
    });
  }

  if (type === STAT_AUTOCOMPLETE_EVENTS.EMPTY.TYPE && !suggests) {
    result.push({
      Request: query,
      Result: '<Пусто>',
      Type: '',
      Position: 0,
      Action: '',
      Date: timeNow,
    });
  }

  // @ts-ignore
  return result;
};

const defineStatParamByRooms = (roomGroups: any) => {
  if (roomGroups.length === 0) {
    return STAT_HOTEL_EVENTS.EMPTYRESULT.PARAMS.NOTFOUND;
  }

  // @ts-ignore
  if (roomGroups.length > 0 && roomGroups.every(({ Rates }) => Rates.every(({ Offline }) => Offline))) {
    return STAT_HOTEL_EVENTS.EMPTYRESULT.PARAMS.WITHOUTONLINECONFIRM;
  }

  // @ts-ignore
  if (roomGroups.length > 0 && roomGroups.some(({ Rates }) => Rates.some(({ Offline }) => Offline))) {
    return STAT_HOTEL_EVENTS.EMPTYRESULT.PARAMS.HASOFFLINEROOM;
  }

  return null;
};

const getNamesOfflineRooms = (roomGroups: any[], type: string) => {
  const offlineRooms: string[] = [];

  if (type === STAT_HOTEL_EVENTS.EMPTYRESULT.PARAMS.WITHOUTONLINECONFIRM) {
    roomGroups.forEach(({ Name }) => offlineRooms.push(Name));
  }

  if (type === STAT_HOTEL_EVENTS.EMPTYRESULT.PARAMS.HASOFFLINEROOM) {
    // @ts-ignore
    roomGroups.forEach(({ Name, Rates }) => Rates.forEach(({ Offline }) => {
      if (Offline) {
        offlineRooms.push(Name);
      }
    }));
  }

  return offlineRooms;
};

const prepareHotelStats = (type: string, data: any, { searchState }: any): any => {
  switch (type) {
    case STAT_HOTEL_EVENTS.SEARCH.VALUE: {
      const { guid, request, checkin, checkout, customCheckin, customCheckout } = data;

      const formattedCheckin = customCheckin ? customCheckin.format() : checkin.format();
      const formattedCheckout = customCheckout ? customCheckout.format() : checkout.format();

      return {
        type,
        data: {
          searchId: guid,
          searchRequest: request,
          checkin: formattedCheckin,
          checkout: formattedCheckout,
        },
      };
    }

    case STAT_HOTEL_EVENTS.OPENHOTEL.VALUE: {
      const { searchId, item, ind, filters } = data;

      let countSelectedCheckboxesInFilter = 0;
      Object.keys(filters).forEach((filterItem) => {
        if ((filterItem === HOTELFILTERTYPE.SMARTHOTEL ||
          filterItem === HOTELFILTERTYPE.HASCANCELLATION ||
          filterItem === HOTELFILTERTYPE.FAVORITES ||
          filterItem === HOTELFILTERTYPE.VAT) && filters[filterItem]) {
          countSelectedCheckboxesInFilter += 1;
        }

        if (filterItem === HOTELFILTERTYPE.AMENITIES) {
          Object.keys(filters[filterItem]).forEach((value) => {
            if (filters[filterItem][value]) countSelectedCheckboxesInFilter += 1;
          });
        }

        if (filterItem === HOTELFILTERTYPE.TYPE || filterItem === HOTELFILTERTYPE.STARS) {
          Object.keys(filters[filterItem]).forEach((value) => {
            if (filters[filterItem][value].selected) countSelectedCheckboxesInFilter += 1;
          });
        }
      });

      return {
        type,
        data: {
          searchId,
          hotelId: item.ClassificatorId,
          hotelName: item.Name,
          searchPosition: ind + 1,
          valueHotelNameFilter: filters.hotelName,
          countSelectedCheckboxesInFilter,
        },
      };
    }

    case STAT_HOTEL_EVENTS.REGIONEMPTYRESULT.VALUE: {
      const { regionId, regionName, checkin, checkout, customCheckin, customCheckout } = data;
      const { guid } = searchState;

      const formattedCheckin = customCheckin ? customCheckin.format() : checkin.format();
      const formattedCheckout = customCheckout ? customCheckout.format() : checkout.format();

      return {
        type,
        data: {
          searchId: guid,
          regionId,
          regionName,
          startDate: formattedCheckin,
          endDate: formattedCheckout,
        },
      };
    }

    case STAT_HOTEL_EVENTS.ADDTOCART.TYPES.FROMHOTEL: {
      const { hotel: { Name, ClassificatorId }, rate: { CancellationPolicy, Meal, Price, RateId } } = data;
      const { guid } = searchState;

      return {
        type: STAT_HOTEL_EVENTS.ADDTOCART.VALUE,
        data: {
          searchId: guid,
          hotelName: Name,
          hotelId: ClassificatorId,
          hasCancellation: CancellationPolicy.Refundable,
          hasMeal: Meal.Included,
          price: Price.TotalPrice,
          bookId: RateId,
        },
      };
    }

    case STAT_HOTEL_EVENTS.ADDTOCART.TYPES.FROMNOTE: {
      const { hotel: { Name, ClassificatorId }, Rate: { Amenities, Price, RateId, Id } } = data;

      return {
        type: STAT_HOTEL_EVENTS.ADDTOCART.VALUE,
        data: {
          rateId: Id,
          hotelName: Name,
          hotelId: ClassificatorId,
          hasCancellation: Amenities.HasCancellation,
          hasMeal: Amenities.HasMeal,
          price: Price.TotalPrice,
          bookId: RateId,
        },
      };
    }

    case STAT_HOTEL_EVENTS.BOOKING.VALUE: {
      const { sources } = data;

      const statsArray: StatsArray[] = [];

      // @ts-ignore
      sources.forEach((source) => {
        if (source.ServiceType !== SERVICETYPE.HOTEL) return;

        const dataSource = parseJsonString(source.Data);

        if (!dataSource) return;

        const { Rate: { Id, Price, RateId }, hotel: { Name, ClassificatorId }, room: { Amenities } } = dataSource;
        statsArray.push({
          type,
          data: {
            rateId: Id,
            hotelName: Name,
            hotelId: ClassificatorId,
            hasCancellation: !!Amenities.CancellationInfo,
            hasMeal: Amenities.HasMeal,
            price: Price.TotalPrice,
            bookId: RateId,
          },
        });
      });

      return statsArray;
    }

    case STAT_HOTEL_EVENTS.EMPTYRESULT.TYPES.SHOWPAGE: {
      const { hotel, guid, hotelId, resRates, resHotel } = data;

      if (!(resRates && hotel && hotel.Name) && !(resHotel && hotel && hotel.RoomGroups && hotel.RoomGroups.length > 0)) break;

      const { checkin, checkout, customCheckin, customCheckout } = searchState;
      const startDate = customCheckin ? customCheckin.format() : checkin.format();
      const endDate = customCheckout ? customCheckout.format() : checkout.format();

      const roomGroups = resRates ? resRates.RoomGroups : hotel.RoomGroups;
      const hotelName = resHotel ? resHotel.Name : hotel.Name;
      const region = resHotel ? resHotel.RegionName : hotel.RegionName;
      const hotelIdInt = parseInt(hotelId, 10);

      const resultType = defineStatParamByRooms(roomGroups);

      if (!resultType) break;

      return {
        type: STAT_HOTEL_EVENTS.EMPTYRESULT.VALUE,
        data: {
          searchId: guid,
          hotelName,
          hotelId: hotelIdInt,
          actionType: type,
          resultType,
          region,
          startDate,
          endDate,
          rooms: getNamesOfflineRooms(roomGroups, resultType),
        },
      };
    }

    case STAT_HOTEL_EVENTS.EMPTYRESULT.TYPES.SENDREQUEST: {
      const { hotel: { RoomGroups, SearchId, Name: hotelName, ClassificatorId, RegionName } } = data;

      const resultType = defineStatParamByRooms(RoomGroups);

      if (!resultType) break;

      const { checkin, checkout, customCheckin, customCheckout } = searchState;

      const startDate = customCheckin ? customCheckin.format() : checkin.format();
      const endDate = customCheckout ? customCheckout.format() : checkout.format();

      return {
        type,
        data: {
          searchId: SearchId,
          hotelName,
          hotelId: ClassificatorId,
          actionType: type,
          resultType,
          region: RegionName,
          startDate,
          endDate,
          rooms: getNamesOfflineRooms(RoomGroups, resultType),
        },
      };
    }
  }

  return null;
};

export { prepareAutocompleteStats, prepareHotelStats };
