import ACTION from '../action';
import Store from '../../../store/index';

import { HotelStatic } from '../types';

const createNewState = () => ({
  hotels: [],
  loading: true,
});

interface NewStore {
  hotels: HotelStatic[],
  loading: boolean,
}

const reducer = (action: { [key: string]: any, type: string }, state: NewStore) => {
  switch (action.type) {
    case ACTION.LOADINGSTATICHOTEL: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case ACTION.ADDSTATICHOTEL: {
      return {
        ...state,
        hotels: [
          ...state.hotels,
          action.payload,
        ],
      };
    }

    case ACTION.REMOVESTATICHOTEL: {
      const indexToRemove = state.hotels.findIndex(hotel => hotel.ClassificatorId === action.payload);

      if (indexToRemove === -1) return state;

      const clonedHotels = [...state.hotels];
      clonedHotels.splice(indexToRemove, 1);

      return {
        ...state,
        hotels: clonedHotels,
      };
    }

    case ACTION.ADDTOFAVORITESTATICHOTEL: {
      const { classificatorId, favoriteId } = action.payload;

      const hotelsChangedfavorites = state.hotels.map((hotel) => {
        if (hotel.ClassificatorId === classificatorId) {
          return { ...hotel, FavoriteId: favoriteId };
        }

        return hotel;
      });

      return {
        ...state,
        hotels: hotelsChangedfavorites,
      };
    }

    case ACTION.REMOVEFROMFAVORITESTATICHOTEL: {
      const hotelsChangedfavorites = state.hotels.map((hotel) => {
        if (hotel.ClassificatorId === action.payload) {
          return { ...hotel, FavoriteId: null };
        }

        return hotel;
      });

      return {
        ...state,
        hotels: hotelsChangedfavorites,
      };
    }
  }

  return state;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
