import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import { STRUCTURE_TYPE } from '../../../../../../bi/constants/chartsAnalytics';

import scrollToTop from '../../../../../utils/scrollToTop';

import { AirlineNestedTable } from '../../../common/airlineNestedTable';
import { TrainNestedTable } from '../../../common/trainNestedTable';
import { HotelNestedTable } from '../../../common/hotelNestedTable';
import { OtherNestedTable } from '../../../common/otherNestedTable';
import { TaxiVoucherTable } from '../../../taxi/tableForVoucher';
import { BusNestedTable } from '../../../common/busNestedTable';

interface DepartmentsDoubleNestedTableProp {
  chartsAnalyticsService: ChartsAnalytics
}

const DepartmentsDoubleNestedTable = observer(({ chartsAnalyticsService }: DepartmentsDoubleNestedTableProp) => {
  const {
    chartsAnalyticsSummaryStore,
    chartsAnalyticsStore: { filtersType, filters: { employees, departments, projects, analytics, tags } },
  } = useStores([MOBX_STORES.CHARTS_ANALYTICS_SUMMARY_STORE, MOBX_STORES.CHARTS_ANALYTICS_STORE]);

  const { type } = chartsAnalyticsSummaryStore;
  const { getStructureDepartmentsDoubleSubPage, setShowFilterDepartment } = chartsAnalyticsService;

  useEffect(() => {
    getStructureDepartmentsDoubleSubPage();

    return () => setShowFilterDepartment(false);
  }, [
    getStructureDepartmentsDoubleSubPage,
    setShowFilterDepartment,
    filtersType,
    employees.selected,
    departments.selected,
    projects.selected,
    analytics.selected,
    tags.selected,
  ]);

  const handlePageChange = (page: number) => chartsAnalyticsService.changePageStructureDepartments(page).then(() => scrollToTop());

  const airlineTable = (
    <AirlineNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  const trainTable = (
    <TrainNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  const hotelTable = (
    <HotelNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  const aeroexpressTable = (
    <TrainNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  const taxiTable = (
    <TaxiVoucherTable
      typeStore={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  const busTable = (
    <BusNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  const otherTable = (
    <OtherNestedTable
      store={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
    />
  );

  return ({
    [STRUCTURE_TYPE.AIR]: airlineTable,
    [STRUCTURE_TYPE.TRAIN]: trainTable,
    [STRUCTURE_TYPE.HOTEL]: hotelTable,
    [STRUCTURE_TYPE.TAXI_OPERATION]: taxiTable,
    [STRUCTURE_TYPE.AEROEXPRESS]: aeroexpressTable,
    [STRUCTURE_TYPE.TRANSFER]: trainTable,
    [STRUCTURE_TYPE.BUS]: busTable,
    [STRUCTURE_TYPE.OTHER]: otherTable,
  }[type]);
});

export { DepartmentsDoubleNestedTable };
