import React, { memo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Text, Dialog, PageLoader } from 'new-ui';

import { getText } from '../../../i18n';

import { useServices } from '../../bi/context/services';

import { StatusCondition } from '../../components/StatusCondition';
import { CommentsDialog } from './components/CommentsDialog';
import { Content } from './components/Content';

import styles from './styles/index.module.css';

export const LABELS = {
  HEADER: getText('approve:approvingTrips'),
  TOOLTIP_TEXT: getText('approve:tooltip'),
  DOWNLOAD: getText('approve:download'),
  CLOSE: getText('approve:aggressiveClose'),
  NO_LIST: getText('approve:noResults'),
  LOADER: getText('approve:loader'),
  NO_APPROVING: getText('approve:noApproving'),
  NAME_ERROR: {
    NO_DATA: getText('approve:errors.noData'),
    ERROR: getText('approve:errors.error'),
  },
};

const Loader = memo(() => <PageLoader text={ LABELS.LOADER } />);

export const ApprovePage = observer(() => {
  const {
    cartService,
    approveService,
    departmentsService,
    reportService,
  } = useServices(['Approve', 'Cart', 'Departments', 'Report']);

  const {
    approveReportStore: { errorDialog },
    setErrorReportDialog,
  } = reportService;

  const errorMessage = errorDialog.msg;

  const loading = approveService.networkStore.getStatus('approveList');

  useEffect(() => {
    cartService.start();
    cartService.getWaitingApproveList();
    approveService.getApproveList();
    departmentsService.loadListForApprove();
  }, []);

  const errorContent = errorMessage === LABELS.NAME_ERROR.NO_DATA
    ? (
      <>
        <Text type='bold_20' className={ styles.dialogHeader }>{ errorMessage }</Text>
        <Text>{ LABELS.NO_APPROVING }</Text>
      </>
    ) : (<Text type='bold_20'>{ errorMessage }</Text>);

  return (
    <div className={ styles.wrapper }>
      <StatusCondition
        status={ loading }
        onLoading={ Loader }
        onLoaded={ Content }
        fallback={ Loader }
      />
      <Dialog
        show={ errorDialog.show }
        showClosing
        onChange={ (show) => setErrorReportDialog(show, '') }
      >
        <div>
          { errorContent }
        </div>
      </Dialog>
      <CommentsDialog />
    </div>
  );
});
