import { UUID } from 'node:crypto';
import { parse } from 'query-string';

import { api } from '../../apiV2';
import { apiNext } from '../../apiV2/apiNext';

import { enhancedSignatureStore } from './store';

import NetworkStatesStore from '../utils/network/networkStatesStore';
import { bound, withIM } from '../utils/dectrators';
import { history } from '../../../../utils/history';

import { TYPE_IMAGE } from '../../constants/signature';

import { EnhancedSignatureModalTypes, GetDocumentsQueryType } from './types';

export enum LoadingFields {
  loadEnhancedDocList,
  sendToEmail,
  sendToGov,
  downloadReport,
  checkGovIntegration,
  govOauthCall,
  static,
  employeeAutocomplete,
  callDocumentPreview,
  downloadDocument,
}

export class Signature {
  netStore = new NetworkStatesStore<LoadingFields>({ contextRecord: true });

  enhancedSignatureStore = enhancedSignatureStore;

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.loadEnhancedDocList))
  async loadEnhancedDocList() {
    const signList = await apiNext.documentAggregator.v1.documents.get(
      { query: this.enhancedSignatureStore.listInputParams },
    );
    this.enhancedSignatureStore.setSignListData(signList);
  }

  updateFilter = <
    K extends keyof GetDocumentsQueryType,
    V extends GetDocumentsQueryType[K],
  >(key: K, value: V) => {
    this.enhancedSignatureStore.setInputParameter(key, value);

    this.loadEnhancedDocList();
  };

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.sendToEmail))
  async sendToEmail(email: string) {
    const docId = this.enhancedSignatureStore.sendDocId;

    if (docId) {
      await api.signature.sendToEmail(
        email,
        docId,
      );
    }
  }

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.sendToGov))
  async sendToGov() {
    await api.signature.sendToGov(
      this.enhancedSignatureStore.selectedIds,
    );
  }

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.downloadReport))
  async downloadReport() {
    const employeeId = this.enhancedSignatureStore.listInputParams.employeeId;

    if (employeeId) {
      await api.signature.downloadReport(
        employeeId,
      );
    }
  }

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.checkGovIntegration))
  async checkGovIntegration() {
    const isEnabled = await apiNext.esiaService.v1.integrated.get();
    this.enhancedSignatureStore.setIntegrationEnabled(isEnabled);
  }

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.govOauthCall))
  async govOauthCall() {
    window.location.href = await apiNext.esiaService.v1.authLink.get(
      { query: { redirectLink: window.location.href } },
    );
  }

  clearQuery = () => {
    history.push(window.location.pathname);
  };

  @bound
  @withIM((o) => o.netStore.withLoaderFlow(LoadingFields.govOauthCall, o.clearQuery))
  async govOauthAutoSendCode() {
    const authorizationCode = parse(location.search).code as string | undefined;

    if (authorizationCode) {
      const isEnabled = await apiNext.esiaService.v1.integration.post(
        { data: { authorizationCode } },
      );

      this.enhancedSignatureStore.setIntegrationEnabled(isEnabled);
      this.clearQuery();
    }
  }

  @bound
  @withIM(o => o.netStore.withAbortRequestAxios(LoadingFields.employeeAutocomplete))
  @withIM(o => o.netStore.withErrorBoundary(LoadingFields.employeeAutocomplete))
  async employeeAutocomplete(query: string) {
    const autocomplete = (
      await api
        .employee
        .autocomplete(
          query.trim(), this.netStore.getAbortTokenAxios(LoadingFields.employeeAutocomplete),
        )
    ).Employees;

    return this.enhancedSignatureStore.setAutocompleteEmployeeList(autocomplete);
  }

  srcString = (image: string) => TYPE_IMAGE.SVG + image;

  @bound
  @withIM(o => o.netStore.withLoaderFlow(LoadingFields.callDocumentPreview))
  async callDocumentPreview(docId: UUID) {
    this.enhancedSignatureStore.openModal(EnhancedSignatureModalTypes.docPreview);

    const docs = await api.signature.downloadDocPreview(docId);
    this.enhancedSignatureStore.setPreviewDocs(docs.map(this.srcString));
  }

  @bound
  @withIM(o => o.netStore.withLoaderFlow(LoadingFields.callDocumentPreview))
  callDocumentPreviewIsntruction() {
    this.enhancedSignatureStore.openModal(EnhancedSignatureModalTypes.docPreview);

    this.enhancedSignatureStore.setPreviewDocs([
      '/images/goskeyGuide/1.png',
      '/images/goskeyGuide/2.png',
      '/images/goskeyGuide/3.png',
      '/images/goskeyGuide/4.png',
    ]);
  }

  @bound
  @withIM(o => o.netStore.withLoaderFlowCoroutine(LoadingFields.downloadDocument))
  async downloadDocument(docId: UUID) {
    this.enhancedSignatureStore.switchDownloadDocID(docId);
    await api.signature.downloadDoc(docId);
    this.enhancedSignatureStore.switchDownloadDocID(docId);
  }
}
