import Store from '../../../store/index';

import ACTION from '../action';

import { SEARCH_MENU_TYPES, VIEW_MODE_TYPES } from '../constants/searchMenuTypes';
import {
  NEW_SEARCH_SORT_TYPES,
  SEARCH_TYPES, SORT_TYPES,
  DEFAULT_ANALYTIC_NAME,
  MEAL_FIELDS,
} from '../../../constants/hotel';
import { BUYTRIPSACCOUNTRIGHT, BUYTRIPSPERSONALRIGHT } from '../../../constants/rights';
import { HOTELFILTERTYPE } from '../../../constants/tagsFilterType';
import { TRAVELPOLICYFILTER, TravelPolicyFilter } from '../../../constants/travelPolicy';

import {
  analyticByFilter,
  changeFilter,
  createNewSearchTags,
  createTags,
  getDiffPercentOnStartSearch,
  getHotelsMealByCategory,
  getNewSourcesWithFavorites,
  getPagingView,
  hasChangeFilter,
  hasChangeObjFilter,
  hasChangePriceFilter,
  mergeVariants,
  sortBy,
  sortByNewSearch,
  sortByVisibleHotelOnMap,
  uncheckFilter,
  updateDistanceFromCenterOrPoint,
  updateFiltersParams,
  updatePriceFilter,
} from '../../../utils/hotels';

import {
  Amenities,
  ChangedFilters,
  Filters,
  IHotelsStore,
  Variant,
} from '../types';
import { YesNoType } from '../../../types/hotel';

const createNewState = (isWhiteLabel = false): IHotelsStore => ({
  sources: [],
  cache: [],
  items: [],
  itemsMap: [],
  tags: [],
  regionLocation: {
    latitude: 0,
    longitude: 0,
    radius: 0,
  },
  coordsPoint: [],
  abortSearchByRadius: false,
  viewMode: VIEW_MODE_TYPES.LIST_MAP,
  travelPolicyAllList: [],
  setCacheOnMapToggler: false,
  lastHotelsSearch: SEARCH_TYPES.REGION,
  isRefund: false,

  menuType: SEARCH_MENU_TYPES.DEFAULT,
  loadComplete: false,
  loading: false,
  loadingRegion: false,

  progress: 0,
  searchStartTime: Date.now(),

  paging: {
    total: 0,
    current: 1,
    count: 15,
  },
  rightsBuyTrip: {},
  accountTravelPolicy: {},
  sortBy: SORT_TYPES.SMART_HOTEL,
  changeSortBy: false,
  hasOneSmart: false,
  hasOneGuaranteed: false,
  isWhiteLabel,
  isAnyFavorite: false,
  unavailableTravelPolicy: false,
  favorite: null,
  filters: {
    price: {
      border: {
        from: 0,
        to: 0,
      },
      roundBy: 0,
      from: 0,
      to: 0,
    },
    smartHotel: false,
    priceGuaranteed: false,
    hotelName: '',
    hasCancellation: false,
    favoriteId: false,
    stars: {},
    type: {},
    network: {},
    travelPolicyList: [],
    hasVat: false,
    selectedTravelPolicy: TravelPolicyFilter.NotApplied,
    amenities: {
      Fitness: false,
      Internet: false,
      Meal: false,
      Parking: false,
      Pool: false,
      Spa: false,
    },
    online: true,
    breakfast: false,
    radius: {
      value: 0,
      custom: false,
    },
    rating: {
      8.5: false,
      7: false,
      6: false,
    },
    mapIsFilter: {
      value: false,
      visibleHotels: [],
    },
    changed: {
      price: false,
      smartHotel: false,
      priceGuaranteed: false,
      hotelName: false,
      stars: false,
      type: false,
      amenities: false,
      network: false,
      hasCancellation: false,
      favoriteId: false,
      rating: false,
      hasVat: false,
      online: false,
      breakfast: false,
      radius: false,
      mapIsFilter: false,
      viewMode: false,
    },
  },
  initDoubleRadiusSearch: false,
  initRadiusSearchHotels: false,
  displayFilters: null,
  radiusCustom: false,
  hotelsFilters: {
    SortType: NEW_SEARCH_SORT_TYPES.RECOMMEND,
    Rate: {
      PriceFrom: 0,
      PriceTo: 0,
      Online: true,
      Breakfast: false,
      HasCancellation: false,
      Recommended: true,
      TravelPolicy: TravelPolicyFilter.NotApplied,
      Meal: [],
    },
    Hotel: {
      SmartHotel: false,
      PriceGuaranteed: false,
      Favorite: false,
      Stars: null,
      Type: null,
      Amenities: null,
      Rating: null,
      Name: '',
      Proximity: {
        Latitude: 0,
        Longitude: 0,
        Radius: 0,
      },
    },
  },
});

const reducer = (action: { [key: string]: any, type: string }, state: IHotelsStore) => {
  let newState = {
    ...state,
  };

  switch (action.type) {
    case ACTION.UPDATE_HOTELS_SEARCH_PAGE: {
      const {
        startMetrics,
        stopMetrics,
        isFiltersHotelsInMicroservice,
        res: { Variants, DisplayFilters, SearchComplete },
      } = action.payload;

      startMetrics();

      const { latitude, longitude } = newState.regionLocation;
      const center: number[] = [latitude, longitude];

      let sources: Variant[] = Variants;

      if (isFiltersHotelsInMicroservice) {
        const { price } = newState.filters;
        const { displayFilters } = newState;

        const displayPriceTo = displayFilters && price.to !== displayFilters.PriceTo
          ? newState.displayFilters?.PriceTo
          : DisplayFilters.PriceTo;

        newState.sources = sources;
        newState.displayFilters = {
          ...DisplayFilters,
          Meal: MEAL_FIELDS.map(({ value }) => ({ [value]: false })),
          PriceTo: displayPriceTo,
        };
      }

      if (!isFiltersHotelsInMicroservice) {
        sources = updateDistanceFromCenterOrPoint(Variants, center, newState.coordsPoint);
        newState.sources = mergeVariants(sources, newState.sources);
      }

      const {
        accountTravelPolicy,
        travelPolicyAllList,
        isWhiteLabel,
        hotelsFilters,
        displayFilters,
        radiusCustom,
        filters: {
          changed,
        },
      } = newState;

      const unavailableTravelPolicy =
        // @ts-ignore
        newState.rightsBuyTrip.BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unavailable &&
        // @ts-ignore
        newState.rightsBuyTrip.BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unavailable;

      let filters;
      let hasOneSmart;
      let hasOneGuaranteed;
      let isAnyFavorite = false;

      if (isFiltersHotelsInMicroservice && !changed.price) {
        newState.filters.price.to = !displayFilters?.PriceTo ? 0 : displayFilters.PriceTo;
      }

      if (newState.sources.length) {
        const resultUpdateFilters = updateFiltersParams(
          newState.sources,
          newState.filters,
          unavailableTravelPolicy,
          accountTravelPolicy,
          travelPolicyAllList,
          isWhiteLabel,
          isFiltersHotelsInMicroservice,
        );

        filters = resultUpdateFilters.filters;
        hasOneSmart = resultUpdateFilters.hasOneSmart;
        hasOneGuaranteed = resultUpdateFilters.hasOneGuaranteed;
        isAnyFavorite = resultUpdateFilters.isAnyFavorite;
        newState.hotelsFilters.Rate.TravelPolicy = accountTravelPolicy && unavailableTravelPolicy
          ? resultUpdateFilters.filters.selectedTravelPolicy
          : newState.hotelsFilters.Rate.TravelPolicy;
      } else {
        filters = newState.filters;
        hasOneSmart = false;
        hasOneGuaranteed = false;
      }

      if (!newState.changeSortBy && !isFiltersHotelsInMicroservice) {
        if (newState.sortBy === SORT_TYPES.SMART_HOTEL && !hasOneSmart) newState.sortBy = SORT_TYPES.DISTANCE;

        if (newState.sortBy === SORT_TYPES.DISTANCE && hasOneSmart) newState.sortBy = SORT_TYPES.SMART_HOTEL;
      }

      if (isFiltersHotelsInMicroservice) {
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          filters.travelPolicyList,
          filters.price.to,
        );
        newState.loading = !newState.sources.length && !displayFilters?.CountHotels && (newState.viewMode !== VIEW_MODE_TYPES.LIST_MAP) && !SearchComplete;
      }

      newState.filters = {
        ...filters,
      };

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, hotelsFilters.Hotel.Proximity.Radius, radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.total = newState.cache.length;
      newState.hasOneSmart = hasOneSmart;
      newState.hasOneGuaranteed = hasOneGuaranteed;
      newState.isAnyFavorite = isAnyFavorite;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.menuType = SEARCH_MENU_TYPES.SUB_MENU;

      if (!isFiltersHotelsInMicroservice) {
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, unavailableTravelPolicy);
        newState.loading = !newState.sources.length && (newState.viewMode !== VIEW_MODE_TYPES.LIST_MAP);
      }

      newState.unavailableTravelPolicy = unavailableTravelPolicy;

      newState.progress = getDiffPercentOnStartSearch(state.searchStartTime);

      stopMetrics();

      break;
    }

    case ACTION.SET_LAST_HOTELS_SEARCH: {
      newState.lastHotelsSearch = action.payload;

      break;
    }

    case ACTION.STARTSEARCH: {
      const { rightsBuyTrip, accountTravelPolicy, travelPolicyAllList } = action.payload;

      if (!newState.initRadiusSearchHotels) {
        newState = createNewState(action.isWhiteLabel);
      }

      newState.loadComplete = false;
      newState.menuType = SEARCH_MENU_TYPES.SUB_MENU;
      newState.loading = !newState.initRadiusSearchHotels;
      newState.loadingRegion = !newState.initRadiusSearchHotels;
      newState.progress = 0.1;
      newState.filters = {
        ...newState.filters,
        ...action.filters,
      };
      newState.favorite = action.favorite || null;
      newState.rightsBuyTrip = rightsBuyTrip;
      newState.accountTravelPolicy = accountTravelPolicy;
      newState.travelPolicyAllList = travelPolicyAllList;

      break;
    }

    case ACTION.REGION_COMPLETE: {
      const { regionLocation } = action;

      newState.loadingRegion = false;
      newState.regionLocation = regionLocation;

      break;
    }

    case ACTION.REGION_FAIL: {
      newState.loadingRegion = false;
      newState.loading = false;
      newState.loadComplete = true;
      newState.searchStartTime = 0;
      newState.progress = 0;

      break;
    }

    case ACTION.NEWSEARCH: {
      newState = createNewState(action.isWhiteLabel);
      break;
    }

    case ACTION.STARTRADIUSSEARCH: {
      const { rightsBuyTrip, accountTravelPolicy, travelPolicyAllList } = action.payload;
      newState.progress = 0.1;
      newState.loadComplete = false;
      newState.favorite = action.favorite || null;

      newState.rightsBuyTrip = rightsBuyTrip;
      newState.accountTravelPolicy = accountTravelPolicy;
      newState.travelPolicyAllList = travelPolicyAllList;
      newState.abortSearchByRadius = true;

      break;
    }

    case ACTION.ABORTRADIUSSEARCH: {
      newState.loadComplete = action.payload;
      newState.searchStartTime = 0;
      newState.progress = 0;
      newState.abortSearchByRadius = false;
      break;
    }

    case ACTION.UPDATEPRICEFILTER: {
      const { value, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Rate.PriceFrom = value[0];
        newState.hotelsFilters.Rate.PriceTo = value[1];
        newState.filters.price.to = value[1];
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );

        const priceObj = {
          from: value[0],
          to: value[1],
          border: {
            from: displayFilters ? displayFilters.PriceFrom : 0,
            to: displayFilters ? displayFilters.PriceTo : 0,
          },
        };

        newState.filters.changed.price = hasChangePriceFilter(priceObj);
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.price = updatePriceFilter(newState.filters.price, value);
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
        newState.filters.changed.price = hasChangePriceFilter(newState.filters.price);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);

      break;
    }

    case ACTION.UPDATESTARSFILTER: {
      const { value: { value, type }, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Hotel.Stars = changeFilter<number>(newState.hotelsFilters.Hotel.Stars, +type);
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.stars[type].selected = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed.stars = hasChangeObjFilter(newState.filters.stars);

      break;
    }

    case ACTION.UPDATE_ONLINE_FILTER: {
      const { value, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Rate.Online = value;
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.online = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed.online = newState.filters.online;

      break;
    }

    case ACTION.UPDATE_BREAKFAST_FILTER: {
      const { value, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Rate.Meal = getHotelsMealByCategory(newState.hotelsFilters.Rate.Meal, value);
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.breakfast = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed.breakfast = newState.filters.breakfast;

      break;
    }

    case ACTION.UPDATE_RECOMMEND_HOTELS: {
      const { value } = action.payload;
      const {
        hotelsFilters,
        travelPolicyAllList,
        unavailableTravelPolicy,
        radiusCustom,
        displayFilters,
        filters: {
          travelPolicyList,
          price,
        },
      } = newState;

      newState.hotelsFilters.Rate.Recommended = value;
      newState.tags = createNewSearchTags(
        hotelsFilters,
        travelPolicyAllList,
        unavailableTravelPolicy,
        displayFilters,
        radiusCustom,
        travelPolicyList,
        price.to,
      );

      break;
    }

    case ACTION.UPDATE_RADIUS_FILTER: {
      const { value, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Hotel.Proximity.Latitude = newState.coordsPoint[0] || newState.regionLocation.latitude;
        newState.hotelsFilters.Hotel.Proximity.Longitude = newState.coordsPoint[1] || newState.regionLocation.longitude;
        newState.hotelsFilters.Hotel.Proximity.Radius = +value.value;
        newState.radiusCustom = value.custom;

        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.radius = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;

      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed.radius = !!newState.filters.radius.value;

      break;
    }

    case ACTION.UPDATERATINGFILTER: {
      const {
        payload: { value: { type, value }, isFiltersHotelsInMicroservice },
      } = action;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Hotel.Rating = changeFilter<number>(newState.hotelsFilters.Hotel.Rating, +type);
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.rating[type] = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed.rating = hasChangeFilter(newState.filters.rating);

      break;
    }

    case ACTION.UPDATEAMENITIESFILTER: {
      const { value: { value, type }, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Hotel.Amenities = changeFilter<string>(newState.hotelsFilters.Hotel.Amenities, type);
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.amenities[type as keyof Amenities] = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed.amenities = hasChangeFilter(newState.filters.amenities);

      break;
    }

    case ACTION.UPDATETYPEFILTER: {
      const { value: { value, type }, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Hotel.Type = changeFilter<string>(newState.hotelsFilters.Hotel.Type, type);
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.type[type].selected = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed.type = hasChangeObjFilter(newState.filters.type);

      break;
    }

    case ACTION.UPDATESMARTHOTELFILTER: {
      const { value, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Hotel.SmartHotel = value;
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.smartHotel = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed.smartHotel = newState.filters.smartHotel;

      break;
    }

    case ACTION.UPDATE_PRICE_GUARANTEED_FILTER: {
      const { value, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Hotel.PriceGuaranteed = value;
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.priceGuaranteed = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed.priceGuaranteed = newState.filters.priceGuaranteed;

      break;
    }

    case ACTION.UPDATEHOTELNAMEFILTER: {
      const { value, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Hotel.Name = value;
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );

        if (!value) newState.filters.mapIsFilter.value = false;
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.hotelName = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
        newState.filters.changed.hotelName = !!newState.filters.hotelName;
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);

      break;
    }

    case ACTION.UPDATECANCELLATIONFILTER: {
      const { value, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Rate.HasCancellation = value;
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.hasCancellation = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed.hasCancellation = newState.filters.hasCancellation;

      break;
    }

    case ACTION.UPDATEFAVORITEFILTER: {
      const { value, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Hotel.Favorite = value;
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.favoriteId = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed.favoriteId = newState.filters.favoriteId;

      break;
    }

    case ACTION.UPDATE_TRAVEL_POLICY_FILTER: {
      const { value: { value }, isFiltersHotelsInMicroservice } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.hotelsFilters.Rate.TravelPolicy = value;
        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters.selectedTravelPolicy = value;
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);

      break;
    }

    case ACTION.UPDATESORTBY: {
      const { isFiltersHotelsInMicroservice, value } = action.payload;

      if (isFiltersHotelsInMicroservice) {
        newState.hotelsFilters.SortType = value;

        if (value === NEW_SEARCH_SORT_TYPES.RECOMMEND) {
          newState.hotelsFilters.Rate.Recommended = true;
        }
      }

      newState.sortBy = value;
      newState.changeSortBy = true;
      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      break;
    }

    case ACTION.PAGING: {
      newState.paging.current = action.payload.value;
      newState.items = getPagingView(newState.cache, newState.paging);
      break;
    }

    case ACTION.LOADCOMPLETE: {
      newState.loadComplete = true;
      newState.searchStartTime = 0;
      newState.progress = 0;
      newState.isRefund = false;

      if (!newState.sources.length) {
        newState.items = [];
        newState.menuType = SEARCH_MENU_TYPES.SUB_MENU;
        newState.loading = false;
      }

      break;
    }

    case ACTION.SET_IS_REFUND: {
      newState.isRefund = action.payload;

      break;
    }

    case ACTION.CHANGEFAVORITESTATE: {
      const { id, favoriteId } = action.payload;

      const newItems = newState.items.map(item => ({
        ...item,
        favoriteId: item.static.HotelId === id ? favoriteId : item.favoriteId,
      }));

      const favorite = newState.items.find(item => item.favoriteId);

      newState.sources = getNewSourcesWithFavorites(newState.sources, id, favoriteId);
      newState.cache = newItems;
      newState.itemsMap = newItems;
      newState.items = newItems;
      newState.isAnyFavorite = Boolean(favorite);

      break;
    }

    case ACTION.TOGGLE_SET_CACHE_ON_MAP: {
      newState.setCacheOnMapToggler = !newState.setCacheOnMapToggler;

      break;
    }

    case ACTION.DELETE_TAGS: {
      const { tag: { filter, key }, isFiltersHotelsInMicroservice, region } = action.payload;
      const {
        Hotel: {
          Stars,
          Type,
          Amenities: services,
          Rating,
        },
        Rate: { Meal },
      } = newState.hotelsFilters;

      let value = null;

      switch (filter) {
        case HOTELFILTERTYPE.ONLINE: {
          value = false;
          newState.hotelsFilters.Rate.Online = false;

          analyticByFilter(filter, YesNoType.no);

          break;
        }

        case HOTELFILTERTYPE.BREAKFAST: {
          value = false;
          newState.hotelsFilters.Rate.Meal = getHotelsMealByCategory(Meal, +key);

          analyticByFilter(filter, YesNoType.no);

          break;
        }

        case HOTELFILTERTYPE.HASCANCELLATION: {
          value = false;
          newState.hotelsFilters.Rate.HasCancellation = false;

          analyticByFilter(filter, YesNoType.no);

          break;
        }

        case HOTELFILTERTYPE.RECOMMENDED: {
          newState.hotelsFilters.Rate.Recommended = false;

          analyticByFilter(filter, YesNoType.no);

          break;
        }

        case HOTELFILTERTYPE.PRICE: {
          value = updatePriceFilter(newState.filters.price, [newState.filters.price.border.from, newState.filters.price.border.to]);

          if (newState.displayFilters) {
            newState.hotelsFilters.Rate.PriceFrom = newState.displayFilters.PriceFrom;
            newState.hotelsFilters.Rate.PriceTo = newState.displayFilters.PriceTo;
          }

          analyticByFilter(filter, {
            regionName: region.label,
            priceRange: `${newState.hotelsFilters.Rate.PriceFrom} ${newState.hotelsFilters.Rate.PriceTo}`,
          });

          break;
        }
        case HOTELFILTERTYPE.SMARTHOTEL: {
          value = false;
          newState.hotelsFilters.Hotel.SmartHotel = false;

          break;
        }

        case HOTELFILTERTYPE.PRICE_GUARANTEED: {
          value = false;
          newState.hotelsFilters.Hotel.PriceGuaranteed = false;

          analyticByFilter(filter, YesNoType.no);

          break;
        }

        case HOTELFILTERTYPE.FAVORITES: {
          value = false;
          newState.hotelsFilters.Hotel.Favorite = false;

          analyticByFilter(filter, YesNoType.no);

          break;
        }

        case HOTELFILTERTYPE.VAT: {
          value = false;

          analyticByFilter(filter, YesNoType.no);

          break;
        }

        case HOTELFILTERTYPE.HOTELNAME: {
          value = '';
          newState.hotelsFilters.Hotel.Name = '';

          analyticByFilter(filter, '');

          break;
        }

        case HOTELFILTERTYPE.STARS: {
          value = {
            ...newState.filters[filter as keyof Filters],
            [key]: {
              ...newState.filters[filter as keyof Filters][key],
              selected: false,
            },
          };

          newState.hotelsFilters.Hotel.Stars = Stars && Stars.filter(star => star !== +key);

          analyticByFilter(filter, { stars: key, checkboxHotelFilter: YesNoType.no });

          break;
        }

        case HOTELFILTERTYPE.TYPE: {
          value = {
            ...newState.filters[filter as keyof Filters],
            [key]: {
              ...newState.filters[filter as keyof Filters][key],
              selected: false,
            },
          };

          newState.hotelsFilters.Hotel.Type = Type && Type.filter(type => type !== key);

          analyticByFilter(filter, { typeAccomodation: key, checkboxHotelFilter: YesNoType.no });

          break;
        }

        case HOTELFILTERTYPE.AMENITIES: {
          value = {
            ...state.filters.amenities,
            [key]: false,
          };

          newState.hotelsFilters.Hotel.Amenities =
            services && services.filter(service => service !== key);

          analyticByFilter(filter, { roomServices: key, checkboxHotelFilter: YesNoType.no });

          break;
        }

        case HOTELFILTERTYPE.SELECTEDTRAVELPOLICY: {
          if (!newState.unavailableTravelPolicy) {
            value = TRAVELPOLICYFILTER.NOTAPPLIED;

            newState.hotelsFilters.Rate.TravelPolicy = TRAVELPOLICYFILTER.NOTAPPLIED;
          }

          analyticByFilter(filter, DEFAULT_ANALYTIC_NAME.TP);

          break;
        }

        case HOTELFILTERTYPE.RATING: {
          value = {
            ...newState.filters[filter as keyof Filters],
            [key]: false,
          };

          newState.hotelsFilters.Hotel.Rating = Rating && Rating.filter(rating => rating !== +key);

          analyticByFilter(filter, { rating: `${key}+`, checkboxHotelFilter: YesNoType.no });

          break;
        }

        case HOTELFILTERTYPE.RADIUS: {
          value = {
            value: 0,
            custom: false,
          };

          newState.hotelsFilters.Hotel.Proximity.Latitude = newState.regionLocation.latitude;
          newState.hotelsFilters.Hotel.Proximity.Longitude = newState.regionLocation.longitude;
          newState.hotelsFilters.Hotel.Proximity.Radius = 0;
          newState.radiusCustom = false;

          analyticByFilter(filter, 0);

          break;
        }
      }

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.filters = {
          ...newState.filters,
          mapIsFilter: {
            value: false,
            visibleHotels: [],
          },
          [filter]: value,
        };

        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed[filter as keyof ChangedFilters] = false;

      break;
    }

    case ACTION.RESET_FILTERS: {
      const { isFiltersHotelsInMicroservice } = action.payload;

      // filters that are not just simple checkboxes
      const stars = uncheckFilter(newState.filters.stars);
      const type = uncheckFilter(newState.filters.type);
      const rating = uncheckFilter(newState.filters.rating, true);
      const selectedTP = isFiltersHotelsInMicroservice
        ? newState.hotelsFilters.Rate.TravelPolicy
        : newState.filters.selectedTravelPolicy;

      const selectedTravelPolicy = !newState.unavailableTravelPolicy
        ? TRAVELPOLICYFILTER.NOTAPPLIED
        : selectedTP;

      newState = {
        ...newState,
        filters: {
          price: {
            ...newState.filters.price,
            from: newState.filters.price.border.from,
            to: newState.filters.price.border.to,
          },
          smartHotel: false,
          priceGuaranteed: false,
          favoriteId: false,
          hasCancellation: false,
          hotelName: '',
          network: {},
          hasVat: false,
          amenities: {
            Fitness: false,
            Internet: false,
            Meal: false,
            Parking: false,
            Pool: false,
            Spa: false,
          },
          travelPolicyList: newState.filters.travelPolicyList,
          rating,
          stars,
          type,
          selectedTravelPolicy,
          online: false,
          breakfast: false,
          radius: {
            value: 0,
            custom: false,
          },
          mapIsFilter: {
            value: false,
            visibleHotels: [],
          },
          changed: {
            price: false,
            smartHotel: false,
            priceGuaranteed: false,
            hotelName: false,
            stars: false,
            type: false,
            amenities: false,
            network: false,
            hasCancellation: false,
            rating: false,
            mapIsFilter: false, // TODO Доп сброс фильтров
            favoriteId: false,
            hasVat: false,
            online: false,
            breakfast: false,
            radius: false,
            viewMode: newState.filters.changed.viewMode,
          },
        },
        radiusCustom: false,
        hotelsFilters: {
          SortType: NEW_SEARCH_SORT_TYPES.RECOMMEND,
          Rate: {
            PriceFrom: 0,
            PriceTo: 0,
            Online: false,
            Breakfast: false,
            HasCancellation: false,
            Recommended: false,
            TravelPolicy: TravelPolicyFilter.NotApplied,
            Meal: [],
          },
          Hotel: {
            SmartHotel: false,
            PriceGuaranteed: false,
            Favorite: false,
            Stars: null,
            Type: null,
            Amenities: null,
            Rating: null,
            Name: '',
            Proximity: {
              Latitude: 0,
              Longitude: 0,
              Radius: 0,
            },
          },
        },
      };

      if (isFiltersHotelsInMicroservice) {
        const {
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          radiusCustom,
          displayFilters,
          filters: {
            travelPolicyList,
            price,
          },
        } = newState;

        newState.tags = createNewSearchTags(
          hotelsFilters,
          travelPolicyAllList,
          unavailableTravelPolicy,
          displayFilters,
          radiusCustom,
          travelPolicyList,
          price.to,
        );
      }

      if (!isFiltersHotelsInMicroservice) {
        newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);
      }

      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);

      break;
    }

    case ACTION.HAS_VAT_FILTERS: {
      const { isFiltersHotelsInMicroservice } = action.payload;

      newState.filters.hasVat = action.payload.value;
      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.paging.current = 1;
      newState.paging.total = newState.cache.length;
      newState.items = getPagingView(newState.cache, newState.paging);
      newState.filters.changed.hasVat = newState.filters.hasVat;

      newState.tags = createTags(newState.filters, newState.travelPolicyAllList, newState.unavailableTravelPolicy);

      break;
    }

    case ACTION.UPDATECOORDSPOINT: {
      newState.coordsPoint = action.payload.value;

      break;
    }

    case ACTION.UPDATEVIEWMODE: {
      newState.viewMode = action.payload;
      newState.itemsMap = newState.cache;
      newState.filters.changed.viewMode = true;

      break;
    }

    case ACTION.UPDATE_VISIBLE_HOTEL_ON_MAP: {
      const { isFiltersHotelsInMicroservice, value } = action.payload;

      newState.filters.mapIsFilter = value;
      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;
      newState.filters.changed.mapIsFilter = !!newState.filters.mapIsFilter.value;

      break;
    }

    case ACTION.UPDATE_DISTANCE_FROM_POINT: {
      const { isFiltersHotelsInMicroservice, value } = action.payload;

      const sources = updateDistanceFromCenterOrPoint(newState.sources, [], value);
      newState.sources = mergeVariants(sources, newState.sources);
      newState.cache = isFiltersHotelsInMicroservice
        ? sortByNewSearch(newState.sources, newState.hotelsFilters.Hotel.Proximity.Radius, newState.radiusCustom)
        : sortBy(newState.sources, newState.sortBy, newState.filters);
      newState.itemsMap = newState.filters.mapIsFilter.value
        ? sortByVisibleHotelOnMap(newState.cache, newState.filters.mapIsFilter.visibleHotels)
        : newState.cache;

      break;
    }

    case ACTION.INIT_RADIUS_SEARCH_HOTELS: {
      newState.initRadiusSearchHotels = action.payload;

      break;
    }

    case ACTION.INIT_DOUBLE_RADIUS_SEARCH: {
      newState.initDoubleRadiusSearch = true;

      break;
    }
  }

  return newState;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
