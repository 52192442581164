import React from 'react';
import { Button, Dialog, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const LABELS = {
  CONFIRM_ACTION: getText('cart:normalCart.confirmAction'),
  OK: getText('common:ok'),
};

interface TransferDialogProps {
  visible: boolean,
  title: string,
  description?: string,
  onChangeVisible: (value: boolean) => void,
  onClose?: () => void,
}

const TransferDialog = ({
  visible,
  title,
  description = '',
  onChangeVisible,
  onClose = () => {},
}: TransferDialogProps) => (
  <Dialog
    showClosing
    show={ visible }
    onChange={ onChangeVisible }
  >
    <div className={ styles['dialog-content'] }>
      <Text type='bold_20' className={ styles.title } qaAttr={ QA_ATTRIBUTES.cart.transfer.dialog.title }>
        { title }
      </Text>

      <Text className={ styles.text } type='NORMAL_14_130'>{ description }</Text>

      <div className={ styles.actions }>
        <Button
          type='primary'
          onClick={ onClose }
          qaAttr={ QA_ATTRIBUTES.cart.transfer.dialog.button }
        >
          { LABELS.OK }
        </Button>
      </div>
    </div>
  </Dialog>
);

export default TransferDialog;
