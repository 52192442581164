import ACTION from './action';
import Store from './store';

import { ErrorStatus, IAuthStore, IStatusLogin, StateStatus } from '../../types/auth';

class Auth {
  api: any;
  store: any;

  constructor(api: any) {
    this.api = api;
    this.store = Store();
  }

  getStore = (): IAuthStore => this.store.getState();

  setField = (field: string, value: any): void => {
    this.store.dispatch({
      type: `update.${field}`,
      payload: value,
    });

    if (field === 'username') {
      this.store.dispatch({
        type: ACTION.UPDATEERROR,
        payload: '',
      });

      this.setButtonDisabled(false);
    }
  };

  setLoginResult = (payload: IStatusLogin): void => {
    this.store.dispatch({
      type: ACTION.LOGINRESULT,
      payload,
    });
  };

  setStartLogin = (): void => {
    this.store.dispatch({
      type: ACTION.LOGINSTART,
    });
  };

  setButtonDisabled = (payload: boolean): void => {
    this.store.dispatch({
      type: ACTION.UPDATEBUTTONDISABLED,
      payload,
    });
  };

  loginSuccess = (state: StateStatus): void => this.setLoginResult({ error: null, state });

  loginError = (error: ErrorStatus, state: StateStatus): void => this.setLoginResult({ error, state });

  subscribe = (cb: any) => this.store.subscribe(cb);
}

export default Auth;
