import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { PageLoader } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import ChartsAnalytics from '../../../../../bi/services/chartsAnalytics';

import { MainAnalytic } from '../../../../../bi/utils/analytics';

import {
  TYPE_SMART_SERVICE_TYPE,
  STRUCTURE_TYPE,
} from '../../../../../bi/constants/chartsAnalytics';
import ROUTES from '../../../../../bi/constants/routes';

import { ChartAnalyticsNoResult } from '../../noResult';

import { SubSections } from '../components/subSections';
import { SmartIndexChart } from '../components/SmartIndexChart';

const LABELS = {
  HEADER: getText('chartsAnalytics:menuItems.smart.indexChart.header_smart'),
  TOOLTIP: getText('chartsAnalytics:menuItems.smart.indexChart.tooltip.page_common'),
};

const SmartSummary = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => {
  const {
    chartsAnalyticsSmartSummaryStore: {
      loading,
      chart: {
        values,
        labels,
      },
      summaryIndex,
      indexes,
    },
    chartsAnalyticsStore: {
      filtersType,
    },
  } = useStores([
    MOBX_STORES.CHARTS_ANALYTICS_SMART_SUMMARY_STORE,
    MOBX_STORES.CHARTS_ANALYTICS_STORE,
  ]);

  const { getSmartSummary } = chartsAnalyticsService;

  useEffect(() => {
    getSmartSummary();
  }, [filtersType, getSmartSummary]);

  useEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.ANALYTICS.SPENDING_INDEX_PAGE_OPENED);
  }, []);

  const history = useHistory();

  const handleOpen = ({ type }: { type: string }) => {
    const active = ({
      [STRUCTURE_TYPE.AIR]: ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.AIRLINE,
      [STRUCTURE_TYPE.TRAIN]: ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.TRAIN,
      [STRUCTURE_TYPE.HOTEL]: ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.HOTEL,
      [STRUCTURE_TYPE.TAXI_VOUCHER]: ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.TAXI,
      [STRUCTURE_TYPE.AEROEXPRESS]: ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.AEROEXPRESS,
      [STRUCTURE_TYPE.TRANSFER]: ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.TRANSFER,
      [STRUCTURE_TYPE.MICE]: ROUTES.CHARTS_ANALYTICS.SMART.COMMON.SECOND.MICE,
    })[type];

    const analyticType = ({
      [STRUCTURE_TYPE.AIR]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_AIRLINE_PAGE_OPENED,
      [STRUCTURE_TYPE.MICE]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_MICE_PAGE_OPENED,
      [STRUCTURE_TYPE.TRAIN]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_TRAIN_PAGE_OPENED,
      [STRUCTURE_TYPE.HOTEL]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_HOTEL_PAGE_OPENED,
      [STRUCTURE_TYPE.TAXI_VOUCHER]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_TAXI_PAGE_OPENED,
      [STRUCTURE_TYPE.AEROEXPRESS]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_AEROEXPRESS_PAGE_OPENED,
      [STRUCTURE_TYPE.TRANSFER]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_TRANSFER_PAGE_OPENED,
      [STRUCTURE_TYPE.MICE]: MainAnalytic.ACTIONS.ANALYTICS.SPENDING_MICE_PAGE_OPENED,
    })[type];

    MainAnalytic.sendAmplitude(analyticType);

    chartsAnalyticsService.changeSmartType(type);

    return history.push(active);
  };

  if (loading) {
    return <PageLoader />;
  }

  if (!values?.length || !summaryIndex) {
    return <ChartAnalyticsNoResult />;
  }

  return (
    <>
      <SmartIndexChart
        title={ LABELS.HEADER }
        values={ values }
        labels={ labels }
        summaryIndex={ summaryIndex }
        tooltip={ LABELS.TOOLTIP }
      />
      <SubSections
        serviceType={ TYPE_SMART_SERVICE_TYPE.SUMMARY }
        indexes={ indexes }
        onOpen={ handleOpen }
      />
    </>
  );
});

export { SmartSummary };
