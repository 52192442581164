import React, { useState } from 'react';
import { Button, StyledWrapper, Text } from 'new-ui';

import { getText } from '../../../../i18n';

import textAbbreviation from '../../../bi/utils/textAbbreviation';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';
import { LINE_FEED_OR_CARRIAGE_RETURN } from '../../../bi/constants/regExp';

import styles from '../styles/importantInformation.module.css';

const LABELS = {
  PRIMARY_INFO: getText('hotels:hotelResult.item.primaryInfo'),
  MORE: getText('hotels:hotelResult.item.importantInfo.more'),
  HIDE: getText('hotels:hotelResult.item.importantInfo.hide'),
};

const NUMBER_WORD = 400;

interface ImportantInformationProps {
  informationInfo: {
    AccommodationInfo: string,
    TaxInfo: string,
  },
}

const ImportantInformation = ({
  informationInfo: { TaxInfo, AccommodationInfo },
}: ImportantInformationProps) => {
  const [moreButton, setMoreButton] = useState(false);

  const getAccommodationInfo = () => {
    if (!AccommodationInfo) {
      return '';
    }

    // @ts-ignore
    const text = AccommodationInfo.substring(AccommodationInfo.replace(LINE_FEED_OR_CARRIAGE_RETURN, '<br />'));

    return moreButton ? text : textAbbreviation(text, NUMBER_WORD);
  };

  const labelButton = moreButton ? LABELS.HIDE : LABELS.MORE;

  const stylesText = [styles.text];

  if (!TaxInfo) {
    stylesText.push(styles.no_margin);
  }

  const buttonMoreHtml = !!AccommodationInfo.length && AccommodationInfo.length > NUMBER_WORD && (
    <Button
      qaAttr={ QA_ATTRIBUTES.hotels.current.importantInfoMore }
      type='textual'
      className={ styles['more-btn'] }
      onClick={ () => setMoreButton(!moreButton) }
    >
      { labelButton }
    </Button>
  );

  return (
    <div className={ styles.wrapped }>
      <Text type='bold_32' className={ styles.title }>
        { LABELS.PRIMARY_INFO }
      </Text>
      <StyledWrapper className={ styles.styled_wrapper }>
        <Text>
          { TaxInfo }
        </Text>
        <div
          className={ stylesText.join(' ') }
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={ { __html: getAccommodationInfo() } }
        />
        { buttonMoreHtml }
      </StyledWrapper>
    </div>
  );
};

export { ImportantInformation };
