// @ts-nocheck
import { DATEFORMATS } from '../constants/dateFormats';
import { lpad } from './pad';

const createNewDateString = (dateRange, hasTime = true) => {
  const startTime = `${lpad(dateRange.start.time)}:00-${lpad(dateRange.start.time + 3)}:00`;
  let result = `${dateRange.start.date.format(DATEFORMATS.DAY_MONTH)} ${hasTime ? startTime : ''}`;

  if (dateRange.end.date.isValid()) {
    const endTime = `${lpad(dateRange.end.time)}:00-${lpad(dateRange.end.time + 3)}:00`;
    result += ` - ${dateRange.end.date.format(DATEFORMATS.DATE)} ${hasTime ? endTime : ''}`;
  }

  return result;
};

export default createNewDateString;
