import React from 'react';
import { observer } from 'mobx-react';
import { Text, StyledWrapper } from 'new-ui';

import { preparedSmartIndexColor } from '../../../../../bi/utils/chartsAnalytics';

import {
  SMART_SECTIONS_TITLE,
} from '../../../../../bi/constants/chartsAnalytics';

import { ISmartType, SmartIndexesType } from '../../../../../bi/services/chartsAnalytics/types';

import arrowSvg from '../../../../../../svg/arrow.svg';
import styles from '../styles/subSections.module.css';

interface ISubSectionsProps {
  serviceType: string,
  indexes: SmartIndexesType[],
  onOpen({ type, label }: ISmartType): void,
}

const SubSections = observer(({
  serviceType,
  indexes,
  onOpen,
}: ISubSectionsProps) => {
  const renderSection = ({ type, level, label }: SmartIndexesType, id: number) => (
    <div key={ `section_${id}` }>
      <StyledWrapper
        className={ `${styles.wrapper} ${styles[preparedSmartIndexColor(level)]}` }
        onClick={ () => onOpen({ type, label }) }
      >
        <div className={ styles.item }>
          <div className={ styles.text }>
            <Text type='bold_14' color='accent'> { label } </Text>
          </div>
          <div className={ styles.level } >
            <Text type='NORMAL_36'> { level } </Text>
            <img src={ arrowSvg } alt='arrow' className={ styles.image } />
          </div>
        </div>
      </StyledWrapper>
    </div>
  );

  return (
    <>
      <div className={ styles.title }>
        <Text type='bold_20'>
          { SMART_SECTIONS_TITLE[serviceType] }
        </Text>
      </div>
      { indexes.map(renderSection) }
    </>
  );
});

export { SubSections };
