import React, { FC } from 'react';
import { Button, Text, Tooltip } from 'new-ui';

import { getText } from '../../../../../i18n/index';

import styles from './styles/index.module.css';

const LABELS = {
  BACK: getText('components:changeAirTrip.back'),
  SEND: getText('components:tripDetailsDialog.flightCertDialogs.error.send'),
  NOT_CONFIRMED: getText('components:tripDetailsDialog.flightCertDialogs.error.notConfirmed'),
  TO_TRAVEL_HELPER: getText('components:tripDetailsDialog.flightCertDialogs.error.toTravelHelper'),
  DEMO: getText('constants:app.notForDemo'),
};

interface ErrorFlightCertificateProps {
  disabled: boolean;
  loading: boolean;
  onSubmit(): void;
  onBack(): void;
}

const ErrorFlightCertificate: FC<ErrorFlightCertificateProps> = ({
  disabled,
  loading,
  onSubmit,
  onBack,
}) => {
  const tooltip = () => (
    <Text
      className={ styles.tooltip_content }
      color='white'
      type='NORMAL_14_130'
    >
      { LABELS.DEMO }
    </Text>
  );

  return (
    <div className={ styles.wrap }>
      <Text className={ styles.title } type='bold_20'>
        { LABELS.NOT_CONFIRMED }
      </Text>
      <Text className={ styles.content }>
        { LABELS.TO_TRAVEL_HELPER }
      </Text>
      <div className={ styles.buttons }>
        <Tooltip show={ disabled } renderContent={ tooltip }>
          <Button
            className={ styles.button }
            type='secondary'
            disabled={ disabled }
            loading={ loading }
            onClick={ onSubmit }
          >
            { LABELS.SEND }
          </Button>
        </Tooltip>
        <Button
          className={ styles.cancel_btn }
          type='textual-medium'
          disabled={ loading }
          onClick={ onBack }
        >
          <Text color='accent'>{ LABELS.BACK }</Text>
        </Button>
      </div>
    </div>
  );
};

export { ErrorFlightCertificate };
