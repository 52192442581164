import React from 'react';
import { observer } from 'mobx-react';

import { MultiSelect, Select, RadioButton, Text } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { isSmartAgent } from '../../../../../bi/utils/env';

import { STEPSRIGHTS, FIELDRIGHTS } from '../../../../../bi/constants/rights';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import { RightsDialogWrap } from '../RightsDialogWrap';

import { IApprovalShemas } from '../../../../../bi/services/employee/types';

import styles from '../index.module.css';

const LABELS = {
  CHOSE_SCHEME: getText('settings:employees.employee.steps.rights.steps.approveExpenseReports.chooseScheme'),
  WITHIN_THE_TP_EMPLOYEES: getText('settings:employees.employee.steps.rights.steps.approveExpenseReports.withinTheTpEmployees'),
  WITHIN_THE_APPROVE_SCHEME: getText('settings:employees.employee.steps.rights.steps.approveExpenseReports.withinTheApproveScheme'),
  AFTER_APPROVE_CHELIK: getText('settings:employees.employee.steps.rights.steps.approveExpenseReports.afterApproveManager'),
  UNLIMITED: getText('settings:employees.employee.steps.rights.steps.approveExpenseReports.unlimited'),
  BY_ANY: getText('settings:employees.employee.steps.rights.steps.approveExpenseReports.byAny'),
  EMPLOYEE: (empl: string) => getText('settings:employees.employee.steps.rights.steps.approveExpenseReports.employeeMayHaveBuy', { empl }),
};

const { employee: { documents: { access: { modals } } } } = QA_ATTRIBUTES;

interface IApproveExpenseReportsStep {
  active: string,
  employee: string,
  header: string,
  approvalERScheme: number,
  approveERUsers: {
    label: string,
    name: string,
    value: string,
  }[],
  approvalERSchemes: IApprovalShemas[],
  showExpenseReportApprove: boolean,
  onChangeStep(value: string): void,
  onChangeRights(value: string, checkValue: boolean): void,
  onChangeApproveERUsers(value: string[]): void,
  onChangeApprovalERScheme(value: string): void,
  rights: {
    createERUnlimited: boolean,
    createERAfterApprove: boolean,
    createERApprovalScheme: boolean,
    approversForExpenseReports: [boolean],
    buyTripPersonal: boolean,
  },
}

const ApproveExpenseReportsStep = observer(({
  active,
  employee,
  header,
  approvalERScheme = 0,
  approveERUsers,
  approvalERSchemes,
  showExpenseReportApprove,
  onChangeStep,
  onChangeRights,
  onChangeApproveERUsers,
  onChangeApprovalERScheme,
  rights: {
    createERUnlimited,
    createERAfterApprove,
    createERApprovalScheme,
    approversForExpenseReports,
    buyTripPersonal,
  },
}: IApproveExpenseReportsStep) => {
  const handleSelectApprovalScheme = (value: string) => {
    onChangeRights(FIELDRIGHTS.CREATE_ER_APPROVAL_SCHEME, true);
    onChangeApprovalERScheme(value);
  };

  const handleSelectApprovers = (value: string[]) => {
    onChangeRights(FIELDRIGHTS.CREATE_ER_AFTER_APPROVE, true);
    onChangeApproveERUsers(value);
  };

  const renderApprovalContent = () => {
    const mappedSchemes = approvalERSchemes.map(({ Id, Name }) => ({ value: Id, label: Name }));

    const selectHtml = approvalERSchemes.length > 1 ? (
      <Select
        placeholder={ LABELS.CHOSE_SCHEME }
        theme='open-blue'
        items={ mappedSchemes }
        value={ approvalERScheme }
        onChange={ handleSelectApprovalScheme }
      />
    ) : (
      <Text>
        { mappedSchemes[0].label }
      </Text>
    );

    return (
      <div className={ styles['selected-item'] }>
        { selectHtml }
      </div>
    );
  };

  const renderCreateERApprovalScheme = () => (
    <div className={ `${styles['multiple-item']} ${styles.item}` }>
      <RadioButton
        checked={ createERApprovalScheme }
        value={ FIELDRIGHTS.CREATE_ER_APPROVAL_SCHEME }
        alternativeDesign={ isSmartAgent }
        onChange={ () => onChangeRights(FIELDRIGHTS.CREATE_ER_APPROVAL_SCHEME, true) }
        qaAttr={ modals.createExpenseReports.radioButtons.approvalERScheme }
      >
        { LABELS.WITHIN_THE_APPROVE_SCHEME }
      </RadioButton>
      { renderApprovalContent() }
    </div>
  );

  const renderCreateERAfterApproveSelect = () => (
    <div className={ styles['selected-item'] }>
      <span>(</span>
      <MultiSelect
        search
        list={ approveERUsers }
        theme='textual-blue'
        withArrow={ false }
        placeholder={ LABELS.BY_ANY }
        className={ styles['overflow-select'] }
        values={ approversForExpenseReports }
        onChange={ handleSelectApprovers }
      />
      <span>)</span>
    </div>
  );

  const createERAfterApproveSelectHtml = !!approveERUsers.length && renderCreateERAfterApproveSelect();
  const createERApprovalSchemeHtml = !!approvalERSchemes.length && renderCreateERApprovalScheme();

  const previousStep = buyTripPersonal ? STEPSRIGHTS.LIMIT : STEPSRIGHTS.MAIN;

  const radioExpenseReportsApprove = showExpenseReportApprove && (
    <>
      <div className={ `${styles['multiple-item']} ${styles.item}` }>
        <RadioButton
          checked={ createERAfterApprove }
          value={ FIELDRIGHTS.CREATE_ER_AFTER_APPROVE }
          alternativeDesign={ isSmartAgent }
          onChange={ () => onChangeRights(FIELDRIGHTS.CREATE_ER_AFTER_APPROVE, true) }
          qaAttr={ modals.createExpenseReports.radioButtons.createERAfterApprove }
        >
          { LABELS.AFTER_APPROVE_CHELIK }
        </RadioButton>
        { createERAfterApproveSelectHtml }
      </div>
      { createERApprovalSchemeHtml }
    </>
  );

  return (
    <RightsDialogWrap
      header={ header }
      theme={ active }
      onClickButton={ () => onChangeStep(STEPSRIGHTS.ADMIN) }
      onClickCancel={ () => onChangeStep(previousStep) }
      qaAttrHeader={ modals.createExpenseReports.title }
      qaAttrButton={ modals.buttonNext }
      qaAttrLink={ modals.createExpenseReports.buttons.cancel }
    >
      <Text className={ styles.subtitle }>
        { LABELS.EMPLOYEE(employee) }
      </Text>
      <RadioButton
        checked={ createERUnlimited }
        value={ FIELDRIGHTS.CREATE_ER_UNLIMITED }
        className={ styles.item }
        alternativeDesign={ isSmartAgent }
        onChange={ () => onChangeRights(FIELDRIGHTS.CREATE_ER_UNLIMITED, true) }
        qaAttr={ modals.createExpenseReports.radioButtons.unlimited }
      >
        { LABELS.UNLIMITED }
      </RadioButton>
      {radioExpenseReportsApprove}
    </RightsDialogWrap>
  );
});

export { ApproveExpenseReportsStep };
