import { observable, action, makeObservable, computed } from 'mobx';

import { CartsType } from '../../types/cart';
import { FactualApprovesLiteResponse } from './types';

export class ApproveStore {
  constructor() {
    makeObservable(this);
  }

  @observable message: string | null = '';
  @observable factualApprovesLiteResponseList: FactualApprovesLiteResponse[] = [];

  @action.bound
  setFactualApprovesLiteResponse(value: FactualApprovesLiteResponse[]) {
    this.factualApprovesLiteResponseList = value;
  }

  @action
  setQuickApprove(res: string | null) {
    this.message = res;
  }

  @computed
  get factualApprovesLiteResponseMap() {
    return Object.fromEntries(
      this.factualApprovesLiteResponseList.map((i) => [i.Id, i]),
    );
  }

  getApproveByCartId = computed(
    () => (CartId: number | null) =>
      this
        .factualApprovesLiteResponseList
        .find((i) => i.CartId === CartId),
  );

  getIsOfflineByCartId = computed(
    () => (cartId: number | null, carts: CartsType[]) => (carts && carts.length && cartId
      ? carts.find(({ id }) => id === cartId)?.isOffline
      : false),
  );
}

export const approveStore = new ApproveStore();
