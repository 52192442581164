import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip, Button, Text, Stars, Price, ItemPanel, Icon } from 'new-ui';
import { Link } from 'react-router-dom';
import { getText, getTextArray } from '../../../../../i18n';

import { ShowOnMapButton } from '../../../../components/ShowOnMapButton';
import { Rating } from '../../../../components/Rating';
import { NoPhoto } from '../../../../components/NoPhoto';
import { ImageGallery } from '../../../../components/ImageGallery/ImageGallery';
import { FavoriteAction } from '../../../../components/FavoriteAction';
import { LinkAction } from '../../../../components/LinkAction';
import { NameErrorsTravelPolicy } from '../../../../components/NameErrorsTravelPolicy';
import { HotelItemRate } from '../HotelItemRate';
import AgencyFee from '../../../../components/AgencyFee';

import AppService from '../../../../bi/services/app';

import MoneyFormat from '../../../../bi/utils/money';
import toDecline from '../../../../bi/utils/toDecline';
import { applyHotelTravelPolicy } from '../../../../bi/utils/travelPolicy';
import { calculatePriceValue } from '../../../../bi/utils/price';
import { convertToBase64, stringifySearchParams } from '../../../../bi/utils/convertSearchParams';
import { preloadOrigUrl } from '../../../../bi/utils/images';
import { prepareCustomCheckinCheckoutTime, preparedDatesAvailableContract } from '../../../../bi/utils/hotel';
import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';
import { prepareQueryMeal } from '../../../../bi/utils/hotels';

import FAVORITESACTION from '../../../../bi/constants/favorites';
import ANIMATION from '../../../../bi/constants/animation';
import CLASSNAMESFORTESTS from '../../../../bi/constants/classnamesfortests';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { PATTERN } from '../../../../bi/constants/dateFormats';

import {
  Filters,
  IHotelsFilters,
  IOptimalRateQueryParams,
  PreparedVariant,
} from '../../../../bi/services/hotels/types';
import { ITravelPolicyListItem } from '../../../../bi/types/travelPolicy';

import smartHotelSvg from '../styles/images/smarthotel.svg';
import styles from './styles.module.css';

const LABELS = {
  SPECIAL_OFFER: getText('hotels:regionResult.item.specialOffer'),
  DAYS_DECLINE: getTextArray('hotels:regionResult.item.daysDecline'),
  ROOM_DECLINE: getTextArray('hotels:regionResult.item.roomDecline'),
  ON: getText('hotels:regionResult.item.on'),
  PER: getText('hotels:regionResult.item.per'),
  PRICE_WITHOUT_FEE: getText('hotels:regionResult.item.priceWithoutFee'),
  FEE: getText('hotels:regionResult.item.fee'),
  FINAL_PRICE: getText('hotels:regionResult.item.finalPrice'),
  MINIMAL_PRICE: getText('hotels:regionResult.item.minimalPrice'),
  OPTIMAL_PRICE: getText('hotels:regionResult.item.optimalPrice'),
  CORPORATE_TARIFF: getText('hotels:regionResult.item.corporateTariff'),
  CHOOSE_ROOM: getText('hotels:regionResult.item.chooseRoom'),
  GUARANTEED: getText('hotels:hotelResult.guaranteed'),
  NEW_RANGE_TO_CENTER: (city: string, distance: string) => getText('hotels:regionResult.item.newRangeToCenter', { city, distance }), // новая карточка отеля
  RANGE_TO_CENTER: (city: string, distance: string) => getText('hotels:regionResult.item.rangeToCenter', { city, distance }),
  ADD_TAX: getText('hotels:hotelResult.item.rate.andTax'),
  RUB: getText('common:currency'),
};

interface HotelItemProps {
  appService: AppService,
  ind: number,
  onOpenMap(item: PreparedVariant): void,
  item: PreparedVariant,
  query: any,
  filters: Filters,
  travelPolicyList: ITravelPolicyListItem[],
  selectedTravelPolicy: string,
  diffDays: number,
  aggregationId: number | null,
  showPriceDetails: boolean,
  onGetLink(item: PreparedVariant, link: string): string,
  onClickToActionLink(): void,
  onCopyToClipboard(): void,
  onCloseDialogCopyLink(): void,
  onChangeFavorite(item: PreparedVariant, action: string): void,
  onShowHotelOnMap(): void,
  qaAttr?: string,
  queryTripId?: string | null,
  isFiltersHotelsInMicroservice: boolean,
  hotelsFilters: IHotelsFilters,
  optimalRateQueryParams: IOptimalRateQueryParams | string,
}

const HotelItem = ({
  ind,
  onOpenMap,
  item,
  query: {
    checkout,
    checkin,
    adult,
    region,
    customCheckout,
    customCheckin,
    requestItemId,
    travellersCount,
    roomCount,
  },
  filters: {
    online,
    // breakfast,
    hasCancellation,
  },
  travelPolicyList,
  selectedTravelPolicy,
  diffDays,
  aggregationId,
  showPriceDetails,
  onGetLink,
  onClickToActionLink,
  onCopyToClipboard,
  onCloseDialogCopyLink,
  onChangeFavorite,
  onShowHotelOnMap,
  qaAttr = '',
  queryTripId = '',
  isFiltersHotelsInMicroservice,
  optimalRateQueryParams,
  hotelsFilters: {
    Rate: {
      // Breakfast,
      Online,
      HasCancellation,
      Recommended,
      Meal,
    },
  },
  appService,
}: HotelItemProps) => {
  const {
    favoriteId,
    static: { HotelId, Address, DistanceFromCenter, Thumbnail, Rating: rating },
    isPriceGuaranteed,
    rate: {
      Total: rateTotal,
      AgentFee,
    },
    rate,
  } = item;

  // const breakfastFilter = isFiltersHotelsInMicroservice ? Breakfast : breakfast;
  const onlineFilter = isFiltersHotelsInMicroservice ? Online : online;
  const hasCancellationFilter = isFiltersHotelsInMicroservice ? HasCancellation : hasCancellation;

  const qaAttribute = ind === 0 ? QA_ATTRIBUTES.hotels.result.firstHotel : qaAttr;

  const [animationClass, setAnimationClass] = useState('');
  const [mainImageValid, setMainImageValid] = useState(false);

  const handlePreloadImg = useCallback(async () => {
    const res = await preloadOrigUrl(Thumbnail);

    if (res) {
      setMainImageValid(true);
    }
  }, [Thumbnail]);

  useEffect(() => {
    handlePreloadImg();
  }, [handlePreloadImg, item]);

  const handleAnimationEnd = () => setAnimationClass('');

  const handleChangeFavorite = (action: string) => {
    if (action === FAVORITESACTION.ADD) {
      setAnimationClass(ANIMATION.PULSE);
    }

    onChangeFavorite(item, action);
  };

  const handleSendAmplitude = () => {
    if (!isFiltersHotelsInMicroservice || !Recommended) return;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.HOTELS.SEARCH_RESULTS_HOTEL_RECOMMEND_RATE, {
      idHotel: HotelId,
      rateName: rate.RoomInfo.Name?.toLowerCase(),
      rateSum: rateTotal,
    });
  };

  const renderContractTooltip = () => (
    <div className={ styles['price-tooltip-container'] }>
      <div className={ styles.row }>
        <Text type='NORMAL_16_130' className={ styles.text }>
          { LABELS.SPECIAL_OFFER }
        </Text>
      </div>
      <Text type='NORMAL_16_130' color='white'>
        { preparedDatesAvailableContract(item.rate.NoAvailabilityPeriods) }
      </Text>
    </div>
  );

  const renderNameHotelContract = ({ HotelName, stars }: { HotelName: string, stars: number }) => (
    <Tooltip position='bottom' renderContent={ renderContractTooltip }>
      <Text type='NORMAL_18' className={ styles.title } qaAttr={ QA_ATTRIBUTES.hotels.result.title }>
        <span>{ HotelName }</span>
        <div className={ styles['stars-container'] }>
          <Stars qaAttr={ QA_ATTRIBUTES.hotels.result.stars } className={ styles.stars } count={ stars } />
        </div>
      </Text>
    </Tooltip>
  );

  // eslint-disable-next-line react/no-unused-prop-types
  const renderNameHotelDefault = ({ HotelName, stars }: { HotelName: string, stars: number }) => (
    <Text qaAttr={ QA_ATTRIBUTES.hotels.result.title } type='NORMAL_18' className={ styles.title }>
      <span>{ HotelName }</span>
      <div className={ styles['stars-container'] }>
        <Stars qaAttr={ QA_ATTRIBUTES.hotels.result.stars } className={ styles.stars } count={ stars } />
      </div>
    </Text>
  );

  const renderNameHotel = ({ isContract, static: { HotelName, Stars: stars } }: PreparedVariant) => (
    isContract
      ? renderNameHotelContract({ HotelName, stars })
      : renderNameHotelDefault({ HotelName, stars })
  );

  const renderHeader = () => {
    const applyTP = applyHotelTravelPolicy(item, selectedTravelPolicy);

    const queryParams = {
      CheckinDate: checkin.format(PATTERN.YEARMONTHDAY),
      CheckoutDate: checkout.format(PATTERN.YEARMONTHDAY),
      GuestsCount: adult,
      TravellersCount: travellersCount,
      RoomCount: roomCount,
      RegionName: region.label,
      RegionId: region.selected.Id,
      ...prepareCustomCheckinCheckoutTime(customCheckin, customCheckout),
      AddToHistory: false,
      RequestItemId: requestItemId,
      online: item.isContract ? false : onlineFilter,
      breakfast: prepareQueryMeal(Meal),
      hasCancellation: hasCancellationFilter,
      tripId: queryTripId,
      OptimalRate: convertToBase64(optimalRateQueryParams),
    };

    const hotelUri = `/search/hotel/${item.static.HotelId}`;
    const hotelUriSearchParams = stringifySearchParams(queryParams);

    const smartIconHtml = item.static.IsSmartHotel && aggregationId && (
      <img
        data-qa={ QA_ATTRIBUTES.hotels.result.hotelIcon }
        className={ styles['smart-icon'] }
        src={ smartHotelSvg }
        alt='Smarthotel'
      />
    );

    const hotelNameClass = item.static.IsSmartHotel && aggregationId ? styles['with-smart'] : '';

    const favoriteHtml = !isSmartAgent && (
      <FavoriteAction
        qaAttr={ QA_ATTRIBUTES.hotels.result.favorite }
        disabled={ applyTP }
        className={ `${styles.favorites} ${CLASSNAMESFORTESTS.HOTELS.TOFAVORITE}` }
        id={ favoriteId }
        onClick={ handleChangeFavorite }
      />
    );

    const linkUrl = `${hotelUri}?${hotelUriSearchParams}`;

    return (
      <div className={ styles.header }>
        { smartIconHtml }
        <Link
          to={ linkUrl }
          target='_blank'
          onClick={ handleSendAmplitude }
          className={ `${styles['name-wrapper']} ${hotelNameClass}` }
        >
          { renderNameHotel(item) }
        </Link>
        <div className={ styles.actions }>
          <NameErrorsTravelPolicy
            oneTravelPolicy
            hiddenNames
            item={ item.rate }
            applyTP={ applyTP }
            travelPolicyList={ travelPolicyList }
            selectedTravelPolicy={ selectedTravelPolicy }
          />
          <LinkAction
            item={ item }
            generateLink={ (value) => onGetLink(value, linkUrl) }
            className={ `${styles['get-link']} ${CLASSNAMESFORTESTS.HOTELS.GETLINK}` }
            onClick={ onClickToActionLink }
            onCopyToClipboard={ onCopyToClipboard }
            onClose={ onCloseDialogCopyLink }
          />
          { favoriteHtml }
        </div>
      </div>
    );
  };

  const renderDaysAmount = () => {
    if (diffDays === 1 && roomCount < 1) return null;

    const textDays = toDecline(diffDays, LABELS.DAYS_DECLINE);
    const textRoomCount = toDecline(roomCount, LABELS.ROOM_DECLINE);

    return (
      <Text qaAttr={ QA_ATTRIBUTES.hotels.result.daysAmount } type='NORMAL_12'>
        {LABELS.PER} {roomCount} {textRoomCount} {LABELS.ON} {diffDays} {textDays}
      </Text>
    );
  };

  const renderPriceHtml = (totalPrice: number) => {
    const { rate: { HasVat, Vat, NoAvailabilityPeriods } } = item;
    const { agentMode } = appService.get();

    const moneyFormatVat = MoneyFormat.moneyWithDecimal(Number(Vat), true);

    const preparedPrice = calculatePriceValue(totalPrice, travellersCount, roomCount);

    const preparedPriceWithAgent = !agentMode ? preparedPrice + AgentFee : preparedPrice;

    const vatHtml = HasVat && (
      <div className={ styles.vat }>
        <Text className={ styles.label } type='NORMAL_12' color='gray'>
          { LABELS.ADD_TAX }: { moneyFormatVat }
        </Text>
      </div>
    );
    const priceHtml = NoAvailabilityPeriods && NoAvailabilityPeriods.length ? (
      <Text type='NORMAL_14' className={ styles.contract_question }>
        <Icon type='question' />{ LABELS.RUB }
      </Text>
    ) : (
      <Price
        qaAttr={ QA_ATTRIBUTES.hotels.result.price }
        className={ styles.price_tooltip }
        value={ preparedPriceWithAgent }
        marginSmall
        type='bold_24'
      />
    );

    return (
      <div>
        { priceHtml }
        { vatHtml }
      </div>
    );
  };

  const renderPriceTooltipHtml = (Base: number, Commission: number, TotalPrice: number, debugInfo: string) => {
    const renderDebugInfo = () => {
      if (!debugInfo) return null;

      return (
        <div className={ styles.row }>
          <Text type='SEMIBOLD_16' className={ styles.text }>
            { debugInfo }
          </Text>
        </div>
      );
    };

    return (
      <div className={ styles['price-tooltip-container'] }>
        <div className={ styles.row }>
          <Text type='SEMIBOLD_16' className={ styles.text }>
            {LABELS.PRICE_WITHOUT_FEE}{' '}
          </Text>
          <Text className={ styles.text }>{MoneyFormat.moneyWithDecimal(calculatePriceValue(Base, travellersCount, roomCount), true)}</Text>
        </div>
        <div className={ styles.row }>
          <Text type='SEMIBOLD_16' className={ styles.text }>
            {LABELS.FEE}{' '}
          </Text>
          <Text className={ styles.text }>
            {MoneyFormat.moneyWithDecimal(calculatePriceValue(Commission, travellersCount, roomCount), true)}
          </Text>
        </div>
        <div className={ styles.row }>
          <Text type='SEMIBOLD_16' className={ styles.text }>
            {LABELS.FINAL_PRICE}{' '}
          </Text>
          <Text className={ styles.text }>
            {MoneyFormat.moneyWithDecimal(calculatePriceValue(TotalPrice, travellersCount, roomCount), true)}
          </Text>
        </div>
        { renderDebugInfo() }
      </div>
    );
  };

  const renderPrice = ({ rate: { Base = 0, Commission = 0, Total }, isContract, debugInfo = '' }: PreparedVariant) => {
    if (showPriceDetails && !isContract) {
      const renderContent = () => renderPriceTooltipHtml(Base, Commission, Total, debugInfo);

      return (
        <Tooltip position='left' renderContent={ renderContent }>
          { renderPriceHtml(Total) }
        </Tooltip>
      );
    }

    if (showPriceDetails && isContract) {
      return (
        <Tooltip position='left' renderContent={ renderContractTooltip }>
          { renderPriceHtml(Total) }
        </Tooltip>
      );
    }

    return renderPriceHtml(Total);
  };

  const queryParams = {
    CheckinDate: checkin.format(PATTERN.YEARMONTHDAY),
    CheckoutDate: checkout.format(PATTERN.YEARMONTHDAY),
    GuestsCount: adult,
    TravellersCount: travellersCount,
    RoomCount: roomCount,
    RegionName: region.label,
    RegionId: region.selected.Id,
    AddToHistory: false,
    ...prepareCustomCheckinCheckoutTime(customCheckin, customCheckout),
    RequestItemId: requestItemId,
    online: item.isContract ? false : onlineFilter,
    breakfast: prepareQueryMeal(Meal),
    hasCancellation: hasCancellationFilter,
    tripId: queryTripId,
    OptimalRate: convertToBase64(optimalRateQueryParams),
  };

  const hotelUri = `/search/hotel/${HotelId}`;

  const hotelUriSearchParams = stringifySearchParams(queryParams);
  const applyTP = applyHotelTravelPolicy(item, selectedTravelPolicy);
  const availableAmenities = item.static.Amenities
    ? Object.keys(item.static.Amenities).filter((k) => item.static.Amenities[k as keyof typeof item.static.Amenities])
    : [];

  const renderImage = () => {
    const items = [{ original: Thumbnail }];
    const maxHeightStyle = isFiltersHotelsInMicroservice ? '100%' : '230px';
    const imageStyles = {
      minHeight: '230px',
      maxHeight: maxHeightStyle,
      objectFit: 'cover',
    };

    return (
      <div className={ styles['img-wrapper'] }>
        <ImageGallery
          showThumbnails={ false }
          items={ items }
          originalImageStyles={ imageStyles }
        />
      </div>
    );
  };

  const renderNoPhoto = () => (
    <div className={ styles['no-photo'] }>
      <NoPhoto />
    </div>
  );

  const imageContent = Thumbnail && mainImageValid ? renderImage() : renderNoPhoto();

  const ratingContent = () => {
    if (!!rating && !!rating.Value) {
      return (
        <div className={ styles.rating }>
          <Link
            to={ `${hotelUri}?${hotelUriSearchParams}&Reviews=true` }
            target='_blank'
            onClick={ handleSendAmplitude }
          >
            <Rating
              qaAttr={ QA_ATTRIBUTES.hotels.result.rating }
              fullSize
              value={ rating }
              isFiltersHotelsInMicroservice={ isFiltersHotelsInMicroservice }
            />
          </Link>
        </div>
      );
    }

    return null;
  };

  const renderPriceLabel = () => {
    if (item.isContract) {
      return (
        <Tooltip position='left' renderContent={ renderContractTooltip }>
          <Text type='SEMIBOLD_16'>{ LABELS.CORPORATE_TARIFF }</Text>
        </Tooltip>
      );
    }

    if (isFiltersHotelsInMicroservice && Recommended) {
      return <Text type='NORMAL_14'>{ LABELS.OPTIMAL_PRICE }</Text>;
    }

    return <Text type='NORMAL_14'>{ LABELS.MINIMAL_PRICE }</Text>;
  };

  const renderAgentFee = () => {
    const { agentMode } = appService.get();

    if (!isSmartAgent) {
      return null;
    }

    return (
      <AgencyFee
        fee={ AgentFee }
        agentMode={ agentMode }
      />
    );
  };

  const renderActions = () => (
    <div className={ styles.actions }>
      <div className={ styles.price }>{ renderPriceLabel() }</div>
      { renderPrice(item) }
      { renderAgentFee() }
      <div className={ styles['days-amount'] }>{ renderDaysAmount() }</div>
      <div className={ styles.action }>
        <Link
          to={ `${hotelUri}?${hotelUriSearchParams}` }
          target='_blank'
          onClick={ handleSendAmplitude }
        >
          <Button qaAttr={ QA_ATTRIBUTES.hotels.result.chooseRoom } type='primary'>{ LABELS.CHOOSE_ROOM }</Button>
        </Link>
      </div>
    </div>
  );

  const renderRate = () => {
    if (!isFiltersHotelsInMicroservice) return null;

    return (
      <HotelItemRate
        rate={ item.rate }
        isMapView={ false }
        containerClassName={ styles.rate_wrap }
      />
    );
  };

  const newSearchRenderContent = () => {
    const styleMapButton = { marginTop: '5px' };

    return (
      <div className={ `${styles.rate_content} ${styles.content}` }>
        <div className={ styles.main }>
          <Link
            target='_blank'
            to={ `${hotelUri}?${hotelUriSearchParams}` }
            onClick={ handleSendAmplitude }
          >
            { imageContent }
          </Link>
          <div className={ `${styles.info} ${styles.start}` }>
            { ratingContent() }
            <div>
              <Text className={ styles.address } type='NORMAL_14'>{ Address }</Text>
              <div className={ styles.content_row }>
                <Text qaAttr={ QA_ATTRIBUTES.hotels.result.distance } type='NORMAL_14' className={ styles.distance }>
                  { LABELS.NEW_RANGE_TO_CENTER(region.label, DistanceFromCenter.toString()) }
                </Text>
                <ShowOnMapButton
                  style={ styleMapButton }
                  iconColor='blue'
                  onClick={ () => {
                    onOpenMap(item);
                    onShowHotelOnMap();
                  } }
                />
              </div>
            </div>
            <div className={ styles.content_box }>
              { renderRate() }
              { renderActions() }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const infoClassName = ratingContent() || !!availableAmenities.length ? styles.space : styles.end;
    const styleMapButton = { marginTop: '12px' };

    return (
      <div className={ styles.content }>
        <div className={ styles.main }>
          <Link target='_blank' to={ `${hotelUri}?${hotelUriSearchParams}` }>
            {imageContent}
          </Link>
          <div className={ `${styles.info} ${infoClassName}` }>
            <div> { ratingContent() } </div>
            <div>
              <Text type='NORMAL_14'>{Address}</Text>
              <Text qaAttr={ QA_ATTRIBUTES.hotels.result.distance } type='NORMAL_14' className={ styles.distance }>
                {LABELS.RANGE_TO_CENTER(region.label, DistanceFromCenter.toString())}
              </Text>
              <ShowOnMapButton
                style={ styleMapButton }
                onClick={ () => {
                  onOpenMap(item);
                  onShowHotelOnMap();
                } }
              />
            </div>
          </div>
        </div>
        {renderActions()}
      </div>
    );
  };

  const isFavoriteId = !isSmartAgent && !!favoriteId;
  const bookmarks = !aggregationId && !isSmartAgent && isPriceGuaranteed ? [{ text: LABELS.GUARANTEED }] : [];
  const content = isFiltersHotelsInMicroservice ? newSearchRenderContent() : renderContent();

  return (
    <ItemPanel
      warning={ applyTP }
      animationClass={ animationClass }
      className={ styles.item }
      favorite={ isFavoriteId }
      contract={ item.isContract }
      qaAttr={ qaAttribute }
      renderHeader={ renderHeader }
      onAnimationEnd={ handleAnimationEnd }
      bookmarks={ bookmarks }
    >
      { content }
    </ItemPanel>
  );
};

export { HotelItem };
