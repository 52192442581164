const ACTION = {
  ORDERLOAD: 'cart.load',
  UPDATE: 'cart.update',
  GET_FLIGHT_CERTIFICATE: 'GET_FLIGHT_CERTIFICATE',
  GET_ALL_STATUS_FLIGHT_CERTIFICATE: 'GET_ALL_STATUS_FLIGHT_CERTIFICATE',
  GET_REFUND_AIR: 'GET_REFUND_AIR',
  GET_REFUND_VIP: 'GET_REFUND_VIP',
  GET_REFUND_TRANSFER: 'GET_REFUND_TRANSFER',
  GET_PLAN_TRIP_DETAILS: 'GET_PLAN_TRIP_DETAILS',
  CREATE_PLAN_TRIP_EVENT: 'CREATE_PLAN_TRIP_EVENT',
  UPDATELINK: 'update.link',
  UPDATESHOWNLINK: 'update.shown.link',
  RESET_STORE: 'resetStore',
};

export default ACTION;
