import { observer } from 'mobx-react';
import React from 'react';
import { Paginate, Table, PageLoader } from 'new-ui';

import { ChartsAnalyticsSummaryStore } from '../../../../../bi/services/chartsAnalytics/stores/summary';

import { ChartAnalyticsNoResult } from '../../noResult';

import styles from './styles.module.css';

interface OtherNestedTableProp {
  store: ChartsAnalyticsSummaryStore,
  onPageChange: (number: number) => void,
}

const OtherNestedTable = observer(({ store, onPageChange }: OtherNestedTableProp) => {
  const {
    subTableInfo: {
      strings,
      columnsName,
    },
    paginate: {
      currentPage,
      itemsPerPage,
      total,
    },
    loading,
  } = store;

  if (loading) return <PageLoader />;

  if (!strings?.length) return <ChartAnalyticsNoResult />;

  return (
    <>
      <div className={ styles.table }>
        <Table
          labelsHeader={ columnsName }
        >
          { strings.map(({ data }, indexRow) => (
            <tr key={ indexRow }>
              { data.map((value, indexData) =>
                <td key={ indexData }>
                  <div className={ styles.value_wrap }>
                    { value }
                  </div>
                </td>)
              }
            </tr>
          )) }
        </Table>
      </div>
      <div className={ styles.footer }>
        <div className={ styles.paginate }>
          <Paginate
            page={ currentPage }
            itemsPerPage={ itemsPerPage }
            total={ total }
            onChange={ onPageChange }
          />
        </div>
      </div>
    </>
  );
});

export { OtherNestedTable };
