import axios from 'axios';
import { api } from '@sw/api';

import { axiosHeaders } from './utils';

import CONFIG from '../../../config';

export const axiosInstance = axios.create({
  baseURL: CONFIG.API_CLUSTER,
  headers: axiosHeaders,
});

axiosInstance.interceptors.request.use((req) => {
  req.headers.set(axiosHeaders);

  return req;
});

export const apiNext = api(axiosInstance);
