import React from 'react';
import { Text } from 'new-ui';
import { getTextArray } from '../../../../../i18n';

import toDecline from '../../../../bi/utils/toDecline';
import { formatDate, formatMomentUtcObject, momentObjectUTC } from '../../../../bi/utils/formatDate';

import { PATTERN } from '../../../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { HistoryObj } from '../../../../bi/services/hotels/types';

import styles from './styles/index.module.css';

const parseTime = (string: string) => string.split(':');

const prepareCustomDate = (defaultDate: string, customDate: string) => {
  const preparedDefault = momentObjectUTC(defaultDate);
  const [hour, minute] = parseTime(formatMomentUtcObject(customDate, PATTERN.LOCAL_TIME_PATTERN));

  return preparedDefault.clone().set({ hour: Number(hour), minute: Number(minute) });
};

const LABELS = {
  TRAVELLERS_DECLINE: getTextArray('search:hotel.history.travellersDecline'),
};

interface HotelsHistoryItemProps {
  item: HistoryObj
}

const HotelsHistoryItem = ({
  item: {
    CheckInTime,
    CheckinDate,
    CheckoutDate,
    CheckOutTime,
    TravellersCount,
    Name },
}: HotelsHistoryItemProps) => {
  const preparedCheckin = CheckInTime
    ? prepareCustomDate(CheckinDate, CheckInTime).format(PATTERN.DAY_OF_MONTH_TIME)
    : formatDate(CheckinDate);

  const preparedCheckout = CheckOutTime
    ? prepareCustomDate(CheckoutDate, CheckOutTime).format(PATTERN.DAY_OF_MONTH_TIME)
    : formatDate(CheckoutDate);

  const dateRange = `${preparedCheckin} – ${preparedCheckout}`;
  const travellersCount = `${TravellersCount} ${toDecline(TravellersCount, LABELS.TRAVELLERS_DECLINE)}`;

  return (
    <div className={ styles.wrap }>
      <div>
        <Text type='NORMAL_14' className={ styles.date } qaAttr={ QA_ATTRIBUTES.hotels.search.fromHistory.item.date }>
          { dateRange }
        </Text>
        <Text type='NORMAL_14' className={ styles.name } qaAttr={ QA_ATTRIBUTES.hotels.search.fromHistory.item.name }>
          { Name }
        </Text>
      </div>
      <Text type='NORMAL_14' className={ styles.adult }>
        { travellersCount }
      </Text>
    </div>
  );
};

export { HotelsHistoryItem };
