import React, { ReactNode, forwardRef } from 'react';
import { Text } from 'new-ui';

import styles from './index.module.css';

interface IStep {
  title: string,
  children: ReactNode,
  isActive?: boolean,
  onClick(): void,
  withBackground?: boolean,
  className?: string,
  qaAttr?: string,
}

const Step = forwardRef<any, IStep>(({
  title,
  children,
  isActive = false,
  onClick = () => {},
  withBackground = true,
  className = '',
  qaAttr = '',
}, ref) => {
  const circleClassNames = [styles.circle];
  const contentClassNames = [styles.content];
  const stepClassNames = [styles.step];

  if (className) {
    stepClassNames.push(className);
  }

  if (isActive) {
    circleClassNames.push(styles.active);
  }

  if (withBackground) {
    contentClassNames.push(styles.background);
  }

  return (
    <div
      className={ stepClassNames.join(' ') }
      onClick={ onClick }
      ref={ ref }
    >
      <div className={ styles.nav }>
        <div className={ circleClassNames.join(' ') } />
        <div className={ styles.line } />
      </div>
      <div className={ styles.wrap }>
        <Text
          qaAttr={ qaAttr }
          className={ styles.title }
          type='bold_24'
        >
          { title }
        </Text>
        <div className={ contentClassNames.join(' ') }>
          { children }
        </div>
      </div>
    </div>
  );
});

const Stepper = ({ children }: { children: ReactNode }) => (
  <div className={ styles.stepper }>
    { children }
  </div>
);

export { Step, Stepper };
