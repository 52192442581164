import React from 'react';
import {
  Text,
  Dialog,
  Button,
} from 'new-ui';
import { getText } from '../../../../../i18n';

import styles from './styles.module.css';

const LABELS = {
  DELETE_VALUE_ANALYTICS: getText('analytics:deleteValueAnalytics'),
  CONFIRM: getText('analytics:confirm'),
  CANCEL: getText('analytics:cancel'),
};

interface DeleteDialogProps {
  showDialog?: boolean,
  onChange?: () => void,
  onDelete?: () => void,
}

const DeleteDialog = ({
  onDelete = () => {},
  onChange = () => {},
  showDialog = false,
}: DeleteDialogProps) => (
  <Dialog
    show={ showDialog }
    showClosing
    onChange={ onChange }
  >
    <div className={ styles.delete_dialog }>
      <Text
        className={ styles.text }
        type='NORMAL_14_130'
      >
        {LABELS.DELETE_VALUE_ANALYTICS}
      </Text>
      <div className={ styles.buttons }>
        <Button onClick={ onDelete }>
          { LABELS.CONFIRM }
        </Button>
        <Button
          className={ styles.cancel_button }
          type='textual-medium'
          onClick={ onChange }
        >
          { LABELS.CANCEL }
        </Button>
      </div>
    </div>
  </Dialog>
);

export default DeleteDialog;
