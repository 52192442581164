import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Dialog, Text, Button, Price, NoResults } from 'new-ui';

import { getText, getTextArray, currentLng } from '../../../../../i18n';

import { SearchLoading } from '../../../../components/SearchLoading';

import {
  AEROEXPRESS_LATE_TEXT,
  AEROEXPRESS_TARIFF_MAP,
  AEROEXPRESS_TARIFF_TYPES,
  GET_AEROEXPRESS_BUSINESS_TWOWAY,
  AEROEXPRESS_SHEREMETYEVO,
  AEROEXPRESS_TARIFF_DESCRIPTIONS,
  IAeroexpressPricingResponseMapped,
} from '../../../../bi/services/aeroexpress/const';

import toDecline from '../../../../bi/utils/toDecline';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { ANALYTIC_SERVICE_TYPES } from '../../../../bi/constants/serviceType';
import { IDS_FOR_INTEGRATION_TESTS } from '../../../../bi/constants/idsForInregrationTests';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { IAeroexpressSearchResult, ISearchSettings } from '../../../../bi/types/aeroExpress';

import styles from './styles/index.module.css';

import aeroexpressOdincovo from './styles/images/aeroexpress_odincovo.png';
import aeroexpressRu from './styles/images/logo.svg';
import aeroexpressEn from './styles/images/logo_en.png';

const LABELS = {
  SEARCH: getText('aeroexpress:result.search'),
  ADD_TO_CART: getText('aeroexpress:result.toCart'),
  TICKETS: getTextArray('aeroexpress:result.ticketsDeclines'),
  CURRENCY: getText('aeroexpress:result.currency'),
  PER: getText('aeroexpress:result.per'),
  RATE: getText('aeroexpress:result.rate'),
  SCHEME_TITLE: getText('aeroexpress:result.scheme.title'),
  IMG_ALT: getText('aeroexpress:result.scheme.imgAlt'),
};

interface IAeroexpressSearchResultProps {
  show: boolean;
  onClose: () => void;
  result: IAeroexpressSearchResult;
  settings: ISearchSettings;
  onAddToCart: (ids: string[]) => void;
  addingToCartLoading: boolean;
  qaAttrs: Record<string, string> | null;
}

const AeroexpressSearchResult = observer(({
  addingToCartLoading,
  show,
  result,
  settings,
  qaAttrs,
  onClose,
  onAddToCart,
}: IAeroexpressSearchResultProps) => {
  useEffect(() => {
    if (show) {
      MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_SCREEN_OPENED(ANALYTIC_SERVICE_TYPES.AERO_EXPRESS),
      );
    }
  }, [show]);

  const renderLoading = () => <SearchLoading text={ LABELS.SEARCH } qaAttrLoadingText={ QA_ATTRIBUTES.search.aeroexpress.loading } />;

  const renderDescription = (string: string) => {
    const content = string.split('\n').map((value, index) => (
      <Text className={ styles.text } type='NORMAL_14' key={ index }>{ value }</Text>
    ));

    return (
      <div className={ styles.description }>
        { content }
      </div>
    );
  };

  const renderRateTitle = (type: string) => (
    <div className={ styles.rate }>
      <Text type='NORMAL_16'>{ LABELS.RATE }</Text>
      <Text
        className={ styles.type }
        type='SEMIBOLD_16'
        qaAttr={ qaAttrs?.tariff || '' }
      >
        { AEROEXPRESS_TARIFF_MAP[type] }
      </Text>
    </div>
  );

  const renderFewTickets = (items: IAeroexpressPricingResponseMapped[]) => {
    const sortedByDepartureDate = items.sort((a, b) =>
      new Date(a.departure_date).getTime() - new Date(b.departure_date).getTime());

    const { type, departure_date: departure } = sortedByDepartureDate[0];
    const { departure_date: back } = sortedByDepartureDate[1];
    const descriptionContent = type === AEROEXPRESS_TARIFF_TYPES.STANDARD
      ? AEROEXPRESS_TARIFF_DESCRIPTIONS[AEROEXPRESS_TARIFF_TYPES.TWOWAY](departure, back)
      : GET_AEROEXPRESS_BUSINESS_TWOWAY(departure, back);

    return (
      <div className={ styles.ticket }>
        { renderRateTitle(type) }
        { renderDescription(descriptionContent) }
        <Text
          className={ styles.business_text }
          type='NORMAL_14'
        >
          { AEROEXPRESS_LATE_TEXT }
        </Text>
      </div>
    );
  };

  const renderOneTicket = (ticket: IAeroexpressPricingResponseMapped) => {
    const { type, description } = ticket;

    return (
      <div className={ styles.ticket }>
        { renderRateTitle(type) }
        { renderDescription(description) }
        <Text
          className={ styles.business_text }
          type='NORMAL_14'
        >
          { AEROEXPRESS_LATE_TEXT }
        </Text>
      </div>
    );
  };

  const renderScheme = () => {
    const { route: { code } } = settings;

    if (code !== AEROEXPRESS_SHEREMETYEVO || currentLng !== 'ru') {
      return null;
    }

    return (
      <div className={ styles.scheme }>
        <Text type='SEMIBOLD_16'>{ LABELS.SCHEME_TITLE }</Text>
        <div className={ styles['image-wrap'] }>
          <img
            className={ styles.image }
            src={ aeroexpressOdincovo }
            alt={ LABELS.IMG_ALT }
          />
        </div>
      </div>
    );
  };

  const renderResult = () => {
    const { items, error } = result;

    if (error) {
      return (
        <div className={ styles.not_found }>
          <NoResults label={ error } />
        </div>
      );
    }

    const { count, amount, ids } = items.reduce((acc, curr) =>
      ({
        count: Number(acc.count) + Number(curr.count),
        amount: Number(acc.amount) + Number(curr.amount),
        ids: [...acc.ids, curr.id],
      }),
    { count: 0, amount: 0, ids: [] } as { count: number, amount: number, ids: string[] });

    const ticketContent = items.length > 1 ? renderFewTickets(items.slice()) : renderOneTicket(items[0]);
    const logoImgType = currentLng === 'ru' ? aeroexpressRu : aeroexpressEn;

    return (
      <>
        <div className={ styles.results }>
          <img src={ logoImgType } alt='aeroexpress-logo' className={ styles.logo } />
          <div
            className={ styles.tickets }
          >
            { ticketContent }
          </div>
          <div className={ styles.action }>
            <Price
              value={ amount }
              type='bold_24'
              className={ styles.amount }
              qaAttr={ qaAttrs?.price || '' }
            />
            <Text
              className={ styles.count }
              color='gray'
              type='NORMAL_12'
              qaAttr={ qaAttrs?.count || '' }
            >
              { LABELS.PER } {`${count} ${toDecline(count, LABELS.TICKETS)}`}
            </Text>
            <Button
              type='secondary'
              loading={ addingToCartLoading }
              disabled={ addingToCartLoading }
              onClick={ () => onAddToCart(ids) }
              // @ts-ignore
              id={ IDS_FOR_INTEGRATION_TESTS.TO_CART.AEROEXPRESS }
              qaAttr={ qaAttrs?.button || '' }
            >
              { LABELS.ADD_TO_CART }
            </Button>
          </div>
        </div>
        { renderScheme() }
      </>
    );
  };

  const { loading } = result;

  const resultHtml = loading ? renderLoading() : renderResult();

  return (
    <Dialog
      className={ styles.dialog }
      onChange={ onClose }
      show={ show }
      showClosing
      qaAttr={ qaAttrs?.close }
    >
      <div className={ styles.content }>
        { resultHtml }
      </div>
    </Dialog>
  );
});

export { AeroexpressSearchResult };
