import React, { FC, useState } from 'react';
import { Button, Datepicker, Dialog, LinkButton, Text, RadioButton } from 'new-ui';
import { Moment } from 'moment';

import { getText } from '../../../../../i18n';

import {
  getMoment,
  getStartPreviousMonth,
  getStartCurrentQuarter,
  getStartPreviousQuarter,
  startOfMonth,
  isSameOrAfter,
  endPreviousDateMomentWithTodayMax,
} from '../../../../bi/utils/formatDate';
import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('trips:dialog.title'),
  CURRENT_MONTH: getText('trips:dialog.currentMonth'),
  PREVIOUS_MONTH: getText('trips:dialog.previousMonth'),
  CURRENT_QUARTER: getText('trips:dialog.currentQuarter'),
  PREVIOUS_QUARTER: getText('trips:dialog.previousQuarter'),
  CANCEL: getText('common:cancel'),
  DOWNLOAD: getText('trips:dialog.download'),
  WARNING: getText('trips:dialog.warning'),
  ERROR: getText('trips:dialog.error'),
  DATE_TRIP: getText('trips:dialog.dateTrip'),
  DATE_BOOK: getText('trips:dialog.dateBook'),
};

const ANALITYCS_VALUE = {
  TRIP_DATE: 'trip_date',
  BOOKING_DATE: 'booking_date',
};

enum RADIO_VALUES {
  TRAVEL_DATE = '0',
  SELL_DATE = '1',
}

interface DownloadDialogProps {
  onDownload(start: Moment, end: Moment, radioField: string): Promise<void>;
  onClose(): void;
}

const DownloadDialog: FC<DownloadDialogProps> = ({
  onDownload,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [error, setError] = useState(false);
  const [startDate, setStartDate] = useState(startOfMonth());
  const [endDate, setEndDate] = useState(endPreviousDateMomentWithTodayMax());
  const [radioField, setRadioField] = useState<RADIO_VALUES>(RADIO_VALUES.TRAVEL_DATE);

  const handleStartDate = (value: Moment) => {
    setStartDate(value);

    if (isSameOrAfter(value, getMoment())) {
      setEndDate(value);

      return setWarning(true);
    }

    return setWarning(false);
  };

  const handleEndDate = (value: Moment) => {
    setEndDate(value);

    if (isSameOrAfter(value, getMoment())) {
      return setWarning(true);
    }

    return setWarning(false);
  };

  const handleCurrentMonthToYesterday = () => {
    setStartDate(startOfMonth());
    setEndDate(endPreviousDateMomentWithTodayMax());
    setWarning(false);
  };

  const handlePreviousMonthToYesterday = () => {
    setStartDate(getStartPreviousMonth());
    setEndDate(endPreviousDateMomentWithTodayMax());
    setWarning(false);
  };

  const handleCurrentQuarterToYesterday = () => {
    setStartDate(getStartCurrentQuarter());
    setEndDate(endPreviousDateMomentWithTodayMax());
    setWarning(false);
  };

  const handlePreviousQuarterToYesterday = () => {
    setStartDate(getStartPreviousQuarter());
    setEndDate(endPreviousDateMomentWithTodayMax());
    setWarning(false);
  };

  const handleDownload = async () => {
    setLoading(true);
    setError(false);

    await onDownload(startDate, endDate, radioField).catch(() => setError(true));

    const formatDate = !Number(radioField) ? ANALITYCS_VALUE.TRIP_DATE : ANALITYCS_VALUE.BOOKING_DATE;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_REPORT_PRESSED(formatDate),
    );

    setLoading(false);
  };

  const warningHtml = warning && (
    <Text type='NORMAL_14_130' color='gray'>
      { LABELS.WARNING }
    </Text>
  );

  const errorHtml = error && (
    <div className={ styles.error }>
      <Text type='NORMAL_14_130' color='red'>
        { LABELS.ERROR }
      </Text>
    </div>
  );

  const renderRadio = () => (isSmartAgent ? (
    <div className={ styles.radios }>
      <div className={ styles.radio }>
        <RadioButton
          value={ RADIO_VALUES.TRAVEL_DATE }
          checked={ radioField === RADIO_VALUES.TRAVEL_DATE }
          alternativeDesign={ isSmartAgent }
          onChange={ () => setRadioField(RADIO_VALUES.TRAVEL_DATE) }
        >
          <Text>{LABELS.DATE_TRIP}</Text>
        </RadioButton>
      </div>
      <div className={ styles.radio }>
        <RadioButton
          value={ RADIO_VALUES.SELL_DATE }
          checked={ radioField === RADIO_VALUES.SELL_DATE }
          alternativeDesign={ isSmartAgent }
          onChange={ () => setRadioField(RADIO_VALUES.SELL_DATE) }
        >
          <Text>{ LABELS.DATE_BOOK }</Text>
        </RadioButton>
      </div>
    </div>
  ) : null);

  return (
    <Dialog
      show
      className={ styles.wrapper }
      onChange={ onClose }
    >
      <Text type='normal_20'>
        { LABELS.TITLE }
      </Text>
      { renderRadio() }
      <div className={ styles.calendars_wrap }>
        <Datepicker
          value={ startDate }
          max={ endDate }
          inputClassName={ styles.input_wrap }
          inputTheme='open'
          onChange={ handleStartDate }
          isDuration
          durationDates={ [startDate, endDate] }
          modalClassName={ styles.wrapper }
        />
        &mdash;
        <Datepicker
          value={ endDate }
          min={ startDate }
          inputClassName={ styles.input_wrap }
          inputTheme='open'
          onChange={ handleEndDate }
          isDuration
          durationDates={ [startDate, endDate] }
          modalClassName={ styles.wrapper }
        />
      </div>
      <div className={ styles.links }>
        <LinkButton
          className={ styles.link }
          theme='small-default'
          onClick={ handleCurrentMonthToYesterday }
        >
          { LABELS.CURRENT_MONTH }
        </LinkButton>
        <LinkButton
          className={ styles.link }
          theme='small-default'
          onClick={ handlePreviousMonthToYesterday }
        >
          { LABELS.PREVIOUS_MONTH }
        </LinkButton>
        <LinkButton
          className={ styles.link }
          theme='small-default'
          onClick={ handleCurrentQuarterToYesterday }
        >
          { LABELS.CURRENT_QUARTER }
        </LinkButton>
        <LinkButton
          theme='small-default'
          onClick={ handlePreviousQuarterToYesterday }
        >
          { LABELS.PREVIOUS_QUARTER }
        </LinkButton>
      </div>
      { warningHtml }
      <div className={ styles.action }>
        <Button
          className={ styles.button }
          loading={ loading }
          onClick={ handleDownload }
        >
          { LABELS.DOWNLOAD }
        </Button>
        <Button
          type='textual'
          className={ styles.button }
          onClick={ () => onClose() }
        >
          { LABELS.CANCEL }
        </Button>
      </div>
      { errorHtml }
    </Dialog>
  );
};

export { DownloadDialog };
