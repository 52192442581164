import React from 'react';
import { Button } from 'new-ui';
import { getText } from '../../../../../i18n';

import type {
  PolicyRuleWrapperProps,
} from './types';

import { PolicyRuleHeader } from '../PolicyRuleHeader';
import { GeneralRules } from '../GeneralRules';
import { DirectionsWrapper } from '../DirectionsWrapper';
import { ExceptionsWrapper } from '../ExceptionsWrapper';

import {
  TRAVEL_POLICY_GENERAL_FIELDS_TO_BE_RENDERED,
  TRAVEL_POLICY_STORE_FIELDS,
} from '../../../../bi/services/travelPolicy/consts';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './index.module.css';

const LABELS = {
  ADD_RULES: getText('settings:travelPolicy.addRules'),
};

const { addRules } = QA_ATTRIBUTES.settings.travelPolicy;

const getQaAttrValue = (type: string) =>
  addRules[type.toLowerCase() as keyof typeof addRules];

const prepareJustGeneralFields = (type: string, store: any) => {
  const obj: any = {};

  TRAVEL_POLICY_GENERAL_FIELDS_TO_BE_RENDERED[type].forEach((f) => {
    obj[f] = { ...store[f] };
  });

  return obj;
};

const PolicyRulesWrapper = ({
  isUnique = false,
  store,
  name,
  type,
  suggestions,
  onChange = () => {},
  onAutocompleteChange = () => {},
  onAutocompleteClear = () => {},
}: PolicyRuleWrapperProps) => {
  const { Apply } = store;

  const renderContent = () => {
    if (!Apply && !isUnique) {
      const qaAttrValue = getQaAttrValue(type);

      return (
        <Button
          onClick={ () => onChange({ [TRAVEL_POLICY_STORE_FIELDS.APPLY]: true }) }
          qaAttr={ typeof qaAttrValue === 'string'
            ? qaAttrValue
            : undefined }
        >
          { LABELS.ADD_RULES }
        </Button>
      );
    }

    const directionsWrapperCheck = store[TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS] && (
      <DirectionsWrapper
        isUnique={ isUnique }
        value={ store[TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS] }
        type={ type }
        suggestions={ suggestions }
        name={ name }
        onChange={ (value: any) => onChange({ [TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS]: { ...value } }) }
        onAutocompleteChange={ onAutocompleteChange }
        onAutocompleteClear={ onAutocompleteClear }
      />
    );

    const exceptionsWrapperCheck = store[TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS] && (
      <ExceptionsWrapper
        isUnique={ !!isUnique }
        value={ store[TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS] }
        generalFieldsStore={ prepareJustGeneralFields(type, store) }
        type={ type }
        name={ name }
        suggestions={ suggestions }
        onChange={ (value: any) => onChange({ [TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS]: { ...value } }) }
        onAutocompleteChange={ onAutocompleteChange }
        onAutocompleteClear={ onAutocompleteClear }
      />
    );

    return (
      <>
        <div className={ styles.main }>
          <GeneralRules
            isUnique={ isUnique }
            store={ store }
            onChange={ onChange }
            type={ type }
          />
          { directionsWrapperCheck }
        </div>
        { exceptionsWrapperCheck }
      </>
    );
  };

  return (
    <div className={ styles.wrapper }>
      <PolicyRuleHeader
        isUnique={ !!isUnique }
        name={ name }
        apply={ Apply }
        serviceType={ type }
      />
      { renderContent() }
    </div>
  );
};

export { PolicyRulesWrapper };
