import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { StyledWrapper, Text, Icon, TextColor } from 'new-ui';

import { getText } from '../../../i18n';

import { MOBX_STORES } from '../../bi/context/stores';
import { withStores } from '../../bi/context';

import { Employees } from './components/Employees';
import { TravelPoliciesTab } from './components/TravelPoliciesTab';
import Analytics from './components/analytics';
import { ApplicationSchemeSettings } from './components/ApplicationSchemeTab';
import { Companies } from './components/Companies';
import { Profile } from './components/Profile';
import { IntegrationTab } from './components/IntegrationTab';
import { DepartmentsTab } from './components/DepartmentsTab';
import { ApprovalSchemesTab } from './components/ApprovalSchemesTab';
import { ApprovalERSchemesTab } from './components/ApprovalERSchemesTab';
import { BonusProgram } from './components/BonusProgramPage';
import ExpenseReports from './components/ExpenseReports';
import { Vouchers } from './components/Vouchers';
import { FeeConstruction } from './components/FeeConstruction';
import { DocumentsForSignature } from './components/DocumentsForSignature';
import { EmployeePersonalAccount } from './components/EmployeePersonalAccount';
import { ReplishmentBalance } from './components/ReplishmentBalance';
import { DocumentsGosSign } from './components/DocumentsGosSign';

import { isSmartAgent } from '../../bi/utils/env';
import { MainAnalytic } from '../../bi/utils/analytics';
import { configDocumentsForSign } from './components/DocumentsForSignature/config';
import { configDocumentsGosSign } from './components/DocumentsGosSign/config';

import ROUTES from '../../bi/constants/routes';
import { BUYTRIPSACCOUNTRIGHT, EDITEMPLOYEERIGHT } from '../../bi/constants/rights';
import { SETTINGS, KEYS, SettingsKeysType } from '../../bi/constants/settings';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { ISettingsPageProps, ISettingsPageState, ItemMenu, ItemMenuLeaf } from './types';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('settings:title'),
};

const getPage = (id: string) => ({
  [KEYS.EMPLOYEES]: Employees,
  [KEYS.DEPARTMENTS]: DepartmentsTab,
  [KEYS.TRAVEL_POLICIES]: TravelPoliciesTab,
  [KEYS.ANALYTICS]: Analytics,
  [KEYS.APPROVAL_SCHEMES]: ApprovalSchemesTab,
  [KEYS.APPROVAL_SCHEMES_EXPENSE_REPORTS]: ApprovalERSchemesTab,
  [KEYS.COMPANIES]: Companies,
  [KEYS.S7]: BonusProgram,
  [KEYS.MY_DATA]: Profile,
  [KEYS.INTEGRATION]: IntegrationTab,
  [KEYS.EXPENSE_REPORT]: ExpenseReports,
  [KEYS.APPLICATION_SCHEME]: ApplicationSchemeSettings,
  [KEYS.VOUCHERS]: Vouchers,
  [KEYS.FEE_CONSTRUCTOR]: FeeConstruction,
  [KEYS.REPLISHMENT_BALANCE]: ReplishmentBalance,
  [KEYS.DOCUMENTS_FOR_SIGNATURE]: DocumentsForSignature,
  [KEYS.DOCUMENTS_GOS_SIGN]: DocumentsGosSign,
  [KEYS.EMPLOYEE_PERSONAL_ACCOUNT]: EmployeePersonalAccount,
}[id]);

// @ts-ignore
@withStores([MOBX_STORES.UI_SETTINGS, MOBX_STORES.SETTINGS_STORE])
@observer
class SettingsPage extends Component<ISettingsPageProps, ISettingsPageState> {
  state = {
    active: KEYS.EMPLOYEES,
  };

  componentDidMount() {
    this.setActiveTab();
    this.props.settingsService.getCompanies();
    this.props.settingsService.getBrandName();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.SETTINGS_OPEN);
  }

  setActiveTab = () => {
    const { match: { params: { tabId } } } = this.props;

    if (!tabId) {
      return;
    }

    const isValidTabId = Object
      .keys(KEYS)
      .some((key) => KEYS[key as keyof SettingsKeysType] === tabId);

    if (!isValidTabId) {
      return;
    }

    this.setState({
      active: tabId,
    });
  };

  handleMoveBack = () =>
    this.props.history.goBack();

  handleChangePage = (id: string) => this.setState({
    active: id,
  }, () => this.props.history.push(`${ROUTES.SETTINGS.MAIN}/${id}`));

  renderItemMenu = (data: ItemMenu) => {
    const { title, id, items } = data;
    const { active } = this.state;

    if (items.length === 0) {
      const selectColor = isSmartAgent ? 'analog-green' : 'red';
      const color = id === active ? selectColor : 'default';

      return (
        <div
          data-qa={ `${QA_ATTRIBUTES.settings.menuItem.item}_${id}` }
          className={ styles.item }
          key={ id }
          onClick={ () => this.handleChangePage(id) }
        >
          <Text
            type='SEMIBOLD_14'
            color={ color as TextColor }
          >
            { title }
          </Text>
        </div>
      );
    }

    const itemHtml = (item: ItemMenuLeaf) => {
      if (!item) {
        return null;
      }

      const { title: itemTitle, id: itemId } = item;
      const color = active === itemId ? 'red' : 'default';

      return (
        <div
          key={ itemId }
          data-qa={ `${QA_ATTRIBUTES.settings.menuItem.sub}_${itemId}` }
          className={ styles.item_sub_menu }
          onClick={ () => this.handleChangePage(itemId) }
        >
          <Text type='SEMIBOLD_14' color={ color as TextColor }>
            { itemTitle }
          </Text>
        </div>
      );
    };

    return (
      <div
        key={ id }
        data-qa={ `${QA_ATTRIBUTES.settings.menuItem.item}_${id}` }
        className={ styles.sub_item }
      >
        <div
          className={ styles.title_items }
          data-qa={ `${QA_ATTRIBUTES.settings.menuItem.itemTitle}_${id}` }
          onClick={ () => this.handleChangePage(items[0].id) }
        >
          <Text type='SEMIBOLD_14'>
            { title }
          </Text>
          <Icon type='arrowsUpGray' className={ styles.icon } color='gray' />
        </div>
        <div className={ styles.items }>
          { items.map(itemHtml) }
        </div>
      </div>
    );
  };

  render() {
    const {
      workspaceService,
      featureFlagsService,
      personalPaymentProtocol,
      featureFlagsService: {
        getShowExpenseReportApprove,
        getShowElementForSmartagent,
      },
      accountSettings: {
        getHidePerDiem,
      },
      stores: {
        uiSettingsStore: {
          settings: {
            showCompanyInfo,
          },
        },
      },
    } = this.props;
    const { active } = this.state;

    const {
      ShowTravelPoilcy,
      Rights: {
        EditTravelPolicies,
        EditEmployee,
        BuyTripAccount,
      },
    } = workspaceService.get();
    const paymentMethods = personalPaymentProtocol.canPayWithPersonalFunds;

    const featureFlagsTP = ShowTravelPoilcy || EditTravelPolicies;
    const canEditEmployees = EditEmployee === EDITEMPLOYEERIGHT.All;
    const showExpenseReportsApprove = getShowExpenseReportApprove();
    const { SimpleSignature } = featureFlagsService.getFeatureFlags();

    const listHtml = [
      ...SETTINGS(showExpenseReportsApprove, isSmartAgent, paymentMethods),
      ...configDocumentsForSign(configDocumentsGosSign()),
    ].reduce((acc: any, item) => {
      const { id } = item;

      const changedHidden = () => {
        switch (id) {
          case KEYS.DEPARTMENTS: {
            return isSmartAgent || !canEditEmployees;
          }
          case KEYS.ANALYTICS: {
            return isSmartAgent ||
              BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unavailable ||
              EditEmployee !== EDITEMPLOYEERIGHT.All;
          }
          case KEYS.TRAVEL_POLICIES: {
            return isSmartAgent || !featureFlagsTP;
          }
          case KEYS.EXPENSE_REPORT: {
            return isSmartAgent || (!workspaceService.isAdmin && !workspaceService.canViewReports) || getHidePerDiem();
          }
          case KEYS.COMPANIES: {
            return !showCompanyInfo;
          }
          case KEYS.VOUCHERS: {
            return !isSmartAgent || !workspaceService.isAdmin;
          }
          case KEYS.FEE_CONSTRUCTOR: {
            return !isSmartAgent || !workspaceService.isAdmin;
          }
          case KEYS.REPLISHMENT_BALANCE: {
            return !isSmartAgent || !workspaceService.canViewReports || !getShowElementForSmartagent();
          }
          case KEYS.DOCUMENTS_FOR_SIGNATURE: {
            return isSmartAgent || !SimpleSignature;
          }
          case KEYS.S7:
          case KEYS.INTEGRATION:
          case KEYS.APPROVAL_SCHEMES: {
            return isSmartAgent || !workspaceService.isAdmin;
          }
          case KEYS.APPLICATION_SCHEME: {
            return isSmartAgent || !workspaceService.isAdmin;
          }
          default: {
            return false;
          }
        }
      };

      if (!changedHidden()) {
        return [...acc, this.renderItemMenu(item as ItemMenu)];
      }

      return acc;
    }, []);

    const ItemComponent = getPage(active);

    return (
      <div className={ styles.wrapper }>
        <Text
          qaAttr={ QA_ATTRIBUTES.settings.title }
          type='bold_32'
        >
          { LABELS.TITLE }
        </Text>
        <div className={ styles.settings }>
          <StyledWrapper className={ styles.menu }>
            { listHtml }
          </StyledWrapper>
          <div className={ styles.page }>
            <ItemComponent
              { ...this.props }
              featureFlagsTP={ featureFlagsTP }
              // @ts-ignore
              moveBack={ this.handleMoveBack }
              isDemo={ workspaceService.isDemo }
            />
          </div>
        </div>
      </div>
    );
  }
}

export { SettingsPage };
