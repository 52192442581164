import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { StyledWrapper, BackLink, Text, Button, DotLoading } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import UniqueTravelPolicyImg from '../../../../../images/unique-travel-policy.png';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import { TravelPolicyServiceType } from '../../../../bi/services/travelPolicy';
import PopupsService from '../../../../bi/services/popups';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import ROUTES from '../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { POPUPS_KIND } from '../../../../bi/constants/popups';
import { UNIQUE_FORMED_TP } from '../../../../bi/constants/travelPolicy';

import { travelPolicyRules } from '../../../../bi/utils/travelPolicy';

import { PolicyRulesWrapper } from '../PolicyRulesWrapper';
import { EmployeeDialog } from '../EmployeeDialog';

import { ServiceTypeSmall } from '../../../../bi/types/order';

import styles from './index.module.css';

const LABELS = {
  BACK: getText('services:app.menuItems.smartdesk'),
  EDIT: getText('settings:travelPolicy.edit'),
  SAVE: getText('settings:travelPolicy.save'),
};

interface ITravelPolicyUnique {
  travelPolicyService: TravelPolicyServiceType,
  popupsService: PopupsService,
  accountSettingsService: any,
}

const TravelPolicyUnique = observer(({
  travelPolicyService,
  accountSettingsService,
  popupsService,
}: ITravelPolicyUnique) => {
  const { travelPolicyStore } = useStores([MOBX_STORES.TRAVEL_POLICY]);

  const [showEmployeeDialog, setShowEmployeeDialog] = useState(false);

  const history = useHistory();

  const {
    store: {
      name,
      id,
    },
    suggestions,
    employees,
    isSelectAll,
    uniqueTravelPolicy: {
      loading,
      wasNoQuestionnaire,
      formedTP,
      headerText: {
        header,
        subTextOne,
        subTextTwo,
      },
    },
  } = travelPolicyStore;

  useEffect(() => {
    if (formedTP === UNIQUE_FORMED_TP.NEW) {
      travelPolicyService.getUniqueTravelPolicy();
    } else {
      travelPolicyService.getDoneUniqueTravelPolicy();
    }
  }, []);

  const noBookingTaxi = accountSettingsService.getBookingTaxi();

  if (loading) {
    return <div className={ styles.loading }>
      <DotLoading />
    </div>;
  }

  const {
    setEmployeesSelected,
    setEmployeeAutocompleteList,
    setSelectAll,
    saveUniqueTravelPolicy,
    deleteQuestions,
    closePopupState,
    setResetUniqueTravelPolicy,
    airTravelPolicyStore,
    trainTravelPolicyStore,
    hotelTravelPolicyStore,
    taxiTravelPolicyStore,
    transferTravelPolicyStore,
    vipHallTravelPolicyStore,
  } = travelPolicyService;

  const preparedTravelPolicyRules = travelPolicyRules({
    airTravelPolicyStore,
    trainTravelPolicyStore,
    hotelTravelPolicyStore,
    taxiTravelPolicyStore,
    transferTravelPolicyStore,
    vipHallTravelPolicyStore,
  });

  const handleShowEmployeeDialog = () => {
    setShowEmployeeDialog(true);
    setEmployeeAutocompleteList('');
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.TRAVEL_POLICY.FORMED_TP_SAVE, { formedTP });
  };

  const handleSave = async () => {
    try {
      await saveUniqueTravelPolicy();

      const preparedPop = formedTP === UNIQUE_FORMED_TP.NEW ?
        POPUPS_KIND.TRAVEL_POLICIES_FIRST_RUN :
        POPUPS_KIND.TRAVEL_POLICIES_SECOND_RUN;

      deleteQuestions();
      setShowEmployeeDialog(false);
      closePopupState(preparedPop);
      setResetUniqueTravelPolicy();
      popupsService.setShowTravelPopup(false);
      history.push(ROUTES.SETTINGS.TRAVEL_POLICIES);
    } catch {
      setShowEmployeeDialog(false);
      setResetUniqueTravelPolicy();
      history.push(ROUTES.SETTINGS.TRAVEL_POLICIES);
    }
  };

  const content = preparedTravelPolicyRules.map(({ store, type }: { store: any, type: string }) =>
    !(type === SERVICETYPE.TAXI && noBookingTaxi) && (
      <PolicyRulesWrapper
        isUnique
        key={ type }
        store={ store }
        name={ name }
        type={ type as ServiceTypeSmall }
        suggestions={ suggestions }
      />
    ));

  const preparedHeaderSubText = wasNoQuestionnaire ? (
    <Text
      type='NORMAL_16'
      qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.uniqueTravelPolicy.subText }
      className={ styles.header_sub_text }
    >
      { subTextOne }
    </Text>
  ) : null;

  const prepareSubTextOne = formedTP === UNIQUE_FORMED_TP.NEW ? preparedHeaderSubText : null;

  const redirectToEditTravelPolicy = () => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.TRAVEL_POLICY.FORMED_TP_EDIT, { formedTP });
    history.push(ROUTES.TRAVEL_POLICY);
  };

  const redirectToSmartdesk = () => {
    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.TRAVEL_POLICY.FORMED_TP_BACK, { formedTP });
    history.push(ROUTES.SMARTDESK);
  };

  return (
    <>
      <BackLink
        qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.uniqueTravelPolicy.back }
        link={ ROUTES.SMARTDESK }
        text={ LABELS.BACK }
        className={ styles.back }
        onClick={ redirectToSmartdesk }
      />
      <StyledWrapper className={ styles.wrapper }>
        <img
          src={ UniqueTravelPolicyImg }
          alt='vector'
          className={ styles.imageStyle }
        />
        <div className={ styles.header }>
          <Text
            type='bold_24'
            qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.uniqueTravelPolicy.header }
            className={ styles.header_text }
          >
            { header }
          </Text>
          { prepareSubTextOne }
          <Text type='NORMAL_16'>
            { subTextTwo }
          </Text>
        </div>
        <div className={ styles.main }>
          { content }
          <div className={ styles.actions }>
            <Button
              qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.uniqueTravelPolicy.edit }
              type='textual'
              className={ styles.edit_button }
              onClick={ redirectToEditTravelPolicy }
            >
              { LABELS.EDIT }
            </Button>
            <Button
              qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.uniqueTravelPolicy.save }
              type='secondary'
              className={ styles.edit_button }
              onClick={ handleShowEmployeeDialog }
            >
              { LABELS.SAVE }
            </Button>
          </div>
        </div>
      </StyledWrapper>
      <EmployeeDialog
        isUnique
        isCreate={ !id }
        show={ showEmployeeDialog }
        employees={ employees }
        formedTP={ formedTP }
        isSelectAll={ isSelectAll }
        setSelectAll={ setSelectAll }
        setShowEmployeeDialog={ () => setShowEmployeeDialog(false) }
        setEmployeeAutocompleteList={ setEmployeeAutocompleteList }
        setEmployeesSelected={ setEmployeesSelected }
        onSave={ handleSave }
      />
    </>
  );
});

export { TravelPolicyUnique };
