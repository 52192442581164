// @ts-nocheck
class Chat {
  constructor(services) {
    Object.keys(services)
      .forEach((s) => {
        this[s] = services[s];
      });
  }

  prepareChatService = () => {
    const { CarrotQuestChat } = this.FeatureFlags.getFeatureFlags();

    if (CarrotQuestChat) {
      this.ChatDecorator.setNewChat();
    }
  };
}

export { Chat };
