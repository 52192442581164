import React from 'react';
import { observer } from 'mobx-react';

import ChartsAnalytics from '../../../../../bi/services/chartsAnalytics';

import ROUTES from '../../../../../bi/constants/routes';

import { HotelPageSubSections } from '../../common/smartIndex/components/hotelPageSubSections';

const SmartHotel = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => (
  <HotelPageSubSections
    chartsAnalyticsService={ chartsAnalyticsService }
    path={ ROUTES.CHARTS_ANALYTICS.SMART.HOTEL.SECOND }
  />
));

export { SmartHotel };
