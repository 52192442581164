import React from 'react';
import { observer } from 'mobx-react';
import { Button, DotLoading, Text } from 'new-ui';

import { useServices } from '../../../../../../bi/context/services';

import { StatusCondition } from '../../../../../../components/StatusCondition';

import { LoadingFields } from '../../../../../../bi/services/signature';

import { LABELS } from '../../consts';

import styles from './styles.module.css';

interface ILocalButton {
  onClick: () => void
}

const LocalButton = ({ onClick }: ILocalButton) => (
  <Button
    type='primary-outline'
    className={ styles.btn }
    onClick={ onClick }
  >
    <img
      alt='gov logo'
      src='/icons/gosuslugi/logo.svg'
      className={ styles.icon }
    />
    <Text type='NORMAL_14_130'>
      { LABELS.GOV_SERVICE_AUTH }
    </Text>
  </Button>
);

export const AuthButton = observer(() => {
  const { signatureService } = useServices(['Signature']);

  return (
    <StatusCondition
      status={ signatureService.netStore.getStatus(LoadingFields.govOauthCall) }
      onLoading={ DotLoading }
      fallback={ () => <LocalButton onClick={ signatureService.govOauthCall } /> }
    />
  );
});
