const OPERATIONSACTION = {
  STARTLOADOPERATION: 'operations.start',
  LOADCOMPLETE: 'operations.loaded',
  LOADRANGEOPERATIONSCOMPLETE: 'operations.range.loaded',
  LOADBALANCE: 'balance',
  INVOICE_REQUISITES: 'invoiceRequisites',
  LOADBALANCEDATARANGE: 'balanceByDateRange',
  GET_ALL_INVOICE: 'get.all.invoice',
};

const UPDACTION = {
  STARTLOADUPD: 'upd.start',
  LOADCOMPLETE: 'upd.loaded',
};

export { OPERATIONSACTION, UPDACTION };
export default OPERATIONSACTION;
