import React, { Component } from 'react';

import { Text, Textarea, DotLoading, Button, IconButton, Checkbox, Tooltip } from 'new-ui';
import { getText } from '../../../../../i18n';

import { STICKY } from '../../../../bi/constants/stickySearch';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { PHANTOM_STATUS_ENUM } from '../../../../bi/constants/note';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { CANCEL_RESERVED } from '../../../../bi/constants/airline';

import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';
import parseJsonString from '../../../../bi/utils/parseJsonString';
import { parsedAllItems } from '../../../../bi/utils/airline';

import InputModal from '../../../../components/InputModal';
import StickySearchPanel from '../../../../components/StickySearchPanel';
import { RemoveItemDialog } from '../../../../components/RemoveItemDialog/RemoveItemDialog';

import { IPrepareNoteItem } from '../../../../bi/services/notepad/types';
import { RouteInterface } from '../../../../bi/utils/changeAirTrip/types';

import styles from './index.module.css';

const LOCATION = 'PANEL';

const ACTIONS = {
  SAVEPDF: 'SAVEPDF',
  SENDAGREEMENT: 'SENDAGREEMENT',
};

const LABELS = {
  SENDINFORMATION: getText('note:search.sendInfo'),
  INPUTEMAIL: getText('note:search.putEmail'),
  ACTUAL: getText('note:actual'),
  INPUTCOMMENTS: getText('note:search.putComment'),
  QUICKSEARCH: getText('note:search.fastSearch'),
  SAVE_PDF: getText('note:search.savePdf'),
  SELECT_ALL: getText('note:search.selectAll'),
  SEND_TO_EMAIL: getText('note:search.sendToEmail'),
  SELECTED: getText('note:search.selected'),
  TO_CART: getText('note:toCart'),
  REMOVE: getText('note:remove'),
  REMOVE_AVAILABLE: getText('note:tooltip.removeAvailable'),
};

const TYPEEMAIL = 'email';

const OPACITY_VALUES = {
  VALUE_06: '0.6',
  VALUE_1: '1',
};

interface INoteSearchPanelProps {
  initTop: number,
  selectedCount: number,
  selectedAll: boolean,
  items: IPrepareNoteItem[],
  userId?: string,
  query?: string,
  handleInputChange: (value: string) => void,
  handleDownload: () => Promise<void>,
  handleCheckboxChange: (value: boolean) => void,
  handleMoveItemsToCart: () => Promise<void>,
  handleDeleteItems: () => void,
  handleSend: (email: string, comment: string) => Promise<void>,
  onMoveNoteToCart: (location: typeof LOCATION, selectedCount: number) => void,
  onRemoveServiceNote: (location: typeof LOCATION, selectedCount: number) => void,
  onOtherEventPanel: (action: string, selectedCount: number) => void,
  onBlurFastSearch: (str: string, selectedCount: number) => void,
}

interface ILoading {
  pdf: boolean,
  send: boolean,
  cart: boolean,
}

interface INoteSearchPanelState {
  showDialog: boolean,
  openText: boolean,
  comment: string,
  loading: ILoading,
  showRemoveItemDialog: boolean,
}

class NoteSearchPanel extends Component<INoteSearchPanelProps, INoteSearchPanelState> {
  static defaultProps = {
    query: '',
    userId: '',
  };

  state = {
    showDialog: false,
    openText: false,
    comment: '',
    loading: {
      pdf: false,
      send: false,
      cart: false,
    },
    showRemoveItemDialog: false,
  };

  handleDialog = () => {
    this.setState({
      showDialog: true,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      showDialog: false,
      openText: false,
      comment: '',
    });
  };

  toggleRemoveItemtDialog = (value: boolean) => {
    this.setState({
      showRemoveItemDialog: value,
    });
  };

  handleDeleteItemDialog = () => {
    this.toggleRemoveItemtDialog(false);
    this.handleClickDelete();
  };

  handleRemoveItemClick = () => {
    const { items } = this.props;

    const hasAirServiceType = items.some(item => item.ServiceType === SERVICETYPE.AIR);

    if (!hasAirServiceType) {
      return this.handleClickDelete();
    }

    const showRemoveItemDialog = items.some(item => {
      if (item.ServiceType === SERVICETYPE.AIR && item.Selected) {
        const { Data } = item;
        const itemData = parseJsonString(Data);

        const airlineCompanySatisfies = itemData.Routes.some((route: RouteInterface) =>
          route.Segments.some(segment =>
            segment.Airline.ID === CANCEL_RESERVED.FV ||
            segment.Airline.ID === CANCEL_RESERVED.SU,
          ),
        );

        return item.IsReserved && airlineCompanySatisfies;
      }

      return false;
    });

    const isSelected = items
      .some(item => showRemoveItemDialog
        && item.Selected
        && item.IsReserved);

    return showRemoveItemDialog && isSelected
      ? this.toggleRemoveItemtDialog(true)
      : this.handleClickDelete();
  };

  setLoading = (field: keyof ILoading, value: boolean) => this.setState({ ...this.state, loading: { ...this.state.loading, [field]: value } });

  handleSend = (email: string) => {
    this.setLoading('send', true);
    this.props.handleSend(email, this.state.comment).then(() => this.setLoading('send', false));
    this.handleCloseDialog();
  };

  handleDownloadPdf = () => {
    this.setLoading('pdf', true);
    this.props.handleDownload().then(() => this.setLoading('pdf', false));
  };

  handleMoveToCart = () => {
    const { handleMoveItemsToCart } = this.props;

    this.setLoading('cart', true);

    handleMoveItemsToCart().finally(() => this.setLoading('cart', false));
  };

  handleOpenText = () => this.setState({
    openText: !this.state.openText,
  });

  handleChangeText = (value: string) => this.setState({
    comment: value,
  });

  handleClickDelete = () => {
    const {
      selectedCount,
      handleDeleteItems,
      onRemoveServiceNote,
    } = this.props;

    handleDeleteItems();
    onRemoveServiceNote(LOCATION, selectedCount);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.DELETE_ALL);
  };

  handleClickDownload = () => {
    const { onOtherEventPanel, selectedCount } = this.props;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.SAVE_PDF);
    this.handleDownloadPdf();
    onOtherEventPanel(ACTIONS.SAVEPDF, selectedCount);
  };

  handleSendOtherEvent = () => {
    const { onOtherEventPanel, selectedCount } = this.props;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.SEND_PDF);

    this.handleDialog();
    onOtherEventPanel(ACTIONS.SENDAGREEMENT, selectedCount);
  };

  moveToCart = () => {
    const { onMoveNoteToCart, selectedCount } = this.props;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.ALL_TO_CART);

    this.handleMoveToCart();
    onMoveNoteToCart(LOCATION, selectedCount);
  };

  handleSelectAll = (value: boolean) => {
    const { handleCheckboxChange } = this.props;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.SELECT_ALL_NOTE);
    handleCheckboxChange(value);
  };

  renderDialog = () => {
    let textHtml = null;

    const {
      title,
      input,
      sendButton,
    } = QA_ATTRIBUTES.note.dialogSendToEmail;

    const attrsDialog = {
      qaAttrTitle: title,
      qaAttrInput: input,
      qaAttrSend: sendButton,
    };

    if (this.state.openText) {
      textHtml = (
        <Textarea
          noneResize
          className={ styles.comments }
          value={ this.state.comment }
          onChange={ this.handleChangeText }
        />
      );
    }

    return (
      <InputModal
        show={ this.state.showDialog }
        title={ LABELS.SENDINFORMATION }
        label={ LABELS.INPUTEMAIL }
        type={ TYPEEMAIL }
        onSend={ this.handleSend }
        onCancel={ this.handleCloseDialog }
        qaAttrs={ attrsDialog }
      >
        <Button
          className={ styles['description-open'] }
          type='textual'
          onClick={ this.handleOpenText }
        >
          { LABELS.INPUTCOMMENTS }
        </Button>
        { textHtml }
      </InputModal>
    );
  };

  renderDownloadPdf = () => {
    const { selectedCount } = this.props;
    const { loading: { pdf } } = this.state;

    const content = pdf ? (
      <div className={ styles['pdf-loading'] }>
        <DotLoading />
      </div>
    ) : (
      <IconButton
        onClick={ this.handleClickDownload }
        iconType='pdf'
        disabled={ !selectedCount }
      >
        <Text>
          { LABELS.SAVE_PDF }
        </Text>
      </IconButton>
    );

    return (
      <div className={ styles.action }>
        { content }
      </div>
    );
  };

  renderSendMessage = () => {
    const { selectedCount } = this.props;

    const { loading: { send } } = this.state;

    const content = send ? (
      <div className={ styles['send-message-loading'] }>
        <DotLoading />
      </div>
    ) : (
      <IconButton
        onClick={ this.handleSendOtherEvent }
        iconType='menuApprove'
        disabled={ !selectedCount }
        qaAttr={ QA_ATTRIBUTES.note.sendToEmail }
      >
        <Text>
          { LABELS.SEND_TO_EMAIL }
        </Text>
      </IconButton>
    );

    return (
      <div className={ styles.action }>
        { content }
      </div>
    );
  };

  renderToCart = () => {
    const { selectedCount } = this.props;
    const { loading: { cart } } = this.state;

    const content = cart ? (
      <div className={ styles['to-cart-loading'] }>
        <DotLoading />
      </div>
    ) : (
      <IconButton
        onClick={ this.moveToCart }
        iconType='menuCart'
        disabled={ !selectedCount }
        qaAttr={ QA_ATTRIBUTES.note.toCartSelected }
      >
        <Text>
          { LABELS.TO_CART }
        </Text>
      </IconButton>
    );

    return (
      <div className={ styles.action }>
        { content }
      </div>
    );
  };

  renderRemoveItemDialog = () => {
    const { items } = this.props;
    const { showRemoveItemDialog } = this.state;

    const parsedItems = parsedAllItems(items);

    if (!parsedItems) return null;

    return (
      <RemoveItemDialog
        items={ parsedItems }
        showRemoveItemDialog={ showRemoveItemDialog }
        onClose={ () => this.toggleRemoveItemtDialog(false) }
        onDelete={ () => this.handleDeleteItemDialog() }
      />
    );
  };

  renderDelete = () => {
    const { selectedCount, items, userId } = this.props;

    const availableRemove = isSmartAgent && items.some(item => item.OwnerId !== userId);

    const renderTooltip = (info: string) => (
      <Text
        color='white'
        type='NORMAL_14_130'
        className={ styles.tooltip_style }
      >
        <div>{ info }</div>
      </Text>
    );

    return (
      <div className={ styles.action }>
        <Tooltip
          show={ availableRemove }
          renderContent={ () => renderTooltip(LABELS.REMOVE_AVAILABLE) }
        >
          <IconButton
            onClick={ this.handleRemoveItemClick }
            iconType='trashcan'
            disabled={ !selectedCount || availableRemove }
            qaAttr={ QA_ATTRIBUTES.note.deleteSelected }
          >
            <Text>
              { LABELS.REMOVE }
            </Text>
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  renderCheckbox = () => {
    const { selectedAll, selectedCount, items } = this.props;
    const { VALUE_1, VALUE_06 } = OPACITY_VALUES;

    const style = { opacity: !selectedCount
      ? VALUE_06
      : VALUE_1,
    };

    const allItemsIsPhantoms = items.every(({ Status }) => Status !== PHANTOM_STATUS_ENUM.NORMAL);

    return (
      <div className={ styles.action }>
        <Checkbox
          value={ selectedAll }
          onChange={ (value) => this.handleSelectAll(value) }
          qaAttr={ QA_ATTRIBUTES.note.selectAll }
          disabled={ allItemsIsPhantoms }
        >
          <div style={ style }>
            <Text>{LABELS.SELECT_ALL}</Text>
          </div>
        </Checkbox>
      </div>
    );
  };

  render() {
    const {
      selectedCount,
      query,
      items,
      handleInputChange,
      onBlurFastSearch,
      initTop,
    } = this.props;
    const { showDialog } = this.state;

    const selectedContent = !!selectedCount && (
      <Text className={ styles['selected-count'] }>
        { LABELS.SELECTED } { selectedCount }
      </Text>
    );

    const dialog = showDialog && this.renderDialog();

    const renderStickySerach = (
      <StickySearchPanel
        adjustment={ STICKY.ADJUSTMENT }
        initial={ initTop }
        style={ STICKY.STYLES }
        query={ query }
        placeholder={ LABELS.QUICKSEARCH }
        onChangeInput={ handleInputChange }
        onBlurFastSearch={ () => onBlurFastSearch(LABELS.QUICKSEARCH, selectedCount) }
      >
        <div className={ styles.actions }>
          { selectedContent }
          { this.renderDownloadPdf() }
          { this.renderSendMessage() }
          { this.renderToCart() }
          { this.renderDelete() }
          { this.renderCheckbox() }
          { this.renderRemoveItemDialog() }
        </div>
        { dialog }
      </StickySearchPanel>
    );

    const renderStatickSearch = (
      <div className={ styles['functional-header'] }>
        <div>
          <Text type='bold_24'>{LABELS.ACTUAL}</Text>
        </div>
        <div className={ styles.actions }>
          { selectedContent }
          { this.renderDownloadPdf() }
          { this.renderSendMessage() }
          { this.renderToCart() }
          { this.renderDelete() }
          { this.renderCheckbox() }
        </div>
        { dialog }
      </div>
    );

    const visibleSearch = !!items.length && renderStatickSearch;
    const html = isSmartAgent ? visibleSearch : renderStickySerach;

    return html;
  }
}

export { NoteSearchPanel };
