import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { getText } from '../../../i18n';

import TransferSearchMenu from '../../components/Menu/TransferSearchMenu';
import { TransferResult } from './components/TransferSearchResult';
import { SearchLoading } from '../../components/SearchLoading';
import Search from '../../components/Search';

import MoneyFormat from '../../bi/utils/money';
import slideToTop from '../utils/scrollToTop';
import { MainAnalytic } from '../../bi/utils/analytics';

import { HISTORY_ACTIONS } from '../../bi/constants/history';
import FAVORITESACTION from '../../bi/constants/favorites';
import { NOTIFICATION_LEVEL } from '../../bi/constants/notifications';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { ANALYTIC_SERVICE_TYPES } from '../../bi/constants/serviceType';
import { SORT } from '../../bi/constants/transfers';

import {
  TransferResultPageProps,
  IFilter,
  ITransferItem,
  TagType,
  AnalyticSortTransfer,
} from '../../bi/types/transfer';

import styles from './index.module.css';
import { useGetParams } from '../../bi/hooks/useGetParams';

const LABELS = {
  SEARCH: getText('transfer:transferResult.loader'),
  IN: getText('common:in'),
  ADD_TO_CART_SUCCESS: (location: string, price: string) =>
    getText('transfer:transferResult.notifications.toCart.success', { location, price }),
  ADD_TO_CART_ERROR: (location: string, price: string) =>
    getText('transfer:transferResult.notifications.toCart.error', { location, price }),
  ADD_TO_NOTE_SUCCESS: (location: string, price: string) =>
    getText('transfer:transferResult.notifications.toNote.success', { location, price }),
  ADD_TO_NOTE_ERROR: (location: string, price: string) =>
    getText('transfer:transferResult.notifications.toNote.error', { location, price }),
};

const TransferResultPage: FC<TransferResultPageProps> = observer(({
  history,
  transferService,
  userSessionService,
  favoriteService,
  notificationService,
  requestsService,
  featureFlagsService: {
    getShowElementForSmartagent,
  },
  match: { params: { guid } },
}) => {
  const { transferStore: { loading, sources } } = useStores([MOBX_STORES.TRANSFER_STORE]);
  const { transferSearchStore: { transferFrom, transferTo, searchId } } = useStores([MOBX_STORES.TRANSFER_SEARCH_STORE]);
  const tripId = useGetParams('tripId');

  useEffect(() => {
    const travelPolicy = userSessionService.get().travelPolicies;

    if (history.action === HISTORY_ACTIONS.POP && sources.length === 0) {
      transferService.searchByGuid(guid, travelPolicy);
    }

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_SCREEN_OPENED(ANALYTIC_SERVICE_TYPES.TRANSFER),
    );

    return () => {
      transferService.setNewSearch();
    };
  }, []);

  const onCloseTag = (tag: TagType) => transferService.deleteTag(tag);

  const handleChangeFavorite = async (item: ITransferItem, action: string) => {
    const {
      selected: selectedFrom,
    } = transferFrom;
    const {
      selected: selectedTo,
    } = transferTo;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_FAVORITES_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.TRANSFER),
    );

    if (action === FAVORITESACTION.REMOVE) {
      await favoriteService.removeItem(item.FavoriteId);

      transferService.changeFavoriteStatus(item.Class, '');

      const location = `${selectedFrom?.Address} в ${selectedTo?.Address}`;

      return notificationService.send({
        message: `Трансфер ${location} удален из избранного`,
        level: 'success',
      });
    }

    const params = {
      Capacity: item.Capacity,
      Class: item.Class,
      Models: item.Models,
      Places: {
        StartPlace: selectedFrom,
        FinishPlace: selectedTo,
      },
    };

    const favoriteId = await transferService.moveToFavorite(params);

    transferService.changeFavoriteStatus(item.Class, favoriteId);

    const location = `${selectedFrom?.Address} в ${selectedTo?.Address}`;

    return notificationService.send({
      message: `Трансфер ${location} добавлен в избранное`,
      level: 'success',
    });
  };

  const handleAlreadyInCart = (id: string) => transferService.setAlreadyInCart(id);

  const handleAddToNotepad = async (transfer: ITransferItem) => {
    const {
      selected: selectedFrom,
    } = transferFrom;
    const {
      selected: selectedTo,
    } = transferTo;

    const { Price } = transfer;

    const location = `${selectedFrom?.Address} ${LABELS.IN} ${selectedTo?.Address}`;

    try {
      await transferService.moveToNote(searchId, transfer, { TripId: tripId });

      notificationService.send({
        message: LABELS.ADD_TO_NOTE_SUCCESS(location, MoneyFormat.money(Price, true)),
        level: 'success',
      });
    } catch (e) {
      notificationService.send({
        message: LABELS.ADD_TO_NOTE_SUCCESS(location, MoneyFormat.money(Price, true)),
        level: 'success',
      });
    }

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_NOTEBOOK_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.TRANSFER),
    );
  };

  const handleAddToCart = async (transfer: ITransferItem) => {
    const {
      selected: selectedFrom,
    } = transferFrom;
    const {
      selected: selectedTo,
    } = transferTo;

    const { Price } = transfer;

    const location = `${selectedFrom?.Address}–${selectedTo?.Address}`;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_ADD_CART_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.TRANSFER),
    );

    try {
      const requestItemId = requestsService.get().requestItem?.Id || null;
      await transferService.moveToCart({ ...transfer, TripId: tripId }, requestItemId);

      notificationService.send({
        message: LABELS.ADD_TO_CART_SUCCESS(location, MoneyFormat.money(Price, true)),
        level: NOTIFICATION_LEVEL.SUCCESS,
        qaAttr: QA_ATTRIBUTES.transfer.result.item.notification,
      });
    } catch (e) {
      notificationService.send({
        message: LABELS.ADD_TO_CART_ERROR(location, MoneyFormat.money(Price, true)),
        level: NOTIFICATION_LEVEL.ERROR,
      });
    }
  };

  const handleUpdateFilter = (opts: IFilter) => {
    transferService.updateFilter(opts);
    slideToTop();
  };

  const handleResetFilters = () => transferService.resetFilters();

  const handleSubSearch = async () => {
    const travelPolicy = userSessionService.get().travelPolicies;

    const id = await transferService.search(travelPolicy);

    history.push({ pathname: `/search/transfers/${id}` });

    MainAnalytic.send(MainAnalytic.CATEGORY.TRANSFER, MainAnalytic.ACTIONS.TRANSFER.UPDATESEARCHONEWAY);
  };

  const handleSortSearchResult = (opts: string) => {
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_TRANSFER_SORTING_CHOSEN(
        SORT.find(i => i.value === opts)?.analyticValue as AnalyticSortTransfer,
      ),
    );

    return transferService.sortSearchResult(opts);
  };

  if (loading) {
    return (
      <div className={ styles.loading }>
        <Search>
          <SearchLoading text={ LABELS.SEARCH } qaAttrLoadingText={ QA_ATTRIBUTES.transfer.result.loading }/>
        </Search>
      </div>
    );
  }

  const html = (
    <TransferResult
      userSessionService={ userSessionService }
      onAddToShoppingCart={ handleAddToCart }
      onAddToNotepad={ handleAddToNotepad }
      sortSearchResult={ handleSortSearchResult }
      updateFilter={ handleUpdateFilter }
      onCloseTag={ onCloseTag }
      onResetFilters={ handleResetFilters }
      onAddToFavorite={ handleChangeFavorite }
      onAlreadyInCart={ handleAlreadyInCart }
    />
  );

  return (
    <>
      <TransferSearchMenu
        subMenu
        onSearch={ handleSubSearch }
        transferService={ transferService }
        getShowElementForSmartagent={ getShowElementForSmartagent() }
      />
      <div className={ styles.wrapper }>
        { html }
      </div>
    </>
  );
});

export { TransferResultPage };
