import ACTION from '../action';
import Store from '../../../store/index';

import { HistoryObj } from '../types';

const reducer = (action: { [key: string]: any, type: string }, state: HistoryObj[]) => {
  switch (action.type) {
    case ACTION.UPDATEHISTORY:
      return action.payload;
  }

  return state;
};

const createStore = () => new (Store as any)(reducer, []);

export default createStore;
