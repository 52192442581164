import { observable, action, makeObservable } from 'mobx';

import type { SettingsExpenseType } from '../types';

const DEFAULT_SOURCES = {
  RuDailyExpenses: 700,
  OverseasDailyExpenses: 2500,
  Rewritable: true,
};

export class SettingsExpenseStore {
  constructor() {
    makeObservable(this);
  }

  @observable sources: SettingsExpenseType = DEFAULT_SOURCES;
  @observable loading: boolean = true;
  @observable loadingButton: boolean = false;
  @observable isValid: boolean = false;

  @action
  setSettings = (data: SettingsExpenseType): void => {
    this.sources = {
      ...DEFAULT_SOURCES,
      ...data,
    };
  };

  @action
  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  @action
  setLoadingButton = (value: boolean): void => {
    this.loadingButton = value;
  };

  @action
  setValid = (value: boolean): void => {
    this.isValid = value;
  };

  @action
  setRuExpense = (value: number): void => {
    const isValid = !this.isValid ? this.sources.RuDailyExpenses !== value : this.isValid;

    this.sources = {
      ...this.sources,
      RuDailyExpenses: Number(value),
    };

    this.setValid(isValid);
  };

  @action
  setAbroadExpense = (value: number): void => {
    const isValid = !this.isValid ? this.sources.OverseasDailyExpenses !== value : this.isValid;

    this.sources = {
      ...this.sources,
      OverseasDailyExpenses: Number(value),
    };

    this.setValid(isValid);
  };

  @action
  setCheckbox = (value: boolean): void => {
    const isValid = !this.isValid ? this.sources.Rewritable !== value : this.isValid;

    this.sources = {
      ...this.sources,
      Rewritable: value,
    };

    this.setValid(isValid);
  };

  @action
  reset = (): void => {
    this.sources = DEFAULT_SOURCES;
    this.loading = true;
    this.loadingButton = false;
    this.isValid = false;
  };
}
export const settingsExpenseStore = new SettingsExpenseStore();
