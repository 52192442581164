import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Text, List, Dialog, PageLoader } from 'new-ui';
import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import type { TravelPolicyServiceType } from '../../../../bi/services/travelPolicy';
import type { TravelPolicyServerModel } from '../../../../bi/services/travelPolicy/types';

import { TableRow } from '../../../../components/TableRow';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import TEXTS from '../../../../bi/constants/texts';
import ROUTES from '../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './index.module.css';

const LABELS = {
  EDIT: getText('settings:travelPolicy.edit'),
  REMOVE: getText('settings:travelPolicy.remove'),
  EMPTY_TEXT: getText('settings:travelPolicy.emptyText'),
  ADD_TP: getText('settings:travelPolicy.add'),
  DIALOG_TEXT: getText('settings:travelPolicy.notDeleteWithEmployee'),
  CLOSE: getText('settings:travelPolicy.close'),
};

type TravelPoliciesPageProps = {
  travelPolicyService: TravelPolicyServiceType,
  history: any,
  isDemo: boolean,
};

const TravelPoliciesTab = observer(({
  travelPolicyService,
  history,
  isDemo,
}: TravelPoliciesPageProps) => {
  const { travelPolicyListStore } = useStores([MOBX_STORES.TRAVEL_POLICY_LIST]);
  const { list, showDialog, loading } = travelPolicyListStore;

  useEffect(() => {
    travelPolicyService.loadList();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRAVEL_POLICY_OPEN);

    return travelPolicyService.clearList;
  }, [travelPolicyService]);

  const redirectToTravelPolicy = (): void => history.push(ROUTES.TRAVEL_POLICY);

  const handleRemoveTravelPolicy =
    ({ Id }: TravelPolicyServerModel): void => {
      travelPolicyService.removeTravelPolicy(Id);
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRAVEL_POLICY_DELETE_BUTTON);
    };

  const handleOpenTravelPolicyPage = (policy: TravelPolicyServerModel | null): void => {
    if (policy) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRAVEL_POLICY_OPENED);
    } else {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRAVEL_POLICY_ADD_BUTTON);
    }

    travelPolicyService.setTravelPolicyFromServerModel(policy);
    redirectToTravelPolicy();
  };

  const handleCloseDialog = (): void => {
    travelPolicyService.setListDialog(false);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRAVEL_POLICY_ERROR_PRESSED);
  };

  const addButton = (
    <Button
      type='primary'
      onClick={ () => handleOpenTravelPolicyPage(null) }
      qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.addPolicyButton }
    >
      { LABELS.ADD_TP }
    </Button>
  );

  const emptyHtml = (
    <div className={ styles.empty }>
      <Text
        type='NORMAL_16_140'
        className={ styles.text }
      >
        { LABELS.EMPTY_TEXT }
      </Text>
      { addButton }
    </div>
  );

  const renderList = () => {
    if (!list?.length) {
      return emptyHtml;
    }

    const removeTooltipText = isDemo ? TEXTS.NOT_FOR_DEMO : undefined;

    const listContent = (
      <List
        onClickItem={ (i) => handleOpenTravelPolicyPage(i) }
        items={ list }
        keyExtractor={ ({ Id }) => Id }
        qaAttrFirstEl={ QA_ATTRIBUTES.settings.travelPolicy.firstItem }
        qaAttrItem={ QA_ATTRIBUTES.settings.travelPolicy.betweenItem }
        qaAttrLastEl={ QA_ATTRIBUTES.settings.travelPolicy.lastItem }
        renderItem={ i => {
          const { Description, Name } = i;

          return (
            <TableRow
              title={ Name }
              description={ Description }
              remove={ {
                action: () => handleRemoveTravelPolicy(i),
                tooltip: removeTooltipText,
              } }
              edit={ {
                action: () => handleOpenTravelPolicyPage(i),
              } }
              onClick={ () => handleOpenTravelPolicyPage(i) }
              qaAttrWrapper={ QA_ATTRIBUTES.settings.travelPolicy.item.wrapper }
              qaAttrTitle={ QA_ATTRIBUTES.settings.travelPolicy.item.title }
              qaAttrRemove={ QA_ATTRIBUTES.settings.travelPolicy.item.delete }
            />
          );
        } }
      />
    );

    return (
      <div className={ styles.wrap }>
        <div className={ styles.header }>
          { addButton }
        </div>
        { listContent }
      </div>
    );
  };

  const renderErrorDialog = () => (
    <Dialog
      show={ showDialog }
      onChange={ handleCloseDialog }
    >
      <div className={ styles.dialog }>
        <Text className={ styles.text }>
          { LABELS.DIALOG_TEXT }
        </Text>
        <div>
          <Button
            type='primary'
            onClick={ handleCloseDialog }
          >
            { LABELS.CLOSE }
          </Button>
        </div>
      </div>
    </Dialog>
  );

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      { renderList() }
      { renderErrorDialog() }
    </>
  );
});

export { TravelPoliciesTab };
