import React from 'react';
import { clsx } from 'clsx';
import {
  SmartwayCommonEnumsSigningStatus,
} from '@sw/api';

import { LABELS } from './consts';

import styles from './styles.module.css';

interface CellProps {
  fieldValue: SmartwayCommonEnumsSigningStatus;
}

export const DocumentStatusCell = ({ fieldValue }: CellProps) => (
  <div className={ styles.documentStatusCell }>
    <div
      className={ clsx(
        styles.markerBase,
        styles[`marker${SmartwayCommonEnumsSigningStatus[fieldValue]}`],
      ) }
    />
    <div className={ styles.noWrap }>
      { LABELS.SIGNING_STATUS[fieldValue] }
    </div>
  </div>
);
