import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Button, Text, Dialog, List, PageLoader, NoResults } from 'new-ui';
import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import ApprovalSchemesService from '../../../../bi/services/approvalSchemes';

import { TableRow } from '../../../../components/TableRow';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import TEXTS from '../../../../bi/constants/texts';
import ROUTES from '../../../../bi/constants/routes';
import { StatusScheme } from '../../../../bi/constants/approvalSchemes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './index.module.css';
import { IApprovalSTabItem } from '../../types';
import { IApprovalSchemeClientModel } from '../../../../bi/types/approvalScheme';

const LABELS = {
  ADD: getText('settings:approvalSchemes.add'),
  REMOVE: getText('settings:approvalSchemes.remove'),
  CANCEL: getText('common:undo'),
  UNABLE_TO_DELETE: getText('settings:approvalSchemes.unableToDeleteDialog.title'),
  UNABLE_TO_DELETE_DESC: getText('settings:approvalSchemes.unableToDeleteDialog.description'),
  DELETE_CONFIRM: getText('settings:approvalSchemes.deleteConfirm'),
  EMPTY_DESC: {
    FIRST_PART: getText('settings:approvalSchemes.emptyDesc.1'),
    SECOND_PART: getText('settings:approvalSchemes.emptyDesc.2'),
    THIRD_PART: getText('settings:approvalSchemes.emptyDesc.3'),
  },
};

interface IErrorRemoveDialog {
  show?: boolean,
  code?: null,
  integration?: number | null,
}

interface IApprovalSchemesTab {
  isDemo: boolean,
  approvalSchemesService: ApprovalSchemesService,
}

const ApprovalSchemesTab = observer(({
  isDemo,
  approvalSchemesService,
}: IApprovalSchemesTab) => {
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [schemeRemoveDialog, setSchemeRemoveDialog] = useState({ Name: '', Id: '' });
  const [loadingRemoveDialog, setLoadingRemoveDialog] = useState(false);
  const [errorRemoveDialog, setErrorRemoveDialog] = useState<IErrorRemoveDialog>({
    show: false,
    code: null,
    integration: null,
  });

  const { approvalSchemesStore } = useStores([MOBX_STORES.APPROVAL_SCHEMES]);
  const {
    list: {
      value,
      loading,
      error,
    },
    loadingList,
  } = approvalSchemesStore;

  const history = useHistory();

  useEffect(() => {
    approvalSchemesService.loadNewList();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.COSTS_SCHEMES_OPEN);
  }, [approvalSchemesService]);

  const handleNavigateToAddForm = () => {
    history.push(ROUTES.APPROVAL_SCHEME);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.COST_SCHEME_ADD_BUTTON);
  };

  const handleNavigateToEditForm = (data: any) => {
    approvalSchemesService.updateStatus(StatusScheme.EDIT);
    approvalSchemesService.initForm(data);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.COST_SCHEME_OPENED);
    history.push(ROUTES.APPROVAL_SCHEME);
  };

  const handleOpenDialog = (i: any) => {
    setShowRemoveDialog(true);
    setSchemeRemoveDialog(i);
  };

  const handleCloseDialog = () => setShowRemoveDialog(false);

  const handleSetDialogLoading = (val: boolean) => setLoadingRemoveDialog(val);

  const handleRemove = (isAppl: boolean, item: IApprovalSTabItem) => (isAppl ? () => null : () => handleOpenDialog(item));

  const handleRemoveScheme = async (Id: number) => {
    handleSetDialogLoading(true);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.COST_SCHEME_DELETE_BUTTON);

    await approvalSchemesService.removeItem(Id)
      .then(() => {
        approvalSchemesService.loadNewList();
        handleCloseDialog();
      })
      .catch(({ status }: { status: number }) => {
        setErrorRemoveDialog({ integration: status, show: true });
      });
    handleSetDialogLoading(false);
  };

  const renderAddButton = () => (
    <Button
      className={ styles.button }
      type='primary'
      onClick={ () => handleNavigateToAddForm() }
      qaAttr={ QA_ATTRIBUTES.approvalSchemes.trips.buttonAdd }
    >
      { LABELS.ADD }
    </Button>
  );

  const renderEmpty = () => (
    <div className={ styles.empty }>
      <Text
        className={ styles.text }
        type='NORMAL_16_140'
      >
        { LABELS.EMPTY_DESC.FIRST_PART } <br />
        { LABELS.EMPTY_DESC.SECOND_PART } <br />
        { LABELS.EMPTY_DESC.THIRD_PART }
      </Text>
      { renderAddButton() }
    </div>
  );

  const renderList = () => {
    if (!value?.length) {
      return renderEmpty();
    }

    const tooltipText = isDemo ? TEXTS.NOT_FOR_DEMO : '';

    const newListContent = (
      <List
        onClickItem={ handleNavigateToEditForm }
        keyExtractor={ ({ Id }) => Id.toString() }
        renderItem={ (item: IApprovalSchemeClientModel) => {
          const { IsApplied, Name } = item;

          return (
            <TableRow
              title={ Name }
              onClick={ () => handleNavigateToEditForm(item) }
              remove={ {
                // @ts-ignore
                action: handleRemove(IsApplied, item),
                tooltip: tooltipText,
              } }
              edit={ { action: () => handleNavigateToEditForm(item) } }
              qaAttrTitle={ QA_ATTRIBUTES.approvalSchemes.trips.item.title }
            />
          );
        } }
        items={ value }
        qaAttrLastEl={ QA_ATTRIBUTES.approvalSchemes.trips.item.last }
      />
    );

    return (
      <div className={ styles.list }>
        <div className={ styles.header }>
          { renderAddButton() }
        </div>
        <div className={ styles.content }>
          { newListContent }
        </div>
      </div>
    );
  };

  const prepareRemoveErrorContent = (code: any) => {
    if (code === 409) {
      return (
        <div className={ styles.error }>
          <Text type='bold_20'>
            { LABELS.UNABLE_TO_DELETE }
          </Text>
          <Text className={ styles.description }>
            { LABELS.UNABLE_TO_DELETE_DESC }
          </Text>
        </div>
      );
    }

    return (
      <NoResults label={ TEXTS.SOMETHING_WENT_WRONG } />
    );
  };

  const renderBasicRemoveDialogContent = () => {
    const { Name, Id } = schemeRemoveDialog;

    return (
      <>
        <Text type='bold_14'>
          { LABELS.DELETE_CONFIRM } <b>{ Name }</b>?
        </Text>
        <div className={ styles.actions }>
          <Button
            onClick={ () => handleRemoveScheme(Number(Id)) }
            loading={ loadingRemoveDialog }
          >
            { LABELS.REMOVE }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual-medium'
            onClick={ handleCloseDialog }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </>
    );
  };

  const renderRemoveDialog = () => {
    const { show: showError, code } = errorRemoveDialog;

    const content = showError
      ? prepareRemoveErrorContent(code)
      : renderBasicRemoveDialogContent();

    return (
      <Dialog
        show={ showRemoveDialog }
        onChange={ handleCloseDialog }
      >
        { content }
      </Dialog>
    );
  };

  if (loading || loadingList) {
    return <PageLoader />;
  }

  if (error) {
    return (
      <div className={ styles.empty }>
        <NoResults label={ TEXTS.SOMETHING_WENT_WRONG } />
      </div>
    );
  }

  return (
    <>
      { renderList() }
      { renderRemoveDialog() }
    </>
  );
});

export { ApprovalSchemesTab };
