import React, { useEffect, useState, useRef, RefCallback, RefObject } from 'react';
import { Button, Dialog, RadioButton, Text, Tooltip, Input } from 'new-ui';
import { getText } from '../../../../../i18n';

import { isSmartAgent } from '../../../../bi/utils/env';
import { replacementComma } from '../../../../bi/utils/text';

import { PREPAYMENTS, PREPAYMENTS_TYPE, PREPAYMENTS_METHOD } from '../../../../bi/constants/expenseReport';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './styles/prepayment.module.css';

const LABELS = {
  INPUT: getText('expenseReports:report.prepaymentDialog.input'),
  SAVE: getText('common:save'),
  CANCEL: getText('common:undo'),
  ERROR: getText('expenseReports:report.prepaymentDialog.error'),
  TOOLTIP: getText('expenseReports:report.prepaymentDialog.tooltip'),
};

const AMOUNT = 10000;

const PREPAYMENTS_METHOD_CASH = PREPAYMENTS_METHOD[PREPAYMENTS.CASH];
const PREPAYMENTS_METHOD_CARD = PREPAYMENTS_METHOD[PREPAYMENTS.CARD];

interface IPrepaymentDialog {
  item?: any,
  show: boolean,
  rewritable: boolean,
  onClose(): void,
  onSave(data: {
    Type: any;
    Amount: number;
    Method: number;
    Id: number;
  }): void,
  canBeEdited?: boolean,
}

const PrepaymentDialog = ({
  item = null,
  rewritable,
  show,
  onClose,
  onSave,
  canBeEdited = true,
}: IPrepaymentDialog) => {
  const [inputValue, setInputValue] = useState(item ? item.Amount : AMOUNT);
  const [selected, setSelected] = useState(item ?
    PREPAYMENTS_METHOD[item.Method].TYPE :
    PREPAYMENTS_METHOD_CASH.TYPE);
  const inputRef: RefCallback<string> | RefObject<any> | null = useRef();

  const typeLabel = item ? PREPAYMENTS_TYPE[item.Type].LABEL : PREPAYMENTS_TYPE[PREPAYMENTS.PREPAYMENT].LABEL;
  const disabledInput = item && item.Type === PREPAYMENTS.DAILY_EXPENSES && !rewritable;

  useEffect(() => {
    if (show) {
      setInputValue(item ? item.Amount : AMOUNT);
      setSelected(item ?
        PREPAYMENTS_METHOD[item.Method].TYPE :
        PREPAYMENTS_METHOD_CASH.TYPE);

      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current.focus();
        }, 100);
      }
    }
  }, [show, item]);

  const handleSave = () => {
    const value = replacementComma(inputValue.toString());
    const preparedValue = !value ? 0 : value;

    const data = {
      Amount: Number(preparedValue),
      Type: item ? item.Type : PREPAYMENTS.PREPAYMENT,
      Method: selected === PREPAYMENTS_METHOD_CASH.TYPE ? PREPAYMENTS.CASH : PREPAYMENTS.CARD,
      Id: item?.Id || 0,
    };

    onSave(data);

    return onClose();
  };

  return (
    <Dialog
      qaAttr={ QA_ATTRIBUTES.expenseReport.item.prepayment.close }
      showClosing
      show={ show }
      className={ styles.wrapper }
      onChange={ onClose }
      isForm
    >
      <Text qaAttr={ QA_ATTRIBUTES.expenseReport.item.prepayment.title } type='bold_20'>
        {typeLabel}
      </Text>
      <Text className={ styles.input_text }>
        {LABELS.INPUT}
      </Text>
      <Tooltip
        show={ disabledInput }
        renderContent={ () => (
          <Text
            color='white'
            type='NORMAL_14_130'
            className={ styles.tooltip_box }
          >
            {LABELS.TOOLTIP}
          </Text>
        ) }
      >
        <Input
          qaAttr={ QA_ATTRIBUTES.expenseReport.item.prepayment.sum }
          className={ styles.input }
          value={ inputValue }
          disabled={ disabledInput }
          onChange={ setInputValue }
        />
      </Tooltip>
      <div className={ styles.wrapped_radio }>
        <RadioButton
          qaAttr={ QA_ATTRIBUTES.expenseReport.item.prepayment.radio.cash }
          value={ PREPAYMENTS_METHOD_CASH.TYPE }
          alternativeDesign={ isSmartAgent }
          checked={ selected === PREPAYMENTS_METHOD_CASH.TYPE }
          className={ styles.radio_left }
          onChange={ setSelected }
        >
          <Text type='NORMAL_14'>
            {PREPAYMENTS_METHOD_CASH.RU}
          </Text>
        </RadioButton>
        <RadioButton
          qaAttr={ QA_ATTRIBUTES.expenseReport.item.prepayment.radio.card }
          value={ PREPAYMENTS_METHOD_CARD.TYPE }
          alternativeDesign={ isSmartAgent }
          checked={ selected === PREPAYMENTS_METHOD_CARD.TYPE }
          className={ styles.radio_right }
          onChange={ setSelected }
        >
          <Text type='NORMAL_14'>
            {PREPAYMENTS_METHOD_CARD.RU}
          </Text>
        </RadioButton>
      </div>
      <div className={ styles.actions }>
        <Button
          qaAttr={ QA_ATTRIBUTES.expenseReport.item.prepayment.save }
          disabled={ !canBeEdited }
          formType='submit'
          className={ styles.submit }
          onClick={ handleSave }
        >
          { LABELS.SAVE }
        </Button>
        <Button
          qaAttr={ QA_ATTRIBUTES.expenseReport.item.prepayment.cancel }
          type='textual-medium'
          onClick={ onClose }
          className={ styles.cancel }
        >
          { LABELS.CANCEL }
        </Button>
      </div>
    </Dialog>
  );
};

export default PrepaymentDialog;
