import React from 'react';

import { getText } from '../../../../../i18n/index';

import styles from '../../index.module.css';

const LABELS = {
  FULL_NAME: getText('settings:travelers.fullName'),
  DATE_OF_BIRTH: getText('settings:travelers.dateOfBirth'),
  CITIZENSHIP: getText('settings:travelers.citizenship'),
  DOCUMENT: getText('settings:travelers.documents'),
  PHONE: getText('settings:travelers.phoneNumber'),
  EMAIL: getText('settings:travelers.email'),
};

const TravelersTableHeader = () => (
  <div className={ styles.table_header }>
    <div className={ styles.row }>
      <div className={ styles['col-1'] }>{ LABELS.FULL_NAME }</div>
      <div className={ styles['col-2'] }>{ LABELS.DATE_OF_BIRTH }</div>
      <div className={ styles['col-3'] }>{ LABELS.CITIZENSHIP }</div>
      <div className={ styles['col-4'] }>{ LABELS.DOCUMENT }</div>
      <div className={ styles['col-5'] }>{ LABELS.PHONE }</div>
      <div className={ styles['col-6'] }>{ LABELS.EMAIL }</div>
    </div>
  </div>
);

export { TravelersTableHeader };
