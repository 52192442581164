// @ts-nocheck
import ACTION from './action';
import Store from '../../store';

import { getText } from '../../../../i18n';

import { REQUESTS_STATUSES } from '../../constants/requests';

import { mapGuids } from '../../utils/integration1S';
import { employeesListMapper } from '../../utils/employees';
import { departmentsListMapper } from '../../utils/departments';

const DEFAULT = {
  loading: true,
  loadingRequestsFlag: false,
  loadingTripRequest: false,
  requestsList: [],
  filteredRequestsList: [],
  filters: {
    statuses: {
      list: REQUESTS_STATUSES,
      selected: [],
    },
    departments: {
      list: [],
      selected: [],
    },
    employees: {
      list: [],
      selected: [],
    },
    dates: {
      start: null,
      end: null,
    },
  },
  pagination: {
    visibleRequests: [],
    itemsOnPage: 15,
    currentPage: 1,
  },
  request: {},
  tripsRequests: [],
  integration: {
    loading: false,
    enabled: false,
    guids: [],
    failedCompanies: [],
    editMode: {},
    showEmptyIdentifier: false,
    error: false,
  },
  requestItem: {},
};

const LABELS = {
  REQUEST: getText('components:tripItem.orderHeader.request'),
};

const getVisibleRequests = (requests, itemsOnPage, currentPage) => (
  requests.slice((currentPage - 1) * itemsOnPage, currentPage * itemsOnPage)
);

const getFailedCompanies = ({ previousCompanies = [], companies, response: { Added = [] }, currentGuid, previousGuid }) => {
  const shouldBeAdded = companies.filter(company => !previousCompanies.includes(company));

  if (currentGuid !== previousGuid && Added.length === 0 && companies.length > 0) {
    return [...companies];
  }

  return shouldBeAdded.filter(company => !Added.includes(company));
};

const replaceGuidInEditMode = ({ editMode, previousGuid, currentGuid }) => {
  const { [previousGuid]: notNeeded, ...newEditMode } = editMode;

  return {
    ...newEditMode,
    [currentGuid]: false,
  };
};

const updateEditMode = ({ currentGuid, previousGuid, editMode, failedCompanies = [] }) => {
  if (failedCompanies.length > 0) {
    return { ...editMode };
  }

  if (previousGuid !== '' && previousGuid !== currentGuid) {
    return replaceGuidInEditMode({ editMode, previousGuid, currentGuid });
  }

  if (!Object.keys(editMode).includes(currentGuid)) {
    return { ...editMode, [currentGuid]: false };
  }

  return ({ ...editMode, [currentGuid]: !editMode[currentGuid] });
};

const updateGuids = ({
  currentGuid,
  companies,
  previousGuid,
  guids,
  failedCompanies,
}) => {
  const index = guids.findIndex(({ guid }) => guid === currentGuid);
  const previousIndex = guids.findIndex(({ guid }) => guid === previousGuid);

  if (companies.length === 0 && (previousIndex === -1 || failedCompanies.length > 0)) {
    return [...guids];
  }

  if (companies.length === 0) {
    return [
      ...guids.slice(0, index),
      ...guids.slice(index + 1),
    ];
  }

  if (!previousGuid) {
    return [
      ...guids,
      { guid: currentGuid, companies },
    ];
  }

  if (previousGuid !== currentGuid) {
    return [
      ...guids.slice(0, previousIndex),
      {
        guid: currentGuid,
        companies,
      },
      ...guids.slice(previousIndex + 1),
    ];
  }

  return [
    ...guids.slice(0, index),
    { guid: currentGuid, companies },
    ...guids.slice(index + 1),
  ];
};

const reducer = (action, state) => {
  let newState = { ...state };

  switch (action.type) {
    case ACTION.LOADREQUESTS: {
      const { payloadRequests } = action.payload;
      const { pagination: { itemsOnPage } } = newState;

      newState = {
        ...newState,
        loading: false,
        loadingRequestsFlag: false,
        requestsList: payloadRequests,
        filteredRequestsList: payloadRequests,
        filters: {
          ...newState.filters,
          employees: {
            list: employeesListMapper(payloadRequests),
            selected: [],
          },
          departments: {
            list: departmentsListMapper(action.payload),
            selected: [],
          },
        },
        pagination: {
          ...newState.pagination,
          visibleRequests: getVisibleRequests(payloadRequests, itemsOnPage, 1),
        },
      };
      break;
    }

    case ACTION.UPDATE_LOADING: {
      newState.loading = action.payload;
      break;
    }

    case ACTION.DISABLELOADING: {
      newState.loading = false;
      break;
    }

    case ACTION.LOADREQUEST: {
      newState.loading = false;
      newState.request = action.payload;

      break;
    }

    case ACTION.LOAD_TRIPS_REQUEST: {
      const requestsMapper = action.payload.map(requestId => ({
        label: `${LABELS.REQUEST} ${requestId}`,
        value: requestId,
      }));

      newState.loading = false;
      newState.tripsRequests = requestsMapper;

      break;
    }

    case ACTION.UPDATE_LOADING_REQUESTS_FLAG: {
      newState.loadingRequestsFlag = true;

      break;
    }

    case ACTION.UPDATE_LOADING_TRIP_REQUEST: {
      newState.loadingTripRequest = !newState.loadingTripRequest;

      break;
    }

    case ACTION.SETREQUESTITEM: {
      newState.requestItem = action.payload;

      break;
    }

    case ACTION.GETINTEGRATION: {
      const guids = mapGuids(action.payload);
      const enabled = guids.length > 0;
      const editMode = guids.reduce((acc, { guid }) => ({ ...acc, [guid]: false }), {});

      newState = {
        ...state,
        loading: false,
        integration: {
          ...state.integration,
          enabled,
          guids,
          editMode,
        },
      };

      break;
    }

    case ACTION.SETADDINTEGRATIONLOADING: {
      newState = {
        ...state,
        integration: {
          ...state.integration,
          loading: action.payload,
        },
      };

      break;
    }

    case ACTION.SWITCHINTEGRATION: {
      const { payload: { guid: currentGuid, companies, response, previousGuid, previousCompanies } } = action;
      const { integration: { guids, editMode } } = state;

      const failedCompanies = getFailedCompanies({ previousCompanies, companies, currentGuid, previousGuid, response });
      const addedCompanies = companies.filter(company => !failedCompanies.includes(company));
      const updatedGuids = updateGuids({ currentGuid, companies: addedCompanies, previousGuid, guids, failedCompanies });
      const updatedEditMode = updateEditMode({ currentGuid, previousGuid, editMode, failedCompanies });

      newState = {
        ...state,
        integration: {
          ...state.integration,
          loading: false,
          editMode: updatedEditMode,
          guids: updatedGuids,
          failedCompanies,
          showEmptyIdentifier: false,
        },
      };

      break;
    }

    case ACTION.FAILEDSWITCHINTEGRATION: {
      newState = {
        ...state,
        integration: {
          ...state.integration,
          loading: false,
          error: true,
        },
      };

      break;
    }

    case ACTION.ENABLEINTEGRATION: {
      newState = {
        ...state,
        integration: {
          ...state.integration,
          enabled: true,
        },
      };

      break;
    }

    case ACTION.TOGGLEEDITMODE: {
      const { integration: { editMode } } = state;
      const { payload: { currentGuid, previousGuid } } = action;

      const updatedEditMode = updateEditMode({ currentGuid, previousGuid, editMode });

      newState = {
        ...state,
        integration: {
          ...state.integration,
          editMode: updatedEditMode,
        },
      };

      break;
    }

    case ACTION.DISABLEINTEGRATION: {
      newState = {
        ...state,
        integration: {
          ...state.integration,
          enabled: false,
          guids: [],
          showEmptyIdentifier: false,
          editMode: {},
          failedCompanies: [],
        },
      };

      break;
    }

    case ACTION.TOGGLEEMPTYIDENTIFIER: {
      const { payload } = action;

      newState = {
        ...state,
        integration: {
          ...state.integration,
          showEmptyIdentifier: payload,
        },
      };

      break;
    }

    case ACTION.HIDEERRORDIALOG: {
      newState = {
        ...state,
        integration: {
          ...state.integration,
          failedCompanies: [],
          error: false,
        },
      };

      break;
    }

    case ACTION.CHANGEPAGE: {
      newState.pagination.currentPage = action.payload;

      const { filteredRequestsList, pagination: { itemsOnPage, currentPage } } = newState;

      newState.pagination.visibleRequests = getVisibleRequests(filteredRequestsList, itemsOnPage, currentPage);

      break;
    }
    case ACTION.CHANGE_FILTERS: {
      const { pagination: { itemsOnPage } } = newState;
      const {
        value,
        statuses,
        employees,
        departments,
        dates,
      } = action.payload;

      newState = {
        ...newState,
        filteredRequestsList: value,
        filters: {
          ...newState.filters,
          statuses: {
            ...newState.filters.statuses,
            selected: statuses.selected,
          },
          departments: {
            ...newState.filters.departments,
            selected: departments.selected,
          },
          employees: {
            ...newState.filters.employees,
            selected: employees.selected,
          },
          dates },
        loadingRequestsFlag: false,
        pagination: {
          ...newState.pagination,
          visibleRequests: getVisibleRequests(value, itemsOnPage, 1),
          currentPage: 1 },
      };

      break;
    }

    case ACTION.CLEAR: {
      DEFAULT.pagination.visibleRequests = [];
      newState = DEFAULT;

      break;
    }
  }

  return newState;
};

const createStore = () => new Store(reducer, DEFAULT);

export default createStore;
