import { MultiSelect, MultiSelectValuesNested } from 'new-ui';
import React from 'react';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';

import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import { getText } from '../../../../../../i18n';

import { FILTERS_FIELDS, TYPE } from '../../../../../bi/constants/chartsAnalytics';

import styles from './styles.module.css';

const LABELS = {
  EMPLOYEES: {
    SEARCH_PLACEHOLDER: getText('chartsAnalytics:filters.employees.lastNameOfTheEmployee'),
    VALUE: getText('chartsAnalytics:filters.employees.value'),
  },
  DEPARTMENTS: {
    VALUE: getText('chartsAnalytics:filters.departments.value'),
    SEARCH_PLACEHOLDER: getText('chartsAnalytics:filters.departments.nameOfDepartments'),
  },
  PROJECTS: {
    VALUE: getText('chartsAnalytics:filters.projects.value'),
    SEARCH_PLACEHOLDER: getText('chartsAnalytics:filters.projects.nameOfCostCenter'),
  },
  TAGS: {
    VALUE: getText('chartsAnalytics:filters.tags.value'),
    SEARCH_PLACEHOLDER: getText('chartsAnalytics:filters.tags.nameOfTag'),
  },
};

interface ChartFiltersProps {
  type: string,
  setDepartments(values: any): void,
  setEmployees(values: any): void,
  setProjects(values: any): void,
  setAnalytics(values: any): void,
  setTags(values: any): void,
  getInfo(tabId: string): void,
  setAutocompleteEmployees(v: any, type: string): void,
  setAutocompleteDepartments(v: any, type: string): void,
  setAutocompleteProjects(v: any, type: string): void,
  setAutocompleteTags(v: any, type: string): void,
  setFiltersType(v: string):void,
  resetPaginate():void,
}

interface MatchParams {
  tabId: string
}

type SelectValuesType = number[] | string[] | MultiSelectValuesNested[];

const ChartAnalyticsFilters = observer(({
  type,
  setEmployees,
  setDepartments,
  setProjects,
  getInfo,
  setAnalytics,
  setTags,
  setAutocompleteTags,
  setAutocompleteEmployees,
  setAutocompleteDepartments,
  setAutocompleteProjects,
  setFiltersType,
  resetPaginate,
}: ChartFiltersProps) => {
  const { chartsAnalyticsStore } = useStores([MOBX_STORES.CHARTS_ANALYTICS_STORE]);
  const {
    filters: {
      employees,
      departments,
      projects,
      tags,
      analytics: {
        list: analList,
        selected: analSelected,
      },
    },
    notShowFilterDepartment,
    notShowFilterProject,
  } = chartsAnalyticsStore;

  const { params: { tabId } } = useRouteMatch<MatchParams>();

  const handleChangeEmployees = (values: SelectValuesType) => {
    setEmployees(values);
    resetPaginate();
    setFiltersType(FILTERS_FIELDS.EMPLOYEES);
    getInfo(tabId);
  };

  const handleChangeDepartments = (values: SelectValuesType) => {
    setDepartments(values);
    resetPaginate();
    setFiltersType(FILTERS_FIELDS.DEPARTMENTS);
    getInfo(tabId);
  };

  const handleChangeProjects = (values: SelectValuesType) => {
    setProjects(values);
    resetPaginate();
    setFiltersType(FILTERS_FIELDS.PROJECTS);
    getInfo(tabId);
  };

  const handleChangeAnalytics = (values: SelectValuesType) => {
    setAnalytics(values);
    resetPaginate();
    setFiltersType(FILTERS_FIELDS.ANALYTICS);
    getInfo(tabId);
  };

  const handleChangeTags = (values: SelectValuesType) => {
    setTags(values);
    resetPaginate();
    setFiltersType(FILTERS_FIELDS.TAGS);
    getInfo(tabId);
  };

  const renderEmployees = () => {
    if (type === TYPE.MICE || !employees.list.length) {
      return null;
    }

    return (
      <div className={ styles.filter }>
        <MultiSelect
          withLabel
          placeholder={ LABELS.EMPLOYEES.VALUE }
          list={ employees.list }
          values={ employees.selected }
          search
          onSearchFunction={ value => setAutocompleteEmployees(value, type) }
          searchPlaceholder={ LABELS.EMPLOYEES.SEARCH_PLACEHOLDER }
          onChange={ values => handleChangeEmployees(values) }
        />
      </div>
    );
  };

  const renderDepartments = () => {
    if (notShowFilterDepartment || type === TYPE.MICE || !departments.list.length) {
      return null;
    }

    return (
      <div className={ styles.filter }>
        <MultiSelect
          withLabel
          placeholder={ LABELS.DEPARTMENTS.VALUE }
          list={ departments.list }
          values={ departments.selected }
          search
          onSearchFunction={ value => setAutocompleteDepartments(value, type) }
          searchPlaceholder={ LABELS.DEPARTMENTS.SEARCH_PLACEHOLDER }
          onChange={ values => handleChangeDepartments(values) }
        />
      </div>
    );
  };

  const renderProjects = () => !notShowFilterProject && (
    <div className={ styles.filter }>
      <MultiSelect
        withLabel
        placeholder={ LABELS.PROJECTS.VALUE }
        list={ projects.list }
        values={ projects.selected }
        search
        onSearchFunction={ v => setAutocompleteProjects(v, type) }
        searchPlaceholder={ LABELS.PROJECTS.SEARCH_PLACEHOLDER }
        onChange={ values => handleChangeProjects(values) }
      />
    </div>
  );

  const renderCustomAnalytics = () => analList.reduce((acc: any, { id, name, values }: { id: number, name: string, values: any }) => {
    const valuesSelected = analSelected.filter((selectedValueId: number) => values.some(({ id: valueId }:{ id: number }) => valueId === selectedValueId));
    const list = values.map(({ name: valuesName, id: valuesId }: { name: string, id: number }) => ({ label: valuesName, value: valuesId }));

    if (!list.length) {
      return acc;
    }

    return [
      ...acc,
      (
        <div key={ id } className={ styles.filter }>
          <MultiSelect
            placeholder={ name }
            list={ list }
            values={ valuesSelected }
            onChange={ v => handleChangeAnalytics([
              ...analSelected.filter((i: any) => !list.some(({ value }: { value: number }) => value === i)),
              ...(v as number[]),
            ])
            }
            search
          />
        </div>
      ),
    ];
  }, []);

  const renderTags = () => (
    <div className={ styles.filter }>
      <MultiSelect
        withLabel
        search
        placeholder={ LABELS.TAGS.VALUE }
        list={ tags.list }
        values={ tags.selected }
        onSearchFunction={ v => setAutocompleteTags(v, type) }
        searchPlaceholder={ LABELS.TAGS.SEARCH_PLACEHOLDER }
        onChange={ values => handleChangeTags(values) }
      />
    </div>
  );

  return (
    <div className={ styles.filters }>
      { renderEmployees() }
      { renderDepartments() }
      { renderProjects() }
      { renderTags() }
      { renderCustomAnalytics() }
    </div>
  );
});

export { ChartAnalyticsFilters };
