import { Moment, MomentSetObject } from 'moment';
import ACTION from '../action';
import Store from '../../../store/index';

import { ISearchStore } from '../types';

import guid from '../../../utils/guid';
import { getMoment, momentObject, nextDateAfterCurrent, noMoreValueDays } from '../../../utils/formatDate';
import { createSuggests } from '../../../utils/hotels';

import { FIELD_HOTEL_FORM_RADIO } from '../../../constants/hotel';

const parseTime = (string: string): MomentSetObject => {
  const [hour, minute] = string.split(':');

  return { hour: Number(hour), minute: Number(minute) };
};

const createNewState = (startDate?: string | Moment, endDate?: string | Moment, startTime?: string, endTime?: string) => {
  let currentDate = getMoment().add(1, 'd').startOf('d');
  const minDate = getMoment().startOf('d');
  let checkoutDefaultDate = currentDate.clone().add(2, 'd');

  if (startDate) {
    currentDate = typeof startDate === 'string' ? momentObject(startDate).startOf('d') : startDate;
  }

  if (endDate) {
    checkoutDefaultDate = typeof endDate === 'string' ? momentObject(endDate).startOf('d') : endDate;
  }

  const preparedCheckin = startTime ? currentDate.clone().set(parseTime(startTime)) : null;
  const preparedCheckout = endTime ? checkoutDefaultDate.clone().set(parseTime(endTime)) : null;

  return {
    guid: guid(),
    region: {
      label: '',
      selected: {},
      isForeign: false,
      suggests: [],
    },
    travellersCount: 1, // кол-во гостей
    adult: 1, // тип размещения
    bedType: 1, // тип кровати
    checkin: currentDate,
    checkout: checkoutDefaultDate,
    checkinMinDate: minDate,
    checkoutMinDate: currentDate.clone().add(1, 'd'),
    customCheckin: preparedCheckin,
    customCheckout: preparedCheckout,
    isTextualOpenCustomCheckin: false,
    isTextualOpenCustomCheckout: false,
    breakfast: FIELD_HOTEL_FORM_RADIO.NOT_DEFINED,
    freeCancellation: FIELD_HOTEL_FORM_RADIO.NOT_DEFINED,
    comment: '',
    arrayEmployees: [],
    employeesId: [],
    isTravelApproval: false,
    schemeLoading: false,
    redirectSearchId: null,
    textTemplateWarning: '',
    redirectSearchType: '',
    optimalRate: '',
  };
};

const getHourAndMinute = (date: Moment) => ({ hour: date.get('hour'), minute: date.get('minute') });

const reducer = (action: { [key: string]: any, type: string }, state: ISearchStore) => {
  switch (action.type) {
    case ACTION.UPDATECHECKIN: {
      const preparedCheckin = action.payload ? action.payload.clone().set({ hour: 0, minute: 0 }) : null;
      const preparedCustomCheckin = (state.customCheckin && preparedCheckin) ? preparedCheckin.clone().set(getHourAndMinute(state.customCheckin)) : null;
      const preparedCheckoutMinDate = action.payload ? action.payload.clone().add(1, 'd') : null;
      const newState = {
        ...state,
        checkin: preparedCheckin,
        customCheckin: preparedCustomCheckin,
        checkoutMinDate: preparedCheckoutMinDate,
        guid: guid(),
      };

      if (newState.checkin && newState.checkin.isSameOrAfter(newState.checkout, 'day')) {
        newState.customCheckout = newState.customCheckout
          ? newState.checkin.clone().add(1, 'd').set(getHourAndMinute(newState.customCheckout))
          : null;
        newState.checkout = newState.checkin.clone().add(1, 'd');
      }

      return newState;
    }

    case ACTION.UPDATECUSTOMCHECKIN: {
      return {
        ...state,
        customCheckin: action.payload ? action.payload.clone() : action.payload,
        guid: guid(),
      };
    }

    case ACTION.UPDATECHECKOUT: {
      const preparedCheckout = action.payload ? action.payload.clone().set({ hour: 0, minute: 0 }) : null;
      const preparedCustomCheckout = (state.customCheckout && preparedCheckout) ? preparedCheckout.clone().set(getHourAndMinute(state.customCheckout)) : null;

      return {
        ...state,
        checkout: preparedCheckout,
        customCheckout: preparedCustomCheckout,
        guid: guid(),
      };
    }

    case ACTION.UPDATECUSTOMCHECKOUT: {
      return {
        ...state,
        customCheckout: action.payload ? action.payload.clone() : action.payload,
        guid: guid(),
      };
    }

    case ACTION.UPDATE_TRAVELLERS_COUNT: {
      const travellersCount = parseInt(action.payload, 10);
      const adult = travellersCount === 1 ? 1 : state.adult;

      return {
        ...state,
        adult,
        travellersCount,
        guid: guid(),
      };
    }

    case ACTION.UPDATE_BREAKFAST: {
      return {
        ...state,
        breakfast: action.payload,
      };
    }

    case ACTION.UPDATE_FREE_CANCELLATION: {
      return {
        ...state,
        freeCancellation: action.payload,
      };
    }

    case ACTION.UPDATE_COMMENT: {
      return {
        ...state,
        comment: action.payload,
      };
    }

    case ACTION.SELECT_EMPLOYEE: {
      const { value, id } = action.payload;

      return {
        ...state,
        arrayEmployees: value,
        employeesId: id,
      };
    }

    case ACTION.REMOVE_EMPLOYEE: {
      return {
        ...state,
        arrayEmployees: state.arrayEmployees.filter(item => item !== action.value),
      };
    }

    case ACTION.UPDATEADULT: {
      return {
        ...state,
        adult: parseInt(action.payload, 10),
        guid: guid(),
      };
    }

    case ACTION.UPDATEREGIONSUGGEST: {
      return {
        ...state,
        region: {
          ...state.region,
          label: action.payload.query,
          suggests: createSuggests(action.payload.items),
          selected: {},
          loading: action.payload.loading || false,
        },
      };
    }

    case ACTION.UPDATE_REGION_SUGGEST_LOADING: {
      return {
        ...state,
        region: {
          ...state.region,
          loading: action.payload.loading,
        },
      };
    }

    case ACTION.UPDATEREGIONSELECTED: {
      if (action.payload.title) {
        return state;
      }

      return {
        ...state,
        region: {
          label: action.payload.Name,
          isForeign: action.payload.IsForeign,
          selected: action.payload,
          suggests: [],
        },
        guid: guid(),
      };
    }

    case ACTION.CLEARREGION: {
      return {
        ...state,
        guid: guid(),
        region: {
          ...state.region,
          suggests: [],
        },
      };
    }

    case ACTION.NEWSEARCH: {
      return {
        ...createNewState(),
      };
    }

    case ACTION.SCHEME_LOADING: {
      return {
        ...state,
        schemeLoading: action.value,
      };
    }

    case ACTION.APPROVAL_REQUEST_SEARCH: {
      const { payload } = action;
      const { Destination, StartDate, EndDate } = payload;
      const employeesLength = payload.Employees.length;

      return {
        ...state,
        guid: guid(),
        region: {
          label: Destination?.Name || '',
          selected: Destination,
          suggests: [],
        },
        travellersCount: employeesLength > 6 ? 6 : employeesLength,
        adult: 1,
        checkin: nextDateAfterCurrent(StartDate),
        checkout: noMoreValueDays(nextDateAfterCurrent(StartDate), EndDate, 28),
        customCheckin: null,
        customCheckout: null,
        breakfast: FIELD_HOTEL_FORM_RADIO.NOT_DEFINED,
        freeCancellation: FIELD_HOTEL_FORM_RADIO.NOT_DEFINED,
        comment: '',
        arrayEmployees: [],
        isTravelApproval: true,
        schemeLoading: true,
      };
    }

    case ACTION.INITNEWSEARCH: {
      const {
        CheckinDate,
        CheckoutDate,
        CheckinTime,
        CheckoutTime,
        RegionName,
        hotelId,
        Label,
        TravellersCount,
        RedirectSearchId,
        RedirectSearchType,
        OptimalRate,
      } = action.payload;

      const newState = createNewState(CheckinDate, CheckoutDate, CheckinTime, CheckoutTime);

      return {
        ...newState,
        region: {
          label: RegionName || state.region.label,
          isForeign: state.region.isForeign,
          selected: { ...state.region.selected, Id: hotelId },
          suggests: state.region.suggests,
        },
        addToHistory: action.payload.AddToHistory || true,
        adult: parseInt(action.payload.GuestsCount, 10),
        hotelId,
        label: Label,
        fromHistory: action.payload.FromHistory,
        travellersCount: Number(TravellersCount),
        redirectSearchId: RedirectSearchId,
        redirectSearchType: RedirectSearchType,
        optimalRate: OptimalRate,
        isTextualOpenCustomCheckin: state.isTextualOpenCustomCheckin,
        isTextualOpenCustomCheckout: state.isTextualOpenCustomCheckout,
      };
    }

    case ACTION.UPDATEREGIONSETTINGS: {
      const {
        RedirectSearchId,
        RedirectSearchType,
      } = action.payload;

      return {
        ...state,
        redirectSearchId: RedirectSearchId,
        redirectSearchType: RedirectSearchType,
      };
    }

    case ACTION.BACKTOSEARCHLIST: {
      return {
        ...state,
        region: { ...state.region, selected: { ...state.region.selected, IsRegion: true } },
      };
    }

    case ACTION.SETHISTORYINSTANCE: {
      const { CheckinDate, CheckoutDate, CheckinTime, CheckoutTime, TravellersCount } = action.payload;
      const newState = createNewState(CheckinDate, CheckoutDate, CheckinTime, CheckoutTime);

      return {
        ...newState,
        region: {
          label: action.payload.Name,
          selected: {
            IsRegion: action.payload.IsRegion,
            Id: action.payload.Id,
          },
          isForeign: action.payload.IsForeign,
          suggests: [],
        },
        guid: action.payload.guid,
        adult: parseInt(action.payload.GuestsCount, 10),
        travellersCount: TravellersCount,
        hotelId: action.payload.HotelId,
      };
    }

    case ACTION.SETIMMEDIATESEARCH: {
      return {
        ...state,
        immediate: true,
      };
    }

    case ACTION.SET_GUID: {
      return {
        ...state,
        guid: action.payload.guid,
      };
    }

    case ACTION.BED_TYPE_SEARCH: {
      return {
        ...state,
        bedType: action.payload,
      };
    }

    case ACTION.TEXT_TEMPLATE_WARNING: {
      return {
        ...state,
        textTemplateWarning: action.payload,
      };
    }

    case ACTION.SET_IS_TEXTUAL_CHECKIN_TIME: {
      return {
        ...state,
        isTextualOpenCustomCheckin: action.payload,
      };
    }

    case ACTION.SET_IS_TEXTUAL_CHECKOUT_TIME: {
      return {
        ...state,
        isTextualOpenCustomCheckout: action.payload,
      };
    }
  }

  return state;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
