import { BANNERS_NAME } from '../../constants/banners';
import { BannersStore, BannersStoreType } from './store';

import { Banner } from './types';

class BannersService {
  api: any;
  store: BannersStoreType;

  constructor(api: any) {
    this.api = api.banners;
    this.store = BannersStore;
  }

  sendMobileAppLinks = () => this.api.sendMobileAppLinks();

  setBanners = () => {
    if (!this.store.bannersState) return;

    Object.keys(this.store.bannersState).forEach((banner: string) => {
      this.store.addBanner({
        name: banner,
        shown: this.store.bannersState[banner],
      });
    });

    const activeBanner = this.store.getBanners.find(item => !item.shown);

    this.store.setActiveBanner(activeBanner || null);
  };

  updateActiveBanner = () => {
    this.store.updateBanner(this.store.activeBanner as Banner);
    const activeBanner = this.store.getBanners.find(item => !item.shown);
    this.store.setActiveBanner(activeBanner || null);
  };

  getBannersState = async () => {
    try {
      const bannersState = await this.api.getStateBanners();

      this.store.setBannersState(bannersState);
    } catch (e) {
      this.store.setBannersState({});
    }
  };

  closeVipHallBannerState = () => this.api.setStateBanners(BANNERS_NAME.VIP_HALL_BANNER);
}

export default BannersService;
