import React from 'react';
import {
  ISmartwayDocumentAggregatorModelsDocumentInfoDto,
  SmartwayCommonEnumsSortType,
} from '@sw/api';
import { IDocumentTablePreset } from 'new-ui/src/components/UniversalTable/types';

import { getText } from '../../../../../i18n';

import { EnhancedSignatureDocumentActionsCell } from '../shared/cells/DocumentActionsCell';
import { DocToEmailInput } from './components/modals/DocToEmailInput';
import { DocToEmailSent } from './components/modals/DocToEmailSent';
import { DocumentStatusCell } from '../shared/cells/DocumentStatusCell';
import { ESDNameCell } from '../shared/cells/DocumentNameCell';
import { PreviewDoc } from './components/modals/PreviewDoc';

import { EnhancedSignatureModalTypes } from '../../../../bi/services/signature/types';

export const LABELS = {
  TITLE: getText('settings:documentsForSignatureEnhanced.menuTitle'),
  HOW_IT_WORK: getText('settings:documentsForSignatureEnhanced.howItWork'),
  ADD_DOCUMENT: getText('settings:documentsForSignatureEnhanced.addDocument'),
  DOWNLOAD_REPORT_XLSX: getText('settings:documentsForSignatureEnhanced.downloadReportXLSX'),
  GOV_SERVICE_AUTH: getText('settings:documentsForSignatureEnhanced.govServiceAuth'),
  SEND_TO_GOV: getText('settings:documentsForSignatureEnhanced.sendToGov'),
  TABLE: {
    NAME: getText('settings:documentsForSignature.table.document'),
    STATUS: getText('settings:documentsForSignature.table.status'),
    ACTIONS: getText('settings:documentsForSignature.table.actions'),
  },
  TO_EMAIL_INPUT: {
    ENTER_EMAIL: getText('settings:employees.employee.telegram.pasteMail'),
    SEND: getText('settings:documentsForSignature.send'),
    CANCEL: getText('settings:documentsForSignature.cancel'),
    INCORRECT_EMAIL: getText('settings:employees.employee.telegram.formatError'),
  },
  TO_EMAIL_SENT: {
    SUCCESS_TITLE: getText('settings:documentsForSignatureEnhanced.sent.email.success'),
    SUCCESS_DESCRIPTION: getText('settings:documentsForSignatureEnhanced.sent.email.description'),
    OK: getText('settings:documentsForSignatureEnhanced.sent.ok'),
  },
  TO_GOV_SENT: {
    SUCCESS_TITLE: (count: number) => getText('settings:documentsForSignatureEnhanced.sent.gov.success.', { count }),
    SUCCESS_DESCRIPTION: (count: number) => getText('settings:documentsForSignatureEnhanced.sent.gov.description.', { count }),
  },
};

export const tableSettings = (selectable: boolean = false): IDocumentTablePreset<ISmartwayDocumentAggregatorModelsDocumentInfoDto> => ({
  rowKey: 'documentId',
  selectable,
  sort: {
    defaultColumn: 'name',
    asc: SmartwayCommonEnumsSortType.Asc,
    desc: SmartwayCommonEnumsSortType.Desc,
  },
  fields: {
    name: {
      fieldName: LABELS.TABLE.NAME,
      sortSupport: true,
      Component: ESDNameCell,
    },
    signingStatus: {
      fieldName: LABELS.TABLE.STATUS,
      sortSupport: true,
      Component: DocumentStatusCell,
    },
    documentId: {
      fieldName: LABELS.TABLE.ACTIONS,
      Component: EnhancedSignatureDocumentActionsCell,
    },
  },
});

export const ModalMap = {
  [EnhancedSignatureModalTypes.docToEmailInput]: DocToEmailInput,
  [EnhancedSignatureModalTypes.docToEmailSent]: () => <DocToEmailSent modalType={ EnhancedSignatureModalTypes.docToEmailSent } />,
  [EnhancedSignatureModalTypes.docToGovSent]: () => <DocToEmailSent modalType={ EnhancedSignatureModalTypes.docToGovSent } />,
  [EnhancedSignatureModalTypes.docPreview]: PreviewDoc,
};
