import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DotLoading, NoResults, Text, UniversalTable } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import InputModal from '../../../../../../components/InputModal';

import { isEmail } from '../../../../../../bi/utils/validation';
import { MainAnalytic } from '../../../../../../bi/utils/analytics';

import { tableSettings } from '../../consts';

import styles from '../../styles/index.module.css';

export interface DocumentTableType {
  isEmployeeSelect: boolean,
  isAdmin: boolean,
  onSetSortType(value: string): void,
  onSetCheckBoxValues(values: string[] | []): void,
  onSetPageSignature(pageNumber: number): void,
  onDownloadDocument(docId: string, employeeId: number): void,
  onShowDocument(docId: string, employeeId: number): void,
  onSendDocumentToEmail(email:string, docId: string, employeeId: string | number): void,
  isAllSelect: boolean,
  setIsAllSelect(value: (((prevState: boolean) => boolean) | boolean)): void,
  disabled: boolean
}

const LABELS = {
  NO_RESULT: getText('settings:documentsForSignature.nothingShow'),
  NO_SELECT_EMPLOYEE: getText('settings:documentsForSignature.selectEmployee'),
  SEND_DIALOG: {
    ENTER_EMPLOYEE_EMAIL: getText('settings:documentsForSignature.enterEmail'),
  },
};
export const DocumentTableWrap = observer(({
  isEmployeeSelect,
  onSetSortType,
  onSetCheckBoxValues,
  onSetPageSignature,
  onDownloadDocument,
  onShowDocument,
  onSendDocumentToEmail,
  isAdmin,
  setIsAllSelect,
  disabled,
}: DocumentTableType) => {
  const { simpleSignatureStore } = useStores([MOBX_STORES.SIMPLE_SIGNATURE_STORE]);
  const {
    loadings: { loadingGetDocsSignature },
    documentsForSignature,
    checkBoxValues,
    getBlockedRequestsIds,
    paginate: { currentPage, itemsPerPage, total },
  } = simpleSignatureStore;

  const [showSendDialog, setShowSendDialog] = useState<{
    isOpen: boolean,
    documentId: string,
    employeeId: string | number,
  }>({
    isOpen: false,
    documentId: '',
    employeeId: '',
  });
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);

  const emailValidate = (email: string): void => setIsValidEmail(isEmail(email));

  const handleChangePage = (page: number) => {
    onSetPageSignature(page);
    setIsAllSelect(false);
    onSetCheckBoxValues([]);
  };

  const handleDownloadDocument = (documentId: string, employeeIds: number) => {
    onDownloadDocument(documentId, employeeIds);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.SIGNED_DOCUMENT_DOWNLOAD_BUTTON_PRESSED);
  };

  const handleShowDocument = (documentId: string, employeeIds: number) => {
    onShowDocument(documentId, employeeIds);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.SIGNED_DOCUMENT_OPEN_BUTTON_PRESSED);
  };

  const handleOpenSendToEmailDialog = (documentId: string, employeeId: string | number) => {
    setShowSendDialog({
      isOpen: true,
      documentId,
      employeeId,
    });

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.SIGNED_DOCUMENT_SEND_TO_EMAIL_BUTTON_PRESSED);
  };

  const handleSetCheckBoxValues: typeof onSetCheckBoxValues = (
    newVar,
  ) => {
    onSetCheckBoxValues(newVar);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.SIGN_DOCUMENTS_CHECKBOX_ON);
  };

  const noResultRules = isEmployeeSelect ? LABELS.NO_RESULT : LABELS.NO_SELECT_EMPLOYEE;

  const renderSendDialog = () => (
    <InputModal
      type='email'
      show={ showSendDialog.isOpen }
      title={ LABELS.SEND_DIALOG.ENTER_EMPLOYEE_EMAIL }
      onCancel={ () => setShowSendDialog({
        isOpen: false,
        documentId: '',
        employeeId: '',
      }) }
      onSend={ (value: string) => {
        onSendDocumentToEmail(value, showSendDialog.documentId, showSendDialog.employeeId);
        setShowSendDialog({
          isOpen: false,
          documentId: '',
          employeeId: '',
        });
      } }
      onChange={ emailValidate }
      isValid={ isValidEmail }
    />
  );

  if (loadingGetDocsSignature) {
    return (
      <div className={ styles.loading }>
        <DotLoading />
      </div>
    );
  }

  if (!documentsForSignature.length && !loadingGetDocsSignature) {
    return (
      <NoResults
        renderContent={ () => (
          <div className={ styles.no_results }>
            <Text type='NORMAL_16' className={ styles.subtext }>
              { noResultRules }
            </Text>
          </div>
        ) }
      />
    );
  }

  return (
    <>
      <UniversalTable
        settings={ tableSettings({
          selectable: !(isAdmin || disabled),
          handleOpenSendToEmailDialog,
          handleShowDocument,
          handleDownloadDocument,
        }) }
        items={ documentsForSignature }
        sortField='signingStatus'
        sortDirection={ simpleSignatureStore.sortType }
        onChangeSort={ (_newSort, direction) => onSetSortType(direction as string) }
        selected={ checkBoxValues }
        onChange={ handleSetCheckBoxValues }
        onChangePage={ handleChangePage }
        currentPage={ currentPage }
        itemsPerPage={ itemsPerPage }
        total={ total }
        blockSelect={ getBlockedRequestsIds }
      />
      { renderSendDialog() }
    </>
  );
});
