import React, { FC } from 'react';
import { Text, Select } from 'new-ui';
import { observer } from 'mobx-react';

import { getText } from '../../../../../../../i18n/index';

import { generateTravelers } from '../../../../../../bi/constants/travelers';
import { ADDITIONAL_TYPES } from '../../../../../../bi/constants/vipHall';

import { AdditionalsVips, IAdditionals } from '../../../../../../bi/types/vipHall';

import styles from './index.module.css';

const GUESTS = generateTravelers(9);

const LABELS = {
  GUESTS: getText('vipHall:item.guests'),
  HOUR: getText('vipHall:item.hour'),
};

const SelectCountAdditional: FC<IAdditionals> = observer(({
  index,
  service,
  additionalServices,
  onChangeCount,
}) => {
  const { billing, capacity } = service;

  const isHour = billing === ADDITIONAL_TYPES.HOUR;
  const title = isHour ? LABELS.HOUR : LABELS.GUESTS;
  const itemsForSelect = isHour || billing === ADDITIONAL_TYPES.PERSON
    ? generateTravelers(capacity > 9 ? 9 : capacity)
    : GUESTS;

  return (
    <div className={ `${styles.options} ${styles.placing_wrapper}` }>
      <div className={ styles.guests }>
        <Text type='NORMAL_14' className={ styles.text }>
          { title }
        </Text>
        <div className={ styles.select }>
          <Select
            theme='default-small'
            items={ itemsForSelect }
            value={ additionalServices[additionalServices.findIndex((item: AdditionalsVips) => item.index === index)].count || 1 }
            onChange={ (value: number) => onChangeCount(value, index) }
          />
        </div>
      </div>
    </div>
  );
});

export { SelectCountAdditional };
