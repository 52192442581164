import { QuizData } from '../types/travelPolicyQuiz';
import { MainAnalytic } from './analytics';

const initCheckboxValues = (data: QuizData): string[] => (
  data?.answersCondition !== 'OnlyOne'
    ? data?.answers.filter(({ selected }) => selected).map(({ type }) => type)
    : []
);

const initSelectedValue = (data: QuizData, field: 'type' | 'selectedManualValue') => {
  const selectedSelect = data.answers.find(({ selected }) => selected);

  if (selectedSelect) {
    return selectedSelect[field];
  }

  return '';
};

const makeDataForMainAnalyticsQuiz = (type: number, values: string[], manualValue: string | null) => {
  switch (type) {
    case 1:
      return {
        name: MainAnalytic.ACTIONS.TRAVEL_POLICY.PRESSED_SURVEY_TP_QUESTION_ONE_POPUP,
        data: {
          serviceCost: manualValue || values[0],
        },
      };

    case 2:
      return {
        name: MainAnalytic.ACTIONS.TRAVEL_POLICY.PRESSED_SURVEY_TP_QUESTION_TWO_POPUP,
        data: {
          travelDays: values[0],
        },
      };

    case 3:
      return {
        name: MainAnalytic.ACTIONS.TRAVEL_POLICY.PRESSED_SURVEY_TP_QUESTION_THREE_POPUP,
        data: {
          early: values[0],
        },
      };

    case 4:
      return {
        name: MainAnalytic.ACTIONS.TRAVEL_POLICY.PRESSED_SURVEY_TP_QUESTION_FOR_POPUP,
        data: {
          serviceBaggege: values[0],
        },
      };

    case 5:
      return {
        name: MainAnalytic.ACTIONS.TRAVEL_POLICY.PRESSED_SURVEY_TP_QUESTION_FIVE_POPUP,
        data: {
          serviceClass: values[0],
        },
      };

    case 6:
      return {
        name: MainAnalytic.ACTIONS.TRAVEL_POLICY.PRESSED_SURVEY_TP_QUESTION_SIX_POPUP,
        data: {
          serviceReturn: values[0],
        },
      };

    case 7:
      return {
        name: MainAnalytic.ACTIONS.TRAVEL_POLICY.PRESSED_SURVEY_TP_QUESTION_SEVEN_POPUP,
        data: {
          serviceBook: values[0],
        },
      };
  }

  return null;
};

const analyticsQuizBackData = (type: number) => {
  switch (type) {
    case 1:
      return MainAnalytic.ACTIONS.TRAVEL_POLICY.BACK_SURVEY_TP_QUESTION_ONE_POPUP;

    case 2:
      return MainAnalytic.ACTIONS.TRAVEL_POLICY.BACK_SURVEY_TP_QUESTION_TWO_POPUP;

    case 3:
      return MainAnalytic.ACTIONS.TRAVEL_POLICY.BACK_SURVEY_TP_QUESTION_THREE_POPUP;

    case 4:
      return MainAnalytic.ACTIONS.TRAVEL_POLICY.BACK_SURVEY_TP_QUESTION_FOR_POPUP;

    case 5:
      return MainAnalytic.ACTIONS.TRAVEL_POLICY.BACK_SURVEY_TP_QUESTION_FIVE_POPUP;

    case 6:
      return MainAnalytic.ACTIONS.TRAVEL_POLICY.BACK_SURVEY_TP_QUESTION_SIX_POPUP;

    case 7:
      return MainAnalytic.ACTIONS.TRAVEL_POLICY.BACK_SURVEY_TP_QUESTION_SEVEN_POPUP;
  }

  return null;
};

export {
  initCheckboxValues,
  initSelectedValue,
  makeDataForMainAnalyticsQuiz,
  analyticsQuizBackData,
};
