const DOMEN_HARD_PASSWORD = [
  'vsw.ru',
  'chmz.ru',
  'omk.ru',
  'vw.ru',
  'trubodetal.ru',
  'jmk.ru',
  'energomash.ru',
  'atz.ru',
  'ecolantvyksa.ru',
  'wsv.ru',
  'energomash.ru',
];

const COLOR_TOOLTIP = {
  BLACK: 'black',
  GREEN: 'green',
  RED: 'red',
};

export {
  DOMEN_HARD_PASSWORD,
  COLOR_TOOLTIP,
};
