import React, { ReactNode } from 'react';

import { Button, LinkButton, Text } from 'new-ui';
import { getText } from '../../../../../../i18n';

import styles from './styles/index.module.css';

const BUTTON = {
  NEXT: getText('settings:employees.employee.steps.rights.dialog.button.next'),
  DONE: getText('settings:employees.employee.steps.rights.dialog.button.done'),
  CONFIRM: getText('settings:employees.employee.steps.rights.dialog.button.confirm'),
};

const LINK = {
  CANCEL: getText('common:undo'),
  BACK: getText('common:back'),
};

interface ILabels {
  button: string;
  link: string;
}

const getLabels = (type: string): ILabels | undefined => ({
  default: {
    button: BUTTON.NEXT,
    link: LINK.CANCEL,
  },
  medium: {
    button: BUTTON.NEXT,
    link: LINK.BACK,
  },
  done: {
    button: BUTTON.DONE,
    link: LINK.BACK,
  },
  delete: {
    button: BUTTON.CONFIRM,
    link: LINK.CANCEL,
  },
  copy: {
    button: BUTTON.DONE,
    link: LINK.CANCEL,
  },
}[type]);

interface IRightsDialogWrap {
  loading?: boolean,
  header: string,
  theme?: string,
  children: ReactNode,
  onClickButton(): void,
  onClickCancel(): void,
  qaAttrWrapper?: string,
  qaAttrHeader?: string,
  qaAttrButton?: string,
  qaAttrLink?: string
}

const RightsDialogWrap = ({
  loading = false,
  header,
  theme = 'default',
  children,
  onClickButton,
  onClickCancel,
  qaAttrWrapper = '',
  qaAttrHeader = '',
  qaAttrButton = '',
  qaAttrLink = '',
}: IRightsDialogWrap) => {
  const { button, link } = getLabels(theme) || { button: '', link: '' };

  const title = (
    <Text
      qaAttr={ qaAttrHeader }
      type='bold_20'
      className={ styles.header }
    >
      { header }
    </Text>
  );

  const submitButton = (
    <Button
      loading={ loading }
      type='primary'
      onClick={ onClickButton }
      qaAttr={ qaAttrButton }
    >
      { button }
    </Button>
  );

  const linkButton = (
    <LinkButton
      className={ styles.link }
      onClick={ onClickCancel }
      theme='blue-without-border'
      qaAttr={ qaAttrLink }
    >
      <Text
        type='NORMAL_14'
        color='accent'
      >
        { link }
      </Text>
    </LinkButton>
  );

  return (
    <div className={ styles.wrap } data-qa={ qaAttrWrapper }>
      { title }
      { children }
      <div className={ styles.actions }>
        { submitButton }
        { linkButton }
      </div>
    </div>
  );
};

export { RightsDialogWrap };
