import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { getText } from '../../../../../../../i18n';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import { TYPE_SMART_SERVICE_TYPE } from '../../../../../../bi/constants/chartsAnalytics';
import ROUTES from '../../../../../../bi/constants/routes';

import { PageSubSectionsOfTable } from '../../../common/smartIndex/components/PageSubSectionsOfTable';

const LABELS = {
  TIMELINESS: getText('chartsAnalytics:menuItems.smart.indexChart.tooltip.train.two.timeliness'),
  RETURNS: getText('chartsAnalytics:menuItems.smart.indexChart.tooltip.train.two.returns'),
  TRAVEL_POLICIES_USING: getText('chartsAnalytics:menuItems.smart.indexChart.tooltip.train.two.travelPoliciesUsing'),
  TRAVELLER_BONUS_USING: getText('chartsAnalytics:menuItems.smart.indexChart.tooltip.train.two.travellerBonusUsing'),
};

const SmartAeroexpressSecond = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => {
  const {
    chartsAnalyticsSmartSummaryStore,
  } = useStores([
    MOBX_STORES.CHARTS_ANALYTICS_SMART_SUMMARY_STORE,
  ]);

  const { subSmartType } = chartsAnalyticsSmartSummaryStore;

  const history = useHistory();

  const tooltipByType = ({
    [TYPE_SMART_SERVICE_TYPE.TIMELINESS]: LABELS.TIMELINESS,
    [TYPE_SMART_SERVICE_TYPE.RETURNS]: LABELS.RETURNS,
    [TYPE_SMART_SERVICE_TYPE.TRAVEL_POLICIES_USING]: LABELS.TRAVEL_POLICIES_USING,
    [TYPE_SMART_SERVICE_TYPE.TRAVELLER_BONUS_USING]: LABELS.TRAVELLER_BONUS_USING,
  })[subSmartType];

  const handleOpen = (subPageInfo: string) => {
    chartsAnalyticsService.changeSmartSubPageInfo(subPageInfo);

    return history.push(ROUTES.CHARTS_ANALYTICS.SMART.AEROEXPRESS.THIRD);
  };

  return (
    <PageSubSectionsOfTable
      chartsAnalyticsService={ chartsAnalyticsService }
      onOpen={ handleOpen }
      tooltip={ tooltipByType }
    />
  );
});

export { SmartAeroexpressSecond };
