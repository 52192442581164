import React, { FC, useState } from 'react';
import { Dialog, Text, Button } from 'new-ui';

import { getText } from '../../../../../i18n';

import ROUTES from '../../../../bi/constants/routes';

import { ErrorDialogProps } from '../../../../bi/types/vipHall';

import styles from './index.module.css';

const LABELS = {
  CLOSE: getText('vipHall:payment.close'),
};

const ErrorDialog: FC<ErrorDialogProps> = ({
  history,
  error,
  id,
}) => {
  const [show, setShow] = useState(true);

  const handleBackPage = () => {
    setShow(false);
    history.push(ROUTES.ADDITIONAL_SERVICES.TRIP(id));
  };

  return (
    <Dialog
      showClosing
      show={ show }
      onChange={ handleBackPage }
      className={ styles.dialog }
    >
      <div className={ styles.wrapper }>
        <Text type='NORMAL_16' className={ styles.error }>{ error }</Text>
        <Button type='secondary' onClick={ handleBackPage }>{ LABELS.CLOSE }</Button>
      </div>
    </Dialog>
  );
};

export { ErrorDialog };
