import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Paginate, Table, PageLoader, Text } from 'new-ui';

import { getText } from '../../../../../../i18n';

import ROUTES from '../../../../../bi/constants/routes';

import { ChartAnalyticsNoResult } from '../../noResult';

import { INestedTable } from '../type';
import { SubTableStringsType } from '../../../../../bi/services/chartsAnalytics/types';

import styles from './styles.module.css';

const CANCELED = getText('chartsAnalytics:canceled');

const HotelNestedTable = observer(({ store, onPageChange }: INestedTable) => {
  const {
    subTableInfo: {
      strings,
      columnsName,
    },
    paginate: {
      currentPage,
      itemsPerPage,
      total,
    },
    loading,
  } = store;

  if (loading) return <PageLoader />;

  if (!strings?.length) return <ChartAnalyticsNoResult />;

  const renderCanceled = (isCanceled: boolean, data: string[], indexData: number) => {
    if (isCanceled && indexData === data.length - 1) {
      return <div className={ styles.canceled }>{ CANCELED }</div>;
    }

    return null;
  };

  const valuesHtml = (value: string, tripId: number, indexData: number) => (tripId && indexData === 0 ? (
    <Link
      to={ `${ROUTES.TRIP.MAIN}/${tripId}` }
      target='_blank'
    >
      <Button
        className={ styles.link }
        type='textual'
      >
        { value }
      </Button>
    </Link>
  ) : value);

  const hotelName = (<Text className={ styles.row } type='SEMIBOLD_14'>{ columnsName[1] }</Text>);
  const header = columnsName.map((name, ind) => {
    if (ind === 1) {
      return hotelName;
    }

    return name;
  });

  const rowHtml = (value: string, subPageInfo: { tripId: number, isCanceled: boolean }, indexData: number, data: string[]) => {
    const wrappedStyles = [styles.value_wrap];

    if (indexData === 1) {
      wrappedStyles.push(styles.row);
    }

    return (
      <div className={ wrappedStyles.join(' ') }>
        { valuesHtml(value, subPageInfo?.tripId, indexData) }
        { renderCanceled(subPageInfo?.isCanceled, data, indexData) }
      </div>
    );
  };

  const renderTable = (items: SubTableStringsType[]) =>
    items.map(({ data, subPageInfo }, indexRow) => (
      <tr key={ indexRow }>
        { data.map((value, indexData) =>
          <td key={ indexData }>
            { rowHtml(value, subPageInfo, indexData, data) }
          </td>)
      }
      </tr>
    ));

  return (
    <>
      <div className={ styles.table }>
        <Table
          labelsHeader={ header as string[] }
        >
          { renderTable(strings) }
        </Table>
      </div>
      <div className={ styles.footer }>
        <div className={ styles.paginate }>
          <Paginate
            page={ currentPage }
            itemsPerPage={ itemsPerPage }
            total={ total }
            onChange={ onPageChange }
          />
        </div>
      </div>
    </>
  );
});

export { HotelNestedTable };
