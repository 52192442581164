import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Text, StyledWrapper, Button, Icon } from 'new-ui';

import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';

import MainAnalytic from '../../bi/utils/analytics/main';
import { MOBX_STORES } from '../../bi/context/stores';

import { ADDITIONAL_TYPES, PATH } from '../../bi/constants/airlineAdditionalServices';
import ROUTES from '../../bi/constants/routes';

import { WarningBlock } from '../../components/WarningBlock/index';
import { RouteSegments } from './components/RouteSegments';

import { VipHallOrderProps } from '../../bi/types/vipHall';
import { IDetails } from '../../bi/types/airlineAdditionalServices';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('components:additionalServices.sectionRoute'),
  SELECT_AIRPORT: getText('components:additionalServices.selectAirport'),
  NO_AIRPORTS: getText('components:additionalServices.noAirports'),
};

const VipHallSelectRoute: FC<VipHallOrderProps> = observer(({
  orderService,
  match: { params: { tripId, ticketId }, path },
  breadCrumbsService,
  vipHallService,
}) => {
  const [detailsData, setDetailsData] = useState<IDetails | null>(null);
  const [selectedRoute, setSelectedRoute] = useState<number | null>(null);

  const { vipHallSearchStore: { textTemplateWarning } } = useStores([MOBX_STORES.VIP_SEARCH_STORE]);

  const history = useHistory();
  const isVipHallPage = path === PATH.ROUTE_VIP;

  useEffect(() => {
    const { details } = orderService.get();
    setDetailsData(details);

    vipHallService.setNewSearch();
    vipHallService.getTextWarning();

    breadCrumbsService.setCurrentPath(path);

    return () => {
      breadCrumbsService.setCurrentPath(PATH.MAIN);
    };
  }, []);

  const tripItem = detailsData && detailsData.OrderItems.find(ticket => ticket.Id === +ticketId);

  const ticketData = tripItem && JSON.parse(tripItem.ActualVersion.JsonData);

  const handleSelect = () => {
    const typeService = isVipHallPage
      ? ADDITIONAL_TYPES.VIP_HALL
      : ADDITIONAL_TYPES.ESCORT;

    breadCrumbsService.setCurrentPath(ROUTES.ADDITIONAL_SERVICES.DIRECTION(tripId, ticketId, typeService, selectedRoute));

    return history.push(ROUTES.ADDITIONAL_SERVICES.DIRECTION(tripId, ticketId, typeService, selectedRoute));
  };

  const handleChangeSelectedRoute = (index: number | null, value: any) => {
    setSelectedRoute(index);
    vipHallService.setSelectedRoute(value);

    const analyticType = isVipHallPage
      ? MainAnalytic.ACTIONS.VIP_HALL.VIP_ROUTE_ROUTE_CHOSEN
      : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_ROUTE_CHOSEN;

    MainAnalytic.sendFirebase(analyticType, {
      departure: `${value[0].DepartureCity} (${value[0].DepartureAirport.Code})`,
      arrival: `${value[0].ArrivalCity} (${value[0].ArrivalAirport.Code})`,
    });
  };

  const handleGoToChat = () => {
    const additionalType = location.href.includes('vipHall') ? `${ADDITIONAL_TYPES.VIP_HALL}Route` : `${ADDITIONAL_TYPES.ESCORT}Route`;
    breadCrumbsService.setCurrentPath(ROUTES.ADDITIONAL_SERVICES.OTHER_CHANGES(tripId, ticketId, additionalType));

    const analyticType = isVipHallPage
      ? MainAnalytic.ACTIONS.VIP_HALL.VIP_ROUTE_BUTTON_PRESSED
      : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_ROUTE_BUTTON_PRESSED;

    MainAnalytic.sendFirebase(analyticType);

    return history.push(ROUTES.ADDITIONAL_SERVICES.OTHER_CHANGES(tripId, ticketId, additionalType));
  };

  const renderSearchRoutes = () =>
    ticketData && ticketData.Routes.map((_: any, index: number) => (
      <RouteSegments
        key={ `search_route_date_${index}` }
        index={ index }
        defaultRoute={ ticketData.Routes[index] }
        onChangeSelectedRoute={ handleChangeSelectedRoute }
        selectedRoute={ selectedRoute }
      />
    ));

  const renderWarningText = () => {
    if (!textTemplateWarning) return null;

    return <WarningBlock text={ textTemplateWarning } type='SEMIBOLD_16' color='red' />;
  };

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.title }>
        <Text type='bold_20'>
          { LABELS.TITLE }
        </Text>
        <Button type='textual' onClick={ handleGoToChat }>{ LABELS.NO_AIRPORTS }</Button>
      </div>
      <StyledWrapper className={ styles.routes }>
        { renderSearchRoutes() }
        <div className={ styles.search }>
          <Button
            type='secondary'
            className={ styles.search_button }
            onClick={ handleSelect }
            disabled={ typeof selectedRoute !== 'number' }
          >
            <Text type='NORMAL_18' color='white'>
              { LABELS.SELECT_AIRPORT }
            </Text>
            <Icon type='arrowRightNext' color='white' className={ styles.arrow } />
          </Button>
        </div>
      </StyledWrapper>
      { renderWarningText() }
    </div>
  );
});

export { VipHallSelectRoute };
