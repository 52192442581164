import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Icon } from 'new-ui';
import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { DialogComments } from './components/dialog';

import { isSmartAgent } from '../../../../bi/utils/env';

import { TripCommentsProps } from '../../../../bi/services/tripComments/types';

import styles from './styles/index.module.css';

const LABELS = {
  ADD_COMMENT: getText('trip:comments.titles.addComment'),
  COMMENTS: getText('trip:comments.titles.comments'),
};

const TripComments = observer(({
  onAddComment,
}: TripCommentsProps) => {
  const {
    tripCommentsStore: { isHistory, disabled },
  } = useStores([MOBX_STORES.TRIP_COMMENTS]);

  const [dialog, setDialog] = useState(false);

  const handleToggleDialog = (value: boolean) => {
    if (disabled) return () => {};

    return setDialog(value);
  };

  const renderDialog = () => dialog && (
    <DialogComments
      show={ dialog }
      onAdd={ onAddComment }
      onClose={ () => handleToggleDialog(false) }
    />
  );

  const title = isHistory ? LABELS.COMMENTS : LABELS.ADD_COMMENT;

  return (
    <div className={ styles.wrapper }>
      <Icon
        alternativeDesign={ isSmartAgent }
        type='comment'
      />
      <Button
        className={ styles.button_comment }
        disabled={ disabled }
        type='textual'
        onClick={ () => handleToggleDialog(true) }
      >
        { title }
      </Button>
      { renderDialog() }
    </div>
  );
});

export { TripComments };
