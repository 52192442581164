import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Button } from 'new-ui';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import { getText } from '../../../../../../../i18n';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import scrollToTop from '../../../../../utils/scrollToTop';

import { TrainNestedTable } from '../../../common/trainNestedTable';

import styles from './styles.module.css';

const LABELS = {
  EXCEL: getText('expenseReports:report.download.excel'),
};

const SmartCommonFourthTransferPage = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => {
  const { chartsAnalyticsSmartSummaryStore } = useStores([
    MOBX_STORES.CHARTS_ANALYTICS_SMART_SUMMARY_STORE,
  ]);

  const {
    getSmartTransferPageWithTrips,
    downloadSubSmartXls,
    changePageSmartTransferPage,
  } = chartsAnalyticsService;

  useEffect(() => {
    getSmartTransferPageWithTrips();
  }, [getSmartTransferPageWithTrips]);

  const handlePageChange = (page: number) => changePageSmartTransferPage(page).then(() => scrollToTop());

  const renderDownload = () => (
    <div className={ styles.header }>
      <div className={ styles.row }>
        <Button type='textual' onClick={ () => downloadSubSmartXls() }>
          { LABELS.EXCEL }
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div className={ styles.wrapper }>
        { renderDownload() }
      </div>
      <TrainNestedTable
        store={ chartsAnalyticsSmartSummaryStore }
        onPageChange={ handlePageChange }
      />
    </>
  );
});

export { SmartCommonFourthTransferPage };
