import type { DataType } from './types';

class ProductAnalytics {
  api: any;

  constructor(api: any) {
    this.api = api.productAnalytics;
  }

  sendData = (data: DataType) => this.api.sendAnalytics([data]);
}

export { ProductAnalytics };
