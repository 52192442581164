import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Text, Button } from 'new-ui';

import { getText } from '../../../i18n/index';

import { AirlineAdditionalFightCertificateProps } from '../../bi/types/airlineAdditionalServices';

import ROUTES from '../../bi/constants/routes';
import { PATH } from '../../bi/constants/airlineAdditionalServices';
import { FLIGHT_CERTIFICATE_TYPES } from '../../bi/constants/serviceType';

import { ErrorFlightCertificate } from './components/ErrorFlightCertificate';
import { ConfirmFlightCertificate } from './components/ConfirmFlightCertificate';

import styles from './styles/index.module.css';

const LABELS = {
  UNAVAILABLE: getText('components:tripItem.orderHeader.tooltip.uncompleted'),
  BACK: getText('components:changeAirTrip.back'),
};

const AirlineAdditionalFightCertificate: FC<AirlineAdditionalFightCertificateProps> = observer(({
  breadCrumbsService,
  match: { path, params: { tripId, ticketId } },
  history,
  chatService,
  userSessionService,
  workspaceService,
  orderService,
  settingsStore: { companiesWithCostCenter },
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { details, allFlightCertificateStatus, bookflightCertificateInfo } = orderService.get();

  const statusFlightCertificate = allFlightCertificateStatus[ticketId];
  const isErrorStatusFlightCertificate = statusFlightCertificate === FLIGHT_CERTIFICATE_TYPES.NOT_CONFIRMED || statusFlightCertificate === FLIGHT_CERTIFICATE_TYPES.FAILED;
  const isConfirmStatusFlightCertificate = statusFlightCertificate === FLIGHT_CERTIFICATE_TYPES.NEED_PURCHASE;

  const ticket = details?.OrderItems.find((item: any) => item.Id === +ticketId);

  useEffect(() => {
    breadCrumbsService.setCurrentPath(path);

    return () => {
      breadCrumbsService.setCurrentPath(PATH.MAIN);
    };
  }, []);

  const isUserDisabled = () => {
    const { Email } = workspaceService.get();

    return chatService.hasDisabledUser(Email) || !workspaceService.canUseIntercom;
  };

  const handelBack = () => history.push(ROUTES.ADDITIONAL_SERVICES.MAIN(tripId, ticketId));

  const handelBackToTrip = () => history.push(ROUTES.ADDITIONAL_SERVICES.TRIP(tripId));

  const handleSendMessage = async () => {
    setIsLoading(true);

    try {
      await chatService.requestFlightCertificate(ticketId);

      handelBackToTrip();
    } finally {
      setIsLoading(false);
    }
  };

  const handleBookFlightCertificate = async (curProjectId: number) => {
    setIsLoading(true);

    try {
      await orderService.bookFlightCertificate(ticketId, curProjectId);

      handelBackToTrip();
    } finally {
      setIsLoading(false);
    }
  };

  const mandatoryProject = companiesWithCostCenter.slice().some((companyId: number) => companyId === ticket.ActualVersion.CompanyId);

  if (!isErrorStatusFlightCertificate && !isConfirmStatusFlightCertificate) {
    return (
      <div className={ styles.wrapper }>
        <Text className={ styles.title_unavailable } type='bold_20'>
          { LABELS.UNAVAILABLE }
        </Text>
        <Button
          className={ styles.button_back }
          type='textual-medium'
          onClick={ handelBack }
        >
          <Text color='accent'>{ LABELS.BACK }</Text>
        </Button>
      </div>
    );
  }

  const content = isErrorStatusFlightCertificate ? (
    <ErrorFlightCertificate
      disabled={ isUserDisabled() }
      loading={ isLoading }
      onSubmit={ handleSendMessage }
      onBack={ handelBack }
    />
  ) : (
    <ConfirmFlightCertificate
      isLoading={ isLoading }
      projects={ userSessionService.get().projects }
      mandatoryProject={ mandatoryProject }
      bookflightCertificateInfo={ bookflightCertificateInfo }
      tripProjectId={ ticket.ActualVersion.ProjectId }
      onSubmit={ handleBookFlightCertificate }
      onBack={ handelBack }
    />
  );

  return (
    <div className={ styles.wrapper }>
      { content }
    </div>
  );
});

export { AirlineAdditionalFightCertificate };
