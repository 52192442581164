import { TripCommentsStore } from './store';
import type { ITripCommentsStore } from './store';

import type { CommentType } from './types';

class TripComments {
  api: any;
  store: ITripCommentsStore;

  constructor(api: any) {
    this.api = api.trip;
    this.store = TripCommentsStore;
  }

  load = async (tripId: number): Promise<void> => {
    try {
      const res: CommentType[] = await this.api.getTripComments(tripId);

      this.setComments(res);
    } catch {
      this.setComments([]);
    }
  };

  setComments = (list: CommentType[]): void => this.store.setComments(list);

  addComment = async (tripId: number | null, email: string, comment: string): Promise<void> => {
    if (!tripId) {
      return;
    }

    this.updateLoading(true);

    const data = {
      Text: comment,
      TripId: tripId,
      Email: email,
    };

    try {
      const res: CommentType = await this.api.addTripComment(data);

      this.store.setComment(res);
      this.updateLoading(false);
    } catch {
      this.updateLoading(false);
    }
  };

  updateLoading = (value: boolean): void => this.store.setLoading(value);
}

export { TripComments };
