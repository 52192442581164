import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { PageLoader } from 'new-ui';
import { RouteComponentProps } from 'react-router-dom';

import { api } from '../../bi/apiV2';

import { getText } from '../../../i18n';

import { TravelApprovals } from '../../components/TravelApprovals';
import InputModal from '../../components/InputModal';
import { ApprovalStatusCardList } from './ApprovalStatusCardList';
import { DeleteDialog } from './DeleteDialog';

import scrollToTop from '../utils/scrollToTop';
import { isEmail } from '../../bi/utils/validation';
import { MainAnalytic } from '../../bi/utils/analytics';

import { KEYS } from '../../bi/services/applicationScheme/constants';
import TravelApproval from '../../bi/services/travelApproval';

import { WorkspaceService } from '../../bi/types/workspace';
import { ApprovalRequest } from '../../bi/protocols/approvalRequest';
import { UiSettingsProtocol } from '../../bi/protocols/uiSettings';

import { APPROVAL_STATUS_PAGE } from '../../bi/constants/travelApproval';
import ROUTES from '../../bi/constants/routes';
import { AGGREGATORS_ACCOUNT } from '../../bi/constants/accounts';

import { useGetParams } from '../../bi/hooks/useGetParams';

import styles from './index.module.css';

const LABELS = {
  SEND_TO_EMAIL_TITLE: getText('approvalScheme:sendToEmailDialog.title'),
  PAGE_LOADER: getText('approvalScheme:loader'),
};

interface IApplicationSchemeRequests {
  history: RouteComponentProps['history'],
  travelApprovalService: TravelApproval,
  workspaceService: WorkspaceService,
  approvalRequestProtocol: ApprovalRequest,
  uiSettingsProtocol: UiSettingsProtocol,
  aggregationId: number | null,
  customAnalyticsService: { [key: string]: any },
}

const ApplicationSchemeRequests = ({
  history,
  travelApprovalService,
  workspaceService,
  approvalRequestProtocol,
  uiSettingsProtocol,
  aggregationId,
  customAnalyticsService,
}: IApplicationSchemeRequests) => {
  const [page, setPage] = useState<number>(1);
  const [approvalRequestStatus, setApprovalRequestStatus] = useState<string[]>([]);
  const [approvalRequestEmployees, setApprovalRequestEmployees] = useState<number[]>([]);
  const [employeeListForFilter, setEmployeeListForFilter] = useState([]);
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const [removeDialogData, setRemoveDialogData] = useState({});
  const [sendMailDialog, setSendMailDialog] = useState< { show: boolean, requestId: number | null }>({ show: false, requestId: null });
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [userHasSchemes, setUserHasSchemes] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const statusParams = useGetParams('statuses');
  const employeesParams = useGetParams('employees');
  const pageParams = useGetParams('page');
  const schemeExistParams = useGetParams('schemes');

  const cKRRestrictions = aggregationId === AGGREGATORS_ACCOUNT.CKR;

  useLayoutEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_REQUEST_SCREEN_OPENED);
    customAnalyticsService.getAnalytics();
  }, []);

  useEffect(() => {
    let employeeList = [];
    let statusArray = [];

    if (employeesParams) {
      employeeList = employeesParams.split(',').map((employee) => Number(employee));
      setApprovalRequestEmployees(employeeList);
    }

    if (statusParams) {
      statusArray = statusParams.split(',');
      setApprovalRequestStatus(statusArray);
    }

    if (pageParams) {
      setPage(Number(pageParams));
    }

    if (schemeExistParams) {
      const schemeAlreadyExist = Number(schemeExistParams) === 1;
      setUserHasSchemes(schemeAlreadyExist);
    }
  }, [schemeExistParams]);

  const updateDataAfterAction = async () => {
    await queryClient.cancelQueries({ queryKey: [KEYS.approvalRequests] });

    return queryClient.invalidateQueries([KEYS.approvalRequests]);
  };

  const setParams = (key: string, value: string) => {
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set(key, value);
    history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
  };

  const useApprovalRequests = () => useQuery({
    queryKey: [KEYS.approvalRequests, approvalRequestStatus, approvalRequestEmployees, page, schemeExistParams],
    queryFn: () => api.approvalScheme.getTravelApprovalRequests(approvalRequestEmployees, approvalRequestStatus, page),
    keepPreviousData: true,
    // eslint-disable-next-line @tanstack/query/no-deprecated-options
    onSuccess: ({ Data }) => {
      if (!schemeExistParams?.length) setParams('schemes', Data?.length ? '1' : '0');
    },
    staleTime: 60000,
  });

  const { data = [], isLoading } = useApprovalRequests();

  const useEmployees = () => useQuery({
    queryKey: ['employeesFilter'],
    queryFn: () => api.approvalScheme.getTravelApprovalEmployees(),
    initialData: [],
    staleTime: 60000,
  });

  const { data: employees } = useEmployees();

  useEffect(() => {
    const list = employees.map(({ FullName, Id }: { FullName: string, Id: number }) => ({ label: FullName, value: Id }));

    setEmployeeListForFilter(list);
  }, [employees]);

  const useDeleteApprovalRequest = useMutation({
    mutationFn: (requestId: number) => api.approvalScheme.deleteTravelApprovalRequest(requestId),
    onSuccess: updateDataAfterAction,
    onSettled: () => setShowRemoveDialog(false),
  });

  const useAddToArchive = useMutation({
    mutationFn: (requestId: number) => api.approvalScheme.addToArchive(requestId),
    onSuccess: updateDataAfterAction,
  });

  const useSendToMail = useMutation({
    // @ts-ignore
    mutationFn: async ({ requestId, email }) => api.approvalScheme.sendApprovalRequestToEmail(requestId, email),
    onSuccess: updateDataAfterAction,
    onSettled: () => setSendMailDialog({ show: false, requestId: null }),
  });

  const handleCreateTravelApproval = () => {
    travelApprovalService.setTravelApproval();
    travelApprovalService.setTravelApprovalStatusPage(APPROVAL_STATUS_PAGE.CREATE);
  };

  const handleChangeStatusFilter = (values: any) => {
    setParams('statuses', values);
    setParams('page', '1');
    setPage(1);
    setApprovalRequestStatus(values);
  };

  const handleChangeEmployeeFilter = (values: any) => {
    setParams('employees', values);
    setParams('page', '1');
    setPage(1);
    setApprovalRequestEmployees(values);
  };

  const handleResetAllFilters = () => {
    setApprovalRequestStatus([]);
    setApprovalRequestEmployees([]);
    setPage(1);

    history.push(ROUTES.APPLICATION_SCHEME_REQUEST);
  };

  const handleDeleteApprovalRequest = (requestId: number): void => {
    useDeleteApprovalRequest.mutate(requestId);

    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_REQUEST_DELETE_TRAVEL_APPROVAL);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_REQUEST_DELETE_TRAVEL_APPROVAL);
  };

  const handleSendToMail = (email: string) => {
    const { requestId } = sendMailDialog;

    // @ts-ignore
    useSendToMail.mutate({ requestId, email });

    MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_REQUEST_SEND_TO_EMAIL);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.APPLICATION_SCHEME.APPLICATION_SCHEME_REQUEST_SEND_TO_EMAIL);
  };

  const handleSendToMailDialog = (requestId: number, e: any) => {
    e.stopPropagation();

    setSendMailDialog({
      show: true,
      requestId,
    });
  };

  const emailValidate = (email: string): void => setIsValidEmail(isEmail(email));

  const handleCloseDialog = () => setShowRemoveDialog(false);

  const handleGoTravelApproval = (id: number) => history.push(`${ROUTES.TRAVEL_APPROVAL}/${id}`);

  const handlePageChange = (value: number) => {
    if (page !== value) {
      scrollToTop();
      setParams('page', value.toString());
      setPage(value);
    }
  };

  if (isLoading) return <PageLoader text={ LABELS.PAGE_LOADER } />;

  const isTravelApproval = approvalRequestProtocol.approveRequestExists;
  const schemesAlreadyExist = userHasSchemes || data?.Data?.length;

  return (
    <div className={ styles.wrapper }>
      <TravelApprovals
        history={ history }
        onCreate={ handleCreateTravelApproval }
        schemesAlreadyExist={ schemesAlreadyExist }
        onChangeStatusFilter={ handleChangeStatusFilter }
        onChangeEmployeeFilter={ handleChangeEmployeeFilter }
        checkedStatusFilter={ approvalRequestStatus }
        checkedEmployeeFilter={ approvalRequestEmployees }
        onResetAllFilters={ handleResetAllFilters }
        employeeListForFilter={ employeeListForFilter }
        isAdmin={ workspaceService.isAdmin }
        isTravelApproval={ isTravelApproval }
        isAggregator={ uiSettingsProtocol.isAggregator }
        cKRRestrictions={ cKRRestrictions }
      />
      <ApprovalStatusCardList
        approvalRequests={ data?.Data }
        useAddToArchive={ useAddToArchive }
        onDeleteApprovalRequest={ () => setShowRemoveDialog(true) }
        handleSendToMailDialog={ handleSendToMailDialog }
        setRemoveDialogData={ setRemoveDialogData }
        handleGoTravelApproval={ handleGoTravelApproval }
        pagination={ data }
        onPageChange={ handlePageChange }
      />
      <DeleteDialog
        show={ showRemoveDialog }
        onCancel={ handleCloseDialog }
        onSubmit={ handleDeleteApprovalRequest }
        data={ removeDialogData }
        isLoading={ useDeleteApprovalRequest.isLoading }
      />
      <InputModal
        show={ sendMailDialog.show }
        onCancel={ () => setSendMailDialog({ show: false, requestId: null }) }
        title={ LABELS.SEND_TO_EMAIL_TITLE }
        onSend={ handleSendToMail }
        onChange={ emailValidate }
        isValid={ isValidEmail }
        loading={ useSendToMail.isLoading }
      />
    </div>
  );
};

export { ApplicationSchemeRequests };
