import React from 'react';
import { observer } from 'mobx-react';
import { Text } from 'new-ui';

import { useServices } from '../../../../bi/context/services';

import { BlockTooltip } from '../BlockTooltip';
import { AddButton } from '../../../../components/AddButton';
import { RemovableListItem } from '../../../../components/RemovableListItem';

import { getEmployeeFullName } from '../../../../bi/utils/employees';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { LABELS } from '../../consts';

import { LoadingStatus } from '../../../../bi/services/utils/network/types';
import { DialogTypes, LoadingFields } from '../../../../bi/services/departments/types';

import styles from './styles.module.css';

export const ListEmployees = observer(() => {
  const {
    departmentsService: {
      networkStore,
      removeEmployee,
      store: { chosenEmployees, openDialog, isNewDepartment, companyNotSelected },
    },
  } = useServices(['Departments']);

  const handleRemoveAction = (employee: any) => (
    isNewDepartment
      ? removeEmployee(employee.Id)
      : openDialog(DialogTypes.deleteEmployee, employee)()
  );

  const RenderListContent = () => Object.keys(chosenEmployees).map(e =>
    <RemovableListItem
      employeeId={ chosenEmployees[e].id }
      key={ e }
      value={ chosenEmployees[e] }
      renderLabel={ getEmployeeFullName }
      onChangeStatus={ handleRemoveAction }
    />,
  );

  const disabled = companyNotSelected
    || networkStore.getIsStatus(LoadingFields.loadDepartment, LoadingStatus.LOADING)
    || networkStore.getIsStatus(LoadingFields.loadUserList, LoadingStatus.LOADING);

  return (
    <div className={ styles.content }>
      <Text type='bold_24'>
        { LABELS.EMPLOYEES }
      </Text>
      <RenderListContent />
      <BlockTooltip>
        <AddButton
          className={ styles.add }
          text={ LABELS.ADD_EMPLOYEES }
          onClick={ openDialog(DialogTypes.editEmployees) }
          qaAttr={ QA_ATTRIBUTES.settings.departments.create.employeeChoose }
          disabled={ disabled }
        />
      </BlockTooltip>
    </div>
  );
});
