import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { NoResults } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { TransferFilters } from '../TransferFilter';
import { EmptyFilter } from './components/EmptyFilter';
import { ResultList } from './components/ResultList';

import { TransferResultProps } from '../../../../bi/types/transfer';

import styles from './index.module.css';

const LABELS = {
  NOT_FOUND: getText('transfer:transferResult.notFound'),
  NOT_FOUND_FAVORITED: getText('transfer:transferResult.notFoundFavorited'),
  RESET_FILTERS: getText('transfer:transferResult.resetFilters'),
};

const TransferResult: FC<TransferResultProps> = observer(({
  userSessionService,
  sortSearchResult,
  onCloseTag,
  onAddToNotepad,
  onAddToShoppingCart,
  onResetFilters,
  onAddToFavorite,
  updateFilter,
  onAlreadyInCart,
}) => {
  const { transferStore: {
    sources, items, tags, sortBy, isEmpty,
    filter: {
      selectedTravelPolicy,
    },
  } } = useStores([MOBX_STORES.TRANSFER_STORE]);

  const travelPolicyList = userSessionService.get().travelPolicies;

  const handleResetFilters = () => onResetFilters();

  const renderNoResults = () => <NoResults label={ LABELS.NOT_FOUND } styles={ { height: 'inherit' } }/>;

  if (isEmpty() && !sources.length) {
    return renderNoResults();
  }

  const html = isEmpty() && sources.length ? (
    <EmptyFilter onResetFilters={ handleResetFilters } />
  ) : (
    <ResultList
      items={ items }
      tags={ tags }
      travelPolicyList={ travelPolicyList }
      selectedTravelPolicy={ selectedTravelPolicy }
      sortBy={ sortBy }
      sortSearchResult={ sortSearchResult }
      onCloseTag={ onCloseTag }
      onAddToNotepad={ onAddToNotepad }
      onAddToShoppingCart={ onAddToShoppingCart }
      onAddToFavorite={ onAddToFavorite }
      onAlreadyInCart={ onAlreadyInCart }
    />
  );

  return (
    <div className={ styles.wrapper }>
      <div>
        <TransferFilters
          updateFilter={ updateFilter }
          travelPolicyList={ travelPolicyList }
        />
      </div>
      { html }
    </div>
  );
});

export { TransferResult };
