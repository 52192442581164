import React from 'react';

import { Tooltip, IconButton, Text } from 'new-ui';
import { getText } from '../../../../../../../i18n';

import styles from './index.module.css';

const LABELS = {
  ADD_IDENTIFIER: getText('settings:integration.identifierButton.add'),
  TIP: getText('settings:integration.identifierButton.tip'),
};

interface IAddIdentifierButton {
  onClick(): void
}

const AddIdentifierButton = ({ onClick }: IAddIdentifierButton) => (
  <Tooltip
    className={ styles.wrap }
    renderContent={ () => (
      <Text
        className={ styles.tooltip }
        type='NORMAL_14_130'
        color='white'
      >
        { LABELS.TIP }
      </Text>
    ) }
  >
    <IconButton
      iconType='plusRound'
      onClick={ onClick }
    >
      { LABELS.ADD_IDENTIFIER }
    </IconButton>
  </Tooltip>
);

export { AddIdentifierButton };
