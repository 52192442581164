import ACTION from './action';
import Store from '../../store';

import { IAuthStore } from '../../types/auth';

const createNewState = (): IAuthStore => ({
  username: '',
  password: '',
  error: null,
  state: null,
  isValid: false,
  loading: false,
  buttonDisabled: false,
});

const reducer = (action: { [key: string]: any, type: string }, state: IAuthStore) => {
  switch (action.type) {
    case ACTION.UPDATEUSERNAME: {
      const newState = {
        ...state,
        username: action.payload,
      };

      newState.isValid = newState.username && newState.password;

      return newState;
    }
    case ACTION.UPDATEPASSWORD: {
      const newState = {
        ...state,
        password: action.payload,
      };

      newState.isValid = newState.username && newState.password;

      return newState;
    }
    case ACTION.LOGINRESULT: {
      let newState = null;

      if (action.payload.error) {
        newState = {
          ...state,
          error: action.payload.error,
          state: action.payload.state,
          loading: false,
        };
      } else {
        newState = createNewState();
      }

      return newState;
    }
    case ACTION.LOGINSTART: {
      return {
        ...state,
        loading: true,
      };
    }
    case ACTION.UPDATEBUTTONDISABLED: {
      return {
        ...state,
        buttonDisabled: action.payload,
      };
    }
    case ACTION.UPDATEERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
  }

  return state;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
