import React, { PropsWithChildren } from 'react';
import { Text, PROPS, Icon } from 'new-ui';
import { observer } from 'mobx-react';

import { getText } from '../../../../i18n';

import { useStores } from '../../../bi/context';
import { MOBX_STORES } from '../../../bi/context/stores';

import { InformationIcon } from '../../../components/InformationIcon';

import { formatDate, momentObject } from '../../../bi/utils/formatDate';
import textTransformation from '../../../bi/utils/textTransformation';
import getTravelTime from '../../../bi/utils/timeLabel';
import { secondsToLabel } from '../../../bi/utils/time';
import toDecline from '../../../bi/utils/toDecline';
import { isSmartAgent } from '../../../bi/utils/env';
import { TrainItemDetailsTime } from './time';

import { PATTERN } from '../../../bi/constants/dateFormats';
import TIMETYPELIST from '../../../bi/constants/timeTypeList';
import { TRANSFER } from '../../../bi/constants/train';

import { TrainSearchItemProps } from './types';

import styles from '../styles/searchItem.module.css';

const LABELS = {
  TEXT_DIRECTION: {
    FROM: getText('trains:ticket.directions.from'),
    TO: getText('trains:ticket.directions.to'),
  },
  STATION_COLLAPSE: getText('trains:ticket.stationCollapse'),
  DIRECT: getText('trains:ticket.withoutTransfer'),
  ATTENTION_KALININGRAD: {
    FIRST: getText('components:trainItem.attentionKaliningrad.first'),
    SECOND: getText('components:trainItem.attentionKaliningrad.second'),
    THIRD: getText('components:trainItem.attentionKaliningrad.third'),
    FOURTH_FIRST: getText('components:trainItem.attentionKaliningrad.fourth_first'),
    FOURTH_SECOND: getText('components:trainItem.attentionKaliningrad.fourth_second'),
    FIFTH: getText('components:trainItem.attentionKaliningrad.fifth'),
    LINK: getText('components:trainItem.attentionKaliningrad.link'),
  },
  KALININGRAD: getText('components:trainItem.kaliningrad'),
};

const TrainSearchItem = observer(({
  children,
  ticket,
  isTicketWithTransfer,
  qaAttr,
}: PropsWithChildren<TrainSearchItemProps>) => {
  const { trainSavedTicketStore } = useStores([MOBX_STORES.TRAIN_SAVED_TICKET]);

  const compareStations = () => {
    const { StationFrom, StationTo } = ticket;
    const { savedTicket } = trainSavedTicketStore;

    const savedStationTo = savedTicket && savedTicket.train && savedTicket.train.StationTo;
    const savedStationFrom = savedTicket && savedTicket.train && savedTicket.train.StationFrom;
    const isNotSameStationFrom = savedStationTo && (savedStationTo !== StationFrom);
    const isNotSameStationTo = savedStationFrom && (savedStationFrom !== StationTo);

    return {
      isNotSameStationFrom,
      isNotSameStationTo,
    };
  };

  const renderTooltip = (isTo: boolean) => {
    const savedTicket = trainSavedTicketStore.savedTicket;

    if (!savedTicket) {
      return null;
    }

    const train = savedTicket.train;

    if (!train) {
      return null;
    }

    const { StationDepart, StationArrive, StationTo, StationFrom } = train;

    const trainStations = `${textTransformation(StationDepart)} – ${textTransformation(StationArrive)}`;

    const textDirection = isTo ? LABELS.TEXT_DIRECTION.TO : LABELS.TEXT_DIRECTION.FROM;
    const currentStation = isTo ? textTransformation(StationFrom) : textTransformation(StationTo);

    const tooltipText = `${LABELS.STATION_COLLAPSE} ${trainStations} ${textDirection} ${currentStation}`;

    return <InformationIcon text={ tooltipText } className={ styles.tooltip }/>;
  };

  const renderTooltipStation = (station: string, city: string, tooltipText: string | any) => {
    const includeStation = station.toLowerCase().includes(city.toLowerCase());

    return includeStation && (
      <InformationIcon
        text={ tooltipText }
        className={ styles.tooltip }
        iconType={ PROPS.ICON.TYPES.WARNING }
        tooltipPosition={ PROPS.TOOLTIP.POSITION.BOTTOM }
        classNameTooltipText={ styles.text }
      />
    );
  };

  const {
    DepartureDate,
    DepartureDateLocal,
    ArrivalDate,
    ArrivalDateLocal,
    StationFrom,
    StationTo,
    TravelTime,
    TransfersTime,
    CountTransfers,
  } = ticket;

  const departureDate = momentObject(DepartureDate);
  const departureDateLocal = momentObject(DepartureDateLocal);
  const arrivalDate = momentObject(ArrivalDate);
  const arrivalDateLocal = momentObject(ArrivalDateLocal);
  const travelHtml = isTicketWithTransfer ? secondsToLabel(TravelTime, true) : getTravelTime(TravelTime);

  const transferTime = TransfersTime ? (
    <Text
      type='NORMAL_14'
      color='gray'
    >
      ({ secondsToLabel(TransfersTime) })
    </Text>
  ) : null;

  const transferCountAndTime = isTicketWithTransfer ? (
    <>
      <Text
        type='NORMAL_14'
        color='gray'
        qaAttr={ qaAttr?.firstElTransfer || '' }
      >
        { CountTransfers } { toDecline(CountTransfers, TRANSFER) }
      </Text>
      { transferTime }
    </>
  ) : (
    <Text
      type='NORMAL_14'
      color='gray'
      qaAttr={ qaAttr?.firstElTransfer || '' }
    >
      { LABELS.DIRECT }
    </Text>
  );

  const linkKaliningradAttention = (
    <a
      href={ LABELS.ATTENTION_KALININGRAD.LINK }
      target='_blank'
      rel='noreferrer'
    >
      { LABELS.ATTENTION_KALININGRAD.LINK }
    </a>
  );

  const kaliningradTooltipText = (
    <div>
      <Text color='white' type='NORMAL_14'>
        { LABELS.ATTENTION_KALININGRAD.FIRST }
      </Text>
      <Text color='white' type='NORMAL_14'>
        { LABELS.ATTENTION_KALININGRAD.SECOND }
      </Text>
      <Text color='white' type='NORMAL_14'>
        { LABELS.ATTENTION_KALININGRAD.THIRD }
      </Text>
      <Text color='white' type='NORMAL_14'>
        { LABELS.ATTENTION_KALININGRAD.FOURTH_FIRST }
        { linkKaliningradAttention }
        { LABELS.ATTENTION_KALININGRAD.FOURTH_SECOND }
      </Text>
      <Text color='white' type='NORMAL_14'>
        { LABELS.ATTENTION_KALININGRAD.FIFTH }
      </Text>
    </div>
  );

  const { isNotSameStationFrom, isNotSameStationTo } = compareStations();
  const tooltipFromHtml = isNotSameStationFrom && renderTooltip(false);
  const tooltipToHtml = isNotSameStationTo && renderTooltip(true);

  const tooltipKaliningradFrom = () => renderTooltipStation(StationFrom, LABELS.KALININGRAD, kaliningradTooltipText);
  const tooltipKaliningradTo = () => renderTooltipStation(StationTo, LABELS.KALININGRAD, kaliningradTooltipText);

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.direction }>
        <TrainItemDetailsTime
          date={ departureDate }
          dateLocal={ departureDateLocal }
          timeType={ TIMETYPELIST.DEPARTURE }
          renderTooltipStation={ tooltipKaliningradFrom }
          qaAttr={ qaAttr?.firstElTimeFrom || '' }
        />
        <div className={ styles.station }>
          <Text
            className={ styles.text }
            qaAttr={ qaAttr?.firstElStationFrom || '' }
          >
            { StationFrom.toLocaleLowerCase() }
          </Text>
          { tooltipFromHtml }
        </div>
        <Text
          type='NORMAL_14'
          color='gray'
          qaAttr={ qaAttr?.firstElDateFrom || '' }
        >
          { formatDate(departureDateLocal, PATTERN.DATEWITHWEEKDAY) }
        </Text>
      </div>
      <div className={ styles.duration }>
        <Icon
          className={ styles.icon }
          type='searchTrainColor'
          alternativeDesign={ isSmartAgent }
        />
        <Text
          className={ styles.time }
          qaAttr={ qaAttr?.firstElDuration || '' }
        >
          { travelHtml }
        </Text>
        {transferCountAndTime}
      </div>
      <div className={ `${styles.direction} ${styles.direction_to}` }>
        <TrainItemDetailsTime
          date={ arrivalDate }
          dateLocal={ arrivalDateLocal }
          timeType={ TIMETYPELIST.ARRIVAL }
          className={ styles.time }
          renderTooltipStation={ tooltipKaliningradTo }
          qaAttr={ qaAttr?.firstElTimeTo || '' }
        />
        <div className={ styles.station }>
          <Text
            className={ styles.text }
            qaAttr={ qaAttr?.firstElStationTo || '' }
          >
            { StationTo.toLocaleLowerCase() }
          </Text>
          {tooltipToHtml}
        </div>
        <Text
          className={ styles.date }
          type='NORMAL_14'
          color='gray'
          qaAttr={ qaAttr?.firstElDateTo || '' }
        >
          { formatDate(arrivalDateLocal, PATTERN.DATEWITHWEEKDAY) }
        </Text>
      </div>
      { children }
    </div>
  );
});

export { TrainSearchItem };
