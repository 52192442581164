import React from 'react';
import { Text, StyledWrapper } from 'new-ui';

import { getText } from '../../../../../i18n';

import { MemoizedExceptionTypes as ExceptionTypes } from '../ExceptionTypes';
import { RoutesList } from '../RoutesList';
import { GeneralRules } from '../GeneralRules';
import { PolicyRuleHeaderText } from '../PolicyRuleHeader';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { TRAVEL_POLICY_FIELDS_DICTIONARIES } from '../../../../bi/services/travelPolicy/consts';

import { ServiceTypeSmall } from '../../../../bi/types/order';
import { SuggestsRequestModel } from '../../../../bi/services/travelPolicy/types';

import styles from './index.module.css';

type ExceptionFormProps = {
  type: ServiceTypeSmall,
  value: any,
  step: number,
  showValidation?: boolean,
  showContent: boolean,
  name: string,
  isNotTheSame?: boolean,
  isUnique: boolean | undefined,
  suggestions: any,
  onChange: (payload: any) => void,
  onAutocompleteChange: (payload: SuggestsRequestModel) => void,
};

const SAPSAN_ROUTES_CODES = ['2000000', '2004000', '2060001'];

const LABELS = {
  VALIDATION_TITLE: getText('settings:travelPolicy.rules.exceptions.validation.title'),
  VALIDATION_DESCR: getText('settings:travelPolicy.rules.exceptions.validation.description'),
  AIR: getText('settings:travelPolicy.rules.exceptions.firstStepHeader.air'),
  HOTEL: getText('settings:travelPolicy.rules.exceptions.firstStepHeader.hotel'),
  TRAIN: getText('settings:travelPolicy.rules.exceptions.firstStepHeader.train'),
};

const getFirstStepHeader = (type: string) => {
  switch (type) {
    case SERVICETYPE.AIR:
      return LABELS.AIR;
    case SERVICETYPE.HOTEL:
      return LABELS.HOTEL;
    case SERVICETYPE.TRAIN:
      return LABELS.TRAIN;
    default:
      return '';
  }
};

const getSecondStepHeader = (policyName: string, exceptionTitle: string, type: ServiceTypeSmall) => (
  <PolicyRuleHeaderText apply serviceType={ type } name={ policyName } exceptionTitle={ exceptionTitle } />
);

const ExceptionForm = ({
  isUnique,
  type,
  value,
  step,
  showValidation,
  showContent,
  name,
  isNotTheSame,
  suggestions,
  onChange,
  onAutocompleteChange,
}: ExceptionFormProps) => {
  const {
    Type: exceptionType,
    Routes: routes,
    Hours,
    fullNameModel: {
      typeText,
    },
  } = value;

  const renderStepHeader = () => {
    const text = step === 0 ? getFirstStepHeader(type) : getSecondStepHeader(name, typeText, type);

    return <div className={ styles.header }>{ text }</div>;
  };

  const renderFirstStep = () => {
    const exceptionTypeOnRoutesChange = type === SERVICETYPE.HOTEL
      ? exceptionType
      : TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.ROUTES;

    const isHoursExceptionType = exceptionType === TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.HOURS;

    const showTypesValidation = showValidation && isHoursExceptionType;
    const showRoutesValidation = showValidation && !isHoursExceptionType;

    const showTypesAutoFocus = showContent && isHoursExceptionType;
    const showRoutesList = showContent && !isHoursExceptionType;

    return (
      <>
        <ExceptionTypes
          type={ type }
          value={ { Type: exceptionType, Hours } }
          onChange={ (payload: any) => onChange({ ...value, ...payload }) }
          showValidation={ showTypesValidation }
          autoFocusField={ showTypesAutoFocus }
        />
        <RoutesList
          list={ routes }
          suggestions={ suggestions }
          type={ type }
          showValidation={ showRoutesValidation }
          show={ showRoutesList }
          exceptionType={ exceptionType }
          onChange={ list => onChange({ ...value, Routes: list, Type: exceptionTypeOnRoutesChange }) }
          onAutocompleteChange={ onAutocompleteChange }
        />
      </>
    );
  };

  const renderSecondStep = () => {
    const validationContent = !isNotTheSame && showValidation && (
      <StyledWrapper className={ styles.error }>
        <Text>{ LABELS.VALIDATION_TITLE }</Text>
        &nbsp;
        <Text type='bold_16'>{ LABELS.VALIDATION_DESCR }</Text>
      </StyledWrapper>
    );

    const hasSapsanRoutes = routes.some(({ From, To }: { From: any, To: any }) =>
      SAPSAN_ROUTES_CODES.includes(From.code) && SAPSAN_ROUTES_CODES.includes(To.code));

    const shouldHideSapsan = !hasSapsanRoutes;

    return (
      <>
        { validationContent }
        <GeneralRules
          isUnique={ isUnique }
          store={ value }
          onChange={ v => onChange({ ...value, ...v }) }
          type={ type }
          shouldHideSapsan={ shouldHideSapsan }
        />
      </>
    );
  };

  const stepHeader = renderStepHeader();
  const stepContent = step === 0 ? renderFirstStep() : renderSecondStep();

  return (
    <div className={ styles.wrap }>
      { stepHeader }
      { stepContent }
    </div>
  );
};

export { ExceptionForm };
