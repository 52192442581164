import React from 'react';
import { Skeleton } from 'new-ui';

import styles from '../../../HotelMaplItem/style.module.css';
import stylesSkeleton from '../../styles/index.module.css';

const HotelMaplItemSkeleton = () => {
  const renderImage = () => (
    <div className={ styles['img-wrapper'] }>
      <div className={ stylesSkeleton['image-skeleton'] } >
        <Skeleton width={ 268 } height={ 140 } />
      </div>
    </div>
  );

  const renderPriceHtml = () => <Skeleton width={ 120 } height={ 20 } />;

  const renderNameHotelDefault = () => <Skeleton width={ 190 } height={ 10 } className={ styles.title }/>;

  const renderNameHotel = () => renderNameHotelDefault();

  const renderHeader = () => (
    <div className={ styles.header }>
      <div>
        {renderImage()}
      </div>
    </div>
  );

  const renderActions = () => (
    <div className={ styles.actions }>
      <div className={ styles['days-amount'] }>
        {renderPriceHtml()}
      </div>
      <div>
        <div>
          <Skeleton width={ 100 } height={ 10 } />
        </div>
      </div>
    </div>
  );

  const renderContent = () => (
    <div className={ styles.content }>
      <div className={ styles.info }>
        <div className={ `${styles['name-wrapper']}` }>
          {renderNameHotel()}
        </div>
        <Skeleton width={ 150 } height={ 10 } className={ styles.distance }/>
      </div>
      {renderActions()}
    </div>
  );

  return (
    <div className={ styles.wrap } >
      <div className={ styles.item }>
        {renderHeader()}
        {renderContent()}
      </div>
    </div>
  );
};

export { HotelMaplItemSkeleton };
