import React from 'react';
import { Button, Dialog, Text } from 'new-ui';
import { getText } from '../../../../../../i18n';

import styles from './styles.module.css';

type DialogRequestErrorProps = {
  onShow: (value: boolean) => void,
  onClose: () => void
};

const LABELS = {
  TITLE: getText('reports:operations.dialogRequestError.title'),
  MESSAGE: getText('reports:operations.dialogRequestError.message'),
  CLOSE: getText('common:close'),
};

const DialogRequestError = ({ onShow, onClose }: DialogRequestErrorProps) => (
  <Dialog show={ !!onShow } onChange={ onClose } outsideClickClosing>
    <div className={ styles.dialog }>
      <Text type='bold_20' className={ styles.title }>
        {LABELS.TITLE}
      </Text>
      <div className={ styles.error }>
        <Text className={ styles.message } type='NORMAL_14' color='red'>
          {LABELS.MESSAGE}
        </Text>
      </div>
      <div className={ styles.actions }>
        <Button onClick={ onClose } type='primary'>
          {LABELS.CLOSE}
        </Button>
      </div>
    </div>
  </Dialog>
);

export { DialogRequestError };
