import React, { useEffect, FC } from 'react';
import { Text, DotLoading } from 'new-ui';
import { observer } from 'mobx-react';

import { RouteComponentProps } from 'react-router-dom';
import ROUTES from '../../bi/constants/routes';
import { CONTRACT_ACTIONS } from '../../bi/constants/hotel';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import HotelsService from '../../bi/services/hotels';

import styles from './style.module.css';

interface MatchParams {
  action: string;
  guid: string;
}

interface ContractHotelProps extends RouteComponentProps<MatchParams> {
  hotelsService: HotelsService,
  history: RouteComponentProps['history'],
}

const ContractHotel: FC<ContractHotelProps> = observer(({
  hotelsService,
  match,
  history,
}) => {
  const {
    contractHotelStore: {
      loading,
      message,
    },
  } = useStores([MOBX_STORES.CONTRACT_HOTEL_STORE]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { params } = match;
    const notValid = !Object.values(CONTRACT_ACTIONS).some((action) => action === params.action);

    if (notValid) {
      return history.push({ pathname: ROUTES.SMARTDESK });
    }

    const { action, guid } = params;

    hotelsService.requestContract(action, guid);
  }, []);

  const goToSmartdesk = () => {
    history.push({ pathname: ROUTES.SMARTDESK });
  };

  const renderMessage = () => {
    if (!message) {
      return null;
    }

    return (
      <div className={ styles.message }>
        <Text type='NORMAL_16_140'>
          {message}
        </Text>
      </div>
    );
  };

  const renderPage = () => (
    <div className={ styles.wrap }>
      <div className={ styles.content }>
        <div className={ styles.logo } onClick={ goToSmartdesk }>
          <img
            src='/svg/logos/smartway.svg'
            alt='Smartway'
            title='Smartway'
          />
        </div>
        {renderMessage()}
      </div>
    </div>
  );

  const renderLoading = () => (
    <div className={ styles.loading }>
      <DotLoading />
    </div>
  );

  return loading ? renderLoading() : renderPage();
});

export { ContractHotel };
