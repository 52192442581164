import React from 'react';
import { DownloadLink } from 'new-ui';

import { ReportTypes } from '../../index';

const WIDTH_DOWNLOAD_LINK = '250px';

export interface ISingleReportButtonProps {
  label: string;
  text?: string;
  type: ReportTypes;
  openDialog: (type: ReportTypes) => void;
}

export const SingleReportButton = ({
  label,
  text,
  type,
  openDialog,
}: ISingleReportButtonProps) => (
  <DownloadLink
    width={ WIDTH_DOWNLOAD_LINK }
    label={ label }
    text={ text as string }
    onDownload={ () => openDialog(type) }
  />
);
