const CHECKOUTACTION = {
  CHECKOUTLOAD: 'checkout.load',
  UPDATE: 'checkout.update',
  PREUPDATE: 'checkout.pre.update',
  CHECKOUTUPDATEACCEPT: 'checkout.update.accept',
  CHECKOUTUPDATERULES: 'checkout.update.rules',
  CHECKOUTCLEARSTATE: 'checkout.clear.state',
  CHECKOUTVALIDATION: 'checkout.validation',
  CHECKOUTUPDATENAME: 'checkout.update.name',
  LOADDISCOUNT: 'checkout.load.discount',
  VALIDATERATE: 'checkout.validate.rate',
};

export default CHECKOUTACTION;
