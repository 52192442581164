import Api from '../../api';

import { airlineAdditionalServicesStore, IAirlineAdditionalServicesStore } from './store';

class AirlineAdditionalServices {
  api: Api['airlineAdditionalServices'];
  store: IAirlineAdditionalServicesStore;

  constructor(api: Api) {
    this.api = api.airlineAdditionalServices;
    this.store = airlineAdditionalServicesStore;
  }

  getAdditionalServiceData = async () => {
    try {
      const additionalServiceData = await this.api.getAirlineAdditionalService();

      this.store.updateAirlineAdditionalServices(additionalServiceData);
    } catch (error) {
      console.error(error);
    }
  };
}

export default AirlineAdditionalServices;
