import { getMoment } from '../utils/formatDate';
import { ITripPlanItem } from '../types/order';

const TripPlanItemDefault: ITripPlanItem = {
  Id: null,
  Owner: '',
  OwnerId: null,
  Name: '',
  EventType: '',
  ServiceType: '',
  IsClosing: null,
  EventDate: getMoment(),
  EventTime: '',
  Notification: null,
  AdditionalInfo: [''],
  index: null,
  isEdit: false,
};

export { TripPlanItemDefault };
