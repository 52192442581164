import { observer } from 'mobx-react';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Paginate, Table, PageLoader } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { ChartsAnalyticsSmartSummaryStore } from '../../../../../bi/services/chartsAnalytics/stores/smart/summary';
import { ChartsAnalyticsSummaryStore } from '../../../../../bi/services/chartsAnalytics/stores/summary';
import ChartsAnalytics from '../../../../../bi/services/chartsAnalytics';

import { ChartAnalyticsNoResult } from '../../noResult';

import ROUTES from '../../../../../bi/constants/routes';

import styles from './styles.module.css';

const CANCELED = getText('chartsAnalytics:canceled');

interface IRow {
  data: string[],
  subPageInfo: ISubPageInfo,
  serviceType: string;
}

interface ISubPageInfo {
  isCanceled: boolean,
  voucherId: string,
}

interface TaxiNestedTableProps {
  onPageChange(page: string | number): void,
  changeVoucher(tripId: string): void,
  typeStore: ChartsAnalyticsSummaryStore | ChartsAnalyticsSmartSummaryStore,
  isService?: boolean,
  path: string,
  chartsAnalyticsService: ChartsAnalytics
}

const TaxiNestedTable = observer(({
  onPageChange,
  typeStore,
  isService,
  path = '',
  chartsAnalyticsService,
  changeVoucher,
}: TaxiNestedTableProps) => {
  const {
    subTableInfo: {
      strings,
      columnsName,
    },
    paginate: {
      currentPage,
      itemsPerPage,
      total,
    },
    loading,
  } = typeStore;

  if (loading) return <PageLoader />;

  if (!strings?.length) return <ChartAnalyticsNoResult />;

  const history = useHistory();

  const redirectToSubPage = (tripId: string, serviceType: string) => {
    chartsAnalyticsService.changeSmartType(serviceType);
    changeVoucher(tripId);
    history.push(path);
  };

  const renderCanceled = (isCanceled: boolean, data: string[], indexData: number) => {
    if (isCanceled && indexData === data.length - 1) {
      return <div className={ styles.canceled }>{ CANCELED }</div>;
    }

    return null;
  };

  const valuesOnClickHtml = (
    value: string,
    tripId: string,
    indexData: number,
    serviceType: string,
  ) => (
    tripId && indexData === 0 && !path ? (
      <Link
        to={ `${ROUTES.TRIP.MAIN}/${tripId}` }
        target='_blank'
      >
        <Button
          className={ styles.link }
          type='textual-medium'
        >
          { value }
        </Button>
      </Link>
    ) : (
      <div className={ styles.redirect } onClick={ () => redirectToSubPage(tripId, serviceType) }>{ value }</div>
    ));

  const valuesHtml = (value: string, tripId: string, indexData: number, serviceType: string) => (tripId ?
    valuesOnClickHtml(value, tripId, indexData, serviceType)
    : <div className={ styles.route_color }>{ value }</div>);

  const valueTdClassName = isService ? '' : styles.row;
  const valueClassName = isService ? '' : styles.value_wrap;

  return (
    <>
      <div className={ styles.table }>
        <Table
          labelsHeader={ columnsName }
        >
          { strings.map(({ data, subPageInfo: { isCanceled, voucherId }, serviceType }: IRow, indexRow: number) => (
            <tr key={ indexRow }>
              { data.map((value: string, indexData: number) =>
                <td className={ valueTdClassName } key={ indexData }>
                  <div className={ valueClassName }>
                    { valuesHtml(value, voucherId, indexData, serviceType) }
                    { renderCanceled(isCanceled, data, indexData) }
                  </div>
                </td>)
              }
            </tr>
          )) }
        </Table>
      </div>
      <div className={ styles.footer }>
        <div className={ styles.paginate }>
          <Paginate
            page={ currentPage }
            itemsPerPage={ itemsPerPage }
            total={ total }
            onChange={ onPageChange }
          />
        </div>
      </div>
    </>
  );
});

export { TaxiNestedTable };
