import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Select, Text, Input, Paginate, PageLoader } from 'new-ui';
import { observer } from 'mobx-react';

import { RouteComponentProps } from 'react-router-dom';
import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { getText } from '../../../i18n';

import TravelersTable from './components/TravelersTable';
import FilterEmptyPanel from '../Note/components/FilterEmptyPanel/FilterEmptyPanel';

import scrollToTop from '../utils/scrollToTop';
import { MainAnalytic } from '../../bi/utils/analytics';

import ROUTES from '../../bi/constants/routes';
import STATUS, {
  FILTER_KEYS,
  TRAVELERS_STATUS_TYPE,
  TRAVELERS_STEP,
} from '../../bi/constants/employee';

import Settings from '../../bi/services/settings/index';
import Employee from '../../bi/services/employee/index';
import App from '../../bi/services/app/index';
import UserSession from '../../bi/services/userSession/index';
import Workspace from '../../bi/services/workspace';
import { SettingsProtocol } from '../../bi/protocols/settings';

import { IPassportView } from '../../bi/services/employee/types';
import { ICitizenship } from '../../bi/types/userSession';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('settings:travelers.title'),
  ADD_PASSENGER: getText('settings:travelers.addPassenger'),
  SEARCH: getText('settings:travelers.searchInput'),
  STATUS: getText('settings:travelers.status'),
  EMPTY_TRAVELERS: getText('settings:travelers.emptyTravelers'),
};

interface TravelersPageProps {
  history: RouteComponentProps['history']
  appService: App,
  settingsService: Settings,
  employeeService: Employee,
  userSessionService: UserSession,
  settingsProtocol: SettingsProtocol,
  workspaceService: Workspace,
}

const TravelersPage: FC<TravelersPageProps> = observer(({
  history,
  settingsService,
  settingsProtocol,
  userSessionService,
  employeeService,
  workspaceService,
}: TravelersPageProps) => {
  const [initComponent, setInitComponent] = useState(true);

  useLayoutEffect(() => {
    settingsService.clearStoreSettingsEmployee();

    return () => settingsService.clearStoreSettingsEmployee();
  }, []);

  useEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.MAIN_PASSENGERS_OPENED);
    settingsService.setEmployeeFlag();
    settingsService.changeCountItemsPerPage(TRAVELERS_STEP);
    settingsProtocol.getFilterEmployees(FILTER_KEYS.SELECT, STATUS.ACCESS.ACTIVE);

    setInitComponent(false);
  }, []);

  const documentType = userSessionService.get().enums.documents as IPassportView;
  const stores: any = useStores([MOBX_STORES.SETTINGS_STORE]);

  const {
    employeesCount,
    waitingResponse,
    filter: {
      search,
      select,
    },
    paginate: {
      currentPage,
      itemsPerPage,
      total,
    },
  } = stores.settingsStore;
  const enumsCitizenship = userSessionService.get().enums.citizenship;

  const handlePageChange = (page: number) => {
    settingsProtocol.changePageEmployees(page);
    scrollToTop();
  };

  const handleFilterEmployees = (field: string, value: string | number) =>
    settingsProtocol.getFilterEmployees(field, value);

  const redirectToEmployee = (id = '') => {
    const travelers = true;

    if (id) return history.push({ pathname: `${ROUTES.EMPLOYEE}${id}`, search: `travelers=${travelers}` });

    return history.push({ pathname: ROUTES.EMPLOYEE, search: `travelers=${travelers}` });
  };

  const handleAddEmployee = () => {
    employeeService.setEmployee(null, documentType, false);
    redirectToEmployee();
  };

  const handleResetFilters = () => settingsProtocol.resetFilters(FILTER_KEYS.SELECT, STATUS.ACCESS.ACTIVE, TRAVELERS_STEP);

  const renderLoading = () => <PageLoader />;

  const renderAddEmployeeButton = workspaceService.canEditEmployee && (
    <div>
      <Button
        className={ styles.button }
        onClick={ handleAddEmployee }
      >
        <Text type='NORMAL_14_130' color='white'>
          { LABELS.ADD_PASSENGER }
        </Text>
      </Button>
    </div>
  );

  if (initComponent) return renderLoading();

  if (!waitingResponse && select === TRAVELERS_STATUS_TYPE[0].value && !search && !employeesCount) {
    return (
      <div className={ styles.empty }>
        { LABELS.EMPTY_TRAVELERS }
        <div className={ styles.button }>
          { renderAddEmployeeButton }
        </div>
      </div>
    );
  }

  const renderFilterEmptyPanel = !waitingResponse ? (
    <div className={ styles.empty }>
      <FilterEmptyPanel
        onResetAllFilters={ handleResetFilters }
      />
    </div>
  ) : null;

  const employeesPresent = (search || select !== TRAVELERS_STATUS_TYPE[0].value) && !employeesCount
    ? renderFilterEmptyPanel
    : (
      <TravelersTable
        stores={ stores }
        enumsCitizenship={ enumsCitizenship as ICitizenship[] }
        onPageChange={ handlePageChange }
        onEditEmployee={ (Id:string) => redirectToEmployee(Id) }
      />
    );

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.header }>
        <div className={ styles.header_content }>
          <Text type='bold_32'>
            { LABELS.TITLE }
          </Text>
          <div className={ styles.filter }>
            <Select
              withLabel
              excludeValue
              theme='default-large'
              value={ select }
              className={ styles.select }
              placeholder={ LABELS.STATUS }
              items={ TRAVELERS_STATUS_TYPE }
              onChange={ (value: string) => handleFilterEmployees(FILTER_KEYS.SELECT, value) }
            />
          </div>
        </div>
        {renderAddEmployeeButton}
      </div>
      <div>
        <Input
          className={ styles.search }
          isCleansing
          placeholder={ LABELS.SEARCH }
          theme='light-shadow'
          value={ search }
          onChange={ (value: string) => handleFilterEmployees(FILTER_KEYS.SEARCH, value) }
        />
      </div>
      <div className={ styles.content }>
        {employeesPresent}
      </div>
      <div className={ styles.paginate }>
        <Paginate
          page={ currentPage }
          itemsPerPage={ itemsPerPage }
          total={ total }
          onChange={ handlePageChange }
        />
      </div>
    </div>
  );
});

export { TravelersPage };
