import React from 'react';
import { observer } from 'mobx-react';
import { Text } from 'new-ui';

import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import { formatDateWithMinutes } from '../../../../../bi/utils/formatDate';

import { CommentType } from '../../../../../bi/services/tripComments/types';

import styles from '../styles/history.module.css';

const HistoryComments = observer(() => {
  const {
    tripCommentsStore: { comments },
  } = useStores([MOBX_STORES.TRIP_COMMENTS]);

  const renderItem = ({ CreatedBy, DateCreated, Email, Id, Text: textComment }: CommentType, ind: number) => {
    const createdBy = CreatedBy || Email;

    return (
      <div key={ `trip_comment_${Id}_${ind}` } className={ styles.item }>
        <Text className={ styles.info }>
          { formatDateWithMinutes(DateCreated) }, { createdBy }
        </Text>
        <Text type='NORMAL_14_130'>
          { textComment }
        </Text>
      </div>
    );
  };

  return (
    <div className={ styles.wrapper }>
      { comments.map(renderItem) }
    </div>
  );
});

export { HistoryComments };
