import { ICON_LIST, SMARTWAY } from '../constants/accounts';

interface IPosition {
  X: number;
  Y: number;
}

const LINKSELECTOR: string = 'link[rel="icon"], link[rel="apple-touch-icon"]';
const BRANDCOLOR: string = '#ff1d1d';
const WHITECOLOR: string = '#fff';
const FAVICONWIDTH: number = 16;

const POSITION: { DOT: IPosition, IMG: IPosition } = {
  DOT: {
    X: 12,
    Y: 3,
  },
  IMG: {
    X: 0,
    Y: 0,
  },
};

const DOTRADIUS: number = 3;
const DOTSTARTARC: number = 0;
const DOTENDARC: number = 2 * Math.PI;
const STROKEWIDTH: number = 1;

class FaviconMode {
  iconLinks: NodeListOf<HTMLLinkElement>;
  isDotSet: boolean;

  constructor() {
    this.iconLinks = document.querySelectorAll(LINKSELECTOR);
  }

  setDot = (directoryName: string): void => {
    if (!this.isDotSet) {
      const canvas = document.createElement('canvas');
      canvas.width = FAVICONWIDTH;
      canvas.height = FAVICONWIDTH;

      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.fillStyle = BRANDCOLOR;

        const img = new Image();

        img.onload = () => {
          ctx.drawImage(img, POSITION.IMG.X, POSITION.IMG.Y);

          ctx.beginPath();
          ctx.arc(POSITION.DOT.X, POSITION.DOT.Y, DOTRADIUS, DOTSTARTARC, DOTENDARC, false);
          ctx.fillStyle = BRANDCOLOR;
          ctx.fill();
          ctx.lineWidth = STROKEWIDTH;
          ctx.strokeStyle = WHITECOLOR;
          ctx.stroke();

          if (this.iconLinks && this.iconLinks.length) {
            this.iconLinks.forEach((link) => {
              if (link.parentNode) {
                link.parentNode.removeChild(link);
              }
            });
          }

          const linkIcon = document.createElement('link');
          linkIcon.setAttribute('rel', 'icon');
          linkIcon.setAttribute('href', canvas.toDataURL());
          document.querySelector('head')?.appendChild(linkIcon);
        };

        let directory = 'smartway';

        if (directoryName !== SMARTWAY && ICON_LIST[directoryName]) {
          directory = directoryName;
        }

        img.src = `../../../../public/icons/${directory}/favicon-16x16.png`;
      }
    }
  };

  removeDot = (): void => {
    if (this.iconLinks && this.iconLinks.length) {
      const iconList = document.querySelectorAll(LINKSELECTOR);
      iconList.forEach((link) => {
        link.parentNode?.removeChild(link);
      });

      const header = document.querySelector('head');
      this.iconLinks.forEach((link) => {
        header?.appendChild(link);
      });
    }
  };
}

const Favicon = new FaviconMode();

export default Favicon;
