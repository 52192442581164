import React, { Component, ReactNode } from 'react';
import { Text, Button } from 'new-ui';

import { getText } from '../../../../../i18n';

import ROUTES from '../../../../bi/constants/routes';

import styles from './styles.module.css';

interface Props {
  history: any,
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  lastPathName: string;
}

const LABELS = {
  SOMETHING_WRONG: getText('components:errorBoundary.somethingWrong'),
  DESCRIPTION: getText('components:errorBoundary.description'),
  GO_BACK: getText('components:errorBoundary.goBack'),
};

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    lastPathName: this.props.history.location.pathname,
  };

  componentDidMount() {
    const { history } = this.props;

    history.listen((location: any) => {
      const { lastPathName } = this.state;

      if (lastPathName !== location.pathname) {
        this.setState({ hasError: false });
      }
    });
  }

  public static getDerivedStateFromError() {
    // @ts-ignore
    return { hasError: true };
  }

  handleBackToSmartdesk = (): void => {
    const { history } = this.props;

    this.setState({ hasError: false });
    history.push(ROUTES.SMARTDESK);
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className={ styles.wrapper }>
          <Text type='bold_22'>
            { LABELS.SOMETHING_WRONG }
          </Text>
          <Text
            className={ styles.description }
            type='NORMAL_16'
          >
            { LABELS.DESCRIPTION }
          </Text>
          <Button
            type='secondary'
            onClick={ this.handleBackToSmartdesk }
          >
            { LABELS.GO_BACK }
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
