import React, { FC } from 'react';
import { Select, Tag } from 'new-ui';

import { TransferItem } from '../../../TransferItem';

import { StickyPanel } from '../../../../../../components/Sticky';

import { SORT } from '../../../../../../bi/constants/transfers';

import { TagType } from '../../../../../../bi/services/transfer/types';
import { PrepareRateType, ITransferItem } from '../../../../../../bi/types/transfer';

import styles from './index.module.css';

const STICKY_PANEL_ADJUSTMENT = 188;

interface ResultListProps {
  items: PrepareRateType[],
  tags: TagType[],
  travelPolicyList: [],
  selectedTravelPolicy: string,
  sortBy: string,
  sortSearchResult(opts: string): void,
  onCloseTag(tag: TagType): void,
  onAddToNotepad(transfer: ITransferItem): void,
  onAddToShoppingCart(transfer: ITransferItem): void,
  onAddToFavorite(item: ITransferItem, action: string): void,
  onAlreadyInCart(id: string): void,
}

const ResultList: FC<ResultListProps> = ({
  items,
  tags,
  travelPolicyList,
  selectedTravelPolicy,
  sortBy,
  sortSearchResult,
  onCloseTag,
  onAddToNotepad,
  onAddToShoppingCart,
  onAddToFavorite,
  onAlreadyInCart,
}) => {
  const itemsHtml = items.map((item, index) => (
    <TransferItem
      index={ index }
      key={ item.RateId }
      item={ item }
      travelPolicyList={ travelPolicyList }
      selectedTravelPolicy={ selectedTravelPolicy }
      onAddToNotepad={ onAddToNotepad }
      onAddToShoppingCart={ onAddToShoppingCart }
      onAddToFavorite={ onAddToFavorite }
      onAlreadyInCart={ onAlreadyInCart }
    />
  ));

  const tagsHtml = !!tags.length && tags.map(tag => {
    const { name, key, readOnly } = tag;

    return (
      <Tag
        label={ name }
        readOnly={ readOnly }
        onClick={ () => onCloseTag(tag) }
        key={ key }
        className={ styles.tag }
      />
    );
  });

  return (
    <div className={ styles.items }>
      <StickyPanel
        adjustment={ STICKY_PANEL_ADJUSTMENT }
        fixedClass={ styles['sticky-panel-fixed'] }
        className={ styles['sticky-panel'] }
      >
        <div className={ styles.header }>
          <div className={ styles.main }>
            <div className={ styles.select }>
              <Select
                theme='default-large'
                items={ SORT }
                onChange={ sortSearchResult }
                value={ sortBy }
              />
            </div>
            <div className={ styles.tags }>
              { tagsHtml }
            </div>
          </div>
        </div>
      </StickyPanel>
      <div className={ styles.list }>
        { itemsHtml }
      </div>
    </div>
  );
};

export { ResultList };
