import React, { FC, useState } from 'react';
import { MultiSelect } from 'new-ui';
import { useQuery } from '@tanstack/react-query';

import { api } from '../../../../../bi/apiV2';

import { getText } from '../../../../../../i18n';

import { getObjectForSelect } from '../../../../../bi/utils/getSelectedFromSetAndArray';

const LABELS = {
  PLACEHOLDER: getText('trips:cities'),
  SEARCH_PLACEHOLDER: getText('trips:nameOfTheCity'),
};

const useCities = (query: string) => useQuery({
  queryKey: ['tripsFilters', query],
  queryFn: () => api.trips.autocompleteCities(query),
  staleTime: 60000,
  enabled: !!query.length,
});

interface FilterCitiesProps {
  cities: string[];
  values: string[];
  onChange(checkedCities: string[]): void;
}

const FilterCities: FC<FilterCitiesProps> = ({ cities, values, onChange }) => {
  const [autoComplete, setAutoComplete] = useState('');
  const { data } = useCities(autoComplete);

  const citisToSelect = data?.length ? data : cities;

  const citiesList = citisToSelect.sort().map((city: string) => getObjectForSelect(city));

  return (
    <MultiSelect
      withLabel
      placeholder={ LABELS.PLACEHOLDER }
      list={ citiesList }
      values={ values }
      search
      searchPlaceholder={ LABELS.SEARCH_PLACEHOLDER }
      onChange={ onChange }
      onSearchFunction={ setAutoComplete }
    />
  );
};

export { FilterCities };
