import { Moment } from 'moment';

import { getText } from '../../../../../../i18n';

const labels: { [key: string]: string } = {
  name: getText('event:events.fields.name'),
};

const labelsCorrect: { [key: string]: string } = {
  startDate: getText('event:events.fields.startDate'),
  endDate: getText('event:events.fields.endDate'),
};

const TYPE_VALID_MICE = {
  REQUIRED: 'REQUIRED',
  CORRECT: 'CORRECT',
};

interface IValid {
  name?: string,
  place?: string,
  budget?: string,
  startDate?: string | Moment | null,
  endDate?: string | Moment | null,
  member?: string
}

const setFormValidationMice = (values: IValid, type: string) => {
  const label = type === TYPE_VALID_MICE.REQUIRED ? labels : labelsCorrect;

  const missingValues = Object.entries(values)
    .filter(([key, value]) => !value && key)
    .map(([key]) => label[key]);

  return missingValues.join(', ');
};

export {
  TYPE_VALID_MICE,
  setFormValidationMice,
};
