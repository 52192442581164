import { observable, action, computed, makeObservable } from 'mobx';

import type { BaseServiceTypeTravelPolicyStore } from '../../types';
import type { TravelPolicyTaxiVoucherRule, TravelPolicyGeneralTaxiRule } from './types';

import {
  TRAVEL_POLICY_FIELDS_DICTIONARIES,
  TRAVEL_POLICY_STORE_FIELDS,
} from '../../consts';

const DEFAULT_GENERAL_RULES = {
  prohibitBook: {
    IsEnabled: false,
  },
  Class: {
    IsEnabled: false,
    Value: TRAVEL_POLICY_FIELDS_DICTIONARIES.TAXI_CLASSES.ECONOMY,
    ProhibitIsEnabled: false,
  },
  DayOff: {
    IsEnabled: false,
    ProhibitIsEnabled: false,
  },
};

const DEFAULT_STORE = {
  [TRAVEL_POLICY_STORE_FIELDS.APPLY]: false,
  ...DEFAULT_GENERAL_RULES,
};

class TaxiTravelPolicyStore implements BaseServiceTypeTravelPolicyStore {
  constructor() {
    makeObservable(this);
  }

  @observable store = DEFAULT_STORE;

  @computed
  get preparedToSaveModel(): TravelPolicyTaxiVoucherRule {
    const { ...rest } = this.store;

    return {
      ...rest,
      prohibitBook: this.store.prohibitBook.IsEnabled,
      DayOff: this.store.DayOff.IsEnabled,
      Class: {
        IsEnabled: this.store.Class.IsEnabled,
        value: this.store.Class.Value,
      },
    };
  }

  @action
  mapServerModelToClient = (model: TravelPolicyGeneralTaxiRule): void => {
    const { ...rest } = model;

    this.store = {
      ...this.store,
      ...rest,
    };
  };

  @action
  updateStore = (payload: any) => {
    this.store = {
      ...this.store,
      ...payload,
    };
  };

  @action
  clearStore = () => {
    this.store = DEFAULT_STORE;
  };
}

export default TaxiTravelPolicyStore;
