// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { SearchHistory } from '../../../../components/SearchHistory';
import { AirlinesHistoryItem } from '../AirlinesHistoryItem';

class AirlineHistory extends Component {
  static propTypes = {
    airlineService: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    qaAttrs: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  };

  static defaultProps = {
    qaAttrs: null,
  };

  state = {
    items: [],
  };

  componentDidMount() {
    const { airlineService } = this.props;

    airlineService.getHistory();

    this.unsubscribeFn = airlineService.subscribeHistory(this.updateHistory);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateHistory = ({ items }) => this.setState({
    items,
  });

  render() {
    const { items } = this.state;
    const { onClick, qaAttrs } = this.props;

    if (!items.length) return null;

    return (
      <SearchHistory
        items={ items }
        onClick={ onClick }
        renderItem={ (i) => <AirlinesHistoryItem item={ i } /> }
        qaAttrLabel={ qaAttrs?.text || '' }
        qaAttrWrapper={ qaAttrs?.wrapper || '' }
      />
    );
  }
}

export default AirlineHistory;
