export interface IFieldUser {
  SURNAME: 'Surname';
  NAME: 'Name';
  PATRONYMIC: 'Patronymic';
  NUMBER: 'Number';
  DUEDATE: 'DueDate';
  LASTNAME: 'LastName';
  FIRSTNAME: 'FirstName';
  MIDDLENAME: 'MiddleName';
  TRAVEL_POLICY: 'TravelPolicy',
}

const DOCUMENTSFIELDS: IFieldUser = {
  SURNAME: 'Surname',
  NAME: 'Name',
  PATRONYMIC: 'Patronymic',
  NUMBER: 'Number',
  DUEDATE: 'DueDate',
  LASTNAME: 'LastName',
  FIRSTNAME: 'FirstName',
  MIDDLENAME: 'MiddleName',
  TRAVEL_POLICY: 'TravelPolicy',
};

export default DOCUMENTSFIELDS;
