// @ts-nocheck
import request from 'superagent';

import { logout } from './index';

const AUTHHEADER = 'Authorization';
const CONTENTTYPEHEADER = 'Content-Type';
const ACCEPTTYPE = 'application/json';
const X_REFERER = 'x-referer';

const CONTENTTYPE = {
  JSON: 'application/json;charset=utf-8',
  FORM: 'application/x-www-form-urlencoded',
  PLAIN: 'text/plain',
};

const RequestWrapLogin = (fn) => {
  let hasCanceled = false;

  const promise = new Promise((resolve, reject) => {
    fn.end((err, response) => {
      if (hasCanceled) {
        return reject('hasCanceled');
      }

      if (err) {
        return reject(err, response);
      }

      return resolve(JSON.parse(response.text));
    });
  });

  promise.end = promise.then;
  promise.abort = fn.abort.bind(fn);
  promise.cancel = () => {
    hasCanceled = true;
  };

  return promise;
};

const RequestWrap = (fn) => {
  let hasCanceled = false;

  const promise = new Promise((resolve, reject) => {
    fn.end((err, response) => {
      if (hasCanceled) {
        return reject('hasCanceled');
      }

      if (err && response?.statusCode === 401) {
        logout();
      }

      if (err) {
        return reject(err, response);
      }

      return resolve(response.body);
    });
  });

  promise.end = promise.then;
  promise.abort = fn.abort.bind(fn);
  promise.cancel = () => {
    hasCanceled = true;
  };

  return promise;
};

const RequestFullWrap = (fn) => {
  let hasCanceled = false;

  const promise = new Promise((resolve, reject) => {
    fn.end((err, response) => {
      if (hasCanceled) {
        return reject('hasCanceled');
      }

      if (err && response.statusCode === 401) {
        logout();
      }

      if (err) {
        return reject({ error: err, status: response.status });
      }

      return resolve({ body: response.body, status: response.status });
    });
  });

  promise.end = promise.then;
  promise.abort = fn.abort.bind(fn);
  promise.cancel = () => {
    hasCanceled = true;
  };

  return promise;
};

const RequestWrapFromFile = (fn) => {
  let hasCanceled = false;

  const promise = new Promise((resolve, reject) => {
    fn.end((err, response) => {
      if (hasCanceled) {
        return reject('hasCanceled');
      }

      if (err && response.statusCode === 401) {
        logout();
      }

      if (err) {
        return reject(err, response);
      }

      return resolve({ body: response.body, headers: response.headers });
    });
  });

  promise.end = promise.then;
  promise.abort = fn.abort.bind(fn);
  promise.cancel = () => {
    hasCanceled = true;
  };

  return promise;
};

const getJSON = (token, url, params = null) => RequestWrap(request.get(url)
  .query(params)
  .set({
    [CONTENTTYPEHEADER]: CONTENTTYPE.JSON,
    [AUTHHEADER]: `Bearer ${token}`,
    [X_REFERER]: document.location.href,
  })
  .accept(ACCEPTTYPE)
  .withCredentials(),
);

const sendFile = (token, url, data, progressFunc, params = null) => RequestWrap(request.post(url)
  .query(params)
  .set({
    [AUTHHEADER]: `Bearer ${token}`,
  })
  .attach('file', data)
  .on('progress', progressFunc)
  .accept(ACCEPTTYPE),
);

const getFullJSON = (token, url, params = null) => RequestFullWrap(request.get(url)
  .query(params)
  .set({
    [CONTENTTYPEHEADER]: CONTENTTYPE.JSON,
    [AUTHHEADER]: `Bearer ${token}`,
    [X_REFERER]: document.location.href,
  })
  .accept(ACCEPTTYPE)
  .withCredentials());

const sendFullJSON = (token, url, data) => RequestFullWrap(request.post(url)
  .set({
    [CONTENTTYPEHEADER]: CONTENTTYPE.JSON,
    [AUTHHEADER]: `Bearer ${token}`,
    [X_REFERER]: document.location.href,
  })
  .accept(ACCEPTTYPE)
  .withCredentials()
  .send(data),
);

const getBinaryFile = (token, url, params = null) => RequestWrapFromFile(request.get(url)
  .query(params)
  .responseType('blob')
  .set({
    [AUTHHEADER]: `Bearer ${token}`,
    [X_REFERER]: document.location.href,
  })
  .withCredentials(),
);

const sendJSON = (token, url, data) => RequestWrap(request.post(url)
  .set({
    [CONTENTTYPEHEADER]: CONTENTTYPE.JSON,
    [AUTHHEADER]: `Bearer ${token}`,
    [X_REFERER]: document.location.href,
  })
  .accept(ACCEPTTYPE)
  .withCredentials()
  .send(data),
);

const putJSON = (token, url, data) => RequestWrap(request.put(url)
  .set({
    [CONTENTTYPEHEADER]: CONTENTTYPE.JSON,
    [AUTHHEADER]: `Bearer ${token}`,
    [X_REFERER]: document.location.href,
  })
  .accept(ACCEPTTYPE)
  .withCredentials()
  .send(data),
);

const delJSON = (token, url, data) => RequestWrap(request.del(url)
  .set({
    [CONTENTTYPEHEADER]: CONTENTTYPE.JSON,
    [AUTHHEADER]: `Bearer ${token}`,
    [X_REFERER]: document.location.href,
  })
  .accept(ACCEPTTYPE)
  .withCredentials()
  .send(data),
);

const sendJSONWithoutToken = (url, data) => RequestWrapLogin(request.post(url)
  .set({
    [CONTENTTYPEHEADER]: CONTENTTYPE.JSON,
    [X_REFERER]: document.location.href,
  })
  .accept(ACCEPTTYPE)
  .withCredentials()
  .send(data),
);

const getJSONWithoutToken = (url, data = null) => RequestWrap(request.get(url)
  .query(data)
  .set({
    [CONTENTTYPEHEADER]: CONTENTTYPE.JSON,
    [X_REFERER]: document.location.href,
  })
  .accept(ACCEPTTYPE)
  .withCredentials(),
);

const putJSONWithoutToken = (url, data = null) => RequestWrap(request.put(url)
  .set({
    [CONTENTTYPEHEADER]: CONTENTTYPE.JSON,
    [X_REFERER]: document.location.href,
  })
  .accept(ACCEPTTYPE)
  .withCredentials()
  .send(data),
);

const getFile = (downloadFileUrl, guid, resolve) => {
  const downloadDiv = document.getElementById('downloadSector');
  const srcUrl = `${downloadFileUrl}/${guid}`;
  const iframe = document.createElement('iframe');
  iframe.src = srcUrl;
  downloadDiv.innerHTML = '';
  downloadDiv.appendChild(iframe);

  resolve();
};

const downloadBlob = (res, filename, resolve) => {
  const url = window.URL.createObjectURL(new Blob([res]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  const downloadDiv = document.getElementById('downloadSector');
  downloadDiv.innerHTML = '';
  downloadDiv.appendChild(link);
  link.click();

  resolve();
};

const downloadWithGuidBinaryFile = (token, url, params = null) => new Promise((resolve, reject) => {
  getBinaryFile(token, url, params).then(res => {
    const filename = res.headers['content-disposition'].split('filename=')[1].split(';')[0].replace(/"/g, '').trim();
    downloadBlob(res.body, decodeURIComponent(escape(filename)), resolve);
  }).catch(e => {
    reject(e);
  });
});

const downloadWithGuid = (token, url, downloadFileUrl, data) => new Promise((resolve, reject) => {
  getJSON(token, url, data).then(guid => {
    getFile(downloadFileUrl, guid.replace(/"/g, ''), resolve);
    resolve();
  },
  (err) => {
    reject(err);
  });
});

const downloadWithGuidSendJSON = (token, url, downloadFileUrl, data) => new Promise((resolve, reject) => {
  sendJSON(token, url, data).then(guid => {
    getFile(downloadFileUrl, guid.replace(/"/g, ''), resolve);
    resolve();
  },
  (err) => {
    reject(err);
  });
});

const xhr = {
  getJSON,
  sendJSON,
  putJSON,
  getFile,
  delJSON,
  downloadWithGuid,
  downloadWithGuidSendJSON,
  downloadWithGuidBinaryFile,
  getJSONWithoutToken,
  sendJSONWithoutToken,
  putJSONWithoutToken,
  getFullJSON,
  sendFullJSON,
  sendFile,
};

export default xhr;
