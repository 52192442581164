import React, { FC, useState } from 'react';
import { MultiSelect } from 'new-ui';
import { useQuery } from '@tanstack/react-query';

import { api } from '../../../../../../bi/apiV2';

import { UserAnalytic, UserAnalyticValue } from '../../../../../../bi/types/trips';

import styles from '../../index.module.css';

const useUserAnalytics = (id: number, query: string) => useQuery({
  queryKey: ['tripsFilters', id, query],
  queryFn: () => api.trips.autocompleteUserAnalytics(id, query),
  staleTime: 60000,
  enabled: !!query.length,
});

const getUserAnalyticForSelect = (userAnalyticValue: UserAnalyticValue) => ({ value: userAnalyticValue.Id, label: userAnalyticValue.Name });
const getUserAnalyticForFilter = (userAnalyticValue: { value: number, label: string }) => ({ Id: userAnalyticValue.value, Name: userAnalyticValue.label });

const getUserAnalyticsForSelect = (userAnalytic: UserAnalytic) =>
  userAnalytic.Values.map(userAnalyticValue => getUserAnalyticForSelect(userAnalyticValue));

interface FilterUserAnalyticProps {
  userAnalytic: UserAnalytic;
  values: number[];
  onChange(checkedTags: number[], checkedUserAnalyticsWithLabels: any): void;
}

const FilterUserAnalytic: FC<FilterUserAnalyticProps> = ({
  userAnalytic,
  values,
  onChange,
}) => {
  const [autoComplete, setAutoComplete] = useState('');
  const { data } = useUserAnalytics(userAnalytic.Id, autoComplete);

  const userAnalyticList = data?.length ?
    data.map((userAnalyticValue) => getUserAnalyticForSelect(userAnalyticValue)) :
    getUserAnalyticsForSelect(userAnalytic);

  const handleChange = (checkedUserAnalytics: number[], checkedUserAnalyticsWithLabels: any) => {
    const checkedUserAnalyticsWithLabelsModified = checkedUserAnalyticsWithLabels.map((userAnalyticsWithLabel: { value: number; label: string; }) => getUserAnalyticForFilter(userAnalyticsWithLabel));
    onChange(checkedUserAnalytics, { Values: checkedUserAnalyticsWithLabelsModified, Id: userAnalytic.Id });
  };

  return (
    <div key={ userAnalytic.Id } className={ styles.filter }>
      <MultiSelect
        withLabel
        placeholder={ userAnalytic.Name }
        list={ userAnalyticList }
        values={ values }
        search
        searchPlaceholder={ '' }
        onChange={ handleChange }
        onSearchFunction={ (value: React.SetStateAction<string>) => setAutoComplete(value) }
      />
    </div>
  );
};

export { FilterUserAnalytic };
