import React from 'react';
import { observer } from 'mobx-react';

import { Text } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { RightsDialogWrap } from '../RightsDialogWrap';

import { isSmartAgent } from '../../../../../bi/utils/env';

import styles from '../index.module.css';

const LABELS = {
  SUBTITLE: (email: string) => getText('settings:employees.employee.steps.rights.steps.close.subtitle', { email }),
  TEXT: (name: string) => getText('settings:employees.employee.steps.rights.steps.close.text', { name }),
  USER: getText('settings:employees.employee.steps.rights.steps.close.user'),
  EMPLOYEE: getText('settings:employees.employee.steps.rights.steps.close.employee'),
};

const closeTextEmployee = isSmartAgent ? LABELS.USER : LABELS.EMPLOYEE;

interface ICloseStep {
  email: string,
  active: string,
  header: string,
  rights: {
    loadingButton: boolean,
  }
  onClose(): void,
  onCloseAccess(): void,
}

const CloseStep = observer(({
  email,
  active,
  header,
  rights,
  onClose,
  onCloseAccess,
}:ICloseStep) => (
  <RightsDialogWrap
    loading={ rights.loadingButton }
    header={ header }
    theme={ active }
    onClickButton={ onCloseAccess }
    onClickCancel={ onClose }
  >
    <Text className={ styles.subtitle }>{ LABELS.SUBTITLE(email) }</Text>
    <Text>{ LABELS.TEXT(closeTextEmployee) }</Text>
  </RightsDialogWrap>
));

export { CloseStep };
