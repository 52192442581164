import { getRouteTitleAdditionalBaggage } from './getRouteTitleAdditionalBaggage';
import { getFilteredCartDataForBaggageWarning } from './getFilteredCartDataForBaggageWarning';

import { IEmployee } from './employees';
import { ICartItemInfo, IFilteredCartData } from '../types/airlineAdditionalServices';

/** Утилита для сбора имени пассажира */
const getEmployeeNames = (employees: Record<string, IEmployee>) =>
  Object.values(employees).map(employee => {
    const { Surname, Name, Patronymic } = employee;

    return `${Surname} ${Name} ${Patronymic}`;
  }).join(', ');

/** Утилита для формирования строк с данными о роуте и пасажирах, для которых нужно перевыбрать багаж */
const transformAndFormatData = (newCartData: Record<string, IFilteredCartData>) => {
  const formattedData: Record<string, string[]> = {};

  Object.keys(newCartData).forEach(id => {
    const item = newCartData[id];

    if (item?.Routes?.Routes) {
      const routes = item.Routes.Routes;

      routes.forEach(({ Segments: segments }) => {
        const routeTitle = getRouteTitleAdditionalBaggage(segments, true);
        const employeesList = getEmployeeNames(item.Employees);

        if (!formattedData[id]) {
          formattedData[id] = [];
        }

        formattedData[id].push(`${routeTitle}: ${employeesList}`);
      });
    }
  });

  return formattedData;
};

/** Утилита для формирования данных для уведомления о сбросе багажа */
const getInfoForBaggageClearWarning = (sources?: ICartItemInfo[]) => {
  const keySelectedBaggage = JSON.parse(localStorage.getItem('keySelectedBaggage') as string);

  const filteredCart = sources?.filter(item => keySelectedBaggage?.includes(String(item.Id)));

  const filteredCartData = getFilteredCartDataForBaggageWarning(filteredCart);
  const transformedData = transformAndFormatData(filteredCartData);

  return transformedData;
};

export { getInfoForBaggageClearWarning };
