import Api from '../../api';

import { IToDoItem } from '../../types/toDo';

class ToDo {
  api: Api['toDo'];

  constructor(api: Api) {
    this.api = api.toDo;
  }

  get = () => this.api.get();

  saveOrUpdate = (item: IToDoItem) => {
    if (item.Id) {
      return this.api.update(item);
    }

    return this.api.save(item);
  };

  remove = (id: number) => this.api.remove(id);

  removeAll = () => this.api.removeAll();
}

export default ToDo;
