import { isSmartAgent } from './env';

import { ACCOUNT_TYPE } from '../constants/accounts';

const accountType = () => {
  if (isSmartAgent) {
    return ACCOUNT_TYPE.SMART_AGENT;
  }

  return ACCOUNT_TYPE.SMARTWAY;
};

export { accountType };
