import React, { useMemo } from 'react';
import { Text, LinkButton } from 'new-ui';
import { getText } from '../../../../../i18n';

import { AddButton } from '../../../../components/AddButton';
import { RemovableListItem } from '../../../../components/RemovableListItem';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';

import styles from './index.module.css';

type ExceptionsListProps = {
  isUnique: boolean | undefined,
  list: any[],
  type: string,
  onAdd: (list: any) => void,
  onEdit: (ind: number, list: any) => void,
  onChange: (ind: number, v: any) => void,
};

const EXCEPTION_LABEL = {
  [SERVICETYPE.AIR]: getText('settings:travelPolicy.rules.exceptions.labels.air'),
  [SERVICETYPE.TRAIN]: getText('settings:travelPolicy.rules.exceptions.labels.train'),
  [SERVICETYPE.HOTEL]: getText('settings:travelPolicy.rules.exceptions.labels.hotel'),
};

const LABELS = {
  EXCEPTIONS: getText('settings:travelPolicy.rules.exceptions.exceptions'),
  ADD_EXCEPTION: getText('settings:travelPolicy.rules.exceptions.addException'),
  ADD: getText('settings:travelPolicy.rules.exceptions.add'),
};

const getLinkText = (type: string) => ({
  [SERVICETYPE.AIR]: getText('settings:travelPolicy.rules.exceptions.emptyExceptions.air'),
  [SERVICETYPE.HOTEL]: getText('settings:travelPolicy.rules.exceptions.emptyExceptions.hotel'),
  [SERVICETYPE.TRAIN]: getText('settings:travelPolicy.rules.exceptions.emptyExceptions.train'),
})[type];

const prepareTypeText = (serviceType: string, text: string) => (serviceType === SERVICETYPE.HOTEL ? `(${text})` : text);

const ExceptionsList = ({
  isUnique,
  list,
  type,
  onAdd,
  onEdit,
  onChange,
}: ExceptionsListProps) => {
  const renderEmpty = () => (
    <div className={ styles.empty }>
      <LinkButton
        theme='blue-without-border'
        onClick={ () => onAdd(list) }
      >
        { LABELS.ADD_EXCEPTION }
      </LinkButton>
      &nbsp;
      <Text>
        { getLinkText(type) }
      </Text>
    </div>
  );

  const renderItem = (i: any, ind: number) => {
    const {
      fullNameModel: {
        typeText,
        restrictions,
      },
      isRemoved,
      isNew,
    } = i;

    if (isNew) {
      return null;
    }

    const contentClassNames = [styles.content];

    if (isRemoved) {
      contentClassNames.push(styles.removed);
    }

    return (
      <RemovableListItem
        isUnique={ !!isUnique }
        value={ i }
        key={ ind }
        className={ styles.item }
        onChangeStatus={ v => onChange(ind, v) }
        onClick={ () => onEdit(ind, list) }
        renderLabel={ () => (
          <Text className={ contentClassNames.join(' ') }>
            <Text
              className={ styles.link }
              color='accent'
            >
              {EXCEPTION_LABEL[type]}{prepareTypeText(type, typeText)}:
            </Text>
            &nbsp;
            { restrictions }
          </Text>
        ) }
      />
    );
  };

  const filteredList = useMemo(() => list.filter(({ isNew }) => !isNew), [list]);

  if (!filteredList.length && !isUnique) {
    return renderEmpty();
  }

  const items = list.map(renderItem);

  const addButtonHtml = !isUnique ? (
    <AddButton
      className={ styles.button }
      text={ LABELS.ADD }
      onClick={ () => onAdd(list) }
    />
  ) : null;

  const exceptionsHeader = !isUnique ? (
    <Text type='bold_18' className={ styles.exception }>
      { LABELS.EXCEPTIONS }
    </Text>
  ) : null;

  return (
    <div className={ styles.wrap }>
      { exceptionsHeader }
      <div className={ styles.items }>
        { items }
      </div>
      { addButtonHtml }
    </div>
  );
};

export { ExceptionsList };
