import React from 'react';
import { DotLoading, Text } from 'new-ui';
import { getText, getTextArray } from '../../../../../i18n';

import toDecline from '../../../../bi/utils/toDecline';

import styles from './style.module.css';

const LABELS = {
  CONTINUE_SEARCH: getText('hotels:regionResult.progress.checkAnotherProviders'),
  HOTEL_LABELS: getTextArray('hotels:regionResult.progress.hotelDeclines'),
};

type HotelProgressProps = {
  count: number,
  loading: boolean,
};

const prepareLabel = (loading: boolean, foundText: string): string => (loading
  ? `${foundText}. ${LABELS.CONTINUE_SEARCH}`
  : foundText
);

const HotelProgress = ({ count, loading }: HotelProgressProps) => {
  const hotelText = toDecline(count, LABELS.HOTEL_LABELS);
  const label = prepareLabel(loading, hotelText);

  const text = `${label}`;
  const loadingHtml = loading && (
    <div className={ styles.loading }><DotLoading width={ '4px' } height={ '4px' } /></div>
  );

  return (
    <div className={ styles.wrapper }>
      <Text className={ styles.count } type='bold_24' color='gray'>
        { count.toString() }
      </Text>
      <div className={ styles.text }>
        <Text color='gray'>{ text }</Text>
        { loadingHtml }
      </div>
    </div>
  );
};

export { HotelProgress };
