import React from 'react';
import { MultiSelect } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { PaymentMethodTypes } from '../../../../../bi/types/trips';

import styles from './index.module.css';

const LABELS = {
  PLACEHOLDER: getText('trips:paymentMethod'),
  FILTERS: {
    CardPayment: getText('trips:personalPayment'),
    CorporateAccount: getText('trips:organizationAccount'),
  },
};

const getPaymentMethodsForSelect = (paymentMethodType: PaymentMethodTypes) => ({
  value: paymentMethodType,
  label: LABELS.FILTERS[paymentMethodType],
});

interface FilerStatusesProps {
  paymentMethods: PaymentMethodTypes[];
  values: string[];
  onChange(checkedStatuses: string[]): void;
  canPayWithPersonalFunds: boolean;
}

const FilterPaymentMethods = ({
  paymentMethods,
  values,
  onChange,
  canPayWithPersonalFunds = false,
}: FilerStatusesProps) => {
  if (!canPayWithPersonalFunds || !paymentMethods) return null;

  const paymentMethodsList = paymentMethods.map((serviceType: PaymentMethodTypes) => getPaymentMethodsForSelect(serviceType));

  return (
    <div className={ styles.wrapper }>
      <MultiSelect
        withLabel
        placeholder={ LABELS.PLACEHOLDER }
        list={ paymentMethodsList }
        values={ values }
        onChange={ (checkedStatuses: string[]) => onChange(checkedStatuses) }
      />
    </div>
  );
};

export { FilterPaymentMethods };
