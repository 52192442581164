import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Prompt, useParams } from 'react-router-dom';
import { BackLink, CircleLoading, IconButton, Input, Select, Text } from 'new-ui';

import { useServices } from '../../bi/context/services';

import { HeadSelect } from './components/HeadSelect';
import { BottomPanel } from './components/BottomPanel';
import { FieldLabel } from '../../components/FieldLabel';
import { ListEmployees } from './components/ListEmployees';

import { isSmartAgent } from '../../bi/utils/env';
import { MainAnalytic } from '../../bi/utils/analytics';

import { Dialogs, LABELS } from './consts';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { LoadingFields } from '../../bi/services/departments/types';
import { LoadingStatus } from '../../bi/services/utils/network/types';

import styles from './styles.module.css';

export const DepartmentPage = observer(() => {
  const {
    workspaceService,
    departmentsService: {
      getDepartment,
      clearForm,
      updateName,
      loadEmployees,
      setDefaultCompanyId,
      networkStore,
      store: {
        initialName,
        activeDialog,
        updateFormValue,
        form: {
          hasChanges,
          value: {
            Name,
            CompanyId: currentCID,
            Id: currentId,
          },
        },
      },
    },
  } = useServices(['Workspace', 'Employee', 'Notification', 'Departments']);
  const { id } = useParams() as Record<string, string>;

  const isNewDepartment = !id;

  const header = Name.trim().length ? Name : LABELS.HEADER;
  const companies = workspaceService.get().Companies as any[];

  const mappedCompanies = companies.map(({ CompanyId, CompanyName, ShortCompanyName }) =>
    ({ label: ShortCompanyName || CompanyName, value: CompanyId }));

  const matchCompany = mappedCompanies.find(i => i.value === currentCID);

  const disabledSelectCompany = companies.length === 1 || currentId !== null;

  useEffect(() => {
    if (isNewDepartment) {
      clearForm();
    } else {
      getDepartment(id).then();
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.DEPARTMENTS_OPEN_ITEM);
    }

    if (companies.length === 1) setDefaultCompanyId(companies[0].CompanyId);
  }, []);

  useEffect(() => {
    if (typeof currentCID === 'number') loadEmployees(currentCID);
  }, [currentCID]);

  const disabledContent = (
    <div className={ styles.disabled }>
      <Text className={ styles.text }>
        { matchCompany?.label || '' }
      </Text>
    </div>
  );

  const selectableContent = (
    <Select
      theme='default-border'
      items={ mappedCompanies }
      value={ currentCID as number }
      onChange={ updateFormValue('CompanyId') }
      excludeValue
    />
  );

  const updatingName = networkStore.getIsStatus(LoadingFields.updateName, LoadingStatus.LOADING);

  const quickSaveElement = initialName !== Name && (
    <>
      <IconButton iconType='checkMark' onClick={ updateName } />
      <IconButton iconType='closeButton' onClick={ () => updateFormValue('Name')(initialName) } />
    </>
  );

  const quickSaveOrLoaderElement = updatingName ? <CircleLoading /> : quickSaveElement;

  const renderQuickSaveName = !isNewDepartment ? quickSaveOrLoaderElement : null;

  const renderDepartmentSelect = disabledSelectCompany ? disabledContent : selectableContent;

  const CurrentDialog = useMemo(() => Dialogs[activeDialog], [activeDialog]);

  const Bottom = isNewDepartment ? BottomPanel : () => null;

  return (
    <div className={ styles.wrapper }>
      <Prompt
        when={ hasChanges }
        message={ LABELS.EXIT_MESSAGE }
      />
      <BackLink link={ ROUTES.SETTINGS.DEPARTMENTS } alternativeDesign={ isSmartAgent } />
      <Text type='bold_32' className={ styles.header }>
        { header }
      </Text>
      <div className={ styles.inputs }>
        <div className={ styles.selectable }>
          <FieldLabel mustHave text={ LABELS.DEPARTMENT_NAME } />
          <div className={ styles.inputName }>
            <Input
              value={ Name }
              onChange={ updateFormValue('Name') }
              qaAttr={ QA_ATTRIBUTES.settings.departments.create.name }
            />
            { renderQuickSaveName }
          </div>
        </div>
        <div className={ styles.selectable }>
          <FieldLabel
            mustHave={ !disabledSelectCompany }
            text={ LABELS.ORGANIZATION }
          />
          { renderDepartmentSelect }
        </div>
      </div>
      <div className={ styles.employees }>
        <HeadSelect />
        <ListEmployees />
      </div>
      <Bottom />
      <CurrentDialog />
    </div>
  );
});
