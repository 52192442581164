import React, { FC } from 'react';
import type { TextColor } from 'new-ui';
import { Text, Tooltip } from 'new-ui';

import { getText } from '../../../../i18n';

import { ApprovesType, MessagesType } from '../../../bi/services/expenseReports/types';

import { formatDate } from '../../../bi/utils/formatDate';

import { PATTERN } from '../../../bi/constants/dateFormats';
import { APPROVE_STATUS } from '../../../bi/constants/travelApproval';

import styles from '../styles/comments.module.css';

const LABELS = {
  WAITING_APPROVE: getText('components:travelApproval.statuses.waitingApprove'),
  APPROVE: getText('components:travelApproval.statuses.approve'),
  NOT_APPROVE: getText('components:travelApproval.statuses.notApprove'),
  COMMENTS: getText('components:travelApproval.comments'),
};

interface CommentsProps {
  approves: ApprovesType[];
  color: TextColor;
}

const Comments: FC<CommentsProps> = ({
  approves,
  color,
}) => {
  const approveHtml = ({ Resolution, Messages }: { Resolution: string, Messages: MessagesType[] }, i: number) => {
    const tooltipApprove = (email: string) => (
      <div className={ styles['tooltip-content'] }>
        <Text color='white' type='NORMAL_14_130'>{ email }</Text>
      </div>
    );

    const checkApproved = (resolution: string) => ({
      [APPROVE_STATUS.WAITING_APPROVE]: () => `- ${LABELS.WAITING_APPROVE}`,
      [APPROVE_STATUS.APPROVED]: () => `- ${LABELS.APPROVE}`,
      [APPROVE_STATUS.DECLINED]: () => `- ${LABELS.NOT_APPROVE}`,
    }[resolution]());

    const comment = (com: string) => (com ? (
      <>
        <div className={ styles.com }>
          <Text color={ color } type='SEMIBOLD_16'> { com } </Text>
        </div>
      </>
    ) : null);

    const content = (item: any) => {
      const { Name, Email, Comment, ShowResolution, Date } = item;

      const date = `${formatDate(Date, PATTERN.DATE_WITH_YEAR)} г.` || null;

      return (
        <div className={ styles.item } key={ `${Name}_${i}` }>
          <Text color={ color }>{ date }</Text>
          &nbsp;
          <Tooltip renderContent={ () => tooltipApprove(Email) }>
            <Text
              type='NORMAL_16_130'
              color={ color }
              className={ styles.name }
            >
              { Name }:
            </Text>
          </Tooltip>
          &nbsp;
          { comment(Comment) }
          &nbsp;
          <Text type='SEMIBOLD_16' color={ color }>
            { Resolution && ShowResolution && checkApproved(Resolution) }
          </Text>
        </div>
      );
    };

    return Messages.map(content);
  };

  const approveListHtml = !!approves.length && approves.map(approveHtml);

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_24' color={ color } className={ styles.header }>{LABELS.COMMENTS}:</Text>
      <div className={ styles['comments-list'] }>
        <div className={ styles.item } />
        { approveListHtml }
      </div>
    </div>
  );
};

export { Comments };
