// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Input, PROPS } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import styles from './styles.module.css';

const LABELS = {
  CODE_INPUT: getText('cart:codeInput'),
};

const CartActionsCodeInput = ({ verificationCode, verificationCodeValue, handleCheckCode }) => (
  <div className={ styles['verification-code'] }>
    { LABELS.CODE_INPUT }
    <Input
      className={ styles.input }
      value={ verificationCodeValue }
      onChange={ handleCheckCode }
    />
    { verificationCode.verificationCodeIsTrue && (
      <Icon
        className={ styles.check }
        type={ PROPS.ICON.TYPES.CHECK_MARK }
        color={ PROPS.ICON.COLORS.GREEN }
      />
    ) }
  </div>
);

CartActionsCodeInput.propTypes = {
  verificationCode: PropTypes.object.isRequired,
  verificationCodeValue: PropTypes.string.isRequired,
  handleCheckCode: PropTypes.func.isRequired,
};

export { CartActionsCodeInput };
