import React from 'react';
import {
  Text,
  StyledWrapper,
  Table,
} from 'new-ui';
import { getText } from '../../../i18n';
import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('offerSmartAgent:title'),
  SECTION: {
    ABOUTE: getText('offerSmartAgent:sections.about'),
    DESCRIPTION: getText('offerSmartAgent:sections.discription'),
    FIRST: {
      TITLE: getText('offerSmartAgent:sections.first.title'),
      PARAGRAPHS: [
        getText('offerSmartAgent:sections.first.first'),
        getText('offerSmartAgent:sections.first.second'),
        getText('offerSmartAgent:sections.first.third'),
        getText('offerSmartAgent:sections.first.fourth'),
        getText('offerSmartAgent:sections.first.fifth'),
        getText('offerSmartAgent:sections.first.sixth'),
        getText('offerSmartAgent:sections.first.seventh'),
        getText('offerSmartAgent:sections.first.eighth'),
      ],
    },
    SECOND: {
      TITLE: getText('offerSmartAgent:sections.second.title'),
      PARAGRAPHS: [
        getText('offerSmartAgent:sections.second.first'),
        getText('offerSmartAgent:sections.second.second'),
        getText('offerSmartAgent:sections.second.third'),
        getText('offerSmartAgent:sections.second.fourth'),
        getText('offerSmartAgent:sections.second.fifth'),
      ],
    },
    THIRD: {
      TITLE: getText('offerSmartAgent:sections.third.title'),
      PARAGRAPHS: [
        getText('offerSmartAgent:sections.third.first'),
        getText('offerSmartAgent:sections.third.second'),
        getText('offerSmartAgent:sections.third.third'),
        getText('offerSmartAgent:sections.third.fourth'),
        getText('offerSmartAgent:sections.third.fifth'),
        getText('offerSmartAgent:sections.third.sixth'),
        getText('offerSmartAgent:sections.third.seventh'),
        getText('offerSmartAgent:sections.third.eighth'),
        getText('offerSmartAgent:sections.third.ninth'),
      ],
    },
    FOURTH: {
      TITLE: getText('offerSmartAgent:sections.fourth.title'),
      TABLE: {
        HEAD_ROW: [
          getText('offerSmartAgent:sections.fourth.table.headRow.first'),
          getText('offerSmartAgent:sections.fourth.table.headRow.second'),
        ],
        FIRST_ROW: [
          getText('offerSmartAgent:sections.fourth.table.firstRow.first'),
          getText('offerSmartAgent:sections.fourth.table.firstRow.second'),
        ],
      },
    },
    FIFTH: {
      TITLE: getText('offerSmartAgent:sections.fifth.title'),
      PARAGRAPHS: [
        getText('offerSmartAgent:sections.fifth.paragraphs.first'),
        getText('offerSmartAgent:sections.fifth.paragraphs.second'),
        getText('offerSmartAgent:sections.fifth.paragraphs.third'),
        getText('offerSmartAgent:sections.fifth.paragraphs.fourth'),
        getText('offerSmartAgent:sections.fifth.paragraphs.fifth'),
      ],
    },
  },
};

const OfferSmartAgent = () => {
  const renderFirstParagraphs = () => (
    <div className={ styles.container }>
      <Text type='bold_18'>{ LABELS.SECTION.FIRST.TITLE }</Text>
      <ul className={ styles.paragraph }>
        { LABELS.SECTION.FIRST.PARAGRAPHS.map((paragraph, index) => (
          <li key={ index }>
            <Text type='NORMAL_18'>{ paragraph }</Text>
          </li>
        ))}
      </ul>
    </div>
  );

  const renderSecondParagraphs = () => (
    <div className={ styles.container }>
      <Text type='bold_18'>{ LABELS.SECTION.SECOND.TITLE }</Text>
      <ul className={ styles.paragraph }>
        { LABELS.SECTION.SECOND.PARAGRAPHS.map((paragraph, index) => (
          <li key={ index }>
            <Text type='NORMAL_18'>{ paragraph }</Text>
          </li>
        ))}
      </ul>
    </div>
  );

  const renderThirdParagraphs = () => (
    <div className={ styles.container }>
      <Text type='bold_18'>{ LABELS.SECTION.THIRD.TITLE }</Text>
      <ul className={ styles.paragraph }>
        { LABELS.SECTION.THIRD.PARAGRAPHS.map((paragraph, index) => (
          <li key={ index }>
            <Text type='NORMAL_18'>{ paragraph }</Text>
          </li>
        ))}
      </ul>
    </div>
  );

  const renderTableFirstRow = LABELS.SECTION.FOURTH.TABLE.FIRST_ROW.map((td, index) => (
    <td key={ index }>{ td }</td>
  ));

  const renderFifthParagraphs = LABELS.SECTION.FIFTH.PARAGRAPHS.map((p, index) => (
    <li key={ index } className={ styles.requisite }>
      <Text type='NORMAL_18'>
        { p }
      </Text>
    </li>),
  );

  return (
    <>
      <StyledWrapper className={ styles.wrapper }>
        <Text type='bold_22'>{ LABELS.TITLE }</Text>
        <Text className={ styles.section } type='NORMAL_18'>
          <Text className={ styles.section } type='NORMAL_18'>{ LABELS.SECTION.ABOUTE }</Text>
          <Text className={ styles.section } type='NORMAL_18'>{ LABELS.SECTION.DESCRIPTION }</Text>
          { renderFirstParagraphs() }
          { renderSecondParagraphs() }
          { renderThirdParagraphs() }
        </Text>
        <Text className={ styles.section }>
          <Text type='bold_18'>
            { LABELS.SECTION.FOURTH.TITLE }
          </Text>
          <div className={ styles.table }>
            <Table
              labelsHeader={ [...LABELS.SECTION.FOURTH.TABLE.HEAD_ROW] }
            >
              <tr>
                { renderTableFirstRow }
              </tr>
            </Table>
          </div>
        </Text>
        <Text className={ styles.section }>
          <Text type='bold_18'>
            { LABELS.SECTION.FIFTH.TITLE }
          </Text>
          <ul >
            { renderFifthParagraphs }
          </ul>
        </Text>
      </StyledWrapper>
    </>
  );
};

export default OfferSmartAgent;
