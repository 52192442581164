import React from 'react';
import { observer } from 'mobx-react';

import { Checkbox, Select, Text } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { RightsDialogWrap } from '../RightsDialogWrap';

import { STEPSRIGHTS, FIELDRIGHTS } from '../../../../../bi/constants/rights';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import styles from '../index.module.css';

const LABELS = {
  SUBTITLE: (email: string) => getText('settings:employees.employee.steps.rights.steps.notifications.subtitle', { email }),
  VOUCHERS: getText('settings:employees.employee.steps.rights.steps.notifications.vouchers'),
  INVOICES: getText('settings:employees.employee.steps.rights.steps.notifications.invoices'),
  CLOSE_DOCUMENTS: getText('settings:employees.employee.steps.rights.steps.notifications.closeDocuments'),
};

const { employee: { documents: { access: { modals } } } } = QA_ATTRIBUTES;

interface INotificationStep {
  showReports: boolean,
  email: string,
  active: string,
  header: string,
  rights: {
    documents: string,
    showSelectDocuments: boolean,
    loadingButton: boolean,
    canGetVouchers: boolean,
    canGetInvoices: boolean,
    sendDocuments: boolean,
  },
  documents: {
    label: string
    value: string,
  }[],
  onSave(): void,
  onChangeStep(value: string): void,
  onChangeRights(field: string, value: boolean): void,
  onChangeDocuments(value: string | number): void,
}

const NotificationStep = observer(({
  email,
  showReports,
  active,
  header,
  rights: {
    documents: docRights,
    showSelectDocuments,
    loadingButton,
    canGetVouchers,
    canGetInvoices,
    sendDocuments,
  },
  documents,
  onSave,
  onChangeStep,
  onChangeRights,
  onChangeDocuments,
}: INotificationStep) => {
  const handleSelectDocuments = (value: string | number, field: string) => {
    onChangeRights(field, true);
    onChangeDocuments(value);
  };

  const renderSelect = (field: string, qaAttr: string) => (
    <Select
      value={ docRights }
      theme='open-blue'
      items={ documents }
      onChange={ value => handleSelectDocuments(value, field) }
      className={ styles['selected-item'] }
      qaAttr={ qaAttr }
    />
  );

  const oneDocumentHtml =
    !showSelectDocuments && <Text color='gray'>&nbsp;{ documents[1].label }</Text>;

  const selectByVouchersHtml = !!showSelectDocuments && renderSelect(FIELDRIGHTS.CANGETVOUCHERS, modals.notification.vouchersSelect);
  const selectByInvoicesHtml = !!showSelectDocuments && renderSelect(FIELDRIGHTS.CANGETINVOICES, modals.notification.invoicesSelect);

  return (
    <RightsDialogWrap
      loading={ loadingButton }
      header={ header }
      theme={ active }
      onClickButton={ onSave }
      onClickCancel={ () => onChangeStep(STEPSRIGHTS.ADMIN) }
      qaAttrHeader={ modals.notification.title }
      qaAttrButton={ modals.buttonNext }
      qaAttrLink={ modals.notification.buttons.cancel }
    >
      <Text className={ styles.subtitle }>
        { LABELS.SUBTITLE(email) }
      </Text>
      <div className={ `${styles['multiple-item']} ${styles.item}` }>
        <Checkbox
          value={ canGetVouchers }
          onChange={ value => onChangeRights(FIELDRIGHTS.CANGETVOUCHERS, value) }
          qaAttr={ modals.notification.vouchers }
        >
          { LABELS.VOUCHERS }
        </Checkbox>
        { oneDocumentHtml }
        { selectByVouchersHtml }
      </div>
      { showReports && (
        <>
          <div className={ `${styles['multiple-item']} ${styles.item}` }>
            <Checkbox
              value={ canGetInvoices }
              onChange={ value => onChangeRights(FIELDRIGHTS.CANGETINVOICES, value) }
              qaAttr={ modals.notification.invoices }
            >
              { LABELS.INVOICES }
            </Checkbox>
            { oneDocumentHtml }
            { selectByInvoicesHtml }
          </div>
          <div className={ `${styles['multiple-item']} ${styles.item}` }>
            <Checkbox
              value={ sendDocuments }
              onChange={ value => onChangeRights(FIELDRIGHTS.SENDDOCUMENTS, value) }
              qaAttr={ modals.notification.closeDocuments }
            >
              { LABELS.CLOSE_DOCUMENTS }
            </Checkbox>
          </div>
        </>
      ) }
    </RightsDialogWrap>
  );
});

export { NotificationStep };
