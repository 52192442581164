import React from 'react';
import { DefaultBarChart, DoughnutChart, Table, Text } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { ChartType, ContentType } from '../../../../../../bi/services/chartsAnalytics/types';
import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import styles from './styles.module.css';

const PRICE = getText('chartsAnalytics:airline.charts.price');

interface TwoChartsProps {
  chart: ChartType,
  donutChart: ChartType,
  labelDoughnut: string,
  labelsHeader: string[],
  content: ContentType[],
  legend?: string,
  longFirstValue?: boolean,
  chartsAnalyticsService: ChartsAnalytics,
  redirectToSubPage(id?: number, index?: number): void,
}

const TwoCharts = ({
  chart,
  donutChart,
  labelDoughnut,
  labelsHeader,
  content,
  legend = '',
  chartsAnalyticsService,
  redirectToSubPage,
  longFirstValue = false,
}: TwoChartsProps) => {
  const { values: chartValues, labels: chartLabels, colors: chartColors } = chart;
  const { values: donutChartValues, labels: donutChartLabels, colors: donutChartColors } = donutChart;

  const handleOpenNestedTablePage = (subPageInfo: any, serviceType: string, isCanClick?: boolean, id?: number, index?: number) => {
    const { changeSubPageInfo, changeSummaryType } = chartsAnalyticsService;

    if (!isCanClick) {
      return;
    }

    changeSubPageInfo(subPageInfo);
    changeSummaryType(serviceType);
    redirectToSubPage(id, index);
  };

  const classNames = (data: boolean) => (data ? styles.cursor : '');

  const firstLabel = (<Text className={ styles.route } type='SEMIBOLD_14'>{ labelsHeader[0] }</Text>);
  const header = longFirstValue ? [firstLabel, ...labelsHeader.slice(1)] : labelsHeader;

  const mapperData = (value: string, indexData: number) => {
    if (indexData === 0) return null;

    return (
      <td key={ indexData }>{ value }</td>
    );
  };

  const firstDataHtml = (value: string) => (longFirstValue
    ? <div className={ `${styles.route_color} ${styles.route_wrap}` }>{ value }</div>
    : value
  );

  return (
    <>
      <div className={ styles['chart-wrapper'] }>
        <div className={ styles['doughnut-chart'] }>
          <Text className={ styles['chart-header'] } type='SEMIBOLD_14'>{ labelDoughnut }</Text>
          <DoughnutChart
            values={ donutChartValues }
            barLabels={ donutChartLabels }
            legend={ legend }
            colors={ donutChartColors }
            getCurrentBarValue={ ({ index }) => handleOpenNestedTablePage(content[index].subPageInfo, content[index].serviceType, true, content[index].id, index) }
          />
        </div>
        <div className={ styles['bar-chart'] }>
          <Text className={ styles['chart-header'] } type='SEMIBOLD_14'>{ PRICE }</Text>
          <div className={ styles['bar-chart-default'] }>
            <DefaultBarChart
              horizontal
              values={ chartValues }
              barLabels={ chartLabels }
              colors={ chartColors }
              getCurrentBarValue={ ({ index }) => handleOpenNestedTablePage(content[index].subPageInfo, content[index].serviceType, true, content[index].id, index) }
            />
          </div>
        </div>
      </div>
      <div className={ styles.table }>
        <Table
          // @ts-ignore
          labelsHeader={ header }
        >
          { content.map(({ data, id, subPageInfo, serviceType }, indexRow) => (
            <tr key={ indexRow } className={ classNames(!!data[1]) } onClick={ () => handleOpenNestedTablePage(subPageInfo, serviceType, !!data[1], id) }>
              <td>
                { firstDataHtml(data[0]) }
              </td>
              { data.map((value, indexData) => mapperData(value, indexData))}
            </tr>
          )) }
        </Table>
      </div>
    </>
  );
};

export { TwoCharts };
