import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';
import { Button } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import scrollToTop from '../../../../../utils/scrollToTop';

import ROUTES from '../../../../../../bi/constants/routes';

import { TaxiNestedTable } from '../../../common/taxiNestedTable';

import styles from './styles.module.css';

const LABELS = {
  EXCEL: getText('expenseReports:report.download.excel'),
};

const SmartCommonFourthTaxiPage = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => {
  const { chartsAnalyticsSmartSummaryStore } =
    useStores([MOBX_STORES.CHARTS_ANALYTICS_SMART_SUMMARY_STORE]);

  const {
    getSmartTaxiPageWithTrips,
    downloadSubSmartXls,
  } = chartsAnalyticsService;

  useEffect(() => {
    getSmartTaxiPageWithTrips();
  }, [getSmartTaxiPageWithTrips]);

  const { url } = useRouteMatch();

  const path = url === ROUTES.CHARTS_ANALYTICS.SMART.TAXI.THIRD ? ROUTES.CHARTS_ANALYTICS.TAXI.SMART_VOUCHER : ROUTES.CHARTS_ANALYTICS.TAXI.SMART_VOUCHER_COMMON;
  const handlePageChange = (page: string) => chartsAnalyticsService.changePageSmartTaxiPage(Number(page)).then(() => scrollToTop());

  const renderDownload = () => (
    <div className={ styles.header }>
      <div className={ styles.row }>
        <Button type='textual' onClick={ () => downloadSubSmartXls() }>
          { LABELS.EXCEL }
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div className={ styles.wrapper }>
        { renderDownload() }
      </div>
      <TaxiNestedTable
        changeVoucher={ chartsAnalyticsService.changeTaxiSmartVoucher }
        path={ path }
        typeStore={ chartsAnalyticsSmartSummaryStore }
        onPageChange={ handlePageChange }
        chartsAnalyticsService={ chartsAnalyticsService }
      />
    </>
  );
});

export { SmartCommonFourthTaxiPage };
