import { featureFlagsStore } from '../../../../bi/services/featureFlags/store';

import { KEYS } from '../../../../bi/constants/settings';
import { LABELS } from './consts';

const simpleItem = {
  title: LABELS.TITLE,
  id: KEYS.DOCUMENTS_GOS_SIGN,
};

export const configDocumentsGosSign = () => {
  if (!featureFlagsStore.getFeatureFlags.EnhancedUnqualifiedSignature) {
    return [];
  }

  return [simpleItem];
};
