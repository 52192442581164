import CONFIG from '../../../../config';

import { PAYMENT_INFO } from '../../types/paymentMethod';

const CURRENCY = 'RUB';
const SKIN = 'modern';

const widget = (paymentInfo: PAYMENT_INFO, paymentType = 'auth') => {
  // @ts-ignore
  const payment = new cp.CloudPayments({
    language: 'ru-RU',
  });

  payment.pay(paymentType,
    {
      publicId: CONFIG.CLOUDPAYMENTS.PUBLICK_KEY,
      currency: CURRENCY,
      skin: SKIN,
      ...paymentInfo,
    },
    {
      onSuccess: paymentInfo.onSuccess,
      onFail: paymentInfo.onFail,
    },
  );
};

export { widget };
