import { SmartwayCommonEnumsSigningStatus } from '@sw/api';

import { getText } from '../../../../../../i18n';

export const LABELS = {
  SHOW: getText('settings:documentsForSignature.table.show'),
  DOWNLOAD: getText('settings:documentsForSignature.table.download'),
  SEND_TO_EMAIL: getText('settings:documentsForSignature.sendToEmail'),
  SIGNING_STATUS: {
    [SmartwayCommonEnumsSigningStatus.Undefined]: getText('settings:documentsForSignatureEnhanced.signStatus.notSigned'),
    [SmartwayCommonEnumsSigningStatus.Signed]: getText('settings:documentsForSignatureEnhanced.signStatus.signed'),
    [SmartwayCommonEnumsSigningStatus.NotValid]: getText('settings:documentsForSignatureEnhanced.signStatus.notSigned'),
    [SmartwayCommonEnumsSigningStatus.Processing]: getText('settings:documentsForSignatureEnhanced.signStatus.processing'),
    [SmartwayCommonEnumsSigningStatus.EnhancedSigned]: getText('settings:documentsForSignatureEnhanced.signStatus.signed'),
    [SmartwayCommonEnumsSigningStatus.SignError]: getText('settings:documentsForSignatureEnhanced.signStatus.signError'),
  },
};
