import { Moment } from 'moment';
import { ParsedQuery } from 'query-string';
import Api from '../../api';
import { getText, getTextArray } from '../../../../i18n';
import debounce from '../../api/debounce';

import { Amplitude, MainAnalytic, MixPanel } from '../../utils/analytics';
import {
  formatDate,
  getMoment,
  isValidMomentObject,
  momentObject,
  nextDateAfterCurrent,
} from '../../utils/formatDate';
import { getAirlineType } from '../../utils/airline';

import FLYCLASS, { SORT_VALUE, CHANGE_AIR_TRIP_FIELDS } from '../../constants/airline';
import { TravelApprovalType } from '../travelApproval/consts';

import ACTION from './action';
import HistoryStore from './store/history';
import AirlineStore from './store/airline';
import AirlineSearchStore, { RouteField } from './store/search';
import toDecline from '../../utils/toDecline';
import parseUnix from '../../utils/parseDateTime';

import { ResponseType } from '../../utils/airlineMultisearch';

import {
  AirlineCacheItem,
  AirSearchRoute,
  IAirSearchRouteItemMin,
  IDetail,
  AirSearchSettings,
  AirTag,
  AirRoute,
  IAirSearchRouteItem,
  ISearchAirItem,
  IResultSearch,
  AirlineSource,
  IChildData,
  IAirSearchValue,
} from '../../types/airline';
import { IMappedItems } from './types';
import { IRespRegionByAirport } from '../hotels/types';
import { TravelPolicyItem } from '../userSession/types';

const LABELS = {
  SORT_RECOMMENDED: getText('services:airline.sort.byRecommended'),
  SORT_BY_PRICE: getText('services:airline.sort.byPrice'),
  SORT_BY_DURATION: getText('services:airline.sort.byDuration'),
  SORT_BY_DEPARTURE_TIME: getText('services:airline.sort.byDepartureTime'),
  SORT_BY_TRANSFER_TIME: getText('services:airline.sort.byTransferTime'),
  WITHOUT_TRANSFERS: getText('services:airline.withoutTransfers'),
  TRANSFERS_DECLINES: getTextArray('services:airline.transferDeclines'),
  FILTER_TRAVEL_POLICY_NOT_APPLIED: getText('components:filterTravelPolicy.notApplied'),
};

interface ShortAirRoute extends Pick<AirRoute, 'Departure' | 'Arrival' | 'Date'> {}

const DEBOUNCE_TIME = 200;
const SHORTDATEFORMAT = 'YYYY-MM-DD';

const classOptions = (Object.keys(FLYCLASS) as Array<keyof typeof FLYCLASS>).map(flyClass => ({ value: flyClass, label: FLYCLASS[flyClass] }));

const ACTIONS = {
  FILTERSBAGGAGE: 'filters.baggage',
  FILTERSREFUNDABLE: 'filters.refundable',
  FILTERSDIRECTCOUNT: 'filters.direct.count',
  FILTERSAIRPORT: 'filters.airport',
  FILTERSFEWAIRLINECOMPANIES: 'filters.fewAirlineCompanies',
  FILTERSAIRLINE: 'filters.airline',
  FILTERSTP: 'filters.travelPolicy',
  FILTERSPRICE: 'filters.price',
  FILTERSTIME: 'filters.border.time',
  FILTER_TRAVEL_TIME: 'filters.travel.time',
  FILTERSDURATIONTIME: 'filters.duration.time',
  SORTING: 'sort',
  FLIP: 'flip',
  COMPLEX: 'complex',
  NOTTP: 'NotApplied',
};

const SEARCH_TYPE = {
  ONEWAY: 0,
  WITHBACK: 1,
  COMPLEX: 2,
};

const makeLabelForAnalytics = ({ Routes }: AirlineCacheItem) => {
  const labelTo = Routes[0].Segments.map((segment) => {
    const airlineType = getAirlineType(segment);

    return `${segment[airlineType].ID} ${segment.FlightNumber}`;
  }).join(', ');

  if (Routes.length === 1) return labelTo;

  const labelFrom = Routes[1].Segments.map((segment) => {
    const airlineType = getAirlineType(segment);

    return `${segment[airlineType].ID} ${segment.FlightNumber}`;
  }).join(', ');

  return `${labelTo} - ${labelFrom}`;
};

const mapSingleRoute = ({
  from: {
    selected: fromSelected,
    label: fromLabel,
  },
  to: {
    selected: toSelected,
    label: toLabel,
  },
  date,
}: AirSearchRoute) => {
  const { Code: fromCode } = fromSelected as IAirSearchRouteItemMin;
  const { Code: toCode } = toSelected as IAirSearchRouteItemMin;

  return {
    DepartureCode: fromCode,
    DepartureName: fromLabel,
    ArrivalCode: toCode,
    ArrivalName: toLabel,
    Date: date.format(SHORTDATEFORMAT),
  };
};

const mapSearchParams = (params: ParsedQuery = {}) => {
  const routes = [{
    DepartureCode: params.sp_code,
    DepartureName: params.sp_name || params.sp_code,
    ArrivalCode: params.ep_code,
    ArrivalName: params.ep_name || params.ep_code,
    Date: params.date_from,
  }];

  if (params.date_to) {
    routes.push({
      DepartureCode: params.sp_code_back,
      DepartureName: params.sp_name_back || params.sp_code_back,
      ArrivalCode: params.ep_code_back,
      ArrivalName: params.ep_name_back || params.ep_code_back,
      Date: params.date_to,
    });
  }

  return {
    Class: params.t_class,
    IsDirect: params.direct === 'true',
    TravellersCount: Number(params.t_count),
    Routes: routes,
  };
};
// заменить тут any на тип когда requestsService будет типизирован
const mapSearchParamsForRequest = (
  requestItem: any = {},
) => {
  const { SearchOptions } = requestItem;

  const routes = SearchOptions.Routes.map(({ ArrivalAirport, DepartureAirport, DepartureDate }: any) => ({
    ArrivalCode: ArrivalAirport.Code,
    ArrivalName: `${ArrivalAirport.City} (${ArrivalAirport.Code})`,
    Date: formatDate(DepartureDate, SHORTDATEFORMAT),
    DepartureCode: DepartureAirport.Code,
    DepartureName: `${DepartureAirport.City} (${DepartureAirport.Code})`,
  }));

  return {
    Class: SearchOptions.FareClass,
    IsDirect: SearchOptions.IsDirect,
    TravellersCount: requestItem.EmployeesNames.length,
    Routes: routes,
  };
};

const getRoutesList = (
  routes: AirSearchRoute[] = [],
  isComplex: boolean = false,
) => {
  if (isComplex) {
    return routes.map(mapSingleRoute);
  }

  const route = routes[0];

  if (!route.dateBack) {
    return [mapSingleRoute(route)];
  }

  return [mapSingleRoute(route), mapSingleRoute({
    ...route,
    to: route.from,
    from: route.to,
    date: route.dateBack as Moment,
  })];
};

const getRouteType = (airplane: IDetail) => {
  if (airplane.Routes.length === 1) {
    return SEARCH_TYPE.ONEWAY;
  }

  if (airplane.Routes.length === 2) {
    const first = airplane.Routes[0];
    const second = airplane.Routes[1];

    if (first.ArrivalCode === second.DepartureCode && first.DepartureCode === second.ArrivalCode) {
      return SEARCH_TYPE.WITHBACK;
    }
  }

  return SEARCH_TYPE.COMPLEX;
};

class Airline {
  api: Api['airline'];
  xhr: any;
  xhrAutocomplete: any;
  historyStore = HistoryStore();
  airlineStore = AirlineStore();
  airlineSearchStore = AirlineSearchStore();
  debounceAutocomplete: ReturnType<typeof debounce>;
  autocompleteLoadingTimer: ReturnType<typeof setTimeout>;

  travellersOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
  ];

  classOptions = classOptions;

  sort = [
    { label: LABELS.SORT_RECOMMENDED, value: SORT_VALUE.RECOMMENDED, type: 1 },
    { label: LABELS.SORT_BY_PRICE, value: SORT_VALUE.PRICE_DOWN, type: 1 },
    { label: LABELS.SORT_BY_DURATION, value: SORT_VALUE.DISTANCE_DOWN, type: 1 },
    { label: LABELS.SORT_BY_DEPARTURE_TIME, value: SORT_VALUE.DEPARTURE_TIME_DOWN, type: 1 },
    { label: LABELS.SORT_BY_TRANSFER_TIME, value: SORT_VALUE.TRANSFER_TIME_DOWN, type: 1 },
  ];

  sortChangeAirTrip = [
    { label: LABELS.SORT_BY_DURATION, value: SORT_VALUE.DISTANCE_DOWN, type: 1 },
    { label: LABELS.SORT_BY_DEPARTURE_TIME, value: SORT_VALUE.DEPARTURE_TIME_DOWN, type: 1 },
    { label: LABELS.SORT_BY_TRANSFER_TIME, value: SORT_VALUE.TRANSFER_TIME_DOWN, type: 1 },
  ];

  directCountString = (value: number | string) => (value === 0 || value === '0'
    ? LABELS.WITHOUT_TRANSFERS
    : `${value === '2' ? '2+' : value} ${toDecline(value as number, LABELS.TRANSFERS_DECLINES)}`);

  constructor(api: Api) {
    this.api = api.airline;
    this.xhr = null;
    this.historyStore = HistoryStore();
    this.airlineStore = AirlineStore();
    this.airlineSearchStore = AirlineSearchStore();

    this.debounceAutocomplete = debounce(this.api.autocomplete, DEBOUNCE_TIME);
  }

  getSearchStore = () => this.airlineSearchStore.getState();

  search = (settings: AirSearchSettings) => {
    const { isValid, isComplex, isDirect, travellers, travellersSA, flightClass, routes } = this.getSearch();

    if (!isValid) {
      return null;
    }

    const routesList = getRoutesList(routes, isComplex);

    const params = {
      Class: flightClass,
      IsDirect: isDirect,
      Travellers: travellersSA,
      TravellersCount: travellers,
      Routes: routesList,
    };

    const mixPanelData = {
      type: 'air',
      people: travellers,
      class: flightClass,
      isComplex,
      directOnly: isDirect,
      origin: routesList[0].DepartureName,
      destination: routesList[0].ArrivalName,
      startDate: routesList[0].Date,
      endDate: routesList.length > 1 ? routesList[routesList.length - 1].Date : '',
      flights: routesList.length,
    };

    if (isDirect) {
      MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FILTERADVANCE, {
        label: MainAnalytic.LABELS.AIRLINE.WITHOUTTRANSPLANT,
      });
    }

    if (this.xhr) {
      this.xhr.abort();
    }

    this.xhr = this.api.search(params);

    return this.xhr
      .then((response: ResponseType) => {
        Amplitude.pushEvent(Amplitude.TYPE.AIRLINE.SEARCH, response);
        MixPanel.track('Search', mixPanelData);

        if (!response.Itineraries.length) {
          let label = `${routesList[0].DepartureCode}-${routesList[0].ArrivalCode} ${MainAnalytic.LABELS.AIRLINE[flightClass]}`;

          if (isComplex) {
            label = `${routesList.map(item => `${item.DepartureCode}-${item.ArrivalCode}`).join(';')} ${MainAnalytic.LABELS.AIRLINE[flightClass]}`;
          } else if (routesList.length > 1) {
            label = `${routesList[0].DepartureCode}-${routesList[0].ArrivalCode}-${routesList[1].ArrivalCode} ${MainAnalytic.LABELS.AIRLINE[flightClass]}`;
          }

          MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.NORESULT, {
            label,
            value: travellers,
          });
        }

        this.airlineStore.dispatch({
          type: ACTION.LOADAIRLINETICKET,
          payload: {
            list: response,
            isDirect,
            settings,
          },
        });

        if (isDirect) {
          const checkbox = 0;
          this.airlineStore.dispatch({
            type: ACTION.UPDATEDIRECTFILTER,
            payload: { value: isDirect, checkbox },
          });
        }

        return response.SearchId;
      });
  };

  searchSetRoutes = (routes: AirSearchRoute[]) => {
    this.airlineStore.dispatch({
      type: ACTION.SET_ROUTES,
      payload: routes,
    });
  };

  searchAirTripChange = (
    airlineCode: string,
    settings: AirSearchSettings,
    fareName: string,
  ) => {
    const { isValid, isComplex, isDirect, travellers, flightClass, routes } = this.getSearch();

    if (!isValid) {
      return null;
    }

    const routesList = getRoutesList(routes, isComplex);

    const params = {
      Class: flightClass,
      IsDirect: isDirect,
      TravellersCount: travellers,
      Routes: routesList,
      Fare: fareName,
      AirlineCode: airlineCode,
    };

    return this.api.search(params)
      .then((response: ResponseType) => {
        this.airlineStore.dispatch({
          type: ACTION.LOADAIRLINETICKET,
          payload: {
            list: response,
            isDirect,
            settings,
          },
        });

        if (isDirect) {
          const checkbox = 0;
          this.airlineStore.dispatch({
            type: ACTION.UPDATEDIRECTFILTER,
            payload: { value: isDirect, checkbox },
          });
        }

        return response.SearchId;
      });
  };

  searchByGuid = (
    guid: string,
    settings: AirSearchSettings,
  ) => this.api.getSearchByGuid(guid)
    .then((response: ResponseType) => {
      if (!response.Itineraries || !response.Itineraries.length) {
        return;
      }

      Amplitude.pushEvent(Amplitude.TYPE.AIRLINE.SEARCH, response);

      const { FromHistory, ...searchOptions } = response.SearchOptions;

      this.airlineSearchStore.dispatch({
        type: ACTION.INITHISTORY,
        payload: {
          ...searchOptions,
          ...response.TemperatureScale,
        },
      });

      this.airlineStore.dispatch({
        type: ACTION.LOADAIRLINETICKET,
        payload: {
          list: response,
          settings,
        },
      });
    });

  searchByHistory = (params: IDetail, settings: AirSearchSettings) => {
    const isDirect = params.IsDirect;

    this.airlineSearchStore.dispatch({
      type: ACTION.INITHISTORY,
      payload: params,
    });

    return this.api.search(params)
      .then((response: ResponseType) => {
        Amplitude.pushEvent(Amplitude.TYPE.AIRLINE.SEARCH, response);

        const type = getRouteType(params);
        let action = MainAnalytic.ACTIONS.AIRLINE.SEARCHBYHISTORYONEWAY;

        if (type === SEARCH_TYPE.COMPLEX) {
          action = MainAnalytic.ACTIONS.AIRLINE.SEARCHBYHISTORYCOMPLEXROUTE;
        } else if (type === SEARCH_TYPE.WITHBACK) {
          action = MainAnalytic.ACTIONS.AIRLINE.SEARCHBYHISTORYTHEREAGAIN;
        }

        MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, action, {
          label: MainAnalytic.LABELS.AIRLINE[params.Class],
          value: params.TravellersCount,
        });
        this.airlineStore.dispatch({
          type: ACTION.LOADAIRLINETICKET,
          payload: {
            list: response,
            isDirect,
            settings,
          },
        });

        if (isDirect) {
          const checkbox = 0;
          this.airlineStore.dispatch({
            type: ACTION.UPDATEDIRECTFILTER,
            payload: { value: isDirect, checkbox },
          });
        }

        return response.SearchId;
      });
  };

  searchByParams = (params: ParsedQuery, settings: AirSearchSettings) => {
    this.setSkeletonSearch(true);
    const searchParams = mapSearchParams(params);
    const { f_number, date_from, date_to, airline_from, airline_to } = params;
    const flightNumber = f_number ? (f_number as string).split(';') : [];

    this.airlineSearchStore.dispatch({
      type: ACTION.INITHISTORY,
      payload: searchParams,
    });

    return this.api.search(searchParams)
      .then((response: ResponseType) => {
        this.airlineStore.dispatch({
          type: ACTION.LOADAIRLINETICKET,
          payload: {
            list: response,
            isDirect: searchParams.IsDirect,
            settings,
          },
          filters: {
            flightNumber,
          },
          favorite: {
            numbers: flightNumber,
            dates: {
              from: date_from,
              to: date_to ?? '',
            },
            airline: {
              from: airline_from ?? '',
              to: airline_to ?? '',
            },
          },
        });

        return response.SearchId;
      });
  };
  // заменить any на тип когда requestsService будет типизирован
  searchFromRequest = (requestItem: any, settings: AirSearchSettings) => {
    const searchParams = mapSearchParamsForRequest(requestItem);

    return this.api.search(searchParams)
      .then((response: ResponseType) => {
        this.airlineSearchStore.dispatch({
          type: ACTION.INITHISTORY,
          payload: searchParams,
        });
        this.airlineStore.dispatch({
          type: ACTION.LOADAIRLINETICKET,
          payload: {
            list: response,
            isDirect: searchParams.IsDirect,
            searchRoutes: searchParams.Routes,
            settings,
          },
        });

        return response.SearchId;
      });
  };

  setSearchValue = (
    field: string,
    value: IChildData | number | string | boolean | IAirSearchValue,
  ) => {
    this.airlineSearchStore.dispatch({
      type: `search.${field}`,
      payload: value,
    });

    if (field === ACTIONS.FLIP) {
      MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.CHANGEDIRECTION);
    } else if (field === ACTIONS.COMPLEX) {
      MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.CHANGESEARCHCOMPLEXROUTE);
    }
  };

  setDefaultSortValue = () => this.setResultValue(CHANGE_AIR_TRIP_FIELDS.SEARCH_SORT, SORT_VALUE.DISTANCE_DOWN);

  setResultValue = (
    field: string,
    // заменить any когда AirlineResultPage будет типизирован
    value: any,
  ) => {
    const cacheFilterTags = this.airlineStore.getState().tags;

    this.airlineStore.dispatch({
      type: `result.${field}`,
      payload: value,
    });

    switch (field) {
      case ACTIONS.FILTERSBAGGAGE:
      case ACTIONS.FILTERSDIRECTCOUNT:
      case ACTIONS.FILTERSREFUNDABLE: {
        if (value.value) {
          MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FILTERCHECKBOX, {
            label: MainAnalytic.LABELS.AIRLINE[value.checkbox],
          });
        }

        break;
      }

      case ACTIONS.FILTERSFEWAIRLINECOMPANIES: {
        if (value.value) {
          MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FILTERCHECKBOX, {
            label: MainAnalytic.LABELS.AIRLINE[field],
          });
        }

        break;
      }

      case ACTIONS.SORTING: {
        MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.SORTING, {
          label: MainAnalytic.LABELS.AIRLINE[value],
        });

        break;
      }

      case ACTIONS.FILTERSAIRPORT: {
        if (value.value) {
          const state = this.airlineStore.getState();
          const airports = state.filters.airports;
          const airportsIndex = airports[value.index];

          if (airportsIndex) {
            const label = airportsIndex[value.field][value.checkbox];

            if (label) {
              MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FILTERCHECKBOX, { label: label.name });
            }
          }
        }

        break;
      }

      case ACTIONS.FILTERSAIRLINE: {
        if (value.value) {
          const state = this.airlineStore.getState();
          const label = state.filters.airlines[value.checkbox];

          if (label) {
            MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FILTERCHECKBOX, {
              label: label.name,
            });
          }
        }

        break;
      }

      case ACTIONS.FILTERSTP: {
        const tp = this.airlineStore.getState().travelPolicyAllList;

        if (tp) {
          const selectedTp = tp.find((item: TravelPolicyItem) => item.Id === value.value);

          if (selectedTp) {
            MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FILTERSWITCH, {
              label: selectedTp.Name,
            });
          }

          if (value.value === ACTIONS.NOTTP) {
            MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FILTERSWITCH, {
              label: LABELS.FILTER_TRAVEL_POLICY_NOT_APPLIED,
            });
          }
        }

        break;
      }

      case ACTIONS.FILTERSPRICE: {
        const sliderOldTag = cacheFilterTags.find((item: AirTag) => item.key === 'price');

        if (!sliderOldTag) {
          const sliderNewTag = this.airlineStore.getState().tags.find((item: AirTag) => item.key === 'price');

          if (sliderNewTag) {
            MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FILTERSLIDER, {
              label: sliderNewTag.name,
            });
          }
        }

        break;
      }

      case ACTIONS.FILTERSDURATIONTIME: {
        const sliderOldTag = cacheFilterTags.find((item: AirTag) => item.key === 'directTime');

        if (!sliderOldTag) {
          const sliderNewTag = this.airlineStore.getState().tags.find((item: AirTag) => item.key === 'directTime');

          if (sliderNewTag) {
            MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FILTERSLIDER, {
              label: sliderNewTag.name,
            });
          }
        }

        break;
      }

      case ACTIONS.FILTERSTIME: {
        const key = `${value.field}_${value.ind}`;
        const sliderOldTag = cacheFilterTags.find((item: AirTag) => item.key === key);

        if (!sliderOldTag) {
          const sliderNewTag = this.airlineStore.getState().tags.find((item: AirTag) => item.key === key);

          if (sliderNewTag) {
            MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FILTERSLIDER, {
              label: sliderNewTag.name,
            });
          }
        }

        break;
      }

      case ACTIONS.FILTER_TRAVEL_TIME: {
        const key = `${value.field}_${value.ind}`;
        const sliderOldTag = cacheFilterTags.find((item: AirTag) => item.key === key);

        if (!sliderOldTag) {
          const sliderNewTag = this.airlineStore.getState().tags.find((item: AirTag) => item.key === key);

          if (sliderNewTag) {
            MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.FILTERSLIDER, {
              label: sliderNewTag.name,
            });
          }
        }

        break;
      }
    }
  };
  // заменить any когда AirlineResultPage будет типизирован
  setFlightsNumbersValue = (value: any) => this.setResultValue('filters.flightsNumbers', value);

  setResultCheckbox = (
    value: boolean,
    route: AirRoute,
  ) => this.airlineStore.dispatch({
    type: ACTION.UPDATEROUTESFILTER,
    payload: {
      value,
      route,
    },
  });

  setNewSearch = () => {
    this.airlineSearchStore.dispatch({
      type: ACTION.NEWSEARCH,
    });
    this.airlineStore.dispatch({
      type: ACTION.CLEARSEARCH,
    });
  };

  autocomplete = (
    field: RouteField,
    key: number,
    query: string,
  ) => {
    this.airlineSearchStore.dispatch({
      type: ACTION.UPDATE_SUGGESTS_LOADING,
      payload: {
        loading: true,
        key,
        field,
      },
    });

    if (this.xhrAutocomplete) this.xhrAutocomplete.abort();

    if (this.autocompleteLoadingTimer) clearTimeout(this.autocompleteLoadingTimer);

    this.xhrAutocomplete = this.debounceAutocomplete({ query: query.trim() });

    this.autocompleteLoadingTimer = setTimeout(() => {
      this.airlineSearchStore.dispatch({
        type: ACTION.UPDATESUGGESTS,
        payload: {
          list: [],
          loading: true,
          key,
          field,
          query,
        },
      });
    }, 1000);

    return this.xhrAutocomplete.then((res: IAirSearchRouteItem[]) => {
      clearTimeout(this.autocompleteLoadingTimer);
      this.airlineSearchStore.dispatch({
        type: ACTION.UPDATESUGGESTS,
        payload: {
          list: res,
          loading: false,
          key,
          field,
          query,
        },
      });
    });
  };

  setSearchAutocomplete = (travelApproval: TravelApprovalType) =>
    this.api.autocomplete({ query: travelApproval.Destinations[0]?.Name?.trim() });

  setSchemeLoading = (value: boolean) => this.airlineSearchStore.dispatch({
    type: ACTION.SCHEME_LOADING,
    value,
  });

  setClearSearch = () =>
    this.airlineStore.dispatch({
      type: ACTION.CLEARSEARCH,
    });

  setSearchFromApproveRequest = async (travelApproval: TravelApprovalType) => {
    const { Destinations, StartDate: startDate, EndDate: endDate } = travelApproval;
    this.setSchemeLoading(true);

    try {
      const res = await this.setSearchAutocomplete(travelApproval);
      const minDate = isValidMomentObject(startDate) ? nextDateAfterCurrent(startDate).startOf('d') : getMoment();
      const StartDate = isValidMomentObject(startDate) ? nextDateAfterCurrent(startDate).startOf('d') : null;
      const EndDate = isValidMomentObject(endDate) ? momentObject(endDate) : null;

      const item = {
        ...travelApproval,
        StartDate,
        EndDate,
        minDate,
        Destinations: Destinations[0]?.Name?.trim() ? res[0] : null,
      };

      this.airlineSearchStore.dispatch({
        type: ACTION.NEW_SEARCH_FROM_APPROVE_REQUEST,
        item,
      });
    } catch (e) {
      this.airlineSearchStore.dispatch({
        type: ACTION.NEW_SEARCH_FROM_APPROVE_REQUEST,
        item: null,
      });
    }

    this.setClearSearch();
    this.setSchemeLoading(false);
  };

  clearSuggests = (field: RouteField, key: number) => this.airlineSearchStore.dispatch({
    type: ACTION.CLEARSUGGEST,
    payload: {
      field,
      key,
    },
  });

  addToCart = (items: IMappedItems[]) => {
    const { sourceId } = this.getResult();

    const mappedItems = items.map(({ tripId, tripIdForOrder, fareId, requestItemId, customFareId = null }) => ({
      SearchId: sourceId,
      TripId: tripId, // Это скорее searchId, а не tripId. Почему так? Понятия не имею...
      FareId: fareId,
      TripIdForOrder: tripIdForOrder, // Настоящий tripId
      RequestItemId: requestItemId,
      CustomFareId: customFareId,
    }));

    return this.api.addToCart(mappedItems)
      .then((res: any) => {
        Amplitude.pushEvent(Amplitude.TYPE.AIRLINE.ADDTOCART, res);

        return res;
      });
  };

  findRegionByAirport = (id: number | null) => this.api.getRegionByAirportId(id).then((res: IRespRegionByAirport) => res, () => null);

  addToNote = (items: IMappedItems[]) => {
    const { sourceId } = this.getResult();
    const mappedItems = items.map(({ tripId, tripIdForOrder, fareId, customFareId = null }) => ({
      SearchId: sourceId,
      TripId: tripId, // Это скорее searchId, а не tripId. Почему так? Понятия не имею...
      FareId: fareId,
      TripIdForOrder: tripIdForOrder, // Настоящий tripId
      CustomFareId: customFareId,
    }));

    return this.api.addToNote(mappedItems)
    // тут в респонсе null, как и выше
      .then((res: any) => {
        Amplitude.pushEvent(Amplitude.TYPE.AIRLINE.ADDTONOTEPAD, res);
        MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.NOTE.AVIA_SEARCH_TO_NOTE);

        return res;
      });
  };

  addToFavorite = (trip: AirlineSource) => {
    const { isDirect, flightClass, routes } = this.getSearch();

    const firstRoute = routes[0];
    const mapRoutes: ShortAirRoute[] = [];
    mapRoutes.push({
      Departure: {
        Code: firstRoute.from.selected.Code,
        Name: firstRoute.from.selected.City,
      },
      Arrival: {
        Code: firstRoute.to.selected.Code,
        Name: firstRoute.to.selected.City,
      },
      Date: firstRoute.date,
    });

    if (firstRoute.dateBack) {
      mapRoutes.push({
        Departure: {
          Code: firstRoute.to.selected.Code,
          Name: firstRoute.to.selected.City,
        },
        Arrival: {
          Code: firstRoute.from.selected.Code,
          Name: firstRoute.from.selected.City,
        },
        Date: firstRoute.dateBack,
      });
    }

    const params = {
      Id: trip.Id,
      Duration: trip.Duration,
      LastTripDate: trip.LastTripDate,
      Class: flightClass,
      IsDirect: isDirect,
      Routes: trip.Routes.map((route, index) => ({
        Duration: route.Duration,
        Segments: route.Segments,
        ...mapRoutes[index],
      })),
    };

    MainAnalytic.send(MainAnalytic.CATEGORY.AIRLINE, MainAnalytic.ACTIONS.AIRLINE.ADDTOFAVORITES, {
      label: makeLabelForAnalytics(trip),
    });

    return this.api.addToFavorite(params);
  };

  getHistory = () => this.api.getSearchHistory()
    .then((res: IDetail[]) => {
      this.historyStore.dispatch({
        type: ACTION.LOADHISTORY,
        payload: res,
      });
    });

  getSearch = () => this.airlineSearchStore.getState();

  getResult = () => this.airlineStore.getState();

  getSearchObject = (
    item: ISearchAirItem,
    {
      dateFrom,
      dateTo,
      travelers,
      withNumber = false,
    }: {
      dateFrom: Moment,
      dateTo: Moment,
      travelers: number,
      withNumber?: boolean,
    }) => {
    const firstRoute = item.Routes[0];
    const secondRoute = item.Routes.length > 1 ? item.Routes[1] : null;
    const lastSegment = (route: AirRoute) => route.Segments[route.Segments.length - 1];
    const airlineType = (route: AirRoute) => getAirlineType(lastSegment(route));

    const result: IResultSearch = {
      date_from: dateFrom.utcOffset('+3:00').format(SHORTDATEFORMAT),
      t_count: travelers,
      t_class: item.Class,
      direct: item.IsDirect,
      sp_code: firstRoute.Departure.Code,
      sp_name: firstRoute.Departure.Name,
      ep_code: firstRoute.Arrival.Code,
      ep_name: firstRoute.Arrival.Name,
      airline_from: lastSegment(firstRoute)[airlineType(firstRoute)].ID,
    };

    if (secondRoute) {
      result.date_to = dateTo.utcOffset('+3:00').format(SHORTDATEFORMAT);
      result.sp_code_back = secondRoute.Departure.Code;
      result.sp_name_back = secondRoute.Departure.Name;
      result.ep_code_back = secondRoute.Arrival.Code;
      result.ep_name_back = secondRoute.Arrival.Name;
      result.airline_to = lastSegment(secondRoute)[airlineType(secondRoute)].ID;
    }

    const number: { [key: string]: boolean } = {};
    item.Routes.forEach((route: AirRoute) => route.Segments.forEach((segment) => {
      if (!number[segment.FlightNumber]) {
        number[segment.FlightNumber] = true;
      }
    }));

    if (withNumber) {
      result.f_number = Object.keys(number).join(';');
    }

    return result;
  };

  prepareSearchData = (itemData: AirlineSource) => {
    const firstRoute = itemData.Routes[0];
    const secondRoute = itemData.Routes.length > 1 ? itemData.Routes[1] : null;

    const dateFrom = parseUnix(firstRoute.Segments[0].DepartureTime_DateTime);
    const dateTo = secondRoute ? parseUnix(secondRoute.Segments[0].DepartureTime_DateTime) : null;
    const Class = itemData.Metadata.Class;
    const Routes = itemData.Routes.map((route: AirRoute) => {
      const lastSegment = route.Segments[route.Segments.length - 1];
      const mappedRoute = {
        ...route,
        Departure: {
          Code: route.Segments[0].DepartureCityCode,
          Name: `${route.Segments[0].DepartureCity} (${route.Segments[0].DepartureCityCode})`,
        },
        Arrival: {
          Code: lastSegment.ArrivalCityCode,
          Name: `${lastSegment.ArrivalCity} (${lastSegment.ArrivalCityCode})`,
        },
      };

      return mappedRoute;
    });

    const mappedItem = {
      Class,
      Routes,
    } as ISearchAirItem;
    const data = {
      travelers: itemData.Metadata.TravellersCount,
      dateFrom,
      dateTo,
    } as unknown as {
      dateFrom: Moment,
      dateTo: Moment,
      travelers: number,
      withNumber?: boolean,
    };

    return { mappedItem, data };
  };

  addSearchRoute = () => this.airlineSearchStore.dispatch({
    type: ACTION.ADDSEARCHROUTE,
  });

  removeSearchRoute = (routeIndex: number) => this.airlineSearchStore.dispatch({
    type: ACTION.REMOVESEARCHROUTE,
    payload: routeIndex,
  });

  deleteTags = (tags: AirTag) => this.airlineStore.dispatch({
    type: ACTION.DELETETAGS,
    payload: tags,
  });

  resetFilters = () => this.airlineStore.dispatch({
    type: ACTION.RESETFILTERS,
  });

  resetSearchStore = () => this.setNewSearch();

  changeFavoriteStatus = (
    id: string | number,
    originalId: string | number,
    favoriteId: string,
  ) => this.airlineStore.dispatch({
    type: ACTION.CHANGEFAVORITESTATE,
    payload: {
      id,
      originalId,
      favoriteId,
    },
  });

  getWarningTemplate = () => this.api.getWarningTemplate()
    // ответ null
    .then((res: any) => {
      this.airlineSearchStore.dispatch({
        type: ACTION.WARNINGTEMPLATE,
        payload: res,
      });
    });

  setSkeletonSearch = (value: boolean) => this.airlineSearchStore.dispatch({
    type: ACTION.SKELETON_SEARCH,
    payload: value,
  });

  subscribeHistory = (cb: any) => this.historyStore.subscribe(cb);

  subscribeSearch = (cb: any) => this.airlineSearchStore.subscribe(cb);

  subscribeResult = (cb: any) => this.airlineStore.subscribe(cb);
}

export default Airline;
