import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { PageLoader } from 'new-ui';

import { useStores } from '../../../../../bi/context';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import { getText } from '../../../../../../i18n';

import ROUTES from '../../../../../bi/constants/routes';

import ChartsAnalytics from '../../../../../bi/services/chartsAnalytics';

import { ChartAnalyticsNoResult } from '../../noResult';
import { TwoCharts } from '../../common/firstPages/twoBarCharts';

const TICKETS = getText('chartsAnalytics:commonChart.booking');

const HotelDirectionsFirstPage = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => {
  const {
    chartsAnalyticsSummaryStore,
    chartsAnalyticsStore: { filtersType, filters: { employees, departments, projects, analytics, tags } },
  } = useStores([
    MOBX_STORES.CHARTS_ANALYTICS_STORE,
    MOBX_STORES.CHARTS_ANALYTICS_SUMMARY_STORE,
  ]);

  const {
    chart,
    donutChart,
    tableInfo: {
      labelsHeader,
      content,
    },
    loading,
  } = chartsAnalyticsSummaryStore;

  const {
    getHotelDirections,
  } = chartsAnalyticsService;

  useEffect(() => {
    getHotelDirections();
  }, [
    getHotelDirections,
    employees.selected,
    departments.selected,
    projects.selected,
    analytics.selected,
    tags.selected,
    filtersType,
  ]);

  const history = useHistory();

  if (loading) return <PageLoader />;

  if (!chart.values.length && !content.length) return <ChartAnalyticsNoResult />;

  const redirectToSubPage = () => {
    history.push(ROUTES.CHARTS_ANALYTICS.HOTEL.DIRECTIONS_NESTED);
  };

  return (
    <TwoCharts
      chartsAnalyticsService={ chartsAnalyticsService }
      labelDoughnut={ TICKETS }
      chart={ chart }
      donutChart={ donutChart }
      labelsHeader={ labelsHeader }
      content={ content }
      redirectToSubPage={ redirectToSubPage }
    />
  );
});

export { HotelDirectionsFirstPage };
