import React, { Component, ReactNode, createRef, RefObject } from 'react';
import { StyledWrapper, Text, Button, Icon } from 'new-ui';
import { RouteComponentProps } from 'react-router-dom';

import { Moment } from 'moment';
import { getText } from '../../../../../../../i18n';

import CONFIG from '../../../../../../../config';

import AppService from '../../../../../../bi/services/app';

import { BUYTRIPSPERSONALRIGHT, BUYTRIPSACCOUNTRIGHT } from '../../../../../../bi/constants/rights';
import ROUTES from '../../../../../../bi/constants/routes';
import { MEAL, UPSELL_EXPERIMENTS } from '../../../../../../bi/constants/hotel';
import { QA_ATTRIBUTES } from '../../../../../../bi/constants/attributesForTests';
import COUNTRIES from '../../../../../../bi/constants/countries';
import { ANALYTIC_SERVICE_TYPES } from '../../../../../../bi/constants/serviceType';

import textAbbreviation from '../../../../../../bi/utils/textAbbreviation';
import { getPrepareCheckinCheckoutDate, prepareHotelPrice } from '../../../../../../bi/utils/hotel';
import MoneyFormat from '../../../../../../bi/utils/money';
import { dateUtcFormat } from '../../../../../../bi/utils/formatDate';
import trimTimezone from '../../../../../../bi/utils/trimTimezone';
import { MainAnalytic } from '../../../../../../bi/utils/analytics';
import { isSmartAgent } from '../../../../../../bi/utils/env';

import { Rate } from '../Rate/rate';
import { NoPhoto } from '../../../../../../components/NoPhoto';
import { ImageGallery } from '../../../../../../components/ImageGallery/ImageGallery';
import { LinkAction } from '../../../../../../components/LinkAction';

import {
  IHotel,
  IImage,
  OfflineRoomGroup,
  PrepareRate,
  PrepareRoomGroup, SearchSettings,
} from '../../../../../../bi/services/hotels/types';
import { IAccountTravelPolicy } from '../../../../../../bi/services/workspace/types';
import { Rights } from '../../../../../../bi/types/workspace';

import styles from './styles/index.module.css';

const ONE_MORE_RATES_LENGTH = 1;

const preloadOrigUrlAndUrl = async (img: IImage) => {
  const { OriginUrl, ThumbnailUrl } = img;
  try {
    await Promise.all([
      new Promise((res, rej) => {
        const imageOriginUrl = new Image();
        imageOriginUrl.src = OriginUrl;
        imageOriginUrl.onload = res;
        imageOriginUrl.onerror = rej;
      }),
      new Promise((res, rej) => {
        const imageThumbnailUrl = new Image();
        imageThumbnailUrl.src = ThumbnailUrl;
        imageThumbnailUrl.onload = res;
        imageThumbnailUrl.onerror = rej;
      }),
    ]);

    return img;
  } catch {
    return null;
  }
};

const LABELS = {
  MOREINFO: getText('hotels:hotelResult.item.roomGroup.moreInfo'),
  NOINFO: getText('hotels:hotelResult.item.roomGroup.noInfo'),
  HOTEL_INFO: (stars: string, address: string, rating: string) => getText('hotels:hotelResult.item.roomGroup.hotelInfo', { stars, address, rating }),
  CANCELLATION_FEE: (date: string) => getText('hotels:hotelResult.item.roomGroup.cancellation.free', { date }),
  CANCELLATION_FEE_FOREIGN: (date: string) => getText('hotels:hotelResult.item.roomGroup.cancellation.freeForeign', { date }),
  CANCELLATION_NON_FEE: getText('hotels:hotelResult.item.roomGroup.cancellation.nonFree'),
  VAT: getText('hotels:hotelResult.item.roomGroup.cancellation.vat'),
  RATES_NAME: getText('hotels:hotelResult.item.roomGroup.rates.name'),
  RATES_TYPE: getText('hotels:hotelResult.item.roomGroup.rates.type'),
  RATES_PROVIDER: getText('hotels:hotelResult.item.roomGroup.rates.provider'),
  CART: getText('hotels:hotelResult.item.roomGroup.rates.cart'),
  BASE: getText('hotels:hotelResult.item.roomGroup.rates.base'),
  REC_RATE: getText('hotels:hotelResult.item.roomGroup.rates.recRate'),
  IS_CUSTOM: getText('hotels:hotelResult.item.roomGroup.rates.isCustom'),
  EARLY_IN: (earlyIn: string) => getText('components:hotelItem.earlyIn', { earlyIn }),
  LATE_OUT: (lateOut: string) => getText('components:hotelItem.lateOut', { lateOut }),
  DEFAULT_PHRASE: getText('components:linkAction.defaultPhrase'),
  BUTTON_SHOW: getText('hotels:hotelResult.item.roomGroup.rates.showRates'),
  BUTTON_HIDE: getText('hotels:hotelResult.item.roomGroup.rates.hideRates'),
};

interface RoomGroupProps {
  isRecommended?: boolean,
  location: RouteComponentProps['location'],
  history: RouteComponentProps['history'],
  room: PrepareRoomGroup,
  hotel: IHotel,
  rightsBuyTrip: Rights,
  showPriceDetails: boolean,
  isMessageSend: boolean,
  disableToCartIfTPApply: boolean,
  accountTravelPolicy: IAccountTravelPolicy,
  travelPolicyList: any[],
  searchSettings: SearchSettings,
  chatState: {
    sendingMessageToAdmin: boolean,
  },
  qaAttrContainer: string,
  onToCart(rate: PrepareRate, ind: number): void,
  onToNote(rate: PrepareRate): void,
  onChangeCount(bookId: string, value: any): void,
  onSendRequest(room: OfflineRoomGroup): void,
  onGallerySlideLeft(): void,
  onGallerySlideRight(): void,
  onGalleryPreview(): void,
  onShowRoomDetails(renderFn: ()=> ReactNode): void
  index: number,
  upsellFlags: string[],
  getReservedHotelSmartagent: boolean,
  appService: AppService,
}

interface RoomGroupState {
  loadedImgs: (IImage | null)[],
  allImageLoadFailed: boolean,
  showDetails: boolean,
}

class RoomGroup extends Component<RoomGroupProps, RoomGroupState> {
  static defaultProps = {
    showPriceDetails: false,
    isRecommended: false,
    room: {},
    accountTravelPolicy: {},
    onToCart: () => {},
    onToNote: () => {},
    onChangeCount: () => {},
    onSendRequest: () => {},
    qaAttrContainer: '',
  };

  imageGallery: ImageGallery | null;
  refTitle: RefObject<HTMLDivElement> = createRef();

  state = {
    loadedImgs: [],
    allImageLoadFailed: false,
    showDetails: false,
  };

  componentDidMount() {
    return this.preloadAndFilterImages();
  }

  handleToScroll = () => {
    if (this.refTitle && this.refTitle.current) {
      this.refTitle.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  preloadAndFilterImages = async () => {
    const { room: { Images } } = this.props;

    if (!Images?.length) {
      return;
    }

    const preloadedImages: (IImage | null)[] = await Promise.all(Images.map(preloadOrigUrlAndUrl));
    const filteredImages: (IImage | null)[] = preloadedImages.filter(Boolean);
    const allImageLoadFailed = !filteredImages.length;

    this.setState({ loadedImgs: filteredImages, allImageLoadFailed });
  };

  handleShowRoomDetails = () => this.props.onShowRoomDetails(this.renderDetails);

  handleSendRequest = (price: string, rateId: string, textError: string) => {
    const { onSendRequest, room } = this.props;
    // @ts-ignore
    // eslint-disable-next-line newline-before-return
    return onSendRequest({ ...room, Price: price, RateId: rateId, textError });
  };

  isShowRatesDetails = () => CONFIG.HOTEL.SHOW_RATES_DETAILS;

  renderGallery = (details = false) => {
    const { room: { Images }, onGallerySlideLeft, onGallerySlideRight } = this.props;
    const { loadedImgs, allImageLoadFailed } = this.state;

    if (!Images?.length || allImageLoadFailed) {
      return (
        <div className={ styles['no-photo-wrapper'] }>
          <NoPhoto />
        </div>
      );
    }

    const imgs = loadedImgs.length ? loadedImgs : Images;

    const imgsList = imgs.map(img => ({
      original: img.OriginUrl,
      thumbnail: img.ThumbnailUrl,
    }));

    const galleryProps = details
      ? { slideInterval: 4000, autoPlay: true, originalImageStyles: { maxHeight: '600px' } }
      : { theme: 'small' };

    return (
      <ImageGallery
        showThumbnails={ details }
        ref={ (i) => {
          this.imageGallery = i;
        } }
        items={ imgsList }
        onSlideLeft={ onGallerySlideLeft }
        onSlideRight={ onGallerySlideRight }
        { ...galleryProps }
        onClick={ this.handleShowRoomDetails }
      />
    );
  };

  renderRatesTable = () => {
    const { room } = this.props;

    if (!this.isShowRatesDetails() || !room.GroupedRates) return null;

    const groupedRatesHtml = room.GroupedRates.map((groupedRate, index) => {
      const innerHtml = groupedRate.Rates.map((rateIn, ind) => {
        const textAsterisk = rateIn.FixedRR ? '*' : '';
        const isCustom = rateIn.IsCustom.toString();

        return (
          <tr key={ `${rateIn.Base}${rateIn.Basket}${ind}` }>
            <td colSpan={ 3 } aria-label='Ы' />
            <td className={ styles['test-table-td'] }>{ rateIn.Basket }</td>
            <td className={ styles['test-table-td'] }>{ rateIn.Base }</td>
            <td className={ styles['test-table-td'] }>{ rateIn.RecRate }{ textAsterisk }</td>
            <td className={ styles['test-table-td'] }>{ isCustom }</td>
          </tr>
        );
      });

      const providerName = groupedRate.ProviderName || '';

      return (
        <tbody key={ `${groupedRate.Name}${index}` }>
          <tr className={ styles['test-table-sub-title'] }>
            <td className={ styles['test-table-td'] }>
              <span className={ styles.name }>{ groupedRate.Name }</span>
            </td>
            <td className={ styles['test-table-td'] }>
              <span className={ styles.name }>{ groupedRate.BedType }</span>
            </td>
            <td className={ styles['test-table-td'] }>
              <span className={ styles.name }>{ providerName }</span>
            </td>
            <td aria-label='Ы'/>
            <td aria-label='Ы'/>
            <td aria-label='Ы'/>
            <td aria-label='Ы'/>
          </tr>
          { innerHtml }
        </tbody>
      );
    });

    return (
      <table className={ styles['test-table'] } >
        <tbody>
          <tr className={ styles['test-table-title'] }>
            <td className={ styles['test-table-td'] }>
              <span className={ styles.name }>{ LABELS.RATES_NAME }</span>
            </td>
            <td className={ styles['test-table-td'] }>
              <span className={ styles.name }>{ LABELS.RATES_TYPE }</span>
            </td>
            <td className={ styles['test-table-td'] }>
              <span className={ styles.name }>{ LABELS.RATES_PROVIDER }</span>
            </td>
            <td className={ styles['test-table-td'] }>
              <span className={ styles.name }>{ LABELS.CART }</span>
            </td>
            <td className={ styles['test-table-td'] }>
              <span className={ styles.name }>{ LABELS.BASE }</span>
            </td>
            <td className={ styles['test-table-td'] }>
              <span className={ styles.name }>{ LABELS.REC_RATE }</span>
            </td>
            <td className={ styles['test-table-td'] }>
              <span className={ styles.name }>{ LABELS.IS_CUSTOM }</span>
            </td>
          </tr>
        </tbody>
        { groupedRatesHtml }
      </table>
    );
  };

  renderDetails = () => {
    const { room: { Name, Description } } = this.props;

    return (
      <div className={ styles['details-wrapper'] }>
        <div className={ styles.gallery }>
          { this.renderGallery(true) }
        </div>
        <div className={ styles.texts }>
          <Text type='bold_32' >{ Name }</Text>
          { Description && (<Text type='NORMAL_16_140' className={ styles.description }>
            { /* eslint-disable-next-line react/no-danger */ }
            <div dangerouslySetInnerHTML={ { __html: Description } } />
          </Text>) }
        </div>
      </div>
    );
  };

  handleGetLinkAction = ({ Stars, Address, Reviews: { Rating } }: IHotel) => {
    const { room, searchSettings, hotel: { CountryCode }, upsellFlags, isRecommended } = this.props;
    const {
      daysCount,
      customCheckin,
      customCheckout,
    } = searchSettings;
    const { Rates } = room;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_LINK_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.HOTEL),
    );

    const ratesOptimal = isRecommended && upsellFlags.includes(UPSELL_EXPERIMENTS.FOURTH)
      ? Rates.slice(0, 1)
      : Rates;

    const mappedRates = ratesOptimal.map(({
      Name,
      Price: {
        TotalPrice,
        AgentFee,
        NightRate,
        HasVAT,
        VAT,
      },
      // @ts-ignore
      Select: { count },
      CancellationPolicy: { Refundable, DeadLine },
      Meal: { Name: MealName, Included },
    }) => {
      const cancellationByCountry = () => (CountryCode === COUNTRIES.RU.SHORTNAME ?
        LABELS.CANCELLATION_FEE(dateUtcFormat(trimTimezone(DeadLine || ''))) :
        LABELS.CANCELLATION_FEE_FOREIGN(dateUtcFormat(trimTimezone(DeadLine || ''))));

      const preparedCancellation = Refundable
        ? cancellationByCountry()
        : LABELS.CANCELLATION_NON_FEE;

      const vat = HasVAT ? `${LABELS.VAT}: ${MoneyFormat.moneyWithDecimal(Number(VAT), true)}` : '';
      const breakfast = Included ? `${MEAL.HAS_MEAL_NAME}: ${MealName}` : MEAL.NOT_HAS_MEAL_NAME;
      const { countString,
        daysString,
        nightRate,
        rzpvString,
        price,
      } = prepareHotelPrice(
        count,
        TotalPrice,
        AgentFee,
        daysCount,
        NightRate,
        searchSettings,
        customCheckin,
        customCheckout,
      );

      const preparePrice = MoneyFormat.moneyWithDecimal(Number(price), true);
      const preparedPrice = count > 1 || daysCount > 1 || customCheckin || customCheckout
        ? `${countString} ${daysString} ${nightRate} ${rzpvString} = ${preparePrice}`
        : `${preparePrice}`;

      return `${Name} /n ${preparedCancellation} /n ${breakfast} /n ${preparedPrice} ${vat} /n`;
    });

    const hotelPrepared = LABELS.HOTEL_INFO(Stars.toString(), Address, Rating.Value.toString());

    return `${hotelPrepared} ${mappedRates.join('')}`;
  };

  handleShowRates = (value: boolean) => {
    this.setState({ showDetails: value });

    if (!value) {
      this.handleToScroll();
    }
  };

  renderLink = () => {
    const { location, history, hotel, searchSettings } = this.props;
    const url = `${ROUTES.SEARCH.HOTEL_PAGE}/${hotel.Id}`;
    const appLink = `${window.location.origin}${history.createHref({ pathname: url, search: location.search })}`;

    const {
      customCheckin,
      customCheckout,
      checkin,
      checkout,
    } = searchSettings;

    const preparedCheckin = getPrepareCheckinCheckoutDate(LABELS.EARLY_IN, checkin as Moment, customCheckin);

    const preparedCheckout = getPrepareCheckinCheckoutDate(LABELS.LATE_OUT, checkout as Moment, customCheckout);

    const dates = `${preparedCheckin} – ${preparedCheckout}`;

    return (
      <LinkAction
        dialogLabel={ dates }
        item={ hotel }
        generateLink={ this.handleGetLinkAction }
        applicationLink={ appLink }
        additionalText={ LABELS.DEFAULT_PHRASE }
        isAllText
        withLinks
      />
    );
  };

  renderRooms = () => {
    const {
      room,
      hotel: { CountryCode },
      rightsBuyTrip,
      onToCart,
      onToNote,
      searchSettings,
      chatState,
      accountTravelPolicy,
      travelPolicyList,
      showPriceDetails,
      onChangeCount,
      isMessageSend,
      disableToCartIfTPApply,
      index,
      isRecommended,
      upsellFlags,
      getReservedHotelSmartagent,
      appService,
    } = this.props;
    const { showDetails } = this.state;

    const { Rates, IsContract } = room;
    const { BuyTripAccount, BuyTripPersonal } = rightsBuyTrip;

    const ratesHide = Rates && !showDetails && upsellFlags.includes(UPSELL_EXPERIMENTS.FOURTH)
      ? Rates.slice(0, 1)
      : Rates;
    const unavailableTravelPolicy = BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unavailable &&
      BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unavailable;
    const tpRights = (BuyTripPersonal === BUYTRIPSPERSONALRIGHT.TravelPolicy ||
      BuyTripPersonal === BUYTRIPSPERSONALRIGHT.ApprovalScheme) &&
      BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unavailable;

    return Rates ? ratesHide?.map((rate, ind) => {
      if (isRecommended && !rate.IsOptimalRate) return null;

      const qaAttrRateContainer = index === 0 ? `${QA_ATTRIBUTES.hotels.current.room.container}-${ind}` : '';

      return (
        <div className={ styles.rate } key={ rate.RateId }>
          <Rate
            rate={ rate }
            countryCode={ CountryCode }
            showPriceDetails={ showPriceDetails }
            unavailableTravelPolicy={ unavailableTravelPolicy }
            tpRights={ tpRights }
            accountTravelPolicy={ accountTravelPolicy }
            travelPolicyList={ travelPolicyList }
            searchSettings={ searchSettings }
            chatState={ chatState }
            onChangeCount={ onChangeCount }
            onSendRequest={ this.handleSendRequest }
            onToCart={ (rateToCart) => onToCart(rateToCart, ind) }
            onToNote={ onToNote }
            isContract={ IsContract }
            isMessageSend={ isMessageSend }
            disableToCartIfTPApply={ disableToCartIfTPApply }
            qaAttrContainer={ qaAttrRateContainer }
            getReservedHotelSmartagent={ getReservedHotelSmartagent }
            appService={ appService }
          />
        </div>
      );
    }) : null;
  };

  renderBody = () => {
    const { room, upsellFlags, isRecommended } = this.props;
    const { showDetails } = this.state;

    const { Rates, Description, Images } = room;

    const isRateIncludeOptimal = Rates ? Rates.some(({ IsOptimalRate }) => IsOptimalRate) : null;
    const rightBlockStyles = [styles.right];

    if (isRateIncludeOptimal && Rates.length > ONE_MORE_RATES_LENGTH) {
      rightBlockStyles.push(styles['include-optimal']);
    }

    const typeIcon = showDetails ? 'arrowsUpBlue' : 'arrowsDownBlue';
    const textButton = showDetails ? LABELS.BUTTON_HIDE : LABELS.BUTTON_SHOW;

    const buttonShowDetailsHtml = !isRecommended && Rates && Rates.length > ONE_MORE_RATES_LENGTH ? (
      <div className={ styles.arrow }>
        <Button
          className={ styles.button_show_rate }
          type='primary-outline'
          onClick={ () => this.handleShowRates(!showDetails) }
        >
          { textButton }
          <Icon
            className={ styles.icon }
            type={ typeIcon }
            alternativeDesign={ isSmartAgent }
          />
        </Button>
      </div>
    ) : null;

    const descriptionStr = textAbbreviation(Description, 110) || '';

    let descriptionHtml = null;
    let moreStrLabel = LABELS.NOINFO;
    let moreStrClass = styles['non-clickable'];
    let moreStrOnClick = () => {};

    if (descriptionStr.length) {
      descriptionHtml = descriptionStr;
      moreStrLabel = LABELS.MOREINFO;
      moreStrClass = '';
      moreStrOnClick = this.handleShowRoomDetails;
    }

    if (Images && Images.length) {
      moreStrClass = '';
      moreStrOnClick = this.handleShowRoomDetails;
    }

    const moreDetailsStyle = upsellFlags.includes(UPSELL_EXPERIMENTS.FOURTH) ?
      styles.more_details :
      '';

    const moreStrContent = (
      <Button
        type='textual'
        className={ `${styles.more} ${moreStrClass} ${moreDetailsStyle}` }
        onClick={ moreStrOnClick }
      >
        { moreStrLabel }
      </Button>
    );

    if (upsellFlags.includes(UPSELL_EXPERIMENTS.FOURTH)) {
      return (
        <div className={ styles.body }>
          <div className={ styles.left }>
            { this.renderGallery() }
          </div>
          <div className={ rightBlockStyles.join(' ') }>
            { moreStrContent }
            <div className={ styles.divide } />
            { this.renderRooms() }
            { this.renderRatesTable() }
          </div>
          { buttonShowDetailsHtml }
        </div>
      );
    }

    return (
      <div className={ styles.body }>
        <div className={ styles.left }>
          { this.renderGallery() }
          { descriptionHtml && (<Text type='NORMAL_16_140' className={ styles.description }>
            { /* eslint-disable-next-line react/no-danger */ }
            <div dangerouslySetInnerHTML={ { __html: descriptionHtml } } />
          </Text>) }
          { moreStrContent }
        </div>
        <div className={ rightBlockStyles.join(' ') }>
          { this.renderRooms() }
          { this.renderRatesTable() }
        </div>
      </div>
    );
  };

  render() {
    const {
      room,
      index,
      qaAttrContainer,
    } = this.props;

    const { Name, IsContract } = room;

    const selectStyles = IsContract ? styles.contract : styles.title;
    const qaAttrFirstEl = index === 0 ? qaAttrContainer : '';

    return (
      <StyledWrapper qaAttr={ qaAttrFirstEl } className={ styles.wrapper }>
        <div className={ selectStyles } ref={ this.refTitle }>
          <Text type='NORMAL_18' >{ Name }</Text>
          { this.renderLink() }
        </div>
        { this.renderBody() }
      </StyledWrapper>
    );
  }
}

export { RoomGroup };
