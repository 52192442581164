import React, { FC, useState } from 'react';
import { Button, Dialog, Text } from 'new-ui';
import { Moment } from 'moment';

import { getText } from '../../../../../i18n';

import { startOfMonth, getMoment, formatDate } from '../../../../bi/utils/formatDate';

import { PATTERN } from '../../../../bi/constants/dateFormats';

import RangeDatePicker from '../../../../components/RangeDatePicker';

import styles from '../../styles/dialog.module.css';

type DialogTemplateProps = {
  onDownload: (params:{ StartDate: string, EndDate: string }) => Promise<void>,
  onClose: (value:boolean) => void,
};

const LABELS = {
  DOWNLOAD: getText('reports:operations.dialog.download'),
  CANCEL: getText('common:cancel'),
  TITLE: getText('reports:operations.dialog.title'),
};

const pattern = PATTERN.YEARMONTHDAY;

const DialogSpecifiedReport: FC<DialogTemplateProps> = ({ onDownload, onClose }) => {
  const [startDate, setStartDate] = useState<string | Moment | null>(startOfMonth());
  const [endDate, setEndDate] = useState<string | Moment | null>(getMoment());
  const [loading, setLoading] = useState<boolean>(false);
  const maxDate: Moment | null = getMoment();

  const toggleLoading = () => setLoading(!loading);

  const handleClose = () => {
    toggleLoading();
    onClose(false);
  };

  const handleDownload = async () => {
    toggleLoading();

    const data = {
      StartDate: formatDate(startDate, pattern),
      EndDate: formatDate(endDate, pattern),
    };

    onDownload(data)
      .then(() => handleClose())
      .catch(() => toggleLoading());
  };

  return (
    <Dialog onChange={ onClose } show>
      <div className={ styles.dialog_wrap }>
        <Text type='normal_20' >{LABELS.TITLE}</Text>
        <div className={ styles.date_range }>
          <RangeDatePicker
            startDate={ startDate }
            endDate={ endDate }
            maxDate={ maxDate }
            onChangeStartDate={ setStartDate }
            onChangeEndDate={ setEndDate }
          />
        </div>
        <div className={ styles.action }>
          <Button
            loading={ loading }
            className={ styles.button }
            onClick={ handleDownload }
          >
            { LABELS.DOWNLOAD }
          </Button>
          <Button
            onClick={ () => onClose(false) }
            type='textual'
            className={ styles.button }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogSpecifiedReport;
