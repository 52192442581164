import React from 'react';
import { observer } from 'mobx-react';
import { SmartwayCommonEnumsSortField, SmartwayCommonEnumsSortType } from '@sw/api';
import { Button, DotLoading, Text, UniversalTable } from 'new-ui';

import { useServices } from '../../../../bi/context/services';

import { StatusCondition } from '../../../../components/StatusCondition';
import { GovServiceAuth } from './components/GovServiceAuth';
import { EmployeeSelect } from './components/EmployeeSelect';

import { useModal } from './hooks';

import { LABELS, tableSettings } from './consts';
import { LoadingStatus } from '../../../../bi/services/utils/network/types';
import { LoadingFields } from '../../../../bi/services/signature';

import styles from './styles.module.css';

export const DocumentsGosSign = observer(() => {
  const {
    signatureService: {
      downloadReport,
      sendToGov,
      netStore,
      updateFilter,
      callDocumentPreviewIsntruction,
      enhancedSignatureStore: {
        setInputParameter,
        blockedIds,
        setSelectedIdsList,
        selectedIds,
        integrationEnabled,
        listInputParams,
        signListData,
      },
    },
    workspaceService: { isAdmin },
  } = useServices(['Signature', 'Workspace']);

  const ModalComponent = useModal();

  const selectable = !isAdmin && integrationEnabled;

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.topBlock }>
        <Text type='bold_24'>
          { LABELS.TITLE }
        </Text>
        <div className={ styles.actionsRow }>
          <Button type='textual' onClick={ callDocumentPreviewIsntruction }>
            <Text type='NORMAL_16' color='accent'>
              { LABELS.HOW_IT_WORK }
            </Text>
          </Button>
          <div className={ styles.actionsBlock }>
            <StatusCondition
              status={ LoadingStatus.NEVER }
              onLoading={ DotLoading }
              onNever={ () => null }
              fallback={ () => (
                <Button type='textual' onClick={ downloadReport }>
                  <Text type='NORMAL_16' color='accent'>
                    { LABELS.DOWNLOAD_REPORT_XLSX }
                  </Text>
                </Button>
              ) }
            />
          </div>
        </div>
        <div className={ styles.actionsRow }>
          <EmployeeSelect />
          <div className={ styles.actionsBlock }>
            <GovServiceAuth />
            <Button
              type='secondary'
              loading={ netStore.getIsStatus(LoadingFields.sendToGov, LoadingStatus.LOADING) }
              onClick={ sendToGov }
              disabled={ selectedIds.length === 0 }
            >
              { LABELS.SEND_TO_GOV }
            </Button>
          </div>
        </div>
      </div>
      <UniversalTable
        items={ signListData.documentsInfo }
        settings={ tableSettings(selectable) }
        blockSelect={ blockedIds }
        selected={ selectedIds }
        onChange={ setSelectedIdsList }
        total={ signListData.count }
        currentPage={ listInputParams.page }
        itemsPerPage={ listInputParams.limit }
        sortDirection={ listInputParams.sortType }
        sortField={ listInputParams.sortField }
        onChangeSort={ (column, direction) => {
          const enumColumn = (column as string).charAt(0).toLowerCase() + (column as string).slice(1);
          setInputParameter('sortField', enumColumn as SmartwayCommonEnumsSortField);
          updateFilter('sortType', direction as SmartwayCommonEnumsSortType);
        } }
        onChangePage={ (num) => updateFilter('page', num) }
      />
      <ModalComponent />
    </div>
  );
});
