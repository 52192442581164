import React from 'react';
import { SidePanel, Text, Input, Button, DotLoading } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { SIGNATURE_DATA_FIELDS } from '../../../../../bi/constants/employee';
import { TYPE_IMAGE } from '../../../../../bi/constants/signature';

import { InputPhone } from '../../../../../components/InputPhone';
import { FormWrapper } from '../../../../../components/FormWrapper';
import { FieldLabel } from '../../../../../components/FieldLabel';

import { MainAnalytic } from '../../../../../bi/utils/analytics';

import styles from '../index.module.css';

interface ISignatureDialog {
  onCloseSignatureDialog(value:boolean): void;
  onSetDataSignature(field: string, value:string): void;
  onSetDataSignatureErrors(field: string, value:string | boolean): void;
  onCreateSimpleSignature(value: boolean): void;
  data: { email: string, phoneNumber: string };
  errorData: { email: string, phoneNumber: string };
  images: string;
  loadings: { [p: string]: boolean };
}

const LABELS = {
  GET_ACCESS: getText('settings:employees.employee.steps.signature.getAccess'),
  HEADER_AGREEMENT: getText('settings:employees.employee.steps.signature.agreement'),
  HEADER_DATA: getText('settings:employees.employee.steps.signature.headerData'),
  PHONE_NUMBER: getText('settings:employees.employee.steps.signature.phoneNumber'),
  EMAIL: getText('settings:employees.employee.steps.signature.email'),
  CONFIRM: getText('settings:employees.employee.steps.signature.confirm'),
};

const SignatureDialog = ({
  onCloseSignatureDialog,
  onSetDataSignature,
  onSetDataSignatureErrors,
  onCreateSimpleSignature,
  data,
  images,
  errorData,
  loadings,
}: ISignatureDialog) => {
  const { email, phoneNumber } = data;
  const { loadingGetSignatureCode, loadingGetSignatureImages } = loadings;
  const { email: errorEmail, phoneNumber: errorPhoneNumber } = errorData;

  const disabledButtonConfirm = errorEmail || errorPhoneNumber || !email || !phoneNumber;

  const handleCreateSimpleSignature = () => {
    onCreateSimpleSignature(false);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.USER_CLOSED_AGREEMENT_SS_MODAL, {
      agreementPEP: 'confirm',
    });
  };

  const handleCloseSignatureDialog = () => {
    onCloseSignatureDialog(false);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.USER_CLOSED_AGREEMENT_SS_MODAL, {
      agreementPEP: 'closed',
    });
  };

  const renderImageContent = () => {
    if (loadingGetSignatureImages) {
      return <div className={ styles.images_loading }><DotLoading /></div>;
    }

    const srcString = () => TYPE_IMAGE.SVG + images;

    return (
      <img
        className={ styles.agreement }
        src={ srcString() }
        alt='agreement'
      />
    );
  };

  const renderDataContent = () => (
    <FormWrapper className={ styles.row }>
      { renderImageContent() }
      <div className={ styles.data }>
        <Text
          className={ styles.header }
          type='bold_16'
        >
          { LABELS.HEADER_DATA }
        </Text>
        <div className={ styles.row }>
          <div className={ styles.field }>
            <FieldLabel
              text={ LABELS.PHONE_NUMBER }
              mustHave
            />
            <InputPhone
              value={ phoneNumber }
              onBlur={ (value: boolean) => onSetDataSignatureErrors(SIGNATURE_DATA_FIELDS.PHONE_NUMBER, value) }
              onChange={ (value: string) => onSetDataSignature(SIGNATURE_DATA_FIELDS.PHONE_NUMBER, value) }
              debounceMs={ 0 }
              error={ errorPhoneNumber }
            />
          </div>
          <div className={ styles.field }>
            <FieldLabel
              text={ LABELS.EMAIL }
              mustHave
            />
            <Input
              useTimeout
              value={ email }
              onChange={ (value: string) => onSetDataSignature(SIGNATURE_DATA_FIELDS.EMAIL, value) }
              onFocus={ () => onSetDataSignatureErrors(SIGNATURE_DATA_FIELDS.EMAIL, email) }
              onBlur={ () => onSetDataSignatureErrors(SIGNATURE_DATA_FIELDS.EMAIL, email) }
              error={ errorEmail }
            />
          </div>
        </div>
      </div>
      <div className={ styles.action }>
        <Button
          className={ styles.confirm }
          type='secondary'
          loading={ loadingGetSignatureCode }
          onClick={ handleCreateSimpleSignature }
          disabled={ !!disabledButtonConfirm }
        >
          { LABELS.CONFIRM }
        </Button>
      </div>
    </FormWrapper>
  );

  const renderContent = () => (
    <div className={ styles.signature_content }>
      <Text
        className={ styles.header }
        type='bold_32'
      >
        { LABELS.HEADER_AGREEMENT }
      </Text>
      <div className={ styles.images } />
      { renderDataContent() }
    </div>
  );

  return (
    <SidePanel
      show
      onClose={ handleCloseSignatureDialog }
    >
      { renderContent() }
    </SidePanel>
  );
};

export { SignatureDialog };
