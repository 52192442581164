import React, { FC } from 'react';
import type { TextColor } from 'new-ui';
import { MomentInput } from 'moment';

import { getText } from '../../../../i18n';

import { getDateDescriptor } from '../../../bi/utils/formatDate';

import { ApprovesType } from '../../../bi/services/expenseReports/types';

import { AddresserInfoItem } from '../../../components/AddresserInfoItem';

import { Comments } from './comments';

import styles from '../styles/approverContent.module.css';

const LABELS = {
  APPROVE: getText('common:approve'),
  DECLINE: getText('common:decline'),
  REPORT_FOR_APPROVAL: getText('expenseReports:report.reportForApproval'),
};

interface ApproverContentProps {
  approves: ApprovesType[];
  addresserColor?: TextColor;
  color?: TextColor;
  stamp?: string | MomentInput;
}

const ApproverContent: FC<ApproverContentProps> = ({
  approves,
  addresserColor = 'white',
  color = 'white',
  stamp = '',
}) => {
  if (
    !approves ||
    !approves[0] ||
    !approves[0].Messages ||
    approves[0].Messages.length === 0
  ) {
    return null;
  }

  const firstComment = approves[0].Messages[0];
  const date = getDateDescriptor(stamp).toLowerCase();

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.request }>
        <AddresserInfoItem
          date={ date }
          email={ firstComment.Email }
          name={ firstComment.Name }
          label={ LABELS.REPORT_FOR_APPROVAL }
          typeText='NORMAL_16'
          color={ addresserColor }
        />
      </div>
      <Comments approves={ approves } color={ color } />
    </div>
  );
};

export { ApproverContent };
