import React, { FC, useState } from 'react';
import { MultiSelect } from 'new-ui';
import { useQuery } from '@tanstack/react-query';

import { api } from '../../../../../bi/apiV2';

import { getText } from '../../../../../../i18n';

import { Tag } from '../../../../../bi/types/trips';

import styles from '../index.module.css';

const getTagsForSelect = (tag: Tag) => ({ value: tag.Id, label: tag.Value });

const LABELS = {
  PLACEHOLDER: getText('trips:tripTags'),
  SEARCH_PLACEHOLDER: getText('trips:tags'),
};

const useTags = (query: string) => useQuery({
  queryKey: ['tripsFilters', query],
  queryFn: () => api.trips.autocompleteTags(query),
  staleTime: 60000,
  enabled: !!query.length,
});

interface FilterTagsProps {
  tags: Tag[];
  values: number[];
  onChange(checkedTags: number[], checkeTagsWithLabels: { label: string, value: number }[]): void;
}

const FilterTags: FC<FilterTagsProps> = ({ tags, values, onChange }) => {
  const [autoComplete, setAutoComplete] = useState('');
  const { data } = useTags(autoComplete);

  if (!tags.length) return null;

  const tagsForSelect = data?.length ? data : tags;

  const tagsList = tagsForSelect.map((tag: Tag) => getTagsForSelect(tag));

  return (
    <div className={ styles.filter }>
      <MultiSelect
        withLabel
        placeholder={ LABELS.PLACEHOLDER }
        itemClassName={ styles.multi_select_item }
        list={ tagsList }
        values={ values }
        search
        searchPlaceholder={ LABELS.SEARCH_PLACEHOLDER }
        onChange={ onChange }
        onSearchFunction={ (value: React.SetStateAction<string>) => setAutoComplete(value) }
      />
    </div>
  );
};

export { FilterTags };
