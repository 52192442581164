import { getText } from '../../../../../i18n';

import ACTION from '../action';
import Store from '../../../store';
import { secondsToLabel } from '../../../utils/time';

import parseUnix from '../../../utils/parseDateTime';
import MoneyFormat from '../../../utils/money';
import { getAirlineType } from '../../../utils/airline';
import { mapResponse, TripTag } from '../../../utils/airlineMultisearch';
import { filterByTransferAirports } from '../../../utils/filterByTransferAirports';

import { TRAVELPOLICYFILTER } from '../../../constants/travelPolicy';
import { AIRFILTERTYPE } from '../../../constants/tagsFilterType';
import { BUYTRIPSPERSONALRIGHT, BUYTRIPSACCOUNTRIGHT } from '../../../constants/rights';
import { QA_ATTRIBUTES } from '../../../constants/attributesForTests';
import { VALUETICKET, SORT_VALUE } from '../../../constants/airline';
import { uniqueElements } from '../../../utils/common';

import {
  AirPaging,
  AirlineSource,
  AirFilters,
  IRefundable,
  AirlinesFilters,
  AirSegment,
  AirRoute,
  AirlinesFilterKeys,
  IBagageFilter,
  AirportsFilter,
  PartialAirportsFilter,
  AirRouteInfo,
  RoutesBorderTime,
  AirTag,
  IFavorite,
  RoutesTravelTime,
  IRouteSelected,
  FilterName,
  AirTime,
  IAction,
  AirlinesFilterNames,
  ITemperatureScale,
} from '../../../types/airline';
import {
  ISearchPatterns,
  IFiltersByFlightsNumbers,
  IItemNumbers,
} from '../types';
import { TravelPolicyItem } from '../../userSession/types';

const getAmountValueByKey = (key: string | number = 0): string | number => {
  if (key === '2' || key === 2) {
    return getText('services:airline.store.airline.twoAndMore');
  }

  return key;
};

const AIRLINECOMPANIESINNERCODES: { [key: string]: string } = {
  тф: 'YK',
  юх: 'N2',
  ао: 'АО',
  ие: 'HZ',
  фл: 'I4',
  ям: '6R',
  бг: '2B',
  ик: '2G',
  у8: 'U8',
  уу: 'VV',
  су: 'SU',
  в2: 'B2',
  бю: 'BU',
  нн: 'NN',
  дд: 'XF',
  вд: 'VI',
  оп: '4G',
  гл: 'GB',
  гг: 'ZG',
  з6: 'Z6',
  д9: 'D9',
  '7д': '7D',
  иж: 'I8',
  эк: 'KV',
  '7к': '7K',
  юк: 'GW',
  пс: 'PS',
  зр: '3R',
  м9: 'М9',
  '5н': '5N',
  ти: 'Y7',
  р2: 'R2',
  по: 'YQ',
  ин: 'WZ',
  пл: 'FV',
  рг: '7R',
  с7: 'S7',
  '6в': '6W',
  кл: 'N4',
  д2: 'D2',
  эх: 'XW',
  дн: 'DV',
  '4й': '4J',
  тд: '7J',
  у9: 'U9',
  ун: 'UN',
  хи: 'HY',
  у6: 'U6',
  фр: 'DF',
  кс: 'KC',
  ои: 'KR',
  аю: 'QH',
  '9у': '9U',
  жг: 'A9',
  юв: 'UW',
  ют: 'UT',
  юр: 'UR',
  як: 'R3',
  ла: 'YL',
};

const EMPTYREFUNDABLEFILTER: IRefundable = {
  Charge: false,
  Included: false,
  NotPenalty: false,
};

const EMPTYBAGGAGEFILTER: IBagageFilter = {
  Included10AndMore: false,
};

const REFUNDABLEFILTERTAGS: { [key: string]: string } = {
  Charge: getText('services:airline.store.airline.refundableFilterTags.charge'),
  Included: getText('services:airline.store.airline.refundableFilterTags.included'),
};

const BAGGAGEFILTERTAGS: { [key: string]: string } = {
  Included10AndMore: getText('services:airline.store.airline.included10AndMore'),
};

const TAGS_LABELS = {
  priceFromTo: (from: string, to: string) => getText('services:airline.store.airline.tags.priceFromTo', { from, to }),
  transferTime: (from: string, to: string) => getText('services:airline.store.airline.tags.transferTime', { from, to }),
  transferCount: (count: string | number) => getText('services:airline.store.airline.tags.transferCount', { count }),
  withoutTransfers: getText('services:airline.store.airline.tags.withoutTransfers'),
  from: (name: string) => getText('services:airline.store.airline.tags.from', { name }),
  to: (name: string) => getText('services:airline.store.airline.tags.to', { name }),
  departureFromTo: (from: string, to: string) => getText('services:airline.store.airline.tags.departureFromTo', { from, to }),
  arrivalFromTo: (from: string, to: string) => getText('services:airline.store.airline.tags.arrivalFromTo', { from, to }),
  travelTime: (from: string, to: string) => getText('services:airline.store.airline.tags.travelTime', { from, to }),
  flightNumber: (number: string) => getText('services:airline.store.airline.tags.flightNumber', { number }),
  favorite: getText('services:airline.store.airline.tags.favorite'),
  refund: (item: string) => getText('services:airline.store.airline.tags.refund', { item }),
  baggage: (item: string) => getText('services:airline.store.airline.tags.baggage', { item }),
  flightNumbers: (numbers: string) => getText('services:airline.store.airline.tags.flightNumbers', { numbers }),
};

interface IAirlineState {
  sourceId: string | null;
  loading: boolean,
  sources: AirlineSource[],
  items: AirlineSource[],
  tags: AirTag[],
  travelPolicyAllList: TravelPolicyItem[],
  unavailableTravelPolicy: boolean,
  isAnyFavorite: boolean,
  favorite: IFavorite | null,
  filters: AirFilters,
  sortBy: string
  paging: AirPaging,
  cacheItems?: AirlineSource[],
  numberOf?: number,
  routeInfo?: AirRouteInfo[],
  temperatureScale?: ITemperatureScale,
}

const createNewState = (): IAirlineState => ({
  sourceId: null,
  loading: false,
  sources: [],
  items: [],
  tags: [],
  travelPolicyAllList: [],
  unavailableTravelPolicy: false,
  isAnyFavorite: false,
  favorite: null,
  filters: {
    price: {
      border: {
        from: 0,
        to: 0,
      },
      roundBy: 0,
      from: 0,
      to: 0,
    },
    directTime: {
      border: {
        from: 0,
        to: 0,
      },
      roundBy: 0,
      from: 0,
      to: 0,
    },
    travelPolicyList: [],
    directCount: {},
    airlines: {} as AirlinesFilters,
    transferAirports: {},
    routesSelected: {} as IRouteSelected,
    airports: [],
    routesBorderTime: [],
    routesTravelTime: [],
    flightNumber: [],
    flightsNumbers: '',
    refundable: EMPTYREFUNDABLEFILTER,
    baggage: EMPTYBAGGAGEFILTER,
    selectedTravelPolicy: TRAVELPOLICYFILTER.NOTAPPLIED,
    onlyAirline: false,
    favoriteId: false,
  },
  sortBy: 'recommended_down',
  paging: {
    total: 0,
    current: 1,
    count: 15,
  },
});

const getPagingView = (
  items: AirlineSource[],
  paging: AirPaging,
): AirlineSource[] => {
  const startInd = paging.count * (paging.current - 1);

  return items.slice(startInd, startInd + paging.count);
};

const applySort = (
  list: AirlineSource[],
  sortBy: string,
  filters: AirFilters,
): AirlineSource[] => {
  const order = 1;
  let fn = null;

  const favoritesTicketsWithoutTP: AirlineSource[] = [];
  const favoritesTicketsWithTP: AirlineSource[] = [];
  const otherTicketsWithoutTP: AirlineSource[] = [];
  const otherTicketsWithTP: AirlineSource[] = [];
  const ticketsWithoutTravelPolicy: AirlineSource[] = [];
  const ticketsWithTravelPolicy: AirlineSource[] = [];

  list.forEach((item) => {
    if (item.TravelPolicy.Apply) {
      if (filters.selectedTravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED) {
        if (typeof (item.TravelPolicy.Errors?.[filters.selectedTravelPolicy]) !== 'undefined') {
          ticketsWithTravelPolicy.push(item);
        } else {
          ticketsWithoutTravelPolicy.push(item);
        }
      } else {
        ticketsWithoutTravelPolicy.push(item);
      }
    } else {
      ticketsWithoutTravelPolicy.push(item);
    }
  });

  if (ticketsWithoutTravelPolicy.length > 0) {
    ticketsWithoutTravelPolicy.forEach((item) => {
      if (item.FavoriteId) {
        favoritesTicketsWithoutTP.push(item);
      } else {
        otherTicketsWithoutTP.push(item);
      }
    });
  }

  if (ticketsWithTravelPolicy.length > 0) {
    ticketsWithTravelPolicy.forEach((item) => {
      if (item.FavoriteId) {
        favoritesTicketsWithTP.push(item);
      } else {
        otherTicketsWithTP.push(item);
      }
    });
  }

  // по цене
  const priceSort = (first: AirlineSource, second: AirlineSource): number => {
    const firstPrice = first.Fares[0].Price.TotalPrice;
    const secondPrice = second.Fares[0].Price.TotalPrice;

    if (firstPrice > secondPrice) return order;

    if (firstPrice < secondPrice) return -order;

    if (first.Duration > second.Duration) return order;

    if (first.Duration < second.Duration) return -order;

    return 0;
  };

  // по времени перелета
  const distanceSort = (
    first: AirlineSource,
    second: AirlineSource,
  ): number => {
    if (first.Duration > second.Duration) return order;

    if (first.Duration < second.Duration) return -order;

    return 0;
  };

  // по времени вылета, 0 index - по 1 сегменту, 1 index - по второму сегменту
  const departureSort = (
    first: AirlineSource,
    second: AirlineSource,
    index: number,
  ): number => {
    const firstTime = first.Routes[index].Segments[0].DepartureTime;
    const secondTime = second.Routes[index].Segments[0].DepartureTime;

    if (firstTime > secondTime) return order;

    if (firstTime < secondTime) return -order;

    return 0;
  };

  // по времени вылета, если маршрут туда - обратно
  const departureTimeSort = (): AirlineSource[] => {
    // сортировка по вермени 1 сегмента
    const tickets = [
      ...favoritesTicketsWithoutTP.sort((...args) => departureSort(...args, 0)),
      ...otherTicketsWithoutTP.sort((...args) => departureSort(...args, 0)),
      ...favoritesTicketsWithTP.sort((...args) => departureSort(...args, 0)),
      ...otherTicketsWithTP.sort((...args) => departureSort(...args, 0)),
    ];

    if (!tickets.length) return [];

    const isOneRoute = tickets[0].Routes.length < 2;

    // если маршрут только туда - возвращаем отсортированный массив по времени 1 сегмента
    if (isOneRoute) return tickets;

    let result: AirlineSource[] = [];
    // массив в который сохраняется время уже отсортированныйх роутов
    const savedTopSegmentTime = [tickets[0].Routes[0].Segments[0].DepartureTime];
    const forSortTickets = (savedTime: number) => tickets.filter(ticket => savedTime === ticket.Routes[0].Segments[0].DepartureTime);

    // цикл в котором происходит сортировка по времени 2 сегмента
    for (let i = 0; i <= tickets.length - 1; i++) {
      const isFirstTime = i === 0;

      if (isFirstTime) {
        const sortTickets = forSortTickets(savedTopSegmentTime[0] as number);

        result = [...sortTickets.sort((...args) => departureSort(...args, 1))];
      }

      const ticketDepartureTime = tickets[i].Routes[0].Segments[0].DepartureTime;
      // проверка - прошло ли сортировку время 1 сегмента
      const isSavedTime = savedTopSegmentTime.find(time => time === ticketDepartureTime);

      if (!isSavedTime) {
        savedTopSegmentTime.push(ticketDepartureTime);
        const sortTickets = forSortTickets(ticketDepartureTime as number);

        // записываем сортированные сегменты ранее и добавляем новые сортированные сегменты по времени 2 сегмента
        result = [...result, ...sortTickets.sort((...args) => departureSort(...args, 1))];
      }
    }

    return result;
  };

  // по времени пересадки
  const transferTimeSort = (
    first: AirlineSource,
    second: AirlineSource,
  ): number => {
    if (first.ChangeDuration > second.ChangeDuration) return order;

    if (first.ChangeDuration < second.ChangeDuration) return -order;

    return 0;
  };

  const sortTicketsWithTags = (ticketsForFilter: AirlineSource[]) => [
    ...ticketsForFilter.filter(ticket => ticket.Tags.some((ticketTag: TripTag) => ticketTag === 'Recommended')),
    ...ticketsForFilter.filter(ticket => ticket.Tags.some((ticketTag: TripTag) => ticketTag === 'Cheapest')),
    ...ticketsForFilter.filter(ticket => ticket.Tags.some((ticketTag: TripTag) => ticketTag === 'Fastest')),
  ];

  const getTicketsWithTags = (ticketsForFilter: AirlineSource[]) => uniqueElements(sortTicketsWithTags(ticketsForFilter.filter(ticket => ticket.Tags.length)), 'Id');
  const getTicketsWithoutTags = (ticketsForFilter: AirlineSource[]) => ticketsForFilter.filter(ticket => !ticket.Tags.length);

  const recommendedSort = () => [
    ...getTicketsWithTags(favoritesTicketsWithoutTP),
    ...getTicketsWithoutTags(favoritesTicketsWithoutTP).sort(priceSort),
    ...getTicketsWithTags(otherTicketsWithoutTP),
    ...getTicketsWithoutTags(otherTicketsWithoutTP).sort(priceSort),
    ...getTicketsWithTags(favoritesTicketsWithTP),
    ...getTicketsWithoutTags(favoritesTicketsWithTP).sort(priceSort),
    ...getTicketsWithTags(otherTicketsWithTP),
    ...getTicketsWithoutTags(otherTicketsWithTP).sort(priceSort),
  ];

  switch (sortBy) {
    case SORT_VALUE.RECOMMENDED: {
      return recommendedSort();
    }
    case SORT_VALUE.PRICE_DOWN: {
      fn = priceSort;
      break;
    }
    case SORT_VALUE.DISTANCE_DOWN: {
      fn = distanceSort;
      break;
    }
    case SORT_VALUE.DEPARTURE_TIME_DOWN: {
      return departureTimeSort();
    }
    case SORT_VALUE.TRANSFER_TIME_DOWN: {
      fn = transferTimeSort;
      break;
    }
    default: {
      fn = priceSort;
    }
  }

  return [
    ...favoritesTicketsWithoutTP.sort(fn),
    ...otherTicketsWithoutTP.sort(fn),
    ...favoritesTicketsWithTP.sort(fn),
    ...otherTicketsWithTP.sort(fn),
  ];
};

const filterByRefundable = (
  list: AirlineSource[],
  filter: IRefundable,
): AirlineSource[] => {
  const activeFilters = Object.keys(filter).filter(item => filter[item]);

  if (activeFilters.length) {
    const filteredList: AirlineSource[] = [];

    list.forEach((item) => {
      const fares = item.Fares.filter(fare => filter[fare.IsTicketRefundable]);

      if (fares.length) {
        filteredList.push({ ...item, Fares: fares });
      }
    });

    return filteredList;
  }

  return list;
};

const filterByBaggage = (
  list: AirlineSource[],
  filter: IBagageFilter,
): AirlineSource[] => {
  const activeFilters = Object.keys(filter).filter(item => filter[item]);

  const filterFare = (baggage: string) => {
    const allowedValues = ['Included'];

    return allowedValues.includes(baggage);
  };

  if (activeFilters.length) {
    const filteredList: AirlineSource[] = [];

    list.forEach((item) => {
      const fares = item.Fares.filter(fare => filterFare(fare.IsBaggageIncluded));

      if (fares.length) {
        filteredList.push({ ...item, Fares: fares });
      }
    });

    return filteredList;
  }

  return list;
};

const filterByFlightsNumbers = (
  list: AirlineSource[],
  filters: IFiltersByFlightsNumbers,
): AirlineSource[] => {
  const prepareSearchPatterns = () => {
    const patterns: ISearchPatterns = {
      airlinesCompanies: [],
      flightsNumbers: [],
    };
    const processFlightNumber = (value: string, remainder: boolean = false): number | null => {
      const preparedValue = remainder ? value.slice(2) : value;

      if (isNaN(+preparedValue) || preparedValue.length < 1) {
        return null;
      }

      return patterns.flightsNumbers.push(preparedValue);
    };

    const searchStrings = filters.flightsNumbers.trim().replace(/[.,]/g, '').split(' ');

    searchStrings.forEach((string) => {
      const rawAirlineCompany = string.slice(0, 2);
      const preparedAirlineCompany = filters.airlines[rawAirlineCompany.toLowerCase()] ||
        (
          AIRLINECOMPANIESINNERCODES[rawAirlineCompany.toLowerCase()] &&
          filters.airlines[AIRLINECOMPANIESINNERCODES[rawAirlineCompany.toLowerCase()].toLowerCase()]
        );

      if (preparedAirlineCompany) {
        patterns.airlinesCompanies.push(preparedAirlineCompany);
        processFlightNumber(string, true);
      } else {
        processFlightNumber(string);
      }
    });

    return patterns;
  };
  const filterItem = (itemNumbers: IItemNumbers, searchPatterns: ISearchPatterns): boolean => {
    if (searchPatterns.flightsNumbers.length || searchPatterns.airlinesCompanies.length) {
      if (!searchPatterns.flightsNumbers.length) {
        return searchPatterns.airlinesCompanies.every(pattern => itemNumbers.airlines.includes(pattern));
      }

      if (!searchPatterns.airlinesCompanies.length) {
        return searchPatterns.flightsNumbers.every(pattern => itemNumbers.numbers.includes(pattern));
      }

      return searchPatterns.airlinesCompanies.every(pattern => itemNumbers.airlines.includes(pattern)) &&
          searchPatterns.flightsNumbers.every(pattern => itemNumbers.numbers.includes(pattern));
    }

    return true;
  };

  if (filters.flightsNumbers && filters.flightsNumbers.length >= 2) {
    const searchPatterns = prepareSearchPatterns();

    return list.filter(item => filterItem(item.FlightsNumbers, searchPatterns));
  }

  return list;
};

const applyFilter = (
  list: AirlineSource[],
  filters: AirFilters,
): AirlineSource[] => {
  const directCountKeys = Object.keys(filters.directCount) as unknown as number[];
  const hasAllDirectCount = directCountKeys.filter(item => !filters.directCount[item]).length === directCountKeys.length;

  const airlinesKeys = Object.keys(filters.airlines) as Array<keyof AirlinesFilters>;
  const selectedAirlines = airlinesKeys.filter(item => filters.airlines[item].selected);
  const notSelectedAirlines = airlinesKeys.filter(item => !filters.airlines[item].selected);
  const hasAllAirlines = notSelectedAirlines.length === airlinesKeys.length;

  const transferKeys = Object.keys(filters.transferAirports);
  const selectedTransfers = transferKeys.filter(item => filters.transferAirports[item].selected);

  const hasAllAirport = filters.airports.map((airport) => {
    const airportKeysFrom = Object.keys(airport.from);
    const airportKeysTo = Object.keys(airport.to);

    return {
      hasAllFrom: airportKeysFrom.filter(item => !airport.from[item].selected).length === airportKeysFrom.length,
      hasAllTo: airportKeysTo.filter(item => !airport.to[item].selected).length === airportKeysTo.length,
    };
  });

  const routeBorderTime = filters.routesBorderTime;

  const directMin = filters.directTime.from;
  const directMax = filters.directTime.to;

  const hasFlightNumber = filters.flightNumber.length === 0;

  const segmentFilterCallback = (segment: AirSegment) => {
    let flightNumber = true;

    if (!hasFlightNumber) {
      flightNumber = filters.flightNumber.includes(segment.FlightNumber);
    }

    return flightNumber;
  };

  const routeFilterCallback = (route: AirRoute, routeIndex: number) => {
    const firstSegment = route.Segments[0];
    const lastSegment = route.Segments[(route.Segments.length - 1)];

    const currentBorderTime = routeBorderTime[routeIndex];

    const firstSegmentDepartureTime = firstSegment.DepartureTime as number;
    const lastSegmentArrivalTime = lastSegment.ArrivalTime as number;

    const isBorderTime = ((firstSegmentDepartureTime >= currentBorderTime.departure.from && firstSegmentDepartureTime <= currentBorderTime.departure.to)
      && (lastSegmentArrivalTime >= currentBorderTime.arrival.from && lastSegmentArrivalTime <= currentBorderTime.arrival.to));

    const airportsFilter = filters.airports[routeIndex];
    const hasAirportsFilter = hasAllAirport[routeIndex];
    let isAirport = true;

    if (airportsFilter) {
      const moreOneFrom = Object.keys(airportsFilter.from).length > 1;
      const moreOneTo = Object.keys(airportsFilter.to).length > 1;

      if (!hasAirportsFilter.hasAllFrom && moreOneFrom) {
        isAirport = airportsFilter.from[firstSegment.DepartureAirport.ID].selected;
      }

      if (!hasAirportsFilter.hasAllTo && moreOneTo) {
        isAirport = airportsFilter.to[lastSegment.ArrivalAirport.ID].selected;
      }
    }

    const routeDirectionCount = route.DirectCount > 2 ? 2 : route.DirectCount;

    return (hasAllDirectCount || filters.directCount[routeDirectionCount])
      && isBorderTime
      && isAirport
      && route.Segments.filter(segmentFilterCallback).length > 0;
  };

  const firstApply = list.reduce<AirlineSource[]>((result, item) => {
    const newResult = [...result];

    const isFavorite = filters.favoriteId ? item.FavoriteId : true;

    const filteredByCostFares = item.Fares.filter(fare =>
      fare.Price.TotalPrice >= filters.price.from && fare.Price.TotalPrice <= filters.price.to);

    if (
      isFavorite &&
      item.Routes.filter(routeFilterCallback).length === item.Routes.length
      && filteredByCostFares.length >= 1) {
      newResult.push({
        ...item,
        Fares: filteredByCostFares,
      });
    }

    return newResult;
  }, []);

  let result: AirlineSource[] = [];

  if (hasAllAirlines) {
    result = firstApply;
  }

  if (filters.onlyAirline) {
    result = firstApply.filter(({ Routes }) => {
      const airlineIDs: string[] = [];

      return Routes.filter(({ Segments }) => Segments.filter((segment) => {
        const airlineType = getAirlineType(segment);

        if (!airlineIDs.includes(segment[airlineType].ID)) {
          airlineIDs.push(segment[airlineType].ID);
        }

        return selectedAirlines.includes(segment[airlineType].ID as AirlinesFilterKeys);
      }).length === Segments.length).length === Routes.length && (filters.fewAirlineCompanies ? airlineIDs.length > 0 : (() => (selectedAirlines.length === 1 ? airlineIDs.length === 1 : selectedAirlines.length === airlineIDs.length))());
    });
  } else if (!filters.onlyAirline) {
    result = firstApply.filter(({ Routes }) => Routes.filter(({ Segments }) => Segments.filter((segment) => {
      const airlineType = getAirlineType(segment);

      return hasAllAirlines || selectedAirlines.includes(segment[airlineType].ID as AirlinesFilterKeys);
    }).length > 0).length > 0);
  }

  if (filters.baggage) {
    result = filterByBaggage(result, filters.baggage);
  }

  if (filters.refundable) {
    result = filterByRefundable(result, filters.refundable);
  }

  if (filters.flightsNumbers) {
    result = filterByFlightsNumbers(result, { flightsNumbers: filters.flightsNumbers, airlines: filters.airlinesDictionary || {} });
  }

  if (selectedTransfers.length) {
    result = filterByTransferAirports(result, selectedTransfers);
  }

  const routeSelected = filters.routesSelected;

  const hasCheckDirect = directMin !== filters.directTime.border.from || directMax !== filters.directTime.border.to;

  if (hasCheckDirect) {
    result = result.filter(({ ChangeDuration }) => ChangeDuration >= directMin && ChangeDuration <= directMax);
  }

  const routesTravelTime = filters.routesTravelTime;

  routesTravelTime.forEach(({ duration }, index) => {
    const travelTimeMin = duration.from;
    const travelTimeMax = duration.to;
    const { from, to } = filters.routesTravelTime[index].duration.border;
    const hasCheckTravelTime = travelTimeMin !== from || travelTimeMax !== to;

    if (hasCheckTravelTime) {
      result = result.filter(({ Routes }) => {
        const routesDuration = Routes[index].Duration;

        return routesDuration >= travelTimeMin && routesDuration <= travelTimeMax;
      });
    }
  });

  if (routeSelected.departure.length && routeSelected.arrival.length && routeSelected.flight.length && routeSelected.airline.length) {
    result = result.filter(item => item.Routes.filter(({ Segments, Selected }) => {
      const airlineType = getAirlineType(Segments[0]);

      const firstSegmentTime = Segments[0].DepartureTime;
      const firstSegmentAirline = Segments[0][airlineType].ID;
      const firstSegmentFlight = Segments[0].FlightNumber;
      const lastSegmentTime = Segments[Segments.length - 1].ArrivalTime;

      return routeSelected.departure.includes(firstSegmentTime as number) &&
        routeSelected.airline.includes(firstSegmentAirline as string) &&
        routeSelected.flight.includes(firstSegmentFlight as string) &&
        routeSelected.arrival.includes(lastSegmentTime as number) &&
        Selected;
    }).length === routeSelected.departure.length);
  }

  return result;
};

const updateAirports = (
  airports: AirportsFilter[],
  firstSegment: AirSegment,
  lastSegment: AirSegment,
  ind: number,
): AirportsFilter[] => {
  const result = airports.slice();

  if (!result[ind]) {
    result[ind] = {} as AirportsFilter;
  }

  const routeAirports: PartialAirportsFilter = {};

  if (firstSegment.DepartureAirport.ID) {
    routeAirports.from = {
      [firstSegment.DepartureAirport.ID]: {
        name: firstSegment.DepartureAirport.Name,
        selected: false,
      },
    };
  }

  if (lastSegment.ArrivalAirport.ID) {
    routeAirports.to = {
      [lastSegment.ArrivalAirport.ID]: {
        name: lastSegment.ArrivalAirport.Name,
        selected: false,
      },
    };
  }

  result[ind] = {
    from: {
      ...result[ind].from,
      ...routeAirports.from,
    },
    to: {
      ...result[ind].to,
      ...routeAirports.to,
    },
  };

  return result;
};

const updateFiltersAndDataPreparation = (
  list: AirlineSource[],
  isDirect: boolean,
  selectedTravelPolicy: string,
  unavailableTravelPolicy: boolean,
  accountTravelPolicy: TravelPolicyItem,
  travelPolicyAllList: TravelPolicyItem[],
  stateFilters: AirFilters,
) => {
  const filters: Partial<AirFilters> = {
    flightNumber: [],
    selectedTravelPolicy,
  };
  const routeInfo: AirRouteInfo[] = [];
  let sources: AirlineSource[] = [];
  let isAnyFavorite: boolean = false;
  let selectedTP: string = selectedTravelPolicy;

  if (list.length > 0) {
    let min = 0;
    let max = list[0].Fares[0].Price.TotalPrice;

    let directTimeMin = 0;
    let directTimeMax = 0;

    const routesBorderTime: RoutesBorderTime[] = [];
    const airlines = {} as AirlinesFilters;
    const transferAirports: Record<string, FilterName> = {};
    const travelPolicyList: string[] = [];
    const routesTravelTime: RoutesTravelTime[] = [];

    travelPolicyList.push(TRAVELPOLICYFILTER.NOTAPPLIED);

    let airports: AirportsFilter[] = [];

    sources = list.map((ticket) => {
      const flightsNumbers: IItemNumbers = {
        numbers: [],
        airlines: [],
      };
      let sum = 0;
      const ticketRoutes = ticket.Routes.map((route, ind) => {
        route.Segments.forEach((segment) => {
          const airlineType = getAirlineType(segment);
          const { FlightNumber, ChangeDuration, ArrivalCity } = segment;

          flightsNumbers.numbers.push(FlightNumber);
          flightsNumbers.airlines.push(segment[airlineType].ID);
          sum += ChangeDuration;
          airlines[segment[airlineType].ID as AirlinesFilterKeys] = {
            name: segment[airlineType].Name as AirlinesFilterNames,
            selected: false,
          };

          if (ChangeDuration !== 0) {
            if (directTimeMin === 0) {
              directTimeMin = ChangeDuration;
            }

            directTimeMin = Math.min(directTimeMin, ChangeDuration);

            transferAirports[ArrivalCity] = {
              name: ArrivalCity,
              selected: false,
            };
          }

          directTimeMax = Math.max(directTimeMax, ChangeDuration);
        });

        const routeDirectionCount = route.Segments.length - 1;

        const firstSegment = route.Segments[0];
        const lastSegment = route.Segments[(route.Segments.length - 1)];

        airports = updateAirports(airports, firstSegment, lastSegment, ind);

        if (!routesBorderTime[ind]) {
          routesBorderTime.push({
            departure: {
              border: {
                from: +firstSegment.DepartureTime,
                to: +firstSegment.DepartureTime,
              },
              from: +firstSegment.DepartureTime,
              to: +firstSegment.DepartureTime,
            },
            arrival: {
              border: {
                from: +lastSegment.ArrivalTime,
                to: +lastSegment.ArrivalTime,
              },
              from: +lastSegment.ArrivalTime,
              to: +lastSegment.ArrivalTime,
            },
          });
        } else {
          const borderItem = routesBorderTime[ind];

          const departureMin = Math.min(borderItem.departure.from, +firstSegment.DepartureTime);
          const departureMax = Math.max(borderItem.departure.to, +firstSegment.DepartureTime);
          const arrivalMin = Math.min(borderItem.arrival.from, +lastSegment.ArrivalTime);
          const arrivalMax = Math.max(borderItem.arrival.to, +lastSegment.ArrivalTime);

          routesBorderTime[ind] = {
            departure: {
              border: {
                from: departureMin,
                to: departureMax,
              },
              from: departureMin,
              to: departureMax,
            },
            arrival: {
              border: {
                from: arrivalMin,
                to: arrivalMax,
              },
              from: arrivalMin,
              to: arrivalMax,
            },
          };
        }

        if (!routesTravelTime[ind]) {
          routesTravelTime.push({
            duration: {
              border: {
                from: route.Duration,
                to: route.Duration,
              },
              from: route.Duration,
              to: route.Duration,
            },
          });
        } else {
          const borderItem = routesTravelTime[ind];

          const durationMin = Math.min(borderItem.duration.from, route.Duration);
          const durationMax = Math.max(borderItem.duration.to, route.Duration);

          routesTravelTime[ind] = {
            duration: {
              border: {
                from: durationMin,
                to: durationMax,
              },
              from: durationMin,
              to: durationMax,
            },
          };
        }

        if (!routeInfo[ind]) {
          routeInfo.push({
            from: firstSegment.DepartureCity,
            to: lastSegment.ArrivalCity,
            departureAirport: firstSegment.DepartureAirport,
            arrivalAirport: lastSegment.ArrivalAirport,
            departureTime: +firstSegment.DepartureTime,
            arrivalTime: +lastSegment.ArrivalTime,
            departureTimeSource: parseUnix(firstSegment.DepartureTime),
            arrivalTimeSource: parseUnix(lastSegment.ArrivalTime),
          });
        }

        return {
          ...route,
          DirectCount: routeDirectionCount,
          Selected: false,
        };
      });

      for (let i = 0; i < ticket.Fares.length; i++) {
        const price = ticket.Fares[i].Price.TotalPrice;
        min = Math.min(min, price);
        max = Math.max(max, price);
      }

      if (ticket.FavoriteId) {
        isAnyFavorite = true;
      }

      travelPolicyAllList.forEach((tp) => {
        if (tp.AirlineRule.Apply && !travelPolicyList.some((tpInList: string) => tpInList === tp.Id)) {
          travelPolicyList.push(tp.Id);
        }
      });

      if (ticket.TravelPolicy.Apply) {
        const travelPolicyErrors = Object.keys(ticket.TravelPolicy.Errors);

        travelPolicyErrors.forEach((tpE) => {
          if (travelPolicyList.length === 1) {
            travelPolicyList.push(tpE);
          } else if (travelPolicyList.length > 1) {
            const tpFind = travelPolicyList.filter((tp: string) => tp === tpE);

            if (!tpFind.length) {
              travelPolicyList.push(tpE);
            }
          }
        });
      }

      return {
        ...ticket,
        ChangeDuration: sum,
        Routes: ticketRoutes,
        FlightsNumbers: flightsNumbers,
      };
    });

    if (unavailableTravelPolicy && travelPolicyList.length > 1 && !!accountTravelPolicy) {
      const hasAccountTP = travelPolicyList.filter(tp => tp === accountTravelPolicy.Id);

      if (hasAccountTP.length > 0) {
        selectedTP = hasAccountTP[0];
      } else {
        travelPolicyList.splice(0, travelPolicyList.length);
      }
    }

    filters.price = {
      border: {
        from: min,
        to: max,
      },
      roundBy: 0,
      from: min,
      to: max,
    };
    filters.directTime = {
      border: {
        from: directTimeMin,
        to: directTimeMax,
      },
      roundBy: 0,
      from: directTimeMin,
      to: directTimeMax,
    };
    filters.airlines = airlines;
    filters.airports = airports;
    filters.transferAirports = transferAirports;
    filters.travelPolicyList = travelPolicyList;
    filters.directCount = {
      0: isDirect,
      1: false,
      2: false,
    };
    filters.refundable = EMPTYREFUNDABLEFILTER;
    filters.baggage = EMPTYBAGGAGEFILTER;
    filters.selectedTravelPolicy = selectedTP;
    filters.fewAirlineCompanies = false;
    filters.onlyAirline = false;
    filters.flightsNumbers = '';
    filters.routesBorderTime = routesBorderTime.map((borderItem) => {
      const time1 = parseUnix(borderItem.departure.from);
      const time2 = parseUnix(borderItem.departure.to);

      time1.startOf('d');
      time2.endOf('d');

      return {
        departure: {
          border: {
            from: time1.unix(),
            to: time2.unix(),
          },
          from: time1.unix(),
          to: time2.unix(),
        },
        arrival: {
          ...borderItem.arrival,
        },
      };
    });
    filters.routesTravelTime = routesTravelTime;
  }

  return {
    sources,
    filters: {
      ...stateFilters,
      ...filters,
    },
    routeInfo,
    isAnyFavorite,
  };
};

const createTags = (
  filters: AirFilters,
  travelPolicyAllList: TravelPolicyItem[],
  unavailableTravelPolicy: boolean,
) => {
  const tags = [];

  if (filters.price && (filters.price.border.from !== filters.price.from
    || filters.price.border.to !== filters.price.to)) {
    tags.push({
      name: TAGS_LABELS.priceFromTo(MoneyFormat.money(filters.price.from), MoneyFormat.money(filters.price.to)),
      key: AIRFILTERTYPE.PRICE,
      filter: AIRFILTERTYPE.PRICE,
    });
  }

  if (filters.directTime && (filters.directTime.border.from !== filters.directTime.from
    || filters.directTime.border.to !== filters.directTime.to)) {
    tags.push({
      name: TAGS_LABELS.transferTime(secondsToLabel(filters.directTime.from), secondsToLabel(filters.directTime.to)),
      key: AIRFILTERTYPE.DIRECTTIME,
      filter: AIRFILTERTYPE.DIRECTTIME,
    });
  }

  if (filters.directCount) {
    Object.keys(filters.directCount).forEach((key: string) => {
      if (filters.directCount[key]) {
        tags.push({
          name: key !== '0'
            ? TAGS_LABELS.transferCount(getAmountValueByKey(key))
            : TAGS_LABELS.withoutTransfers,
          filter: AIRFILTERTYPE.DIRECTCOUNT,
          qaAttr: `${QA_ATTRIBUTES.search.airline.directCount}-${key}`,
          key,
        });
      }
    });
  }

  if (filters.airlines) {
    (Object.keys(filters.airlines) as AirlinesFilterKeys[]).forEach((key: AirlinesFilterKeys) => {
      if (filters.airlines[key].selected) {
        tags.push({
          name: filters.airlines[key].name,
          filter: AIRFILTERTYPE.AIRLINES,
          key,
        });
      }
    });
  }

  if (filters.airports) {
    filters.airports.forEach((airport, index) => {
      const airportFrom = Object.keys(airport.from);
      const airportTo = Object.keys(airport.to);

      if (airportFrom.length > 1) {
        airportFrom.forEach((key) => {
          if (airport.from[key].selected) {
            tags.push({
              name: TAGS_LABELS.from(airport.from[key].name),
              filter: AIRFILTERTYPE.AIRPORTS,
              direct: 'from',
              key,
              index,
            });
          }
        });
      }

      if (airportTo.length > 1) {
        airportTo.forEach((key) => {
          if (airport.to[key].selected) {
            tags.push({
              name: TAGS_LABELS.to(airport.to[key].name),
              filter: AIRFILTERTYPE.AIRPORTS,
              direct: 'to',
              key,
              index,
            });
          }
        });
      }
    });
  }

  if (filters.routesBorderTime) {
    filters.routesBorderTime.forEach((border, index) => {
      const { arrival, departure } = border;

      if (departure.border.from !== departure.from
        || departure.border.to !== departure.to) {
        tags.push({
          name: TAGS_LABELS.departureFromTo(parseUnix(departure.from).format('HH:mm'), parseUnix(departure.to).format('HH:mm')),
          filter: AIRFILTERTYPE.ROUTESBORDERTIME,
          key: `departure_${index}`,
          field: 'departure',
          index,
        });
      }

      if (arrival.border.from !== arrival.from
        || arrival.border.to !== arrival.to) {
        tags.push({
          name: TAGS_LABELS.arrivalFromTo(parseUnix(arrival.from).format('HH:mm'), parseUnix(arrival.to).format('HH:mm')),
          filter: AIRFILTERTYPE.ROUTESBORDERTIME,
          key: `arrival_${index}`,
          field: 'arrival',
          index,
        });
      }
    });
  }

  if (filters.routesTravelTime) {
    filters.routesTravelTime.forEach((border, index) => {
      const { duration } = border;

      if (duration.border.from !== duration.from
        || duration.border.to !== duration.to) {
        tags.push({
          name: TAGS_LABELS.travelTime(secondsToLabel(duration.from), secondsToLabel(duration.to)),
          filter: AIRFILTERTYPE.ROUTE_TRAVEL_TIME,
          key: `${AIRFILTERTYPE.ROUTE_TRAVEL_TIME_FIELD}_${index}`,
          field: AIRFILTERTYPE.ROUTE_TRAVEL_TIME_FIELD,
          index,
        });
      }
    });
  }

  if (filters.flightNumber && filters.flightNumber.length) {
    tags.push({
      name: TAGS_LABELS.flightNumber(filters.flightNumber.join(' ')),
      key: AIRFILTERTYPE.FLIGHTNUMBER,
      filter: AIRFILTERTYPE.FLIGHTNUMBER,
    });
  }

  if (filters.favoriteId) {
    tags.push({
      name: TAGS_LABELS.favorite,
      key: AIRFILTERTYPE.FAVORITES,
      filter: AIRFILTERTYPE.FAVORITES,
    });
  }

  if (filters.refundable) {
    const selectedItems = Object.keys(filters.refundable)
      .filter(key => filters.refundable[key]);

    selectedItems.forEach((item, key) => {
      tags.push({
        name: TAGS_LABELS.refund(REFUNDABLEFILTERTAGS[item]),
        filter: AIRFILTERTYPE.REFUNDABLE,
        qaAttr: `${QA_ATTRIBUTES.search.airline.refundable}-${key}`,
        key: item,
      });
    });
  }

  if (filters.baggage) {
    const selectedItems = Object.keys(filters.baggage)
      .filter(key => filters.baggage[key]);

    selectedItems.forEach((item, key) => {
      tags.push({
        name: TAGS_LABELS.baggage(BAGGAGEFILTERTAGS[item]),
        filter: AIRFILTERTYPE.BAGGAGE,
        qaAttr: `${QA_ATTRIBUTES.search.airline.baggage}-${key}`,
        key: item,
      });
    });
  }

  if (filters.flightsNumbers) {
    tags.push({
      name: TAGS_LABELS.flightNumbers(filters.flightsNumbers),
      filter: AIRFILTERTYPE.FLIGHTSNUMBERS,
      key: AIRFILTERTYPE.FLIGHTSNUMBERS,
    });
  }

  if (filters.travelPolicyList && filters.travelPolicyList.length > 1 && filters.selectedTravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED) {
    const name = travelPolicyAllList.find(item => item.Id === filters.selectedTravelPolicy)?.Name;

    tags.push({
      name,
      filter: AIRFILTERTYPE.SELECTEDTRAVELPOLICY,
      key: AIRFILTERTYPE.SELECTEDTRAVELPOLICY,
      readOnly: unavailableTravelPolicy,
    });
  }

  if (filters.transferAirports) {
    Object.keys(filters.transferAirports).forEach((key) => {
      if (filters.transferAirports[key].selected) {
        tags.push({
          name: filters.transferAirports[key].name,
          filter: AIRFILTERTYPE.TRANSFER_AIRPORTS,
          key,
        });
      }
    });
  }

  return tags;
};

const reducer = (
  action: IAction,
  state: IAirlineState,
) => {
  switch (action.type) {
    case ACTION.CLEARSEARCH: {
      return {
        ...createNewState(),
      };
    }

    case ACTION.FINDAIRLINETICKET: {
      return state;
    }

    case ACTION.LOADAIRLINETICKET: {
      const { list, isDirect, settings } = action.payload;
      const { rightsBuyTrip, accountTravelPolicy, travelPolicyAllList } = settings;

      const paging = {
        ...state.paging,
        current: 1,
        total: list.Itineraries.length,
      };

      const unavailableTravelPolicy = rightsBuyTrip.BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unavailable &&
        rightsBuyTrip.BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unavailable;

      const mappedList = mapResponse(list);

      const { sources, filters, routeInfo, isAnyFavorite } = updateFiltersAndDataPreparation(
        mappedList.Trips as unknown as AirlineSource[],
        isDirect,
        state.filters.selectedTravelPolicy,
        unavailableTravelPolicy,
        accountTravelPolicy,
        travelPolicyAllList,
        state.filters,
      );

      const newFilters = {
        ...filters,
        // @ts-ignore
        routesSelected: {
          departure: [],
          airline: [],
          flight: [],
          arrival: [],
        },
        ...action.filters,
      };

      let items: AirlineSource[] = [];
      let cacheItems: AirlineSource[] = [];

      if (sources.length > 0) {
        cacheItems = applySort(applyFilter(sources, newFilters), 'recommended_down', newFilters);
        paging.total = cacheItems.length;
        items = getPagingView(cacheItems, paging);
      }

      return {
        ...state,
        sources,
        items,
        cacheItems,
        paging,
        filters: newFilters,
        favorite: action.favorite || null,
        routeInfo,
        isAnyFavorite,
        unavailableTravelPolicy,
        travelPolicyAllList,
        tags: createTags(newFilters, travelPolicyAllList, unavailableTravelPolicy),
        sourceId: mappedList.SearchId,
        loading: false,
        sortBy: 'recommended_down',
        numberOf: sources.length,
        temperatureScale: list.TemperatureScale,
      };
    }

    case ACTION.UPDATERESULTSORT: {
      const sortBy = action.payload as unknown as string;
      const paging = {
        ...state.paging,
        current: 1,
      };
      const cacheItems = applySort(applyFilter(state.cacheItems ?? [], state.filters), sortBy, state.filters);

      return {
        ...state,
        items: getPagingView(cacheItems, paging),
        cacheItems,
        sortBy,
        paging,
      };
    }
    case ACTION.PAGING: {
      const paging = {
        ...state.paging,
        current: action.payload,
      };

      return {
        ...state,
        items: getPagingView(state.cacheItems ?? [], paging as unknown as AirPaging),
        paging,
      };
    }

    case ACTION.UPDATEPRICEFILTER: {
      const filters = {
        ...state.filters,
        price: {
          ...state.filters.price,
          from: action.payload[0],
          to: action.payload[1],
        },
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);

      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }
    case ACTION.UPDATEDIRECTFILTER: {
      const filters = {
        ...state.filters,
        directCount: {
          ...state.filters.directCount,
          [action.payload.checkbox]: action.payload.value,
        },
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);

      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }
    case ACTION.UPDATEAIRLINEFILTER: {
      const airlines = state.filters.airlines;
      airlines[action.payload.checkbox as AirlinesFilterKeys].selected = action.payload.value;

      const airlinesKeys = Object.keys(airlines);

      const selectedAirlines = airlinesKeys.filter(item => airlines[item as AirlinesFilterKeys].selected);

      const fewAirlineCompanies = (selectedAirlines.length === 1 && action.payload.value) || state.filters.fewAirlineCompanies;

      const filters = {
        ...state.filters,
        airlines,
        onlyAirline: selectedAirlines.length > 0 && !fewAirlineCompanies,
        fewAirlineCompanies,
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);

      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }
    case ACTION.UPDATEFEWAIRLINECOMPNAIES: {
      const airlines = state.filters.airlines;

      const airlinesKeys = Object.keys(airlines);

      const selectedAirlines = airlinesKeys.filter(item => airlines[item as AirlinesFilterKeys].selected);

      const filters = {
        ...state.filters,
        airlines,
        onlyAirline: selectedAirlines.length < 1 ? false : !action.payload.value,
        fewAirlineCompanies: action.payload.value,
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);

      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }
    case ACTION.UPDATEREFUNDABLEFILTER: {
      const filters = state.filters;
      filters.refundable = { ...filters.refundable, [action.payload.checkbox]: action.payload.value };

      if (action.payload.checkbox === VALUETICKET.CHARGE) {
        filters.refundable.NotPenalty = action.payload.value;
      }

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);
      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);
      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }
    case ACTION.UPDATEBAGGAGEFILTER: {
      const filters = state.filters;
      filters.baggage = { ...filters.baggage, [action.payload.checkbox]: action.payload.value };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);
      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);
      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }
    case ACTION.UPDATEFLIGHTSNUMBERSFILTER: {
      const filters = state.filters;
      filters.flightsNumbers = action.payload.value;
      filters.airlinesDictionary = action.payload.airlines;

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);
      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);
      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }
    case ACTION.UPDATEAIRPORTFILTER: {
      const { index, field, value, checkbox } = action.payload;

      const airports = state.filters.airports;

      airports[index][field as keyof AirportsFilter][checkbox].selected = value;

      const filters = {
        ...state.filters,
        airports,
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);

      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }

    case ACTION.TRANSFER_AIRPORTS_FILTER: {
      const transferAirports = state.filters.transferAirports;
      transferAirports[action.payload.checkbox].selected = action.payload.value;

      const filters = {
        ...state.filters,
        transferAirports,
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);
      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }

    case ACTION.UPDATEDURATIONTIMEFILTER: {
      const filters = {
        ...state.filters,
        directTime: {
          ...state.filters.directTime,
          from: action.payload.min,
          to: action.payload.max,
        },
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);

      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }
    case ACTION.UPDATEBORDERTIMEFILTER: {
      const { field, ind, value } = action.payload;

      const routesBorderTime = state.filters.routesBorderTime;
      routesBorderTime[ind][field as keyof RoutesBorderTime].from = value[0];
      routesBorderTime[ind][field as keyof RoutesBorderTime].to = value[1];

      const filters = {
        ...state.filters,
        routesBorderTime,
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);

      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }

    case ACTION.UPDATE_TRAVEL_TIME_FILTER: {
      const { field, ind, value } = action.payload;

      const routesTravelTime = state.filters.routesTravelTime;
      routesTravelTime[ind][field as keyof RoutesTravelTime].from = value.min;
      routesTravelTime[ind][field as keyof RoutesTravelTime].to = value.max;

      const filters = {
        ...state.filters,
        routesTravelTime,
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);

      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }

    case ACTION.UPDATEROUTESFILTER: {
      const { value, route: { Segments } } = action.payload;

      const airlineType = getAirlineType(Segments[0]);

      const firstChangeSegmentTime = Segments[0].DepartureTime;
      const firstChangeSegmentAirline = Segments[0][airlineType].ID;
      const firstChangeSegmentFlight = Segments[0].FlightNumber;
      const lastChangeSegmentTime = Segments[Segments.length - 1].ArrivalTime;

      state.sources.map(({ Routes }) => Routes.map(({ Segments: stateSegments }, ind) => {
        const airlineTypeStateSegment = getAirlineType(Segments[0]);

        const firstSegmentTime = stateSegments[0].DepartureTime;
        const firstSegmentAirline = stateSegments[0][airlineTypeStateSegment].ID;
        const firstSegmentFlight = stateSegments[0].FlightNumber;
        const lastSegmentTime = stateSegments[stateSegments.length - 1].ArrivalTime;

        const select = Routes[ind];

        if (firstSegmentTime === firstChangeSegmentTime &&
          firstSegmentAirline === firstChangeSegmentAirline &&
          firstSegmentFlight === firstChangeSegmentFlight &&
          lastSegmentTime === lastChangeSegmentTime) {
          select.Selected = value;
        }

        return null;
      }));

      const stateRoutesSelected = state.filters.routesSelected;
      const indexOfDeparture = stateRoutesSelected.departure.indexOf(firstChangeSegmentTime);
      const indexOfAirline = stateRoutesSelected.airline.indexOf(firstChangeSegmentAirline);
      const indexOfFlight = stateRoutesSelected.flight.indexOf(firstChangeSegmentFlight);
      const indexOfArrival = stateRoutesSelected.arrival.indexOf(lastChangeSegmentTime);

      if (value) {
        stateRoutesSelected.departure.push(firstChangeSegmentTime);
        stateRoutesSelected.airline.push(firstChangeSegmentAirline);
        stateRoutesSelected.flight.push(firstChangeSegmentFlight);
        stateRoutesSelected.arrival.push(lastChangeSegmentTime);
      } else {
        stateRoutesSelected.departure.splice(indexOfDeparture, 1);
        stateRoutesSelected.airline.splice(indexOfAirline, 1);
        stateRoutesSelected.flight.splice(indexOfFlight, 1);
        stateRoutesSelected.arrival.splice(indexOfArrival, 1);
      }

      const filters = {
        ...state.filters,
        routesSelected: stateRoutesSelected,
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);

      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }

    case ACTION.UPDATEFAVORITEFILTER: {
      const filters = state.filters;
      filters.favoriteId = action.payload as unknown as boolean;

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);

      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }

    case ACTION.UPDATETRAVELPOLICYFILTER: {
      const { value } = action.payload;
      const filters = {
        ...state.filters,
        selectedTravelPolicy: value,
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);
      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }

    case ACTION.CHANGEFAVORITESTATE: {
      const { id, originalId, favoriteId } = action.payload;
      const { sources, filters, sortBy, paging } = state;

      const updatedSources = sources.map((item) => {
        if (item.Id === id) {
          const OriginalTrips = item.OriginalTrips.map((originalItem) => {
            if (originalItem.Id === originalId) {
              return {
                ...originalItem,
                FavoriteId: favoriteId,
              };
            }

            return originalItem;
          });

          return {
            ...item,
            OriginalTrips,
            FavoriteId: OriginalTrips.some(trip => !!trip.FavoriteId) ? 'favoriteId' : null,
          };
        }

        return item;
      });

      const isAnyFavorite = !!favoriteId || updatedSources.some(({ FavoriteId }) => !!FavoriteId);

      const cacheItems = applySort(applyFilter(updatedSources, filters), sortBy, filters);

      const updatedPaging = {
        ...paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, updatedPaging);

      return {
        ...state,
        isAnyFavorite,
        sources: updatedSources,
        cacheItems,
        items,
      };
    }

    case ACTION.DELETETAGS: {
      let value = null;

      if (action.payload.filter === AIRFILTERTYPE.PRICE
        || action.payload.filter === AIRFILTERTYPE.DIRECTTIME) {
        const filterValue = state.filters[action.payload.filter] as AirTime;
        value = {
          ...filterValue,
          from: filterValue.border.from,
          to: filterValue.border.to,
        };
      }

      if (action.payload.filter === AIRFILTERTYPE.ROUTESBORDERTIME) {
        const newBorderTime = state.filters.routesBorderTime;

        const index: number = action.payload.index;
        const field: keyof RoutesBorderTime = action.payload.field;
        newBorderTime[action.payload.index] = {
          ...newBorderTime[action.payload.index],
          [action.payload.field]: {
            ...newBorderTime[index][field],
            from: newBorderTime[index][field].border.from,
            to: newBorderTime[index][field].border.to,
          },
        };

        value = newBorderTime;
      }

      if (action.payload.filter === AIRFILTERTYPE.ROUTE_TRAVEL_TIME) {
        const newTravelTime = state.filters.routesTravelTime;
        const { index, field } = action.payload;
        newTravelTime[index] = {
          ...newTravelTime[index],
          [field]: {
            ...newTravelTime[index][field as keyof RoutesTravelTime],
            from: newTravelTime[index][field as keyof RoutesTravelTime].border.from,
            to: newTravelTime[index][field as keyof RoutesTravelTime].border.to,
          },
        };

        value = newTravelTime;
      }

      if (action.payload.filter === AIRFILTERTYPE.DIRECTCOUNT) {
        value = {
          ...state.filters.directCount,
          [action.payload.key]: false,
        };
      }

      if (action.payload.filter === AIRFILTERTYPE.AIRLINES) {
        value = {
          ...state.filters.airlines,
          [action.payload.key]: {
            ...state.filters.airlines[action.payload.key as AirlinesFilterKeys],
            selected: false,
          },
        };
      }

      if (action.payload.filter === AIRFILTERTYPE.TRANSFER_AIRPORTS) {
        value = {
          ...state.filters.transferAirports,
          [action.payload.key]: {
            ...state.filters.transferAirports[action.payload.key],
            selected: false,
          },
        };
      }

      if (action.payload.filter === AIRFILTERTYPE.AIRPORTS) {
        const newAirports: AirportsFilter[] = state.filters.airports;
        const index = action.payload.index;
        const direct = action.payload.direct as keyof AirportsFilter;
        newAirports[action.payload.index] = {
          ...newAirports[action.payload.index],
          [action.payload.direct]: {
            ...newAirports[index][direct],
            [action.payload.key]: {
              ...newAirports[index][direct][action.payload.key],
              selected: false,
            },
          },
        };
        value = newAirports;
      }

      if (action.payload.filter === AIRFILTERTYPE.FAVORITES) {
        value = false;
      }

      if (action.payload.filter === AIRFILTERTYPE.FLIGHTNUMBER) {
        value = [];
      }

      if (action.payload.filter === AIRFILTERTYPE.REFUNDABLE) {
        value = { [action.payload.key]: false };
      }

      if (action.payload.filter === AIRFILTERTYPE.BAGGAGE) {
        value = { [action.payload.key]: false };
      }

      if (action.payload.filter === AIRFILTERTYPE.FLIGHTSNUMBERS) {
        value = '';
      }

      if (action.payload.filter === AIRFILTERTYPE.SELECTEDTRAVELPOLICY) {
        if (!state.unavailableTravelPolicy) {
          value = TRAVELPOLICYFILTER.NOTAPPLIED;
        }
      }

      const filters = {
        ...state.filters,
        [action.payload.filter]: value,
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);

      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }

    case ACTION.RESETFILTERS: {
      const airlines = {} as AirlinesFilters;
      Object.keys(state.filters.airlines).forEach((field) => {
        airlines[field as keyof AirlinesFilters] = {
          ...state.filters.airlines[field as keyof AirlinesFilters],
          selected: false,
        };
      });

      const directCount: Record<string, boolean | undefined> = {};
      Object.keys(state.filters.directCount).forEach((field) => {
        directCount[field] = false;
      });

      const transferAirports: Record<string, FilterName> = {};
      Object.keys(state.filters.transferAirports).forEach((field) => {
        transferAirports[field] = {
          ...state.filters.transferAirports[field],
          selected: false,
        };
      });

      const routesBorderTime = state.filters.routesBorderTime.map(item => ({
        ...item,
        departure: {
          ...item.departure,
          from: item.departure.border.from,
          to: item.departure.border.to,
        },
        arrival: {
          ...item.arrival,
          from: item.arrival.border.from,
          to: item.arrival.border.to,
        },
      }));

      const routesTravelTime = state.filters.routesTravelTime.map(item => ({
        ...item,
        duration: {
          ...item.duration,
          from: item.duration.border.from,
          to: item.duration.border.to,
        },
      }));

      const airports: AirportsFilter[] = state.filters.airports.map((item) => {
        const from: Record<string, FilterName> = {};
        const to: Record<string, FilterName> = {};

        Object.keys(item.from).forEach((fromField) => {
          from[fromField] = {
            ...item.from[fromField],
            selected: false,
          };
        });

        Object.keys(item.to).forEach((toField) => {
          to[toField] = {
            ...item.to[toField],
            selected: false,
          };
        });

        return {
          from,
          to,
        };
      });

      let selectedTravelPolicy = state.filters.selectedTravelPolicy;

      if (!state.unavailableTravelPolicy) {
        selectedTravelPolicy = TRAVELPOLICYFILTER.NOTAPPLIED;
      }

      const filters = {
        price: {
          ...state.filters.price,
          from: state.filters.price.border.from,
          to: state.filters.price.border.to,
        },
        directTime: {
          ...state.filters.directTime,
          from: state.filters.directTime.border.from,
          to: state.filters.directTime.border.to,
        },
        airlines,
        directCount,
        routesBorderTime,
        routesTravelTime,
        airports,
        selectedTravelPolicy,
        baggage: EMPTYBAGGAGEFILTER,
        refundable: EMPTYREFUNDABLEFILTER,
        flightsNumbers: '',
        routesSelected: {
          ...state.filters.routesSelected,
        },
        transferAirports,
        flightNumber: [],
        onlyAirline: false,
        travelPolicyList: state.filters.travelPolicyList,
      };

      const cacheItems = applySort(applyFilter(state.sources, filters), state.sortBy, filters);

      const paging = {
        ...state.paging,
        current: 1,
        total: cacheItems.length,
      };

      const items = getPagingView(cacheItems, paging);

      const tags = createTags(filters, state.travelPolicyAllList, state.unavailableTravelPolicy);

      return {
        ...state,
        items,
        filters,
        paging,
        cacheItems,
        tags,
        numberOf: cacheItems.length,
      };
    }

    default: {
      return state;
    }
  }
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
