const METRICS_TYPES = {
  CART: {
    EMPLOYEE: {
      SET: 'SetEmployeeInCartItem',
      DELETE: 'DeleteEmployeeInCartItem',
    },
    PROJECT: {
      SET: 'SetProjectInCartItem',
      DELETE: 'DeleteProjectInCartItem',
    },
    CUSTOM_ANALYTICS_ITEM: {
      SET: 'SetCustomAnalyticsInCartItem',
      DELETE: 'DeleteCustomAnalyticsInCartItem',
    },
    TAGS: 'SetTagsInCartItem',
    LOADED: 'CartLoaded',
    NOTE: 'NoteInCartItem',
    DELETE_ITEM: 'DeleteCartItem',
  },
  TRIPS: {
    LOADED: 'TripPageLoaded',
  },
  CHARTS_ANALYTICS: {
    SMART_COMMON: 'CHARTS_ANALYTICS_SMART_COMMON',
  },
};

const METRICS_HOTEL_TYPES = {
  REGION_DISPLAY_PREPARATION: 'RegionDisplayPreparation',
  REGION_FIRST_DISPLAY: 'RegionFirstDisplay',
  REGION_COMPLETE_DISPLAY: 'RegionCompleteDisplay',

  HOTEL_DISPLAY: 'HotelDisplay', // Метрика на время, через которое клиент видит выдачу
  HOTEL_DISPLAY_PREPARATION: 'HotelDisplayPreparation', // Метрика время, которое фронт готовит рейты по отелю к отображению
};

export { METRICS_TYPES, METRICS_HOTEL_TYPES };
