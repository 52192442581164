import ACTION from './action';
import Store from '../../store';

import { IOrderDetailsData } from '../../types/order';
import { IOrderStore } from './types';
import { TRIPSTATUS } from '../../constants/trips';
import { SERVICETYPE } from '../../constants/serviceType';

const DEFAULT_STATE: IOrderStore = {
  loading: true,
  details: null,
  tripPlan: [{
    AdditionalInfo: [''],
    EventDate: null,
    EventType: '',
    EventTime: '',
    IsClosing: false,
    Notification: '',
    Name: null,
    Owner: '',
    ServiceType: '',
    OwnerId: null,
  }],
  bookflightCertificateInfo: {
    Success: true,
    Error: '',
    flightCertificateLoading: false,
  },
  refundAir: {
    Error: null,
    Success: false,
    Tickets: [],
    TotalPenaltySum: 0,
    TotalRefundSum: 0,
  },
  refundVipHall: {
    Success: false,
    Penalty: 0,
  },
  refundTransfer: {
    Success: false,
    Penalty: 0,
  },
  allFlightCertificateStatus: {},
  shownLink: false,
  disabledLink: false,
};

const isShownLink = (details: IOrderDetailsData[]) => details
  && details.every(({ Status }) => Status === TRIPSTATUS.BOOKED)
  && details.some(({ OrderItems }) => OrderItems.some(({ ActualVersion: { ServiceType } }) =>
    ServiceType !== SERVICETYPE.AEROEXPRESS &&
        ServiceType !== SERVICETYPE.CUSTOM &&
        ServiceType !== SERVICETYPE.TRANSFER &&
        ServiceType !== SERVICETYPE.VIP_HALL,
  ));

const reducer = (action: { [key: string]: any, type: string }, state: IOrderStore) => {
  switch (action.type) {
    case ACTION.ORDERLOAD: {
      return {
        ...state,
        loading: true,
      };
    }

    case ACTION.UPDATE: {
      return {
        ...state,
        loading: false,
        details: action.payload,
        shownLink: isShownLink([action.payload]),
      };
    }

    case ACTION.UPDATESHOWNLINK: {
      return {
        ...state,
        shownLink: isShownLink(action.payload),
      };
    }

    case ACTION.UPDATELINK: {
      return {
        ...state,
        disabledLink: action.payload,
      };
    }
    case ACTION.GET_FLIGHT_CERTIFICATE: {
      return {
        ...state,
        bookflightCertificateInfo: action.payload,
      };
    }

    case ACTION.GET_ALL_STATUS_FLIGHT_CERTIFICATE: {
      const { payload: { allFlightCertificateStatus } } = action;

      return {
        ...state,
        allFlightCertificateStatus,
      };
    }

    case ACTION.GET_REFUND_AIR: {
      const { payload } = action;

      return {
        ...state,
        refundAir: payload,
      };
    }

    case ACTION.GET_PLAN_TRIP_DETAILS: {
      return {
        ...state,
        tripPlan: action.payload,
      };
    }

    case ACTION.GET_REFUND_VIP: {
      const { payload } = action;

      return {
        ...state,
        refundVipHall: payload,
      };
    }

    case ACTION.GET_REFUND_TRANSFER: {
      const { payload } = action;

      return {
        ...state,
        refundTransfer: payload,
      };
    }

    case ACTION.RESET_STORE: {
      return {
        ...state,
        loading: true,
      };
    }
  }

  return state;
};

const createStore = () => new (Store as any)(reducer, DEFAULT_STATE);

export default createStore;
