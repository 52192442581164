import moment, { Moment } from 'moment';

import ACTION from '../action';
import Store from '../../../store';

import { AirlineHistoryItem, IDetail } from '../../../types/airline';

interface IAirlineHistoryAction {
  type: string;
  payload: IDetail[];
}

const createNewState = () => ({
  items: [] as AirlineHistoryItem[],
});

const reducer = (
  action: IAirlineHistoryAction,
  state: ReturnType<typeof createNewState>,
) => {
  const newState = {
    ...state,
  };

  switch (action.type) {
    case ACTION.LOADHISTORY: {
      newState.items = action.payload.map((item: IDetail) => {
        const locationData: string[] = [];
        const routeDate: Moment[] = [];

        item.Routes.forEach(({ ArrivalPlace, ArrivalName, DeparturePlace, DepartureName, Date }) => {
          // TODO: ВЫПИЛИТЬ ЭТО СО ВРЕМЕНЕМ И ОСТАВИТЬ ТОЛЬКО ArrivalPlace
          const arrivalName = ArrivalPlace || ArrivalName;
          const departureName = DeparturePlace || DepartureName;

          if (locationData.length === 0) {
            locationData.push(departureName);
          } else if (locationData[(locationData.length - 1)] !== departureName) {
            locationData.push(departureName);
          }

          locationData.push(arrivalName);

          routeDate.push(moment(Date));
        });

        return {
          label: locationData.join(' – '),
          details: item,
          dates: routeDate,
        };
      });

      break;
    }
  }

  return newState;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
