import React, { FC, useState } from 'react';
import { Dialog, Button, RadioButton, Datepicker, Text } from 'new-ui';
import moment, { Moment } from 'moment';

import { PATTERN } from '../../../../../bi/constants/dateFormats';
import { getText } from '../../../../../../i18n';

import { isSmartAgent } from '../../../../../bi/utils/env';
import { startOfMonth } from '../../../../../bi/utils/formatDate';

import styles from './index.module.css';

interface PaymentDetailsDialogProps {
  onDownload: (startPeriod: string | null, endPeriod: string | null, periodType: string) => void,
  onCloseDialog: () => void,
  show: boolean,
}

enum RADIO_VALUES {
  ALL_TIME = 'AllTime',
  LAST_PAYMENT = 'LastPayment',
  DATE_RANGE = 'DateRange',
}

const LABELS = {
  TITLE: getText('reports:operations.dialogPaymentDetails.title'),
  SUBTITLE: getText('reports:operations.dialogPaymentDetails.subtitle'),
  ALL_TIME: getText('reports:operations.dialogPaymentDetails.allTime'),
  AFTER_LAST_PAY: getText('reports:operations.dialogPaymentDetails.afterLastTime'),
  DOWNLOAD_XLS: getText('reports:operations.dialogPaymentDetails.xlsx'),
  CANCEL: getText('common:cancel'),
  FROM: getText('reports:operations.dialogPaymentDetails.from'),
  FROM_LOWER: getText('reports:operations.dialogPaymentDetails.fromLower'),
  BY: getText('reports:operations.dialogPaymentDetails.by'),
};

const PaymentDetailsDialog: FC<PaymentDetailsDialogProps> = ({
  onDownload,
  onCloseDialog,
  show,
}) => {
  const [radioField, setRadioField] = useState<RADIO_VALUES>(RADIO_VALUES.DATE_RANGE);
  const [startDate, setStartDate] = useState<Moment | null>(startOfMonth);
  const [endDate, setEndDate] = useState<Moment | null>(moment());

  const handleSwitchRadio = (value: RADIO_VALUES) => {
    setRadioField(value);
  };

  const handleDownload = () => {
    const periodRules = radioField === RADIO_VALUES.DATE_RANGE;

    const startPeriod = startDate && periodRules ? startDate.format(PATTERN.YEARMONTHDAY) : '';
    const endPeriod = endDate && periodRules ? endDate.format(PATTERN.YEARMONTHDAY) : '';

    onDownload(startPeriod, endPeriod, radioField);
  };

  return (
    <div>
      <Dialog
        show={ show }
        showClosing
        onChange={ onCloseDialog }
      >
        <div className={ styles.main }>
          <Text type='bold_20'>{ LABELS.TITLE }</Text>
          <Text className={ styles.subtitle }>{ LABELS.SUBTITLE }</Text>
          <div className={ styles.radios }>
            <div className={ styles.radio }>
              <RadioButton
                value={ RADIO_VALUES.ALL_TIME }
                checked={ radioField === RADIO_VALUES.ALL_TIME }
                alternativeDesign={ isSmartAgent }
                onChange={ () => handleSwitchRadio(RADIO_VALUES.ALL_TIME) }
              >
                <Text>{ LABELS.ALL_TIME }</Text>
              </RadioButton>
            </div>
            <div className={ styles.radio }>
              <RadioButton
                value={ RADIO_VALUES.LAST_PAYMENT }
                checked={ radioField === RADIO_VALUES.LAST_PAYMENT }
                alternativeDesign={ isSmartAgent }
                onChange={ () => handleSwitchRadio(RADIO_VALUES.LAST_PAYMENT) }
              >
                <Text>{ LABELS.AFTER_LAST_PAY }</Text>
              </RadioButton>
            </div>
            <div className={ styles.radio }>
              <RadioButton
                value={ RADIO_VALUES.DATE_RANGE }
                checked={ radioField === RADIO_VALUES.DATE_RANGE }
                alternativeDesign={ isSmartAgent }
                onChange={ () => handleSwitchRadio(RADIO_VALUES.DATE_RANGE) }
              >
                <Text>{ LABELS.FROM }</Text>
              </RadioButton>
              <div className={ styles.range }>
                <Datepicker
                  placeholder={ LABELS.FROM_LOWER }
                  value={ startDate }
                  max={ endDate }
                  wrapperClassName={ styles.datepicker }
                  onChange={ (value: Moment | null) => setStartDate(value) }
                  onFocus={ () => handleSwitchRadio(RADIO_VALUES.DATE_RANGE) }
                />
                <Text className={ styles.middleText }>{LABELS.BY}</Text>
                <Datepicker
                  placeholder={ LABELS.BY }
                  value={ endDate }
                  min={ startDate }
                  max={ moment() }
                  wrapperClassName={ styles.datepicker }
                  onChange={ (value: Moment | null) => setEndDate(value) }
                  onFocus={ () => handleSwitchRadio(RADIO_VALUES.DATE_RANGE) }
                />
              </div>
            </div>
          </div>
          <div className={ styles.actions }>
            <Button
              className={ styles.button }
              onClick={ handleDownload }
            >
              { LABELS.DOWNLOAD_XLS }
            </Button>
            <Button
              className={ styles.cancel }
              type='textual'
              onClick={ onCloseDialog }
            >
              { LABELS.CANCEL }
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export { PaymentDetailsDialog };
