import React from 'react';
import { IconButton, Text, Tooltip } from 'new-ui';

import { LABELS } from '../../index';

import styles from '../../../../index.module.css';

interface ITitleOfDocForSignatureProps {
  title: string;
}

export const TitleOfDocForSignature = ({ title }: ITitleOfDocForSignatureProps) => (
  <div className={ styles.signature_title }>
    { title }
    <Tooltip
      className={ styles.tooltip_wrapper }
      position='right'
      clickable={ false }
      renderContent={ () => (
        <Text
          className={ styles.tooltip_text }
          color='white'
          type='NORMAL_14'
        >
          { LABELS.TOOLTIP.HOW_IT_WORKS_FIRST }
          <br/>
          { LABELS.TOOLTIP.HOW_IT_WORKS_SECOND }
          <br/>
          <br/>
          { LABELS.TOOLTIP.HOW_IT_WORKS_THIRD }
          <br/>
          <li>{ LABELS.TOOLTIP.HOW_IT_WORKS_FOURTH }</li>
          <li>{ LABELS.TOOLTIP.HOW_IT_WORKS_FIFTH }</li>
          <li>{ LABELS.TOOLTIP.HOW_IT_WORKS_SIXTH }</li>
        </Text>
      ) }
    >
      <IconButton iconType='question' />
    </Tooltip>
  </div>
);
