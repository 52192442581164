import React from 'react';
import { Button, Dialog, Text } from 'new-ui';

import { useServices } from '../../../../../../bi/context/services';

import { LABELS } from '../../consts';

import { EnhancedSignatureModalTypes } from '../../../../../../bi/services/signature/types';

import styles from './styles.module.css';

const LabelMap = {
  [EnhancedSignatureModalTypes.docToEmailSent]: {
    title: () => LABELS.TO_EMAIL_SENT.SUCCESS_TITLE,
    description: () => LABELS.TO_EMAIL_SENT.SUCCESS_DESCRIPTION,
  },
  [EnhancedSignatureModalTypes.docToGovSent]: {
    title: (count: number) => LABELS.TO_GOV_SENT.SUCCESS_TITLE(count),
    description: (count: number) => LABELS.TO_GOV_SENT.SUCCESS_DESCRIPTION(count),
  },
};

interface IDocToEmailSent {
  modalType: keyof typeof LabelMap
}

export const DocToEmailSent = ({ modalType }: IDocToEmailSent) => {
  const { signatureService: { enhancedSignatureStore: { closeModal, selectedIds } } } = useServices(['Signature']);
  const size = selectedIds.length;

  const { title, description } = LabelMap[modalType];

  return (
    <Dialog
      show
      showClosing
      onChange={ closeModal }
      className={ styles.modalContent }
    >
      <div className={ styles.top }>
        <Text type='bold_20'>
          { title(size) }
        </Text>
        <Text>
          { description(size) }
        </Text>
      </div>
      <Button onClick={ closeModal } type='secondary'>
        { LABELS.TO_EMAIL_SENT.OK }
      </Button>
    </Dialog>
  );
};
