import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, IconType, Text } from 'new-ui';

import { isSmartAgent } from '../../../../bi/utils/env';

import styles from './styles.module.css';

interface AddingTripItemProps {
  title: string,
  url: string,
  type: IconType,
  searchUrl: string
  serviceType: string
  onClick?: (service: string) => void
}

const AddingTripItem = ({ title, url, type, searchUrl, serviceType, onClick }: AddingTripItemProps) => {
  const [target, setTarget] = useState(false);

  const handleSetTarget = (value: boolean) => setTarget(value);

  const color = (target ? 'accent' : 'default');

  return (
    <NavLink
      to={ `${url}${searchUrl}` }
      onMouseEnter={ () => handleSetTarget(true) }
      onMouseLeave={ () => handleSetTarget(false) }
      onClick={ () => onClick && onClick(serviceType) }
    >
      <div className={ styles.item }>
        <Icon type={ type } color={ color } alternativeDesign={ isSmartAgent }/>
        <Text color={ color }>{ title }</Text>
      </div>
    </NavLink>
  );
};

export { AddingTripItem };
