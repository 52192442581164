import React from 'react';

import { Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import { TravelPolicyRuleContainer } from '../TravelPolicyRuleContainer';
import { OnePriceRule } from '../OnePriceRule';
import { NotGreaterPriceRule } from '../NotGreaterPriceRule';
import { DaysCountRule } from '../DaysCountRule';
import { ClassRule } from '../ClassRule';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import {
  TRAVEL_POLICY_GENERAL_FIELDS_TO_BE_RENDERED,
  TRAVEL_POLICY_STORE_FIELDS,
} from '../../../../bi/services/travelPolicy/consts';

import { GeneralRulesProps } from './types';

import styles from './index.module.css';

const LABELS = {
  VAT_TEXT: getText('settings:travelPolicy.rules.general.description'),
  ONLY_REFUNDABLE: getText('settings:travelPolicy.rules.general.onlyRefundable'),
  ONLY_VAT: getText('settings:travelPolicy.rules.general.onlyVat'),
  PROHIBIT_BOOK: getText('services:travelPolicy.utils.text.prohibitBook'),
  DAY_OFF: {
    [SERVICETYPE.AIR]: getText('services:travelPolicy.utils.text.dayOff.air'),
    [SERVICETYPE.TRAIN]: getText('services:travelPolicy.utils.text.dayOff.train'),
    [SERVICETYPE.TRANSFER]: getText('services:travelPolicy.utils.text.dayOff.transfer'),
    [SERVICETYPE.HOTEL]: getText('services:travelPolicy.utils.text.dayOff.default'),
    [SERVICETYPE.TAXI]: getText('services:travelPolicy.utils.text.dayOff.taxi'),
  },
  INCLUDED_BAGGAGE: getText('services:travelPolicy.utils.text.includedBaggage'),
  ALLOW_BOOK: getText('services:travelPolicy.utils.text.allowBook'),
};

const GeneralRules = ({
  isUnique,
  store,
  type,
  shouldHideSapsan = false,
  onChange,
}: GeneralRulesProps) => {
  const prepareFieldsToBeRenderedCollection = () => {
    if (type !== SERVICETYPE.TRAIN) {
      return TRAVEL_POLICY_GENERAL_FIELDS_TO_BE_RENDERED[type];
    }

    return shouldHideSapsan
      ? TRAVEL_POLICY_GENERAL_FIELDS_TO_BE_RENDERED[type].slice(0, 4)
      : TRAVEL_POLICY_GENERAL_FIELDS_TO_BE_RENDERED[type];
  };

  const getRuleContentByField = (fieldType: string) => (field: string, storeFieldValue: any, onRender: (ref: HTMLElement | null) => void) => ({
    [`${TRAVEL_POLICY_STORE_FIELDS.ONE_WAY}`]: () => (
      <OnePriceRule
        isUnique={ !!isUnique }
        onChange={ (payload: any) => onChange({ [field]: { ...storeFieldValue, IsEnabled: true, ...payload } }) }
        type={ fieldType }
        value={ storeFieldValue }
        onRender={ onRender }
      />),
    [TRAVEL_POLICY_STORE_FIELDS.NOT_GREATER_PRICE]: () => (
      <NotGreaterPriceRule
        isUnique={ !!isUnique }
        type={ fieldType }
        value={ storeFieldValue }
        onChange={ (value: any) => onChange({ [field]: { ...storeFieldValue, ...value, IsEnabled: true } }) }
        onRender={ onRender }
      />),
    [TRAVEL_POLICY_STORE_FIELDS.DAYS_COUNT]: () => (
      <DaysCountRule
        isUnique={ !!isUnique }
        value={ storeFieldValue.Days }
        onChange={ (Days: number) => onChange({ [field]: { ...storeFieldValue, Days, IsEnabled: true } }) }
        onRender={ onRender }
      />),
    [TRAVEL_POLICY_STORE_FIELDS.CLASS]: () => (
      <ClassRule
        isUnique={ isUnique }
        type={ fieldType }
        value={ storeFieldValue.Value }
        isSapsan={ false }
        isTaxi
        onChange={ (Value: string) => onChange({ [field]: { ...storeFieldValue, Value, IsEnabled: true } }) }
      />),
    [TRAVEL_POLICY_STORE_FIELDS.SAPSAN_CLASS]: () => (
      <ClassRule
        isUnique={ isUnique }
        type={ fieldType }
        value={ storeFieldValue.Value }
        isSapsan
        isTaxi={ false }
        onChange={ (Value: string) => onChange({ [field]: { ...storeFieldValue, Value, IsEnabled: true } }) }
      />),
    [TRAVEL_POLICY_STORE_FIELDS.REFUNDABLE]: () => (<Text>{ LABELS.ONLY_REFUNDABLE }</Text>),
    [TRAVEL_POLICY_STORE_FIELDS.VAT]: () => (
      <div className={ styles.vat }>
        <Text className={ styles.text }>
          { LABELS.ONLY_VAT }
        </Text>
      </div>
    ),
    [TRAVEL_POLICY_STORE_FIELDS.DAY_OFF]: () => <Text>{ LABELS.DAY_OFF[fieldType] }</Text>,
    [TRAVEL_POLICY_STORE_FIELDS.PROHIBIT_BOOK]: () => <Text>{ LABELS.PROHIBIT_BOOK }</Text>,
    [TRAVEL_POLICY_STORE_FIELDS.INCLUDED_BAGGAGE]: () => <Text>{ LABELS.INCLUDED_BAGGAGE }</Text>,
    [TRAVEL_POLICY_STORE_FIELDS.ALLOW_BOOK]: () => <Text>{ LABELS.ALLOW_BOOK }</Text>,
  })[field]();

  const fieldRenderer = getRuleContentByField(type);

  const rulesContent = prepareFieldsToBeRenderedCollection().map(f => (
    <TravelPolicyRuleContainer
      isUnique={ !!isUnique }
      key={ f }
      taxiCheck={ store[f].ProhibitIsEnabled }
      value={ store[f].IsEnabled }
      onChange={ (value: boolean) => onChange({ [f]: { ...store[f], IsEnabled: value } }) }
      renderField={ onRender => fieldRenderer(f, store[f], onRender) }
    />),
  );

  return (
    <>
      { rulesContent }
    </>
  );
};

export { GeneralRules };
