import { NewHotelsStore, NewHotelsType } from './store';

class NewHotels {
  api: any;
  xhr: any | null;
  store: NewHotelsType;

  constructor(api: any) {
    this.api = api.hotels;
    this.xhr = null;
    this.store = NewHotelsStore;
  }

  updateActiveHotel = ({ id, active }: { id: number, active: boolean }) => {
    if (this.store.clusterInstance) {
      // @ts-ignore
      const obj = this.store.clusterInstance.getGeoObjects().find(({ properties }) => properties.get('hotelId') === id);

      if (obj) {
        const objectState = this.store.clusterInstance.getObjectState(obj);

        if (objectState.isClustered) {
          const activeCluster = this.store.clusterInstance.getClusters()
            // @ts-ignore
            .find(cluster => cluster.properties.get('geoObjects')
              // @ts-ignore
              .find(geoObject => geoObject.properties.get('hotelId') === id));
          activeCluster.events.fire(active ? 'mousemove' : 'mouseleave');

          return;
        }

        obj.properties.set({ active });
      }
    }
  };
}

export default NewHotels;
