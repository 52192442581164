import { PROPS, IconType } from 'new-ui';

import { ALL, FILTERS_KIND, PHANTOM_STATUS_ENUM } from '../constants/note';
import { SERVICETYPE } from '../constants/serviceType';

import { ApplyType } from '../services/notepad/types';

const {
  ICON: {
    TYPES: {
      NOTE: {
        GROUPE_OF_PEOPLE,
        PEOPLE_WITH_DOT,
        PEOPLE,
        TICKETS,
      },
      SEARCH: {
        AIR,
        HOTEL,
        TRAIN,
        TRANSFER,
      },
    },
  },
} = PROPS;

const applyType = ({ list, serviceFilter, noteFilter, userId, isFoul = false }: ApplyType) => {
  const applyNoteType = () => {
    if (noteFilter === ALL) return list;

    return list.filter(({ OwnerId, OriginalOwnerId, Status }) => {
      const condition = isFoul
        ? (noteFilter === OwnerId)
        : (noteFilter === OwnerId && (Status === PHANTOM_STATUS_ENUM.NORMAL));

      return ((noteFilter === userId && userId === OwnerId)
        || (OriginalOwnerId === userId && OriginalOwnerId === noteFilter)
        || condition
      );
    },
    );
  };

  const applyServiceType = () => {
    if (serviceFilter === ALL) return applyNoteType();

    return applyNoteType().filter(({ ServiceType }) => serviceFilter === ServiceType);
  };

  return applyServiceType();
};

const iconByNoteType = (value: string, userId: string): IconType => {
  switch (value) {
    case ALL:
      return GROUPE_OF_PEOPLE as IconType;

    case userId:
      return PEOPLE_WITH_DOT as IconType;

    default:
      return PEOPLE as IconType;
  }
};

const iconByServiceType = (value: string): IconType | null => {
  switch (value) {
    case ALL:
      return TICKETS as IconType;

    case SERVICETYPE.AIR:
      return AIR as IconType;

    case SERVICETYPE.HOTEL:
      return HOTEL as IconType;

    case SERVICETYPE.TRANSFER:
      return TRANSFER as IconType;

    case SERVICETYPE.TRAIN:
      return TRAIN as IconType;

    default:
      return null;
  }
};

const preparedFiltersType = (type: string, user: string) => {
  if (type === ALL) {
    return FILTERS_KIND.ALL;
  }

  if (type === user) {
    return FILTERS_KIND.OWN;
  }

  return FILTERS_KIND.ANOTHER;
};

export {
  applyType,
  iconByNoteType,
  iconByServiceType,
  preparedFiltersType,
};
