import React from 'react';

import styles from './index.module.css';

const SEX: Record<string, string[]> = {
  Ж: ['woman'],
  М: ['man'],
  С: ['man', 'woman'],
  Ц: [],
};
const ICON_HEIGHT: number = 19;

interface ISexIcon {
  litter: keyof typeof SEX,
  height: number,
}

const SexIcon: React.FC<ISexIcon> = ({ litter = '', height = 0 }) => {
  const icons = SEX[litter];

  if (!icons) return null;

  const indent = icons.length > 1 ? ICON_HEIGHT : ICON_HEIGHT / 2;
  const top = (height / 2) - indent;

  const wrapStyles = { top };

  const html = icons.map((icon, ind) => <div key={ ind } className={ styles[icon] } />);

  return (
    <div style={ wrapStyles } className={ styles.sex }>
      { html }
    </div>
  );
};

export { SexIcon };
