import React, { useState } from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../../../../i18n';

import styles from './index.module.css';
import { RequestInterfaceKeys } from '../../../../../bi/types/applicationScheme';

const LABELS = {
  REQUIRED_FIELDS: getText('approvalScheme:dropZone.requiredFields'),
  MOVE_REQUIRED_FIELDS: getText('approvalScheme:dropZone.moveRequiredFields'),
  RELEASE_FIELDS: getText('approvalScheme:dropZone.releaseField'),
};

interface DropZoneProps {
  renderFields: React.ReactNode;
  onDrop: () => void;
  isDragged: boolean,
  draggableItem: RequestInterfaceKeys | null;
}

const DropZone = ({ renderFields, onDrop, isDragged, draggableItem }: DropZoneProps) => {
  const [canBeDropped, setCanBeDropped] = useState(false);
  const dropZoneStyles = [styles['drop-zone']];

  const handleDragProps = {
    onDragEnter: () => setCanBeDropped(true),
    onDrop: () => {
      setCanBeDropped(false);
      onDrop();
    },
    onDragOver: (e: React.MouseEvent) => e.preventDefault(),
  };

  const renderBlurDroppableZone = () => {
    if (canBeDropped && draggableItem && !draggableItem) {
      return (
        <div
          className={ styles['droppable-panel'] }
          onDragLeave={ () => setCanBeDropped(false) }
          onDragOver={ e => e.preventDefault() }
          onDrop={ onDrop }
        >
          <Text type='normal_20'>{ LABELS.RELEASE_FIELDS }</Text>
        </div>
      );
    }

    return null;
  };

  if (isDragged) dropZoneStyles.push(styles.droppable);

  return (
    <div className={ dropZoneStyles.join(' ') } { ...handleDragProps }>
      { renderBlurDroppableZone() }
      <Text type='NORMAL_16' >
        { LABELS.REQUIRED_FIELDS }
      </Text>
      { renderFields }
      <Text type='NORMAL_16' className={ styles.row }>
        { LABELS.MOVE_REQUIRED_FIELDS }
      </Text>
    </div>
  );
};

export { DropZone };
