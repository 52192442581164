import { Text, NoResults } from 'new-ui';
import React from 'react';

import { getText } from '../../../../../i18n';

import styles from './styles.module.css';

const LABELS = {
  NO_LIST_HEADER: getText('chartsAnalytics:noListHeader'),
  NO_LIST_SUB: getText('chartsAnalytics:noListSubtext'),
};

const ChartAnalyticsNoResult = () => (
  <NoResults renderContent={ () => (
    <div className={ styles.no_results }>
      <Text type='bold_18'>
        { LABELS.NO_LIST_HEADER }
      </Text>
      <Text className={ styles.subtext }>
        { LABELS.NO_LIST_SUB }
      </Text>
    </div>
  ) }
  />
);

export { ChartAnalyticsNoResult };
