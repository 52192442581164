import moment from 'moment';
import { getText } from '../../../../i18n';

import Store from '../../store';
import ACTION from './action';

import trimTimeZone from '../../utils/trimTimezone';
import { getAirlineType } from '../../utils/airline';
import { applyType } from '../../utils/note';
import { isSmartAgent } from '../../utils/env';

import { SERVICETYPE, SERVICETYPERUMN } from '../../constants/serviceType';
import { ALL, PHANTOM_STATUS_ENUM } from '../../constants/note';
import { IHotelData, INoteItem, INotepadStore, IPrepareNoteItem, IResponseGetNotes } from './types';

const LABELS = {
  ALL: getText('services:notepad.all'),
  ALL_SERVICES: getText('services:notepad.allServices'),
  ONLY_MY: getText('services:notepad.onlyMy'),
  ALL_EMPLOYEES: getText('services:notepad.allEmployees'),
  ALL_USERS: getText('services:notepad.allUsers'),
};

const template = (label: string, value: any) => ({ label, value });

const updateFilter = (list: INoteItem[]) => {
  const filter: { [key: string]: boolean } = {};
  const labelText = isSmartAgent
    ? LABELS.ALL_SERVICES
    : LABELS.ALL;

  list.forEach((item) => {
    filter[item.ServiceType] = true;
  });

  const tabs = Object.keys(filter).map((item) => (template(SERVICETYPERUMN[item], item)));

  tabs.unshift(template(labelText, ALL));

  return {
    current: ALL,
    tabs,
  };
};

const updateNoteFilter = (list: INoteItem[], employeeId: string) => {
  const allText = isSmartAgent ? LABELS.ALL_USERS : LABELS.ALL_EMPLOYEES;
  const employees = [
    template(LABELS.ONLY_MY, employeeId),
    template(allText, ALL),
  ];

  list.forEach(({ Owner: { RegistrationName, Id }, OriginalOwnerId }) => {
    const newEmployee = template(RegistrationName, Id);
    const flag = employees.every(({ value }) => (value !== newEmployee.value && OriginalOwnerId === Id));

    if (flag) {
      employees.push(newEmployee);
    }
  });

  return {
    current: employeeId,
    tabs: employees,
  };
};

const splitNote = ({ Items, ServerTime }: IResponseGetNotes) => {
  const sources: INoteItem[] = [];
  const fouls: INoteItem[] = []; // тухлые брони

  const currentMoment = moment(ServerTime);

  Items.forEach((item) => {
    if (item.IsReserved && item.BookDeadline && moment(trimTimeZone(item.BookDeadline)).isBefore(currentMoment)) {
      fouls.push(item);
    } else {
      sources.push(item);
    }
  });

  return { fouls, sources };
};

const applySearch = (
  list: INoteItem[],
  searchValue: string,
  serviceType: string = ALL,
  noteType: string,
) => list.filter(({ Data, Employees, ServiceType, OwnerId, OriginalOwnerId }) => {
  if (serviceType !== ServiceType && serviceType !== ALL) return false;

  if ((noteType !== OriginalOwnerId && noteType !== OwnerId) && noteType !== ALL) return false;

  const data: IHotelData | any = JSON.parse(Data);
  const value = searchValue.trim().toLowerCase();
  const result = [];

  Employees.forEach(({ Employee: { Name, Surname, Patronymic } }) => {
    result.push(Name.toLowerCase());
    result.push(Surname.toLowerCase());

    if (Patronymic.length) {
      result.push(Patronymic.toLowerCase());
    }
  });

  switch (ServiceType) {
    case SERVICETYPE.AIR: {
      // @ts-ignore
      data.Routes.forEach(({ Segments }) => {
        result.push(Segments[0].DepartureCity.toLowerCase());
        result.push(Segments[Segments.length - 1].ArrivalCity.toLowerCase());
        // @ts-ignore
        Segments.forEach((segment) => {
          const airlineType = getAirlineType(segment);

          const segmentName = segment[airlineType].Name ?? '';

          result.push(segmentName.toLowerCase());
        });
      });
      break;
    }
    case SERVICETYPE.TRANSFER: {
      result.push(data.StartPlace.Address.toLowerCase());
      result.push(data.EndPlace.Address.toLowerCase());
      break;
    }
    case SERVICETYPE.HOTEL: {
      result.push(data.hotel.City.toLowerCase());
      result.push(data.hotel.Name.toLowerCase());
      break;
    }
    case SERVICETYPE.TRAIN: {
      result.push(data.From.City.toLowerCase());
      result.push(data.To.City.toLowerCase());
      break;
    }
  }

  return result.some((el) => el.includes(value));
});

const applySelect = (items: IPrepareNoteItem[], { item, selected }: { item: IPrepareNoteItem, selected: boolean }) => {
  const currentItem = items.find((i) => i.Id === item.Id);

  if (currentItem) {
    currentItem.Selected = selected;
  }

  return items;
};

const applySelectMulti = (
  list: IPrepareNoteItem[],
  { itemIds, selected }: { itemIds: any[], selected: boolean },
) =>
  list.map((item) => {
    if (itemIds.some((id) => id === item.Id)) {
      return {
        ...item,
        Selected: selected,
      };
    }

    return item;
  });

const applySelectAll = (list: IPrepareNoteItem[], value: boolean) => list.map((item) => (item.Status === 0
  ? { ...item, Selected: value }
  : item),
);

const initStore: INotepadStore = {
  loading: false,
  userId: null,
  sources: [],
  employeeId: '',
  items: [],
  fouls: [],
  sourcesFouls: [],
  foulDialogShow: null,
  tripsByTripIds: [],
  serverTime: null,
  filters: {
    query: '',
    selectedAll: false,
    selectedCount: 0,
    type: {
      current: null,
      tabs: [],
    },
    noteType: {
      current: null,
      tabs: [],
    },
  },
};

const reducer = (action: { [key: string]: any }, state: INotepadStore) => {
  const newState = {
    ...state,
  };

  switch (action.type) {
    case ACTION.NOTELOAD: {
      newState.loading = true;

      break;
    }

    case ACTION.NOTE_USER_ID: {
      newState.userId = action.payload;

      break;
    }

    case ACTION.UPDATE_TRIP_BY_IDS: {
      newState.tripsByTripIds = action.payload;

      break;
    }

    case ACTION.NOTEUPDATE: {
      const { fouls, sources } = splitNote(action.payload);

      newState.sources = sources;
      newState.loading = false;
      newState.employeeId = action.employeeId;
      newState.filters.type = updateFilter(newState.sources);
      newState.filters.noteType = updateNoteFilter(newState.sources, action.employeeId);
      newState.items = applyType({
        list: newState.sources,
        serviceFilter: newState.filters.type.current as string,
        noteFilter: newState.filters.noteType.current as string,
        userId: action.employeeId,
      });
      newState.sourcesFouls = fouls;
      newState.fouls = applyType({
        list: fouls,
        serviceFilter: newState.filters.type.current as string,
        noteFilter: newState.filters.noteType.current as string,
        userId: action.employeeId,
        isFoul: true,
      });
      newState.filters.selectedCount = 0;
      newState.serverTime = moment(action.payload.ServerTime);

      break;
    }

    case ACTION.UPDATEFOUL: {
      newState.items = state.items.filter(i => i.Id !== action.payload.Id);
      newState.fouls.push(action.payload);
      newState.foulDialogShow = action.payload;

      break;
    }

    case ACTION.CLOSEDIALOG: {
      newState.foulDialogShow = action.payload;

      break;
    }

    case ACTION.NOTETYPEFILTERUPDATE: {
      newState.filters.noteType.current = action.noteType;
      newState.filters.type.current = action.tripType;
      newState.items = action.list;
      newState.fouls = action.foulsList;

      break;
    }

    case ACTION.SEARCHUPDATE: {
      newState.filters.query = action.payload.searchValue;
      newState.items = applySearch(
        newState.sources,
        newState.filters.query,
        action.payload.serviceType,
        newState.filters.noteType.current as string,
      );
      newState.filters.selectedCount = newState.items.length;

      break;
    }

    case ACTION.SELECTUPDATE: {
      newState.items = applySelect(newState.items, action.payload);

      const selectedCount = newState.items.filter((item) => item.Selected).length;
      newState.filters.selectedAll = selectedCount === newState.items.filter(({ Status }) =>
        Status === PHANTOM_STATUS_ENUM.NORMAL).length;
      newState.filters.selectedCount = selectedCount;

      break;
    }

    case ACTION.SELECT_MULTI_UPDATE: {
      newState.items = applySelectMulti(newState.items, action.payload);

      const selectedCount = newState.items.filter(item => item.Selected).length;
      newState.filters.selectedAll = selectedCount === newState.items.length;
      newState.filters.selectedCount = selectedCount;

      break;
    }

    case ACTION.UPDATE_TAGS: {
      newState.sources = newState.sources.map(trip => {
        if (trip.Id === action.payload.Id) return { ...trip, Tags: action.payload.Tags };

        return trip;
      });

      break;
    }

    case ACTION.FILTERCLEAR: {
      newState.filters.selectedAll = false;
      newState.filters.query = '';
      newState.filters.type = updateFilter(newState.sources);
      newState.filters.noteType = updateNoteFilter(newState.sources, action.employeeId);
      newState.items = applyType({
        list: newState.sources,
        serviceFilter: newState.filters.type.current as string,
        noteFilter: newState.filters.noteType.current as string,
        userId: action.employeeId,
      });
      newState.fouls = applyType({
        list: newState.sourcesFouls,
        serviceFilter: newState.filters.type.current as string,
        noteFilter: newState.filters.noteType.current as string,
        userId: action.employeeId,
        isFoul: true,
      });

      break;
    }

    case ACTION.SELECTALLUPDATE: {
      newState.filters.selectedAll = action.payload;
      newState.items = applySelectAll(newState.items, newState.filters.selectedAll);
      newState.filters.selectedCount = newState.filters.selectedAll
        ? newState.items.filter(item => item.Selected).length
        : 0;

      break;
    }

    case ACTION.UPDATE_RULES: {
      const { itemId, rules } = action.payload;

      const currentItem: INoteItem | undefined = newState.sources.find(({ Id }) => Id === itemId);

      if (currentItem) {
        // @ts-ignore
        currentItem.CancellationInfo = rules;
      }

      break;
    }
  }

  return newState;
};

const createStore = () => new (Store as any)(reducer, initStore);

export default createStore;
