import React, { FC } from 'react';
import { Button, Text, NoResults } from 'new-ui';
import { getText } from '../../../../../i18n';

import { isSmartAgent } from '../../../../bi/utils/env';

import styles from './index.module.css';

const LABELS = {
  NOT_FOUND: getText('note:result.notFound'),
  CHANGE_FILTERS: getText('note:result.changeParamsOfFilters'),
  RESET_FILTERS: getText('note:result.resetFilters'),
};

interface FilterEmptyPanelProps {
  onResetAllFilters(): void,
}

const FilterEmptyPanel: FC<FilterEmptyPanelProps> = ({ onResetAllFilters }) => {
  const classNames = !isSmartAgent ? styles.indent : null;

  return (
    <div className={ classNames }>
      <NoResults
        renderContent={ () => (
          <div className={ styles.no_results }>
            <Text type='bold_18'>
              { LABELS.NOT_FOUND }
            </Text>
            <Text className={ styles.subtext }>
              { LABELS.CHANGE_FILTERS }
            </Text>
            <Button
              type='primary-outline'
              onClick={ onResetAllFilters }
            >
              { LABELS.RESET_FILTERS}
            </Button>
          </div>
        ) }
      />
    </div>
  );
};

export default FilterEmptyPanel;
