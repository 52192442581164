import React, { useRef, memo, useEffect } from 'react';
import { RadioButton, Text } from 'new-ui';
import { getText, getTextArray } from '../../../../../i18n';

import { NumberInput } from '../../../../components/NumberInput';

import { isSmartAgent } from '../../../../bi/utils/env';
import toDecline from '../../../../bi/utils/toDecline';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { TRAVEL_POLICY_FIELDS_DICTIONARIES } from '../../../../bi/services/travelPolicy/consts';

import { ExceptionTypesProps } from './types';

import styles from './index.module.css';

const LABELS = {
  HOURS_DECLINES: getTextArray('settings:travelPolicy.rules.exceptions.hoursDeclines'),
  LONGER: getText('settings:travelPolicy.rules.exceptions.longer'),
  TO_POINTS: getText('settings:travelPolicy.rules.exceptions.toPoints'),
  IN_THESE_CITIES: getText('settings:travelPolicy.rules.exceptions.inCities'),
  IN_THESE_COUNTRIES: getText('settings:travelPolicy.rules.exceptions.inCountries'),
};

const getDeclinedHoursText = (value: number) => toDecline(value, LABELS.HOURS_DECLINES);

const ExceptionTypes = ({
  value,
  type,
  onChange,
  showValidation,
  autoFocusField,
}: ExceptionTypesProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { Type, Hours } = value;

  useEffect(() => {
    if (inputRef?.current && autoFocusField) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 250);
    }
  }, [autoFocusField]);

  const renderContent = (service: string) => {
    switch (service) {
      case SERVICETYPE.AIR: {
        const inputClassNames = [];

        if (showValidation && !Number(Hours)) {
          inputClassNames.push(styles.error);
        }

        return (
          <>
            <RadioButton
              className={ styles.radio }
              alternativeDesign={ isSmartAgent }
              checked={ Type === TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.HOURS }
              onChange={ () => {
                onChange({ Type: TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.HOURS });

                if (inputRef.current) {
                  inputRef.current.focus();
                }
              } }
            >
              <div className={ styles.subcontent }>
                <Text>{ LABELS.LONGER } &nbsp;</Text>
                <NumberInput
                  onChange={ hrs => onChange({
                    Hours: hrs,
                    Type: TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.HOURS,
                  }) }
                  value={ Hours }
                  onRender={ ref => {
                    inputRef.current = ref;
                  } }
                  className={ inputClassNames.join(' ') }
                />
                &nbsp;
                { getDeclinedHoursText(Number(Hours)) }
              </div>
            </RadioButton>
            <RadioButton
              className={ styles.radio }
              alternativeDesign={ isSmartAgent }
              checked={ Type === TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.ROUTES }
              onChange={ () => onChange({ Type: TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.ROUTES }) }
            >
              { LABELS.TO_POINTS }
            </RadioButton>
          </>
        );
      }
      case SERVICETYPE.HOTEL:
        return (
          <>
            <RadioButton
              className={ styles.radio }
              alternativeDesign={ isSmartAgent }
              checked={ Type === TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.COUNTRIES }
              onChange={ () => onChange({ Type: TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.COUNTRIES }) }
            >
              { LABELS.IN_THESE_COUNTRIES }
            </RadioButton>
            <RadioButton
              className={ styles.radio }
              alternativeDesign={ isSmartAgent }
              checked={ Type === TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.ROUTES }
              onChange={ () => onChange({ Type: TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.ROUTES }) }
            >
              { LABELS.IN_THESE_CITIES }
            </RadioButton>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={ styles.wrap }>
      { renderContent(type) }
    </div>
  );
};

const propsAreEqual = (cProps: ExceptionTypesProps, nProps: ExceptionTypesProps) => {
  const { value: { Type: cType, Hours: cHours }, showValidation: cShowValidation } = cProps;
  const { value: { Type: nType, Hours: nHours }, showValidation: nShowValidation } = nProps;

  return !(nType !== cType || nHours !== cHours || nShowValidation !== cShowValidation);
};

const MemoizedExceptionTypes = memo(ExceptionTypes, propsAreEqual);

export { MemoizedExceptionTypes };
