import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { getFormattedOrganization } from '../../../../bi/utils/minimalFares';
import { getImageLogo } from '../../../../bi/utils/airline';

import { AdditionalType } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  NOT_OFFERED: getText('constants:airline.baggage.notOffered'),
  INCLUDED: getText('constants:airline.baggage.included'),
  CHARGE: getText('constants:airline.baggage.charge'),
  CLASS: getText('components:fareDetails.class'),
  ECONOM: getText('constants:airline.flyClass.econom'),
  BUSINESS: getText('constants:airline.flyClass.business'),
  FIRST: getText('constants:airline.flyClass.first'),
};

const ALT_TYPE = 'SU';

enum BaggageOptions {
  Included = 'Included',
  NotDefined = 'NotDefined',
  Charge = 'Charge',
  NotOffered = 'NotOffered',
  NotPenalty = 'NotPenalty',
}

enum FlightClasses {
  Econom = 'Econom',
  Business = 'Business',
  First = 'First',
}

const baggageLabels: Record<BaggageOptions, string> = {
  [BaggageOptions.Included]: LABELS.INCLUDED,
  [BaggageOptions.NotDefined]: '',
  [BaggageOptions.Charge]: LABELS.CHARGE,
  [BaggageOptions.NotOffered]: LABELS.NOT_OFFERED,
  [BaggageOptions.NotPenalty]: '',
};

const flightClassLabels: Record<FlightClasses, string> = {
  [FlightClasses.Econom]: LABELS.ECONOM,
  [FlightClasses.Business]: LABELS.BUSINESS,
  [FlightClasses.First]: LABELS.FIRST,
};

const prepareBaggage = (value: BaggageOptions) => baggageLabels[value] || '';

const prepareClasses = (value: FlightClasses) => flightClassLabels[value] || '';

const AirlineInfoComponent = ({
  organization,
  flightClass,
  isBaggageIncluded,
}: AdditionalType) => (
  <div className={ styles.airline_item }>
    <div className={ styles.flight_info_item_image }>
      <Text
        color='gray'
        className={ styles.flight_info_item }
      >
        { getFormattedOrganization(organization) }
      </Text>
      <img
        className={ styles.airline_item_image }
        src={ getImageLogo(organization.Code) }
        alt={ ALT_TYPE }
      />
    </div>
    <Text
      color='gray'
      className={ styles.flight_info_item }
    >
      { LABELS.CLASS }: { prepareClasses(flightClass as FlightClasses) }
    </Text>
    <Text
      color='gray'
      className={ styles.flight_info_item }
    >
      { prepareBaggage(isBaggageIncluded as BaggageOptions) }
    </Text>
  </div>
);

export default AirlineInfoComponent;
