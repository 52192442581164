import React, { useState, MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Moment } from 'moment';
import {
  Button,
  Datepicker,
  Icon,
  Input,
  Text,
  Checkbox,
  Price as PriceComponent,
  ItemPanel,
} from 'new-ui';

import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import ItemLayout from '../../../../components/ItemLayout';
import TaxiProviders from '../../../../components/TaxiProviders';
import TaxiDetailsPrice from '../../../../components/TaxiDetailsPrice';

import { onlyFractionalNumbersOrEmpty, onlyNumbersOrEmpty } from '../../../../bi/utils/text';
import { valueToNumber } from '../../../../bi/utils/taxi';
import { MainAnalytic } from '../../../../bi/utils/analytics';
import MoneyFormat from '../../../../bi/utils/money';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { PATTERN } from '../../../../bi/constants/dateFormats';
import { ACTIVE_PROVIDERS, AVAILABLE_CLASSES } from '../../../../bi/constants/taxi';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import TaxiService from '../../../../bi/services/taxi';

import styles from './styles/voucher.module.css';

const LABELS = {
  AMOUNT: getText('taxi:voucher.amount'),
  COUNT: getText('taxi:voucher.count'),
  PERIOD_FROM: getText('taxi:voucher.periodFrom'),
  PERIOD_TO: getText('taxi:voucher.periodTo'),
  AVAILABLE_CLASS: getText('taxi:voucher.availableClass'),
  VOUCHER_INFO: getText('taxi:voucher.voucherInfo'),
  ONLY_IN_RF: getText('taxi:voucher.onlyInRussia'),
  SUPPORT: getText('taxi:voucher.support'),
  TO_CART: getText('taxi:voucher.toCart'),
  MSK_TIME: getText('taxi:voucher.mskTime'),
  TOOLTIP_ONE: getText('taxi:voucher.tooltip.one'),
  TOOLTIP_TWO: getText('taxi:voucher.tooltip.two'),
  ERROR_SUM: getText('taxi:voucher.errorMsg'),
  ERROR_COUNT: getText('taxi:voucher.errorCount'),
  RUB: getText('taxi:voucher.rub'),
  VAT: getText('taxi:voucher.vat'),
};

interface VoucherProps {
  taxiService: TaxiService,
  onAddToCart(): void,
}

const Voucher = observer(({
  taxiService: {
    setDate,
    setDateTo,
    setAmount,
    setVAT,
    setCount,
    setAvailableClasses,
  },
  onAddToCart,
}: VoucherProps) => {
  const {
    taxiStore: {
      voucher: {
        amount,
        vat,
        count,
        date,
        dateTo,
        minDate,
        minDateTo,
        availableClasses,
      },
      voucherIsValid,
    },
  } = useStores([MOBX_STORES.TAXI_STORE]);

  const [dateOpen, setDateOpen] = useState<boolean>(false);
  const [dateToOpen, setDateToOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleDateChangeAmp = <Fn extends (v: any) => void>(func: Fn, value: Parameters<Fn>[0]) => {
    func(value);

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_TAXI_DATE_CHOSEN(value.format()),
    );
  };

  const handleChangeInput = (value: string) => {
    const roundValue = Math.trunc(valueToNumber(value));

    setAmount(roundValue);
    setVAT(roundValue);
  };

  const handleChangeCount = (value: string) => setCount(valueToNumber(value));

  const handleAddToCart = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    setLoading(true);
    await onAddToCart();
    setLoading(false);
  };

  const renderActions = () => {
    const fullPrice = Number(amount) + Number(vat);

    return (
      <div className={ styles.actions }>
        <PriceComponent
          value={ fullPrice }
          type='bold_24'
          typeCurrency='NORMAL_16'
          marginSmall
        />
        <div className={ styles['details-price'] }>
          <TaxiDetailsPrice
            tariffAmount={ Number(amount) }
            vatAmount={ vat }
            showDetails={ false }
          />
        </div>
        <div className={ styles.action }>
          <Button
            className={ styles.button }
            type='secondary'
            onClick={ handleAddToCart }
            disabled={ !voucherIsValid() }
            qaAttr={ QA_ATTRIBUTES.taxi.voucher.actions.addToCart }
          >
            {LABELS.TO_CART}
          </Button>
        </div>
      </div>);
  };

  const handleChangeBlur = () => setDateToOpen(true);
  const validateFn = (v: string): boolean => !onlyFractionalNumbersOrEmpty(v) || v.length > 8;
  const validateCount = (v: string): boolean => !onlyNumbersOrEmpty(v) || v.length > 2 || Number(v) > 10;

  const getErrorSum = (): string => {
    if ((amount as number) > 41667) {
      return LABELS.ERROR_SUM;
    }

    return '';
  };

  const getErrorCount = (): string => {
    if (!count || (count as number) < 1) {
      return LABELS.ERROR_COUNT;
    }

    return '';
  };

  const renderAvailableClasses = () => AVAILABLE_CLASSES.map(availableClass => {
    const { value, label } = availableClass;

    return (
      <Checkbox
        key={ value }
        className={ styles.checkbox }
        onChange={ () => setAvailableClasses(value) }
        value={ availableClasses.includes(value) }
      >
        { label }
      </Checkbox>
    );
  });

  return (
    <ItemPanel
      className={ styles.wrapper }
      renderHeader={ () => null }
      loading={ loading }
    >
      <div className={ styles.content }>
        <ItemLayout
          serviceType={ SERVICETYPE.TAXI }
          html={ renderActions() }
        >
          <TaxiProviders providers={ ACTIVE_PROVIDERS } className={ styles.providers } />
          <ul>
            <li>
              <div className={ styles.item }>
                <Text type='NORMAL_16'>
                  { LABELS.AMOUNT }
                </Text>
                <Input
                  className={ styles.input }
                  value={ amount }
                  withValidation
                  error={ getErrorSum() }
                  debounceMs={ 0 }
                  validateFn={ validateFn }
                  onChange={ handleChangeInput }
                />
                <Text type='NORMAL_16'>
                  { LABELS.RUB }
                </Text>
                <Text type='NORMAL_16' className={ styles.plus }>
                  +
                </Text>
                <Input
                  className={ styles['vat-input'] }
                  textClassName={ styles['vat-input-text'] }
                  value={ MoneyFormat.money(vat) }
                  onChange={ () => {} }
                  disabled
                />
                <Text type='NORMAL_16'>
                  { LABELS.RUB } { LABELS.VAT }
                </Text>
              </div>
            </li>
            <li>
              <div className={ styles.item }>
                <Text>{ LABELS.PERIOD_FROM }</Text>
                <Datepicker
                  open={ dateOpen }
                  type='date'
                  closeOnTabOut
                  wrapperClassName={ styles.date }
                  inputClassName={ styles.datepicker }
                  value={ date }
                  min={ minDate }
                  format={ PATTERN.DATE_VOUCHER_FROM }
                  onBlur={ handleChangeBlur }
                  getCurrentOpen={ setDateOpen }
                  onChange={ (value: Moment) => handleDateChangeAmp(setDate, value) }
                  isDuration
                  durationDates={ [date, dateTo] }
                  qaAttr={ QA_ATTRIBUTES.taxi.voucher.dates.dateFrom }
                />
                <Text>{LABELS.PERIOD_TO}</Text>
                <Datepicker
                  open={ dateToOpen }
                  type='date'
                  closeOnTabOut
                  wrapperClassName={ styles.date }
                  inputClassName={ styles.datepicker }
                  value={ dateTo }
                  min={ minDateTo }
                  format={ PATTERN.DATE_VOUCHER_TO }
                  getCurrentOpen={ setDateToOpen }
                  onChange={ (value: Moment) => handleDateChangeAmp(setDateTo, value) }
                  isDuration
                  durationDates={ [date, dateTo] }
                  qaAttr={ QA_ATTRIBUTES.taxi.voucher.dates.dateTo }
                />
                <Text>{LABELS.MSK_TIME}</Text>
              </div>
            </li>
            <li>
              <div className={ styles.item }>
                <Text type='NORMAL_16'>
                  { LABELS.COUNT }
                </Text>
                <Input
                  className={ styles.input }
                  value={ count }
                  withValidation
                  error={ getErrorCount() }
                  debounceMs={ 0 }
                  validateFn={ validateCount }
                  onChange={ handleChangeCount }
                />
              </div>
            </li>
            <li>
              <div className={ styles.itemCheckbox }>
                <Text>{ LABELS.AVAILABLE_CLASS }</Text>
                <div className={ styles.select }>
                  { renderAvailableClasses() }
                </div>
              </div>
            </li>
          </ul>
          <div className={ styles.info }>
            <Icon type='info' className={ styles.icon }/>
            <div>
              <div>{LABELS.VOUCHER_INFO}</div>
              <div className={ styles.text }>{LABELS.ONLY_IN_RF}</div>
              <Text type='SEMIBOLD_16'>{LABELS.SUPPORT}</Text>
            </div>
          </div>
        </ItemLayout>
      </div>
    </ItemPanel>
  );
});

export { Voucher };
