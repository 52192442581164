import React from 'react';
import { Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { formatDateTemplate, formatLocationTemplate } from '../../../../bi/utils/formatDate';

import { PATTERN } from '../../../../bi/constants/dateFormats';

import { FlightSegmentProps } from './types';

import styles from './styles/index.module.css';

const LABELS = {
  AIR_DECLINES: getText('constants:airline.airDeclines.keyWithoutCount'),
};

const FlightSegmentComponent = ({
  departureTime,
  arrivalTime,
  departurePlace,
  arrivalPlace,
  index,
  isFirstSegment,
}: FlightSegmentProps) => {
  const renderSecond = () => {
    if (isFirstSegment) {
      return null;
    }

    return (
      <div className={ styles.oval_container }>
        <Text type='NORMAL_16' color='white'>
          { index } { LABELS.AIR_DECLINES }
        </Text>
      </div>
    );
  };

  return (
    <div className={ styles.flight_item_container }>
      <div className={ styles.flight_info_details }>
        { renderSecond() }
        <div className={ styles.flight_info_item_time }>
          { formatDateTemplate(departureTime, arrivalTime) }
        </div>
        <Text
          color='gray'
          className={ styles.flight_info_item }
        >
          { formatLocationTemplate(departurePlace, arrivalPlace) }
        </Text>
        <Text
          color='gray'
          className={ styles.flight_info_item }
        >
          { formatDateTemplate(departureTime, arrivalTime, PATTERN.DATEWITHWEEKDAY) }
        </Text>
      </div>
    </div>
  );
};

export default FlightSegmentComponent;
