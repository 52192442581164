import { Moment } from 'moment';
import React, { useEffect, useState, useRef, RefCallback, RefObject } from 'react';
import { Button, Datepicker, Dialog, IconButton, Input, Select, Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import { formatDate, momentObject } from '../../../../bi/utils/formatDate';
import { replacementComma } from '../../../../bi/utils/text';

import { ItemType, ItemTypes } from '../../../../bi/services/expenseReports/types';

import {
  EXPENSE_VAT_RATES,
  EXPENSES,
  EXPENSES_TYPES,
  EXPENSES_NAME,
  PREFILED_VALUE_TYPE,
} from '../../../../bi/constants/expenseReport';
import { PATTERN } from '../../../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import HelpIcon from '../../../../components/HelpIcon';

import styles from './styles/expense.module.css';

const LABELS = {
  HEADER: getText('expenseReports:report.expenseDialog.title'),
  NAME: getText('expenseReports:report.expenseDialog.name'),
  PLACEHOLDER_NAME: getText('expenseReports:report.expenseDialog.placeholderName'),
  TYPE: getText('expenseReports:report.expenseDialog.type'),
  AMOUNT: getText('expenseReports:report.expenseDialog.amount'),
  VAT: getText('expenseReports:report.expenseDialog.vat'),
  VAT_PLACEHOLDER: getText('expenseReports:report.expenseDialog.vatPlaceholder'),
  DOCUMENT: getText('expenseReports:report.expenseDialog.document'),
  NUMBER: getText('expenseReports:report.expenseDialog.number'),
  FROM: getText('expenseReports:report.expenseDialog.from'),
  SAVE: getText('common:save'),
  CANCEL: getText('common:undo'),
  ERROR_NAME: getText('expenseReports:report.expenseDialog.errorName'),
  ERROR_AMOUNT: getText('expenseReports:report.expenseDialog.errorAmount'),
  ERROR_NUMBER: getText('expenseReports:report.expenseDialog.errorNumber'),
  ERROR_VAT: getText('expenseReports:report.expenseDialog.errorVat'),
  TOOLTIP: getText('expenseReports:report.expenseDialog.tooltip'),
};

const ERRORS: { [key: string]: string } = {
  name: LABELS.ERROR_NAME,
  amount: LABELS.ERROR_AMOUNT,
  number: LABELS.ERROR_NUMBER,
  vat: LABELS.ERROR_VAT,
};

const AMOUNT = 10000;

const ERRORS_DEFAULT = {
  name: '',
  amount: '',
  number: '',
  vat: '',
};

const checkField = (value: string | number, field: string) => {
  const isNumber = typeof value === 'number';
  const preparedValue = isNumber ? value : value.trim().length;

  return !preparedValue ? ERRORS[field] : '';
};

type SimpleSelectValuesType = { value: number, label: string }[];

interface IExpenseDialog {
  show: boolean,
  item: any,
  startDate: Moment,
  onClose(): void,
  onSave(
    data: {
      Type: number | string;
      Number: string;
      Amount: number | string;
      Vat: number | string;
      Id: number;
      VatRate: number;
      Date: string;
      Name: string
    }
  ): void,
  canBeEdited?: boolean,
  prefiledValue: ItemTypes[],
  prefiledValueType: string,
}

const ExpenseDialog = ({
  show,
  item,
  startDate,
  onClose,
  onSave,
  canBeEdited = true,
  prefiledValue,
  prefiledValueType,
}: IExpenseDialog) => {
  const items: ItemType[] = EXPENSES_TYPES;
  const [names, setNames] = useState(EXPENSES_NAME.GSM);
  const itemsVatRates = EXPENSE_VAT_RATES;
  const [name, setName] = useState('');
  const [type, setType] = useState<number | string>(EXPENSES.CASH_RECEIPT);
  const [amount, setAmount] = useState<number | string>(AMOUNT);
  const [vat, setVat] = useState('');
  const [number, setNumber] = useState('');
  const [date, setDate] = useState<Moment | string | null>(startDate);
  const [errors, setErrors] = useState({ ...ERRORS_DEFAULT });
  const [border, setBorders] = useState(styles.default);
  const [vatRates, setVatRates] = useState(EXPENSE_VAT_RATES[5].value);

  const typePrefiled = {
    selectSearch: prefiledValueType === PREFILED_VALUE_TYPE.SELECT_SEARCH,
    inputSelect: prefiledValueType === PREFILED_VALUE_TYPE.SELECT_INPUT,
  };

  const firstFieldRef: RefCallback<string> | RefObject<any> | null = useRef();

  const setPrefiledNames = () => {
    const selectNames = item && prefiledValueType !== PREFILED_VALUE_TYPE.NONE
      ? prefiledValue.find(({ label }) => label === item?.Name)
      : prefiledValue[0];

    const checkNames = typePrefiled.inputSelect && (!selectNames || selectNames?.value === EXPENSES_NAME.ANOTHER)
      ? EXPENSES_NAME.ANOTHER
      : selectNames?.value || 0;

    return checkNames;
  };

  useEffect(() => {
    if (show) {
      setName(item ? item.Name : '');
      setNames(setPrefiledNames());
      setType(item ? item.Type : EXPENSES.CASH_RECEIPT);
      setAmount(item ? item.Amount : AMOUNT);
      setVat(item ? item.Vat : '');
      setVatRates(item ? item.VatRate : EXPENSE_VAT_RATES[5].value);
      setNumber(item ? item.Number : '');
      setDate(item ? momentObject(item.Date) : startDate);
      setErrors({ ...ERRORS_DEFAULT });
      setBorders(styles.default);

      if (firstFieldRef.current) {
        setTimeout(() => {
          firstFieldRef.current.focus();
        }, 100);
      }
    }
  }, [item, show, startDate]);

  const handleSetVatRates = (vatRate: number) => {
    if (vatRate === 6 || vatRate === 5) {
      setVat('0');
    }

    setVatRates(vatRate);
  };

  const handleSave = () => {
    const nameSend = !name && prefiledValueType !== PREFILED_VALUE_TYPE.NONE ? prefiledValue[names].label : name;

    const errorName = checkField(nameSend, 'name');
    const errorAmount = checkField(amount, 'amount');
    const errorNumber = checkField(number, 'number') || (!date ? LABELS.ERROR_NUMBER : '');
    const errorVat = checkField(vat, 'vat') && (vatRates !== 6 && vatRates !== 5) ? LABELS.ERROR_VAT : '';

    if (!errorName.length && !errorAmount.length && !errorNumber.length && !errorVat.length) {
      let vatRate = vatRates;
      let doneVat = vat || '0';

      if (!vat && vatRate !== 6 && vatRate !== 5) {
        vatRate = 6;
        setVatRates(6);
      }

      if (vatRate === 6 || vatRate === 5) {
        doneVat = '0';
      }

      const data = {
        Type: type,
        Name: nameSend,
        Number: number,
        Amount: replacementComma(amount.toString()),
        Vat: replacementComma(doneVat.toString()),
        VatRate: vatRate,
        Date: formatDate(date, PATTERN.YEARMONTHDAY),
        Id: item?.Id || 0,
      };

      onSave(data);

      return onClose();
    }

    setBorders(!date ? styles.red : styles.default);

    return setErrors(e => ({
      ...e,
      name: errorName,
      amount: errorAmount,
      number: errorNumber,
      vat: errorVat,
    }));
  };

  const handleFocusVat = () => {
    if (vatRates !== 6) {
      setErrors(e => ({ ...e, vat: '' }));
    }
  };

  const disabledVatRates = vatRates === 6 || vatRates === 5;

  const renderName = () => {
    const { selectSearch, inputSelect } = typePrefiled;

    if ((inputSelect && names !== EXPENSES_NAME.ANOTHER) || (selectSearch)) {
      const setOurNames = (data: number) => {
        setNames(data);
        setName('');
      };

      return (
        <Select
          search={ selectSearch }
          excludeValue
          value={ names as number }
          theme='default-border'
          items={ prefiledValue as SimpleSelectValuesType }
          onChange={ setOurNames }
        />
      );
    }

    const iconClose = (names === EXPENSES_NAME.ANOTHER) && (
      <IconButton
        iconType='closeButton'
        onClick={ () => setNames(EXPENSES_NAME.GSM) }
      />
    );

    const wrapperAnother = names === EXPENSES_NAME.ANOTHER ? styles.wrapper_another : '';
    const inputAnother = names === EXPENSES_NAME.ANOTHER ? styles.input_another : '';

    return (
      <div
        className={ wrapperAnother }
      >
        <div
          className={ inputAnother }
        >
          <Input
            qaAttr={ QA_ATTRIBUTES.expenseReport.item.expense.name }
            ref={ firstFieldRef }
            value={ name }
            placeholder={ LABELS.PLACEHOLDER_NAME }
            error={ errors.name }
            onChange={ setName }
            onFocus={ () => setErrors(e => ({ ...e, name: '' })) }
          />
        </div>
        { iconClose }
      </div>
    );
  };

  return (
    <Dialog
      qaAttr={ QA_ATTRIBUTES.expenseReport.item.expense.close }
      showClosing
      show={ show }
      className={ styles.wrapper }
      onChange={ onClose }
      isForm
    >
      <Text
        qaAttr={ QA_ATTRIBUTES.expenseReport.item.expense.title }
        type='bold_20'
        className={ styles.header }
      >
        { LABELS.HEADER }
      </Text>
      <Text className={ styles.title }>
        {LABELS.NAME}
      </Text>
      { renderName() }
      <Text className={ styles.title }>
        { LABELS.TYPE }
      </Text>
      <Select
        qaAttr={ QA_ATTRIBUTES.expenseReport.item.expense.type.select }
        qaAttrLabel={ QA_ATTRIBUTES.expenseReport.item.expense.type.selectLabel }
        qaAttrLoading={ QA_ATTRIBUTES.expenseReport.item.expense.type.selectLoading }
        excludeValue
        value={ type }
        theme='default-border'
        items={ items }
        onChange={ setType }
      />
      <Text className={ styles.title }>
        {LABELS.AMOUNT}
      </Text>
      <Input
        qaAttr={ QA_ATTRIBUTES.expenseReport.item.expense.sum }
        className={ styles.amount }
        value={ amount }
        error={ errors.amount }
        onFocus={ () => setErrors(e => ({ ...e, amount: '' })) }
        onChange={ setAmount }
      />
      <Text className={ styles.title }>
        {LABELS.VAT}
      </Text>
      <Input
        className={ styles.amount }
        disabled={ disabledVatRates }
        value={ vat }
        error={ errors.vat }
        onFocus={ handleFocusVat }
        onChange={ setVat }
      />
      <div className={ styles['vat-rates'] }>
        <Select
          qaAttr={ QA_ATTRIBUTES.expenseReport.item.expense.vatRates.select }
          qaAttrLabel={ QA_ATTRIBUTES.expenseReport.item.expense.vatRates.selectLabel }
          qaAttrLoading={ QA_ATTRIBUTES.expenseReport.item.expense.vatRates.selectLoading }
          excludeValue
          value={ vatRates }
          theme='default-border'
          items={ itemsVatRates }
          onChange={ handleSetVatRates }
        />
      </div>
      <div className={ styles.document_title }>
        <Text className={ styles.document_text }>
          {LABELS.DOCUMENT}
        </Text>
        <HelpIcon text={ LABELS.TOOLTIP }/>
      </div>
      <div className={ styles.document }>
        <div className={ styles.number }>
          <Text
            type='NORMAL_14'
            color='gray'
            className={ styles.text }
          >
            {LABELS.NUMBER}
          </Text>
          <Input
            qaAttr={ QA_ATTRIBUTES.expenseReport.item.expense.numberDoc }
            value={ number }
            error={ errors.number }
            onFocus={ () => setErrors(e => ({ ...e, number: '' })) }
            onChange={ setNumber }
          />
        </div>
        <div className={ styles.date }>
          <Text
            type='NORMAL_14'
            color='gray'
            className={ styles.text }
          >
            {LABELS.FROM}
          </Text>
          <div className={ border }>
            <Datepicker
              qaAttr={ QA_ATTRIBUTES.expenseReport.item.expense.dateDoc.date }
              qaAttrTopSwitcher={ QA_ATTRIBUTES.expenseReport.item.expense.dateDoc.top }
              qaAttrNextMonth={ QA_ATTRIBUTES.expenseReport.item.expense.dateDoc.nextMonth }
              inputClassName={ styles.input }
              value={ date }
              inputTheme='open'
              onChange={ setDate }
              onFocus={ () => {
                setBorders(styles.default);

                if (number) {
                  setErrors(e => ({ ...e, number: '' }));
                }
              } }
            />
          </div>
        </div>
      </div>
      <div className={ styles.actions }>
        <Button
          qaAttr={ QA_ATTRIBUTES.expenseReport.item.expense.save }
          disabled={ !canBeEdited }
          formType='submit'
          className={ styles.submit }
          onClick={ handleSave }
        >
          { LABELS.SAVE }
        </Button>
        <Button
          qaAttr={ QA_ATTRIBUTES.expenseReport.item.expense.cancel }
          type='textual-medium'
          onClick={ onClose }
          className={ styles.cancel }
        >
          { LABELS.CANCEL }
        </Button>
      </div>
    </Dialog>
  );
};

export default ExpenseDialog;
