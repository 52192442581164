import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, Text, Checkbox, Button } from 'new-ui';
import * as queryString from 'query-string';
import { observer } from 'mobx-react';

import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { prepareAirlineNames, prepareDataFlight, prepareDataPerson } from '../../bi/utils/airlineAdditionalServices';
import { prepareFullInfoAirport } from '../../bi/utils/vipHall';
import MoneyFormat from '../../bi/utils/money';
import MainAnalytic from '../../bi/utils/analytics/main';
import { formatDate, fullFormYear } from '../../bi/utils/formatDate';

import { PATH } from '../../bi/constants/airlineAdditionalServices';
import ROUTES from '../../bi/constants/routes';
import { NOTIFICATION_LEVEL } from '../../bi/constants/notifications';
import { FIELDS_DIRECTION } from '../../bi/constants/vipHall';

import { IDetails, TicketDataInterface } from '../../bi/types/airlineAdditionalServices';
import { VipHallPaymentProps } from '../../bi/types/vipHall';

import { SearchLoading } from '../../components/SearchLoading';
import { ErrorDialog } from './components/ErrorDialog';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('vipHall:payment.title'),
  AGREEMENT: getText('vipHall:payment.agreement'),
  ATTENTION: getText('vipHall:payment.attention'),
  CONFIRM: getText('vipHall:payment.confirm'),
  CLOSE: getText('vipHall:payment.close'),
  COST: getText('vipHall:payment.cost'),
  OLD_COST: getText('vipHall:payment.oldCost'),
  MAX_TIME: (max: string) => getText('vipHall:item.maxTime', { max }),
  LOADING: getText('vipHall:payment.loading'),
  ERROR_VOUCHER: getText('vipHall:payment.errorVoucher'),
  PAYMENT_SUCCESS: (name: string, airport: string, date: string) => getText('vipHall:payment.notificationSuccess', { name, airport, date }),
  WARNING: getText('vipHall:payment.warning'),
  LIMIT_BUY_VIP_HALL: (name: string) => getText('vipHall:payment.limitBuyVipHall', { name }),
};

const VipHallFormPayment: FC<VipHallPaymentProps> = observer(({
  orderService,
  vipHallService,
  breadCrumbsService,
  notificationService,
  match: { params: { tripId, ticketId, guid }, path },
}) => {
  const history = useHistory();

  const { vipHallStore: { choosenVipHall, mainInfo, newCost } } = useStores([MOBX_STORES.VIP_STORE]);

  const { cancellation_description, name, hours_to_stay } = choosenVipHall;

  const [detailsData, setDetailsData] = useState<IDetails | null>(null);
  const [show, setShow] = useState<boolean>(true);
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingRecalculate, setLoadingRecalculate] = useState<boolean>(false);

  const { guid: id, route, segment, terminal }: any = queryString.parse(location.search);

  const isVipHallPage = path === PATH.PAYMENT_VIP;

  const handleBackPage = () => {
    setShow(!show);

    if (isVipHallPage) {
      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.VIP_HALL.VIP_PAYMENT_POPUP_CLOSED);

      history.push(ROUTES.ADDITIONAL_SERVICES.INFO_VIP(guid, tripId, ticketId, id, route, segment, terminal));
    } else {
      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.VIP_HALL.ESCORT_PAYMENT_POPUP_CLOSED);

      history.push(ROUTES.ADDITIONAL_SERVICES.INFO_ESCORT(guid, tripId, ticketId, id, route, segment, terminal));
    }
  };

  useEffect(() => {
    if (!choosenVipHall.name) {
      history.push(ROUTES.ADDITIONAL_SERVICES.MAIN(tripId, ticketId));
    }

    const { details }: { details: IDetails | null } = orderService.get();

    vipHallService.searchByGuid(id, terminal);

    setDetailsData(details);

    breadCrumbsService.setCurrentPath(path);

    return () => {
      breadCrumbsService.setCurrentPath(PATH.MAIN);
    };
  }, []);

  const ticket = detailsData?.OrderItems.find(item => item.Id === +ticketId);
  const ticketData: TicketDataInterface = ticket && JSON.parse(ticket.ActualVersion.JsonData);

  const dataPerson = ticketData && prepareDataPerson(ticketData, ticket);
  const airportHtml = ticketData && prepareFullInfoAirport(ticketData, mainInfo, route, segment);

  const cancellationInfo = cancellation_description?.split('.').length > 1
    ? `${cancellation_description.split('.')[0]}. ${cancellation_description.split('.')[1]}`
    : cancellation_description;

  const maxTimeText = hours_to_stay ? LABELS.MAX_TIME(hours_to_stay) : null;

  const handleChangeAgreement = () => {
    setIsAgree(!isAgree);

    const analyticType = isVipHallPage
      ? MainAnalytic.ACTIONS.VIP_HALL.VIP_PAYMENT_CHECKBOX_CHECKED
      : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_PAYMENT_CHECKBOX_CHECKED;

    MainAnalytic.sendFirebase(analyticType);
  };

  const handlePay = async () => {
    const { departureData, arrivalData } = prepareDataFlight(ticketData.Routes[route]);

    const analyticType = isVipHallPage
      ? MainAnalytic.ACTIONS.VIP_HALL.VIP_PAYMENT_BUTTON_PRESSED
      : MainAnalytic.ACTIONS.VIP_HALL.ESCORT_PAYMENT_BUTTON_PRESSED;

    MainAnalytic.sendFirebase(analyticType, {
      sum: choosenVipHall.one_passenger_cost,
      name_service: name,
    });

    setLoading(true);
    setShow(!show);

    const { Success, TripItemId, Error } =
      await vipHallService.confirmPayment(guid, ticket.ActualVersion, route, ticket.ActualVersion.ProjectId, ticketId, segment);

    setError(Error);
    setLoading(false);

    if (Success) {
      try {
        setLoading(true);

        await orderService.sendDocument({ tripItemId: TripItemId, email: ticket.ActualVersion.Employees[0].Email });

        history.push(ROUTES.ADDITIONAL_SERVICES.TRIP(tripId));

        notificationService.send({
          message: LABELS.PAYMENT_SUCCESS(name, airportHtml, mainInfo.type === FIELDS_DIRECTION.DEPARTURE ? departureData : arrivalData),
          // @ts-ignore
          level: NOTIFICATION_LEVEL.SUCCESS,
        });

        vipHallService.setNewSearch();
        setLoading(false);
      } catch (err) {
        setError(LABELS.ERROR_VOUCHER);
        setLoading(false);
      }
    }
  };

  const handleRecalculate = async () => {
    const birthDates = formatDate(ticket.ActualVersion.Employees[0].Birthday, fullFormYear);

    setLoadingRecalculate(true);

    const { AllowedBooking } = await vipHallService.recalculatePrice(guid, [birthDates]);

    setLoadingRecalculate(false);

    if (AllowedBooking) {
      await handlePay();
    }

    handleChangeAgreement();
  };

  const renderDataFlight = () => {
    const { firstSegment, lastSegment, departureData, arrivalData } = prepareDataFlight(ticketData.Routes[route]);

    return (
      <Text
        className={ styles.data }
        type='NORMAL_16_130'
        color='gray'
      >
        { `${firstSegment?.DepartureCity} – ${lastSegment?.ArrivalCity},`}
        {` ${departureData} – ${arrivalData}, ${prepareAirlineNames(ticketData.Routes[route].Segments, ticketData.Routes[route].Segments[segment])}` }
      </Text>
    );
  };

  const renderErrorDialog = () => (error && error.length ? (
    <ErrorDialog
      history={ history }
      error={ error }
      id={ tripId }
    />
  ) : null);

  const renderPrice = () => {
    const fullPriceHtml = MoneyFormat.money(choosenVipHall.one_passenger_cost, true);
    const label = !newCost ? LABELS.COST : LABELS.OLD_COST;
    const styleOldPrice = newCost ? styles.old_price : null;

    return (
      <div className={ styles.cost }>
        <Text type='NORMAL_16'>{ label }</Text>
        <Text
          type='bold_18'
          color='red'
          className={ `${styles.cost_passenger} ${styleOldPrice}` }
        >
          { fullPriceHtml }
        </Text>
      </div>
    );
  };

  const renderRecalculatePrice = () => {
    if (!newCost) return null;

    const moneyFormatPrice = MoneyFormat.money(newCost, true);

    return (
      <div className={ styles.cost }>
        <Text type='NORMAL_16'>{ LABELS.COST }</Text>
        <Text
          type='bold_18'
          color='red'
          className={ styles.cost_passenger }
        >
          { moneyFormatPrice }
        </Text>
      </div>
    );
  };

  const dataFlightHtml = ticketData ? renderDataFlight() : null;

  if (loading) {
    return (
      <div className={ styles.search_wrapper }>
        <SearchLoading text={ LABELS.LOADING } />
      </div>
    );
  }

  return (
    <>
      <Dialog
        showClosing
        show={ show }
        onChange={ handleBackPage }
        className={ styles.dialog }
      >
        <div className={ styles.wrapper }>
          <Text type='bold_20'>{ LABELS.TITLE }</Text>
          <div className={ styles.ticket }>
            <Text type='NORMAL_16_130' color='gray'>{ dataPerson }</Text>
            { dataFlightHtml }
          </div>
          <div className={ styles.info }>
            <Text type='NORMAL_16_140'>{ name }</Text>
            <Text type='NORMAL_16_140'>{ maxTimeText }</Text>
            <Text type='NORMAL_16_140'>{ airportHtml }</Text>
          </div>
          <Text type='NORMAL_14' color='gray'>{ cancellationInfo }</Text>
          { renderPrice() }
          { renderRecalculatePrice() }
          <div className={ styles.agree }>
            <Checkbox
              value={ isAgree }
              onChange={ handleChangeAgreement }
            >
              <Text type='NORMAL_14'>{ LABELS.AGREEMENT }</Text>
            </Checkbox>
          </div>
          <Text type='NORMAL_16_130' color='red'>{ LABELS.ATTENTION }</Text>
          <div className={ styles.buttons }>
            <Button
              type='secondary'
              className={ styles.confirm }
              disabled={ !isAgree }
              onClick={ handleRecalculate }
              loading={ loadingRecalculate }
            >
              { LABELS.CONFIRM }
            </Button>
            <Button
              type='textual-medium'
              onClick={ handleBackPage }
            >
              { LABELS.CLOSE }
            </Button>
          </div>
        </div>
      </Dialog>
      { renderErrorDialog() }
    </>
  );
});

export { VipHallFormPayment };
