import moment, { Moment } from 'moment';

export interface ICreateDateFromToObj {
  start: Moment;
  end: Moment;
  minEnd: Moment;
  minStart: Moment;
}
const createDateFromTo = (
  startDate: Moment,
  addDays = 1,
  minDay = moment(),
): ICreateDateFromToObj | null => {
  if (startDate) {
    const cloneStartDate = startDate.clone();
    const endDay = cloneStartDate.clone().add(addDays, 'd');

    return {
      start: cloneStartDate,
      end: endDay,
      minEnd: cloneStartDate.clone().startOf('d'),
      minStart: minDay.startOf('d'),
    };
  }

  return null;
};

export default createDateFromTo;
