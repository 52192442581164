const REQUESTSACTION = {
  LOADREQUESTS: 'requests.loadRequests',
  LOAD_TRIPS_REQUEST: 'requests.loadTripsRquests',
  UPDATE_LOADING_TRIP_REQUEST: 'requests.updateLoadingTripsRquests',
  UPDATE_LOADING_REQUESTS_FLAG: 'requests.loadingRequestsFlag',
  LOADREQUEST: 'requests.loadRequest',
  SETREQUESTITEM: 'requests.setRequestItem',
  TOGGLEEMPTYIDENTIFIER: 'requests.toggleEmptyIdentifier',
  GETINTEGRATION: 'requests.getIntegratxwion',
  ENABLEINTEGRATION: 'requests.enableIntegration',
  DISABLEINTEGRATION: 'requests.disableIntegration',
  SETADDINTEGRATIONLOADING: 'requests.setAddIntegrationLoading',
  SWITCHINTEGRATION: 'requests.switchIntegration',
  FAILEDSWITCHINTEGRATION: 'requests.failedSwitchIntegration',
  HIDEERRORDIALOG: 'requests.hideErrorDialog',
  TOGGLEEDITMODE: 'requests.toggleEditMode',
  CHANGEPAGE: 'requests.changePage',
  CLEAR: 'requests.clear',
  DISABLELOADING: 'requests.disableLoading',
  UPDATE_LOADING: 'requests.upadteLoading',
  CHANGE_FILTERS: 'requests.changeFilters',
};

export default REQUESTSACTION;
