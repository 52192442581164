import { action, makeObservable, observable } from 'mobx';
import * as qs from 'query-string';

import { history } from '../../../../../utils/history';

export class TripCacheStore {
  constructor() {
    makeObservable(this);
  }

  @observable tripId: number | string | string [] | null = null;
  @observable isRequest: boolean = false;
  @observable tripName: string = '';
  @observable tripDate: string = '';

  @action.bound
  cacheFromQuery = (tripIdKey: string = 'tripId') => {
    const query = qs.parse(history.location.search);
    const tripId = Number(query[tripIdKey]);

    if (!Number.isNaN(tripId)) this.tripId = tripId;

    if (query.is_request === 'true') this.isRequest = true;

    if (typeof query.tripName === 'string') this.tripName = query.tripName;

    if (typeof query.tripDate === 'string') this.tripDate = query.tripDate;
  };

  @action.bound
  clear = () => {
    this.tripId = null;
    this.isRequest = false;
    this.tripName = '';
    this.tripDate = '';
  };
}

export const tripCacheStore = new TripCacheStore();
