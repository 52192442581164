import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Input,
  Textarea,
  Text,
  LinkButton,
  Button,
  Tooltip,
  PageLoader,
  BackLink,
} from 'new-ui';
import { getText } from '../../../i18n';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import type { TravelPolicyServiceType } from '../../bi/services/travelPolicy';
import type { SuggestsRequestModel } from '../../bi/services/travelPolicy/types';

import { EmployeeDialog } from './components/EmployeeDialog';
import { PolicyRulesWrapper } from './components/PolicyRulesWrapper';
import { FieldLabel } from '../../components/FieldLabel';

import { isSmartAgent } from '../../bi/utils/env';

import { SERVICETYPE } from '../../bi/constants/serviceType';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { POPUPS_KIND } from '../../bi/constants/popups';
import { UNIQUE_FORMED_TP } from '../../bi/constants/travelPolicy';

import { travelPolicyRules } from '../../bi/utils/travelPolicy';
import { MainAnalytic } from '../../bi/utils/analytics';

import {
  COMMON_TRAVEL_POLICY_STORE_FIELDS,
  TRAVEL_POLICY_STORES_TYPES,
} from '../../bi/services/travelPolicy/consts';

import styles from './styles/index.module.css';

type TravelPolicyPageProps = {
  travelPolicyService: TravelPolicyServiceType,
  workspaceService: any,
  notificationService: any,
  userSessionService: any,
  accountSettingsService: any,
  history: any,
};

const LABELS = {
  DEMO: getText('settings:travelPolicy.demo'),
  TITLE: getText('settings:travelPolicy.title'),
  SAVE: getText('common:save'),
  CANCEL: getText('common:cancel'),
  NAME: getText('settings:travelPolicy.name'),
  RESTRICTION: getText('settings:travelPolicy.restriction'),
  NAME_OF_TP: getText('settings:travelPolicy.nameOfTp'),
  DESC_OF_TP: getText('settings:travelPolicy.descOfTp'),
  REQUIRED_FIELDS: getText('settings:travelPolicy.requiredFields'),
  EDIT_SUCCESS: (name: string) => getText('settings:travelPolicy.successEdit', { name }),
  SAVE_SUCCESS: (name: string) => getText('settings:travelPolicy.successSave', { name }),
};

const TravelPolicyPage = observer(({
  travelPolicyService,
  workspaceService,
  notificationService,
  userSessionService,
  history,
  accountSettingsService,
}: TravelPolicyPageProps) => {
  const { travelPolicyStore } = useStores([MOBX_STORES.TRAVEL_POLICY]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showEmployeeDialog, setShowEmployeeDialog] = useState(false);
  const {
    store: {
      name,
      description,
      id,
      loading,
    },
    suggestions,
    employees,
    isSelectAll,
    uniqueTravelPolicy: {
      travelPolicyModel,
      formedTP,
    },
  } = travelPolicyStore;
  const { isDemo } = workspaceService;
  const { isFormValid, policyIsApplied, updateStore, getSuggestions, saveForm } = travelPolicyService;
  const noBookingTaxi = accountSettingsService.getBookingTaxi();

  const {
    setEmployeesSelected,
    setEmployeeAutocompleteList,
    setSelectAll,
    getUniqueTravelPolicy,
    closePopupState,
  } = travelPolicyService;

  const handleChangeTooltip = () => setShowTooltip(true);

  const handleOpenEmployeeDialog = () => {
    setShowEmployeeDialog(true);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRAVEL_POLICY_SAVE_BUTTON_PRESSED);

    setEmployeeAutocompleteList('');

    if (travelPolicyModel) {
      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.TRAVEL_POLICY.CREATED_TP_SAVE, { formedTP });
    }
  };

  const handleResize = (e: any) => {
    if (e.target.innerWidth < 1400) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 1400) {
      handleChangeTooltip();
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  const handlePushFirebase = () => {
    if (travelPolicyModel) {
      const preparedPop = formedTP === UNIQUE_FORMED_TP.NEW ?
        POPUPS_KIND.TRAVEL_POLICIES_FIRST_RUN :
        POPUPS_KIND.TRAVEL_POLICIES_SECOND_RUN;

      closePopupState(preparedPop);
      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.TRAVEL_POLICY.CREATED_TP_CANCEL, { formedTP });
    }
  };

  const handleUpdateMainAnalytics = (type: string) => {
    const typeUpdate = ({
      [SERVICETYPE.AIR]: () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRAVEL_POLICY_AVIA_BUTTON_PRESSED),
      [SERVICETYPE.HOTEL]: () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRAVEL_POLICY_HOTEL_BUTTON_PRESSED),
      [SERVICETYPE.TRAIN]: () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRAVEL_POLICY_TRAIN_BUTTON_PRESSED),
      [SERVICETYPE.TAXI]: () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRAVEL_POLICY_TAXI_BUTTON_PRESSED),
      [SERVICETYPE.TRANSFER]: () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRAVEL_POLICY_TRANSFER_BUTTON_PRESSED),
      [SERVICETYPE.VIP_HALL]: () => MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRAVEL_POLICY_VIP_BUTTON_PRESSED),
    })[type];

    typeUpdate();
  };

  const handleSave = () => {
    handlePushFirebase();

    history.push(ROUTES.SETTINGS.TRAVEL_POLICIES);
  };

  const handleGoBack = () => {
    handlePushFirebase();

    if (travelPolicyModel) {
      return getUniqueTravelPolicy().then(() => history.push(ROUTES.TRAVEL_POLICY_UNIQUE));
    }

    return history.push(ROUTES.SETTINGS.TRAVEL_POLICIES);
  };

  const handleFormChange = (storeType: string, payload: any) => {
    if (payload?.Apply) {
      handleUpdateMainAnalytics(storeType);
    }

    updateStore(storeType, payload);
  };

  const handleAutocompleteChange = (payload: SuggestsRequestModel) => getSuggestions(payload);

  const handleSaveForm = () => {
    saveForm().then(({ Name, Id }: { Name: string, Id: number }) => {
      handleSave();

      const label = Id
        ? LABELS.EDIT_SUCCESS(Name)
        : LABELS.SAVE_SUCCESS(Name);

      notificationService.send({
        message: label,
        level: 'success',
        qaAttr: QA_ATTRIBUTES.settings.travelPolicy.notification,
      });

      userSessionService.getTravelPolicies();
    });
  };

  const renderRules = () => {
    const {
      airTravelPolicyStore,
      trainTravelPolicyStore,
      hotelTravelPolicyStore,
      taxiTravelPolicyStore,
      transferTravelPolicyStore,
      vipHallTravelPolicyStore,
      clearSuggestions,
    } = travelPolicyService;

    const preparedTravelPolicyRules = travelPolicyRules({
      airTravelPolicyStore,
      trainTravelPolicyStore,
      hotelTravelPolicyStore,
      taxiTravelPolicyStore,
      transferTravelPolicyStore,
      vipHallTravelPolicyStore,
    });

    const content = preparedTravelPolicyRules.map(
      ({ store, type }: { store: any, type: string }) => !(type === SERVICETYPE.TAXI && noBookingTaxi) && (
      <PolicyRulesWrapper
        key={ type }
        store={ store }
        name={ name }
        type={ type }
        suggestions={ suggestions }
        onChange={ (payload: any) => handleFormChange(type, payload) }
        onAutocompleteChange={ handleAutocompleteChange }
        onAutocompleteClear={ () => clearSuggestions() }
      />
      ));

    return (
      <div className={ styles.rules }>
        { content }
      </div>
    );
  };

  const renderCommonFields = () => {
    const onChangeHandler = (payload: any) => handleFormChange(TRAVEL_POLICY_STORES_TYPES.COMMON, payload);

    return (
      <div className={ styles.inputs }>
        <div className={ styles.item }>
          <FieldLabel
            mustHave
            text={ LABELS.NAME_OF_TP }
          />
          <Input
            value={ name }
            onChange={ (v: string) => onChangeHandler({ [COMMON_TRAVEL_POLICY_STORE_FIELDS.NAME]: v }) }
            qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.createPage.inputName }
          />
        </div>
        <div className={ styles.item }>
          <FieldLabel
            text={ LABELS.DESC_OF_TP }
          />
          <Textarea
            className={ styles.textarea }
            value={ description }
            onChange={ (v: string) => onChangeHandler({ [COMMON_TRAVEL_POLICY_STORE_FIELDS.DESCRIPTION]: v }) }
          />
        </div>
      </div>
    );
  };

  const renderDemoTooltip = () => (
    <Text
      className={ styles.tooltip }
      type='NORMAL_14'
      color='white'
    >
      { LABELS.DEMO }
    </Text>
  );

  const renderNonFilledTooltip = () => {
    const nonFilledFields = [];
    const tooltipStyle = showTooltip ? styles.error_tooltip : styles['non-filled'];

    if (!name) {
      nonFilledFields.push(LABELS.NAME);
    }

    if (!policyIsApplied) {
      nonFilledFields.push(LABELS.RESTRICTION);
    }

    return (
      <div className={ tooltipStyle }>
        <Text
          color='white'
          type='bold_16'
        >
          { LABELS.REQUIRED_FIELDS }
        </Text>
        { !!nonFilledFields.length && (
          <Text
            color='white'
            type='NORMAL_14_130'
          >
            { nonFilledFields.join(', ') }
          </Text>
        ) }
      </div>
    );
  };

  const actionButtonsContent = () => {
    const positionTooltip = showTooltip ? 'right' : 'top';

    return (
      <div className={ styles.bottom }>
        <div className={ styles.actions }>
          <Tooltip
            position={ positionTooltip }
            className={ styles.submit }
            show={ isDemo || !isFormValid }
            renderContent={ () => (
              isDemo
                ? renderDemoTooltip()
                : renderNonFilledTooltip()
            ) }
          >
            <Button
              type='secondary'
              disabled={ isDemo || !isFormValid }
              onClick={ handleOpenEmployeeDialog }
              qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.createPage.saveButton }
            >
              { LABELS.SAVE }
            </Button>
          </Tooltip>
          <LinkButton
            theme='blue-without-border'
            className={ styles.cancel }
            onClick={ handleGoBack }
          >
            <Text
              type='NORMAL_14'
              color='accent'
            >
              { LABELS.CANCEL }
            </Text>
          </LinkButton>
        </div>
      </div>
    );
  };

  const backLinkContent = (
    <BackLink
      link={ ROUTES.SETTINGS.TRAVEL_POLICIES }
      alternativeDesign={ isSmartAgent }
      onClick={ handleGoBack }
    />
  );

  const titleContent = (
    <Text
      className={ styles.title }
      type='bold_32'
      qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.createPage.title }
    >
      { name || LABELS.TITLE }
    </Text>
  );

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className={ styles.wrap }>
        { backLinkContent }
        { titleContent }
        { renderCommonFields() }
        { renderRules() }
        { actionButtonsContent() }
        <EmployeeDialog
          isUnique={ !!travelPolicyModel }
          show={ showEmployeeDialog }
          employees={ employees }
          formedTP={ formedTP }
          isSelectAll={ isSelectAll }
          setSelectAll={ setSelectAll }
          setShowEmployeeDialog={ () => setShowEmployeeDialog(false) }
          setEmployeeAutocompleteList={ setEmployeeAutocompleteList }
          setEmployeesSelected={ setEmployeesSelected }
          onSave={ handleSaveForm }
          isCreate={ !id }
        />
      </div>
    </>
  );
});

export { TravelPolicyPage };
