import React from 'react';
import { Button, Text } from 'new-ui';

import { getText } from '../../../../i18n';

import styles from './styles.module.css';

interface IAfterSendActionProps {
  isError?: boolean
  message: string | null
  redirect?: () => void
}

const LABELS = {
  TO_WORKPLACE: getText('quickApprove:toWorkplace'),
};

export const AfterSendAction = ({ isError, message = '', redirect }: IAfterSendActionProps) => {
  const additionalButton = isError && (
    <div className={ styles.buttonWrapper }>
      <Button
        onClick={ redirect }
        className={ styles.button }
        type='secondary'
      >
        { LABELS.TO_WORKPLACE }
      </Button>
    </div>
  );

  const formattedMessage = message && message.split('\n').map((text, key) => (
    <React.Fragment key={ key }>
      { text }
      <br />
    </React.Fragment>
  ));

  return (
    <>
      <div>
        <Text type='bold_20'>
          { formattedMessage }
        </Text>
      </div>
      { additionalButton }
    </>
  );
};
