import React from 'react';
import { observer } from 'mobx-react';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import { STRUCTURE_TYPE_LOWER } from '../../../../../../bi/constants/chartsAnalytics';
import ROUTES from '../../../../../../bi/constants/routes';

import { AirPageSubSections } from '../../../common/smartIndex/components/airPageSubSections';
import { TrainPageSubSections } from '../../../common/smartIndex/components/trainPageSubSections';
import { HotelPageSubSections } from '../../../common/smartIndex/components/hotelPageSubSections';
import { TaxiPageSubSections } from '../../../common/smartIndex/components/taxiPageSubSections';
import { AeroexpressPageSubSections } from '../../../common/smartIndex/components/aeroexpressPageSubSections';
import { TransferPageSubSections } from '../../../common/smartIndex/components/transferPageSubSections';
import { MicePageSubSections } from '../../../common/smartIndex/components/micePageSubSection';

const SmartCommonSecondPage = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => {
  const { chartsAnalyticsSmartSummaryStore } =
    useStores([MOBX_STORES.CHARTS_ANALYTICS_SMART_SUMMARY_STORE]);

  const { type } = chartsAnalyticsSmartSummaryStore;

  const air = (
    <AirPageSubSections
      chartsAnalyticsService={ chartsAnalyticsService }
      path={ ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.AIRLINE }
    />
  );

  const hotel = (
    <HotelPageSubSections
      chartsAnalyticsService={ chartsAnalyticsService }
      path={ ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.HOTEL }
    />
  );

  const mice = (
    <MicePageSubSections
      chartsAnalyticsService={ chartsAnalyticsService }
      path={ ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.MICE }
    />
  );

  const train = (
    <TrainPageSubSections
      chartsAnalyticsService={ chartsAnalyticsService }
      path={ ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.TRAIN }
    />
  );

  const taxi = (
    <TaxiPageSubSections
      chartsAnalyticsService={ chartsAnalyticsService }
      path={ ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.TAXI }
    />
  );

  const aeroexpress = (
    <AeroexpressPageSubSections
      chartsAnalyticsService={ chartsAnalyticsService }
      path={ ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.AEROEXPRESS }
    />
  );

  const transfer = (
    <TransferPageSubSections
      chartsAnalyticsService={ chartsAnalyticsService }
      path={ ROUTES.CHARTS_ANALYTICS.SMART.COMMON.THIRD.TRANSFER }
    />
  );

  return ({
    [STRUCTURE_TYPE_LOWER.AIR]: air,
    [STRUCTURE_TYPE_LOWER.TRAIN]: train,
    [STRUCTURE_TYPE_LOWER.HOTEL]: hotel,
    [STRUCTURE_TYPE_LOWER.TAXI_VOUCHER]: taxi,
    [STRUCTURE_TYPE_LOWER.AEROEXPRESS]: aeroexpress,
    [STRUCTURE_TYPE_LOWER.TRANSFER]: transfer,
    [STRUCTURE_TYPE_LOWER.MICE]: mice,
  }[type.toLowerCase()]);
});

export { SmartCommonSecondPage };
