import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { clsx } from 'clsx';
import { Button, Dialog, Input, Text } from 'new-ui';

import { useServices } from '../../../../../../bi/context/services';

import { yup } from '../../../../../../../utils/yup';

import { LABELS } from '../../consts';

import { LoadingFields } from '../../../../../../bi/services/signature';
import { LoadingStatus } from '../../../../../../bi/services/utils/network/types';

import styles from './styles.module.css';

const checkValue = (value: string) => {
  try {
    yup.string()
      .required(LABELS.TO_EMAIL_INPUT.INCORRECT_EMAIL)
      .email(LABELS.TO_EMAIL_INPUT.INCORRECT_EMAIL)
      .validateSync(value);
  } catch (e: any) {
    return (e as yup.ValidationError).message;
  }

  return undefined;
};

export const DocToEmailInput = observer(() => {
  const {
    signatureService,
    signatureService: {
      netStore,
      enhancedSignatureStore: { closeModal },
    },
  } = useServices(['Signature']);
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string>();
  const [called, setCalled] = useState<boolean>(false);

  const loading = netStore.getIsStatus(LoadingFields.sendToEmail, LoadingStatus.LOADING);

  useEffect(() => {
    if (called) setError(checkValue(email));
  }, [email, called]);

  const sendEmail = () => {
    setCalled(true);

    if (!checkValue(email)) signatureService.sendToEmail(email);
  };

  return (
    <Dialog
      show
      showClosing
      onChange={ closeModal }
      className={ clsx(styles.modalContent, styles.toEmailInput) }
    >
      <div className={ styles.top }>
        <Text type='bold_20'>
          { LABELS.TO_EMAIL_INPUT.ENTER_EMAIL }
        </Text>
        <Input value={ email } onChange={ setEmail } error={ error } />
      </div>
      <div className={ styles.bottom }>
        <Button
          disabled={ !!error }
          onClick={ sendEmail }
          type='secondary'
          loading={ loading }
        >
          { LABELS.TO_EMAIL_INPUT.SEND }
        </Button>
        <Button
          onClick={ closeModal }
          type='textual'
          loading={ loading }
        >
          { LABELS.TO_EMAIL_INPUT.CANCEL }
        </Button>
      </div>
    </Dialog>
  );
});
