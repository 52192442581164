import React, { FC } from 'react';
import { MultiSelect } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { TRIPSTATUSVALUES } from '../../../../../bi/constants/trips';

import { TripStatuses } from '../../../../../bi/types/trips';

const getStatusesForSelect = (status: TripStatuses) => ({ value: status, label: TRIPSTATUSVALUES[status] });

const LABELS = {
  PLACEHOLDER: getText('trips:status'),
};

interface FilerStatusesProps {
  statuses: TripStatuses[];
  values: string[];
  onChange(checkedStatuses: string[]): void;
}

const FilterStatuses: FC<FilerStatusesProps> = ({
  statuses,
  values,
  onChange,
}) => {
  const statusesList = statuses.map((status: TripStatuses) => getStatusesForSelect(status));

  return (
    <MultiSelect
      withLabel
      placeholder={ LABELS.PLACEHOLDER }
      list={ statusesList }
      values={ values }
      onChange={ (checkedStatuses: string[]) => onChange(checkedStatuses) }
    />
  );
};

export { FilterStatuses };
