import { UPDACTION } from './action';
import Store from '../../store';

import { IPackagesStore } from '../../types/report';

const createNewState = (): IPackagesStore => ({
  loading: false,
  items: [],
});

const reducer = (action: { [key: string]: any, type: string }, state: IPackagesStore) => {
  switch (action.type) {
    case UPDACTION.STARTLOADUPD: {
      return {
        ...state,
        loading: true,
      };
    }

    case UPDACTION.LOADCOMPLETE: {
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    }
  }

  return state;
};

const createStore = () => new (Store as any)(reducer, createNewState());

export default createStore;
