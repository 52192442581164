import React, { FC } from 'react';
import { Icon, Text, Button } from 'new-ui';

import { getText } from '../../../../i18n';

import styles from '../styles/index.module.css';

const LABELS = {
  DELETE: getText('expenseReports:report.delete'),
  DOWNLOAD_DOCUMENT: getText('expenseReports:report.downloadDocument'),
};

const PROGRESS_BAR = {
  ID: 'progressBar',
  MAX: '100',
};

interface DocumentItemProps {
  index: number,
  fileGuid: string;
  fileName: string;
  fileSize: string;
  sizeText: string;
  fileExtension: string;
  progressBar: string | number,
  validationDownloadDoc: string,
  updateStatusText: string,
  loadingForDownloadButton: boolean;
  disabledDoSomethingWithDoc: boolean,
  loadingForDeleteButton: boolean,
  disabled: boolean,
  notShowDeleteButton: boolean,
  downloadItem: boolean,
  downloadDocumentsItem(fileGuid: string, index: number): void,
  openDeleteDocumentsItemDialog(index: number, value: true): void,
}

const DocumentItem: FC<DocumentItemProps> = ({
  index,
  fileGuid,
  fileName,
  fileSize,
  sizeText,
  fileExtension,
  progressBar,
  validationDownloadDoc,
  updateStatusText,
  loadingForDownloadButton,
  disabledDoSomethingWithDoc,
  loadingForDeleteButton,
  disabled,
  notShowDeleteButton,
  downloadItem,
  downloadDocumentsItem,
  openDeleteDocumentsItemDialog,
}) => {
  const showDownloadButton = fileGuid && !fileExtension && (
    <Button
      loading={ loadingForDownloadButton }
      type='textual'
      onClick={ () => downloadDocumentsItem(fileGuid, index) }
      className={ styles.download }
      disabled={ disabledDoSomethingWithDoc }
    >
      { LABELS.DOWNLOAD_DOCUMENT }
    </Button>
  );

  const showDeleteButton = !notShowDeleteButton ? (
    <Button
      loading={ loadingForDeleteButton }
      type='textual'
      onClick={ () => openDeleteDocumentsItemDialog(index, true) }
      className={ styles.delete }
      disabled={ disabledDoSomethingWithDoc || disabled }
    >
      { LABELS.DELETE }
    </Button>
  ) : null;

  const actionsContent = (
    <div className={ styles.buttons }>
      { showDownloadButton }
      { showDeleteButton }
    </div>
  );

  const progressHtml = downloadItem ? (
    <div>
      <progress
        id={ PROGRESS_BAR.ID }
        value={ progressBar }
        max={ PROGRESS_BAR.MAX }
        className={ styles.progress }
      />
    </div>
  ) : null;

  const status = validationDownloadDoc ? (
    <Text type='NORMAL_14' color='red' className={ styles.status }> { validationDownloadDoc } </Text>
  ) : <Text type='NORMAL_14' color='gray' className={ styles.status }> { updateStatusText } </Text>;

  return (
    <div className={ styles.wrap }>
      <div className={ `${styles.expense} ${styles.item_wrapper}` }>
        <Icon
          type='menuReports'
          className={ styles.doc }
        />
        <div className={ styles['name-and-date'] }>
          <Text type='bold_16' className={ styles.name }>
            { fileName }
          </Text>
          { status }
        </div>
        <Text className={ styles.amount }>
          { fileSize || sizeText }
        </Text>
        <div className={ styles.buttons }>
          { actionsContent }
        </div>
      </div>
      { progressHtml }
    </div>
  );
};

export { DocumentItem };
