import React from 'react';
import { Text, Tooltip } from 'new-ui';

import { isSmartAgent } from '../../../../../../bi/utils/env';

import { IEmployee } from '../../../../../../bi/services/settings/types';
import { ICompany } from '../../../../../../bi/services/workspace/types';

import styles from './index.module.css';

interface IEmployeSettingsComponent {
  employee: IEmployee,
  companies: ICompany[],
  anchorPrefix: string,
  hasTripTags: boolean,
}

const Employee = ({ employee, companies, anchorPrefix, hasTripTags }: IEmployeSettingsComponent) => {
  const { Surname, Name, Patronymic, MobilePhone, Email, Companies, readableTags } = employee;

  const isMultiCompany = companies.length > 1;

  const renderMultiCompany = () => {
    const companiesName = Companies.map(company => company.ShortCompanyName).join(', ');

    return (
      <div className={ styles['companies-wrap'] }>
        <Tooltip
          className={ styles['tooltip-wrap'] }
          renderContent={ () =>
            <Text className={ styles.tooltip } color='white'>
              { companiesName }
            </Text>
          }
        >
          <Text className={ styles.companies }>
            { companiesName }
          </Text>
        </Tooltip>
      </div>
    );
  };

  const multiCompanyContent = isMultiCompany && renderMultiCompany();

  const tagsContent = !isSmartAgent && hasTripTags &&
    <Text
      color='gray'
      type='NORMAL_14'
      className={ styles.tag }
    >
      { readableTags }
    </Text>;

  const name = `${Surname} ${Name} ${Patronymic || ''}`;

  const prefixId = `${anchorPrefix}${(Surname || ' ')[0]}`;

  return (
    <div className={ styles.employee }>
      <div className={ styles.anchor } id={ prefixId } />
      <div className={ styles.row }>
        <div className={ styles.name }>
          <Text type='SEMIBOLD_16'>
            { name }
          </Text>
          { tagsContent }
        </div>
        <Text className={ styles.phone }>
          { MobilePhone }
        </Text>
        <Text className={ styles.email }>
          { Email }
        </Text>
        { multiCompanyContent }
      </div>
    </div>
  );
};

export { Employee };
