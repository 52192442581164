import { getText } from '../../../i18n';
import { IOperationsList, ISourceOperationsList } from '../types/report';

const LABELS = {
  INVOICE: (invoiceNum: string) => getText('services:report.invoice', { invoiceNum }),
};

const createItems = (list: ISourceOperationsList[]): IOperationsList[] =>
  list.map((item: ISourceOperationsList): IOperationsList => {
    let debit = item.Debit;
    let credit = item.Credit;

    let isOutgoing = item.Debit === 0 && item.Type !== '3';

    if (debit > 0 && credit > 0) {
      const result = debit - credit;

      if (result > 0) {
        debit = result;
        credit = 0;
        isOutgoing = false;
      } else {
        credit = Math.abs(result);
        debit = 0;
        isOutgoing = true;
      }
    }

    return {
      ...item,
      Debit: debit,
      Credit: credit,
      Description: JSON.parse(item.Description),
      InvoiceName: item.InvoiceNum ? LABELS.INVOICE(item.InvoiceNum) : '',
      IsOutgoing: isOutgoing,
    };
  });

export { createItems };
