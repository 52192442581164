import React from 'react';
import { Button, Dialog, Text } from 'new-ui';
import { getText } from '../../../../../../../i18n';

import styles from './index.module.css';

const LABELS = {
  CONFIRMATION_TEXT: getText('settings:integration.confirmationDialog.text'),
  ACCEPT: getText('settings:integration.confirmationDialog.accept'),
  DECLINE: getText('common:undo'),
};

interface IConfirmationDialog {
  show: boolean,
  loading: boolean,
  onClose(): void,
  disableIntegration(): void,
}

const ConfirmationDialog = ({
  show,
  onClose,
  disableIntegration,
  loading,
}: IConfirmationDialog) => (
  <Dialog
    show={ show }
    onChange={ onClose }
  >
    <>
      <Text>
        { LABELS.CONFIRMATION_TEXT }
      </Text>
      <div className={ styles.actions }>
        <Button
          onClick={ disableIntegration }
          loading={ loading }
        >
          { LABELS.ACCEPT }
        </Button>
        <Button
          type='textual-medium'
          className={ styles.secondary }
          onClick={ onClose }
          disabled={ loading }
        >
          { LABELS.DECLINE }
        </Button>
      </div>
    </>
  </Dialog>
);

export { ConfirmationDialog };
