import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Price } from 'new-ui';
import { getText } from '../../../../i18n';

import { getEmployeeFullNameWithSimplicity } from '../../../bi/utils/employees';
import { formatRangeDateWithSimplicity } from '../../../bi/utils/formatDate';

import { AEROEXPRESS_TARIFF_MAP } from '../../../bi/services/aeroexpress/const';
import { TravelApprovalSpendingType } from '../../../bi/services/travelApproval/consts';

import { SERVICETYPE } from '../../../bi/constants/serviceType';
import ROUTES from '../../../bi/constants/routes';
import { MAPCARCLASS_RU } from '../../../bi/constants/transfer';

import styles from '../styles/anotherExpenses.module.css';

const LABELS = {
  ROOM: getText('expenseReports:report.room'),
  AIR: getText('expenseReports:report.serviceTypes.air'),
  TRAIN: getText('expenseReports:report.serviceTypes.train'),
  CUSTOM: getText('expenseReports:report.serviceTypes.custom'),
  TRANSFER: getText('expenseReports:report.serviceTypes.transfer'),
  TAXI: getText('expenseReports:report.serviceTypes.taxi'),
};

interface IAnotherExpenses {
  item: TravelApprovalSpendingType & {
    JsonData?: string
    Voucher?: any
  },
  employee: any,
  className?: string,
  isApprovalPage?: boolean,
  attrPrice?: string,
  attrExpense?: string,
}

const AnotherExpenses = ({
  item: {
    TripId,
    Amount,
    JsonData,
    Data,
    ServiceType,
    Voucher,
  },
  employee,
  className = '',
  isApprovalPage = false,
  attrExpense = '',
  attrPrice = '',
}: IAnotherExpenses) => {
  let text: string;

  const data = JSON.parse(JsonData || Data || '');
  const { CheckinDate, CheckoutDate } = data;

  const updateEmployee = isApprovalPage ? [...employee] : [employee];

  const employeeText = updateEmployee.map(emp => getEmployeeFullNameWithSimplicity(emp, true)).join(', ');

  const datesText = formatRangeDateWithSimplicity(CheckinDate, CheckoutDate, { withoutSpaceBetweenDates: true });

  switch (ServiceType) {
    case SERVICETYPE.AIR: {
      const { Routes, TicketsExtended } = data;

      const route = Routes.reduce((r: any, { Segments }: { Segments: any }) => {
        const firstSegment = Segments[0];
        const lastSegment = Segments[Segments.length - 1];

        return [...r, `${firstSegment.DepartureCity} \u2013 ${lastSegment.ArrivalCity}`];
      }, []).join('; ');

      const number = TicketsExtended.length && TicketsExtended[0].Num ? ` #${TicketsExtended[0].Num}` : '';

      text = `${LABELS.AIR} ${route}${number}, ${employeeText}, ${datesText}`;

      break;
    }
    case SERVICETYPE.HOTEL: {
      const { Name, Room: { Category } } = data;

      const room = Category ? `${LABELS.ROOM} ${Category[0].toUpperCase()}${Category.slice(1).toLowerCase()}, ` : '';

      text = `${Name}, ${room}${employeeText}, ${datesText}`;

      break;
    }
    case SERVICETYPE.TRAIN: {
      const { StationDepart, StationArrive, TicketNumber } = data;

      text = `${LABELS.TRAIN} ${StationDepart} - ${StationArrive}, ${TicketNumber}, ${employeeText}, ${datesText}`;

      break;
    }
    case SERVICETYPE.TRANSFER: {
      const { StartPlace: { Address: startName }, EndPlace: { Address: endName }, CarClass } = data;

      const carClass = MAPCARCLASS_RU[CarClass];

      text = `${LABELS.TRANSFER} ${startName} - ${endName}, ${carClass}, ${employeeText}, ${datesText}`;

      break;
    }
    case SERVICETYPE.AEROEXPRESS: {
      const { Name, Ticket: { Id: ticketId, TariffType } } = data;

      const tariff = AEROEXPRESS_TARIFF_MAP[TariffType];

      text = `${Name} ${tariff} #${ticketId}, ${employeeText}, ${datesText}`;

      break;
    }
    case SERVICETYPE.CUSTOM: {
      const { Name } = data;

      text = `${LABELS.CUSTOM} ${Name}, ${employeeText}, ${datesText}`;

      break;
    }
    case SERVICETYPE.TAXI_VOUCHER: {
      if (!Voucher) {
        text = `${LABELS.TAXI}`;

        break;
      }

      const { Operations, StartDate, EndDate } = Voucher;
      const route = Operations.reduce((r: any, { Route }: { Route: any }) => {
        const routePreLength = Route[Route.length - 1];
        const firstAddress = `г. ${Route[0].City}, ${Route[0].Address.Street}, ${Route[0].Address.Number}`;
        const lastAddress = `г. ${routePreLength.City}, ${routePreLength.Address.Street} ${routePreLength.Address.Number}`;

        return [...r, `${firstAddress} \u2013 ${lastAddress}`];
      }, []).join('; ');

      // @ts-ignore
      const dates = formatRangeDateWithSimplicity(StartDate, EndDate, { withoutSpaceBetweenDates: true });

      text = `${LABELS.TAXI} ${route}, ${employeeText}, ${dates}`;

      break;
    }
    default: {
      text = `${employeeText}, ${datesText}`;

      break;
    }
  }

  return (
    <div className={ `${className} ${styles.wrapper}` }>
      <div className={ styles.info }>
        <Link
          data-qa={ attrExpense }
          to={ `${ROUTES.TRIP.MAIN}/${TripId}` }
          target='_blank'
        >
          <Button
            className={ styles.link }
            type='textual'
            onClick={ () => {} }
          >
            {text}
          </Button>
        </Link>
      </div>
      <Price
        marginSmall
        qaAttr={ attrPrice }
        type='SEMIBOLD_16'
        value={ Amount || 0 }
      />
    </div>
  );
};

export default AnotherExpenses;
