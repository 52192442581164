import React from 'react';
import { observer } from 'mobx-react';
import { Dialog, Text } from 'new-ui';

import { useServices } from '../../../../bi/context/services';

import { formatDate } from '../../../../bi/utils/formatDate';

import { LABELS } from '../../../Cart/components/ConsistentCart/ConsistentCartItem';
import { PATTERN } from '../../../../bi/constants/dateFormats';

import { FactualApprovesLiteResponse } from '../../../../bi/services/approve/types';

import styles from './styles.module.css';

interface IApproveComment {
  message: FactualApprovesLiteResponse['Messages'][0],
  isOffline: boolean,
  ind: number,
}

const ApproveComment = ({ message, isOffline, ind }: IApproveComment) => {
  const messageName = isOffline && ind === 0 ? LABELS.TRAVEL_ASSISTANT : message.Name;

  if (message.Comment) {
    return (
      <Text type='NORMAL_16_140'>
        { `${formatDate(message.Date, PATTERN.FULLDATEWITHTIME)}, ${messageName} - ${message.Comment}` }
      </Text>
    );
  }

  return null;
};

export const CommentsDialog = observer(() => {
  const {
    approveService: { store: { getApproveByCartId, getIsOfflineByCartId } },
    reportService: {
      approveReportStore: { closeCommentDialog, commentsDialog },
    },
    cartService: { get },
  } = useServices(['Report', 'Approve', 'Cart']);
  const { carts } = get();

  const approve = getApproveByCartId.get()(commentsDialog);
  const isOffline = getIsOfflineByCartId.get()(commentsDialog, carts) || false;

  const approveCommentsListHtml = approve?.Messages.map((msgObj, ind) => (
    <div key={ `${ind}` }>
      <ApproveComment message={ msgObj } isOffline={ isOffline } ind={ ind } />
    </div>
  )) || [];

  return (
    <Dialog
      onChange={ closeCommentDialog }
      show={ !!commentsDialog }
      showClosing
    >
      <div className={ styles.dialogContent }>
        <Text
          type='bold_20'
        >
          { LABELS.COMMENT_HISTORY }
        </Text>
        <div>
          { ...approveCommentsListHtml }
        </div>
      </div>
    </Dialog>
  );
});
