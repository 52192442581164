import React, { FC } from 'react';
import { MultiSelect } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { isSmartAgent } from '../../../../../bi/utils/env';

import { Department } from '../../../../../bi/types/trips';

import styles from '../index.module.css';

const LABELS = {
  COMPANIES: getText('trips:companies.companies'),
  COMPANIES_WITH_DEPARTMENTS: getText('trips:companies.companiesWithDepartments'),
  DEPARTMENTS: getText('trips:companies.departments'),
  VALUE: getText('trips:companies.companies'),
  COMPANIES_PLACEHOLDERS: {
    COMPANY: getText('trips:companiesPlaceholders.company'),
    COMPANY_OR_DEPARTMENT: getText('trips:companiesPlaceholders.companyOrDepartment'),
    DEPARTMENT: getText('trips:companiesPlaceholders.department'),
  },
};

const getDepartmentsForSelect = (department: Department) => {
  const nested = department.Departments.map((departmentValue) => ({ value: departmentValue.Id, label: departmentValue.Name }));

  return ({ value: department.CompanyId, label: department.ShortCompanyName?.length ? department.ShortCompanyName : department.CompanyName, nested });
};

interface FilterDepartmentsProps {
  departments: Department[];
  selected: Department[];
  setSelected: React.Dispatch<React.SetStateAction<Department[]>>;
  onChange(departmentsChecked: number[], companiesChecked: number[], checkedDepartments: any): void;
}

const FilterDepartments: FC<FilterDepartmentsProps> = ({
  departments,
  onChange,
  selected,
  setSelected,
}) => {
  const departmentsList = departments.map(department => getDepartmentsForSelect(department));

  const handleChange = (checkedDepartments: any) => {
    setSelected(checkedDepartments);

    const departmentsChecked: number[] = [];
    const companiesChecked = checkedDepartments.map(({ main }: { main: number }) => Number(main));
    checkedDepartments.forEach(({ nested }: { nested: string[] }) => nested.forEach((id) => departmentsChecked.push(Number(id))));
    onChange(departmentsChecked, companiesChecked, checkedDepartments);
  };

  const searchPlaceholderText = isSmartAgent
    ? LABELS.COMPANIES_PLACEHOLDERS.COMPANY
    : LABELS.COMPANIES_PLACEHOLDERS.COMPANY_OR_DEPARTMENT;

  const placeholderCompaniesText = isSmartAgent
    ? LABELS.COMPANIES
    : LABELS.COMPANIES_WITH_DEPARTMENTS;

  return (
    <div className={ styles.filter }>
      <MultiSelect
        withLabel
        placeholder={ placeholderCompaniesText }
        list={ departmentsList }
        values={ selected }
        search
        searchPlaceholder={ searchPlaceholderText }
        onChange={ handleChange }
      />
    </div>
  );
};

export { FilterDepartments };
