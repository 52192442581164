import { observer } from 'mobx-react';
import {
  BackLink,
  Button,
  Dialog,
  DotLoading,
  Dropdown,
  IconButton,
  LinkButton,
  List,
  PageLoader,
  Text,
  Textarea,
  Tooltip,
} from 'new-ui';

import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';

import { getText, getTextArray } from '../../../i18n';
import { PATTERN } from '../../bi/constants/dateFormats';
import {
  ApproveStatus,
  DIALOGS_FIELD,
  FIELDS,
  PREPAYMENTS,
  PREPAYMENTS_METHOD,
  PREPAYMENTS_TYPE,
  Status1S,
  STATUS_PAGE,
  STEPS_RESOLUTIONS,
  VALIDATION_DOC_DOWNLOAD,
} from '../../bi/constants/expenseReport';
import FORMATS from '../../bi/constants/formats';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { CREATE_EXPENSE_REPORT } from '../../bi/constants/rights';
import ROUTES from '../../bi/constants/routes';
import { ERROR_CODE } from '../../bi/constants/app';

import type ExpenseReportsService from '../../bi/services/expenseReports';
import type { ExpenseReportSingleStore } from '../../bi/services/expenseReports/stores/expenseReport';
import { ExpenseReportsStore } from '../../bi/services/expenseReports/stores/expenseReports';
import type { DocumentsType, ExpensesType, PrepaymentsType } from '../../bi/services/expenseReports/types';
import { AnalyticAddExpense, AnalyticAdvanceStatus } from '../../bi/services/expenseReports/types';
import FeatureFlagsService from '../../bi/services/featureFlags';
import AccountSettings from '../../bi/services/accountSettings';

import { getEmployeeFullName } from '../../bi/utils/employees';
import {
  diffDays,
  formatDate,
  formatRangeDateWithSimplicity,
  momentObject,
  momentObjectUTC, momentUtc,
} from '../../bi/utils/formatDate';
import MoneyFormat from '../../bi/utils/money';
import toDecline from '../../bi/utils/toDecline';
import { isSmartAgent } from '../../bi/utils/env';
import { MainAnalytic } from '../../bi/utils/analytics';

import InputModal from '../../components/InputModal';
import { StatusMarker } from '../../components/StatusMarker';
import DownloadDialog from '../../components/UploadDocument';
import ConfirmDialog from '../../components/ConfirmDialog';

import ApproveSchemeDialog from './components/dialogs/schemeApprove';
import ExpenseDialog from './components/dialogs/expense';
import { UsualApproveDialog } from './components/dialogs/usualApprove';
import PrepaymentDialog from './components/dialogs/prepayment';

import AnotherExpenses from './components/anotherExpenses';
import { ApproverContent } from './components/approverContent';
import { DocumentList } from './components/documentList';

import { TravelApprovalSpendingType } from '../../bi/services/travelApproval/consts';

import styles from './styles/index.module.css';

type Expense = {
  dialog: boolean,
  item: ExpensesType | null,
  index: number | null | undefined,
};

type Prepayment = {
  dialog: boolean,
  item: PrepaymentsType | null,
  index: number | null,
};

interface Document {
  dialog: boolean,
  item: DocumentsType | null,
  index: number,
}

type ExpenseReportPropsType = {
  expenseReportsService: ExpenseReportsService,
  expenseReportStore: ExpenseReportSingleStore,
  expenseReportsStore: ExpenseReportsStore,
  history: {
    goBack: () => void,
    push(value: string): void,
  },
  match: any,
  notificationService: any,
  workspaceService: any,
  expenseReportsProtocol: any
  featureFlagsService: FeatureFlagsService,
  accountSettingsService: AccountSettings;
};

type ExpenseReportStateType = {
  expense: Expense,
  prepayment: Prepayment,
  document: Document,
  deleteDialog: boolean,
  sendMailDialog: {
    show: boolean,
    loading: boolean,
  },
  comment: string,
  downloadLoading: boolean,
  progressBar: number,
  sizeText: string,
  statusText: string,
};

const TYPE = 'ACTION';
const BYTES_IN_MB = 1048576;
const SIZE_FILE = 11;

const LABELS = {
  BACK: getText('expenseReports:report.back'),
  CREATE: getText('expenseReports:report.create'),
  EDIT_TITLE: getText('expenseReports:report.editTitle'),
  ACCOUNTABLE_PERSON: getText('expenseReports:report.accountablePerson'),
  PREPAYMENTS: getText('expenseReports:report.prepayments'),
  ADVANCE: getText('expenseReports:report.advance'),
  EXPENSES: getText('expenseReports:report.expenses'),
  SUB_EXPENSES: getText('expenseReports:report.subExpenses'),
  DOCUMENT: getText('expenseReports:report.document'),
  SUB_DOCUMENT: getText('expenseReports:report.subDoc'),
  ANOTHER_EXPENSES: getText('expenseReports:report.anotherExpenses'),
  SUB_ANOTHER_EXPENSES: getText('expenseReports:report.subAnotherExpenses'),
  SAVE: getText('common:save'),
  SEND_TO_APPROVE: getText('common:sendToApprove'),
  CREATE_ER: getText('common:create'),
  APPROVE: getText('common:approve'),
  DECLINE: getText('common:decline'),
  CANCEL: getText('common:undo'),
  EDIT: getText('expenseReports:report.edit'),
  DELETE: getText('expenseReports:report.delete'),
  DOWNLOAD_ARCHIVE: getText('expenseReports:report.downloadArchive'),
  TOOLTIP_SAVE: getText('expenseReports:report.tooltipSave'),
  NOT_AVAILABLE_IN_DEMO: getText('expenseReports:report.notAvailableInDemo'),
  EXIT_MESSAGE: getText('expenseReports:report.exitMessage'),
  ADD_BUTTONS: {
    PREPAYMENTS: getText('expenseReports:report.addButtons.prepayments'),
    EXPENSES: getText('expenseReports:report.addButtons.expenses'),
  },
  LOADING: getText('expenseReports:report.loader'),
  REMOVE_REPORT: getText('expenseReports:report.removeReport'),
  CANCEL_REPORT: getText('expenseReports:report.cancelReport'),
  SAVED: getText('expenseReports:report.saved'),
  SAVE_ERROR: getText('expenseReports:report.saveError'),
  SENDING_TO_APPROVE: getText('expenseReports:report.sendToApprove'),
  SENDING_TO_APPROVE_ERROR: getText('expenseReports:report.sendToApproveError'),
  DELETED: getText('expenseReports:report.deleted'),
  DELETE_ERROR: getText('expenseReports:report.deleteError'),
  DAY_DECLENSIONS: getTextArray('expenseReports:report.dayDeclensions'),
  CREATED: getText('expenseReports:report.created'),
  UPDATED: getText('expenseReports:report.updated'),
  SEND_TO_EMAIL_TITLE: getText('expenseReports:report.sendToEmailTitle'),
  DOWNLOAD: {
    PDF: getText('expenseReports:report.download.pdf'),
    EXCEL: getText('expenseReports:report.download.excel'),
  },
  WAS_SENT: (email: string) => getText('expenseReports:report.wasSent', { email }),
  DOWNLOAD_ERROR: getText('expenseReports:report.downloadError'),
  WITHOUT_DAY_PAYMENTS: getText('expenseReports:report.withoutDayPayments'),
  SEND_TO_EMAIL: getText('expenseReports:report.sendToEmail'),
  DELETE_DIALOG: {
    TITLE: getText('expenseReports:report.deleteDialog.title'),
    TITLE_FOR_APPROVED: getText('expenseReports:report.deleteDialog.titleForApproved'),
    YES_DELETE: getText('expenseReports:report.deleteDialog.yesDel'),
    YES: getText('expenseReports:report.deleteDialog.yes'),
    NO: getText('expenseReports:report.deleteDialog.no'),
  },
  DELETE_DOCUMENT_DIALOG: {
    TITLE: getText('expenseReports:report.deleteDocumentDialog.title'),
    DESCRIPTION: getText('expenseReports:report.deleteDocumentDialog.description'),
  },
  COMMENT_FOR_APPROVE: getText('expenseReports:report.commentForApprove'),
  CONFIRM_ACTION: getText('expenseReports:report.confirmAction'),
  HISTORY: getText('expenseReports:report.history'),
  PROGRESS: {
    SIZE_TEXT: (loadedMb: string, totalSizeMb: string) => getText('expenseReports:report.progress.sizeText', { loadedMb, totalSizeMb }),
    STATUS_TEXT: (percentLoaded: number) => getText('expenseReports:report.progress.statusText', { percentLoaded }),
  },
};

const ATTRS_DIALOG = {
  qaAttrDialog: QA_ATTRIBUTES.expenseReport.item.sendDialog.close,
  qaAttrInput: QA_ATTRIBUTES.expenseReport.item.sendDialog.email,
  qaAttrSend: QA_ATTRIBUTES.expenseReport.item.sendDialog.buttonSend,
  qaAttrClose: QA_ATTRIBUTES.expenseReport.item.sendDialog.buttonClose,
  qaAttrTitle: QA_ATTRIBUTES.expenseReport.item.sendDialog.title,
};

const PREPAYMENT_ANALYTIC = {
  [PREPAYMENTS.PREPAYMENT]: MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_PREPAID_EXPENSE_PRESSED,
  [PREPAYMENTS.DAILY_EXPENSES]: MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_EDIT_DAILY_PRESSED,
};

const canEditReports = (createExpenseReports: string, isAdmin: boolean, creator?: string) => (createExpenseReports === CREATE_EXPENSE_REPORT.Unlimited || isAdmin) || !creator;

@observer
class ExpenseReportPage extends Component<ExpenseReportPropsType, ExpenseReportStateType> {
  state: ExpenseReportStateType = {
    expense: {
      dialog: false,
      item: null,
      index: 0,
    },
    prepayment: {
      dialog: false,
      item: null,
      index: 0,
    },
    document: {
      dialog: false,
      item: null,
      index: 0,
    },
    deleteDialog: false,
    sendMailDialog: {
      show: false,
      loading: false,
    },
    comment: '',
    downloadLoading: false,
    progressBar: 0,
    sizeText: '',
    statusText: '',
  };

  createExpenseReports = this.props.workspaceService.canCreateExpenseReports;
  rights = this.props.workspaceService.rights;
  isAdmin = this.props.workspaceService.isAdmin;
  email = this.props.workspaceService.get().Email;

  async componentDidMount() {
    const {
      expenseReportsService: {
        loadSettingsForReport,
        checkEmptyPage,
        getPrefiledValues,
      },
      expenseReportsProtocol,
      match,
      featureFlagsService: {
        getPrefilledExpenseValues,
      },
    } = this.props;

    const reportId = match.params.id;

    if (getPrefilledExpenseValues()) {
      await getPrefiledValues();
    }

    if (reportId) {
      await expenseReportsProtocol.loadExpenseReport(reportId);
    }

    if (checkEmptyPage() && !reportId) {
      return this.handleGoBack();
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_SCREEN_OPENED);

    return loadSettingsForReport();
  }

  componentDidUpdate() {
    const { isValid, forcedPrompt } = this.props.expenseReportStore;

    // @ts-ignore
    window.onbeforeunload = forcedPrompt && !isValid ? (e) => {
      e.preventDefault();

      return '';
    } : undefined;
  }

  componentWillUnmount() {
    // @ts-ignore
    window.onbeforeunload = undefined;
    this.props.expenseReportsService.resetStoreReport();
  }

  showNotification = (message: string) => {
    const { notificationService } = this.props;

    notificationService.send({ message, qaAttr: QA_ATTRIBUTES.expenseReport.create.notification });
  };

  setDeleteDialog = (value: boolean): void => this.setState({ deleteDialog: value });

  setDialog = (value = false, type = ''): void => this.props.expenseReportsService.setDialog(value, type);

  setComment = (value: string) => this.setState({ comment: value });

  setSendMailDialog = (value: any) => this.setState({ sendMailDialog: { ...this.state.sendMailDialog, ...value } });

  handleGoBack = (): void => this.props.history.goBack();

  handleSendToMail = async (email: string) => {
    const { expenseReportsService, expenseReportStore, notificationService } = this.props;

    try {
      this.setSendMailDialog({ loading: true });
      await expenseReportsService.sendEmail(email, expenseReportStore.report.Id);
      this.setSendMailDialog({ show: false, loading: false });
      setTimeout(() => notificationService.send({ message: LABELS.WAS_SENT(email) }), 300);
    } catch (e) {
      this.setSendMailDialog({ loading: false });
    }
  };

  handleDownload = async (format: string) => {
    const { expenseReportsService, expenseReportStore, notificationService } = this.props;

    try {
      this.setState({ downloadLoading: true });
      await expenseReportsService.downloadReport(format, expenseReportStore.report.Id);
      this.setState({ downloadLoading: false });
    } catch (e) {
      this.setState({ downloadLoading: false });
      notificationService.send({ message: LABELS.DOWNLOAD_ERROR });
    }
  };

  handleSave = (withReturning = true) => {
    const { expenseReportsService } = this.props;

    return expenseReportsService.saveReport().then(
      (r: any) => {
        if (withReturning) {
          this.showNotification(LABELS.SAVED);

          MainAnalytic.sendAmplitudeArrayArgs(
            MainAnalytic.ACTIONS.REPORTING.EXPENSE_DETAIL_SAVE_PRESSED(
              AnalyticAdvanceStatus.DELIVERED,
              r?.Stamp,
              r?.Id,
            ),
          );

          return this.handleGoBack();
        }

        return expenseReportsService.openReport(r);
      },
      () => {
        this.showNotification(LABELS.SAVE_ERROR);

        MainAnalytic.sendAmplitudeArrayArgs(
          MainAnalytic.ACTIONS.REPORTING.EXPENSE_DETAIL_SAVE_PRESSED(
            AnalyticAdvanceStatus.ERROR_DELIVERED,
            momentUtc().format(),
            expenseReportsService.storeReport.dataForSave.Id,
          ),
        );
      });
  };

  handleOpenPrepaymentDialog = (value: boolean, item: PrepaymentsType | null = null, ind?: number | null): void => {
    this.setState({
      prepayment: {
        ...this.state.prepayment,
        dialog: value,
        item: value ? item : null,
        index: ind || this.state.prepayment.index,
      },
    });

    if (value) {
      MainAnalytic.sendAmplitude(
        item
          ? PREPAYMENT_ANALYTIC[item.Type]
          : PREPAYMENT_ANALYTIC[PREPAYMENTS.PREPAYMENT],
      );
    }
  };

  handleOpenExpenseDialog = (value: boolean, item?: any | null, index?: number | null): void => {
    this.setState({
      expense: {
        dialog: value,
        item: value ? item : null,
        index,
      },
    });

    if (!value) {
      MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_CONSUMPTION_PRESSED(AnalyticAddExpense.CANCEL),
      );
    }
  };

  handleOpenDocumentDialog = (value: boolean, item: any, ind: number | null): void => {
    this.setState({
      document: {
        dialog: value,
        item: value ? item : null,
        index: ind || this.state.document.index,
      },
    });
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_DOCUMENT_PRESSED);
  };

  handleDeleteReport = () => {
    const { expenseReportsService, expenseReportStore: { report: { Id } } } = this.props;

    expenseReportsService.deleteExpenseReport(Id)
      .then(
        () => {
          this.showNotification(LABELS.DELETED);
          this.handleGoBack();
        },
        () => {
          this.showNotification(LABELS.DELETE_ERROR);
        },
      );
  };

  handleCancelReport = async () => {
    const { expenseReportsService, expenseReportStore: { report: { Id } } } = this.props;

    try {
      await expenseReportsService.cancelApprovedExpenseReport(Id);
    } finally {
      this.setDialog(false, DIALOGS_FIELD.CANCEL);
      this.handleGoBack();
    }
  };

  handleSendToApprove = async (values: any, scheme = false) => {
    const { expenseReportsService } = this.props;
    const { EmployeeId } = this.rights;

    const preparedValues = {
      ...values,
      Email: this.email,
      EmployeeId,
    };

    try {
      await expenseReportsService.sendReportToApprove(preparedValues, scheme);

      this.showNotification(LABELS.SENDING_TO_APPROVE);
    } catch {
      this.showNotification(LABELS.SENDING_TO_APPROVE_ERROR);
    } finally {
      this.setDialog(false, DIALOGS_FIELD.APPROVE);
      this.setDialog(false, DIALOGS_FIELD.APPROVE_SCHEME);
      this.handleGoBack();
    }
  };

  analyticApproveExpenseReportByStatus = (status: ApproveStatus) => {
    switch (status) {
      case ApproveStatus.ACCEPTED:
        return MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_REFUSE_APPROVAL_PRESSED;
      case ApproveStatus.DECLINED:
        return MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_REFUSE_APPROVAL_PRESSED;
      default:
        return '';
    }
  };

  handleApprove = async (field: string | number, comment: string) => {
    const {
      expenseReportsService,
      expenseReportStore: { report: { Id } },
      history,
    } = this.props;

    try {
      this.setComment('');

      await expenseReportsService.approveExpenseReport(
        field,
        comment,
        Id as unknown as string,
      );

      MainAnalytic.sendAmplitude(
        this.analyticApproveExpenseReportByStatus(field as ApproveStatus),
      );
    } finally {
      this.setDialog(false, DIALOGS_FIELD.APPROVED);
      this.setDialog(false, DIALOGS_FIELD.DECLINE);

      history.push(ROUTES.APPROVE.EXPENSE_REPORTS);
    }
  };

  progressHandler = (event: any) => {
    const loadedMb = (event.loaded / BYTES_IN_MB).toFixed(2);
    const totalSizeMb = (event.total / BYTES_IN_MB).toFixed(2);
    const percentLoaded = Math.round((event.loaded / event.total) * 100);

    this.setState({
      progressBar: percentLoaded,
      sizeText: LABELS.PROGRESS.SIZE_TEXT(loadedMb, totalSizeMb),
      statusText: LABELS.PROGRESS.STATUS_TEXT(percentLoaded),
    });
  };

  validatedAndSendFile = async (doc: any, ind: number) => {
    const { expenseReportsService } = this.props;

    if ((doc.size / BYTES_IN_MB) < SIZE_FILE) {
      await expenseReportsService.sendFile(doc, this.progressHandler as unknown as boolean, ind);
    } else {
      expenseReportsService.setValidationDownloadDoc(VALIDATION_DOC_DOWNLOAD[ERROR_CODE.REQUEST_ENTITY_TOO_LARGE], ind);
    }
  };

  handleAddDocument = async (doc: any, ind: number) => {
    this.handleOpenDocumentDialog(false, doc, ind);
    await this.validatedAndSendFile(doc, ind);
  };

  sendToApprove = (selectDialog: string) => () => {
    this.setDialog(true, selectDialog);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.NEW_EXPENSE_ON_APPROVAL_BUTTON_PRESSED);
  };

  renderPrompt = () => {
    const { isValid, forcedPrompt } = this.props.expenseReportStore;

    return (
      <Prompt
        when={ forcedPrompt && isValid }
        message={ LABELS.EXIT_MESSAGE }
      />
    );
  };

  renderPrepaymentsItem = (item: PrepaymentsType, index: number, disabled: boolean) => {
    const { expenseReportStore: { report: { CheckinDate, CheckoutDate } } } = this.props;
    const { Method, Type, Amount } = item;

    const actionsContent = Type === PREPAYMENTS.PREPAYMENT ? (
      <>
        <Button
          type='textual'
          onClick={ () => this.handleOpenPrepaymentDialog(true, item, index) }
          disabled={ disabled }
        >
          { LABELS.EDIT }
        </Button>
        <Button
          type='textual'
          onClick={ () => this.props.expenseReportsService.deletePrepaymentsItem(index) }
          className={ styles.delete }
          disabled={ disabled }
        >
          { LABELS.DELETE }
        </Button>
      </>
    ) : (
      <>
        <Button
          type='textual'
          onClick={ () => this.handleOpenPrepaymentDialog(true, item) }
          disabled={ disabled }
        >
          { LABELS.EDIT }
        </Button>
      </>
    );

    const daysCount = diffDays(CheckinDate, CheckoutDate) + 1;
    const typePostfix = Type === PREPAYMENTS.DAILY_EXPENSES ? (
      `(${daysCount} ${toDecline(daysCount, LABELS.DAY_DECLENSIONS)})`
    ) : '';

    const amountContent = Amount ? MoneyFormat.money(Amount, true) : '\u2014';
    const titleContent = Amount ? `${PREPAYMENTS_TYPE[Type].RU} ${typePostfix}` : LABELS.WITHOUT_DAY_PAYMENTS;

    return (
      <div className={ `${styles.prepayment} ${styles.item_wrapper}` }>
        <Text type='bold_16' className={ styles.name }>
          { titleContent }
        </Text>
        <Text className={ styles.amount }>
          { amountContent }
        </Text>
        <Text className={ styles.method }>
          { PREPAYMENTS_METHOD[Method].RU }
        </Text>
        <div className={ styles.buttons }>
          { actionsContent }
        </div>
      </div>
    );
  };

  renderDeclineDialog = () => {
    const { expenseReportStore: { dialogs: { declineDialog }, loadings: { loadingDeclinedButton } } } = this.props;
    const { comment } = this.state;

    if (!declineDialog) return null;

    return (
      <Dialog
        qaAttr={ QA_ATTRIBUTES.expenseReport.approver.close }
        showClosing
        show={ declineDialog }
        onChange={ () => this.setDialog(false, DIALOGS_FIELD.DECLINE) }
      >
        <div className={ styles['dialog-content'] }>
          <Text type='bold_20' className={ styles['dialog-header'] }>
            { LABELS.CONFIRM_ACTION }
          </Text>
          <Textarea
            qaAttr={ QA_ATTRIBUTES.expenseReport.approver.comment }
            value={ comment }
            onChange={ this.setComment }
          />
          <div className={ styles['dialog-actions'] }>
            <Button
              qaAttr={ QA_ATTRIBUTES.expenseReport.approver.decline }
              type='primary'
              loading={ loadingDeclinedButton }
              onClick={ () => this.handleApprove(ApproveStatus.DECLINED, comment) }
            >
              { LABELS.DECLINE }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  renderConfirmDeleteDocumentDialog = () => {
    const {
      expenseReportStore: { showDeleteDocumentsItemDialog },
      expenseReportsService: { openDeleteDocumentsItemDialog, deleteDocumentsItem },
    } = this.props;

    return (
      <ConfirmDialog
        visible={ showDeleteDocumentsItemDialog }
        title={ LABELS.DELETE_DOCUMENT_DIALOG.TITLE }
        description={ LABELS.DELETE_DOCUMENT_DIALOG.DESCRIPTION }
        onChangeVisible={ () => openDeleteDocumentsItemDialog(null, false) }
        onClick={ deleteDocumentsItem }
        onClose={ () => openDeleteDocumentsItemDialog(null, false) }
        visibleAction
      />
    );
  };

  renderExpensesItem = (item: ExpensesType, index: number, disabled: boolean) => {
    const { Name, Amount, Date } = item;

    return (
      <div className={ `${styles.expense} ${styles.item_wrapper}` }>
        <div className={ styles['name-and-date'] }>
          <Text type='bold_16' className={ styles.name }>
            { Name }
          </Text>
          <Text className={ styles.date } type='NORMAL_14' color='gray'>
            { momentObjectUTC(Date).format(PATTERN.DAY_OF_MONTH_WITH_YEAR) }
          </Text>
        </div>
        <Text className={ styles.amount }>
          { MoneyFormat.moneyWithDecimal(Amount, true) }
        </Text>
        <div className={ styles.buttons }>
          <Button
            type='textual'
            onClick={ () => this.handleOpenExpenseDialog(true, item, index) }
            className={ styles.edit }
            disabled={ disabled }
          >
            { LABELS.EDIT }
          </Button>
          <Button
            type='textual'
            onClick={ () => this.props.expenseReportsService.deleteExpensesItem(index) }
            disabled={ disabled }
          >
            { LABELS.DELETE }
          </Button>
        </div>
      </div>
    );
  };

  renderList = (list: any, field: string) => {
    const {
      expenseReportStore: {
        report: { IsEditingAvailable },
      },
      accountSettingsService: {
        getHidePerDiem,
      },
    } = this.props;

    const isPrepayment = field === FIELDS.PREPAYMENTS;

    const preparedList = IsEditingAvailable ? [...list, { type: TYPE }] : list;

    const onClick = () => (isPrepayment ?
      this.handleOpenPrepaymentDialog(true) :
      this.handleOpenExpenseDialog(true));

    const renderItemFn = (item: any, index: number) => (
      isPrepayment
        ? this.renderPrepaymentsItem(item, index, !IsEditingAvailable)
        : this.renderExpensesItem(item, index, !IsEditingAvailable)
    );

    const addButton: { [key: string]: string } = {
      PREPAYMENTS: getHidePerDiem() ? LABELS.ADVANCE : LABELS.PREPAYMENTS,
      EXPENSES: LABELS.EXPENSES,
    };

    const labelButton = addButton[field];

    const renderFn = (item: any, index: number) => (
      (item.type === TYPE && IsEditingAvailable) ? (
        <div className={ styles.add_panel }>
          <IconButton iconType='plusRound' onClick={ onClick } className={ styles.btn }>
            { labelButton }
          </IconButton>
        </div>
      ) : renderItemFn(item, index));

    const onClickItem = (type: string) => {
      if (type === TYPE) {
        onClick();
      }
    };

    return (
      <div className={ styles.list_wrapper }>
        <List
          items={ preparedList }
          keyExtractor={ (_i, index) => `${index}` }
          onClickItem={ ({ type }) => onClickItem(type) }
          renderItem={ renderFn }
          wrapperClassNameExtractor={ (item) => (item.type === TYPE ? styles.action : styles.item) }
        />
      </div>
    );
  };

  renderAnotherExpenses = () => {
    const { report: { Items, Data: { Employee } } } = this.props.expenseReportStore;

    const listHtml = Items.map((item, index) => (
      <AnotherExpenses
        key={ `${item.Id}_${index}` }
        item={ item as unknown as TravelApprovalSpendingType }
        employee={ Employee }
        attrExpense={ QA_ATTRIBUTES.expenseReport.item.anotherExpense }
        attrPrice={ QA_ATTRIBUTES.expenseReport.item.price }
      />
    ));

    return (
      <div className={ styles.another_expenses }>
        <Text type='bold_20'>
          {LABELS.ANOTHER_EXPENSES}
        </Text>
        <Text color='gray' className={ styles.sub_another }>
          {LABELS.SUB_ANOTHER_EXPENSES}
        </Text>
        { ...listHtml }
      </div>
    );
  };

  renderDeleteDialog = () => {
    const { deleteDialog } = this.state;

    return (
      <Dialog
        qaAttr={ QA_ATTRIBUTES.expenseReport.item.deleteDialog.close }
        show={ deleteDialog }
        showClosing
        onChange={ this.setDeleteDialog }
      >
        <div className={ styles['delete-dialog-content'] }>
          <Text qaAttr={ QA_ATTRIBUTES.expenseReport.item.deleteDialog.title } type='bold_20'>
            { LABELS.DELETE_DIALOG.TITLE }
          </Text>
          <div className={ styles['delete-actions'] }>
            <Button
              qaAttr={ QA_ATTRIBUTES.expenseReport.item.deleteDialog.delete }
              type='primary'
              onClick={ this.handleDeleteReport }
            >
              { LABELS.DELETE_DIALOG.YES_DELETE }
            </Button>
            <Button
              className={ styles.second }
              type='textual-medium'
              onClick={ () => this.setDeleteDialog(false) }
            >
              { LABELS.DELETE_DIALOG.NO }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  renderCancelDialog = () => {
    const { expenseReportStore: { dialogs: { cancelDialog } } } = this.props;

    return (
      <Dialog
        showClosing
        qaAttr={ QA_ATTRIBUTES.expenseReport.item.cancelSendDialog.close }
        show={ cancelDialog }
        onChange={ value => this.setDialog(value, DIALOGS_FIELD.CANCEL) }
      >
        <div className={ styles['delete-dialog-content'] }>
          <Text qaAttr={ QA_ATTRIBUTES.expenseReport.item.cancelSendDialog.title } type='bold_20'>
            { LABELS.DELETE_DIALOG.TITLE_FOR_APPROVED }
          </Text>
          <div className={ styles['delete-actions'] }>
            <Button
              qaAttr={ QA_ATTRIBUTES.expenseReport.item.cancelSendDialog.cancel }
              type='primary'
              onClick={ this.handleCancelReport }
            >
              { LABELS.DELETE_DIALOG.YES }
            </Button>
            <Button
              qaAttr={ QA_ATTRIBUTES.expenseReport.item.cancelSendDialog.return }
              className={ styles.second }
              type='textual-medium'
              onClick={ () => this.setDialog(false, DIALOGS_FIELD.CANCEL) }
            >
              { LABELS.DELETE_DIALOG.NO }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  renderDeleteButton = () => {
    const { expenseReportStore: { report: { Status, Id }, loadings: { loadingDeleteButton } }, workspaceService } = this.props;

    const checkForApprovedReport = Status === ApproveStatus.WAITING_APPROVE && this.createExpenseReports !== CREATE_EXPENSE_REPORT.Unlimited;

    const label = checkForApprovedReport ? LABELS.CANCEL_REPORT : LABELS.REMOVE_REPORT;
    const click = checkForApprovedReport ? () => this.setDialog(true, DIALOGS_FIELD.CANCEL) : () => this.setDeleteDialog(true);

    if (Status === Status1S.DELIVERED || !Id) {
      return null;
    }

    return (
      <Button
        qaAttr={ QA_ATTRIBUTES.expenseReport.item.buttons.delete }
        type='textual'
        disabled={ workspaceService.isDemo }
        onClick={ click }
        loading={ loadingDeleteButton }
      >
        { label }
      </Button>
    );
  };

  renderSendEmail = () => {
    const { sendMailDialog: { show, loading } } = this.state;

    return (
      <InputModal
        show={ show }
        onCancel={ () => this.setSendMailDialog({ show: false }) }
        title={ LABELS.SEND_TO_EMAIL_TITLE }
        onSend={ this.handleSendToMail }
        loading={ loading }
        qaAttrs={ ATTRS_DIALOG }
      />
    );
  };

  renderSendToApproveSchemeDialog = () => {
    const { expenseReportsStore: { approvers }, expenseReportStore: { dialogs: { approveSchemeDialog }, stepsResolutions } } = this.props;

    return (
      <ApproveSchemeDialog
        show={ approveSchemeDialog }
        onClose={ () => this.setDialog(false, DIALOGS_FIELD.APPROVE_SCHEME) }
        approvers={ approvers }
        onSubmit={ values => this.handleSendToApprove(values, true) }
        error={ stepsResolutions === STEPS_RESOLUTIONS.ERROR }
      />
    );
  };

  renderSendToUsualApproveDialog = () => {
    const {
      expenseReportsStore: { usualApprovers },
      expenseReportStore: {
        dialogs: { approveDialog },
        loadings: { loadingSendButton },
      },
    } = this.props;

    return (
      <UsualApproveDialog
        show={ approveDialog }
        loading={ loadingSendButton }
        onClose={ () => this.setDialog(false, DIALOGS_FIELD.APPROVE) }
        approvers={ usualApprovers }
        onSubmit={ this.handleSendToApprove }
      />
    );
  };

  renderApproverDialog = () => {
    const { comment } = this.state;
    const { expenseReportStore: { dialogs: { approvedDialog }, loadings: { loadingSendButton } } } = this.props;

    return (
      <Dialog
        qaAttr={ QA_ATTRIBUTES.expenseReport.approver.close }
        showClosing
        show={ approvedDialog }
        onChange={ () => this.setDialog(false, DIALOGS_FIELD.APPROVED) }
      >
        <div className={ styles['dialog-content'] }>
          <Text type='bold_20' className={ styles['dialog-header'] }>
            { LABELS.CONFIRM_ACTION }
          </Text>
          <Textarea
            qaAttr={ QA_ATTRIBUTES.expenseReport.approver.comment }
            placeholder={ LABELS.COMMENT_FOR_APPROVE }
            value={ comment }
            onChange={ this.setComment }
          />
          <div className={ styles['dialog-actions'] }>
            <Button
              qaAttr={ QA_ATTRIBUTES.expenseReport.approver.approve }
              type='primary'
              loading={ loadingSendButton }
              onClick={ () => this.handleApprove(ApproveStatus.ACCEPTED, comment) }
            >
              { LABELS.APPROVE }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  renderApprover = () => {
    const {
      expenseReportStore: {
        report,
        loadings: { loadingSendButton, addNewDocLoader,
        },
      },
      workspaceService: { isDemo },
    } = this.props;
    const { Approves, Stamp } = report;

    const tooltipRules = addNewDocLoader || report.ImageLinks.find(({ ValidationDownloadDoc }) => ValidationDownloadDoc);
    const tooltipInfo = isDemo ? LABELS.NOT_AVAILABLE_IN_DEMO : LABELS.TOOLTIP_SAVE;

    return (
      <>
        <div className={ styles.approver }>
          <ApproverContent
            approves={ Approves }
            color='default'
            addresserColor='default'
            stamp={ Stamp }
          />
        </div>
        { this.renderHeaderContent(false, true) }
        { this.renderHtmlReport() }
        <div className={ styles.fixed_panel }>
          <div className={ styles.actions }>
            <Tooltip
              show={ !!tooltipRules || isDemo }
              className={ styles.tooltip_wrap }
              renderContent={ () => (
                <Text type='NORMAL_14_130' color='white' className={ styles.tooltip_content_error }>
                  { tooltipInfo }
                </Text>
              ) }
            >
              <Button
                type='secondary'
                loading={ loadingSendButton }
                disabled={ tooltipRules || isDemo }
                className={ styles.submit }
                onClick={ () => this.setDialog(true, DIALOGS_FIELD.APPROVED) }
              >
                { LABELS.APPROVE }
              </Button>
            </Tooltip>
            <Button
              type='textual'
              disabled={ isDemo }
              onClick={ () => this.setDialog(true, DIALOGS_FIELD.DECLINE) }
              className={ styles.cancel }
            >
              { LABELS.DECLINE }
            </Button>
          </div>
        </div>
      </>
    );
  };

  renderFooterButtonsFromStatus = () => {
    const {
      expenseReportStore: {
        loadings: { loadingSaveButton, addNewDocLoader },
        isValid,
        report,
        stepsResolutions,
      },
      workspaceService,
    } = this.props;

    const { Status, Creator } = report;

    const createNotUnl = Status !== ApproveStatus.APPROVED && this.createExpenseReports !== CREATE_EXPENSE_REPORT.Unlimited;
    const rulesForNewReport = (createNotUnl && !Creator) || Status === ApproveStatus.DECLINED;
    const createReport = Status === ApproveStatus.APPROVED;
    const createFromScheme = this.createExpenseReports === CREATE_EXPENSE_REPORT.ApprovalScheme;

    const selectDialog = createFromScheme ? DIALOGS_FIELD.APPROVE_SCHEME : DIALOGS_FIELD.APPROVE;
    const selfApprove = createFromScheme && stepsResolutions === STEPS_RESOLUTIONS.SELF_APPROVE;

    const tooltipRules = report.ImageLinks.find(({ ValidationDownloadDoc }) => ValidationDownloadDoc);
    const tooltipInfo = workspaceService.isDemo ? LABELS.NOT_AVAILABLE_IN_DEMO : LABELS.TOOLTIP_SAVE;

    let buttonLabel = LABELS.SAVE;
    let qaAttr = QA_ATTRIBUTES.expenseReport.item.buttons.save;

    if (rulesForNewReport && !selfApprove) {
      buttonLabel = LABELS.SEND_TO_APPROVE;
      qaAttr = QA_ATTRIBUTES.expenseReport.item.buttons.sendToApprove;
    }

    if (createReport) {
      buttonLabel = LABELS.CREATE_ER;
      qaAttr = QA_ATTRIBUTES.expenseReport.item.buttons.create;
    }

    const buttonClick = rulesForNewReport && !selfApprove ? this.sendToApprove(selectDialog) : this.handleSave;
    const statusNotApprove = Status === ApproveStatus.DECLINED;
    const disabled = (!isValid && Status !== ApproveStatus.APPROVED) || workspaceService.isDemo || (createNotUnl && Creator && !statusNotApprove);

    const disabledRules = (disabled && (statusNotApprove && !isValid)) || Status === ApproveStatus.SEND_TO_1C || addNewDocLoader || tooltipRules || workspaceService.isDemo;

    return (
      <div className={ styles.fixed_panel }>
        <div className={ styles.actions }>
          <Tooltip
            show={ !!tooltipRules || workspaceService.isDemo }
            className={ styles.tooltip_wrap }
            renderContent={ () => (
              <Text type='NORMAL_14_130' color='white' className={ styles.tooltip_content_error }>
                { tooltipInfo }
              </Text>
            ) }
          >
            <Button
              qaAttr={ qaAttr }
              type='secondary'
              disabled={ disabledRules }
              loading={ loadingSaveButton }
              className={ styles.submit }
              onClick={ () => buttonClick() }
            >
              { buttonLabel }
            </Button>
          </Tooltip>
          <Button
            qaAttr={ QA_ATTRIBUTES.expenseReport.item.buttons.cancel }
            type='textual'
            disabled={ workspaceService.isDemo }
            onClick={ this.handleGoBack }
            className={ styles.cancel }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </div>
    );
  };

  renderHistoryApprove = () => {
    const { expenseReportStore: { report: { Approves, Stamp } } } = this.props;

    if (!Approves.length) return null;

    const renderContentTooltip = () => (
      <div className={ styles['tooltip-wrapper'] }>
        <ApproverContent
          approves={ Approves }
          stamp={ Stamp }
        />
      </div>
    );

    return (
      <div className={ styles.history }>
        <Tooltip
          theme='without-foot'
          position='right'
          renderContent={ renderContentTooltip }
        >
          <LinkButton theme='large-default' className={ styles.link }>
            { LABELS.HISTORY }
          </LinkButton>
        </Tooltip>
      </div>
    );
  };

  renderHeaderContent = (showDownload = true, approver = false) => {
    const {
      expenseReportStore: {
        edit,
        report: {
          Updated,
          Stamp,
          Name,
          Status,
          Data: { Employee },
          CheckinDate,
          CheckoutDate,
          IsRemoveAvailable,
        },
      },
    } = this.props;
    const { downloadLoading } = this.state;

    const deleteButton = IsRemoveAvailable ? this.renderDeleteButton() : null;

    const text = edit ? LABELS.EDIT_TITLE : LABELS.CREATE;
    const qaAttr = edit ? QA_ATTRIBUTES.expenseReport.item.editReport : QA_ATTRIBUTES.expenseReport.item.createReport;

    const headerTextHtml = !approver &&
      (<div data-qa={ qaAttr } className={ styles.title }>
        <Text type='bold_32'>{text}</Text>
        { deleteButton }
      </div>);

    const downloadContent = downloadLoading ? (
      <div className={ styles['download-loading-container'] }>
        <DotLoading />
      </div>
    ) : (
      <div data-qa={ QA_ATTRIBUTES.expenseReport.item.download.pdf } className={ styles['download-dropdown'] }>
        <Dropdown
          title={ LABELS.DOWNLOAD.PDF }
          onClick={ () => this.handleDownload(FORMATS.PDF) }
        >
          <div className={ styles['download-dropdown-content'] }>
            <Button
              qaAttr={ QA_ATTRIBUTES.expenseReport.item.download.xlsx }
              type='textual'
              onClick={ () => this.handleDownload(FORMATS.XLSX) }
            >
              { LABELS.DOWNLOAD.EXCEL }
            </Button>
          </div>
        </Dropdown>
      </div>
    );

    const lastChangeContent = (
      <Text type='NORMAL_16' className={ styles.updated }>
        { Updated ? LABELS.UPDATED : LABELS.CREATED } { formatDate(Stamp, PATTERN.DAY_OF_MONTH) }
      </Text>
    );

    const sendToEmailAndDownloadContent = showDownload && (
      <div className={ styles.actions }>
        <Button
          qaAttr={ QA_ATTRIBUTES.expenseReport.item.buttons.sendToEmail }
          type='textual'
          onClick={ () => this.setSendMailDialog({ show: true }) }
          className={ styles.send }
        >
          { LABELS.SEND_TO_EMAIL }
        </Button>
        { downloadContent }
      </div>
    );

    return (
      <div className={ styles.header }>
        {headerTextHtml}
        <div className={ styles.panel }>
          <div className={ styles.top }>
            <Text type='NORMAL_18'>
              { Name }
            </Text>
            { sendToEmailAndDownloadContent }
          </div>
          <div className={ styles.content }>
            <div className={ styles.column }>
              <Text
                type='NORMAL_16'
                className={ styles.dates }
              >
                { formatRangeDateWithSimplicity(CheckinDate, CheckoutDate)}
              </Text>
              <StatusMarker status={ Status } isExpense />
            </div>
            <div className={ styles.column }>
              <Text type='NORMAL_16'>
                { getEmployeeFullName(Employee) }
              </Text>
              { lastChangeContent }
            </div>
            <div className={ styles.column } />
          </div>
        </div>
      </div>
    );
  };

  renderWaitingApprove = () => {
    const { expenseReportStore: { report: { Approves, Stamp } } } = this.props;

    const approvesContent = Approves.length ? (
      <ApproverContent
        approves={ Approves }
        color='default'
        addresserColor='default'
        stamp={ Stamp }
      />
    ) : null;

    return (
      <>
        <div className={ styles.approver }>
          { approvesContent }
        </div>
        { this.renderHeaderContent(false) }
        { this.renderHtmlReport() }
      </>
    );
  };

  renderNotApprove = () => {
    const { expenseReportStore: { report: { Approves, Stamp } } } = this.props;

    return (
      <>
        <div className={ styles.approver }>
          <ApproverContent
            approves={ Approves }
            color='default'
            addresserColor='default'
            stamp={ Stamp }
          />
        </div>
        { this.renderHeaderContent(false) }
        { this.renderHtmlReport() }
        { this.renderFooterButtonsFromStatus() }
      </>
    );
  };

  renderAgreed = () => (
    <>
      { this.renderHistoryApprove() }
      { this.renderHeaderContent() }
      { this.renderHtmlReport() }
      { this.renderFooterButtonsFromStatus() }
    </>
  );

  renderCreate = () => {
    const { expenseReportStore: { report: { Approves, Creator, Id } } } = this.props;

    const history = Approves.length ? this.renderHistoryApprove() : null;
    const footer = canEditReports(this.createExpenseReports, this.isAdmin, Creator) ? this.renderFooterButtonsFromStatus() : null;

    return (
      <>
        { history }
        { this.renderHeaderContent(!!Id) }
        { this.renderHtmlReport() }
        { footer }
      </>
    );
  };

  renderPage = (status: string) => ({
    [STATUS_PAGE.CREATE]: this.renderCreate,
    [STATUS_PAGE.APPROVE]: this.renderAgreed,
    [STATUS_PAGE.WAITING_APPROVE]: this.renderWaitingApprove,
    [STATUS_PAGE.NOT_APPROVE]: this.renderNotApprove,
    [STATUS_PAGE.APPROVER]: this.renderApprover,
  }[status]());

  renderDocuments = (list: any, notShowDeleteButton: boolean) => {
    const {
      expenseReportStore: {
        report: { IsEditingAvailable },
        loadings: { addNewDocLoader, deleteItemDoc, downloadItemDoc },
        docItemIndex,
      },
      expenseReportsService: { openDeleteDocumentsItemDialog, downloadDocumentsItem },
    } = this.props;

    const { progressBar, sizeText, statusText } = this.state;

    return (
      <DocumentList
        disabled={ !IsEditingAvailable }
        notShowDeleteButton={ notShowDeleteButton }
        addNewDocLoader={ addNewDocLoader }
        list={ list }
        onOpenDocumentDialog={ this.handleOpenDocumentDialog }
        openDeleteDocumentsItemDialog={ openDeleteDocumentsItemDialog }
        docItemIndex={ docItemIndex }
        deleteItemDoc={ deleteItemDoc }
        downloadItemDoc={ downloadItemDoc }
        downloadDocumentsItem={ downloadDocumentsItem }
        progressBar={ progressBar }
        sizeText={ sizeText }
        statusText={ statusText }
      />
    );
  };

  renderHtmlReport = () => {
    const {
      expenseReportStore: {
        report: { Data: { Expenses, Prepayments }, Id, ImageLinks, Items, Approves, Status },
        loadings: { loadingDownloadArchiveDocs },
      },
      expenseReportsService,
      workspaceService,
      accountSettingsService: {
        getHidePerDiem,
      },
    } = this.props;

    const prepaymentsList = getHidePerDiem() ? Prepayments.filter(({ Amount }) => Amount !== 0) : Prepayments;

    const anotherExpensesHtml = !!Items.length && this.renderAnotherExpenses();

    const notShowDeleteDocButton = Status === ApproveStatus.ACCEPTED && Approves.length && !workspaceService.isAdmin;

    const labelPrepayments = getHidePerDiem() ? LABELS.ADVANCE : LABELS.PREPAYMENTS;

    const downloadArchiveDocHtml = ImageLinks.length && Id ? (
      <Button
        qaAttr={ QA_ATTRIBUTES.expenseReport.item.buttons.downloadArchive }
        type='textual'
        onClick={ expenseReportsService.downloadArchiveDoc }
        loading={ loadingDownloadArchiveDocs }
      >
        { LABELS.DOWNLOAD_ARCHIVE }
      </Button>
    ) : null;

    return (
      <>
        <Text type='bold_20' qaAttr={ QA_ATTRIBUTES.expenseReport.create.prepayments }>
          { labelPrepayments }
        </Text>
        { this.renderList(prepaymentsList, FIELDS.PREPAYMENTS) }
        <Text type='bold_20'>
          { LABELS.EXPENSES }
        </Text>
        <Text color='gray' className={ styles.sub_expenses }>
          { LABELS.SUB_EXPENSES }
        </Text>
        { this.renderList(Expenses, FIELDS.EXPENSES) }
        <div className={ styles.docs_header }>
          <div className={ styles.doc_name }>
            <Text type='bold_20'>
              { LABELS.DOCUMENT }
            </Text>
            <Text color='gray' className={ styles.sub_document }>
              { LABELS.SUB_DOCUMENT }
            </Text>
          </div>
          { downloadArchiveDocHtml }
        </div>
        { this.renderDocuments(ImageLinks, !!notShowDeleteDocButton) }
        { anotherExpensesHtml }
      </>
    );
  };

  render() {
    const {
      expenseReportsService: {
        updatePrepayments,
        updateExpenses,
        updateDocuments,
      },
      expenseReportStore: {
        statusPage,
        loadings: { loading },
        report: { CheckinDate },
        settings: { Rewritable },
        prefiledValue,
        prefiledValueType,
      },
      workspaceService,
    } = this.props;

    const {
      prepayment: {
        dialog: prepaymentDialog,
        item: prepaymentItem,
        index: prepaymentIndex,
      },
      expense: {
        dialog: expenseDialog,
        item: expenseItem,
        index: expenseIndex,
      },
      document: {
        dialog: documentDialog,
        item: documentItem,
        index: documentIndex,
      },
    } = this.state;

    if (loading) {
      return <PageLoader text={ LABELS.LOADING } />;
    }

    const link = statusPage === STATUS_PAGE.APPROVER ? ROUTES.APPROVE.EXPENSE_REPORTS : ROUTES.EXPENSE.MAIN;

    return (
      <div className={ styles.wrapper }>
        { this.renderPrompt() }
        <BackLink
          qaAttr={ QA_ATTRIBUTES.expenseReport.item.buttons.back }
          className={ styles.back }
          link={ link }
          text={ LABELS.BACK }
          alternativeDesign={ isSmartAgent }
          onClick={ this.handleGoBack }
        />
        { this.renderPage(statusPage) }
        <PrepaymentDialog
          show={ prepaymentDialog }
          item={ prepaymentItem }
          rewritable={ Rewritable || workspaceService.isAdmin }
          onSave={ (payload) => updatePrepayments(payload, prepaymentIndex) }
          onClose={ () => this.handleOpenPrepaymentDialog(false) }
          canBeEdited={ !workspaceService.isDemo }
        />
        <ExpenseDialog
          show={ expenseDialog }
          item={ expenseItem }
          startDate={ momentObject(CheckinDate) }
          // isIntegration1S={ isIntegration1S }
          onSave={ (payload) => updateExpenses(payload, expenseIndex) }
          onClose={ () => this.handleOpenExpenseDialog(false) }
          canBeEdited={ !workspaceService.isDemo }
          prefiledValue={ prefiledValue }
          prefiledValueType={ prefiledValueType }
        />
        <DownloadDialog
          show={ documentDialog }
          item={ documentItem }
          onClose={ () => this.handleOpenDocumentDialog(false, documentItem, documentIndex) }
          onFileAdded={ (payload) => this.handleAddDocument(payload, documentIndex) }
          onSave={ (payload) => updateDocuments(payload, documentIndex) }
        />
        { this.renderDeleteDialog() }
        { this.renderCancelDialog() }
        { this.renderSendEmail() }
        { this.renderSendToApproveSchemeDialog() }
        { this.renderSendToUsualApproveDialog() }
        { this.renderApproverDialog() }
        { this.renderDeclineDialog() }
        { this.renderConfirmDeleteDocumentDialog() }
      </div>
    );
  }
}

export default ExpenseReportPage;
