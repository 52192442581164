import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Text, StyledWrapper, Icon } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { FLIGHT_TYPE_FORMAT } from '../../../../bi/constants/vipHall';

import { ImageGallery } from '../../../../components/ImageGallery/ImageGallery';

import { VipHallCardProps } from '../../../../bi/types/vipHall';

import styles from './index.module.css';

const LABELS = {
  DOMESTIC: getText('vipHall:item.domestic'),
  INTERNATIONAL: getText('vipHall:item.international'),
  MORE_ABOUT_VIP_HALL: getText('vipHall:item.moreAboutVipHall'),
  MAX_TIME: (max: string) => getText('vipHall:item.maxTime', { max }),
};

const VipHallCard: FC<VipHallCardProps> = observer(({
  item: {
    location,
    images,
    opening_hours,
    hours_to_stay,
  },
  airport,
  guid,
}) => {
  const { vipHallStore: { items } } = useStores([MOBX_STORES.VIP_STORE]);
  const maxTimeText = hours_to_stay ? LABELS.MAX_TIME(hours_to_stay) : null;

  const flightType = items.find(({ hallId }: any) => hallId === guid)?.flightType;

  const isInternationalFlight = flightType === FLIGHT_TYPE_FORMAT.INTERNATIONAL.toLowerCase();

  const title = isInternationalFlight ? LABELS.INTERNATIONAL : LABELS.DOMESTIC;

  const renderGallery = () => {
    if (!images) {
      return null;
    }

    const imgsList = images.map((img: string) => ({
      original: img,
      thumbnail: img,
    }));

    return (
      <ImageGallery
        autoPlay
        items={ imgsList }
        slideInterval={ 4000 }
      />
    );
  };

  const renderIconFlight = () => {
    const typeIcon = isInternationalFlight ? 'international' : 'menuSmartdesk';

    return (
      <Icon type={ typeIcon } color='blue' className={ styles.icon } />
    );
  };

  return (
    <StyledWrapper className={ styles.static }>
      <div className={ styles.info }>
        <div className={ styles['row-info'] }>
          { renderIconFlight() }
          <Text type='NORMAL_16_130'>
            { title }
          </Text>
        </div>
        <div className={ styles.description }>
          <Text type='NORMAL_16_130'>{ location }</Text>
        </div>
        <div className={ styles.time }>
          <Text type='NORMAL_14' color='gray'>{ maxTimeText }</Text>
        </div>
        <div className={ styles.airport }>
          <div className={ styles['opening-hours'] }>
            <Icon type='clock' className={ styles['icon-clock'] } />
            <Text type='NORMAL_14_130'>{ opening_hours }</Text>
          </div>
          <div className={ styles['row-info'] }>
            <Icon type='pinsMap' color='blue' className={ styles.icon } />
            <Text type='NORMAL_14'>{ airport }</Text>
          </div>
        </div>
      </div>
      <div className={ styles.gallery }>
        { renderGallery() }
      </div>
    </StyledWrapper>
  );
});

export { VipHallCard };
