import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { IconButton, RadioButton, StyledWrapper, Text, ItemLoader, Suggest } from 'new-ui';

import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';
import { useServices } from '../../../../bi/context/services';

import { getEmployeeFullNameWithSimplicity } from '../../../../bi/utils/employees';
import { formatRangeDateWithSimplicity } from '../../../../bi/utils/formatDate';
import { isSmartAgent } from '../../../../bi/utils/env';

import ROUTES from '../../../../bi/constants/routes';
import { APPROVAL_STATUS_PAGE } from '../../../../bi/constants/travelApproval';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { AGGREGATORS_ACCOUNT } from '../../../../bi/constants/accounts';

import { Employee } from '../../../../bi/services/vipHall/types';
import { ApprovedRequest, CartApproveRequestProps, SuggestItems } from './types';

import styles from './styles.module.css';

const LABELS = {
  CREATE_NEW: getText('components:travelApproval.newRequestLower'),
  TRAVEL_APPROVE: getText('components:travelApproval.travelApprovalRequest'),
  NO_DATE: getText('components:travelApproval.noDate'),
  PLACEHOLDER: getText('settings:travelPolicy.policyUnique.employeeDialog.input'),
  SELECT: getText('settings:travelPolicy.policyUnique.employeeDialog.select'),
  FIND_EMPLOYEE: getText('settings:travelPolicy.policyUnique.employeeDialog.findEmployee'),
};

const getEmployeeFullName = (employee: SuggestItems | any) => `${employee.Surname} ${employee.Name} ${employee.Patronymic || ''}`;

const CartApproveRequest = observer(({
  id,
  travelApprovalService,
  onFetch,
  aggregationId,
  isTravelApproval,
  history,
  handleValidRequestOnChange,
}: CartApproveRequestProps) => {
  const { travelApprovalsStore } = useStores([MOBX_STORES.TRAVEL_APPROVALS]);

  const [filter, setFilter] = useState<string>('');
  const [filteredItems, setFilteredItems] = useState<SuggestItems[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);

  const { cloudPaymentService: { isPersonalPaymentMethod } } = useServices(['CloudPayment']);

  if (!isTravelApproval || isPersonalPaymentMethod) {
    return null;
  }

  const { approvedList, chosenApprovedRequest, validateLoading } = travelApprovalsStore;
  const chosenApprovedRequestId = chosenApprovedRequest?.Id || null;
  const loadingHtml = validateLoading && <ItemLoader />;
  const cKRRestrictions = Number(aggregationId) === AGGREGATORS_ACCOUNT.CKR;

  const handleGoNewTravelApproval = () => {
    travelApprovalService.setTravelApproval(null);
    travelApprovalService.setTravelApprovalStatusPage(APPROVAL_STATUS_PAGE.CREATE);

    history.push(`${ROUTES.NEW_REQUEST}`);
  };

  const filteredApprovedList = approvedList.filter(item => {
    if (selectedEmployee && filter.trim() !== '') {
      return item.Employees.some(employee => employee.Id === selectedEmployee.Id);
    }

    const fullEmployeeNames = item.Employees
      .map(employee => getEmployeeFullName(employee).toLowerCase())
      .join(' ')
      .includes(filter.toLowerCase());

    return fullEmployeeNames;
  });

  const handleSelect = (item: SuggestItems | any) => {
    setSelectedEmployee(item);
    setFilter(getEmployeeFullName(item).trim());

    const matchedRequest = approvedList.find((request) =>
      request.Employees.some(employee => employee.Id === item.Id),
    );

    if (matchedRequest !== undefined) {
      handleValidRequestOnChange(matchedRequest.Id, id);
    }
  };

  const renderNewTravelApprovalRequest = !cKRRestrictions ? (
    <IconButton
      iconType='plusRound'
      className={ styles.icon }
      onClick={ handleGoNewTravelApproval }
      qaAttr={ QA_ATTRIBUTES.cart.approval.scheme.request.createNew }
    >
      { LABELS.CREATE_NEW }
    </IconButton>
  ) : null;

  const renderApprovedRequest = (item: ApprovedRequest) => {
    const { Destinations, Employees, StartDate, EndDate } = item;
    const dates = !StartDate && !EndDate ? LABELS.NO_DATE : formatRangeDateWithSimplicity(StartDate, EndDate);
    const cities = Destinations.map(({ Name }: { Name: string }) => Name).join(', ');
    const employees = Employees.map(employee => getEmployeeFullNameWithSimplicity(employee, true)).join(', ');

    return (
      <div className={ styles.name }>
        { dates }: { cities } { employees }
      </div>
    );
  };

  const renderApprovedRequestName = (value: string) => {
    setFilter(value);

    if (filter.trim() === '') {
      setSelectedEmployee(null);
      onFetch(value).then((list: SuggestItems[] | any) => {
        if (list !== null) {
          setFilteredItems(list);
        }
      });
    } else {
      onFetch(value).then((list: SuggestItems[] | any) => {
        if (list !== null) {
          setFilteredItems(list);
        }
      });
    }
  };

  const renderApprovedRequestlastName = (item: SuggestItems) => (
    <div className={ styles.name }>
      { getEmployeeFullName(item).trim() }
    </div>
  );

  const renderApprovedItem = (item: ApprovedRequest | any, index: number) => (
    <div
      key={ `travel_approval_${item.Id}_${index}` }
      className={ styles.item }
      data-qa={ `${QA_ATTRIBUTES.cart.approval.scheme.request.item}-${index}` }
    >
      <RadioButton
        disabled={ validateLoading }
        value={ String(chosenApprovedRequestId) }
        checked={ chosenApprovedRequestId === item.Id }
        alternativeDesign={ isSmartAgent }
        onChange={ () => handleValidRequestOnChange(item.Id, id) }
      >
        { renderApprovedRequest(item) }
      </RadioButton>
    </div>
  );

  const renderApprovedRequestList = () => filteredApprovedList.map(renderApprovedItem);

  return (
    <StyledWrapper className={ styles.wrapper_approve_request }>
      { loadingHtml }
      <div className={ styles['approve-request'] }>
        <div className={ styles['approve-header'] }>
          <IconButton
            theme='default-red'
            iconType='warning'
          />
          <Text
            type='SEMIBOLD_14'
            color='default'
            className={ styles['header-text'] }
            qaAttr={ QA_ATTRIBUTES.cart.approval.scheme.request.warning }
          >
            { LABELS.TRAVEL_APPROVE }
          </Text>
        </div>
        <Text
          type='SEMIBOLD_14'
          color='default'
          className={ styles['header-text'] }
          qaAttr={ QA_ATTRIBUTES.cart.approval.scheme.request.warning }
        >
          { LABELS.FIND_EMPLOYEE }
        </Text>
        <div className={ styles['filter-input'] }>
          <Suggest
            value={ filter }
            onChange={ renderApprovedRequestName }
            withLabel={ false }
            items={ filteredItems }
            renderItem={ renderApprovedRequestlastName }
            onSelect={ handleSelect }
            placeholder={ LABELS.PLACEHOLDER }
            theme='border'
            displaySearchIcon
          />
        </div>
        <Text
          type='NORMAL_14'
          color='default'
          className={ styles['header-text'] }
          qaAttr={ QA_ATTRIBUTES.cart.approval.scheme.request.warning }
        >
          { LABELS.SELECT }
        </Text>
        <div className={ styles['approved-list-wrapper'] }>
          { renderApprovedRequestList() }
        </div>
        { renderNewTravelApprovalRequest }
      </div>
    </StyledWrapper>
  );
});

export { CartApproveRequest };
