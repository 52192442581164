import React, { FC } from 'react';
import { Checkbox } from 'new-ui';

import styles from '../index.module.css';

interface FilterMineCheckboxProps {
  show: boolean;
  value: boolean;
  onChange(checked: boolean): void;
  label: string,
  className?: string,
}

const FilterMineCheckbox: FC<FilterMineCheckboxProps> = ({
  show,
  onChange,
  value,
  label,
  className = '',
}) => {
  if (!show) return null;

  const wrapperClassName = [styles.filter, className];

  return (
    <div className={ wrapperClassName.join(' ') }>
      <Checkbox
        onChange={ (newValue: boolean) => onChange(newValue) }
        value={ value }
      >
        { label }
      </Checkbox>
    </div>
  );
};

export { FilterMineCheckbox };
