import React, { FC, useState } from 'react';
import { Text, Button, Price as PriceComponent, ItemPanel } from 'new-ui';
import { getText } from '../../../../../i18n';

import { FavoriteAction } from '../../../../components/FavoriteAction';
import { ToNote } from '../../../../components/ToNote';
import { NameErrorsTravelPolicy } from '../../../../components/NameErrorsTravelPolicy';
import { TravelPolicyDialog } from '../../../../components/TravelPolicyDialog';

import { isSmartAgent } from '../../../../bi/utils/env';

import FAVORITESACTION from '../../../../bi/constants/favorites';
import ANIMATION from '../../../../bi/constants/animation';
import CLASSNAMESFORTESTS from '../../../../bi/constants/classnamesfortests';
import { MAPCARCLASS } from '../../../../bi/constants/transfer';
import { TRAVELPOLICYFILTER } from '../../../../bi/constants/travelPolicy';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { TransferItemProps, ITransferItem } from '../../../../bi/types/transfer';

import styles from './index.module.css';

const LABELS = {
  TOLL_ROAD: getText('transfer:transferResult.tollRoad'),
  ADDED: getText('transfer:transferResult.added'),
  TO_CART: getText('transfer:transferResult.toCart'),
  COUNT_OF_PLACES: getText('transfer:transferResult.countOfPlaces'),
  CANCEL_TRIP: getText('transfer:transferResult.cancelTrip'),
  TABLE_SIGNATURE: getText('transfer:transferResult.tableSignature'),
  INCLUDE: getText('transfer:transferResult.include'),
  NOT_INCLUDE: getText('transfer:transferResult.notInclude'),
  OTHER: getText('transfer:transferResult.otherModels'),
};

const applyTravelPolicy = (item: ITransferItem, selectedTravelPolicy: string) => {
  const hasTP = item.TravelPolicy && item.TravelPolicy.Apply;
  let selectedTP = false;

  if (hasTP && selectedTravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED) {
    selectedTP = !!item.TravelPolicy.Errors[selectedTravelPolicy];
  }

  return hasTP && selectedTP;
};

const nameErrorsToDialogTP = (item: ITransferItem, selectedTravelPolicy: string) => ({
  [selectedTravelPolicy]: item.TravelPolicy.Errors[selectedTravelPolicy],
});

const TransferItem: FC<TransferItemProps> = ({
  item: {
    Class,
    Capacity,
    Price,
    IncludeTablet,
    Models,
    Title,
    FavoriteId,
    AlreadyInCart,
  },
  item,
  onAddToShoppingCart,
  onAddToNotepad,
  onAddToFavorite,
  onAlreadyInCart,
  travelPolicyList,
  selectedTravelPolicy,
  index,
}) => {
  const [animationClass, setAnimationClass] = useState('');
  const [showTravelPolicyDialog, setShowTravelPolicyDialog] = useState(false);

  const label = AlreadyInCart ? LABELS.ADDED : LABELS.TO_CART;
  const applyTP = applyTravelPolicy(item, selectedTravelPolicy);

  const handleAddToCart = () => {
    onAddToShoppingCart(item);
    onAlreadyInCart(item.RateId);

    setAnimationClass(ANIMATION.MOVETOCART);

    if (showTravelPolicyDialog) setShowTravelPolicyDialog(false);
  };

  const handleAddToNote = () => {
    onAddToNotepad(item);

    setAnimationClass(ANIMATION.MOVETONOTE);
  };

  const handleChangeFavorite = (action: string) => {
    onAddToFavorite(item, action);

    if (action === FAVORITESACTION.ADD) {
      setAnimationClass(ANIMATION.PULSE);
    }
  };

  const handleAnimationEnd = () => setAnimationClass('');

  const handleShowTravelPolicyDialog = () => setShowTravelPolicyDialog(true);

  const handleCloseTravelPolicyDialog = () => setShowTravelPolicyDialog(false);

  const renderTrevelPolicyDialog = () => {
    const errors = nameErrorsToDialogTP(item, selectedTravelPolicy);

    return (
      <TravelPolicyDialog
        show={ showTravelPolicyDialog }
        errors={ errors }
        onClose={ handleCloseTravelPolicyDialog }
        onAdd={ handleAddToCart }
      />
    );
  };

  const renderCarModels = () => {
    const models = Models.map(modal => `${modal[0].toUpperCase()}${modal.slice(1)}`).join(', ');

    return (
      <div>
        <Text type='NORMAL_16' className={ styles.class }>
          {`${models} ${LABELS.OTHER}`}
        </Text>
      </div>
    );
  };

  const renderFavorites = () => !isSmartAgent && (
    <FavoriteAction
      id={ FavoriteId }
      onClick={ handleChangeFavorite }
      className={ CLASSNAMESFORTESTS.TRANSFERS.TOFAVORITE }
    />
  );

  const renderHeader = () => (
    <div className={ styles.header }>
      <Text type='NORMAL_18'>
        { Title }
      </Text>
      <div className={ styles.actions }>
        <NameErrorsTravelPolicy
          oneTravelPolicy
          hiddenNames
          item={ item }
          applyTP={ applyTP }
          travelPolicyList={ travelPolicyList }
          selectedTravelPolicy={ selectedTravelPolicy }
        />
        <ToNote
          onClick={ handleAddToNote }
          className={ `${CLASSNAMESFORTESTS.TRANSFERS.TONOTEPAD} ${styles.note}` }
        />
        { renderFavorites() }
      </div>
    </div>
  );

  const travelPolicyDialogHtml = showTravelPolicyDialog ? renderTrevelPolicyDialog() : null;

  const isFirstEl = index === 0;
  const qaAttrFirstItem = isFirstEl ? QA_ATTRIBUTES.transfer.result.item.first : '';
  const qaAttrFirstItemButton = isFirstEl ? QA_ATTRIBUTES.transfer.result.item.toCart : '';

  return (
    <ItemPanel
      warning={ applyTP }
      animationClass={ animationClass }
      className={ CLASSNAMESFORTESTS.TRANSFERS.ITEM }
      onAnimationEnd={ handleAnimationEnd }
      renderHeader={ renderHeader }
      qaAttr={ qaAttrFirstItem }
    >
      <div className={ styles.wrap }>
        <div className={ styles.icon }>
          <img src={ `/images/newTransfer/${MAPCARCLASS[Class as keyof typeof MAPCARCLASS]}.png` } alt={ Class } />
        </div>
        <div className={ styles.descriptions }>
          { renderCarModels() }
          <Text type='NORMAL_14' color='gray'>
            { LABELS.COUNT_OF_PLACES } { Capacity }
          </Text>
          <Text type='NORMAL_14' color='gray'>
            { LABELS.TABLE_SIGNATURE } { IncludeTablet ? LABELS.INCLUDE : LABELS.NOT_INCLUDE }
          </Text>

        </div>
        <div className={ styles.actions }>
          <PriceComponent value={ Price } type='bold_20' />
          <Button
            className={ styles.button }
            type='secondary'
            disabled={ !!AlreadyInCart }
            onClick={ applyTP ? handleShowTravelPolicyDialog : handleAddToCart }
            qaAttr={ qaAttrFirstItemButton }
          >
            { label }
          </Button>
        </div>
      </div>
      { travelPolicyDialogHtml }
    </ItemPanel>
  );
};

export { TransferItem };
