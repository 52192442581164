import React, { FC } from 'react';
import { Icon, Text, StyledWrapper } from 'new-ui';

import { getText } from '../../../../../i18n';

import { isSmartAgent } from '../../../../bi/utils/env';

import { FAST_TRACK_TYPES, VIP_HALL_TYPES } from '../../../../bi/constants/vipHall';

import { VipHallInfoProps } from '../../../../bi/types/vipHall';

import styles from './index.module.css';

const LABELS = {
  ABOUT_BUSINESS: getText('vipHall:item.aboutBusiness'),
  ABOUT_VIP_HALL: getText('vipHall:item.aboutVipHall'),
  ABOUT_ASSIST: getText('vipHall:item.aboutAssist'),
  DETAILED: getText('vipHall:item.detailed'),
  COST: getText('vipHall:item.cost'),
  PRE_COST: (value: number) => getText('vipHall:item.preCost', { value }),
  SERVICES: getText('vipHall:item.services'),
  CANCELLATION: getText('vipHall:item.cancellation'),
  NEED_PAYMENT: getText('vipHall:item.needPayment'),
};

const VipHallInfo: FC<VipHallInfoProps> = ({
  item: {
    description,
    detailed_description,
    option_categories,
    one_passenger_cost,
    cancellation_description,
  },
  category,
}) => {
  const renderDescriptionTitle = () => {
    switch (category) {
      case FAST_TRACK_TYPES.MEET_AND_ASSIST:
      case FAST_TRACK_TYPES.FAST_TRACK:
        return LABELS.ABOUT_ASSIST;

      case VIP_HALL_TYPES.VIP_HALL:
        return LABELS.ABOUT_VIP_HALL;

      case VIP_HALL_TYPES.BUSINESS_HALL:
        return LABELS.ABOUT_BUSINESS;

      default:
        return LABELS.ABOUT_VIP_HALL;
    }
  };

  const categoriesHTML = option_categories && option_categories.length ?
    option_categories.map(({ name, included }, ind) => {
      const needPaymentHtml = !included ? ` - ${LABELS.NEED_PAYMENT}` : null;
      const colorCheckItem = isSmartAgent ? 'dim_green' : 'red';

      return (
        <div key={ `services_${ind}` } className={ styles.category }>
          <Icon type='checkMark' color={ colorCheckItem } />
          <Text
            type='NORMAL_16_140'
            className={ styles['category-name'] }
          >
            { name }{ needPaymentHtml }
          </Text>
        </div>
      );
    }) : null;

  const additionalHtml = option_categories && option_categories.length && (
    <div className={ styles.description }>
      <Text type='bold_24'>{ LABELS.SERVICES }</Text>
      { categoriesHTML }
    </div>
  );

  const descriptionHtml = description && !!description.length && (
    <div className={ styles.description }>
      <Text type='bold_24'>{ renderDescriptionTitle() }</Text>
      <Text type='NORMAL_16_140' className={ styles.text }>{ description }</Text>
    </div>
  );

  const detailedDescriptionHtml = detailed_description && !!detailed_description.length && (
    <div className={ styles.description }>
      <Text type='bold_24'>{ LABELS.DETAILED }</Text>
      <Text type='NORMAL_16_140' className={ styles.text }>{ detailed_description }</Text>
    </div>
  );

  const costHtml = !!one_passenger_cost && (
    <div className={ styles.description }>
      <Text type='bold_24'>{ LABELS.COST }</Text>
      <Text type='NORMAL_16_140' className={ styles.text }>{ LABELS.PRE_COST(one_passenger_cost) }</Text>
    </div>
  );

  const cancellationHtml = cancellation_description && !!cancellation_description.length && (
    <div className={ styles.description }>
      <Text type='bold_24'>{ LABELS.CANCELLATION }</Text>
      <Text type='NORMAL_16_140' className={ styles.text }>{ cancellation_description }</Text>
    </div>
  );

  return (
    <StyledWrapper className={ styles.wrapper }>
      { descriptionHtml }
      { detailedDescriptionHtml }
      { costHtml }
      { cancellationHtml }
      { additionalHtml }
    </StyledWrapper>
  );
};

export { VipHallInfo };
