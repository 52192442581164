import { ReactNode } from 'react';
import { SidePanelStore } from './store';

class SidePanelService {
  store = SidePanelStore;

  setRenderFn = (fn: () => ReactNode): void => this.store.setRenderFn(fn);

  setShow = (value: boolean): void => this.store.setShow(value);
}

export default SidePanelService;
