import React, { FC } from 'react';
import { Text, IconButton, List, Tooltip } from 'new-ui';

import { getText } from '../../../../i18n';

import { DocumentsType } from '../../../bi/services/expenseReports/types';

import { formatDate } from '../../../bi/utils/formatDate';

import { DocumentItem } from './documentItem';

import styles from '../styles/index.module.css';

const LABELS = {
  TOOLTIP_LIMITATION_LENGTH: getText('expenseReports:report.limitationLength'),
  TOOLTIP_WAIT_FOR_IT: getText('expenseReports:report.tooltipWaitForIt'),
  ADD_BUTTONS: {
    DOCUMENTS: getText('expenseReports:report.addButtons.document'),
    DOCUMENTS_SIZE: getText('expenseReports:report.addButtons.documentSize'),
  },
};

const TYPE = 'ACTION';

interface DocumentListProps {
  progressBar: string | number,
  sizeText: string,
  statusText: string,
  docItemIndex: number | null,
  disabled: boolean,
  notShowDeleteButton: boolean,
  addNewDocLoader: boolean,
  deleteItemDoc: boolean,
  downloadItemDoc: boolean,
  list: any[],
  onOpenDocumentDialog(value: boolean, item: any, index: number): void,
  downloadDocumentsItem(fileGuid: string, index: number): void,
  openDeleteDocumentsItemDialog(index: number, value: true): void,
}

const DocumentList: FC<DocumentListProps> = ({
  docItemIndex,
  disabled,
  notShowDeleteButton,
  addNewDocLoader,
  deleteItemDoc,
  downloadItemDoc,
  list,
  onOpenDocumentDialog,
  openDeleteDocumentsItemDialog,
  downloadDocumentsItem,
  progressBar,
  sizeText,
  statusText,
}) => {
  const renderDocItem = (item: DocumentsType, index: number, lastItem: number) => {
    const { FileName, FileSize, EventDate, FileGuid, FileExtension = '', ValidationDownloadDoc = '' } = item;
    const currentItem = index === lastItem;

    const downloadItem = addNewDocLoader && currentItem;
    const disabledDoSomethingWithDoc = downloadItem || deleteItemDoc || downloadItemDoc;

    const updateStatusText = downloadItem ? statusText : formatDate(EventDate);

    const loadingForDownloadButton = downloadItemDoc && index === docItemIndex;
    const loadingForDeleteButton = deleteItemDoc && index === docItemIndex;

    return (
      <DocumentItem
        index={ index }
        fileGuid={ FileGuid }
        fileName={ FileName }
        fileSize={ FileSize }
        sizeText={ sizeText }
        fileExtension={ FileExtension }
        validationDownloadDoc={ ValidationDownloadDoc }
        updateStatusText={ updateStatusText }
        loadingForDownloadButton={ loadingForDownloadButton }
        disabledDoSomethingWithDoc={ disabledDoSomethingWithDoc }
        loadingForDeleteButton={ loadingForDeleteButton }
        disabled={ disabled }
        notShowDeleteButton={ notShowDeleteButton }
        progressBar={ progressBar }
        downloadItem={ downloadItem }
        downloadDocumentsItem={ downloadDocumentsItem }
        openDeleteDocumentsItemDialog={ openDeleteDocumentsItemDialog }
      />
    );
  };

  const preparedList = !disabled ? [...list, { type: TYPE }] : list;
  const lastItem = list.length - 1;
  const limitationLength = list.length === 10;

  const tooltipLabel = limitationLength ? LABELS.TOOLTIP_LIMITATION_LENGTH : LABELS.TOOLTIP_WAIT_FOR_IT;
  const limitationRules = addNewDocLoader || limitationLength;

  const renderFn = (item: any, index: number) => ((item.type === TYPE && !disabled) ? (
    <div className={ styles.add_panel }>
      <Tooltip
        show={ limitationRules }
        className={ styles.tooltip_wrap }
        renderContent={ () => (
          <Text type='NORMAL_14_130' color='white' className={ styles.tooltip_content }>
            { tooltipLabel }
          </Text>
        ) }
      >
        <IconButton
          iconType='plusRound'
          onClick={ () => onOpenDocumentDialog(true, item, index) }
          className={ styles.btn }
          disabled={ limitationRules }
        >
          { LABELS.ADD_BUTTONS.DOCUMENTS }
          <Text type='NORMAL_12_120' color='blue'>
            { LABELS.ADD_BUTTONS.DOCUMENTS_SIZE }
          </Text>
        </IconButton>
      </Tooltip>
    </div>
  ) : renderDocItem(item, index, lastItem));

  return (
    <div className={ styles.list_wrapper }>
      <List
        items={ preparedList }
        keyExtractor={ (_i, index) => `${index}` }
        onClickItem={ (item: any, ind: number) => {
          if (item.type === TYPE && !limitationRules) onOpenDocumentDialog(true, item, ind);
        } }
        renderItem={ renderFn }
        wrapperClassNameExtractor={ (item) => (item.type === TYPE ? styles.action : styles.item) }
      />
    </div>
  );
};

export { DocumentList };
