import React from 'react';
import { observer } from 'mobx-react';

import { MultiSelect, Select, RadioButton, Text } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { RightsDialogWrap } from '../RightsDialogWrap';

import { isSmartAgent } from '../../../../../bi/utils/env';

import { STEPSRIGHTS, FIELDRIGHTS } from '../../../../../bi/constants/rights';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import styles from '../index.module.css';
import { MultiSelectList } from '../../../../../bi/services/employee/types';

const LABELS = {
  CHOSE_SCHEME: getText('settings:employees.employee.steps.rights.steps.limit.chooseScheme'),
  CHOSE_TP: getText('settings:employees.employee.steps.rights.steps.limit.chooseTp'),
  WITHIN_THE_TP_EMPLOYEES: getText('settings:employees.employee.steps.rights.steps.limit.withinTheTpEmployees'),
  WITHIN_THE_TP: getText('settings:employees.employee.steps.rights.steps.limit.withinTheTp'),
  WITHIN_THE_APPROVE_SCHEME: getText('settings:employees.employee.steps.rights.steps.limit.withinTheApproveScheme'),
  AFTER_APPROVE_CHELIK: getText('settings:employees.employee.steps.rights.steps.limit.afterApproveManager'),
  UNLIMITED: getText('settings:employees.employee.steps.rights.steps.limit.unlimited'),
  EMPLOYEE_MAY_HAVE_BUY: (empl: string) => getText('settings:employees.employee.steps.rights.steps.limit.employeeMayHaveBuy', { empl }),
  BY_ANY: getText('settings:employees.employee.steps.rights.steps.limit.byAny'),
};

const { employee: { documents: { access: { modals } } } } = QA_ATTRIBUTES;

interface IItem {
  Id: string,
  Name: string
}

type Item = {
  label: string;
  value: string;
};

interface ILimitStep {
  employee: string,
  active: string,
  header: string,
  showExpenseReportApprove: boolean,
  travelPolicy: Item,
  approvalScheme: number,
  rights: {
    buyTripAccount: string,
    buyTravelPolicy: string,
    buyUnlimited: string,
    buyAfterApprove: string,
    buyApprovalScheme: string,
    approvers: string[],
  },
  allApproveUsers: MultiSelectList[],
  travelPolicies: Item[],
  approvalSchemes: IItem[],
  onChangeStep(value: string): void,
  onChangeRights(field: string, value: string | boolean): void,
  onChangeApproveUsers(value: string[]): void,
  onChangeTravelPolicy(value: string[] | string | number): void,
  onChangeApprovalScheme(value: string): void,
}

const LimitStep = observer(({
  employee,
  active,
  header,
  showExpenseReportApprove,
  travelPolicy = {
    value: '',
    label: '',
  },
  approvalScheme = 0,
  rights: {
    buyTripAccount,
    buyTravelPolicy,
    buyUnlimited,
    buyAfterApprove,
    buyApprovalScheme,
    approvers,
  },
  allApproveUsers,
  travelPolicies,
  approvalSchemes,
  onChangeStep,
  onChangeRights,
  onChangeApproveUsers,
  onChangeTravelPolicy,
  onChangeApprovalScheme,
}: ILimitStep) => {
  const handleSelectTP = (value: number | string | string[]) => {
    onChangeRights(FIELDRIGHTS.BUYTRAVELPOLICY, true);
    onChangeTravelPolicy(value);
  };

  const handleSelectApprovalScheme = (value: string) => {
    onChangeRights(FIELDRIGHTS.BUY_APPROVAL_SCHEME, true);
    onChangeApprovalScheme(value);
  };

  const handleSelectApprovers = (value: string[]) => {
    onChangeRights(FIELDRIGHTS.BUYAFTERAPPROVE, true);
    onChangeApproveUsers(value);
  };

  const renderTravelPolicy = () => {
    const content = travelPolicies.length > 1 ? (
      <Select
        placeholder={ LABELS.CHOSE_TP }
        theme='open-blue'
        items={ travelPolicies }
        value={ travelPolicy.value }
        onChange={ handleSelectTP }
      />
    ) : (
      <Text>{ travelPolicies[0].label }</Text>
    );

    return (
      <div className={ styles['selected-item'] }>
        { content }
      </div>
    );
  };

  const renderBuyTravelPolicy = () => (
    <div className={ `${styles['multiple-item']} ${styles.item}` }>
      <RadioButton
        checked={ !!buyTravelPolicy }
        value={ buyTravelPolicy }
        alternativeDesign={ isSmartAgent }
        onChange={ value => onChangeRights(FIELDRIGHTS.BUYTRAVELPOLICY, value) }
        qaAttr={ modals.limit.radioButtons.tp }
      >
        { buyTripAccount ? LABELS.WITHIN_THE_TP_EMPLOYEES : LABELS.WITHIN_THE_TP }
      </RadioButton>
      { !buyTripAccount && renderTravelPolicy() }
    </div>
  );

  const renderBuyApprovalContent = () => {
    const mappedSchemes = approvalSchemes.map(({ Id, Name }) => ({ value: Id, label: Name }));

    const selectHtml = approvalSchemes.length > 1 ? (
      <Select
        placeholder={ LABELS.CHOSE_SCHEME }
        theme='open-blue'
        items={ mappedSchemes }
        value={ approvalScheme }
        onChange={ handleSelectApprovalScheme }
      />
    ) : (
      <Text>
        { mappedSchemes[0].label }
      </Text>
    );

    return (
      <div className={ styles['selected-item'] }>
        { selectHtml }
      </div>
    );
  };

  const renderBuyApprovalScheme = () => (
    <div className={ `${styles['multiple-item']} ${styles.item}` }>
      <RadioButton
        checked={ !!buyApprovalScheme }
        value={ buyApprovalScheme }
        alternativeDesign={ isSmartAgent }
        onChange={ value => onChangeRights(FIELDRIGHTS.BUY_APPROVAL_SCHEME, value) }
        qaAttr={ modals.limit.radioButtons.approvalScheme }
      >
        { LABELS.WITHIN_THE_APPROVE_SCHEME }
      </RadioButton>
      { renderBuyApprovalContent() }
    </div>
  );

  const renderBuyAfterApproveSelect = () => (
    <div className={ styles['selected-item'] }>
      <span>(</span>
      <MultiSelect
        search
        list={ allApproveUsers }
        theme='textual-blue'
        withArrow={ false }
        placeholder={ LABELS.BY_ANY }
        className={ styles['overflow-select'] }
        values={ approvers }
        onChange={ handleSelectApprovers }
      />
      <span>)</span>
    </div>
  );

  const buyTravelPolicyHtml = !!travelPolicies.length && renderBuyTravelPolicy();
  const buyAfterApproveSelectHtml = !!allApproveUsers.length && renderBuyAfterApproveSelect();
  const buyApprovalSchemeHtml = !!approvalSchemes.length && renderBuyApprovalScheme();
  const nextStep = showExpenseReportApprove ? STEPSRIGHTS.APPROVE_EXPENSE_REPORTS : STEPSRIGHTS.ADMIN;

  return (
    <RightsDialogWrap
      header={ header }
      theme={ active }
      onClickButton={ () => onChangeStep(nextStep) }
      onClickCancel={ () => onChangeStep(STEPSRIGHTS.MAIN) }
      qaAttrHeader={ modals.limit.title }
      qaAttrButton={ modals.buttonNext }
      qaAttrLink={ modals.limit.buttons.cancel }
    >
      <Text className={ styles.subtitle }>
        { LABELS.EMPLOYEE_MAY_HAVE_BUY(employee) }
      </Text>
      <RadioButton
        checked={ !!buyUnlimited }
        value={ buyUnlimited }
        className={ styles.item }
        alternativeDesign={ isSmartAgent }
        onChange={ value => onChangeRights(FIELDRIGHTS.BUYUNLIMITED, value) }
        qaAttr={ modals.limit.radioButtons.unlimited }
      >
        { LABELS.UNLIMITED }
      </RadioButton>
      <div className={ `${styles['multiple-item']} ${styles.item}` }>
        <RadioButton
          checked={ !!buyAfterApprove }
          value={ buyAfterApprove }
          alternativeDesign={ isSmartAgent }
          onChange={ value => onChangeRights(FIELDRIGHTS.BUYAFTERAPPROVE, value) }
          qaAttr={ modals.limit.radioButtons.buyAfterApprove }
        >
          { LABELS.AFTER_APPROVE_CHELIK }
        </RadioButton>
        { buyAfterApproveSelectHtml }
      </div>
      { buyTravelPolicyHtml }
      { buyApprovalSchemeHtml }
    </RightsDialogWrap>
  );
});

export { LimitStep };
