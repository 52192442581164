import React from 'react';

import { Button, LinkButton, Text, Dialog } from 'new-ui';
import { getText } from '../../../../../../i18n';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('settings:employees.employee.steps.activeBookingDialog.title'),
  TEXT: getText('settings:employees.employee.steps.activeBookingDialog.text'),
  CLOSE: getText('settings:employees.employee.steps.activeBookingDialog.close'),
  TO_HELP: getText('settings:employees.employee.steps.activeBookingDialog.toHelp'),
};

interface IActiveBookingDialog {
  show: boolean,
  onClose(): void,
  handleOpenTravelHelper(): void,
}

const ActiveBookingDialog = ({
  show,
  onClose,
  handleOpenTravelHelper,
}: IActiveBookingDialog) => (
  <Dialog
    show={ show }
    onChange={ () => onClose }
  >
    <div className={ styles.wrap }>
      <Text
        type='bold_20'
        className={ styles.title }
      >
        { LABELS.TITLE }
      </Text>
      <Text>
        { LABELS.TEXT }
      </Text>
      <div className={ styles.actions }>
        <Button
          onClick={ onClose }
        >
          { LABELS.CLOSE }
        </Button>
        <LinkButton
          onClick={ handleOpenTravelHelper }
          className={ styles.link }
        >
          { LABELS.TO_HELP }
        </LinkButton>
      </div>
    </div>
  </Dialog>
);

export { ActiveBookingDialog };
