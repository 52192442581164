import React, { FC, useState } from 'react';
import { MultiSelect } from 'new-ui';
import { useQuery } from '@tanstack/react-query';

import { getText } from '../../../../../../i18n';

import { api } from '../../../../../bi/apiV2';

import { Project } from '../../../../../bi/types/trips';

import styles from '../index.module.css';

const LABELS = {
  PLACEHOLDER: getText('trips:projects'),
  SEARCH_PLACEHOLDER: getText('trips:nameOfCostCenter'),
};

const getProjectsForselect = (project: Project) => ({ value: project.Id, label: project.Name });

const useProjects = (query: string) => useQuery({
  queryKey: ['tripsFilters', query],
  queryFn: () => api.trips.autocompleteProjects(query),
  staleTime: 60000,
  enabled: !!query.length,
});

interface FilterProjectsProps {
  projects: Project[];
  values: number[];
  onChange(checkedProjects: number[], checkedProjectsWithLabels: any): void;
}

const FilterProjects: FC<FilterProjectsProps> = ({
  projects,
  values,
  onChange,
}) => {
  const [autoComplete, setAutoComplete] = useState('');
  const { data } = useProjects(autoComplete);

  if (!projects.length) return null;

  const projectsToSelect = data?.length ? data : projects;

  const projectsList = projectsToSelect.map((project: Project) => getProjectsForselect(project));

  return (
    <div className={ styles.filter }>
      <MultiSelect
        withLabel
        placeholder={ LABELS.PLACEHOLDER }
        list={ projectsList }
        values={ values }
        search
        searchPlaceholder={ LABELS.SEARCH_PLACEHOLDER }
        onChange={ onChange }
        onSearchFunction={ (value: React.SetStateAction<string>) => setAutoComplete(value) }
      />
    </div>
  );
};

export { FilterProjects };
