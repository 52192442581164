import React from 'react';

import { Text } from 'new-ui';
import { getText, getTextArray } from '../../../../../i18n';

import { NumberInput } from '../../../../components/NumberInput';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import toDecline from '../../../../bi/utils/toDecline';

import { DaysCountRuleProps } from './types';

import styles from './index.module.css';

const LABELS = {
  DAYS_DECLINES: getTextArray('settings:travelPolicy.rules.days.daysDeclines'),
  NO_LATER_THAN: getText('settings:travelPolicy.rules.days.noLaterThan'),
  DAYS_BEFORE_DEPARTURE: (days: number) => getText('settings:travelPolicy.rules.days.daysBeforeDeparture', { days }),
};

const DaysCountRule = ({
  value,
  onChange,
  onRender,
}: DaysCountRuleProps) => {
  const daysLabel = toDecline(+value, LABELS.DAYS_DECLINES);

  return (
    <div className={ styles.wrap } data-qa={ QA_ATTRIBUTES.settings.travelPolicy.addRules.rules.daysCountRule }>
      <Text>{ LABELS.NO_LATER_THAN }</Text>
      <NumberInput
        value={ value }
        onChange={ onChange }
        className={ styles.input }
        onRender={ onRender }
      />
      <Text>{ LABELS.DAYS_BEFORE_DEPARTURE(daysLabel) }</Text>
    </div>
  );
};

export { DaysCountRule };
