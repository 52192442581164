import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, DotLoading, Text, Textarea, NoResults } from 'new-ui';

import { getText, getTextArray } from '../../../../../i18n';

import { getEmployeeFullName } from '../../../../bi/utils/employees';
import toDecline from '../../../../bi/utils/toDecline';

import { APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES } from '../../../../bi/constants/approvalSchemes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('components:approvalSchemeDialog.title'),
  CHOOSE: getText('components:approvalSchemeDialog.select'),
  WITHOUT_CHOICE: getText('components:approvalSchemeDialog.withoutERChoice'),
  NOT_FIRST_STEP_EVERY: getText('components:approvalSchemeDialog.notFirstStepEvery'),
  SENT_TO: getTextArray('components:approvalSchemeDialog.sentToDeclines'),
  STEP: getTextArray('components:approvalSchemeDialog.step'),
  NOT_FIRST_STEP_CHOOSE: getText('components:approvalSchemeDialog.notFirstStepChoose'),
  COMMENT: {
    DESCRIPTION: getText('components:approvalSchemeDialog.commentForApproveExpenseReport.description'),
    PLACEHOLDER: getText('components:approvalSchemeDialog.comment.placeholder'),
  },
  SUBMIT: getText('components:approvalSchemeDialog.submitER'),
  LOADING_ERROR: getText('components:approvalSchemeDialog.loadingError'),
  NO_SCHEME_ERROR: getText('components:approvalSchemeDialog.noSchemeError'),
};

interface IApprovers {
  Email: string,
  EmployeeId: number,
  FirstName: string,
  LastName: string,
  MiddleName: string,
  Name: string,
  UserId: string,
}

interface IApproveSchemeDialog {
  approvers: any
  show: boolean,
  onSubmit(model: any): Promise<any>,
  onClose(): void,
  error: boolean,
}

interface IApproversStep {
  Approvers: IApprovers[],
  ConditionOfApproval: number,
  StepId: number,
}

const shouldChoose = (approvalCondition: number) =>
  approvalCondition === APPROVAL_SCHEME_CONDITION_OF_APPROVAL_TYPES.ONLY_ONE_SEND_CHOSEN.value;

const ApproveSchemeDialog = ({
  approvers,
  show,
  onSubmit,
  onClose,
  error,
}: IApproveSchemeDialog) => {
  const [errors, setErrors] = useState<any[]>([]);
  const [chosenApprovers, setChosenApprovers] = useState<any[]>([]);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const approversChoose: any[] = [];
    const errorsChoose: any[] = [];

    approvers.forEach(() => {
      approversChoose.push({ Approvers: new Set() });
      errors.push([]);
    });

    setErrors(errorsChoose);
    setChosenApprovers(approversChoose);
  }, []);

  const submitForm = () => {
    const errorsClone = [...errors];

    setErrors(errorsClone);

    if (errorsClone.some(i => i)) {
      return;
    }

    const preparedToSaveSteps: any[] = [];

    approvers.forEach(({ Approvers, ConditionOfApproval, StepId }:
    { Approvers: IApprovers[], ConditionOfApproval: number, StepId: number }, ind: number) => {
      if (shouldChoose(ConditionOfApproval) && Approvers.length > 1) {
        const approversList = Array.from(chosenApprovers[ind].Approvers);
        errorsClone[ind] = !approversList.length;

        return preparedToSaveSteps.push({ Id: StepId, Approvers: approversList });
      }

      return preparedToSaveSteps.push({ Id: StepId, Approvers: Approvers.map((item: any) => item.UserId) });
    });

    const model = {
      Comment: comment,
      StepSettings: preparedToSaveSteps,
    };

    setLoading(true);

    onSubmit(model).then(() => setLoading(false));
  };

  const handleChangeChosenApprover = (value: any, index: number, approver: any) => {
    const chosenApproversCopy = [...chosenApprovers];

    if (value) {
      chosenApproversCopy[index].Approvers.add(approver);
    } else {
      chosenApproversCopy[index].Approvers.delete(approver);
    }

    setChosenApprovers(chosenApproversCopy);
  };

  const renderComment = () => {
    const title = `${LABELS.COMMENT.DESCRIPTION}:`;

    return (
      <div className={ styles.comment }>
        <Text className={ styles.title }>{title}</Text>
        <Textarea
          qaAttr={ QA_ATTRIBUTES.expenseReport.sendToApproveDialogs.scheme.comment }
          placeholder={ LABELS.COMMENT.PLACEHOLDER }
          onChange={ value => setComment(value) }
          value={ comment }
        />
      </div>
    );
  };

  const notChoosenApprovers = approvers.map((step: IApproversStep, ind: number) =>
    !!(chosenApprovers.length && shouldChoose(step.ConditionOfApproval) && chosenApprovers[ind].Approvers.size === 0));
  const disabled = notChoosenApprovers.find((item: any) => item === true);

  const renderActions = (
    <div className={ styles.actions }>
      <Button
        qaAttr={ QA_ATTRIBUTES.expenseReport.sendToApproveDialogs.scheme.send }
        onClick={ submitForm }
        loading={ loading }
        type='secondary'
        disabled={ disabled }
      >
        { LABELS.SUBMIT }
      </Button>
    </div>
  );

  const renderEmployee = (approve: any) => {
    const { LastName, FirstName, MiddleName, Name, Email } = approve;

    const fullName = Name
      ? getEmployeeFullName({ Surname: LastName, Name: FirstName, Patronymic: MiddleName }) : null;
    let email = '';

    if (Email) {
      email = `(${Email})`;
    }

    return (
      <Text className={ styles.employee }>
        { fullName } <Text color='gray' className={ styles.inline }>{ email }</Text>
      </Text>
    );
  };

  const renderStepWithoutChoiceApprovers = (step: IApproversStep) => {
    const { Approvers } = step;

    const content = Approvers.length === 1
      ? renderEmployee(Approvers[0])
      : (
        <ul>
          <Text className={ styles.text_approvers }>
            { Approvers.map((i: any, ind: number) => <li key={ `${i.UserId}_${ind}` }>{ renderEmployee(i) }</li>)}
          </Text>
        </ul>
      );

    const stylesApprovers = Approvers.length !== 1 ? styles.approvers : '';

    return (
      <div className={ stylesApprovers }>
        { content }
      </div>
    );
  };

  const renderStepWithChoiceApprovers = (Approvers: IApprovers[], index: number) => (
    <div className={ styles.approvers }>
      { Approvers.map((approver: any) => (
        <Checkbox
          qaAttr={ QA_ATTRIBUTES.expenseReport.sendToApproveDialogs.scheme.add }
          key={ `${approver.UserId}_${index}` }
          onChange={ (value) => handleChangeChosenApprover(value, index, approver.UserId) }
          value={ chosenApprovers[index]?.Approvers.has(approver.UserId) }
          className={ styles.checkbox }
        >
          { renderEmployee(approver) }
        </Checkbox>
      )) }
    </div>
  );

  const renderStepHeader = (step: any, index: number, realIndex: number) => {
    const { ConditionOfApproval, Approvers } = step;

    const stepNumber = (<Text type='SEMIBOLD_16' className={ styles.number }>{ LABELS.STEP }{realIndex + 1}</Text>);
    const descriptionContent = realIndex === 0
      ? (() => (shouldChoose(ConditionOfApproval) ? LABELS.CHOOSE : LABELS.WITHOUT_CHOICE))()
      : (() => (shouldChoose(ConditionOfApproval) ? LABELS.NOT_FIRST_STEP_CHOOSE : `${LABELS.NOT_FIRST_STEP_EVERY} ${toDecline(Approvers.length, LABELS.SENT_TO)}:`))();

    return (
      <div className={ styles.header }>
        { stepNumber }
        <Text color={ errors[index] ? 'red' : 'default' }>
          <Text>{descriptionContent}</Text>
        </Text>
      </div>
    );
  };

  const renderSteps = () => {
    let nonSkippedCount = 0;

    return (
      <div className={ styles.steps }>
        {
          approvers.map((step: any, i: number) => {
            const { Approvers, ConditionOfApproval } = step;

            const content = shouldChoose(ConditionOfApproval) && Approvers.length > 1
              ? renderStepWithChoiceApprovers(Approvers, i)
              : renderStepWithoutChoiceApprovers(step);

            const headerContent = renderStepHeader(step, i, nonSkippedCount);

            nonSkippedCount++;

            return (
              <div className={ `${styles.step} ${styles.border}` } key={ i }>
                { headerContent }
                { content }
              </div>
            );
          })
        }
      </div>
    );
  };

  const prepareRenderContent = (
    <div className={ styles.wrapper }>
      <Text
        qaAttr={ QA_ATTRIBUTES.expenseReport.sendToApproveDialogs.scheme.title }
        className={ styles.header }
        type='bold_20'
      >
        { LABELS.TITLE }
      </Text>
      { renderSteps() }
      { renderComment() }
      { renderActions }
    </div>
  );

  const content = () => {
    if (loading) {
      return (
        <div className={ styles.loading }>
          <DotLoading />
        </div>
      );
    }

    if (error) {
      return (
        <NoResults styles={ { paddingTop: 0 } } label={ LABELS.NO_SCHEME_ERROR } />
      );
    }

    return prepareRenderContent;
  };

  return (
    <Dialog
      qaAttr={ QA_ATTRIBUTES.expenseReport.sendToApproveDialogs.scheme.close }
      show={ show }
      onChange={ onClose }
      showClosing
    >
      <div className={ styles.wrapper }>
        { content() }
      </div>
    </Dialog>
  );
};

export default ApproveSchemeDialog;
