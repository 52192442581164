interface Employee {
  Id: number;
  Surname: string;
  Name: string;
  Patronymic: string;
}

interface TrainItem {
  Id: number;
  Data: string | any;
  Employees: any;
}

interface DiscountPayload {
  Id: number;
  FirstDate: string;
  SecondDate: string;
  Discount: number;
  Employees: Employee[];
}
export const createDiscountSapsan = (list: TrainItem[]) =>
  list.reduce<DiscountPayload[]>((acc, {
    Data: {
      DiscountAvailable,
      Discount,
      DateDepartureLocal,
    },
    Id,
    Employees,
  }) => {
    if (DiscountAvailable) {
      const second = list.find(({ Id: secondId }) => secondId === Discount.BackwardId) || null;

      if (second) {
        const newPayload = {
          Id,
          FirstDate: DateDepartureLocal,
          SecondDate: second.Data.DateDepartureLocal,
          Discount,
          Employees,
        };

        return [...acc, newPayload];
      }
    }

    return acc;
  }, []);

export const mappedOnlyTrainList = (list: TrainItem[]) => list
  .map(item => ({
    ...item,
    Data: JSON.parse(item.Data),
  }));
