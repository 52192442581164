import { getText } from '../../../i18n';

const QUALITY = {
  DESCRIPTION: {
    FIRST: (value: string) => getText(`quality:whiteLabel.${value}.description.first`),
    FIRST_SMARTAGENT: (value: string) => getText(`quality:whiteLabel.${value}.description.firstSmartagent`),
    SECOND: (value: string) => getText(`quality:whiteLabel.${value}.description.second`),
  },
  NOTIFICATION: (value: string) => getText(`quality:whiteLabel.${value}.notificationSuccess`),
  ONLY_FOR_USER: (value: string) => getText(`quality:whiteLabel.${value}.onlyForUser`),
  ONLY_FOR_USER_SMARTAGENT: (value: string) => getText(`quality:whiteLabel.${value}.onlyForUserSmartagent`),
};

export { QUALITY };
