import { FavoritesStore, FavoritesStoreType } from './store';

import { MainAnalytic } from '../../utils/analytics';

import { IFavorite, ITab } from '../../types/favorites';

class Favorite {
  api: any;
  store: FavoritesStoreType;

  constructor(api: any) {
    this.api = api;
    this.store = FavoritesStore;
  }

  load = (): void => {
    this.store.setLoading(true);

    this.api.favorites.load().then((favorites: IFavorite[]) => {
      this.store.setLoading(false);
      this.store.loadFavorites(favorites);
    });
  };

  setSearchInput = (value: string): void => {
    this.store.setSearchInput(value);
    this.store.filteredFavorites();

    MainAnalytic.send(MainAnalytic.CATEGORY.FAVORITES, MainAnalytic.ACTIONS.FAVORITES.FASTSEARCH, {
      label: value,
      value: this.store.favorites.length,
    });
  };

  setCurrentTab = (tab: ITab): void => {
    const newTab = this.store.tabs.find(item => item.value === tab.value);

    if (newTab) {
      this.store.setCurrentTab(newTab.value);
      this.store.filteredFavorites();

      MainAnalytic.send(MainAnalytic.CATEGORY.FAVORITES, MainAnalytic.ACTIONS.FAVORITES.FILTER, { label: tab.label });
    }
  };

  getFavoritesHotels = () => this.api.favorites.getFavoritesHotels();

  remove = (favoriteId: string) => this.api.favorites.remove(favoriteId).then(() => this.store.removeFavorite(favoriteId));

  removeItem = (favoriteId: string) => this.api.favorites.remove(favoriteId);

  removeItems = (items: any[]) => {
    const requests: any[] = [];

    items.forEach(item => requests.push(this.removeItem(item.FavoriteId)));

    return Promise.all(requests);
  };
}

export default Favorite;
