import { observable, action, computed, makeObservable } from 'mobx';

import { TrainException, TravelPolicyTrainRule } from './types';
import type { BaseServiceTypeTravelPolicyStore } from '../../types';
import {
  TRAVEL_POLICY_DEFAULT_PRICE, TRAVEL_POLICY_DEFAULT_ROUTE,
  TRAVEL_POLICY_FIELDS_DICTIONARIES,
  TRAVEL_POLICY_STORE_FIELDS,
} from '../../consts';
import {
  filterNewAndRemovedExceptions,
  mapTravelPolicyDirections,
  mapTravelPolicyExceptions,
  mapTravelPolicyRoutes,
} from '../../utils';
import { SERVICETYPE } from '../../../../constants/serviceType';

const DEFAULT_GENERAL_RULES = {
  [TRAVEL_POLICY_STORE_FIELDS.ONE_WAY]: {
    IsEnabled: false,
    Value: TRAVEL_POLICY_DEFAULT_PRICE,
  },
  [TRAVEL_POLICY_STORE_FIELDS.NOT_GREATER_PRICE]: {
    IsEnabled: false,
    ValueType: TRAVEL_POLICY_FIELDS_DICTIONARIES.NOT_GREATER_PRICE_TYPE.RUBLES,
    Value: TRAVEL_POLICY_DEFAULT_PRICE,
  },
  [TRAVEL_POLICY_STORE_FIELDS.CLASS]: {
    IsEnabled: false,
    Value: TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.ECONOM,
  },
  [TRAVEL_POLICY_STORE_FIELDS.SAPSAN_CLASS]: {
    IsEnabled: false,
    Value: TRAVEL_POLICY_FIELDS_DICTIONARIES.CLASSES.ECONOM,
  },
  [TRAVEL_POLICY_STORE_FIELDS.DAY_OFF]: {
    IsEnabled: false,
  },
};

const TRAVEL_POLICY_TRAIN_DEFAULT_EXCEPTION = {
  isRemoved: false,
  isNew: true,
  Type: TRAVEL_POLICY_FIELDS_DICTIONARIES.EXCEPTION_TYPES.ROUTES,
  Routes: [{ ...TRAVEL_POLICY_DEFAULT_ROUTE }],
  ...DEFAULT_GENERAL_RULES,
};

const DEFAULT_STORE = {
  [TRAVEL_POLICY_STORE_FIELDS.APPLY]: false,
  ...DEFAULT_GENERAL_RULES,
  [TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS]: {
    IsEnabled: false,
    showDialog: false,
    Type: TRAVEL_POLICY_FIELDS_DICTIONARIES.DIRECTION_TYPES.RUSSIA,
    Routes: [{ ...TRAVEL_POLICY_DEFAULT_ROUTE }],
  },
  [TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS]: {
    dialog: { show: false, step: 0, chosenIndex: null },
    list: [] as TrainException[],
  },
};

class TrainTravelPolicyStore implements BaseServiceTypeTravelPolicyStore {
  constructor() {
    makeObservable(this);
  }

  @observable store = DEFAULT_STORE;

  @computed
  get mappedDirections() {
    const value = this.store[TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS];

    return {
      ...value,
      Routes: mapTravelPolicyDirections(value.Routes, SERVICETYPE.TRAIN),
    };
  }

  @computed
  get mappedExceptions() {
    const value = this.store[TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS];

    return {
      ...value,
      list: mapTravelPolicyExceptions(value.list, SERVICETYPE.TRAIN),
    };
  }

  @computed
  get preparedToSaveModel(): TravelPolicyTrainRule {
    const { Exceptions, ...rest } = this.store;

    const preparedExceptions = filterNewAndRemovedExceptions(Exceptions.list)
      .map(ei => ({
        ...ei,
        [TRAVEL_POLICY_STORE_FIELDS.DAY_OFF]: ei[TRAVEL_POLICY_STORE_FIELDS.DAY_OFF].IsEnabled,
      }));

    return {
      ...rest,
      [TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS]: preparedExceptions,
      [TRAVEL_POLICY_STORE_FIELDS.DAY_OFF]: this.store[TRAVEL_POLICY_STORE_FIELDS.DAY_OFF].IsEnabled,
    };
  }

  @action
  mapServerModelToClient = (model: TravelPolicyTrainRule) => {
    const { Exceptions = [], Directions, ...rest } = model;

    const { Routes } = Directions;

    this.store = {
      ...this.store,
      ...rest,
      [TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS]: {
        ...this.store[TRAVEL_POLICY_STORE_FIELDS.EXCEPTIONS],
        list: Exceptions.map(ei =>
          ({
            ...ei,
            Routes: mapTravelPolicyRoutes(ei.Routes),
          }),
        ),
      },
      [TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS]: {
        ...this.store[TRAVEL_POLICY_STORE_FIELDS.DIRECTIONS],
        ...Directions,
        Routes: mapTravelPolicyRoutes(Routes),
      },
    } as typeof this.store;
  };

  @action
  updateStore = (payload: any) => {
    this.store = {
      ...this.store,
      ...payload,
    };
  };

  @action
  clearStore = () => {
    this.store = DEFAULT_STORE;
  };
}

export default TrainTravelPolicyStore;

export { TRAVEL_POLICY_TRAIN_DEFAULT_EXCEPTION };
