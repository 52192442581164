import { ICartItemInfo, IFilteredCartData } from '../types/airlineAdditionalServices';

/** Утилита для формирования объекта с данными для уведомления о сбросе багажа */
const getFilteredCartDataForBaggageWarning = (filteredCartData?: ICartItemInfo[]) => {
  const dataForWarning: Record<string, IFilteredCartData> = {};

  filteredCartData?.forEach(cartItem => {
    if (!cartItem.Id) {
      return;
    }

    dataForWarning[cartItem.Id] = {
      Routes: null,
      Employees: {},
    };

    if (cartItem.Data) {
      const cartItemData = JSON.parse(cartItem.Data);
      dataForWarning[cartItem.Id].Routes = cartItemData;
    }

    if (cartItem.Employees) {
      cartItem.Employees.forEach(employee => {
        dataForWarning[cartItem.Id].Employees[employee.Employee.Id] = employee.Employee;
      });
    }
  },
  );

  return dataForWarning;
};

export { getFilteredCartDataForBaggageWarning };
