import React, { FC } from 'react';
import { PageLoader } from 'new-ui';

import { Alphabet } from '../../../../components/Alphabet';
import { TravelersTableHeader } from '../TravelersTableHeader';
import { TravelersTableRow } from '../TravelersTableRow';

import styles from '../../index.module.css';
import { ICitizenship } from '../../../../bi/types/userSession';

interface TravelersTableProps {
  stores: {
    settingsStore: {
      employees: [any];
      paginate: {
        currentPage: number,
        itemsPerPage: number,
        total: number,
      }
    };
  },
  enumsCitizenship: ICitizenship[],
  onPageChange(number: number): void
  onEditEmployee(Id: string): void
}

const ALPHABET_SHOW_CONDITION = 3;

const TravelersTable: FC<TravelersTableProps> = ({
  stores,
  enumsCitizenship,
  onPageChange,
  onEditEmployee,
}: TravelersTableProps) => {
  const {
    settingsStore: {
      employees,
      paginate: {
        currentPage,
        itemsPerPage,
        total,
      },
    },
  } = stores;

  const activeLetters = employees.map((employee: { Surname: string }) => employee.Surname[0]);
  const showAlphabet = !!activeLetters.length && activeLetters.some((item: string) => item !== activeLetters[0]);
  const pagesCount = Math.ceil(total / itemsPerPage);

  const content = (
    <>
      <TravelersTableHeader/>
      <TravelersTableRow
        employees={ employees }
        enumsCitizenship={ enumsCitizenship }
        anchorPrefix='letter'
        onEditEmployee={ onEditEmployee }
      />
    </>
  );

  const renderTable = employees.length ? content : <PageLoader />;

  const renderAlphablet = employees && employees.length > ALPHABET_SHOW_CONDITION && showAlphabet && (
    <Alphabet
      className={ styles.alphabet }
      anchorPrefix='#letter'
      activeLetters={ activeLetters }
      pagesCount={ pagesCount }
      currentPage={ currentPage }
      onChangePage={ onPageChange }
    />
  );

  return (
    <div className={ styles.table }>
      {renderTable}
      {renderAlphablet}
    </div>
  );
};

export default TravelersTable;
