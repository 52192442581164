import React from 'react';
import { observer } from 'mobx-react';
import { History } from 'history';
import { Text, Button, List, PageLoader } from 'new-ui';

import { getText } from '../../../../../i18n';

import { UseServicesReturnType } from '../../../../bi/context/services';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import ROUTES from '../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { AnalyticsValueType } from '../../../../bi/services/customAnalytics/types';
import { IAnalyticsPageState } from '../../types';

import styles from './styles/styles.module.css';

const LABELS = {
  TO_EDIT: getText('settings:analytics.edit'),
  COMMON: getText('settings:analytics.common'),
  CUSTOM: getText('settings:analytics.custom'),
  CONST_CENTER: getText('settings:analytics.costCenter'),
  PLACEHOLDER: getText('settings:analytics.placeholder'),
  ADD_ANALYTIC: getText('settings:analytics.addAnalytic'),
  DELETE_ANALYTIC: getText('settings:analytics.deleteAnalytic'),
};

type AnalyticsPropsType = UseServicesReturnType<'CustomAnalytics'> & {
  history: History
};

@observer
class Analytics extends React.Component<AnalyticsPropsType, IAnalyticsPageState> {
  constructor(props: AnalyticsPropsType) {
    super(props);

    this.state = {
      idDeleteAnalytic: 0,
    };
  }

  componentDidMount() {
    this.props.customAnalyticsService.getAnalytics();
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.TRIP_ANALYTICS_OPEN);
  }

  addAnalytics = () => {
    this.props.history.push(ROUTES.ANALYTICS);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_ADD_ITEM);
  };

  editAnalytics = (id: number) => {
    this.props.history.push(`${ROUTES.ANALYTICS}/${id}`);
  };

  editCostCenter = () => {
    this.props.history.push(ROUTES.COSTCENTERS);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_PROJECT_OPEN);
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SETTINGS.ANALYTICS_PROJECT_EDIT);
  };

  renderActions = () => {
    const { customAnalyticsService: { store: { list = [] } } } = this.props;

    if (!list.length) {
      return null;
    }

    return (
      <div className={ styles.actions }>
        { this.renderAddButton() }
      </div>
    );
  };

  handleDeleteItem = (e: any, id: number) => {
    const { customAnalyticsService: { deleteAnalytics } } = this.props;

    e.stopPropagation();

    this.setState({
      idDeleteAnalytic: id,
    });

    return deleteAnalytics(id);
  };

  checkValueAnalytics = (id: number) => {
    const { customAnalyticsService: { store: { availableValue } } } = this.props;
    const { idDeleteAnalytic } = this.state;

    return availableValue && id === idDeleteAnalytic;
  };

  handleAvailableAnalytics = (id: number) => {
    if (!this.checkValueAnalytics(id)) {
      this.editAnalytics(id);
    }
  };

  checkAvailableValueAnalytics = (id: number) => {
    const classNameItemWrapper = [styles.item];

    if (this.checkValueAnalytics(id)) {
      classNameItemWrapper.push(styles.item_avalible);
    }

    return classNameItemWrapper.join(' ');
  };

  renderAddButton = () => (
    <Button onClick={ this.addAnalytics } qaAttr={ QA_ATTRIBUTES.settings.analytics.addAnalyticButton }>
      { LABELS.ADD_ANALYTIC }
    </Button>
  );

  renderCommonAnalytics = () => {
    const items = [
      {
        id: 0,
        name: LABELS.CONST_CENTER,
      },
    ];

    return (
      <List
        className={ styles.list }
        items={ items }
        wrapperClassNameExtractor={ () => styles.item_wrapper }
        onClickItem={ this.editCostCenter }
        keyExtractor={ ({ id }) => String(id) }
        renderItem={ ({ name }) => (
          <div className={ styles.item }>
            <Text
              type='bold_16'
              qaAttr={ QA_ATTRIBUTES.settings.analytics.costCenter }
            >
              { name }
            </Text>
            <Button type='textual'>
              { LABELS.TO_EDIT }
            </Button>
          </div>
        ) }
      />
    );
  };

  renderCustomAnalytics = () => {
    const { customAnalyticsService: { store: { list = [] } } } = this.props;

    if (!list.length) {
      return (
        <div className={ styles.placeholder }>
          <Text
            className={ styles.placeholder_text }
            type='NORMAL_16_140'
          >
            { LABELS.PLACEHOLDER }
          </Text>
          { this.renderAddButton() }
        </div>
      );
    }

    const renderDeleteButton = (value: AnalyticsValueType[], id: number) => {
      if (value.length) {
        return null;
      }

      return (
        <Button
          type='textual'
          className={ styles.deleteButton }
          onClick={ (e) => this.handleDeleteItem(e, id) }
        >
          { LABELS.DELETE_ANALYTIC}
        </Button>
      );
    };

    return (
      <List
        className={ styles.list }
        items={ [...list] }
        onClickItem={ ({ Id }) => this.handleAvailableAnalytics(Id) }
        keyExtractor={ ({ Id }) => String(Id) }
        wrapperClassNameExtractor={ () => styles.item_wrapper }
        renderItem={ ({ Name, Values, Id }) => (
          <div className={ this.checkAvailableValueAnalytics(Id) }>
            <Text type='bold_16'>{ Name }</Text>
            <div className={ styles.buttons }>
              <Button type='textual'>
                { LABELS.TO_EDIT }
              </Button>
              { renderDeleteButton(Values, Id) }
            </div>
          </div>
        ) }
      />
    );
  };

  renderLoading() {
    return (
      <PageLoader />
    );
  }

  render() {
    const { customAnalyticsService: { store: { loading } } } = this.props;

    const actions = this.renderActions();
    const commonAnalytics = this.renderCommonAnalytics();
    const customAnalytics = this.renderCustomAnalytics();

    if (loading) {
      return this.renderLoading();
    }

    return (
      <div className={ styles.wrap }>
        { actions }
        <div className={ styles.lists }>
          <Text type='bold_20'>
            { LABELS.COMMON }
          </Text>
          { commonAnalytics }
          <Text type='bold_20'>
            { LABELS.CUSTOM }
          </Text>
          { customAnalytics }
        </div>
      </div>
    );
  }
}

export default Analytics;
