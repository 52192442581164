const SW_PREFIX = 'sw';

const IDS_FOR_INTEGRATION_TESTS = {
  EMPLOYEE: {
    GENERAL: {
      NAME: `${SW_PREFIX}Name`,
      SURNAME: `${SW_PREFIX}Surname`,
      PATRONYMIC: `${SW_PREFIX}Patronymic`,
      BIRTHDAY: `${SW_PREFIX}Birthday`,
      CITIZENSHIP: `${SW_PREFIX}Citizenship`,
      MAN: `${SW_PREFIX}SexMan`,
      WOMAN: `${SW_PREFIX}SexWoman`,
      PHONE_NUMBER: `${SW_PREFIX}PhoneNumber`,
      EMAIL: `${SW_PREFIX}Email`,
    },
    DOCUMENTS: {
      RU: {
        SURNAME: `${SW_PREFIX}RUSurname`,
        NAME: `${SW_PREFIX}RUName`,
        PATRONYMIC: `${SW_PREFIX}RUPatronymic`,
        NUMBER: `${SW_PREFIX}RUNumber`,
        TRANSLITED_SURNAME: `${SW_PREFIX}RUTranslitedSurname`,
        TRANSILTED_NAME: `${SW_PREFIX}RuTranslitedName`,
        TRANSLITED_PATRONYMIC: `${SW_PREFIX}RuTranslitedPatronymic`,
      },
      DOMESTIC: {
        SURNAME: `${SW_PREFIX}DomesticSurname`,
        NAME: `${SW_PREFIX}DomesticName`,
        NUMBER: `${SW_PREFIX}DomesticNumber`,
        DUE_DATE: `${SW_PREFIX}DomesticDueDate`,
        PATRONOMYC: `${SW_PREFIX}DomesticPatronomyc`,
      },
      FOREIGN: {
        SURNAME: `${SW_PREFIX}ForeignSurname`,
        NAME: `${SW_PREFIX}ForeignName`,
        NUMBER: `${SW_PREFIX}ForeignNumber`,
        DUE_DATE: `${SW_PREFIX}ForeignDueDate`,
        PATRONOMYC: `${SW_PREFIX}DomesticPatronomyc`,
      },
      BIRTHDAY: {
        SURNAME: `${SW_PREFIX}BirthdaySurname`,
        NAME: `${SW_PREFIX}BirthdayName`,
        PATRONYMIC: `${SW_PREFIX}BirthdayPatronymic`,
        NUMBER: `${SW_PREFIX}BirthdayNumber`,
      },
    },
  },
  TO_CART: {
    TRAIN: `${SW_PREFIX}TrainToCart`,
    AIR: `${SW_PREFIX}AirToCart`,
    AEROEXPRESS: `${SW_PREFIX}AeroexpressToCart`,
    HOTEL: `${SW_PREFIX}HotelToCart`,
    TRANSFER: `${SW_PREFIX}TransferToCart`,
  },
};

const JUST_CONS_TO_SHUT_ESLINT = 0;

export { IDS_FOR_INTEGRATION_TESTS, JUST_CONS_TO_SHUT_ESLINT };
