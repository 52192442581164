import React from 'react';
import { Text } from 'new-ui';

import { formatDate } from '../../../bi/utils/formatDate';

import { PATTERN } from '../../../bi/constants/dateFormats';

import { LocalTime } from '../../../components/LocalTime';

import { TrainItemDetailsTimeProps } from './types';

import styles from '../styles/time.module.css';

const TrainItemDetailsTime = ({
  date,
  dateLocal,
  timeType,
  className = '',
  renderTooltipStation,
  qaAttr = '',
}: TrainItemDetailsTimeProps) => {
  const wrappedStyles = [styles.wrapper];

  if (className) {
    wrappedStyles.push(className);
  }

  return (
    <div className={ wrappedStyles.join(' ') }>
      <Text type='bold_22' qaAttr={ qaAttr }>
        { formatDate(dateLocal, PATTERN.LOCAL_TIME_PATTERN) }
      </Text>
      <LocalTime
        className={ styles.tooltip }
        timeType={ timeType }
        dateLocal={ dateLocal }
        dateMoscow={ date }
        dayMoscow={ formatDate(date, PATTERN.LOCAL_DAY_PATTERN) }
        timeMoscow={ formatDate(date, PATTERN.LOCAL_TIME_PATTERN) }
      />
      { renderTooltipStation && renderTooltipStation() }
    </div>
  );
};

export { TrainItemDetailsTime };
