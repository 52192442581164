const TRAINCARRIERS = {
  FPK: {
    NAME: 'АО "Федеральная пассажирская компания"',
    SHORTNAME: 'ФПК',
  },
  TKS: {
    NAME: 'АО "ТрансКлассСервис"',
    SHORTNAME: 'ТКС',
  },
  GRAND: {
    NAME: 'ЗАО ТК "Гранд Сервис Экспресс"',
    SHORTNAME: 'ГРАНД',
  },
  TVERSK: {
    NAME: 'ООО "Тверской экспресс"',
    SHORTNAME: 'ТВЕРСК',
  },
  DOSS: {
    NAME: 'ОАО "Дирекция скоростного сообщения"',
    SHORTNAME: 'ДОСС',
  },
  CPPK: {
    NAME: 'ОАО "Центральная пригородная пассажирская компания"',
    SHORTNAME: 'ЦППК',
  },
};

export { TRAINCARRIERS };
