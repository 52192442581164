import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import scrollToTop from '../../../../../utils/scrollToTop';

import { AirlineNestedTable } from '../../../common/airlineNestedTable';

import styles from './styles.module.css';

const LABELS = {
  EXCEL: getText('expenseReports:report.download.excel'),
};

const SmartCommonFourthAirPage = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => {
  const {
    chartsAnalyticsSmartSummaryStore,
  } = useStores([
    MOBX_STORES.CHARTS_ANALYTICS_SMART_SUMMARY_STORE,
  ]);

  const {
    getSmartAirPageWithTrips,
    downloadSubSmartXls,
  } = chartsAnalyticsService;

  useEffect(() => {
    getSmartAirPageWithTrips();
  }, [getSmartAirPageWithTrips]);

  const handlePageChange = (page: number) => chartsAnalyticsService.changePageSmartAirPage(page).then(() => scrollToTop());

  const renderDownload = () => (
    <div className={ styles.header }>
      <div className={ styles.row }>
        <Button type='textual' onClick={ () => downloadSubSmartXls() }>
          { LABELS.EXCEL }
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div className={ styles.wrapper }>
        { renderDownload() }
      </div>
      <AirlineNestedTable
        store={ chartsAnalyticsSmartSummaryStore }
        onPageChange={ handlePageChange }
      />
    </>
  );
});

export { SmartCommonFourthAirPage };
