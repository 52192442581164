import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStores } from '../../../../../../bi/context';
import { MOBX_STORES } from '../../../../../../bi/context/stores';

import scrollToTop from '../../../../../utils/scrollToTop';

import ChartsAnalytics from '../../../../../../bi/services/chartsAnalytics';

import { TaxiVoucherTable } from '../../tableForVoucher';

const TaxiDirectionNestedDoubleTable = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => {
  const {
    chartsAnalyticsSummaryStore,
    chartsAnalyticsStore: { filtersType, filters: { employees, departments, projects, analytics, tags } },
  } = useStores([MOBX_STORES.CHARTS_ANALYTICS_SUMMARY_STORE, MOBX_STORES.CHARTS_ANALYTICS_STORE]);

  const {
    getTaxiDirectionsSubDoublePage,
  } = chartsAnalyticsService;

  useEffect(() => {
    getTaxiDirectionsSubDoublePage();
  }, [
    getTaxiDirectionsSubDoublePage,
    filtersType,
    employees.selected,
    departments.selected,
    projects.selected,
    analytics.selected,
    tags.selected,
  ]);

  const handlePageChange = (page: number) => chartsAnalyticsService.changePageTaxiSummary(page).then(() => scrollToTop());

  return (
    <TaxiVoucherTable
      typeStore={ chartsAnalyticsSummaryStore }
      onPageChange={ handlePageChange }
      changeVoucher={ () => {} }
      path=''
    />
  );
});

export { TaxiDirectionNestedDoubleTable };
