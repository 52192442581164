import { observer } from 'mobx-react';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Button, Paginate, Table, Text, PageLoader } from 'new-ui';

import { getText } from '../../../../../../i18n';

import { ChartsAnalyticsSummaryStore } from '../../../../../bi/services/chartsAnalytics/stores/summary';
import { ChartsAnalyticsSmartSummaryStore } from '../../../../../bi/services/chartsAnalytics/stores/smart/summary';

import ROUTES from '../../../../../bi/constants/routes';

import { ChartAnalyticsNoResult } from '../../noResult';

import styles from './styles.module.css';

const CANCELED = getText('chartsAnalytics:canceled');

interface ITaxiVoucherTableProp {
  onPageChange(page: string | number): void,
  changeVoucher?(tripId: number): void,
  typeStore: ChartsAnalyticsSummaryStore | ChartsAnalyticsSmartSummaryStore,
  path?: string
}

const TaxiVoucherTable = observer(({ onPageChange, changeVoucher, typeStore, path }: ITaxiVoucherTableProp) => {
  const {
    subTableInfo: {
      strings,
      columnsName,
    },
    paginate: {
      currentPage,
      itemsPerPage,
      total,
    },
    loading,
  } = typeStore;

  if (loading) return <PageLoader />;

  if (!strings?.length) return <ChartAnalyticsNoResult />;

  const history = useHistory();

  const redirectToSubPage = (tripId: number) => {
    // @ts-ignore
    changeVoucher(tripId);
    // @ts-ignore
    history.push(path);
  };

  const firstLabel = (<Text className={ styles.route } type='SEMIBOLD_14'>{ columnsName[0] }</Text>);
  const header = [firstLabel, ...columnsName.slice(1)];

  const renderCanceled = (isCanceled: boolean | undefined, data: string[], indexData: number) => {
    if (isCanceled && indexData === data.length - 1) {
      return <div className={ styles.canceled }>{ CANCELED }</div>;
    }

    return null;
  };

  const valuesOnClickHtml = (value: string, tripId: number) => (tripId && !path ? (
    <Link
      to={ `${ROUTES.TRIP.MAIN}/${tripId}` }
      target='_blank'
    >
      <Button
        className={ styles.link }
        type='textual-medium'
        onClick={ () => {} }
      >
        <div className={ styles.route_wrap }>{ value }</div>
      </Button>
    </Link>
  ) : <div className={ styles.redirect } onClick={ () => redirectToSubPage(tripId) }>{value}</div>);

  const valuesHtml = (value: string, tripId: number | undefined) => (tripId ?
    valuesOnClickHtml(value, tripId)
    : <div className={ `${styles.route_color} ${styles.route_wrap}` }>{ value }</div>);

  const mapperData = (value: string, indexData: number, isCanceled: boolean | undefined, data: any) => {
    if (indexData === 0) return null;

    return (
      <td key={ indexData }>
        <div className={ styles.value_wrap }>
          { value }
          { renderCanceled(isCanceled, data, indexData) }
        </div>
      </td>
    );
  };

  return (
    <>
      <div className={ styles.table }>
        <Table
          labelsHeader={ header as string[] }
        >
          { strings.map(({ data, subPageInfo }:
          { data: string[], subPageInfo: { route: string, isCanceled?: boolean, voucherId?: number } }, indexRow: number) => (
            <tr key={ indexRow }>
              <td>
                { valuesHtml(data[0], subPageInfo?.voucherId) }
              </td>
              { data.map((value, indexData) => mapperData(value, indexData, subPageInfo?.isCanceled, data))}
            </tr>
          )) }
        </Table>
      </div>
      <div className={ styles.footer }>
        <div className={ styles.paginate }>
          <Paginate
            page={ currentPage }
            itemsPerPage={ itemsPerPage }
            total={ total }
            onChange={ onPageChange }
          />
        </div>
      </div>
    </>
  );
});

export { TaxiVoucherTable };
