import React from 'react';
import { observer } from 'mobx-react';

import ChartsAnalytics from '../../../../../bi/services/chartsAnalytics';

import ROUTES from '../../../../../bi/constants/routes';

import { TrainPageSubSections } from '../../common/smartIndex/components/trainPageSubSections';

const SmartTrain = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => (
  <TrainPageSubSections
    chartsAnalyticsService={ chartsAnalyticsService }
    path={ ROUTES.CHARTS_ANALYTICS.SMART.TRAIN.SECOND }
  />
));

export { SmartTrain };
