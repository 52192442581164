import React, { useRef } from 'react';
import { Suggest, Text } from 'new-ui';
import { getText } from '../../../../../i18n';

import { RouteTicketItemProps } from './types';

import styles from './index.module.css';

const LABELS = {
  FROM: getText('settings:travelPolicy.rules.routes.from'),
  TO: getText('settings:travelPolicy.rules.routes.to'),
  AND_BACK: getText('settings:travelPolicy.rules.routes.back'),
  FILL_FIELDS: getText('settings:travelPolicy.rules.routes.fillFields'),
};

const RouteTicketItem = ({
  value,
  type,
  showValidation,
  suggestions,
  renderSuggest,
  prepareSuggestList,
  onChange,
  onAutocomplete,
  getSuggestModel,
  firstFieldRef,
}: RouteTicketItemProps) => {
  const secondFieldRef: any = useRef(null);

  const {
    From,
    To,
    From: {
      Name: fromName,
      showSuggests: fromShowSuggests,
    },
    To: {
      Name: toName,
      showSuggests: toShowSuggests,
    },
    isValid,
  } = value;

  const { results: suggestionRes, loading: suggestionLoading } = suggestions;

  const content = (
    <div className={ styles.content }>
      <Suggest
        ref={ firstFieldRef }
        isFetching={ suggestionLoading }
        theme='border'
        className={ styles.suggest }
        inputClassName={ styles.input }
        placeholder={ LABELS.FROM }
        value={ fromName }
        onChange={ n => {
          onChange({ ...value, From: { ...From, Name: n, isSelected: false, showSuggests: true } });
          onAutocomplete(n);
        } }
        onSelect={ async v => {
          if (v && secondFieldRef.current) {
            await onChange({ ...value, From: { ...getSuggestModel(type, v), isSelected: true, showSuggests: false } });
            secondFieldRef.current.focus();
          }
        } }
        items={ prepareSuggestList(suggestionRes, fromShowSuggests) }
        renderItem={ renderSuggest }
      />
      <Suggest
        ref={ secondFieldRef }
        isFetching={ suggestionLoading }
        theme='border'
        className={ styles.suggest }
        inputClassName={ styles.input }
        placeholder={ LABELS.TO }
        value={ toName }
        onChange={ n => {
          onChange({ ...value, To: { ...To, Name: n, isSelected: false, showSuggests: true } });
          onAutocomplete(n);
        } }
        onSelect={ v =>
          v && onChange({ ...value, To: { ...getSuggestModel(type, v), isSelected: true, showSuggests: false } })
        }
        items={ prepareSuggestList(suggestionRes, toShowSuggests) }
        renderItem={ renderSuggest }
      />
      <Text className={ styles.back }>
        { LABELS.AND_BACK }
      </Text>
    </div>
  );

  const error = showValidation && !isValid && (
    <Text
      className={ styles.error }
      type='NORMAL_12'
      color='red'
    >
      { LABELS.FILL_FIELDS }
    </Text>
  );

  return (
    <div className={ styles.wrap }>
      { content }
      { error }
    </div>
  );
};

export { RouteTicketItem };
