import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ManyColumnsBarChart, PageLoader } from 'new-ui';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import ChartsAnalytics from '../../../../bi/services/chartsAnalytics';

import { ChartAnalyticsNoResult } from '../noResult';

import { SummaryCommon } from './components/SummaryCommon';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import styles from './styles/index.module.css';

const ChartAnalyticsGeneral = observer(({ chartsAnalyticsService }: { chartsAnalyticsService: ChartsAnalytics }) => {
  const {
    chartsAnalyticsGeneralStore: {
      loading,
      leftList,
      rightList,
      isCommonChart,
      chart: {
        values,
        labels,
        legends,
        colors,
      },
    },
  } = useStores([MOBX_STORES.CHARTS_ANALYTICS_GENERAL_STORE]);

  const {
    getGeneral,
  } = chartsAnalyticsService;

  useEffect(() => {
    getGeneral();
  }, [getGeneral]);

  useEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.ANALYTICS.MAIN_PAGE_OPENED);
  }, []);

  if (loading) {
    return <PageLoader/>;
  }

  if (!values.length) {
    return <ChartAnalyticsNoResult />;
  }

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.wrapper_chart }>
        <ManyColumnsBarChart
          stacked={ isCommonChart }
          barLabels={ labels }
          values={ values }
          legends={ legends }
          colors={ colors }
        />
      </div>
      <div className={ styles.summary }>
        <SummaryCommon
          list={ leftList }
        />
        <SummaryCommon
          list={ rightList }
          className={ styles.right }
        />
      </div>
    </div>
  );
});

export { ChartAnalyticsGeneral };
