import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Collapse, RadioButton } from 'new-ui';
import { getText } from '../../../../../i18n';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { PriceSlider } from '../../../../components/PriceSlider';
import { FilterPanel } from '../../../../components/FilterPanel';
import { FilterTravelPolicy } from '../../../../components/FilterTravelPolicy';

import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { FILTER_TYPE, MAPCARCLASS_RU, VALUES_TABLETS } from '../../../../bi/constants/transfers';

import { AnalyticSignTransfer, AnalyticTypeClassTransfer, TransferFiltersProps } from '../../../../bi/types/transfer';

import styles from './index.module.css';

const LABELS = {
  REQUEST: getText('transfer:transferResult.request'),
  COST: getText('transfer:transferResult.cost'),
  TRAVEL_POLICY: getText('transfer:transferResult.travelPolicy'),
  FAVORITED: getText('transfer:transferResult.favorited'),
  ONLY_FAVORITED: getText('transfer:transferResult.onlyFavorited'),
  CLASS: getText('transfer:transferResult.class'),
  ADDITIONAL_SERVICES: getText('transfer:transferResult.additionalServices'),
  WITH_TABLE: getText('transfer:transferResult.withTable'),
  WITHOUT_TABLE: getText('transfer:transferResult.withoutTable'),
};

const AnalyticMatch = {
  All: null,
  Undefined: null,
  Standart: AnalyticTypeClassTransfer.standard,
  TollRoadStandart: AnalyticTypeClassTransfer.standard_plus,
  Comfort: AnalyticTypeClassTransfer.comfort,
  TollRoadComfort: AnalyticTypeClassTransfer.comfort_plus,
  Business: AnalyticTypeClassTransfer.business,
  TollRoadBusiness: AnalyticTypeClassTransfer.business,
  BusinessLite: AnalyticTypeClassTransfer.business,
  BusinessComfort: AnalyticTypeClassTransfer.business,
  Minivan: AnalyticTypeClassTransfer.minivan,
  MinivanVip: AnalyticTypeClassTransfer.minivan,
  MiniBus: AnalyticTypeClassTransfer.minibus,
  LargeMiniBus: AnalyticTypeClassTransfer.minibus,
  Executive: AnalyticTypeClassTransfer.government,
};

const TransferFilters: FC<TransferFiltersProps> = observer(({
  updateFilter,
  travelPolicyList,
}) => {
  const { transferStore: {
    unavailableTravelPolicy,
    filter: {
      carClass,
      price: { min, max, from, to },
      table,
      travelPolicyList: travelPolicy,
      selectedTravelPolicy,
    },
  } } = useStores([MOBX_STORES.TRANSFER_STORE]);

  const updatePolitics = (field: string, value: { value: string }) => updateFilter({
    name: FILTER_TYPE.POLICY,
    value,
    field,
  });

  const handleSlide = (values: []) => updateFilter({
    name: FILTER_TYPE.PRICE,
    value: values,
  });

  const updateCheckbox = (field: string, value: boolean) => {
    updateFilter({
      name: FILTER_TYPE.CAR_CLASS,
      value,
      field,
    });

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_TRANSFER_CLASS_CHOSEN(
        AnalyticMatch[field as keyof typeof AnalyticMatch],
      ),
    );
  };

  const updateTable = (value: string, type: AnalyticSignTransfer) => {
    updateFilter({ name: FILTER_TYPE.TABLE, value });

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_TRANSFER_SIGN_CHOSEN(type),
    );
  };

  const travelPolitics = travelPolicy.length > 1 && (
    <Collapse
      opened
      className={ styles.item }
      label={ LABELS.TRAVEL_POLICY }
    >
      <FilterTravelPolicy
        list={ travelPolicy }
        travelPolicyList={ travelPolicyList }
        readOnly={ unavailableTravelPolicy }
        selected={ selectedTravelPolicy }
        onChangeCheckbox={ (value: string) => updatePolitics(FILTER_TYPE.POLICY, { value }) }
      />
    </Collapse>
  );

  const priceSliderHtml = (
    <Collapse
      opened
      className={ styles.item }
      label={ LABELS.COST }
    >
      <PriceSlider
        min={ min }
        max={ max }
        start={ from }
        end={ to }
        step={ 1 }
        onSlide={ handleSlide }
      />
    </Collapse>
  );

  const checkboxHtml = Object.keys(carClass).map((k) => (
    <Checkbox
      className={ styles.checkbox }
      key={ k }
      value={ carClass[k] }
      onChange={ v => updateCheckbox(k, v) }
    >
      { MAPCARCLASS_RU[k as keyof typeof MAPCARCLASS_RU] }
    </Checkbox>
  ));

  const classesHtml = (
    <Collapse
      opened
      className={ styles.item }
      label={ LABELS.CLASS }
    >
      { checkboxHtml }
    </Collapse>
  );

  const additionalServicesHtml = (
    <Collapse
      opened
      label={ LABELS.ADDITIONAL_SERVICES }
      className={ styles.item }
    >
      <RadioButton
        key={ VALUES_TABLETS.WITH_TABLET }
        value={ VALUES_TABLETS.WITH_TABLET }
        checked={ table === VALUES_TABLETS.WITH_TABLET }
        onChange={ (value) => updateTable(value, AnalyticSignTransfer.included) }
        alternativeDesign={ isSmartAgent }
        className={ styles.radio }
      >
        { LABELS.WITH_TABLE }
      </RadioButton>
      <RadioButton
        key={ VALUES_TABLETS.WITHOUT_TABLET }
        value={ VALUES_TABLETS.WITHOUT_TABLET }
        checked={ table === VALUES_TABLETS.WITHOUT_TABLET }
        onChange={ (value) => updateTable(value, AnalyticSignTransfer.not_included) }
        alternativeDesign={ isSmartAgent }
        className={ styles.radio }
      >
        { LABELS.WITHOUT_TABLE }
      </RadioButton>
    </Collapse>
  );

  return (
    <FilterPanel>
      { travelPolitics }
      { priceSliderHtml }
      { classesHtml }
      { additionalServicesHtml }
    </FilterPanel>
  );
});

export { TransferFilters };
